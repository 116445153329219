import React, { useState, useContext, useEffect } from "react";
import {
  TextField,
  Grid,
  Box,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { SnackbarContext } from "context/snackBarContext";
import CuringUnitService from "services/CuringUnitService";
import { useTranslation } from "react-i18next";
import Divider from "components/common/GrowingDivider";
import CloseIcon from "@mui/icons-material/Close"; // <-- Import CloseIcon for the close button inside TextField

const AdditionalCuringUnitData = ({
  errors,
  values,
  setValues,
  handleChange,
  options,
  setOptions,
  edit,
}) => {
  const { t } = useTranslation();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const fetchFurnaceTypes = () => {
    CuringUnitService.getFurnaceTypes()
      .then((res) => {
        const furnaceTypes =
          res?.data?.response?.furnaceTypes.map((furnaceType) => ({
            furnaceType: furnaceType.ftFurnaceTypeName,
            furnaceTypeid: furnaceType.ftFurnaceTypeid,
          })) || [];
        setOptions((prevOptions) => ({
          ...prevOptions, // Spread previous options to retain other values
          furnaceTypeOptions: furnaceTypes, // Update only furnaceTypeOptions
        }));
        if (values.furnaceTypeId) {
          setValues((prevValues) => ({
            ...prevValues,
            furnaceType: furnaceTypes.find(
              (furnaceType) =>
                furnaceType.furnaceTypeid === values.furnaceTypeId
            ).furnaceType,
          }));
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const fetchCuringControllers = () => {
    CuringUnitService.getCuringControllers()
      .then((res) => {
        const curingControllers =
          res?.data?.response?.curingDevices.map((curingDevice) => ({
            curingController: curingDevice.cdCuringDeviceName,
            curingDeviceId: curingDevice.cdCuringDeviceid,
          })) || [];
        setOptions((prevOptions) => ({
          ...prevOptions, // Spread previous options to retain other values
          curingControllerOptions: curingControllers, // Update only furnaceTypeOptions
        }));
        if (values.curingDeviceId) {
          const matchingController = curingControllers.find(
            (curingDevice) =>
              curingDevice.curingDeviceId === values.curingDeviceId
          );
          setValues((prevValues) => ({
            ...prevValues,
            curingController: matchingController
              ? matchingController.curingController
              : "",
          }));
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const fetchPicklistApi = () => {
    CuringUnitService.getPicklistApi()
      .then((res) => {
        const furnacePositionOptions =
          res?.data?.response?.CURFP.map(
            (position) => position.pickListDescription
          ) || [];
        const shedTypeOptions =
          res?.data?.response?.CURST.map(
            (shedType) => shedType.pickListDescription
          ) || [];
        setOptions((prevOptions) => ({
          ...prevOptions,
          furnacePositionOptions: furnacePositionOptions,
          shedTypeOptions: shedTypeOptions,
        }));
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  useEffect(() => {
    fetchFurnaceTypes();
    fetchCuringControllers();
    fetchPicklistApi();
  }, []);

  return (
    <>
      <Divider
        title={
          t("curingUnitRegistration.additionalData") + values.curingTypeName
        }
      />
      <Box
        sx={{
          minHeight: "10%",
          flexGrow: 1,
          mt: "20px",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <Box
          sx={{
            padding: "1rem",
            paddingBottom: 0,
            bgcolor: "#EFEFEF",
            borderRadius: "5px",
            paddingRight: "30px",
          }}
        >
          {values.curingTypeName === "Flue Cured" ? (
            <Grid container rowSpacing={0} columnSpacing={2}>
              {/* Furnace Type */}
              <Grid item xs={4}>
                <TextField
                  select
                  name="furnaceType"
                  label={t("curingUnitRegistration.furnaceType")}
                  value={values.furnaceType || "Select an Option"}
                  onChange={handleChange}
                  fullWidth
                  helperText={errors.furnaceType ? errors.furnaceType : " "}
                  error={!!errors.furnaceType}
                  disabled={edit === false}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      ...(edit === false && {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #0000003B",
                        },
                      }),
                    },
                  }}
                  InputProps={{
                    endAdornment: values.furnaceType && edit !== false && (
                      <InputAdornment
                        position="end"
                        sx={{ paddingRight: "10px" }}
                      >
                        <IconButton
                          size="small"
                          onClick={() => {
                            setValues({ ...values, furnaceType: "" });
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="Select an Option" sx={{ display: "none" }}>
                    {t("selectoption")}
                  </MenuItem>
                  {options.furnaceTypeOptions.map((furnace, index) => (
                    <MenuItem key={index} value={furnace.furnaceType}>
                      {furnace.furnaceType}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Curing Controller */}
              <Grid item xs={4}>
                <TextField
                  select
                  name="curingController"
                  label={t("curingUnitRegistration.curingController")}
                  value={values?.curingController || "Select an Option"}
                  onChange={handleChange}
                  fullWidth
                  helperText={
                    errors.curingController ? errors.curingController : " "
                  }
                  disabled={edit === false}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      ...(edit === false && {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #0000003B",
                        },
                      }),
                    },
                  }}
                  error={!!errors.curingController}
                  InputProps={{
                    endAdornment: values.curingController && edit !== false && (
                      <InputAdornment
                        position="end"
                        sx={{ paddingRight: "10px" }}
                      >
                        <IconButton
                          size="small"
                          onClick={() => {
                            setValues({ ...values, curingController: "" });
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="Select an Option" sx={{ display: "none" }}>
                    {t("selectoption")}
                  </MenuItem>
                  {options.curingControllerOptions.map((curing, index) => (
                    <MenuItem key={index} value={curing.curingController}>
                      {curing.curingController}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Furnace Position */}
              <Grid item xs={4}>
                <TextField
                  select
                  name="furnacePosition"
                  label={t("curingUnitRegistration.furnacePosition")}
                  value={values.furnacePosition || "Select an Option"}
                  onChange={handleChange}
                  fullWidth
                  helperText={
                    errors.furnacePosition ? errors.furnacePosition : " "
                  }
                  disabled={edit === false}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      ...(edit === false && {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #0000003B",
                        },
                      }),
                    },
                  }}
                  error={!!errors.furnacePosition}
                  InputProps={{
                    endAdornment: values.furnacePosition && edit !== false && (
                      <InputAdornment
                        position="end"
                        sx={{ paddingRight: "10px" }}
                      >
                        <IconButton
                          size="small"
                          onClick={() => {
                            setValues({ ...values, furnacePosition: "" });
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="Select an Option" sx={{ display: "none" }}>
                    {t("selectoption")}
                  </MenuItem>
                  {options.furnacePositionOptions.map((position, index) => (
                    <MenuItem key={index} value={position}>
                      {position}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          ) : (
            <Grid container rowSpacing={0} columnSpacing={2}>
              {/* Shed Type */}
              <Grid item xs={4}>
                <TextField
                  select
                  name="shedType"
                  label={t("curingUnitRegistration.shedType")}
                  value={values.shedType || "Select an Option"}
                  onChange={handleChange}
                  fullWidth
                  helperText={errors.shedType ? errors.shedType : " "}
                  error={!!errors.shedType}
                  disabled={edit === false}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      ...(edit === false && {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #0000003B",
                        },
                      }),
                    },
                  }}
                  InputProps={{
                    endAdornment: values.shedType && edit !== false && (
                      <InputAdornment
                        position="end"
                        sx={{ paddingRight: "10px" }}
                      >
                        <IconButton
                          size="small"
                          onClick={() => {
                            setValues({ ...values, shedType: "" });
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="Select an Option" sx={{ display: "none" }}>
                    {t("selectoption")}
                  </MenuItem>
                  {options.shedTypeOptions.map((shedType, index) => (
                    <MenuItem key={index} value={shedType}>
                      {shedType}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Cover Screen */}
              <Grid item xs={4}>
                <TextField
                  select
                  name="coverScreen"
                  label={t("curingUnitRegistration.coverScreen")}
                  value={values.coverScreen || "Select an Option"}
                  onChange={handleChange}
                  fullWidth
                  helperText={errors.coverScreen ? errors.coverScreen : " "}
                  error={!!errors.coverScreen}
                  disabled={edit === false}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      ...(edit === false && {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #0000003B",
                        },
                      }),
                    },
                  }}
                  InputProps={{
                    endAdornment: values.coverScreen && edit !== false && (
                      <InputAdornment
                        position="end"
                        sx={{ paddingRight: "10px" }}
                      >
                        <IconButton
                          size="small"
                          onClick={() => {
                            setValues({ ...values, coverScreen: "" });
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="Select an Option" sx={{ display: "none" }}>
                    {t("selectoption")}
                  </MenuItem>
                  {options.coverScreenOptions.map((screen, index) => (
                    <MenuItem key={index} value={screen}>
                      {screen}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Side Closure */}
              <Grid item xs={4}>
                <TextField
                  select
                  name="sideClosure"
                  label={t("curingUnitRegistration.sideClosure")}
                  value={values.sideClosure || "Select an Option"}
                  onChange={handleChange}
                  fullWidth
                  helperText={errors.sideClosure ? errors.sideClosure : " "}
                  error={!!errors.sideClosure}
                  disabled={edit === false}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      ...(edit === false && {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #0000003B",
                        },
                      }),
                    },
                  }}
                  InputProps={{
                    endAdornment: values.sideClosure && edit !== false && (
                      <InputAdornment
                        position="end"
                        sx={{ paddingRight: "10px" }}
                      >
                        <IconButton
                          size="small"
                          onClick={() => {
                            setValues({ ...values, sideClosure: "" });
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="Select an Option" sx={{ display: "none" }}>
                    {t("selectoption")}
                  </MenuItem>
                  {options.sideClosureOptions.map((side, index) => (
                    <MenuItem key={index} value={side}>
                      {side}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};
export default AdditionalCuringUnitData;
