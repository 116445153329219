import { httpGrow } from "../http-common";

const getAPI = async (query) => {

    const { cropYearUUID, importHierarchyId } = query;
    const response = await httpGrow.get(`/expenses-with-TPLimits-distribution`, {
        params: {
            cropYearUUID,
            importHierarchyId
        }
    });
    return response;
};

const getCropSeasonAPI = async () => {
    const response = await httpGrow.get('/cropYears');
    return response;
};

const getHierarchyNodeAPI = async () => {
    const response = await httpGrow.get('/importHierarchyVersion/tree');
    return response;
};

const getExpensesGroupListAPI = async (query) => {
    const { ewtpExpenseswith3rdPartiesDescription } = query;
    const response = await httpGrow.get('/expense-with-3rd-parties/find', {
        params: {
            ewtpExpenseswith3rdPartiesDescription: ewtpExpenseswith3rdPartiesDescription || ""
        }
    });
    return response;
};

const getExpensesGLAPI = async () => {
    const response = await httpGrow.get('/expense-with-3rd-parties/list');
    return response;
};

const putExpensesGLDAPI = (data) => {
    return httpGrow.put('/expenses-with-TPLimits-distribution/save', data);
};


const deleteDataAPI = async ({cropYearUUID, expensesWithTPGroupId}) => {
    return await httpGrow.delete(`/expenses-with-TPLimits-distribution/delete/${cropYearUUID}/${expensesWithTPGroupId}`);
  };


const ExpensesLimitDistributionService = {
    getAPI,
    getCropSeasonAPI,
    getHierarchyNodeAPI,
    getExpensesGroupListAPI,
    putExpensesGLDAPI,
    getExpensesGLAPI,
    deleteDataAPI
};

export default ExpensesLimitDistributionService;