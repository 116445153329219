// src\services\PAFBService.jsx
import { httpGrow } from "../http-common";

// User Story constants
const formBuilderBasename = "farmer-contract-pre-assessment-form-builder";

const getAllFormApi = async (query) => {
    const { 
        pageNumber = 0, 
        pageSize = 10, 
        formVersionNo = undefined
    } = query;
    
    const response = await httpGrow.get(`/${formBuilderBasename}/all-form-details`, {
        params: {
            pageNumber,
            pageSize,
            formVersionNo
        }
    });
    return response;
};

const getFormDetailsApi = async (formVersionNo, isDuplicateEnable = undefined) => {
    const response = await httpGrow.get(`/${formBuilderBasename}/form-details`, {
        params: {
            formVersionNo,
            isDuplicateEnable
        }
    });
    return response;
};

const createPreAssessmentForm = async (formData) => {
    const response = await httpGrow.post(`/${formBuilderBasename}`, formData);
    return response;
};

const updatePreAssessmentForm = async (formData) => {
    const response = await httpGrow.put(`/${formBuilderBasename}/update-form-details`, formData);
    return response;
};

const deleteAnswerApi = async (fcpFaId) => {
    const response = await httpGrow.delete(`/${formBuilderBasename}/delete-answer/${fcpFaId}`);
    return response;
};

const deleteQuestionApi = async (fcpFqId) => {
    const response = await httpGrow.delete(`/${formBuilderBasename}/delete-question/${fcpFqId}`);
    return response;
};

const deleteSectionApi = async (fcpFsId) => {
    const response = await httpGrow.delete(`/${formBuilderBasename}/delete-section/${fcpFsId}`);
    return response;
};

const deleteFormApi = async (fcpFbId) => {
    const response = await httpGrow.delete(`/${formBuilderBasename}/delete-form/${fcpFbId}`);
    return response;
};

const PAFBService = {
    getAllFormApi,
    getFormDetailsApi,
    createPreAssessmentForm,
    updatePreAssessmentForm,
    deleteAnswerApi,
    deleteQuestionApi,
    deleteSectionApi,
    deleteFormApi
};

export default PAFBService;
