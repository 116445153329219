import { httpGrow } from "../http-common";

const geModuleAPI = async () => {
    const response = await httpGrow.get('/functionalities');
    return response;
  }


const getAlertData = async ({moduleId}) => {
  return await httpGrow.get(`/alertAndNotification?moduleId=${moduleId}&pageNumber=0&pageSize=7`);
};

const getAllAlertData = async () => {
  return await httpGrow.get(`/alertAndNotification?pageNumber=0&pageSize=7`);
};


const AlertsService = {
    geModuleAPI,
    getAlertData,
    getAllAlertData
    
};

export default AlertsService;