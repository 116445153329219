import React, { useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
//importing css from education type as there is no change here
import "../EducationTypeRegistration/EducationTypeTable.css";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import _ButtonView_ from "assets/Vector.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";

const AgronomicLossTypeTable = ({ ALTData, setALTData }) => {
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const { t } = useTranslation();

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...ALTData].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setALTData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <ArrowUpwardIcon fontSize="small" />;
      } else if (sortConfig.direction === "desc") {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
    return <ArrowDownwardIcon fontSize="small" />;
  };

  return (
    <div className="table-container">
      <table className="education-type-table">
        <thead>
          <tr>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("agronomicLossType")}
            >
              {t("agronomicLossTypeRegistration.agronomicLossType")}{" "}
              {getSortIcon("agronomicLossType")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("isActive")}
            >
              {t("status")}
              {getSortIcon("isActive")}
            </th>
            <th className="actions-header">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  justifyContent: "left",
                }}
              >
                {t("actions")}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {ALTData?.map((row, index) => (
            <tr key={index}>
              <td>{row.agronomicLossType}</td>
              <td>{row.isActive ? "Active" : "Inactive"}</td>
              <td className="actions-cell">
                <Tooltip title="EDIT" arrow placement="top">
                  <img
                    className="btn edit-btn"
                    src={_ButtonEdit_}
                    alt="Edit Button"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the row click from firing
                      navigate("/addAgronomicLossType", {
                        state: { row: row, edit: true },
                      });
                    }}
                  />
                </Tooltip>

                <Tooltip title="VIEW" arrow placement="top">
                  <img
                    className="btn edit-btn"
                    src={_ButtonView_}
                    alt="View Button"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the row click from firing
                      navigate("/addAgronomicLossType", {
                        state: { row: row, edit: false },
                      });
                    }}
                  />
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AgronomicLossTypeTable;
