import React, { useState, useCallback, useEffect } from "react";
import Map, { Marker, NavigationControl } from "react-map-gl";
import axios from "axios";
import "mapbox-gl/dist/mapbox-gl.css";

const MapboxMap = (props) => {
  const [coordinates, setCoordinates] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Use environment variable for Mapbox token
  const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

  // Update coordinates when initialCoordinates prop changes
  useEffect(() => {
    if (props.initialCoordinates) {
      // Ensure coordinates are valid numbers
      const validCoordinates = {
        latitude: parseFloat(props.initialCoordinates.latitude),
        longitude: parseFloat(props.initialCoordinates.longitude),
        altitude: parseFloat(props.initialCoordinates.altitude) || 0
      };

      // Only set if latitude and longitude are valid numbers
      if (!isNaN(validCoordinates.latitude) && !isNaN(validCoordinates.longitude)) {
        setCoordinates(validCoordinates);
      }
    }
  }, [props.initialCoordinates]);

  const getAltitude = useCallback(async (lt, ln) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://api.open-elevation.com/api/v1/lookup",
        {
          locations: [{ latitude: lt, longitude: ln }],
        },
        // {
        //   timeout: 3000 // 5 second timeout
        // }
      );
      const altitudeValue = response.data.results[0].elevation;
      
      const obj = {
        latitude: lt,
        longitude: ln,
        altitude: altitudeValue,
      };
      
      props.setCoordinatesParent(obj);
      setCoordinates(obj);
      setIsLoading(false);
      return altitudeValue;
    } catch (error) {
      console.error("Error fetching altitude:", error);
      
      // Fallback: use a default altitude or skip altitude
      const obj = {
        latitude: lt,
        longitude: ln,
        altitude: 0, // or null
      };
      
      props.setCoordinatesParent(obj);
      setCoordinates(obj);
      setIsLoading(false);
      return 0; // or null
    }
  }, [props.setCoordinatesParent]);

  const handleMapClick = async (event) => {
    const { lngLat } = event;
    const { lng, lat } = lngLat;
    
    // Immediately set coordinates to improve responsiveness
    const newCoordinates = {
      latitude: lat,
      longitude: lng,
      altitude: null
    };
    
    setCoordinates(newCoordinates);

    // Fetch altitude in background
    getAltitude(lat, lng);
  };

  // Initial view state now uses a fixed zoom level
  const initialViewState = coordinates 
    ? {
        longitude: coordinates.longitude,
        latitude: coordinates.latitude,
        zoom: 2, // Keep a consistent zoom level
      }
    : {
        longitude: 0,
        latitude: 0,
        zoom: 2,
      };

  // If no token, render a message
  if (!MAPBOX_TOKEN) {
    return (
      <div style={{ 
        height: "50vh", 
        width: "100%", 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center",
        border: "2px solid red"
      }}>
        <p style={{ color: "red" }}>
          Mapbox Token is not defined. Please check your .env file.
        </p>
      </div>
    );
  }

  return (
    <div style={{ 
      height: "50vh", 
      width: "100%", 
      position: "relative" 
    }}>
      <Map
        initialViewState={initialViewState}
        style={{ width: "100%", height: "100%" }}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        mapboxAccessToken={MAPBOX_TOKEN}
        onClick={handleMapClick}
      >
        <NavigationControl position="top-right" />
        
        {coordinates && (
          <Marker 
            longitude={coordinates.longitude} 
            latitude={coordinates.latitude} 
            color={isLoading ? "red" : "red"} 
            draggable
            onDragEnd={(event) => {
              const { lngLat } = event;
              handleMapClick({ lngLat });
            }}
          />
        )}
      </Map>
      {isLoading && (
        <div style={{
          position: 'absolute',
          bottom: '10px',
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: 'rgba(0,0,0,0.7)',
          color: 'white',
          padding: '5px 10px',
          borderRadius: '5px'
        }}>
          Fetching altitude...
        </div>
      )}
    </div>
  );
};

export default MapboxMap;
