import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import SoilAnalysisInterpretationTable from "./SoilAnalysisInterpretationTable";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SoilAnalysisInterpretationRegistrationService from "services/SoilAnalysisInterpretationRegistrationService";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const SoilAnalysisInterpretationRegistration = () => {
  const navigate = useNavigate();
  const [geneticTypeOptions, setGeneticTypeOptions] = useState([]);
  const [parameterNamesOptions, setParameterNamesOptions] = useState([]);
  const [filAnalysisData, setfilAnalysisData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [values, setValues] = useState({
    iprInterpretationName: "",
    geneticType: "",
    geneticTypeId: "",
    parameterName: "",
    parameterNameId: "",
    iprStatus: "",
  });
  const { t } = useTranslation();
  const debounceTimeout = useRef(null); // Declare useRef at the top level of the component

  const fetchGeneticTypes = () => {
    SoilAnalysisInterpretationRegistrationService.getGeneticTypes()
      .then((res) => {
        const geneticTypes =
          res?.data?.response?.map((geneticType) => ({
            geneticType: geneticType.gtGeneticDescription,
            geneticTypeId: geneticType.gtGeneticTypeId,
          })) || [];
        setGeneticTypeOptions(geneticTypes);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const fetchSoilAnalysisParameters = () => {
    SoilAnalysisInterpretationRegistrationService.getSoilAnalysisParameters()
      .then((res) => {
        const parameters =
          res?.data?.response?.soilAnalysisParameters.map((params) => ({
            parameterName: params.sapParameterName,
            parameterNameId: params.sapAnalysisId,
          })) || [];
        setParameterNamesOptions(parameters);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const handleInterpretationChange = (event) => {
    const value = event.target.value;
    setValues((prev) => ({
      ...prev,
      interpretationName: value,
    }));
    setCurrentPage(0);

    // Clear the previous timeout to debounce the API call
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new timeout to call the API after 2 seconds
    debounceTimeout.current = setTimeout(() => {
      if (value) {
        searchSoilAnalysisApi(
          0,
          itemsPerPage,
          value,
          values.geneticTypeId,
          values.parameterNameId,
          values.iprStatus
        );
      } else {
        searchSoilAnalysisApi(
          0,
          itemsPerPage,
          "",
          values.geneticTypeId,
          values.parameterNameId,
          values.iprStatus
        );
      }
    }, 1500);
  };

  const handleClearStatus = () => {
    setValues((prev) => ({
      ...prev,
      iprStatus: "",
    }));
    setCurrentPage(0);
    searchSoilAnalysisApi(
      0,
      itemsPerPage,
      values.interpretationName,
      values.geneticTypeId,
      values.parameterNameId,
      null
    );
  };

  const handleClearGeneticType = () => {
    setValues((prev) => ({
      ...prev,
      geneticType: "",
      geneticTypeId: "",
    }));
    setCurrentPage(0);
    searchSoilAnalysisApi(
      0,
      itemsPerPage,
      values.interpretationName,
      "",
      values.parameterNameId,
      values.iprStatus
    );
  };

  const handleClearParameterName = () => {
    setValues((prev) => ({
      ...prev,
      parameterName: "",
      parameterNameId: "",
    }));
    setCurrentPage(0);
    searchSoilAnalysisApi(
      0,
      itemsPerPage,
      values.interpretationName,
      values.geneticTypeId,
      "",
      values.iprStatus
    );
  };

  const handleStatusChange = (statusValue) => {
    setValues((prev) => ({
      ...prev,
      iprStatus: statusValue,
    }));
    setCurrentPage(0);
    searchSoilAnalysisApi(
      0,
      itemsPerPage,
      values.interpretationName,
      values.geneticTypeId,
      values.parameterNameId,
      statusValue
    );
  };

  const handleGeneticTypeChange = (geneticType) => {
    const geneticTypeId = geneticTypeOptions.find(
      (ele) => ele.geneticType === geneticType
    ).geneticTypeId;
    setValues((prev) => ({
      ...prev,
      geneticType: geneticType,
      geneticTypeId: geneticTypeId,
    }));
    setCurrentPage(0);
    searchSoilAnalysisApi(
      0,
      itemsPerPage,
      values.interpretationName,
      geneticTypeId,
      values.parameterNameId,
      values.iprStatus
    );
  };

  const handleParameterNameChange = (parameter) => {
    const parameterNameId = parameterNamesOptions.find(
      (ele) => ele.parameterName === parameter
    ).parameterNameId;
    setValues((prev) => ({
      ...prev,
      parameterName: parameter,
      parameterNameId: parameterNameId,
    }));
    setCurrentPage(0);
    searchSoilAnalysisApi(
      0,
      itemsPerPage,
      values.interpretationName,
      values.geneticTypeId,
      parameterNameId,
      values.iprStatus
    );
  };

  const searchSoilAnalysisApi = async (
    pageNumber,
    size,
    interpretationName,
    geneticTypeId,
    sapAnalysisId,
    status
  ) => {
    setLoading(true);

    // Construct the query object
    const query = {
      pageNumber,
      size,
      interpretationName,
      geneticTypeId,
      sapAnalysisId,
      status,
    };

    try {
      let response;

      // Decide which API to call based on the query parameters
      if (
        !query.status &&
        !query.sapAnalysisId &&
        !query.geneticTypeId &&
        !query.interpretationName
      ) {
        const query = {
          pageNumber,
          size,
        };
        response =
          await SoilAnalysisInterpretationRegistrationService.getAllSoilAnalysisInterpretationData(
            query
          );
      } else {
        response =
          await SoilAnalysisInterpretationRegistrationService.getSoilAnalysisInterpretationData(
            query
          );
      }

      // Extract response data
      const interpretationResults =
        response?.data?.response?.["Soil Anlysis Interpretation"] || [];

      // Update state with the API results
      setCurrentPage(pageNumber);
      setItemsPerPage(size);
      setfilAnalysisData(interpretationResults);
      setTotalItems(response?.data?.response?.totalItems || 0);

      // Handle case when no records are found
      if (interpretationResults.length === 0) {
        setMessage("No records found.");
        setSeverity("info");
        setOpenAlert(true);
      }
    } catch (error) {
      // Handle errors
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        "An error occurred.";
      setMessage(errorMessage);
      setSeverity("error");
      setOpenAlert(true);

      // Clear data on error
      setfilAnalysisData([]);
      setTotalItems(0);
    } finally {
      // Ensure loading is stopped
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGeneticTypes();
    fetchSoilAnalysisParameters();
  }, []);

  useEffect(() => {
    searchSoilAnalysisApi(
      currentPage,
      itemsPerPage,
      values.interpretationName,
      values.geneticTypeId,
      values.parameterNameId,
      values.iprStatus
    );
  }, [currentPage, itemsPerPage]);

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    searchSoilAnalysisApi(
      pageNumber - 1,
      itemsPerPage,
      values.interpretationName,
      values.geneticTypeId,
      values.parameterNameId,
      values.iprStatus
    );
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t("soilAnalysisInterpretationRegistration.pageTitle")}
      background="#ffffff"
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          padding: "1rem",
          height: "100px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} />
          <Typography sx={{ fontWeight: "600", color:"#666666"}}>
            {t("filtersLable")}
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={10}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4.5}>
                <TextField
                  name="interpretationName"
                  label={t(
                    "soilAnalysisInterpretationRegistration.interpretationName"
                  )}
                  value={values.interpretationName}
                  onChange={handleInterpretationChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px", // Adjust padding for the input content
                      minHeight: "50px", // Adjust the overall height
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em", // Adjust label spacing if needed
                    },
                    width: "100%",
                  }}
                  InputProps={{
                    style: { height: "50px" }, // Directly setting the height
                  }}
                />
              </Grid>

              {/* Genetic Type Field */}
              <Grid item xs={12} sm={2.5}>
                <FormControl sx={{ width: { xs: "100%" }, minHeight: 50 }}>
                  <TextField
                    select
                    name="geneticType"
                    label={t(
                      "soilAnalysisInterpretationRegistration.geneticType"
                    )}
                    value={values.geneticType || "Select an Option"}
                    onChange={(event) => {
                      handleGeneticTypeChange(event.target.value);
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                      endAdornment: values.geneticType && (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={handleClearGeneticType}
                            style={{ marginRight: "15px" }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  >
                                      <MenuItem value="Select an Option" sx={{ display: "none" }}>
                    {t("selectoption")}
                  </MenuItem>
                    {geneticTypeOptions.map((geneticType) => (
                      <MenuItem
                        key={geneticType.geneticTypeId}
                        value={geneticType.geneticType}
                      >
                        {geneticType.geneticType}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>

              {/* Parameters Names */}
              <Grid item xs={12} sm={2.5}>
                <FormControl sx={{ width: { xs: "100%" }, minHeight: 50 }}>
                  <TextField
                    select
                    name="parameterName"
                    label={t(
                      "soilAnalysisInterpretationRegistration.parameterName"
                    )}
                    value={values.parameterName || "Select an Option"}
                    onChange={(event) => {
                      handleParameterNameChange(event.target.value);
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                      endAdornment: values.parameterName && (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={handleClearParameterName}
                            style={{ marginRight: "15px" }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  >
                                      <MenuItem value="Select an Option" sx={{ display: "none" }}>
                    {t("selectoption")}
                  </MenuItem>
                    {parameterNamesOptions.map((parameter) => (
                      <MenuItem key={parameter} value={parameter.parameterName}>
                        {parameter.parameterName}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>

              {/* Status field */}
              <Grid item xs={12} sm={2.5}>
                <FormControl sx={{ width: { xs: "100%" }, minHeight: 50 }}>
                  <TextField
                    select
                    name="status"
                    label={t("status")}
                    value={values.iprStatus || "Select an Option"}
                    onChange={(event) => handleStatusChange(event.target.value)}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                      endAdornment: values.iprStatus && (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={handleClearStatus}
                            style={{ marginRight: "15px" }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  >
                                      <MenuItem value="Select an Option" sx={{ display: "none" }}>
                    {t("selectoption")}
                  </MenuItem>
                    <MenuItem value="Active">{t("active")}</MenuItem>
                    <MenuItem value="Inactive">{t("inactive")}</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={2} textAlign={"right"}>
            <Button
              sx={{
                borderRadius: 1,
                minWidth: 95,
                minHeight: 44,
                background: "#004F9F",
              }}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() =>
                navigate("/addSoilAnalysisInterpretationRegistration")
              }
            >
              {t("addNew")}
            </Button>
          </Grid>
        </Grid>
      </Box>
      {/* Render table header if no data is found */}
      <SoilAnalysisInterpretationTable
        analysisData={filAnalysisData}
        setAnalysisData={setfilAnalysisData}
      />
    </Layout>
  );
};

export default SoilAnalysisInterpretationRegistration;
