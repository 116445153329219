import { Autocomplete, Box, Button, Chip, CircularProgress, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, InputLabel, ListItemText, MenuItem, Modal, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import { display, height } from '@mui/system';
import Layout from 'components/structure/Layout';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";
import arrowLeft from "assets/arrowLeft.svg";
import Save from "assets/SaveNew.svg";
import Divider from 'components/common/GrowingDivider';
import RedSwitch from 'utils/RedSwitch';
import deleteIconNew from "assets/deleteIconNew.svg";
import AgriculturalSuppliersRegService from 'services/AgriculturalSuppliesRegService';
import { MenuProps } from 'utils/utils';
import CloseIcon from "@mui/icons-material/Close";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { SnackbarContext } from 'context/snackBarContext';
import { getBase64 } from 'utils/CommonFunction';
import UserService from 'services/UserService';
import { usageSetupSchema } from 'utils/validations';



const AgriculturalSuppliesRegForm = () => {

    const location = useLocation();
    const {
        asrSupplyId,
        asrSupplyName,
        asrCode,
        sapCode,
        unlockedforOffSeason,
        notConfigured,
        displayName,
        typeOfUse,
        typeOfUseId,
        asrUnitOfMeasurement,
        supplyTypeName,
        supplyTypeId,
        blockedforBilling,
        asrThirdPartyAgriculturalSupply,
        isDelete,
        agriculturalInputGroup,
        image,
        seedType,
        applicationPhase
    } = location?.state?.row || {};
    const { edit } = location.state || {};



    const { t, i18n } = useTranslation();


    const [values, setValues] = useState({
        asrSupplyId: asrSupplyId,
        asrSupplyName: asrSupplyName,
        asrCode: asrCode,
        sapCode: sapCode,
        unlockedforOffSeason: unlockedforOffSeason,
        notConfigured: notConfigured,
        displayName: displayName,
        typeOfUse: typeOfUse,
        typeOfUseId: typeOfUseId,
        asrUnitOfMeasurement: asrUnitOfMeasurement,
        supplyTypeName: supplyTypeName,
        supplyTypeId: supplyTypeId,
        blockedforBilling: blockedforBilling,
        asrThirdPartyAgriculturalSupply: asrThirdPartyAgriculturalSupply,
        isDelete: isDelete,
        agriculturalInputGroup: agriculturalInputGroup,
        image: image,
        seedType: seedType,
        applicationPhase: applicationPhase,
    });


    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [cropSeason, setCropSeason] = useState("");
    const [cropSeasonId, setCropSeasonId] = useState("");
    const [cropSeasonOptions, setCropSeasonOptions] = useState([]);
    const [uploadImageModal, setUploadImageModal] = useState(false);
    const [geneticType, setGeneticType] = React.useState([]);
    const [geneticTypeMenu, setGeneticTypeMenu] = useState([]);
    const [usageTypeMenu, setUsageTypeMenu] = useState([]);
    const [varietyList, setVarietyList] = useState([])
    const [fundingInvestmentList, setFundingInvestmentList] = useState([])
    const [usageSetup, setUsageSetup] = useState([])
    const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

    console.log("usage info list", usageSetup)
    console.log("variety list", varietyList)
    console.log("values", varietyList)


    const handleUploadClick = () => {

    }
    const handleDeleteConfirm = () => {
        setOpen(false);
        navigate("/agriculturalSuppliesRegistrationList");
    };

    const handleOpenModal = () => {
        setOpen(true);
    };
    const handleCloseModal = () => {
        setOpen(false);
    };
    // const handleSubmit = () => {
    //     setOpen(false);
    // };
    const handleThirdPartySupplyChange = (event) => {
        const { name, value, type, checked } = event.target;
        console.log("WERTYUI", name, checked);
        setValues((prevValues) => ({
            ...prevValues,
            [name]: type === "checkbox" ? checked : value,
        }));
    }

    const handleDisplayNameChange = (value) => {
        setValues((prevValues) => {
            const updatedValues = { ...prevValues }; // Create a shallow copy of the current state
            updatedValues.displayName = value; // Update the displayName property
            return updatedValues; // Return the updated state
        });
    };

    const handleAddNew = () => {
        setUsageSetup((prevUsageInfo) => [
            ...prevUsageInfo,
            {
                createdDateTimeUTC: "",
                createdByUser: "",
                modifiedDateTimeUTC: "",
                modifiedByUser: "Jnana",
                cropSeasonId: cropSeasonId,
                geneticType: [],
                usageType: [],
                asuInfoId: "",
                // usageType: "",
                recommendationType: "",
                minQuantity: 0,
                maxQuantity: 0,
                variety: "",
                varietyId: "",
                minQuantityToOrder: 0,
                controlledByLimit: false,
                yearsToPay: 0,
                specialFunding: "",
                specialFundingId: "",
                priceConversionUnit: 0,
                price: 0,
                useSysCalc: false,
                isDelete: false
            },
        ]);
    }

    const handleDeleteUsageSetup = (index) => {
        setUsageSetup((prev) =>
            prev.map((item, i) => {
                if (i === index) {
                    if (item.asuInfoId) {
                        // Change isDelete to true if asuInfoId is available
                        return { ...item, isDelete: true };
                    } else {
                        // Filter out the item if asuInfoId is not available
                        return null; // Mark for filtering
                    }
                }
                return item;
            }).filter(Boolean) // Remove null items
        );
    };

    const [previewImage, setPreviewImage] = useState(null); // To store the preview image
    const [loading, setLoading] = useState(false);
    const inputFileRef = useRef();
    const [imageBase64, setImageBase64] = useState("");
    const [files, setfiles] = React.useState([]);
    const [imageFile, setImageFile] = useState();
    console.log("image", files)
    console.log("imageBase64", imageBase64)

    const handleFileUpload = (event) => {
        event.preventDefault();

        const file = event.target.files[0];
        const isValidType = ["image/png", "image/jpeg"].includes(file.type);
        if (isValidType) {
            handleImageDelete();
            setImageFile(URL.createObjectURL(event.target.files[0]));
            getBase64(file)
                .then((res) => {
                    const new_file = {
                        // country: country.length > 0 ? country[0].countryName : null,
                        fileContent: res,
                        fileName: file.name,
                        mimeType: file.type,
                        batOneId: "",
                    };
                    handleUpload(new_file);
                })
                .catch((err) => console.log(err));
        } else {
            alert("Please upload a PNG or JPEG image.");
        }
        // let c_code = formValues.countryCode.value;
        // let country = countries.filter((ele) => ele.countryCode == c_code);
    };

    const handleImageDelete = () => {
        setfiles([]);
        setImageFile();
        setValues((prevValues) => ({
            ...prevValues,
            image: "",
        }));
    };

    const [percentage, setPercentage] = React.useState(0);
    const config = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            if (percent <= 100) {
                setPercentage(percent); // hook to set the value of current level that needs to be passed to the progressbar
            }
        },
    };

    const handleUpload = (file) => {
        setLoading(true);
        UserService.uploadUserFile(file, config)
            .then((response) => {
                const fileData = {
                    fileUrl: response.data.response[file.fileName],
                    fileName: file.fileName,
                    country: "BRA", // Replace with dynamic country if needed
                    mimeType: file.mimeType,
                };
                setfiles([fileData]); // Update files state
                setPercentage(0);
                setLoading(false);
            })
            .catch((error) => {
                setMessage(error?.response?.data?.message || error?.message);
                setOpenAlert(true);
                setSeverity("error");
                setTimeout(() => {
                    setPercentage(0);
                }, 1000);
                setLoading(false);
            });
    };

    const onFileChange = (e) => {
        const file = e.target.files[0]; // Extract the file from the event
        handleFileUpload(file); // Pass the file to the function
    };

    const onBtnClick = () => {
        inputFileRef.current.click();
    };
    const onDragOver = (event) => {
        event.preventDefault(); // Prevent default behavior (open in a new tab)
        event.stopPropagation();
    };

    const onDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const file = event.dataTransfer.files[0];
        if (file) {
            const isValidType = ["image/png", "image/jpeg"].includes(file.type);
            if (isValidType) {
                handleImageDelete(); // Reset any existing image
                setImageFile(URL.createObjectURL(file)); // Set the preview
                getBase64(file)
                    .then((res) => {
                        const new_file = {
                            fileContent: res,
                            fileName: file.name,
                            mimeType: file.type,
                            batOneId: "",
                        };
                        handleUpload(new_file); // Upload the file
                    })
                    .catch((err) => console.error("Error processing file:", err));
            } else {
                alert("Please upload a PNG or JPEG image.");
            }
        }
    };


    const callImageBase64Api = () => {
        AgriculturalSuppliersRegService.getBase64Image(image)
            .then((response) => {
                setImageBase64(response.data.response.fileContent);
            })
            .catch((error) => {
                if (error.response) {
                    const errorMessage = error.response.data.message;
                    setMessage(errorMessage);
                    setOpenAlert(true);
                } else {
                    setSeverity("error");
                    setMessage("Failed to retrieve image.");
                    setOpenAlert(true);
                }
            });
    };







    const handleGeneticType = (index, event) => {
        const { value } = event.target;
        console.log("geneValue", value)
        const lastGeneticTypeId = value[value.length - 1]?.geneticTypeId;

        const matchingIndex = value.findIndex(
            (item, idx) => item.geneticTypeId === lastGeneticTypeId && idx !== value.length - 1
        );

        if (matchingIndex !== -1) {
            // Check if agricultureSupplyGeneticTypeId is present for the matching index's geneticType
            const matchingItem = value[matchingIndex];
            if (matchingItem.agricultureSupplyGeneticTypeId) {
                // Remove the last item from the array
                value.pop();

                // Change the isDelete flag of the matching value to its opposite
                matchingItem.isDelete = !matchingItem.isDelete;
            }
            console.log("Updated geneValue:", value);
        }
        setUsageSetup((prev) =>
            prev.map((item, i) =>
                i === index
                    ? {
                        ...item,
                        geneticType: value,
                        variety: {
                            variety: "", // Reset variety field
                            varietyId: "", // Reset varietyId field
                        },
                        varietyId: "",
                    }
                    : item
            )
        );
    };

    const handleGeneticTypeChipDelete = (index, chipToDelete) => {
        setUsageSetup((prev) =>
            prev.map((item, i) =>
                i === index
                    ? {
                        ...item,
                        geneticType: item.geneticType.map((chip) =>
                            chip.geneticTypeId === chipToDelete.geneticTypeId
                                ? chip.agricultureSupplyGeneticTypeId
                                    ? { ...chip, isDelete: true } // Set isDelete to true if agricultureSupplyGeneticTypeId is not empty
                                    : null // Remove the chip if agricultureSupplyGeneticTypeId is empty
                                : chip
                        ).filter((chip) => chip !== null), // Filter out null chips
                        variety: {
                            variety: "", // Reset variety field
                            varietyId: "", // Reset varietyId field
                        },
                        varietyId: "", // Reset varietyId field
                    }
                    : item
            )
        );

    };


    const handleUsageType = (index, event) => {
        const { value } = event.target;
        console.log("usageTypeValue", value)
        const lastUsageTypeId = value[value.length - 1]?.usageTypeId;

        const matchingIndex = value.findIndex(
            (item, idx) => item.usageTypeId === lastUsageTypeId && idx !== value.length - 1
        );

        if (matchingIndex !== -1) {
            // Check if agricultureSupplyGeneticTypeId is present for the matching index's geneticType
            const matchingItem = value[matchingIndex];
            if (matchingItem.agricultureSupplyUsageTypeId) {
                // Remove the last item from the array
                value.pop();

                // Change the isDelete flag of the matching value to its opposite
                matchingItem.isDelete = !matchingItem.isDelete;
            }
            console.log("Updated UsageTypeValue:", value);
        }
        setUsageSetup((prev) =>
            prev.map((item, i) =>
                i === index
                    ? { ...item, usageType: value }
                    : item
            )
        );
    };

    const handleUsageTypeChipDelete = (index, chipToDelete) => {
        setUsageSetup((prev) =>
            prev.map((item, i) =>
                i === index
                    ? {
                        ...item,
                        usageType: item.usageType.map((chip) =>
                            chip.usageTypeId === chipToDelete.usageTypeId
                                ? chip.agricultureSupplyUsageTypeId
                                    ? { ...chip, isDelete: true } // Set isDelete to true if agricultureSupplyGeneticTypeId is not empty
                                    : null // Remove the chip if agricultureSupplyGeneticTypeId is empty
                                : chip
                        ).filter((chip) => chip !== null), // Filter out null chips
                    }
                    : item
            )
        );
    };

    const handleRecommendationTypeChange = (index, value) => {
        console.log("Rec value", value)
        const updatedSetup = [...usageSetup];
        updatedSetup[index].recommendationType = value;
        setUsageSetup(updatedSetup);
    };


    const handleMaxQuantityChange = (index, value) => {
        const formattedValue = value.match(/^\d*\.?\d{0,2}$/) ? value : usageSetup[index].maxQuantity;

        setUsageSetup((prevUsageSetup) =>
            prevUsageSetup.map((setup, i) =>
                i === index ? { ...setup, maxQuantity: formattedValue } : setup
            )
        );
    };
    const handleMinQuantityChange = (index, value) => {
        const formattedValue = value.match(/^\d*\.?\d{0,2}$/) ? value : usageSetup[index].minQuantity;

        setUsageSetup((prevUsageSetup) =>
            prevUsageSetup.map((setup, i) =>
                i === index ? { ...setup, minQuantity: formattedValue } : setup
            )
        );
    };

    const handleVarietyChange = (index, value) => {
        console.log("value Variety", value)
        const updatedSetup = [...usageSetup];
        updatedSetup[index].variety = value;
        updatedSetup[index].varietyId = value.varietyId;
        setUsageSetup(updatedSetup);
    };

    const handleSpecialFundingChange = (index, value) => {
        console.log("inv value", value)
        const updatedSetup = [...usageSetup];
        updatedSetup[index].specialFunding = value;
        updatedSetup[index].specialFundingId = value.specialFundingId;
        setUsageSetup(updatedSetup);
    };

    const handleMinQuantityToOrder = (index, value) => {
        const formattedValue = value.match(/^\d*\.?\d{0,2}$/) ? value : usageSetup[index].minQuantityToOrder;

        setUsageSetup((prevUsageSetup) =>
            prevUsageSetup.map((setup, i) =>
                i === index ? { ...setup, minQuantityToOrder: formattedValue } : setup
            )
        );
    };

    const handleControlledByLimit = (index, checked) => {
        setUsageSetup((prevUsageSetup) =>
            prevUsageSetup.map((setup, i) =>
                i === index ? { ...setup, controlledByLimit: checked } : setup
            )
        );
    };

    const handleYearsToPay = (index, value) => {
        const formattedValue = value.match(/^\d*$/) ? value : usageSetup[index].yearsToPay;
        setUsageSetup((prevUsageSetup) =>
            prevUsageSetup.map((setup, i) =>
                i === index ? { ...setup, yearsToPay: formattedValue } : setup
            )
        );
    };

    const handlePriceConversionUnit = (index, value) => {
        const formattedValue = value.match(/^\d*\.?\d{0,2}$/) ? value : usageSetup[index].priceConversionUnit;

        setUsageSetup((prevUsageSetup) =>
            prevUsageSetup.map((setup, i) =>
                i === index ? { ...setup, priceConversionUnit: formattedValue, price: formattedValue * 5 } : setup
            )
        );
    };
    const handlePrice = (index, value) => {
        const formattedValue = value.match(/^\d*\.?\d{0,2}$/) ? value : usageSetup[index].price;

        setUsageSetup((prevUsageSetup) =>
            prevUsageSetup.map((setup, i) =>
                i === index ? { ...setup, price: formattedValue,  priceConversionUnit: 0} : setup
            )
        );
    };

    const handleUseSysCalc = (index, checked) => {
        setUsageSetup((prevUsageSetup) =>
            prevUsageSetup.map((setup, i) =>
                i === index ? { ...setup, useSysCalc: checked } : setup
            )
        );
    };

    //Use Effect Related 
    useEffect(() => {
        if (values.asrSupplyId) {
            fetchCropSeason();
            fetchGeneticTypeList();
            fetchUsageTypeList();
            fetchVarietyList();
            fetchUsageSetup();
            fetchFundingGroupList();
            if (image != "" && image != undefined && image != null) {
                console.log("image", typeof image)
                callImageBase64Api();
            }
        }
    }, [values.asrSupplyId]);

    //API Related
    const fetchCropSeason = async () => {
        try {
            const [cropSeasonResponse] = await Promise.all([
                AgriculturalSuppliersRegService.getCropSeasonAPI(),
            ]);

            setCropSeasonOptions(cropSeasonResponse.data.response.cropSeason.map(item => ({
                label: `${item.cropYear.toString()}/${item.season}`,
                value: `${item.cropYear.toString()}/${item.season}`,
                cropSeasonId: item.cropSeasonId,// Assuming there's an id field      
            })));

        } catch (error) {
            console.error("Error fetching Crop Season data:", error);
        }
    };

    const fetchGeneticTypeList = async () => {
        try {
            const geneticResponse = await AgriculturalSuppliersRegService.getgeneticTypeAPI();

            const formatgeneticResponse = geneticResponse.data.response.GeneticTypes.map((item) => ({
                geneticTypeId: item.gtGeneticTypeId,
                geneticType: item.gtGeneticDescription,
                gtGeneticAbbreviation: item.gtGeneticAbbreviation,
                agricultureSupplyGeneticTypeId: "",
                isDelete: false,
                createdByUser: "",
                modifiedByUser: ""
            }))
            console.log("Genetic Response", formatgeneticResponse)
            setGeneticTypeMenu(formatgeneticResponse)
        } catch (error) {
            console.error("Error fetching Crop Season data:", error);
        }
    };
    const fetchUsageTypeList = async () => {
        try {
            const usageResponse = await AgriculturalSuppliersRegService.getUsageTypeAPI();

            const formatusageResponse = usageResponse.data.response.map((item) => ({
                usageTypeId: item.usageTypeId,
                usageType: item.usageType,
                agricultureSupplyUsageTypeId: "",
                isDelete: false,
                // createdByUser: "",
                // modifiedByUser: ""
            }))
            console.log("UsageType Response", formatusageResponse)
            setUsageTypeMenu(formatusageResponse)
        } catch (error) {
            console.error("Error fetching Crop Season data:", error);
        }
    };
    const fetchVarietyList = async () => {
        try {
            const varietyResponse = await AgriculturalSuppliersRegService.getVarietyAPI();

            const formatvarietyResponse = varietyResponse.data.response?.["Variety Registration"].map((item) => ({
                varietyId: item.vrVarietyId,
                variety: item.vrVariety,
                gtGeneticTypeId: item.gtGeneticTypeId
            }))
            console.log("Variety Response", formatvarietyResponse)
            setVarietyList(formatvarietyResponse)
        } catch (error) {
            console.error("Error fetching Crop Season data:", error);
        }
    };
    const fetchFundingGroupList = async () => {
        try {
            const fundingGroupResponse = await AgriculturalSuppliersRegService.getFundingGroupAPI();

            const formattedResponse = fundingGroupResponse.data.response?.fundingGroups.map((item) => {
                return item.investments; // Extracting the investments array from each fundingGroup
            });

            // Flattening the investments arrays and ensuring uniqueness based on `investmentId`
            const uniqueInvestments = [
                ...new Map(
                    formattedResponse.flat().map((investment) => [investment.investmentId, investment])
                ).values(),
            ];

            const requiredResponse = uniqueInvestments.map((item) => ({
                specialFundingId: item.investmentId,
                specialFunding: item.investmentName
            }))
            console.log("Unique Investments", requiredResponse);
            setFundingInvestmentList(requiredResponse)
        } catch (error) {
            console.error("Error fetching Crop Season data:", error);
        }
    };
    const fetchUsageSetup = async () => {
        try {
            const usageSetupResponse = await AgriculturalSuppliersRegService.getUsageSetupAPI(values.asrSupplyId);


            const formattedUsageSetup = (usageSetupResponse.data.response?.usageInfo || []).map((item) => ({
                createdDateTimeUTC: item.createdDateTimeUTC || "",
                createdByUser: item.createdByUser || "",
                modifiedDateTimeUTC: item.modifiedDateTimeUTC || "",
                modifiedByUser: item.modifiedByUser || "Jnana",
                cropSeasonId: item.cropSeasonId,
                geneticType: item.geneticType || [],
                asuInfoId: item.asuInfoId || "",
                usageType: item.usageType || [],
                recommendationType: item.recommendationType || "",
                minQuantity: item.minimumQuantity || 0,
                maxQuantity: item.maximumQuantity || 0,
                variety: {
                    variety: item.varietyDescription || "",
                    varietyId: item.varietyId || ""
                },
                varietyId: item.varietyId || "",
                minQuantityToOrder: item.minimumQuantityToOrder || 0,
                controlledByLimit: item.controlledByLimit || false,
                yearsToPay: item.yearsToPay || 0,
                specialFunding: {
                    specialFunding: item.investment,
                    specialFundingId: item.investmentID
                },
                specialFundingId: item.investmentID || "",
                priceConversionUnit: item.priceConversionUnit || 0,
                price: item.price || 0,
                useSysCalc: item.useSystemCalculator || false,
                isDelete: item.isDelete || false
            }));

            console.log("getUsageSetup Response", formattedUsageSetup)
            setUsageSetup(formattedUsageSetup)
        } catch (error) {
            console.error("Error fetching Crop Season data:", error);
        }
    };

    const [errors, setErrors] = useState({});
    //Submit
    const handleSubmit = async () => {
        try {
            const schemaValidationErrors = {};
            const filterUsageSetup = usageSetup.map((setup) => {
                return {
                    ...setup,
                    geneticType: setup.geneticType.filter((genetic) => !genetic.isDelete),
                    usageType: setup.usageType.filter((usage) => !usage.isDelete),
                };
            });
            console.log("Filter usage setup1", filterUsageSetup)
            console.log("Filter usage setup2", usageSetup)
            try {
                await usageSetupSchema.validate(filterUsageSetup, {
                    abortEarly: false,
                    context: { asrThirdPartyAgriculturalSupply: values.asrThirdPartyAgriculturalSupply },
                });
            } catch (err) {
                err?.inner?.forEach((error) => {
                    const [index, field] = error.path.split(".");
                    schemaValidationErrors[`${index}.${field}`] = error.message;
                });
            }
            console.log("errors", schemaValidationErrors)
            if (Object.keys(schemaValidationErrors).length > 0) {
                setErrors(schemaValidationErrors);
                // setMessage("Validation errors occurred");
                // setSeverity("error");
                // setOpenAlert(true);
                return;
            }
            setErrors(schemaValidationErrors)

            const currentDateTime = new Date().toISOString();
            const currentUser = "currentUser"; // Replace with actual user info

            // Construct the final payload
            const payload = {
                asrSupplyId: values.asrSupplyId,
                asrSupplyName: values.asrSupplyName,
                asrCode: values.asrCode,
                sapCode: values.sapCode,
                unlockedforOffSeason: values.unlockedforOffSeason,
                notConfigured: values.notConfigured,
                displayName: values.displayName,
                typeOfUseId: values.typeOfUseId,
                supplyTypeId: values.supplyTypeId,
                asrUnitOfMeasurement: values.asrUnitOfMeasurement,
                blockedforBilling: values.blockedforBilling,
                asrThirdPartyAgriculturalSupply: values.asrThirdPartyAgriculturalSupply,
                isDelete: values.isDelete,
                agriculturalInputGroup: values.agriculturalInputGroup,
                createdDateTimeUTC: currentDateTime,
                createdByUser: currentUser,
                modifiedDateTimeUTC: currentDateTime,
                image: files.length > 0 ? files[0].fileUrl : "",
                modifiedByUser: currentUser,
                applicationPhase: values.applicationPhase,
                usageInfo: usageSetup.map((usage) => ({
                    createdDateTimeUTC: usage.createdDateTimeUTC || "",
                    createdByUser: usage.createdByUser || "",
                    modifiedDateTimeUTC: usage.modifiedDateTimeUTC || "",
                    modifiedByUser: usage.modifiedByUser || "Jnana",
                    cropSeasonId: usage.cropSeasonId || cropSeasonId,
                    geneticType: usage.geneticType.map((gt) => ({
                        createdDateTimeUTC: "",
                        createdByUser: "",
                        modifiedDateTimeUTC: "",
                        modifiedByUser: "",
                        agricultureSupplyGeneticTypeId: gt.agricultureSupplyGeneticTypeId,
                        geneticTypeId: gt.geneticTypeId,
                        geneticType: gt.geneticType,
                        isDelete: gt.isDelete || false,
                    })),
                    usageType: usage.usageType.map((ut) => ({
                        // createdDateTimeUTC: "",
                        // createdByUser: "",
                        // modifiedDateTimeUTC: "",
                        // modifiedByUser: "",
                        agricultureSupplyUsageTypeId: ut.agricultureSupplyUsageTypeId,
                        usageTypeId: ut.usageTypeId,
                        usageType: ut.usageType,
                        isDelete: ut.isDelete || false,
                    })),
                    // usageType: [],
                    asuInfoId: usage.asuInfoId || "",
                    recommendationType: usage.recommendationType || "",
                    minimumQuantity: usage.minQuantity || 0,
                    maximumQuantity: usage.maxQuantity || 0,
                    varietyId: usage.varietyId || "",
                    minimumQuantityToOrder: usage.minQuantityToOrder || 0,
                    controlledByLimit: usage.controlledByLimit || false,
                    yearsToPay: usage.yearsToPay || 0,
                    investmentID: usage.specialFundingId || "",
                    priceConversionUnit: usage.priceConversionUnit || 0,
                    price: usage.price || 0,
                    useSystemCalculator: usage.useSysCalc || false,
                    isDelete: usage.isDelete || false
                }))
            }

            // Debug log
            console.log('Sending payload to API:', payload);

            // Make the API call
            const response = await AgriculturalSuppliersRegService.putAgriSupplyRegAPI(payload);
            if (response.status === 200) {
                setMessage(t("agriSupplyReg.putSuccess"));
                setSeverity("success");
                setOpenAlert(true);
                navigate("/agriculturalSuppliesRegistrationList");
            }


        } catch (error) {
            console.error("Error saving data:", error);
            setMessage(error.response.data.message || "Error saving Agricultural Supplies Registration targets");
            setSeverity("error");
            setOpenAlert(true);
        } finally {
            // setLoading(false);
        }
    };
    const isImageEmpty = files.length === 0 && (values?.image === undefined || values?.image === "");

    return (
        <Layout
            title={t("agriSupplyReg.editPageTitle")}
            background="#ffffff"
        >
            <Box sx={{ paddingBottom: 20 }}>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        gap: 1
                    }}
                >
                    <Box
                        sx={{
                            // height: "30vh",
                            width: "20vw",
                            borderRadius: 4,
                            backgroundColor: "#EFEFEF",
                            padding: 2,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: 1,
                            }}
                        >
                            <ArrowDropDownIcon sx={{ marginRight: 1, }} />
                            <Typography fontWeight={600} color="#666666">{t("agriSupplyReg.image")}</Typography>
                        </Box>

                        {/* Inner Upload Box */}
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                                border: "1px dashed gray",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 2,
                                backgroundColor: "#FFFFFF",
                                cursor: !isImageEmpty ? "default" : "pointer",
                                "&:hover": {
                                    backgroundColor: !isImageEmpty ? "inherit" : "#0000003B",
                                },
                            }}
                            onClick={isImageEmpty ? () => setUploadImageModal(true) : undefined}
                        >
                            {files.length === 0 &&
                                (values?.image === undefined ||
                                    values?.image === "") ? (
                                <>
                                    <IconButton
                                        sx={{
                                            color: "gray",
                                            fontSize: "2rem",
                                            pointerEvents: "none",
                                        }}
                                    >
                                        <AddIcon fontSize="large" />
                                    </IconButton>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: "gray",
                                        }}
                                    >
                                        Upload
                                    </Typography>
                                </>
                            ) :
                                (
                                    <Box
                                        sx={{
                                            position: "relative",
                                            width: "100%",
                                            height: "100%",
                                            "&:hover .delete-icon": {
                                                display: "flex",
                                            },
                                        }}
                                    >
                                        {values?.image && imageBase64 != "" ? (
                                            <img
                                                src={`data:image/jpeg;base64,${imageBase64}`}
                                                alt="Preview"
                                                style={{ width: "100%", height: "100%", objectFit: "contain", maxHeight: "50vh" }}
                                            />
                                        ) : (
                                            <img
                                                src={imageFile}
                                                alt="Preview"
                                                style={{ width: "100%", height: "100%", objectFit: "contain", maxHeight: "50vh" }}
                                            />
                                        )
                                        }

                                        <Box
                                            className="delete-icon"
                                            sx={{
                                                display: "none",
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translate(-50%, -50%)",
                                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                borderRadius: "50%",
                                                padding: 1,
                                                cursor: "pointer",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent triggering the parent onClick
                                                handleImageDelete(); // Add your delete logic here
                                            }}
                                        >
                                            <img
                                                src={deleteIconNew}
                                                alt="Delete"
                                                style={{
                                                    width: "24px",
                                                    height: "24px",
                                                    objectFit: "contain",
                                                    pointerEvents: "none", // Prevent interfering with the click event
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                )
                            }

                        </Box>
                    </Box>
                    <Box
                        sx={{
                            borderRadius: 4,
                            backgroundColor: "#EFEFEF",
                            padding: 2,
                            display: "flex",
                            alignItems: "flex-start",
                            width: "100%"
                        }}
                    >
                        <Grid container spacing={2} alignItems="center">
                            <Grid item md={12}>
                                <Box
                                    sx={{
                                        display: "flex",
                                    }}
                                >
                                    <ArrowDropDownIcon sx={{ marginRight: 1, }} />
                                    <Typography fontWeight={600} color="#666666">{t("agriSupplyReg.legSysInfo")}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={2.3}>
                                <FormControl fullWidth>
                                    <TextField
                                        name="code"
                                        label={t("agriSupplyReg.code")}
                                        value={values?.asrCode}
                                        disabled={true}
                                        fullWidth
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#131619", //Adjust text color here
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                padding: "7px",
                                                minHeight: "50px",
                                                "&.MuiOutlinedInput-root.Mui-disabled": {
                                                    "& fieldset": {
                                                        borderColor: "gray",
                                                        borderWidth: "1px",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "gray",
                                                        borderWidth: "1px",
                                                    },
                                                },
                                            },
                                            "& .MuiInputLabel-root": {
                                                lineHeight: "1.2em",
                                                "&.Mui-disabled": {
                                                    color: "#3B454F", // Label color when disabled
                                                },
                                            },
                                        }}
                                        InputProps={{
                                            style: { height: "46px" },
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={2.3}>
                                <FormControl fullWidth>
                                    <TextField
                                        name="sapCode"
                                        label={t("agriculturalSupplyUnlock.sapCode")}
                                        value={values?.sapCode}
                                        disabled={true}
                                        fullWidth
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#131619", //Adjust text color here
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                padding: "7px",
                                                minHeight: "50px",
                                                "&.MuiOutlinedInput-root.Mui-disabled": {
                                                    "& fieldset": {
                                                        borderColor: "gray",
                                                        borderWidth: "1px",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "gray",
                                                        borderWidth: "1px",
                                                    },
                                                },
                                            },
                                            "& .MuiInputLabel-root": {
                                                lineHeight: "1.2em",
                                                "&.Mui-disabled": {
                                                    color: "#3B454F", // Label color when disabled
                                                },
                                            },
                                        }}
                                        InputProps={{
                                            style: { height: "46px" },
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4.4}>
                                <FormControl fullWidth>
                                    <TextField
                                        name="sapCode"
                                        label={t("agriSupplyReg.name")}
                                        value={values?.asrSupplyName}
                                        disabled={true}
                                        fullWidth
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#131619", //Adjust text color here
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                padding: "7px",
                                                minHeight: "50px",
                                                "&.MuiOutlinedInput-root.Mui-disabled": {
                                                    "& fieldset": {
                                                        borderColor: "gray",
                                                        borderWidth: "1px",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "gray",
                                                        borderWidth: "1px",
                                                    },
                                                },
                                            },
                                            "& .MuiInputLabel-root": {
                                                lineHeight: "1.2em",
                                                "&.Mui-disabled": {
                                                    color: "#3B454F", // Label color when disabled
                                                },
                                            },
                                        }}
                                        InputProps={{
                                            style: { height: "46px" },
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <TextField
                                        name="sapCode"
                                        label={t("agriSupplyReg.uom")}
                                        value={values?.asrUnitOfMeasurement}
                                        disabled={true}
                                        fullWidth
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#131619", //Adjust text color here
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                padding: "7px",
                                                minHeight: "50px",
                                                "&.MuiOutlinedInput-root.Mui-disabled": {
                                                    "& fieldset": {
                                                        borderColor: "gray",
                                                        borderWidth: "1px",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "gray",
                                                        borderWidth: "1px",
                                                    },
                                                },
                                            },
                                            "& .MuiInputLabel-root": {
                                                lineHeight: "1.2em",
                                                "&.Mui-disabled": {
                                                    color: "#3B454F", // Label color when disabled
                                                },
                                            },
                                        }}
                                        InputProps={{
                                            style: { height: "46px" },
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                            </Grid>
                            {/* second row */}
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <TextField
                                        name="sapCode"
                                        label={t("agriSupplyReg.supplyType")}
                                        value={values?.supplyTypeName}
                                        disabled={true}
                                        fullWidth
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#131619", //Adjust text color here
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                padding: "7px",
                                                minHeight: "50px",
                                                "&.MuiOutlinedInput-root.Mui-disabled": {
                                                    "& fieldset": {
                                                        borderColor: "gray",
                                                        borderWidth: "1px",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "gray",
                                                        borderWidth: "1px",
                                                    },
                                                },
                                            },
                                            "& .MuiInputLabel-root": {
                                                lineHeight: "1.2em",
                                                "&.Mui-disabled": {
                                                    color: "#3B454F", // Label color when disabled
                                                },
                                            },
                                        }}
                                        InputProps={{
                                            style: { height: "46px" },
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <TextField
                                        name="sapCode"
                                        label={t("agriSupplyReg.typeOfUse")}
                                        value={values?.typeOfUse}
                                        disabled={true}
                                        fullWidth
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#131619", //Adjust text color here
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                padding: "7px",
                                                minHeight: "50px",
                                                "&.MuiOutlinedInput-root.Mui-disabled": {
                                                    "& fieldset": {
                                                        borderColor: "gray",
                                                        borderWidth: "1px",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "gray",
                                                        borderWidth: "1px",
                                                    },
                                                },
                                            },
                                            "& .MuiInputLabel-root": {
                                                lineHeight: "1.2em",
                                                "&.Mui-disabled": {
                                                    color: "#3B454F", // Label color when disabled
                                                },
                                            },
                                        }}
                                        InputProps={{
                                            style: { height: "46px" },
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <TextField
                                        name="sapCode"
                                        label={t("agriSupplyReg.agriInputGroup")}
                                        value={values?.agriculturalInputGroup}
                                        disabled={true}
                                        fullWidth
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#131619", //Adjust text color here
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                padding: "7px",
                                                minHeight: "50px",
                                                "&.MuiOutlinedInput-root.Mui-disabled": {
                                                    "& fieldset": {
                                                        borderColor: "gray",
                                                        borderWidth: "1px",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "gray",
                                                        borderWidth: "1px",
                                                    },
                                                },
                                            },
                                            "& .MuiInputLabel-root": {
                                                lineHeight: "1.2em",
                                                "&.Mui-disabled": {
                                                    color: "#3B454F", // Label color when disabled
                                                },
                                            },
                                        }}
                                        InputProps={{
                                            style: { height: "46px" },
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <TextField
                                        name="sapCode"
                                        label={t("agriSupplyReg.seedType")}
                                        value={values?.seedType}
                                        disabled={true}
                                        fullWidth
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#131619", //Adjust text color here
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                padding: "7px",
                                                minHeight: "50px",
                                                "&.MuiOutlinedInput-root.Mui-disabled": {
                                                    "& fieldset": {
                                                        borderColor: "gray",
                                                        borderWidth: "1px",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "gray",
                                                        borderWidth: "1px",
                                                    },
                                                },
                                            },
                                            "& .MuiInputLabel-root": {
                                                lineHeight: "1.2em",
                                                "&.Mui-disabled": {
                                                    color: "#3B454F", // Label color when disabled
                                                },
                                            },
                                        }}
                                        InputProps={{
                                            style: { height: "46px" },
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={12}>
                                <Box sx={{ backgroundColor: "#FFFFFF", margin: "1 0", paddingLeft: 1, borderRadius: 2, textTransform: 'uppercase' }}>
                                    <Typography fontWeight={600} color="#666666">{t("agriSupplyReg.lockInfo")}</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={3}>
                                <FormControl fullWidth>
                                    <TextField
                                        name="sapCode"
                                        label={t("agriSupplyReg.bfb")}
                                        value={values?.blockedforBilling === true ? t("agriSupplyReg.yes") : t("agriSupplyReg.no")}
                                        disabled={true}
                                        fullWidth
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#131619", //Adjust text color here
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                padding: "7px",
                                                minHeight: "50px",
                                                "&.MuiOutlinedInput-root.Mui-disabled": {
                                                    "& fieldset": {
                                                        borderColor: "gray",
                                                        borderWidth: "1px",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "gray",
                                                        borderWidth: "1px",
                                                    },
                                                },
                                            },
                                            "& .MuiInputLabel-root": {
                                                lineHeight: "1.2em",
                                                "&.Mui-disabled": {
                                                    color: "#3B454F", // Label color when disabled
                                                },
                                            },
                                        }}
                                        InputProps={{
                                            style: { height: "46px" },
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={3}>
                                <FormControl fullWidth>
                                    <TextField
                                        name="sapCode"
                                        label={t("agriSupplyReg.unlockedOffSeason")}
                                        value={values?.unlockedforOffSeason === true ? t("agriSupplyReg.yes") : t("agriSupplyReg.no")}
                                        disabled={true}
                                        fullWidth
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#131619", //Adjust text color here
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                padding: "7px",
                                                minHeight: "50px",
                                                "&.MuiOutlinedInput-root.Mui-disabled": {
                                                    "& fieldset": {
                                                        borderColor: "gray",
                                                        borderWidth: "1px",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "gray",
                                                        borderWidth: "1px",
                                                    },
                                                },
                                            },
                                            "& .MuiInputLabel-root": {
                                                lineHeight: "1.2em",
                                                "&.Mui-disabled": {
                                                    color: "#3B454F", // Label color when disabled
                                                },
                                            },
                                        }}
                                        InputProps={{
                                            style: { height: "46px" },
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                {/* Additional Information */}
                <Box
                    sx={{
                        borderRadius: 4,
                        backgroundColor: "#EFEFEF",
                        padding: 2,
                        display: "flex",
                        alignItems: "flex-start",
                        // width: "100%",
                        marginTop: 2
                    }}
                >
                    <Grid container spacing={2} alignItems="center">
                        <Grid item md={12}>
                            <Box
                                sx={{
                                    display: "flex",
                                }}
                            >
                                <ArrowDropDownIcon sx={{ marginRight: 1, }} />
                                <Typography fontWeight={600} color="#666666">{t("agriSupplyReg.addInfo")}</Typography>
                            </Box>
                        </Grid>
                        <Grid item md={10}>
                            <FormControl fullWidth>
                                <TextField
                                    name="sapCode"
                                    label={t("agriSupplyReg.displayName")}
                                    value={values?.displayName}
                                    fullWidth
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            padding: "7px",
                                            minHeight: "50px",
                                        },
                                        "& .MuiInputLabel-root": {
                                            lineHeight: "1.2em",
                                        },
                                    }}
                                    InputProps={{
                                        style: { height: "46px" },
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(event) => handleDisplayNameChange(event.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2}>
                            <FormControl component="fieldset">
                                <FormLabel
                                    sx={{
                                        fontWeight: "500",
                                        color: "#5A646E",
                                        mb: 1.5,
                                        ml: 0.5,
                                        "&.Mui-focused": { color: "#5A646E" },
                                        "&:hover": { color: "#5A646E" },
                                    }}
                                >
                                    {t("agriSupplyReg.3rdPsupply")}
                                </FormLabel>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "0.4rem",
                                        ml: 5,
                                    }}
                                >
                                    {/* "No" label on the left */}
                                    <Typography
                                        sx={{
                                            fontWeight: "500",
                                            color: "#5A646E",
                                        }}
                                    >
                                        {t("no")}
                                    </Typography>

                                    {/* Switch */}
                                    <RedSwitch
                                        sx={{
                                            marginRight: "11px",
                                        }}
                                        checked={values?.asrThirdPartyAgriculturalSupply}
                                        onChange={handleThirdPartySupplyChange}
                                        name="asrThirdPartyAgriculturalSupply"
                                        color="primary"
                                        disabled={edit === false}
                                    />

                                    {/* "Yes" label on the right */}
                                    <Typography
                                        sx={{
                                            fontWeight: "500",
                                            color: "#5A646E",
                                        }}
                                    >
                                        {t("yes")}
                                    </Typography>
                                </Box>
                            </FormControl>

                        </Grid>
                    </Grid>
                </Box>
                {/* Usage Info */}
                <Divider title={t('agriSupplyReg.usageInfo')} />
                {/* Mnadatory Filters */}
                <Box
                    sx={{
                        borderRadius: 4,
                        backgroundColor: "#EFEFEF",
                        padding: 2,
                        display: "flex",
                        alignItems: "flex-start",
                        // width: "100%",
                        marginTop: 2
                    }}
                >
                    <Grid container spacing={2} alignItems="center">
                        <Grid item md={12}>
                            <Box
                                sx={{
                                    display: "flex",
                                }}
                            >
                                <ArrowDropDownIcon sx={{ marginRight: 1, }} />
                                <Typography fontWeight={600} color="#666666">{t("agriSupplyReg.mandatoryFilter")}</Typography>
                            </Box>
                        </Grid>
                        <Grid item md={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={cropSeasonOptions.find(option => option.value === cropSeason) || null}
                                    disableClearable={false}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            setCropSeason(newValue.value);
                                            setCropSeasonId(newValue.cropSeasonId); // This will trigger useEffect
                                        } else {
                                            setCropSeason("");
                                            setCropSeasonId(""); // This will trigger useEffect
                                        }
                                    }}
                                    options={cropSeasonOptions}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                            label={t('investmentGroupLimits.csSearchField')}
                                            fullWidth
                                            placeholder='Select an Option'
                                            required={true}
                                            InputLabelProps={{ shrink: true }}
                                            sx={{
                                                '& .MuiInputBase-input::placeholder': {
                                                    opacity: 1,
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        {/* <Grid item xs /> */}
                        <Grid item md={6.5} />
                        {/* Second Grid Item */}
                        <Grid item md={1.5}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}>
                                <Button
                                    sx={{
                                        backgroundColor: "#004F9F",
                                        "&.Mui-disabled": {
                                            backgroundColor: "#0000003B",
                                            color: "#A1A1A1",
                                        }
                                    }}
                                    variant="contained"
                                    onClick={handleAddNew}
                                    disabled={cropSeason ? false : true}
                                >
                                    {t("addNew")}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                {/* Usage Setup */}
                {cropSeason && (
                    usageSetup.map((setup, index) => !setup.isDelete && setup.cropSeasonId === cropSeasonId && (
                        <Box
                            key={index}
                            sx={{
                                borderRadius: 4,
                                backgroundColor: "#EFEFEF",
                                padding: 2,
                                display: "flex",
                                alignItems: "flex-start",
                                // width: "100%",
                                marginTop: 2
                            }}
                        >
                            <Grid container spacing={2} alignItems="center">
                                <Grid item md={11}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                        }}
                                    >
                                        <ArrowDropDownIcon sx={{ marginRight: 1, }} />
                                        <Typography fontWeight={600} color="#666666">{t("agriSupplyReg.usageSetup")}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={1}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <Button
                                            onClick={() => handleDeleteUsageSetup(index)}
                                        >
                                            <img src={_ButtonDelete_} alt="Delete" />
                                        </Button>
                                    </Box>
                                </Grid>
                                {/* Genetic Type */}
                                {!values.asrThirdPartyAgriculturalSupply && (
                                    <Grid item md={4}>
                                        {/* multiSelect */}
                                        <FormControl
                                            sx={{ width: "100%" }}
                                        // error={!!errors.tobaccoType}
                                        >
                                            <InputLabel
                                                id={`genetic-type-${index}`}
                                                sx={{
                                                    color: "#112a63",
                                                    "&.Mui-focused": { color: "#112a63" },
                                                }}
                                                shrink={true}
                                            >
                                                {t("agriSupplyReg.genericType")}
                                                <span style={{ color: "red" }}> *</span>
                                            </InputLabel>
                                            <Select
                                                labelId={`genetic-type-${index}`}
                                                id="demo-multiple-checkbox"
                                                multiple
                                                isMulti
                                                value={setup.geneticType || []}
                                                required
                                                onChange={(event) => handleGeneticType(index, event)}
                                                input={<OutlinedInput label="Genetic Type" />}
                                                renderValue={(selected) => (
                                                    selected.length ? (
                                                        <div style={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
                                                            {selected.map((value) => (!value.isDelete) && (
                                                                <Chip
                                                                    key={value.geneticTypeId}
                                                                    // disabled={!newMode && !edit}
                                                                    label={value.geneticType}
                                                                    onDelete={() => handleGeneticTypeChipDelete(index, value)}
                                                                    deleteIcon={
                                                                        <IconButton
                                                                            size="small"
                                                                            sx={{
                                                                                backgroundColor: "rgba(0, 0, 0, 0.18)",
                                                                                padding: "1px",
                                                                                "&:hover": {
                                                                                    backgroundColor: "rgba(0, 0, 0, 0.38)", // Slightly lighter shade on hover
                                                                                },
                                                                            }}
                                                                        >
                                                                            <CloseIcon
                                                                                fontSize="small"
                                                                                sx={{ fontSize: "19px" }}
                                                                                onMouseDown={(event) => event.stopPropagation()}
                                                                            />
                                                                        </IconButton>
                                                                    }
                                                                    sx={{
                                                                        "&.Mui-disabled": {
                                                                            opacity: 1,
                                                                            backgroundColor: "#e0e0e0",
                                                                            color: "#333333",
                                                                        },
                                                                    }}
                                                                />
                                                            ))}
                                                        </div>
                                                    ) : "Select an option"
                                                )}
                                                MenuProps={MenuProps} //importing from utils
                                                variant="outlined"
                                                displayEmpty
                                                // disabled={!newMode && !edit}
                                                sx={{
                                                    "&.Mui-disabled fieldset": {
                                                        borderColor: "gray !important",
                                                        borderWidth: "1px !important",
                                                    },
                                                    "&.Mui-disabled:hover fieldset": {
                                                        borderColor: "gray",
                                                        borderWidth: "1px",
                                                    },
                                                }}
                                            >
                                                {geneticTypeMenu.map((genetic, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={genetic}
                                                        sx={{
                                                            borderLeft: setup.geneticType?.some(
                                                                (item) => item.geneticTypeId === genetic.geneticTypeId && item.isDelete === false
                                                            )
                                                                ? "4px solid #004F9F"
                                                                : "none",
                                                            paddingLeft: setup.geneticType?.some(
                                                                (item) => item.geneticTypeId === genetic.geneticTypeId && item.isDelete === false
                                                            )
                                                                ? "8px"
                                                                : "12px",
                                                        }}
                                                    >
                                                        <ListItemText
                                                            primary={`${genetic.gtGeneticAbbreviation}-${genetic.geneticType}`}
                                                        />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText
                                                error={Boolean(errors[`[${index}].geneticType`])}
                                                sx={{
                                                    minHeight: "2em", // Ensures space is reserved
                                                }}
                                            >
                                                {errors[`[${index}].geneticType`] || " "}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                )}
                                <Grid item md={4}>
                                    {/* multiSelect Usage type*/}
                                    <FormControl
                                        sx={{ width: "100%" }}
                                    // error={!!errors.tobaccoType}
                                    >
                                        <InputLabel
                                            id={`usage-type-${index}`}
                                            sx={{
                                                color: "#112a63",
                                                "&.Mui-focused": { color: "#112a63" },
                                            }}
                                            shrink={true}
                                        >
                                            {t("agriSupplyReg.usageType")}
                                            <span style={{ color: "red" }}> *</span>
                                        </InputLabel>
                                        <Select
                                            labelId={`usage-type-${index}`}
                                            id="demo-multiple-checkbox"
                                            multiple
                                            isMulti
                                            value={setup.usageType || []}
                                            required
                                            onChange={(event) => handleUsageType(index, event)}
                                            input={<OutlinedInput label="USage Type" />}
                                            renderValue={(selected) => (
                                                selected.length ? (
                                                    <div style={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
                                                        {selected.map((value) => (!value.isDelete) && (
                                                            <Chip
                                                                key={value.usageTypeId}
                                                                // disabled={!newMode && !edit}
                                                                label={value.usageType}
                                                                onDelete={() => handleUsageTypeChipDelete(index, value)}
                                                                deleteIcon={
                                                                    <IconButton
                                                                        size="small"
                                                                        sx={{
                                                                            backgroundColor: "rgba(0, 0, 0, 0.18)",
                                                                            padding: "1px",
                                                                            "&:hover": {
                                                                                backgroundColor: "rgba(0, 0, 0, 0.38)", // Slightly lighter shade on hover
                                                                            },
                                                                        }}
                                                                    >
                                                                        <CloseIcon
                                                                            fontSize="small"
                                                                            sx={{ fontSize: "19px" }}
                                                                            onMouseDown={(event) => event.stopPropagation()}
                                                                        />
                                                                    </IconButton>
                                                                }
                                                                sx={{
                                                                    "&.Mui-disabled": {
                                                                        opacity: 1,
                                                                        backgroundColor: "#e0e0e0",
                                                                        color: "#333333",
                                                                    },
                                                                }}
                                                            />
                                                        ))}
                                                    </div>
                                                ) : "Select an option"
                                            )}
                                            MenuProps={MenuProps} //importing from utils
                                            variant="outlined"
                                            displayEmpty
                                            // disabled={!newMode && !edit}
                                            sx={{
                                                "&.Mui-disabled fieldset": {
                                                    borderColor: "gray !important",
                                                    borderWidth: "1px !important",
                                                },
                                                "&.Mui-disabled:hover fieldset": {
                                                    borderColor: "gray",
                                                    borderWidth: "1px",
                                                },
                                            }}
                                        >
                                            {usageTypeMenu.map((usage, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={usage}
                                                    sx={{
                                                        borderLeft: setup.usageType?.some(
                                                            (item) => item.usageTypeId === usage.usageTypeId && item.isDelete === false
                                                        )
                                                            ? "4px solid #004F9F"
                                                            : "none",
                                                        paddingLeft: setup.usageType?.some(
                                                            (item) => item.usageTypeId === usage.usageTypeId && item.isDelete === false
                                                        )
                                                            ? "8px"
                                                            : "12px",
                                                    }}
                                                >
                                                    <ListItemText
                                                        primary={`${usage.usageType}`}
                                                    />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText
                                            error={Boolean(errors[`[${index}].usageType`])}
                                            sx={{
                                                minHeight: "2em", // Ensures space is reserved
                                            }}
                                        >
                                            {errors[`[${index}].usageType`] || " "}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item md={6} />
                                {/* Recommendation Info */}
                                {!values.asrThirdPartyAgriculturalSupply && (
                                    <>
                                        <Grid item md={12}>
                                            <Box sx={{ backgroundColor: "#FFFFFF", margin: "1 0", paddingLeft: 1, borderRadius: 2, textTransform: 'uppercase' }}>
                                                <Typography fontWeight={600} color="#666666">{t("agriSupplyReg.recommendationInfo")}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item md={4}>
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    value={setup.recommendationType}
                                                    disableClearable={true}
                                                    onChange={(event, newValue) => handleRecommendationTypeChange(index, newValue)}
                                                    options={["Type 1", "Type 2", "Type"]}
                                                    renderInput={(params) => (
                                                        <TextField {...params}
                                                            label={t('agriSupplyReg.recommendationType')}
                                                            fullWidth
                                                            placeholder='Select an Option'
                                                            required={true}
                                                            InputLabelProps={{ shrink: true }}
                                                            sx={{
                                                                '& .MuiInputBase-input::placeholder': {
                                                                    opacity: 1,
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <FormHelperText
                                                    error={Boolean(errors[`[${index}].recommendationType`])}
                                                    sx={{
                                                        minHeight: "3.1em", // Ensures space is reserved
                                                    }}
                                                >
                                                    {errors[`[${index}].recommendationType`] || " "}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={4}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="minQuantity"
                                                    label={t("agriSupplyReg.minQuantity")}
                                                    value={setup.minQuantity}
                                                    fullWidth
                                                    sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            padding: "7px",
                                                            minHeight: "50px",
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            lineHeight: "1.2em",
                                                        },
                                                    }}
                                                    InputProps={{
                                                        style: { height: "46px" },
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(event) => handleMinQuantityChange(index, event.target.value)}

                                                />
                                                <FormHelperText
                                                    error={Boolean(errors[`[${index}].minQuantity`])}
                                                    sx={{
                                                        minHeight: "3.1em", // Ensures space is reserved
                                                    }}
                                                >
                                                    {errors[`[${index}].minQuantity`] || " "}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={4}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="maxQuantity"
                                                    label={t("agriSupplyReg.maxQuantity")}
                                                    value={setup.maxQuantity}
                                                    fullWidth
                                                    sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            padding: "7px",
                                                            minHeight: "50px",
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            lineHeight: "1.2em",
                                                        },
                                                    }}
                                                    InputProps={{
                                                        style: { height: "46px" },
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(event) => handleMaxQuantityChange(index, event.target.value)}
                                                />
                                                <FormHelperText
                                                    error={true}
                                                    sx={{
                                                        minHeight: "3.1em", // Ensures space is reserved
                                                    }}
                                                >
                                                    {" "}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </>
                                )}

                                {/* Limits and RelationShip Info  */}
                                <Grid item md={12}>
                                    <Box sx={{ backgroundColor: "#FFFFFF", margin: "1 0", paddingLeft: 1, borderRadius: 2, textTransform: 'uppercase' }}>
                                        <Typography fontWeight={600} color="#666666">{t("agriSupplyReg.limitsRelationInfo")}</Typography>
                                    </Box>
                                </Grid>
                                {!values.asrThirdPartyAgriculturalSupply && (
                                    <Grid item md={4}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                value={setup.variety}
                                                disableClearable={false}
                                                onChange={(event, newValue) => handleVarietyChange(index, newValue)}
                                                options={varietyList
                                                    .filter((variety) =>
                                                        setup.geneticType
                                                            .filter((item) => !item.isDelete) // Exclude geneticTypes where isDelete is true
                                                            .map((item) => item.geneticTypeId)
                                                            .includes(variety.gtGeneticTypeId)
                                                    )
                                                    .map((vr) => ({
                                                        variety: vr.variety,
                                                        varietyId: vr.varietyId,
                                                    }))}
                                                getOptionLabel={(option) => option.variety || ""}
                                                renderInput={(params) => (
                                                    <TextField {...params}
                                                        label={t('agriSupplyReg.variety')}
                                                        fullWidth
                                                        placeholder='Select an Option'
                                                        // required={true}
                                                        InputLabelProps={{ shrink: true }}
                                                        sx={{
                                                            '& .MuiInputBase-input::placeholder': {
                                                                opacity: 1,
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors[`[${index}].minQuantityToOrder`] && (
                                                <FormHelperText error>
                                                    {" "}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                )}
                                <Grid item md={4}>
                                    <FormControl fullWidth>
                                        <TextField
                                            name="sapCode"
                                            label={t("agriSupplyReg.minQuanityOrder")}
                                            value={setup.minQuantityToOrder}
                                            fullWidth
                                            // error={!!errors[`${index}.minQuantityToOrder`]} // Show error state
                                            // helperText={errors[`${index}.minQuantityToOrder`] || " "}
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    padding: "7px",
                                                    minHeight: "50px",
                                                },
                                                "& .MuiInputLabel-root": {
                                                    lineHeight: "1.2em",
                                                },
                                            }}
                                            InputProps={{
                                                style: { height: "46px" },
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={(event) => handleMinQuantityToOrder(index, event.target.value)}
                                        />
                                        {errors[`[${index}].minQuantityToOrder`] && (
                                            <FormHelperText error>
                                                {errors[`[${index}].minQuantityToOrder`]}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                {!values.asrThirdPartyAgriculturalSupply && (
                                    <Grid item md={4}>
                                        <FormControl component="fieldset">
                                            <FormLabel
                                                sx={{
                                                    fontWeight: "500",
                                                    color: "#5A646E",
                                                    mb: 1.5,
                                                    ml: 0.5,
                                                    "&.Mui-focused": { color: "#5A646E" },
                                                    "&:hover": { color: "#5A646E" },
                                                }}
                                            >
                                                {t("agriSupplyReg.controlledLimit")}
                                            </FormLabel>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "0.4rem",
                                                    ml: 10,
                                                }}
                                            >
                                                {/* "No" label on the left */}
                                                <Typography
                                                    sx={{
                                                        fontWeight: "500",
                                                        color: "#5A646E",
                                                    }}
                                                >
                                                    {t("no")}
                                                </Typography>

                                                {/* Switch */}
                                                <RedSwitch
                                                    sx={{
                                                        marginRight: "11px",
                                                    }}
                                                    checked={setup.controlledByLimit}
                                                    onChange={(event) => handleControlledByLimit(index, event.target.checked)}
                                                    name="status"
                                                    color="primary"
                                                    disabled={edit === false}
                                                />

                                                {/* "Yes" label on the right */}
                                                <Typography
                                                    sx={{
                                                        fontWeight: "500",
                                                        color: "#5A646E",
                                                    }}
                                                >
                                                    {t("yes")}
                                                </Typography>
                                            </Box>
                                            {errors[`[${index}].minQuantityToOrder`] && (
                                                <FormHelperText error>
                                                    {" "}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                )}

                                {/* Financial Info */}
                                <Grid item md={12}>
                                    <Box sx={{ backgroundColor: "#FFFFFF", margin: "1 0", paddingLeft: 1, borderRadius: 2, textTransform: 'uppercase' }}>
                                        <Typography fontWeight={600} color="#666666">{t("agriSupplyReg.financialinfo")}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={4}>
                                    <FormControl fullWidth>
                                        <TextField
                                            name="sapCode"
                                            label={t("agriSupplyReg.yearsToPay")}
                                            value={setup.yearsToPay}
                                            fullWidth
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    padding: "7px",
                                                    minHeight: "50px",
                                                },
                                                "& .MuiInputLabel-root": {
                                                    lineHeight: "1.2em",
                                                },
                                            }}
                                            InputProps={{
                                                style: { height: "46px" },
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={(event) => handleYearsToPay(index, event.target.value)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={4}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            value={setup.specialFunding}
                                            disableClearable={true}
                                            onChange={(event, newValue) => handleSpecialFundingChange(index, newValue)}
                                            options={fundingInvestmentList}
                                            getOptionLabel={(option) => option.specialFunding || ""}
                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    label={t('agriSupplyReg.specialFunding')}
                                                    fullWidth
                                                    placeholder='Select an Option'
                                                    // required={true}
                                                    InputLabelProps={{ shrink: true }}
                                                    sx={{
                                                        '& .MuiInputBase-input::placeholder': {
                                                            opacity: 1,
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={4} />

                                {/* Price Info */}
                                {!values.asrThirdPartyAgriculturalSupply && (
                                    <>
                                        <Grid item md={12}>
                                            <Box sx={{ backgroundColor: "#FFFFFF", margin: "1 0", paddingLeft: 1, borderRadius: 2, textTransform: 'uppercase' }}>
                                                <Typography fontWeight={600} color="#666666">{t("agriSupplyReg.priceInfo")}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item md={4}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="sapCode"
                                                    label={t("agriSupplyReg.priceConversionUnit")}
                                                    value={setup.priceConversionUnit}
                                                    disabled={!setup.useSysCalc}
                                                    fullWidth
                                                    sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            padding: "7px",
                                                            minHeight: "50px",
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            lineHeight: "1.2em",
                                                        },
                                                    }}
                                                    InputProps={{
                                                        style: { height: "46px" },
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(event) => handlePriceConversionUnit(index, event.target.value)}

                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={4}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="sapCode"
                                                    label={t("agriSupplyReg.price")}
                                                    value={setup.price}
                                                    disabled={setup.useSysCalc}
                                                    fullWidth
                                                    sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            padding: "7px",
                                                            minHeight: "50px",
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            lineHeight: "1.2em",
                                                        },
                                                    }}
                                                    InputProps={{
                                                        style: { height: "46px" },
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(event) => handlePrice(index, event.target.value)}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={4}>
                                            <FormControl component="fieldset">
                                                <FormLabel
                                                    sx={{
                                                        fontWeight: "500",
                                                        color: "#5A646E",
                                                        mb: 1.5,
                                                        ml: 0.5,
                                                        "&.Mui-focused": { color: "#5A646E" },
                                                        "&:hover": { color: "#5A646E" },
                                                    }}
                                                >
                                                    {t("agriSupplyReg.useSysCalc")}
                                                </FormLabel>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "0.4rem",
                                                        ml: 10,
                                                    }}
                                                >
                                                    {/* "No" label on the left */}
                                                    <Typography
                                                        sx={{
                                                            fontWeight: "500",
                                                            color: "#5A646E",
                                                        }}
                                                    >
                                                        {t("no")}
                                                    </Typography>

                                                    {/* Switch */}
                                                    <RedSwitch
                                                        sx={{
                                                            marginRight: "11px",
                                                        }}
                                                        checked={setup.useSysCalc}
                                                        onChange={(event) => handleUseSysCalc(index, event.target.checked)}
                                                        name="status"
                                                        color="primary"
                                                        disabled={edit === false}
                                                    />

                                                    {/* "Yes" label on the right */}
                                                    <Typography
                                                        sx={{
                                                            fontWeight: "500",
                                                            color: "#5A646E",
                                                        }}
                                                    >
                                                        {t("yes")}
                                                    </Typography>
                                                </Box>
                                            </FormControl>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Box>
                    ))

                )}

                {/* Bottom buttons */}
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mt={3}
                    mb={2.5}
                    sx={{
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: "#ffffff",
                        padding: "10px",
                        boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
                        zIndex: 1000
                    }}
                >
                    {/* Cancel Button - Left bottom corner */}
                    <Button
                        style={{
                            backgroundColor: "#737D86",
                            padding: "6px 16px",
                            borderRadius: "4px",
                        }}
                        variant="contained"
                        onClick={edit == false ? handleDeleteConfirm : handleOpenModal} // Open the modal on click
                        startIcon={<img src={arrowLeft} alt="Arrow" />}
                    >
                        {edit == false ? "Back" : "Cancel"}
                    </Button>

                    {/* Modal confirmation */}
                    <Modal
                        open={open}
                        onClose={handleCloseModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box
                            sx={{
                                backgroundColor: "#fff",
                                borderRadius: "7px",
                                width: "585px",
                                margin: "auto",
                                mt: 25,
                                height: "200px",
                            }}
                        >
                            <Box
                                sx={{
                                    background: "#102A62",
                                    height: "50px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderTopLeftRadius: "7px",
                                    borderTopRightRadius: "7px",
                                }}
                            >
                                <Typography
                                    sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                                >
                                    {t("cancelTitle")}
                                </Typography>
                            </Box>
                            <Box sx={{ p: 2 }}>
                                <Typography
                                    id="modal-modal-description"
                                    sx={{ mt: 2, fontWeight: 400 }}
                                >
                                    {t("cancelMessage")}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "20px",
                                    mb: 1,
                                }}
                            >
                                <Button
                                    onClick={handleCloseModal}
                                    sx={{
                                        background: "#737D86",
                                        width: "100px",
                                        color: "#ffffff",
                                        "&:hover": { background: "#737D86" },
                                    }}
                                >
                                    {t("no")}
                                </Button>
                                <Button
                                    onClick={handleDeleteConfirm}
                                    sx={{
                                        background: "#004F9F",
                                        width: "100px",
                                        color: "#ffffff",
                                        "&:hover": { background: "#004F9F" },
                                    }}
                                >
                                    {t("yes")}
                                </Button>
                            </Box>
                        </Box>
                    </Modal>


                    <Button
                        sx={{
                            padding: "6px 16px",
                            borderRadius: "4px",
                            backgroundColor: "#EF7D00",
                            color: "white",
                            "&:hover": {
                                backgroundColor: "#EF7D00",
                            },
                        }}
                        startIcon={<img src={Save} alt="Right Arrow" />}
                        onClick={handleSubmit}
                        variant="contained"
                    >
                        {"Save"}
                    </Button>

                </Box>
            </Box>

            {/* Upload Image Model */}
            {/* <Modal
                open={uploadImageModal}
                onClose={() => setUploadImageModal(false)}
            >
                <Box
                    sx={{
                        backgroundColor: "#fff",
                        borderRadius: "7px",
                        width: "529px",
                        margin: "auto",
                        mt: 2,
                        maxHeight: "90vh",
                        display: "flex",
                        flexDirection: "column",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <Box
                        sx={{
                            background: "#102A62",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderTopLeftRadius: "7px",
                            borderTopRightRadius: "7px",
                            color: "#fff",
                            pt: 2,
                            pb: 1,
                        }}
                    >
                        {t("agriSupplyReg.upload")}
                    </Box>
                    <Box sx={{ padding: "1rem" }}>
                        <Box
                            sx={{
                                borderRadius: "5px",
                                borderColor: "grey",
                                borderWidth: "1px",
                                borderStyle: "solid",
                                padding: "1rem",
                                marginLeft: 5,
                                marginRight: 5,
                                marginTop: 2,
                                marginBottom: 2,
                            }}
                            onDragOver={onDragOver}
                            onDrop={onDrop}
                        >
                            <input
                                style={{ display: "none" }}
                                ref={inputFileRef}
                                type="file"
                                accept=".png, .jpeg"
                                onChange={(e) => handleFileUpload(e)}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    padding: "1rem",
                                }}
                            >
                                {loading ? (
                                    <CircularProgress sx={{ alignSelf: "center" }} />
                                ) : (
                                    <>
                                        <Typography
                                            sx={{
                                                textAlign: "center",
                                                fontSize: "13px !important",
                                            }}
                                        >
                                            Drag and drop your file here or
                                        </Typography>
                                    </>
                                )}
                            </Box>
                            <Box
                                sx={{
                                    background: "#EFEFEF",
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    padding: "1rem",
                                    marginLeft: 5,
                                    marginRight: 5,
                                }}
                            >
                                <Button
                                    style={{
                                        height: "35px",
                                        color: "white",
                                        background: "#004F9F",
                                        alignSelf: "center",
                                    }}
                                    variant="contained"
                                    onClick={onBtnClick}
                                >
                                    Browse file
                                </Button>
                            </Box>
                        </Box>
                        {previewImage && (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: 2,
                                }}
                            >
                                <img
                                    src={previewImage}
                                    alt="Preview"
                                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                                />
                            </Box>
                        )}
                    </Box>
                    <Box
                        sx={{
                            marginTop: "auto",
                            padding: "1rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Button
                            onClick={() => setUploadImageModal(false)}
                            sx={{
                                background: "#737D86",
                                width: "100px",
                                color: "#ffffff",
                                "&:hover": { background: "#737D86" },
                            }}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal> */}

            <Modal
                open={uploadImageModal}
                onClose={() => setUploadImageModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        backgroundColor: "#fff",
                        borderRadius: "7px",
                        width: "460px",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        height: "400px",
                    }}
                >
                    <Box
                        sx={{
                            background: "#102A62",
                            height: "50px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderTopLeftRadius: "7px",
                            borderTopRightRadius: "7px",
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#ffffff",
                                fontSize: "20px",
                                fontWeight: 500,
                            }}
                        >
                            {t("agriSupplyReg.upload")}
                        </Typography>
                    </Box>

                    {/* Centering the Icon, Typography, and Button */}
                    <Box
                        sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 1, // Space between elements
                        }}
                    >
                        {/* Wrap the icon and text in a Box with a border */}
                        <Box
                            onDragOver={onDragOver}
                            onDrop={onDrop}
                            sx={{
                                border: "4px solid #ACB4BA",
                                borderRadius: "5px",
                                p: 2,
                                paddingLeft: 4,
                                paddingRight: 4,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                            }}
                        >
                            <DescriptionOutlinedIcon
                                sx={{ fontSize: 60, color: "grey" }}
                            />

                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 1, fontWeight: 400, textAlign: "center" }}
                            >
                                {t('SoilAnalysisRegistration.modals.dragDrop')}
                            </Typography>

                            {/* Add light gray background for "OR" and "BROWSE FILE" */}
                            <Box
                                sx={{
                                    background: "#E9EBEE",
                                    width: "80%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    p: 1.5,
                                    mt: 1, // Margin top for spacing
                                }}
                            >
                                <Typography
                                    id="modal-modal-description"
                                    sx={{ fontWeight: 400, textAlign: "center" }}
                                >
                                    {t('SoilAnalysisRegistration.modals.or')}
                                </Typography>

                                <Button
                                    onClick={onBtnClick}
                                    sx={{
                                        background: "#0E2B63",
                                        width: "150px",
                                        color: "white",
                                        mt: 1,
                                        "&:hover": { background: "#0E2B63" },
                                    }}
                                >
                                    {t('SoilAnalysisRegistration.modals.browseFile')}
                                </Button>
                                <input
                                    style={{ display: "none" }}
                                    ref={inputFileRef}
                                    type="file"
                                    accept=".png, .jpeg"
                                    onChange={(e) => handleFileUpload(e)}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "30px",
                            mb: 1,
                        }}
                    >
                        <Button
                            onClick={() => setUploadImageModal(false)}
                            sx={{
                                background: "#737D86",
                                width: "100px",
                                color: "#ffffff",
                                "&:hover": { background: "#737D86" },
                            }}
                        >
                            {t('close')}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Layout>
    )
}

export default AgriculturalSuppliesRegForm