import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from 'react-i18next';
import CultureTable from "./CultureTable";
import CultureRegistrationService from "services/CultureRegistrationService";
import SelectInput from "components/common/SelectInput";

const GetCultureRegistration = () => {
    const { t } = useTranslation();
  const navigate = useNavigate();
  const [cultureData, setCultureData] = useState([]);
  const [filCultureData, setfilCultureData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [menu, setMenu] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchParams, setSearchParams] = useState({ description: "" });
  const [isActiveStatus, setIsActiveStatus] = useState(null);

  const statusOptions = [
    { value: "Active", label: t("active") },
    { value: "Inactive", label: t("inactive") }
  ];

  const handleSearchParamsChange = (event) => {
    const { name, value } = event.target;
    setSearchParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
    
    if (name === "status") {
        setStatusFilter(value);
        searchCulture(0, itemsPerPage, searchKeyword, value);
    } else {
        setSearchKeyword(value);
        if (value) {
            searchCulture(0, itemsPerPage, value, statusFilter);
        } else {
            getCulture(0, itemsPerPage);
        }
    }
  };

  const getCulture = (pageNumber, size) => {
    setLoading(true);
    CultureRegistrationService.getCulture({ pageNumber, size })
      .then((res) => {
        const cultureResults = res?.data?.response?.Culture || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setCultureData(cultureResults);
        setfilCultureData(cultureResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (cultureResults.length === 0 && searchKeyword.length > 0) {
          setMessage(t('noRecordsFound'));
          setOpenAlert(true);
          setSeverity("info");
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setCultureData([]);
        setfilCultureData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const searchCulture = (pageNumber, size, keyword, status) => {
    setLoading(true);
    const query = {
        pageNumber,
        size,
        cultureName: keyword,
        isActive: status === "Active" ? true : status === "Inactive" ? false : undefined
    };

    CultureRegistrationService.searchCulture(query)
        .then((res) => {
            const cultureResults = res?.data?.response?.Culture || [];
            setCurrentPage(pageNumber);
            setItemsPerPage(size);
            setCultureData(cultureResults);
            setfilCultureData(cultureResults);
            setTotalItems(res?.data?.response?.totalItems || 0);
            if (cultureResults.length === 0 && searchKeyword.length > 0) {
                setMessage(t('noRecordsFound'));
                setOpenAlert(true);
                setSeverity("info");
            }
        })
        .catch((error) => {
            setMessage(error?.response?.data?.message || error?.message);
            setOpenAlert(true);
            setSeverity("error");
            setCultureData([]);
            setfilCultureData([]);
            setTotalItems(0);
        })
        .finally(() => {
            setLoading(false);
        });
  };

  useEffect(() => {
    getCulture(currentPage, itemsPerPage);
  }, [currentPage, itemsPerPage]);

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    if (searchKeyword) {
      searchCulture(pageNumber - 1, itemsPerPage, searchKeyword);
    } else {
      getCulture(pageNumber - 1, itemsPerPage);
    }
  };

  const handleCultureDelete = () => {
    // Refresh the current page
    if (searchKeyword) {
      searchCulture(currentPage, itemsPerPage, searchKeyword, statusFilter);
    } else {
      getCulture(currentPage, itemsPerPage);
    }
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t("Culture Registration List")}
      background="#ffffff"Reg
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          padding: "1rem",
          height: "100px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} />
          <Typography>
            {t("Culture Registration.filters.filtersLabel")}
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={10}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="description"
                  label={t("Culture Registration.filters.culture")}
                  value={searchParams.description}
                  onChange={handleSearchParamsChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                    width: "100%",
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <SelectInput
                  name="status"
                  label={t("status")}
                  value={statusFilter}
                  onChange={handleSearchParamsChange}
                  options={statusOptions}
                  placeholder={t("selectoption")}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                      height: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  inputProps={{
                    style: { height: "50px" }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={2} textAlign={"right"}>
            <Button
              sx={{
                borderRadius: 1,
                minWidth: 95,
                minHeight: 44,
                background: "#004F9F",
              }}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => navigate("/addCultureRegistration")}
            >
              {t("addNew")}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <CultureTable 
        cultureData={filCultureData}
        onDelete={handleCultureDelete}
      />
    </Layout>
  );
};

export default GetCultureRegistration;
