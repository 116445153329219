import React from "react";
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
  TextField,
  IconButton,
  Grid,
} from "@mui/material";
import DeleteCircle from "assets/DeleteCircle.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "react-i18next";
import "./Table.css";

const SimulationData = () => {
  const { t } = useTranslation();

  const segmentationData = [
    { id: "S1", percentage: "23%", quantity: "3,450" },
    { id: "S2", percentage: "15%", quantity: "2,250" },
    { id: "S3", percentage: "12%", quantity: "1,800" },
    { id: "S4", percentage: "10%", quantity: "1,500" },
    { id: "S4T", percentage: "20%", quantity: "3,000" },
    { id: "S4TP", percentage: "20%", quantity: "3,000" },
  ];

  return (
    <Box sx={{ p: 3, bgcolor: "#F5F5F5", borderRadius: 5, mt: 2 }}>
      {/* Title Section */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Typography variant="h8" display="flex">
          <ArrowDropDownIcon />
          {t("Simulation 2")}
        </Typography>
        <IconButton>
          <img
            src={DeleteCircle}
            alt="Delete Icon"
            style={{ width: 40, height: 40 }}
          />
        </IconButton>
      </Box>

      {/* Simulation Data TextField */}
      <Grid container xs={12} sx={{ gap: 2 }}>
        <Grid xs={9} item>
          <TextField
            fullWidth
            label="Simulaton Data Used"
            variant="outlined"
            size="small"
            value="IOS (2023,2022,2021,2020) - Scale (2023,2022,2021,2020) - Yield (2022,2022,2021,2020) - Loyalty (2023,2020,2019,2017) - Debt (2023,2022,2021,2020)"
            InputProps={{
              readOnly: true,
            }}
            sx={{ mb: 3 }}
          />
        </Grid>
        <Grid item sx={3}>
          <TextField
            fullWidth
            label="Use Segmentation Range for Simulaton"
            variant="outlined"
            size="small"
            value="Range for Flue Cured Tobacco"
            InputProps={{
              readOnly: true,
            }}
            sx={{ mb: 3, minWidth: 350 }}
          />
        </Grid>
      </Grid>
      {/* Concept Selection */}
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        Concept to Use for Calculation
      </Typography>
      <FormControl>
        <RadioGroup row defaultValue="property">
          <FormControlLabel
            value="property"
            control={<Radio />}
            label="Property"
          />
          <FormControlLabel value="farmer" control={<Radio />} label="Farmer" />
        </RadioGroup>
      </FormControl>

      <table className="segmentation-table">
        <thead>
          <tr>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
            >
              {t("ID")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
            >
              {t("Percentage")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
            >
              {t("Quantity")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
            >
              {t("")}
            </th>
          </tr>
        </thead>
        <tbody>
          {segmentationData.map((row, index) => (
            <tr key={index}>
              <td>{row.id}</td>
              <td>{row.percentage}</td>
              <td>{row.quantity}</td>
              <td>
                <Button
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  size="small"
                  sx={{
                    bgcolor: "#0A2756",
                    "&:hover": {
                      bgcolor: "#0A2756",
                    },
                  }}
                >
                  Details
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};

export default SimulationData;
