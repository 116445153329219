import { httpGrow } from "../http-common";

const getProgress = async () => {
  const response = await httpGrow.get(
    `/task-management/progress`
  );
  return response;
};

const taskManagement = async (query) => {
  const {
    fromDate,
    toDate
  } = query;

  const response = await httpGrow.get(`/task-management?`, {
      params: {
        fromDate,
        toDate
      }
  });
  return response;
};

const timeline = async () => {
  const response = await httpGrow.get(
    `/task-management/timeline`
  );
  return response;
};

const history = async (query) => {
  const {
    fromDate,
    toDate
  } = query;

  const response = await httpGrow.get(`/task-management/history?`, {
      params: {
        fromDate,
        toDate
      }
  });
  return response;
};

const TaskManagementService = {
    getProgress,
    taskManagement,
    timeline,
    history
  };
  
  export default TaskManagementService;