import React from "react";
import { Box, Typography } from "@mui/material";

const CustomDivider = ({ title, CustomButton }) => {
  return (
    <Box
      id="divider"
      sx={{
        marginTop: "8px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between", // Align title and right element
        padding: "0px 20px 10px",
        gap: "20px",
        background: "#FFFFFF",
        borderBottom: "5px solid #004F9F",
        flex: "none",
        order: 2,
        alignSelf: "stretch",
        flexGrow: 0,
        zIndex: 1,
      }}
    >
      <Typography
        sx={{
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "20px",
          color: "#5A646E",
          flex: "none",
          order: 0,
          flexGrow: 0,
          zIndex: 0,
        }}
      >
        {title}
      </Typography>
      {CustomButton && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {CustomButton}
        </Box>
      )}
    </Box>
  );
};

export default CustomDivider;
