import { httpGrow } from "../http-common";

const createAgronomicLossType = (data) => {
  return httpGrow.post(`/agronomicLossTypeRegistration`, data);
};

const updateAgronomicLossType = (data) => {
  return httpGrow.put(`agronomicLossTypeRegistration`, data);
};

const getAgronomicLossTypes = async (query) => {
  const { pageNumber, pageSize, agronomicLossType, isActive } = query;

  const response = await httpGrow.get(`/agronomicLossTypeRegistration/search`, {
    params: {
      pageNumber,
      pageSize,
      agronomicLossType: agronomicLossType || undefined,
      isActive: isActive,
    },
  });
  return response;
};

const AgronomicLossTypeService = {
  createAgronomicLossType,
  updateAgronomicLossType,
  getAgronomicLossTypes,
};

export default AgronomicLossTypeService;
