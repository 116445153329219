import React, { useEffect, useState, useContext } from "react";
import { Typography, Box, Modal, Switch, Button } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useNavigate } from "react-router-dom";
import { SnackbarContext } from "context/snackBarContext";
import RedSwitch from "utils/RedSwitch";
import AgriculturalSupplyService from "services/AscService";
import { useTranslation } from "react-i18next";

const AgriculturalSupplyConfigurationTable = ({
  data,
  setData,
  setIsDeleted,
}) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [confirmSwitchOpen, setConfirmSwitchOpen] = useState(false);
  const [currentSwitchId, setCurrentSwitchId] = useState(null);
  const { t } = useTranslation();

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
    setData((prevData) =>
      [...prevData].sort((a, b) => {
        if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
        if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
        return 0;
      })
    );
  };

  const getSortIcon = (key) =>
    sortConfig.key === key ? (
      sortConfig.direction === "asc" ? (
        <ArrowUpwardIcon fontSize="small" />
      ) : (
        <ArrowDownwardIcon fontSize="small" />
      )
    ) : (
      <ArrowDownwardIcon fontSize="small" />
    );

  const handleSwitchChange = async (id, useThisRecord) => {
    if (useThisRecord) {
      setCurrentSwitchId(id);
      setConfirmSwitchOpen(true);
      return;
    }
    try {
      const itemToUpdate = data.find(
        (item) => item.agriSuppliesConfigId === id
      );
      if (itemToUpdate) {
        const updatedRecord = {
          ...itemToUpdate,
          useThisRecord: !itemToUpdate.useThisRecord,
        };
        const updatedData = data.map((item) =>
          item.agriSuppliesConfigId === id ? updatedRecord : item
        );
        setData(updatedData);
        console.log("Updated Record  ---- ", updatedRecord);
        await AgriculturalSupplyService.updateAPI({
          agriSuppliesConfigId: id,
          useThisRecord: !useThisRecord,
        });
        setIsDeleted((prev) => (prev += 1));
      }
      setMessage("Record Updated Successfully");
      setSeverity("success");
      setOpenAlert(true);
    } catch (error) {
      setMessage("Error updating record");
      setSeverity("error");
      setOpenAlert(true);
    } finally {
      setConfirmSwitchOpen(false);
      setCurrentSwitchId(null);
    }
  };

  const handleConfirmSwitchChange = async (id) => {
    try {
      const itemToUpdate = data.find(
        (item) => item.agriSuppliesConfigId === id
      );
      if (itemToUpdate) {
        const updatedRecord = {
          ...itemToUpdate,
          useThisRecord: !itemToUpdate.useThisRecord,
        };
        const updatedData = data.map((item) =>
          item.agriSuppliesConfigId === id ? updatedRecord : item
        );
        setData(updatedData);
        await AgriculturalSupplyService.updateAPI({
          agriSuppliesConfigId: id,
          useThisRecord: updatedRecord.useThisRecord,
        });
        setIsDeleted((prev) => (prev += 1));
      }
      setMessage("Record Updated Successfully");
      setSeverity("success");
      setOpenAlert(true);
    } catch (error) {
      setMessage("Error updating record");
      setSeverity("error");
      setOpenAlert(true);
    } finally {
      setConfirmSwitchOpen(false);
      setCurrentSwitchId(null);
    }
  };

  return (
    <div className="table-container">
      <table className="gw-custom-table">
        <thead>
          <tr>
            <th
              style={{
                minWidth: "10%",
              }}
            >
              {t("AgriculturalSupplyConfiguration.useThisRecord")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
                minWidth: "8%",
              }}
              onClick={() => handleSort("code")}
            >
              {t("code")}
              {getSortIcon("code")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
                minWidth: "29%",
              }}
              onClick={() => handleSort("asrSupplyName")}
            >
              {t("AgriculturalSupplyConfiguration.agriculturalSupply")}{" "}
              {getSortIcon("asrSupplyName")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
                minWidth: "15%",
              }}
              onClick={() => handleSort("applicationPhase")}
            >
              {t("AgriculturalSupplyConfiguration.applicationPhase")}{" "}
              {getSortIcon("applicationPhase")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
                minWidth: "15%",
              }}
              onClick={() => handleSort("unitOfMeasure")}
            >
              {t("AgriculturalSupplyConfiguration.unitOfMeasure")}
              {getSortIcon("unitOfMeasure")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
                minWidth: "10%",
              }}
              onClick={() => handleSort("target")}
            >
              {t("AgriculturalSupplyConfiguration.hasTarget")}{" "}
              {getSortIcon("target")}
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.agriSuppliesConfigId}>
              <td style={{ minWidth: "10%" }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                  <Box sx={{ mr: "6px" }}>{t("no")}</Box>
                  <RedSwitch
                    checked={row.useThisRecord}
                    onChange={() =>
                      handleSwitchChange(
                        row.agriSuppliesConfigId,
                        row.useThisRecord
                      )
                    }
                    color="primary"
                  />
                  <Box sx={{ ml: "6px" }}>{t("yes")}</Box>
                </Box>
              </td>
              <td style={{ minWidth: "8%" }}>{row.code}</td>
              <td style={{ minWidth: "29%" }}>{row.asrSupplyName}</td>
              <td style={{ minWidth: "15%" }}>{row.applicationPhase}</td>
              <td style={{ minWidth: "15%" }}>{row.unitOfMeasure}</td>
              <td style={{ minWidth: "10%" }}>{row.target}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        open={confirmSwitchOpen}
        onClose={() => setConfirmSwitchOpen(false)}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "560px",
            margin: "auto",
            mt: 25,
            height: "200px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
              color: "#fff",
            }}
          >
            {t("AgriculturalSupplyConfiguration.alert")}
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography
              variant="body2"
              sx={{ whiteSpace: "pre-wrap", width: "550px", overflowX: "auto" }}
            >
              {t("AgriculturalSupplyConfiguration.alertMessage")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "60px",
              pt: 4,
            }}
          >
            <Button
              onClick={() => setConfirmSwitchOpen(false)}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              {t("no")}
            </Button>

            <Button
              onClick={() => handleConfirmSwitchChange(currentSwitchId)}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              {t("yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default AgriculturalSupplyConfigurationTable;
