import React, { useState, useEffect, useContext, useCallback, forwardRef, useImperativeHandle } from "react";
import {
  Autocomplete,
  Checkbox,
  InputAdornment,
  FormLabel,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
  TableContainer,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "react-i18next";
import SelectInput from "components/common/SelectInput";
import RedSwitch from "utils/RedSwitch";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import { useCultureData } from "./useCultureData";
import useCultureStore from "./useCultureStore";

const ListFilter = forwardRef(({ onDelete, initialData, disabled }, ref) => {
    const { t } = useTranslation();
    
    // Initialize states with data if available
    const [abbreviation, setAbbreviation] = useState(initialData?.ctAbbreviation || "");
    const [description, setDescription] = useState(initialData?.ctDescription || "");
    const [curingType, setCuringType] = useState(initialData?.curingTypeName || "");
    const [geneticTypeSelection, setGeneticTypeSelection] = useState({
        name: initialData?.geneticTypeName || "",
        id: initialData?.gtGeneticTypeId || ""
    });
    const [lsm, setLsm] = useState(initialData?.ctLSM || "");
    const [status, setStatus] = useState(initialData?.ctStatus ?? true);
    const [selectedCuringTypeId, setSelectedCuringTypeId] = useState(initialData?.ctrId || "");

    // Add state for validation errors
    const [errors, setErrors] = useState({
        abbreviation: false,
        description: false,
        lsm: false,
        curingType: false,
        geneticType: false
    });

    // Validation function
    const validateFields = () => {
        const newErrors = {
            abbreviation: abbreviation.trim() === '' || abbreviation.length > 50,
            description: description.trim() === '' || description.length > 50,
            lsm: lsm.trim() === '',
            curingType: curingType.trim() === '',
            geneticType: geneticTypeSelection.name.trim() === ''
        };

        setErrors(prev => ({
            ...prev,
            abbreviation: newErrors.abbreviation,
            description: newErrors.description,
            lsm: newErrors.lsm,
            curingType: newErrors.curingType,
            geneticType: newErrors.geneticType
        }));

        // Return true if all fields are valid (no errors)
        return !Object.values(newErrors).some(error => error);
    };

    // Update values when initialData changes
    useEffect(() => {
        if (initialData) {
            setAbbreviation(initialData.ctAbbreviation || "");
            setDescription(initialData.ctDescription || "");
            setCuringType(initialData.curingTypeName || "");
            setGeneticTypeSelection({
                name: initialData.geneticTypeName || "",
                id: initialData.gtGeneticTypeId || ""
            });
            setLsm(initialData.ctLSM || "");
            setStatus(initialData.ctStatus ?? true);
            setSelectedCuringTypeId(initialData.ctrId || "");
        }
    }, [initialData]);

    // Get the curingTypesQuery from useCultureData
    const { curingTypesQuery } = useCultureData({
        pageNumber: 0,
        size: 50,
        culture: useCultureStore((state) => state.cultureTypeId),
        status: true
    });

    // Transform curing types to options
    const curingTypeOptions = curingTypesQuery.data?.menu || [];

    // Handler for Curing Type change
    const handleCuringTypeChange = (event) => {
        const selectedValue = event.target.value;
        setCuringType(selectedValue);
        
        // Find the selected curing type and get its ID
        const selectedCuringType = curingTypesQuery.data?.curingTypes?.find(
            type => type.curingType === selectedValue
        );
        setSelectedCuringTypeId(selectedCuringType?.ctrId || "");

        // Reset curing type error
        setErrors(prev => ({ ...prev, curingType: false }));
    };

    // Get the geneticTypesQuery from useCultureData
    const { geneticTypesQuery } = useCultureData({
        cultureTypeId: useCultureStore((state) => state.cultureTypeId)
    });

    // Transform genetic types to options with both id and value
    const geneticTypeOptions = geneticTypesQuery.data?.geneticTypes?.map(type => ({
        value: type.gtGeneticDescription,
        label: type.gtGeneticDescription,
        id: type.gtGeneticTypeId
    })) || [];

    // Modified handler for Genetic Type change
    const handleGeneticTypeChange = (event) => {
        const selectedValue = event.target.value;
        const selectedType = geneticTypeOptions.find(opt => opt.value === selectedValue);
        
        setGeneticTypeSelection({
            name: selectedValue,
            id: selectedType?.id || ""
        });

        // Reset genetic type error
        setErrors(prev => ({ ...prev, geneticType: false }));
    };

    // Handle delete
    const handleDelete = () => {
        onDelete();
    };

    // Modify useImperativeHandle to include validation
    useImperativeHandle(ref, () => ({
        getData: () => {
            if (validateFields()) {
                return {
                    abbreviation,
                    description,
                    curingType,
                    geneticType: geneticTypeSelection.name,
                    geneticTypeId: geneticTypeSelection.id,
                    lsm,
                    status,
                    ctrId: selectedCuringTypeId
                };
            }
            return null; // Return null if validation fails
        }
    }));

    return (
        <>
    <Grid
        container
        spacing={2}
        sx={{
          background: "#EFEFEF",
          borderRadius: "10px",
          p: 2,
          maxWidth: "100%",
          margin: "auto",
          paddingBottom: "8px",
          px: 3,
        }}
      >
        {/* Filter and arrow */}
        <Grid item xs={12} columnSpacing={2} style={{ paddingTop: "0px", paddingLeft: "0px" }}>
          <Typography variant="h8" display="flex">
            <ArrowDropDownIcon />
            {t("cultureTypeRegistration.cultureType")}
          </Typography>
        </Grid>

        {/* Abbreviation input field */}
        <Grid item xs={1.71} sx={{ 
          paddingLeft: "8px !important", 
          paddingRight: "8px !important", 
          marginBottom: "10px" 
        }}>
          <FormControl fullWidth error={errors.abbreviation}>
            <TextField
              fullWidth
              id="abbreviation-input"
              variant="outlined"
              size="small"
              label={t("curingTypeRegistration.abbreviation")}
              value={abbreviation}
              onChange={(e) => {
                  const value = e.target.value;
                  setAbbreviation(value);
                  setErrors(prev => ({ 
                      ...prev, 
                      abbreviation: value.trim() === '' || value.length > 50 
                  }));
              }}
              error={errors.abbreviation}
              helperText={
                  errors.abbreviation 
                      ? (abbreviation.length > 50 
                          ? t("cultureTypeRegistration.abbreviationMaxLength") 
                          : t("cultureTypeRegistration.abbreviationRequired"))
                      : ""
              }
              InputProps={{
                sx: {
                  backgroundColor: "#F5F5F5",
                  minHeight: "46px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#E0E0E0",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#F5F5F5",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E0E0E0",
                    },
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: disabled ? "#E0E0E0 !important" : undefined,
                  },
                },
              }}
              InputLabelProps={{
                shrink: true,
                sx: {
                  padding: '0 0px',
                  color: disabled ? "#999999 !important" : undefined,
                  "&.Mui-focused": {
                    color: disabled ? "#999999 !important" : undefined,
                  }
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        {/* Description input field */}
        <Grid item xs={1.71} sx={{ 
          paddingLeft: "8px !important", 
          paddingRight: "8px !important", 
          marginBottom: "10px" 
        }}>
          <FormControl fullWidth error={errors.description}>
            <TextField
              fullWidth
              id="description-input"
              variant="outlined"
              size="small"
              label={t("curingTypeRegistration.discription")}
              value={description}
              onChange={(e) => {
                  const value = e.target.value;
                  setDescription(value);
                  setErrors(prev => ({ 
                      ...prev, 
                      description: value.trim() === '' || value.length > 50 
                  }));
              }}
              error={errors.description}
              helperText={
                  errors.description 
                      ? (description.length > 50 
                          ? t("cultureTypeRegistration.descriptionMaxLength") 
                          : t("cultureTypeRegistration.descriptionRequired"))
                      : ""
              }
              InputProps={{
                sx: {
                  backgroundColor: "#F5F5F5",
                  minHeight: "46px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#E0E0E0",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#F5F5F5",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E0E0E0",
                    },
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: disabled ? "#E0E0E0 !important" : undefined,
                  },
                },
              }}
              InputLabelProps={{
                shrink: true,
                sx: {
                  padding: '0 0px',
                  color: disabled ? "#999999 !important" : undefined,
                  "&.Mui-focused": {
                    color: disabled ? "#999999 !important" : undefined,
                  }
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        {/* Curing Type */}
        <Grid item xs={1.71} sx={{ 
          paddingLeft: "8px !important", 
          paddingRight: "8px !important", 
          marginBottom: "10px" 
        }}>
          <SelectInput 
            label={t("curingTypeRegistration.curingType")}
            options={curingTypeOptions}
            value={curingType}
            onChange={handleCuringTypeChange}
            placeholder={t("selectoption")}
            loading={curingTypesQuery.isLoading}
            disabled={disabled}
            error={errors.curingType}
            helperText={
                errors.curingType 
                    ? t("cultureTypeRegistration.curingTypeRequired") 
                    : ""
            }
          />
        </Grid>

        {/* Genetic Type */}
        <Grid item xs={1.71} sx={{ 
          paddingLeft: "8px !important", 
          paddingRight: "8px !important", 
          marginBottom: "10px" 
        }}>
          <SelectInput 
            label={t("spacingRegistration.geneticType")}
            options={geneticTypeOptions}
            value={geneticTypeSelection.name}
            onChange={handleGeneticTypeChange}
            placeholder={t("selectoption")}
            loading={geneticTypesQuery.isLoading}
            disabled={disabled}
            error={errors.geneticType}
            helperText={
                errors.geneticType 
                    ? t("cultureTypeRegistration.geneticTypeRequired") 
                    : ""
            }
          />
        </Grid>

        {/* LSM input field */}
        <Grid item xs={1.71} sx={{ 
          paddingLeft: "8px !important", 
          paddingRight: "8px !important", 
          marginBottom: "10px" 
        }}>
          <FormControl fullWidth error={errors.lsm}>
            <TextField
              fullWidth
              id="lsm-input"
              variant="outlined"
              size="small"
              label={t("cultureTypeRegistration.lsm")}
              value={lsm}
              onChange={(e) => {
                  const value = e.target.value;
                  setLsm(value);
                  setErrors(prev => ({ 
                      ...prev, 
                      lsm: value.trim() === '' 
                  }));
              }}
              error={errors.lsm}
              helperText={
                  errors.lsm 
                      ? t("cultureTypeRegistration.lsmRequired") 
                      : ""
              }
              InputProps={{
                sx: {
                  backgroundColor: "#F5F5F5",
                  minHeight: "46px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#E0E0E0",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#F5F5F5",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E0E0E0",
                    },
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: disabled ? "#E0E0E0 !important" : undefined,
                  },
                },
              }}
              InputLabelProps={{
                shrink: true,
                sx: {
                  padding: '0 0px',
                  color: disabled ? "#999999 !important" : undefined,
                  "&.Mui-focused": {
                    color: disabled ? "#999999 !important" : undefined,
                  }
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        {/* Status Section */}
        <Grid item xs={1.71} sx={{ 
          paddingLeft: "8px !important", 
          paddingRight: "8px !important", 
          marginBottom: "10px" 
        }}>
                <FormControl component="fieldset">
                <FormLabel
                  sx={{
                    color: "#0E2B63",
                    mb: 2,
                    ml: 0.5,
                    "&.Mui-focused": { color: "#0E2B63" },
                    "&:hover": { color: "#0E2B63" },
                  }}
                >
                  Status
                </FormLabel>
                <Box sx={{ display: "flex", alignItems: "center", marginTop: "-10px" }}>
                  <Typography sx={{ mr: 3, ml: 3 }}>Inactive</Typography>
                  <FormControlLabel
                    control={
                      <RedSwitch
                        checked={status}
                        onChange={(e) => setStatus(e.target.checked)}
                        name="status"
                        disabled={disabled}
                      />
                    }
                    label={<Typography sx={{ ml: 2 }}>Active</Typography>}
                    labelPlacement="end"
                  />
                </Box>
              </FormControl>
        </Grid>

        {/* Delete Icon Section */}
        {!disabled && (
          <Grid 
            item 
            xs={1.71} 
            sx={{ 
                paddingLeft: "8px !important", 
                paddingRight: "8px !important", 
                marginBottom: "10px", 
                display: "flex", 
                justifyContent: "flex-end", 
                alignItems: "center"
            }}
          >
            <img
                className="btn delete-btn"
                src={_ButtonDelete_}
                alt="Delete"
                style={{ 
                    cursor: 'pointer', 
                    height: '24px', 
                    width: '24px'
                }}
                onClick={handleDelete}
            />
        </Grid>
        )}

    </Grid>
        </>
    );
});

export default ListFilter;