import React, { useEffect, useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "./SoilAnalysisInterpretationTable.css";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _Recommendation_ from "assets/Recommendation.svg";
import { Tooltip } from "@mui/material";

const SoilAnalysisInterpretationTable = (props) => {
  const navigate = useNavigate();
  const [analysisData, setAnalysisData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const { t } = useTranslation();

  useEffect(() => {
    setAnalysisData(props.analysisData);
    setOriginalData(props.analysisData);
  }, [props.analysisData]);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...analysisData].sort((a, b) => {
      const aValue =
        key === "geneticTypes" ? a[key][0].gtGeneticDescription : a[key];
      const bValue =
        key === "geneticTypes" ? b[key][0].gtGeneticDescription : b[key];

      if (aValue.toString().toLowerCase() < bValue.toString().toLowerCase())
        return direction === "asc" ? -1 : 1;
      if (aValue.toString().toLowerCase() > bValue.toString().toLowerCase())
        return direction === "asc" ? 1 : -1;
      return 0;
    });

    setAnalysisData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <ArrowUpwardIcon fontSize="small" />;
      } else if (sortConfig.direction === "desc") {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
    return <ArrowDownwardIcon fontSize="small" />;
  };

  return (
    <div className="table-container">
      <table className="soil-interpretation-table">
        <thead>
          <tr>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("iprInterpretationName")}
            >
              {t("soilAnalysisInterpretationRegistration.interpretationName")}{" "}
              {getSortIcon("iprInterpretationName")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("geneticTypes")}
            >
              {t("soilAnalysisInterpretationRegistration.geneticType")}{" "}
              {getSortIcon("geneticTypes")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("sapParameterRuleName")}
            >
              {t("soilAnalysisInterpretationRegistration.parameters")}{" "}
              {getSortIcon("sapParameterRuleName")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("iprStatus")}
            >
              {t("status")}
              {getSortIcon("iprStatus")}
            </th>
            <th className="actions-header">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  justifyContent: "left",
                }}
              >
                {t("Actions")}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {analysisData?.map((row, index) => (
            <tr
              key={index}
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate("/addSoilAnalysisInterpretationRegistration", {
                  state: { row: row, edit: false },
                })
              }
            >
              <td style={{ overflowX: "hidden" }}>
                {row.iprInterpretationName}
              </td>
              <td>
                {row.geneticTypes
                  .map((ele) => ele.gtGeneticDescription)
                  .join(", ")}
              </td>
              <td>
                {row.sapParameterRuleName + ", " + row.sapParameterSubRuleName}
              </td>
              <td>{row.iprStatus}</td>
              <td className="actions-cell">
                {/* <Tooltip title="MANAGE RECOMMENDATIONS" arrow placement="top">
                  <img
                    className="btn delete-btn"
                    src={_Recommendation_}
                    alt="Manage Recommendations"
                  />
                </Tooltip> */}

                <Tooltip title="EDIT" arrow placement="top">
                  <img
                    className="btn edit-btn"
                    src={_ButtonEdit_}
                    alt="Edit Button"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the row click from firing
                      navigate("/addSoilAnalysisInterpretationRegistration", {
                        state: { row: row, edit: true },
                      });
                    }}
                  />
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SoilAnalysisInterpretationTable;
