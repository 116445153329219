import Layout from 'components/structure/Layout';
import { SnackbarContext } from 'context/snackBarContext';
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ApprovalLevelConfigurationService from 'services/ApprovalLevelConfigurationService';
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import eyeIcon from "assets/eyeIcon.svg";
import { Tooltip, Typography } from '@mui/material';
import "./ApprovalLevelConfigurationTable.css";


const ApprovalLevelConfigurationTable = () => {

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(() => {
        return parseInt(localStorage.getItem("approvalLevelConfig")) || 5;
    });
    const [totalItems, setTotalItems] = useState(0);


    useEffect(() => {
        fetchData(currentPage, itemsPerPage)
    }, [])

    const fetchData = (
        pageNumber,
        size,
    ) => {
        setLoading(true);
        const query = {
            pageNumber,
            size,
        };
        console.log("usedRecordsquery", query)
        ApprovalLevelConfigurationService.getAPI(query)
            .then((res) => {
                const results =
                    res?.data?.response?.Modules || [];
                setData(results);
                setTotalItems(res?.data?.response?.totalItems || 0);
                if (results.length === 0) {
                    setMessage("No records found.");
                    setSeverity("info");
                    setOpenAlert(true);
                }
            })
            .catch((error) => {
                setMessage(error?.response?.data?.message || error?.message);
                setSeverity("error");
                setOpenAlert(true);
                setData([]);
                setTotalItems(0);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const paginate = (pageNumber, itemsPerPage) => {
        setCurrentPage(pageNumber - 1);
        setItemsPerPage(itemsPerPage);
        localStorage.setItem("approvalLevelConfig", itemsPerPage)
        fetchData(pageNumber - 1, itemsPerPage);
    };


    return (
        <Layout
            itemsPerPage={itemsPerPage}
            totalItems={totalItems}
            paginate={paginate}
            currentPage={currentPage}
            title={t("approvalLevelConfig.screen1")}
            background="#ffffff"
            useNewPagination={true}
        >
            {loading ? (
                <Typography variant="h6" align="center">
                    {t("loading")}
                </Typography>
            ) : (
                <>
                    {(
                        totalItems > 0 && <ALCTable data={data} />
                    )}
                </>
            )}
        </Layout>
    )
}



const ALCTable = (props) => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
    const [data, setData] = useState(props.data);

    const handleSort = (key) => {
        let direction = "asc";
        if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({ key, direction });

        const sortedData = [...data].sort((a, b) => {
            if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
            if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
            return 0;
        });
        setData(sortedData);
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            if (sortConfig.direction === "asc") {
                return <ArrowUpwardIcon fontSize="small" />;
            } else if (sortConfig.direction === "desc") {
                return <ArrowDownwardIcon fontSize="small" />;
            }
        }
        // Default icon (ArrowDownward) when no sorting is applied
        return <ArrowDownwardIcon fontSize="small" />;
    };

    return (
        <div className="table-container">
            <table className='custom-tableALC'>
                <thead>
                    <tr>
                        <th
                            onClick={() => handleSort("mName")}
                            style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            {t("approvalLevelConfig.module")} {getSortIcon("mName")}
                        </th>
                        <th className="actions-headerALC">{t("Actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <tr key={index}>
                            <td
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                    navigate("/approvalWorkflowApprovalLevelConfigurationDetails", {
                                        state: { row: row, edit: false },
                                    })
                                }
                            >
                                {row.mName}
                            </td>
                            <td className="actions-cellALC">
                                <Tooltip title={t("edit")} arrow>
                                    <img
                                        className="btn edit-btnALC"
                                        onClick={() =>
                                            navigate("/approvalWorkflowApprovalLevelConfigurationDetails", {
                                                state: { row: row, edit: true },
                                            })
                                        }
                                        src={_ButtonEdit_}
                                        alt="Edit"
                                    />
                                </Tooltip>
                                <Tooltip title={t("view").toUpperCase()} arrow>
                                    <img
                                        className="btn edit-btnALC"
                                        onClick={() =>
                                            navigate("/approvalWorkflowApprovalLevelConfigurationDetails", {
                                                state: { row: row, edit: false },
                                            })
                                        }
                                        src={eyeIcon}
                                        alt="Edit"
                                    />
                                </Tooltip>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}


export default ApprovalLevelConfigurationTable