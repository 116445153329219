import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SegmentationKpiService from "services/SegmentationKpiService";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "react-i18next";
import iconSearch from "assets/iconSearchs.svg";
import SegmentationTable from "./SegmentationTable";

const GetSegmentationRegistration = () => {
  const navigate = useNavigate();
  const [kpiData, setKpiData] = useState([]);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [origins, setOrigins] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [origin, setOrigin] = useState("");
  const [isDeleted, setIsDeleted] = useState(0);
  const { t } = useTranslation();
  const debounceTimeout = useRef(null);

  const debounceSearch = (newKeyword, newOrigin) => {
    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(() => {
      SearchSegmentationApi(0, itemsPerPage, newKeyword, newOrigin);
    }, 1500);
  };

  const SearchSegmentationApi = (
    pageNumber,
    size,
    searchParam,
    skpioDescription
  ) => {
    const query = {
      pageNumber,
      pageSize: size,
      searchParam,
      skpioDescription,
    };

    SegmentationKpiService.getSegmentationKpi(query)
      .then((res) => {
        const results = res?.data?.segmentationKpis || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setKpiData(results);
        setTotalItems(res?.data?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setKpiData([]);
        setTotalItems(0);
      });
  };

  const handleKeywordChange = (event) => {
    const newValue = event.target.value;
    setSearchKeyword(newValue);
    debounceSearch(newValue, origin);
  };

  const handleOriginChange = (event, value) => {
    const newValue = value || "";
    setOrigin(newValue);
    debounceSearch(searchKeyword, newValue);
  };

  const CallOriginsApi = () => {
    SegmentationKpiService.getOriginsData()
      .then((res) => {
        const List = res?.data?.response?.SKPIC || [];
        setOrigins(List);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setOrigins([]);
      });
  };

  useEffect(() => {
    CallOriginsApi();
  }, []);

  useEffect(() => {
    SearchSegmentationApi(currentPage, itemsPerPage, searchKeyword, origin);
  }, [currentPage, itemsPerPage, isDeleted]);

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    SearchSegmentationApi(pageNumber - 1, itemsPerPage, searchKeyword, origin);
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t("segmentationKPI.pageTitle")}
      background="#ffffff"
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: 1,
          padding: 1.5,
          height: "122px",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1 }} />
          <Typography sx={{ fontWeight: "600", color: "#666666" }}>
            {t("segmentationKPI.sectionName")}
          </Typography>
        </Box>

        <Grid container spacing={1.5} sx={{ alignItems: "center" }}>
          <Grid item container spacing={1.5} xs={12} md={10.2}>
            <Grid item xs={12} sm={6} md={7}>
              <FormControl fullWidth>
                <TextField
                  onChange={handleKeywordChange}
                  InputLabelProps={{ shrink: true }}
                  label={t("segmentationKPI.name/Des")}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={iconSearch}
                          alt="search"
                          style={{
                            width: "24px",
                            height: "24px",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  freeSolo
                  onChange={(event, newValue) => {
                    handleOriginChange(event, newValue);
                  }}
                  onInputChange={(event, newValue) => {
                    handleOriginChange(event, newValue);
                  }}
                  options={origins.map((ele) => ele.pickListDescription)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label={t("segmentationKPI.origin")}
                      placeholder="Select an Option"
                      fullWidth
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={1.8}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: { xs: 2, md: 0 },
            }}
          >
            <Button
              variant="contained"
              startIcon={<AddCircleIcon />}
              sx={{
                minWidth: 95,
                borderRadius: 1,
                background: "#004F9F",
                height: { xs: "40px", md: "44px" },
              }}
              onClick={() => navigate("/addsegmentationRegistration")}
            >
              {t("addNew")}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <SegmentationTable kpiData={kpiData} setKpiData={setKpiData} setIsDeleted={setIsDeleted} />
    </Layout>
  );
};

export default GetSegmentationRegistration;
