import React from "react";
import { CircularProgress, Box, Typography } from "@mui/material";

const CircularProgressDemo = ({ progress }) => {
  const size = 60;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        alignItems: "center",
        p: 1,
      }}
    >
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        {/* Background Circle */}
        <CircularProgress
          variant="determinate"
          value={100}
          size={size}
          thickness={4}
          sx={{
            color:
              progress <= 50
                ? "gray"
                : progress > 50 && progress < 100
                ? "#102A63"
                : progress === 100 && "rgb(80,175,71)",
            position: "absolute",
          }}
        />
        {/* Progress Circle */}
        <CircularProgress
          variant="determinate"
          value={100 - progress}
          size={size}
          thickness={4}
          sx={{
            color: "#e9ebee",
          }}
        />
        {/* Percentage Text */}
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color={
              progress <= 50
                ? "gray"
                : progress > 50 && progress < 100
                ? "#102A63"
                : progress === 100 && "rgb(80,175,71)"
            }
            fontWeight="700"
            fontSize="0.9rem"
          >
            {`${Math.round(progress)}%`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CircularProgressDemo;
