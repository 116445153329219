import { httpGrow } from "http-common";

const getAPI = async (query) => {
    const { pageNumber, size } = query;
    const response = await httpGrow.get(`/module/getAllModules`, {
        params: {
            pageNumber,
            size
        }
    });
    return response;
};
const getALCDetails = async (moduleId) => {
    const response = await httpGrow.get(`/approval-level-configuration/${moduleId}`);
    return response;
};
const getAllProfile = async () => {
    const response = await httpGrow.get(`/profile/getAll`);
    return response;
};

const putALCAPI = (data) => {
    return httpGrow.put('approval-level-configuration/update', data);
};

const postALCAPI = (data) => {
    return httpGrow.post('approval-level-configuration/save', data);
};

const ApprovalLevelConfigurationService = {
    getAPI,
    getALCDetails,
    getAllProfile,
    // putALCAPI,
    postALCAPI,
};

export default ApprovalLevelConfigurationService