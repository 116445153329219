// SelectInput.jsx
import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { FormHelperText } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export default function SelectInput({
  label,
  options = [],
  value,
  onChange,
  placeholder = 'Select an option',
  variant = "outlined",
  disabled = false,
  sx,
  name,
  error,
  helperText,
  loading = false
}) {
  const handleClearSelection = (event) => {
    event.stopPropagation();
    onChange({ target: { value: "", name } });
  };

  return (
    <FormControl 
      variant={variant} 
      fullWidth 
      sx={{ 
        ...sx,
        position: "relative",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: disabled ? "#E0E0E0 !important" : undefined,
        },
        "&.Mui-disabled": {
          backgroundColor: "#F5F5F5",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#E0E0E0",
          },
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: disabled ? "#E0E0E0 !important" : undefined,
        },
      }}
      error={error}
      disabled={disabled}
    >
      <InputLabel 
        id={`${label}-label`}
        shrink
        sx={{
          '&.Mui-focused': {
            color: 'gray',
          },
          padding: '0 8px',
          // background: 'white',
          color:  undefined,
          "&.Mui-focused": {
            color: disabled ? "#999999 !important" : undefined,
          }
        }}
      >
        {label}
      </InputLabel>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Select
          labelId={`${label}-label`}
          id={`${label}-select`}
          value={value || ""}
          onChange={onChange}
          displayEmpty
          label={label}
          name={name}
          disabled={disabled}
          sx={{
            flex: 1,
            minHeight: '46px',
            '& .MuiSelect-select': {
              paddingTop: '14px',
              paddingBottom: '8px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              '& legend': {
                marginLeft: '4px',
              },
            },
          }}
          renderValue={(selected) => {
            if (!selected) {
              return <span style={{ color: '#131619' }}>{placeholder}</span>;
            }
            const selectedOption = options.find(opt => opt.value === selected);
            return selectedOption?.label || selected;
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 250,
              },
            },
          }}
        >
          {loading ? (
            <MenuItem disabled>
              <CircularProgress size={20} />
            </MenuItem>
          ) : (
            options.map((option) => (
              <MenuItem 
                key={option.value}
                value={option.value}
                sx={{
                  borderLeft: value === option.value ? '4px solid #004F9F' : 'none',
                  '&.Mui-selected': {
                    borderLeft: '4px solid #004F9F',
                  },
                  '&.Mui-selected:hover': {
                    borderLeft: '4px solid #004F9F',
                  }
                }}
              >
                {option.label}
              </MenuItem>
            ))
          )}
        </Select>
        {value && !disabled && (
          <IconButton
            aria-label="clear selection"
            size="small"
            onClick={handleClearSelection}
            sx={{
              position: "absolute",
              right: 24,
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 1,
              padding: '4px',
              '&:hover': {
                background: 'transparent'
              }
            }}
          >
            <CloseIcon 
              fontSize="small" 
              sx={{
                width: '16px',
                height: '16px',
                // opacity: 0.26,
                color: 'grey.500',
                // '&:hover': {
                //   opacity: 0.4
                // }
              }}
            />
          </IconButton>
        )}
      </Box>
      {helperText && (
        <FormHelperText error={error}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}