import { httpGrow } from "../http-common";

const GetPriceParameterizationList = async (query) => {
  const response = await httpGrow.get(
    `/priceParametrizationOfInvestment?pageNumber=${query?.pageNumber}&pageSize=${query?.size}`
  );
  return response;
};

const getSupplierDetails = async () => {
  const response = await httpGrow.get(
    `/priceParametrizationOfInvestment/supplier-names`
  );
  return response;
};
const getCulture = () => {
  const response = httpGrow.get(`/culture/cultureNames-and-Id`);
  return response;
};

const createGenetic = (data) => {
  return httpGrow.post(`/geneticType/save`, data);
};

const updatePriceParameter = (data) => {
  console.log("data for update---",data)
  return httpGrow.put(`/priceParametrizationOfInvestment`, data);
};

const deletePriceList = (priceListId) => {
  return httpGrow.delete(`/priceParametrizationOfInvestment/price-list/${priceListId}`);
};
const searchPriceParameter = async (queryString) => {
  const response = await httpGrow.get(`/priceParametrizationOfInvestment/search${queryString}`);
  return response;
};

const PriceParameterizationService = {
  GetPriceParameterizationList,
  getSupplierDetails,
  getCulture,
  createGenetic,
  deletePriceList,
  searchPriceParameter,
  updatePriceParameter,
};
export default PriceParameterizationService;
