import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import FurnaceTable from "./FurnaceTable";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FurnaceService from "services/FurnaceService";
import Lable from "utils/lables.json";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/Clear";
import label from "../growingLabel.json";

const GetFurnaceTypeRegistration = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [furnaceData, setFurnaceData] = useState([]);
  const [filteredFurnaceData, setFilteredFurnaceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [menu, setMenu] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");

  const handleSearchChange = (value) => {
    setSearchKeyword(value);
  };
  const getRefreshData = () =>{
    callFurnaceAPI(currentPage, itemsPerPage);
  }
  const handleStatusChange = (status) => {
    setStatusFilter(status); // Update the status filter
    setCurrentPage(0); // Reset to the first page when the status changes

    const isActive =
      status === "Active" ? true : status === "Inactive" ? false : null;

    callSearchFurnaceAPI(0, itemsPerPage, searchKeyword, isActive);
  };

  const callFurnaceAPI = (pageNumber, size) => {
    setLoading(true);
    FurnaceService.getFurnace({ pageNumber, size })
      .then((res) => {
        const furnaceResults = res?.data?.response?.furnaceTypes || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setFurnaceData(furnaceResults);
        setFilteredFurnaceData(furnaceResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setFurnaceData([]);
        setFilteredFurnaceData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getfullFurnaceMenuAPI = () => {
    setLoading(true);
    FurnaceService.getFurnace({ pageNumber: currentPage, size: 50 })
      .then((res) => {
        const furnaceResults = res?.data?.response?.furnaceTypes || [];
        const menues = furnaceResults.map((e) => e.ftFurnaceTypeName || []);
        setMenu(menues);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setFurnaceData([]);
        setFilteredFurnaceData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const callSearchFurnaceAPI = (pageNumber, size, Keyword, status) => {
    setLoading(true);
    setSearchKeyword(Keyword);

    let query = {
      pageNumber,
      size,
      ftFurnaceTypeName: Keyword,
    };

    const params = [];

    if (query?.pageNumber !== undefined) {
      params.push(`pageNumber=${query.pageNumber}`);
    }
    if (query?.size !== undefined) {
      params.push(`pageSize=${query.size}`);
    }
    if (query?.ftFurnaceTypeName) {
      params.push(`ftFurnaceTypeName=${query.ftFurnaceTypeName}`);
    }
    if (status !== null && status !== undefined) {
      params.push(`isActive=${status}`);
    }
    const queryString = params.length ? `?${params.join("&")}` : "";

    FurnaceService.searchFurnace(queryString)
      .then((res) => {
        const Results = res?.data?.response?.furnaceTypes || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setFurnaceData(Results);
        setFilteredFurnaceData(Results);
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setFurnaceData([]);
        setFilteredFurnaceData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    callFurnaceAPI(currentPage, itemsPerPage);
    if (currentPage <= 0) {
      getfullFurnaceMenuAPI();
    }
  }, [currentPage, itemsPerPage]);

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    if (searchKeyword) {
      callSearchFurnaceAPI(pageNumber - 1, itemsPerPage, searchKeyword);
    } else {
      callFurnaceAPI(pageNumber - 1, itemsPerPage);
    }
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t("furnaceType.furnaceType")}
      background="#ffffff"
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: 1,
          padding: 2,
          height: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1 }} />
          <Typography>{Lable.filtersLable}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            //justifyContent: "space-between",
            gap: 2,
          }}
        >
          <FormControl sx={{ flex: 1, maxWidth: "35rem", width: "100%" }}>
            <TextField
              value={searchKeyword}
              onChange={(event) => {
                const newValue = event.target.value;
                setSearchKeyword(newValue);
                if (newValue) {
                  callSearchFurnaceAPI(0, itemsPerPage, newValue); // Trigger search on value change
                } else {
                  callSearchFurnaceAPI(0, itemsPerPage, ""); // Trigger search with empty string if cleared
                }
              }}
              onInputChange={(event, newInputValue) => {
                if (newInputValue) {
                  handleSearchChange(newInputValue); // Trigger your custom search handling
                } else {
                  getfullFurnaceMenuAPI(); // Reset search if input is cleared
                }
              }}
              label="Furnace Type"
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "0.438rem",
                  minHeight: "3.125rem",
                },
              }}
              InputProps={{
                style: { height: "3.125rem" },
              }}
            />
          </FormControl>

          <FormControl sx={{ flex: 1, maxWidth: "18.125rem", width: "100%" }}>
            <TextField
              select
              name="status"
              label={t("Status")}
              value={statusFilter || ""}
              onChange={(event) => handleStatusChange(event.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "0.438rem",
                  minHeight: "3.125rem",
                },
                "& .MuiInputLabel-root": {
                  lineHeight: "1.2em",
                },
              }}
              InputProps={{
                style: { height: "3.125rem" },
                endAdornment: statusFilter && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleStatusChange("")} // Clears the filter
                      size="small"
                      //edge="end"
                      sx={{ color: "gray" }} // Optional: color for the icon
                      style={{ marginRight: "15px" }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value="pl">Select an option</MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
            </TextField>
          </FormControl>

          <Button
            variant="contained"
            startIcon={<AddCircleIcon />}
            sx={{
              minWidth: 95,
              borderRadius: 1,
              background: "#004F9F",
              height: { xs: "40px", md: "44px" },
              marginLeft: { xs: 0, md: "auto" },
            }}
            onClick={() => navigate("/addfurnaceTypeRegistration")}
          >
            {t("addNew")}
          </Button>
        </Box>
      </Box>

      {!loading && furnaceData && furnaceData.length === 0 && (
        <Typography variant="h6" align="center" color="textSecondary">
          {label.noDataMessage}
        </Typography>
      )}
      {!loading && furnaceData && furnaceData.length > 0 && (
        <FurnaceTable furnaceData={filteredFurnaceData} setFurnaceData={setFilteredFurnaceData} getfullFurnaceMenuAPI={getRefreshData} />
      )}
    </Layout>
  );
};

export default GetFurnaceTypeRegistration;
