import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  ListItemText,
  Checkbox,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useTranslation } from "react-i18next";
import SelectInput from "components/common/SelectInput";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import CentralAgriculturalSupplyManagementService from "services/CentralAgriculturalSupplyManagementService";
import "./MoreFiltersTable.css";
import SearchIcon from "assets/SearchIcon.svg";
import CloseIcon from "@mui/icons-material/Close";

const MoreFilters = ({
  open,
  onClose,
  cropSeasonId,
  isThirdPartySupply,
  onItemsSelect,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    description: "",
    supplyType: "",
    geneticType: [],
    usageType: [],
  });

  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [selectedItems, setSelectedItems] = useState({});
  const [geneticTypeOptions, setGeneticTypeOptions] = useState([]);
  const [supplyTypeOptions, setSupplyTypeOptions] = useState([]);
  const [usageTypeOptions, setUsageTypeOptions] = useState([]);

  // Modified useEffect to fetch agricultural supply data with filters
  const fetchAgriculturalSupplyData = useCallback(
    async (filters = {}) => {
      // Only fetch if modal is open and required parameters are provided
      if (open && cropSeasonId) {
        try {
          setLoading(true);
          setError(null);

          // Prepare API parameters matching the service method signature
          const apiParams = {
            cropSeasonId: cropSeasonId,
            isThirdPartySupply: isThirdPartySupply,
            searchKeyword: filters.description || undefined,
            supplyTypeId: filters.supplyType || undefined,
            geneticTypeIds:
              filters.geneticType?.map((gt) => gt.value) || undefined,
            usageTypes: filters.usageType?.map((ut) => ut.value) || undefined,
          };

          const response =
            await CentralAgriculturalSupplyManagementService.searchAgriculturalSupply(
              apiParams
            );

          // Log the full response for debugging
          console.log("Agricultural Supply API Response:", response);

          // Transform the response data to match table structure
          const formattedData =
            response?.data?.response?.map((item) => ({
              id: item.agriculturalSupplyId,
              code: item.agriculturalSupplyCode,
              name: item.agriculturalSupplyName,
              supplyType: item.supplyType || "N/A",
              geneticType: item.geneticTypes
                ? item.geneticTypes.join(", ")
                : "N/A",
              usageType: item.usageTypes ? item.usageTypes.join(", ") : "N/A",
            })) || [];

          setTableData(formattedData);
        } catch (err) {
          console.error("Error fetching agricultural supply data:", err);
          setError(err);
        } finally {
          setLoading(false);
        }
      }
    },
    [open, cropSeasonId, isThirdPartySupply]
  );

  // Initial data fetch
  useEffect(() => {
    fetchAgriculturalSupplyData();
  }, [fetchAgriculturalSupplyData]);

  // Fetch genetic type options when component mounts
  useEffect(() => {
    const fetchGeneticTypes = async () => {
      try {
        const response =
          await CentralAgriculturalSupplyManagementService.getGeneticType();

        // Transform the API response into options for Autocomplete
        const formattedOptions = response?.data?.response.map((type) => ({
          value: type.gtGeneticTypeId,
          label: type.gtGeneticDescription,
        }));

        setGeneticTypeOptions(formattedOptions);
      } catch (error) {
        console.error("Error fetching genetic types:", error);
        setGeneticTypeOptions([]);
      }
    };

    fetchGeneticTypes();
  }, []);

  // Fetch supply type options when component mounts
  useEffect(() => {
    const fetchSupplyTypes = async () => {
      try {
        const response =
          await CentralAgriculturalSupplyManagementService.getSupplyType();

        // Transform the API response into options for SelectInput
        const formattedOptions = response?.data?.response.map((type) => ({
          value: type.supplyTypeId,
          label: type.supplyTypeDescription,
        }));

        setSupplyTypeOptions(formattedOptions);
      } catch (error) {
        console.error("Error fetching supply types:", error);
        setSupplyTypeOptions([]);
      }
    };

    fetchSupplyTypes();
  }, []);

  // Fetch usage type options when component mounts
  useEffect(() => {
    const fetchUsageTypes = async () => {
      try {
        const response =
          await CentralAgriculturalSupplyManagementService.getUsageType();

        // Transform the API response into options for Autocomplete
        const formattedOptions = response?.data?.response.map((type) => ({
          value: type.usageTypeId,
          label: type.usageType,
        }));

        setUsageTypeOptions(formattedOptions);
      } catch (error) {
        console.error("Error fetching usage types:", error);
        setUsageTypeOptions([]);
      }
    };

    fetchUsageTypes();
  }, []);

  // Modified handleFilterChange to handle Autocomplete
  const handleFilterChange = (name) => (event, newValue) => {
    let newFilters;

    switch (name) {
      case "description":
        newFilters = {
          ...selectedFilters,
          [name]: event.target.value,
        };
        break;
      case "supplyType":
        newFilters = {
          ...selectedFilters,
          [name]: newValue ? newValue.value : "",
        };
        break;
      case "geneticType":
      case "usageType":
        newFilters = {
          ...selectedFilters,
          [name]: newValue,
        };
        break;
      default:
        newFilters = selectedFilters;
    }

    // Update selected filters
    setSelectedFilters(newFilters);

    // Trigger API call with new filters
    fetchAgriculturalSupplyData(newFilters);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...tableData].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setTableData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? (
        <ArrowUpwardIcon fontSize="small" />
      ) : (
        <ArrowDownwardIcon fontSize="small" />
      );
    }
    return <ArrowDownwardIcon fontSize="small" />;
  };

  const handleItemSelection = (id) => {
    // Toggle the selection state
    const newSelectedItems = {
      ...selectedItems,
      [id]: !selectedItems[id],
    };

    // Convert selectedItems to an array of selected agricultural supplies
    const selectedSupplies = tableData
      .filter((row) => newSelectedItems[row.id])
      .map((row) => ({
        value: row.id,
        label: row.name,
      }));

    // Call the callback to pass selected items to parent
    onItemsSelect(selectedSupplies);

    // Update the selected items state
    setSelectedItems(newSelectedItems);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "85%",
          backgroundColor: "white",
          borderRadius: 2,
          padding: 3,
          overflow: "auto",
        }}
      >
        {/* Modal Header */}
        <Box
          sx={{
            background: "#102A62",
            height: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTopLeftRadius: "7px",
            borderTopRightRadius: "7px",
            marginLeft: "-24px",
            marginRight: "-24px",
            marginTop: "-24px",
            paddingLeft: 3,
            paddingRight: 3,
            position: "relative",
          }}
        >
          <Typography
            sx={{
              color: "#ffffff",
              fontSize: "20px",
              fontWeight: 500,
              textAlign: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            {t("centralAgriculturalSupplyManagement.moreFilters")}
          </Typography>
        </Box>

        {/* Filters Section */}
        <Box
          sx={{
            background: "#EFEFEF",
            borderRadius: "5px",
            padding: "1rem",
            marginTop: "1rem",
          }}
        >
          <Box sx={{ display: "flex", marginBottom: 1 }}>
            <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} />
            <Typography>{t("filtersLable")}</Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2.5}>
                  <TextField
                    name="description"
                    label={t("centralAgriculturalSupplyManagement.codeName")}
                    value={selectedFilters.description}
                    onChange={handleFilterChange("description")}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        height: '54px',
                        padding: '0 14px',
                      },
                      width: "100%",
                    }}
                    InputProps={{
                      endAdornment: selectedFilters.description && (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear description"
                            onClick={() => {
                              setSelectedFilters(prev => ({
                                ...prev,
                                description: ""
                              }));
                              fetchAgriculturalSupplyData({
                                ...selectedFilters,
                                description: ""
                              });
                            }}
                            edge="end"
                            size="small"
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <Autocomplete
                    id="supply-type-select"
                    options={supplyTypeOptions}
                    getOptionLabel={(option) => option.label}
                    value={
                      supplyTypeOptions.find(
                        (option) => option.value === selectedFilters.supplyType
                      ) || null
                    }
                    onChange={handleFilterChange("supplyType")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("centralAgriculturalSupplyManagement.supplyType")}
                        placeholder={t("selectoption")}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                src={SearchIcon}
                                alt="search"
                                style={{
                                  width: "20px", 
                                  height: "20px", 
                                  color: "#737D86",
                                  marginRight: "5px"
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          '& .MuiInputBase-input::placeholder': {
                            color: 'black',
                            opacity: 1,
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3.5}>
                  <Autocomplete
                    multiple
                    id="genetic-type-select"
                    options={geneticTypeOptions}
                    getOptionLabel={(option) => option.label}
                    value={selectedFilters.geneticType}
                    onChange={handleFilterChange("geneticType")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("centralAgriculturalSupplyManagement.geneticType")}
                        placeholder={
                          selectedFilters.geneticType.length === 0
                            ? t("selectoption")
                            : ""
                        }
                        InputLabelProps={{ shrink: true }}
                        sx={{
                          '& .MuiInputBase-input::placeholder': {
                            color: 'black',
                            opacity: 1,
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3.5}>
                  <Autocomplete
                    multiple
                    id="usage-type-select"
                    options={usageTypeOptions}
                    getOptionLabel={(option) => option.label}
                    value={selectedFilters.usageType}
                    onChange={handleFilterChange("usageType")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("centralAgriculturalSupplyManagement.usageType")}
                        placeholder={
                          selectedFilters.usageType.length === 0
                            ? t("centralAgriculturalSupplyManagement.selectMore")
                            : ""
                        }
                        InputLabelProps={{ shrink: true }}
                        sx={{
                          '& .MuiInputBase-input::placeholder': {
                            color: 'black',
                            opacity: 1,
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {/* Table Section */}
        <div className="moreFilters-container" style={{ marginTop: "1rem" }}>
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="200px"
            >
              <CircularProgress />
            </Box>
          ) : error ? (
            <Typography align="center">
              {t("No Data Available")}
            </Typography>
          ) : tableData.length === 0 ? (
            <Typography align="center">{t("noDataAvailable")}</Typography>
          ) : (
            <table className="moreFilters-table">
              <thead>
                <tr>
                  <th
                    onClick={() => handleSort("code")}
                    style={{ verticalAlign: "middle" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "4px",
                      }}
                    >
                      {t("code")} {getSortIcon("code")}
                    </div>
                  </th>
                  <th
                    onClick={() => handleSort("name")}
                    style={{ verticalAlign: "middle" }}
                  >
                    <div
                      style={{
                        display: "flex",

                        gap: "4px",
                      }}
                    >
                      {t("centralAgriculturalSupplyManagement.name")}{" "}
                      {getSortIcon("name")}
                    </div>
                  </th>
                  <th
                    onClick={() => handleSort("supplyType")}
                    style={{ verticalAlign: "middle" }}
                  >
                    <div
                      style={{
                        display: "flex",

                        gap: "4px",
                      }}
                    >
                      {t("centralAgriculturalSupplyManagement.supplyType")}{" "}
                      {getSortIcon("supplyType")}
                    </div>
                  </th>
                  <th
                    onClick={() => handleSort("geneticType")}
                    style={{ verticalAlign: "middle" }}
                  >
                    <div
                      style={{
                        display: "flex",

                        gap: "4px",
                      }}
                    >
                      {t("centralAgriculturalSupplyManagement.geneticType")}{" "}
                      {getSortIcon("geneticType")}
                    </div>
                  </th>
                  <th
                    onClick={() => handleSort("usageType")}
                    style={{ verticalAlign: "middle" }}
                  >
                    <div
                      style={{
                        display: "flex",

                        gap: "4px",
                      }}
                    >
                      {t("centralAgriculturalSupplyManagement.usageType")}{" "}
                      {getSortIcon("usageType")}
                    </div>
                  </th>
                  <th className="actions-header">
                    {t("centralAgriculturalSupplyManagement.actions")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row) => (
                  <tr key={row.id}>
                    <td>{row.code}</td>
                    <td>{row.name}</td>
                    <td>{row.supplyType}</td>
                    <td>{row.geneticType}</td>
                    <td>{row.usageType}</td>
                    <td className="actions-cell">
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleItemSelection(row.id)}
                        sx={{
                          height: "35px",
                          background: selectedItems[row.id]
                            ? "var(--Gray-3, #737D86)"
                            : "#004F9F",
                          fontSize: "0.75rem",
                          padding: "6px 12px",
                          "&:hover": {
                            background: selectedItems[row.id]
                              ? "#666E75"
                              : "#003A75",
                          },
                        }}
                      >
                        {selectedItems[row.id] ? t("Remove") : t("Selected")}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {/* Bottom Buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: 3,
            gap: 2,
          }}
        >
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{
              backgroundColor: "#737D86",
              padding: "6px 16px",
              borderRadius: "4px",
              color: "white",
              width: "110px",
              "&:hover": {
                backgroundColor: "#666E75",
              },
            }}
          >
            {t("close")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default MoreFilters;
