import { httpGrow } from "../http-common";


const getCultureType = async () => {
  const response = await httpGrow.get(`/culture-type/tobacco-culture-type`);
  return response;
};

const getCropSeason = async () => {
  const response = await httpGrow.get(`/grCropSeason/crop-season-drop-down`);
  return response;
};
const getFarmerSegmentationData = async (query) => {
    const { pageNumber, pageSize, groupName, ctDescription, cropYear } = query;
  
    // Remove undefined parameters to avoid sending them in the request
    const params = {};
    if (pageNumber !== undefined) params.pageNumber = pageNumber;
    if (pageSize !== undefined) params.pageSize = pageSize;
    if (groupName) params.groupName = groupName;
    if (ctDescription) params.ctDescription = ctDescription;
    if (cropYear) params.cropYear = cropYear;
  
    const response = await httpGrow.get(`/farmer-segmentation-tobacco-group`, {
      params,
    });
    return response;
  };
  const PostData = (data) => {
    return httpGrow.post(`/farmer-segmentation-tobacco-group`, data);
  };
  const deleteAPI = (formId) => {
    return httpGrow.delete(`/farmer-segmentation-tobacco-group/delete-form/${formId}`);
  };
  const updateData = (data) => {
    return httpGrow.put(`farmer-segmentation-tobacco-group/update-form-segment-details`, data);
  };

const FarmerSegmentationService = {
getCultureType,
getCropSeason,
getFarmerSegmentationData,
PostData,
deleteAPI,
updateData

};

export default FarmerSegmentationService;
