import React, { useState, useEffect, useContext } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  TextField,
  Typography,
  Grid,
  InputAdornment,
} from "@mui/material";
import Divider from "components/common/GrowingDivider";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "react-i18next";
import TechnicalRecomendationGetData from "./TechnicalRecomendationGetData";
import TechnicalRecomendationService from "services/TechnicalRecomendationService";
import iconSearchs from "../../../../assets/iconSearchs.svg";

const TechnicalRecomendation = () => {
  const { t, i18n } = useTranslation();
  const [cropSeason, setCropSeason] = useState("");
  const [cropSeasonId, setCropSeasonId] = useState("");
  const [cropSeasonOptions, setCropSeasonOptions] = useState([]);

  const [typeValue, setTypeValue] = useState("");
  const [typeId, setTypeId] = useState("");
  const [typeOptions, setTypeOptions] = useState([]);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [inputBoxes, setInputBoxes] = useState([
    {
      id: 1,
      investmentType: null, // Placeholder for Investment Type
      rules: null, // Placeholder for Rules
      quantity: "", // Placeholder for Quantity
    },
  ]);

  const [isEditing, setIsEditing] = useState(false);
  const [editingData, setEditingData] = useState(null);

  const [investmentValue, setInvestmentValue] = useState("");
  const [investmentId, setInvestmentId] = useState("");
  const [investmentTypeOptions, setInvestmentTypeOptions] = useState([]);

  const [agriSuppliesValue, setAgriSuppliesValue] = useState("");
  const [agriSuppliesId, setAgriSuppliesId] = useState("");
  const [agriSuppliesOptions, setAgriSuppliesOptions] = useState([]);

  const [ruleValue, setRuleValue] = useState("");
  const [ruleId, setRuleId] = useState("");
  const [rulesOptions, setRulesOptions] = useState([]);
  const [formData, setFormData] = useState([]);

  const [typeData, setTypeData] = useState();
  const [investmentData, setInvestmentData] = useState();
  const [agriSupplyData, setAgriSupplyData] = useState();
  const [ruleData, setRuleData] = useState();
  const [uomData, setUomData] = useState([]);

  const [unit, setUnit] = useState("");
  const [unitId, setUnitId] = useState("");

  const [quantity, setQuantity] = useState("");
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);

  const [quantityError, setQuantityError] = useState("");

  console.log(investmentValue, "investmentValue");
  console.log(investmentTypeOptions, "investmentTypeOptions");

  // Update handleQuantityChange to validate input
  const handleQuantityChange = (e) => {
    const value = e.target.value;
    
    // Clear any previous error
    setQuantityError("");
    
    // Check if value is negative
    if (value < 0) {
      setQuantityError(t("TechnicalRecomendation.negativeQuantityError"));
      setQuantity("");
      return;
    }
    
    // Check if value contains decimal points
    if (value.includes('.')) {
      setQuantityError(t("TechnicalRecomendation.decimalQuantityError"));
      // Keep the previous valid value
      return;
    }
    
    // If all validations pass, update the quantity
    setQuantity(value);
  };

  // useEffect(() => {
  //   if (cropSeason && typeId) {
  //     getAllData(cropSeason, typeId);
  //   }
  // }, [cropSeason, typeId]);
  useEffect(() => {
    if (cropSeason && typeId) {
      // Reset table data when type changes
      setTableData([]);
      setFilteredTableData([]);
      getAllData(cropSeason, typeId);
    }
  }, [cropSeason, typeId]);

  useEffect(() => {
    if (tableData.length > 0) {
      console.log("tableData Inside:", tableData);
      handleFilterData();
    }
  }, [tableData]);

  console.log("tableData", tableData);

  const getAllData = async (cropSeason, typeId) => {
    try {
      const response = await TechnicalRecomendationService.getDataAPI({
        cropYear: cropSeason,
        type: typeId,
      });
  
      if (response?.status === 200) {
        const tbData = response?.data?.response?.techRecommendations?.map(
          (item) => ({
            cropSeasonId: item.cropSeasonId,
            investmentId: item.investmentId || "",
            agriculturalSuppliesId: item.agriculturalSuppliesId || "",
            quantity: item.quantity,
            rule: item.rule,
            type: item.type,
            unitOfMeasure: item.unitOfMeasure,
            trtsId: item.trtsId,
            isDelete: item.isDelete,
          })
        )|| [];
  
        setTableData(tbData);
        handleFilterData(); // This will now filter based on the current typeId
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
      setTableData([]);
      setFilteredTableData([]);
    }
  };
  const filterAndMapTableData = (
    tableData,
    typeData,
    uomData,
    ruleData,
    investmentData,
    agriSupplyData
  ) => {
    return tableData
      .filter(
        (item) =>
          typeData.some(
            (type) => String(type.pickListItemCode) === String(item.type)
          ) ||
          uomData.some(
            (uom) => String(uom.pickListItemCode) === String(item.rule)
          ) ||
          ruleData.some(
            (rule) =>
              String(rule.pickListItemCode) === String(item.unitOfMeasure)
          ) ||
          investmentData.some(
            (val) => String(val.inInvestmentId) === String(item.investmentId)
          ) ||
          agriSupplyData.some(
            (val) =>
              String(val.asrSupplyId) === String(item.agriculturalSuppliesId)
          )
      )
      .map((item) => {
        const matchedType = typeData.find(
          (type) => String(type.pickListItemCode) === String(item.type)
        );
        const matchedRule = ruleData.find(
          (rule) => String(rule.pickListItemCode) === String(item.rule)
        );
        const matchedUnitOfMeasure = uomData.find(
          (uom) => String(uom.pickListItemCode) === String(item.unitOfMeasure)
        );
        const matchedinvestmentId = investmentData.find(
          (val) => String(val.inInvestmentId) === String(item.investmentId)
        );
        let itemName = "";
        if (item.investmentId) {
          const matchedInvestment = investmentData.find(
            (val) => String(val.inInvestmentId) === String(item.investmentId)
          );
          itemName = matchedInvestment
            ? matchedInvestment.inInvestmentName
            : item.investmentId;
        } else if (item.agriculturalSuppliesId) {
          const matchedAgriSupply = agriSupplyData.find(
            (val) =>
              String(val.asrSupplyId) === String(item.agriculturalSuppliesId)
          );
          itemName = matchedAgriSupply
            ? matchedAgriSupply.asrSupplyName
            : item.agriculturalSuppliesId;
        }

        return {
          ...item,
          typeId: item.type,
          type: matchedType ? matchedType.pickListDescription : item.type,
          rule: matchedRule ? matchedRule.pickListDescription : item.rule,
          unitOfMeasure: matchedUnitOfMeasure
            ? matchedUnitOfMeasure.pickListDescription
            : item.unitOfMeasure,
          investmentName: item.investmentId ? itemName : "",
          // If it's an agricultural supply record
          agriSupplyName: item.agriculturalSuppliesId ? itemName : "",
        };
      });
  };
  const handleEdit = (rowData) => {
    setIsEditing(true);
    console.log("rowData", rowData, investmentData);
    setEditingData(rowData);
    console.log("editingData", editingData);

    const matchingCropSeasonOption = cropSeasonOptions.find(
      (option) => option.cropSeasonId === rowData.cropSeasonId
    );
    if (matchingCropSeasonOption) {
      setCropSeason(matchingCropSeasonOption.value);
      setCropSeasonId(rowData.cropSeasonId);
    }

    setTypeValue(rowData.type);
    setTypeId(rowData.typeId);

    if (rowData.investmentId) {
      const matchingInvestment = investmentData.find(
        (inv) => inv.inInvestmentId === rowData.investmentId
      );
      console.log(matchingInvestment, "matchingInvestment");

      if (matchingInvestment) {
        const investmentOptionValue = matchingInvestment.inInvestmentName;
        setInvestmentValue(investmentOptionValue);
       // setInvestmentId(rowData.investmentId);
      }
      // Reset agricultural supplies
      setAgriSuppliesValue("");
      setAgriSuppliesId("");
    } else if (rowData.agriculturalSuppliesId) {
      const matchingSupply = agriSupplyData.find(
        (supply) => supply.asrSupplyId === rowData.agriculturalSuppliesId
      );
      if (matchingSupply) {
        const agriOptionValue = matchingSupply.asrSupplyName;
        setAgriSuppliesValue(agriOptionValue);
        setAgriSuppliesId(rowData.agriculturalSuppliesId);
      }
      // Reset investment
      setInvestmentValue("");
      setInvestmentId("");
    }

    const matchingRule = ruleData.find(
      (rule) => rule.pickListDescription === rowData.rule
    );
    if (matchingRule) {
      setRuleValue(rowData.rule);
      setRuleId(matchingRule.pickListItemCode);
      console.log("Setting rule:", rowData.rule, matchingRule.pickListItemCode);
    }

    setQuantity(rowData.quantity);

    // Find and set the unit
    const matchingUOM = uomData.find(
      (uom) => uom.value === rowData.unitOfMeasure
    );
    if (matchingUOM) {
      setUnit(rowData.unitOfMeasure);
      setUnitId(matchingUOM.pickListItemCode);
    }
    setQuantity(rowData.quantity);
    // setUnit(rowData.unitOfMeasure);
    // setUnitId(rowData.unitOfMeasureId);

    // Reset to single input box with the editing data
    setInputBoxes([{ id: 1 }]);
  };

  const handleSave = async () => {
    if (!isValid()) return;

    try {
      const currentDateTime = new Date().toISOString();
      const currentUser = "";

      console.log('HI')
      const payload = {
        createdDateTimeUTC: isEditing ? editingData.createdDateTimeUTC : "",
        createdByUser: isEditing ? editingData.createdByUser : currentUser,
        modifiedDateTimeUTC: currentDateTime,
        modifiedByUser: currentUser,
        cropSeasonId: cropSeasonId,
        type: typeId,
        
        agriculturalSuppliesId: isEditing ? editingData.agriculturalSuppliesId : agriSuppliesId || "",
         investmentId: isEditing ? editingData.investmentId : investmentId || "",

        quantity: quantity,
        rule: ruleId,
        unitOfMeasure: unitId || "Unit",
        trtsId: isEditing ? editingData.trtsId : "",
        isDelete: false,
        countryId: "",
        languageId: "",
      };
      console.log("payloaddata", payload);
      let response;
      if (isEditing) {
        response = await TechnicalRecomendationService.putDataAPI(payload);
      } else {
        response = await TechnicalRecomendationService.postDataAPI(payload);
      }

      setOpenAlert(true);
      setSeverity("success");
      setMessage(isEditing ? "Update successful" : "Save successful");

      // Reset form after successful save
      resetForm();

      // Refresh the table data
      if (cropSeason && typeId) {
        getAllData(cropSeason, typeId);
      }
    } catch (error) {
      setOpenAlert(true);
      setSeverity("error");
      setMessage("Existing/Deleted Records");
    }
  };

  const resetForm = () => {
    setIsEditing(false);
    setEditingData(null);
    setInvestmentValue("");
    setInvestmentId("");
    setAgriSuppliesValue("");
    setAgriSuppliesId("");
    setRuleValue("");
    setRuleId("");
    setQuantity("");
    setUnit("");
    setUnitId("");
    setQuantityError("");
    setInputBoxes([{ id: 1 }]);
  };

  // Usage in a React component
  const handleFilterData = () => {
    const finalData = filterAndMapTableData(
      tableData,
      typeData,
      uomData,
      ruleData,
      investmentData,
      agriSupplyData
    );
    setFilteredTableData(finalData);
  };
  console.log(filteredTableData, "filteredTableData");

  useEffect(() => {
    fetchCropData();
  }, []);

  const fetchCropData = async () => {
    try {
      let cropSeasonResponse =
        await TechnicalRecomendationService.getCropSeasonAPI();
      setCropSeasonOptions(
        cropSeasonResponse.data.response.cropSeason.map((item) => ({
          label: item.cropYear.toString(),
          value: item.cropYear.toString(),
          cropSeasonId: item.cropSeasonId, // Assuming there's an id field
        }))
      );
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };

  console.log("cropSeason", cropSeasonId);

  const handleDelete = (id) => {
    const updatedBoxes = inputBoxes.filter((box) => box.id !== id);
    setInputBoxes(updatedBoxes);
    setFormData(updatedBoxes);
  };

  // Function to handle adding a new input box
  const handleAddBox = () => {
    // Reset all form values when adding a new box
    setInvestmentValue("");
    setInvestmentId("");
    setAgriSuppliesValue("");
    setAgriSuppliesId("");
    setRuleValue("");
    setRuleId("");
    setQuantity("");
    setUnit("");
    setUnitId("");
    setQuantityError("");

    // Reset editing state
    setIsEditing(false);
    setEditingData(null);

    const newBoxId = inputBoxes.length + 1;
    setInputBoxes([...inputBoxes, { id: newBoxId }]);
    setFormData([...formData, { investmentType: "", rules: "", quantity: "" }]);
  };

  const isValid = () => {
    if (!cropSeasonId || !typeId || inputBoxes.length === 0) {
      alert("Please fill in all required fields.");
      return false;
    }
    if (!quantity || quantity < 0) {
      setOpenAlert(true);
      setSeverity("error");
      setMessage(t("TechnicalRecomendation.invalidQuantity"));
      setQuantityError(t("TechnicalRecomendation.negativeQuantityError"));
      return false;
    }

    return true;
  };

  useEffect(() => {
    pickListData();
  }, []);

  useEffect(() => {
    fetchInvestment();
  }, [investmentValue]);

  // fetch PickListData
  async function pickListData() {
    try {
      // Make sure to await the API call
      const response = await TechnicalRecomendationService.picklistAPI({
        pickListCodes: ["GRTRTSTYPE", "GRTRTSUOM", "GRTRTSRULE"],
      });

      if (response?.status === 200) {
        const allTypeData =
          response?.data?.response?.GRTRTSTYPE?.map((item) => ({
            pickListItemCode: item.pickListItemCode,
            pickListDescription: item.pickListDescription,
          })) || [];

        const allRuleData =
          response?.data?.response?.GRTRTSRULE?.map((item) => ({
            pickListItemCode: item.pickListItemCode,
            pickListDescription: item.pickListDescription,
          })) || [];

        setTypeData(allTypeData);
        setRuleData(allRuleData);
        setUomData(
          response?.data?.response?.GRTRTSUOM?.map((item) => ({
            pickListItemCode: item.pickListItemCode,
            value: item.pickListDescription.toString(),
            parentPickListItemCode: item.parentPickListItemCode.toString(),
          })) || []
        );
        console.log("ruleData", ruleData);
        console.log("uomData", uomData);
        console.log("typeData", typeData);

        setTypeOptions(
          allTypeData?.map((item) => ({
            label: item.pickListDescription.toString(),
            value: item.pickListDescription.toString(),
            typeId: item.pickListItemCode,
          })) || []
        );

        setRulesOptions(
          allRuleData?.map((item) => ({
            label: item.pickListDescription.toString(),
            value: item.pickListDescription.toString(),
            ruleId: item.pickListItemCode,
          })) || []
        );
      } else {
        throw new Error("API response was not successful");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // You might want to set some error state here
      setTypeData([]);
      setRuleData([]);
      setUomData([]);
      setTypeOptions([]);
      setRulesOptions([]);
    }
  }

  // Also update the useEffect to handle the async function properly
  useEffect(() => {
    const fetchData = async () => {
      await pickListData();
    };
    fetchData();
  }, []);

  const handleRuleChange = (event, newValue) => {
    if (newValue) {
      console.log("Rule change - selected value:", newValue);
      setRuleValue(newValue.value);
      setRuleId(newValue.ruleId);

      // Find the corresponding unit based on the selected rule
      const selectedRule = newValue.ruleId;
      const matchedUOM = uomData.find(
        (uom) => uom.parentPickListItemCode === selectedRule
      );

      if (matchedUOM) {
        console.log("Matched UOM:", matchedUOM);
        setUnit(matchedUOM.value);
        setUnitId(matchedUOM.pickListItemCode);
      } else {
        setUnit("");
        setUnitId("");
      }
    } else {
      setRuleValue("");
      setRuleId("");
      setUnit("");
      setUnitId("");
    }
  };

  //Update the investment options filtering as well:
  const fetchInvestment = async () => {
    try {
      const response = await TechnicalRecomendationService.getInvestmentAPI();

      if (response?.status === 200) {
        // Filter unique investment data
        const uniqueInvestmentData =
          response?.data?.response?.reduce((acc, current) => {
            const isDuplicate = acc.find(
              (item) => item.inInvestmentId === current.inInvestmentId
            );
            if (!isDuplicate) {
              acc.push(current);
            }
            return acc;
          }, []) || [];

        setInvestmentData(uniqueInvestmentData);
        console.log(investmentData, "investmentData");

        // Transform filtered investment data into options
        const investmentOpts = uniqueInvestmentData.map((item) => ({
          label: item.inInvestmentName,
          value: item.inInvestmentName,
          investmentId: item.inInvestmentId,
        }));

        setInvestmentTypeOptions(investmentOpts);
        console.log("Unique investment options:", investmentOpts);
      }
    } catch (error) {
      console.error("Error fetching investment data:", error);
      setInvestmentData([]);
      setInvestmentTypeOptions([]);
    }
  };

  useEffect(() => {
    if (typeId === "GRTRTSTYPE-02") {
      fetchAgriculturalSupply();
    }
  }, [typeId]);

  // Fetch agricultural supplies if type is GRTRTSTYPE-02
  const fetchAgriculturalSupply = async () => {
    try {
      console.log("tID", typeId);
      const response =
        await TechnicalRecomendationService.getAgriculturalSupplyAPI();
      console.log("response::", response);

      if (response?.status === 200) {
        const agriSuppliesData =
          response?.data?.response?.agriculturalSupplies
            ?.filter(
              (supply) => supply.asrThirdPartyAgriculturalSupply === true
            )
            .map((item) => ({
              asrSupplyId: item.asrSupplyId,
              asrSupplyName: item.asrSupplyName,
            })) || [];

        console.log("agriSuppliesData", agriSuppliesData);
        setAgriSupplyData(agriSuppliesData);

        const agriopts = agriSuppliesData.map((item) => ({
          label: item.asrSupplyName,
          value: item.asrSupplyName,
          agriSuppliesId: item.asrSupplyId,
        }));

        console.log("agricultural supply options:", agriopts);
        setAgriSuppliesOptions(agriopts);
      }
    } catch (error) {
      console.error("Error fetching Agricultural Supplies data:", error);
      setAgriSupplyData([]);
      setAgriSuppliesOptions([]);
    }
  };

  return (
    <Layout title={t("TechnicalRecomendation.pageTitle")} background="#ffffff">
      <Grid
        container
        id="mainfilter"
        spacing={2}
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          p: 2,
          maxWidth: "100%",
          margin: "auto",
          paddingBottom: "34px",
          marginBottom: "12px",
        }}
      >
        <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px" }}>
          <Typography sx={{ fontWeight: 600 }} variant="h8" display="flex">
            <ArrowDropDownIcon />
            {t("TechnicalRecomendation.mandatoryFilters")}
          </Typography>
        </Grid>

        <Grid item xs={6} style={{ paddingLeft: "0px" }}>
          <FormControl fullWidth>
            <Autocomplete
              value={
                cropSeasonOptions.find(
                  (option) => option.value === cropSeason
                ) || null
              }
              disableClearable={false}
              onChange={(event, newValue) => {
                if (newValue) {
                  setCropSeason(newValue.value);
                  setCropSeasonId(newValue.cropSeasonId); // This will trigger useEffect
                } else {
                  setCropSeason("");
                  setCropSeasonId(""); // This will trigger useEffect
                }
              }}
              options={cropSeasonOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("TechnicalRecomendation.csSearchField")}
                  fullWidth
                  placeholder="Select an Option"
                  required={true}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: "18px" },
                  }}
                  sx={{
                    "& .MuiInputBase-input::placeholder": {
                      opacity: 1,
                    },
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: "20px" }}>
          <FormControl fullWidth>
            <Autocomplete
              value={
                typeOptions.find((option) => option.value === typeValue) || null
              }
              disableClearable={true}
              onChange={(event, newValue) => {
                if (newValue) {
                  setTypeValue(newValue.value);
                  setTypeId(newValue.typeId); // This will trigger useEffect
                } else {
                  setTypeValue("");
                  setTypeId(""); // This will trigger useEffect
                }
              }}
              options={typeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("TechnicalRecomendation.typeSearchField")}
                  fullWidth
                  placeholder="Select an Option"
                  required={true}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: "18px" },
                  }}
                  sx={{
                    "& .MuiInputBase-input::placeholder": {
                      opacity: 1,
                    },
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      {cropSeasonId && typeId && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {/* Box with List Name and  Add New Button */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between", // Align items to the left and right
              alignItems: "center",
              mb: 0, // Add some margin for spacing
              width: "100%", // Ensure the Box takes up full width
            }}
          >
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {t("TechnicalRecomendation.list")}
            </Typography>
            <Button
              variant="contained"
              startIcon={<AddCircleIcon />}
              sx={{
                minWidth: 95,
                borderRadius: 1,
                background: "#004F9F",
                height: { xs: "40px", md: "44px" },
              }}
              onClick={handleAddBox}
            >
              {t("TechnicalRecomendation.addNewItemsButton")}
            </Button>
          </Box>

          {/* Full Width Divider */}
          <Divider sx={{ width: "100%", my: 1 }} />
        </Box>
      )}

      {cropSeasonId &&
        typeId &&
        inputBoxes.map((box, index) => (
          <Box
            key={box.id}
            sx={{
              background: "#EFEFEF",
              borderRadius: "5px",
              p: 2,
              maxWidth: "100%",
              margin: "auto",
              mt: 1,
              paddingBottom: "34px",
              marginBottom: "12px",
            }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  {["GRTRTSTYPE-01", "GRTRTSTYPE-02"].includes(typeId) && (
                    <Autocomplete
                      key={typeId} // Add key to force re-render when type changes
                      value={
                        typeId === "GRTRTSTYPE-01"
                          ? investmentTypeOptions.find(
                              (option) => option.value === investmentValue
                            )||null
                          : agriSuppliesOptions.find(
                              (option) => option.value === agriSuppliesValue
                            )||null
                      }
                      disableClearable={true}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          if (typeId === "GRTRTSTYPE-01") {
                            setInvestmentValue(newValue.value);
                            setInvestmentId(newValue.investmentId);
                          } else {
                            setAgriSuppliesValue(newValue.value);
                            setAgriSuppliesId(newValue.agriSuppliesId);
                          }
                        } else {
                          if (typeId === "GRTRTSTYPE-01") {
                            setInvestmentValue("");
                            setInvestmentId("");
                          } else {
                            setAgriSuppliesValue("");
                            setAgriSuppliesId("");
                          }
                        }
                      }}
                      options={
                        typeId === "GRTRTSTYPE-01"
                          ? investmentTypeOptions
                          : agriSuppliesOptions
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            typeId === "GRTRTSTYPE-01"
                              ? t("TechnicalRecomendation.Investment")
                              : t("TechnicalRecomendation.agriSupply")
                          }
                          placeholder="Select an Option"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  src={iconSearchs}
                                  alt="search-icon"
                                  style={{ width: 20, height: 20 }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                      noOptionsText={t("TechnicalRecomendation.noOptionsText")}
                    />
                  )}
                </FormControl>
              </Grid>
              {/* Rules Dropdown */}
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    value={
                      rulesOptions.find(
                        (option) => option.value === ruleValue
                      ) || null
                    }
                    disableClearable={true}
                    onChange={handleRuleChange}
                    options={rulesOptions}
                    //getOptionLabel={(option) => option.name || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("TechnicalRecomendation.Rules")} // This is always shown as the label
                        placeholder="Select an Option"
                        InputLabelProps={{
                          shrink: true, // Ensures the label stays above the field
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              {/* Delete Button + Quantity Input */}
              <Grid item xs={12} sm={2} display="flex" alignItems="center">
  <FormControl fullWidth sx={{position: 'relative', minHeight: '80px'}}>
    <TextField
      label={t("TechnicalRecomendation.Quantity")}
      type="number"
      value={quantity}
      onChange={handleQuantityChange}
      error={!!quantityError}
      helperText={quantityError}
      inputProps={{
        min: 0,
        step: 1, // Only allow whole numbers
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {unit || "Unit"}
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        shrink: true,
      }}
      sx={{
        '& .MuiFormLabel-root': {
          lineHeight: '3.4375em',
        },
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
        '& .MuiFormHelperText-root': {
          position: 'absolute',
          bottom: '-60px',
          marginLeft: 0,
        },
        '& .MuiInputBase-root': {
          position: 'relative',
          top:10
        },
        '& .MuiOutlinedInput-root': {
          marginBottom: 0
        },
        marginBottom: quantityError ? '20px' : '0', // Add bottom margin when there's an error
      }}
    />
  </FormControl>
</Grid>
              <Grid item xs={12} sm={1} display="flex" alignItems="center">
                {/* Delete Button (hidden if only one box remains) */}
                {inputBoxes.length > 1 && (
                  <img
                    onClick={() => handleDelete(box.id)}
                    src={_ButtonDelete_}
                    alt="Delete"
                    style={{
                      cursor: "pointer",
                      width: "24px",
                      height: "24px",
                      marginRight: "8px",
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        ))}

      {cropSeasonId &&
        typeId &&
        filteredTableData &&
        filteredTableData.length === 0 && (
          <Typography variant="h6" align="center" color="textSecondary">
            {t("TechnicalRecomendation.noData")}
          </Typography>
        )}

      {cropSeasonId &&
        typeId &&
        filteredTableData &&
        filteredTableData.length > 0 && (
          <TechnicalRecomendationGetData
            filteredTableData={filteredTableData}
            onEdit={handleEdit}
            typeId={typeId}
          />
        )}

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          minHeight: "10vh",
          paddingBottom: "1.5rem",
        }}
      >
        <Button
          sx={{
            padding: "6px 16px",
            borderRadius: "4px",
            backgroundColor: "#EF7D00",
            color: "white",
            "&:hover": {
              backgroundColor: "#EF7D00",
            },
          }}
          onClick={handleSave}
          variant="contained"
        >
          {t("TechnicalRecomendation.saveButton")}
        </Button>
      </div>
    </Layout>
  );
};

export default TechnicalRecomendation;
