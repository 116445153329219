import React, { useState } from 'react';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CKEditorComponent = () => {
    const [editorData, setEditorData] = useState('');

    const handleEditorChange = (event, editor) => {
        setEditorData(editor.getData());
    };

    // Custom upload adapter to convert image to base64
    const uploadAdapter = (loader) => {
        return {
            upload: () => {
                console.log('Upload Adapter Triggered');
                return new Promise((resolve, reject) => {
                    loader.file.then((file) => {
                        console.log('File selected:', file);

                        // Use FileReader to convert the image to base64
                        const reader = new FileReader();

                        reader.onloadend = () => {
                            const base64String = reader.result;
                            console.log('Base64 string:', base64String);

                            // Resolve the promise with base64 string (image URL)
                            resolve({
                                default: base64String, // Base64 image URL for CKEditor
                            });
                        };

                        reader.onerror = () => {
                            console.error('Error reading file');
                            reject('Failed to convert image to base64');
                        };

                        reader.readAsDataURL(file); // Convert file to base64
                    }).catch((error) => {
                        console.error('Error selecting file:', error);
                        reject('Failed to load file');
                    });
                });
            },
        };
    };

    return (
        <div>
            <h2>CKEditor 5 with Base64 Image Upload</h2>
            <CKEditor
                editor={ClassicEditor}
                data={editorData}
                onChange={handleEditorChange}
                config={{
                    toolbar: [
                        'heading', '|', 'bold', 'italic', 'link', 'bulletedList',
                        'numberedList', 'blockQuote', 'insertTable', 'undo', 'redo',
                        'imageUpload', 'alignment', 'fontSize', 'fontFamily',
                        'fontColor', 'highlight', 'underline', 'strikethrough',
                        'removeFormat', '|', 'horizontalLine',
                    ],
                    licenseKey: 'eyJhbGciOiJFUzI1NiJ9.eyJleHAiOjE3MzgzNjc5OTksImp0aSI6IjlhOTg0NjljLTUxMzUtNDE5Zi04YTc0LTVjYjllMjg1N2YyNSIsInVzYWdlRW5kcG9pbnQiOiJodHRwczovL3Byb3h5LWV2ZW50LmNrZWRpdG9yLmNvbSIsImRpc3RyaWJ1dGlvbkNoYW5uZWwiOlsiY2xvdWQiLCJkcnVwYWwiLCJzaCJdLCJ3aGl0ZUxhYmVsIjp0cnVlLCJsaWNlbnNlVHlwZSI6InRyaWFsIiwiZmVhdHVyZXMiOlsiKiJdLCJ2YyI6IjcyZGZjYjhmIn0.Wl99qCYBigJC0u_VuRHgPDixXhD65Chv0vOw20M9BG_RHFKUDz3cl0OvLj8uqtMMYDFgYBBlA6urNx4XhjkJZg', 
                    // image: {
                    //     toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side', '|', 'linkImage'],
                    //     upload: uploadAdapter, // Custom upload adapter
                    // },
                    ckfinder: {
                        uploadUrl: 'https://example.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json'
                    },
                    language: 'en',
                }}
            />
            <div>
                <h3>Editor Output</h3>
                <div dangerouslySetInnerHTML={{ __html: editorData }} />
            </div>
        </div>
    );
};

export default CKEditorComponent;
