// BankConfiguration.jsx
import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Checkbox,
  InputAdornment,
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import debounce from "lodash/debounce";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "react-i18next";
import BankConfigurationTable from "./BankConfigurationTable";
import BankFieldTechnicanConfigService from "services/BankFieldTechnicanConfigService";
import arrowLeft from "assets/arrowLeft.svg";

const BankConfiguration = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [showUsedRecords, setShowUsedRecords] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // Define fetchData first
  const fetchData = useCallback((pageNumber, size) => {
    setLoading(true);
    const query = {
      pageNumber,
      size
    };
    BankFieldTechnicanConfigService.getBankList(query)
      .then((res) => {
        const results = res?.data?.response?.bankFieldTechnicianConfigurationResponse || [];
        setData(results);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (results.length === 0) {
          setMessage("No records found.");
          setSeverity("info");
          setOpenAlert(true);
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
        setData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setMessage, setSeverity, setOpenAlert]);

  // Then use useEffect with fetchData
  useEffect(() => {
    fetchData(currentPage, itemsPerPage);
  }, [fetchData, currentPage, itemsPerPage]);

  const debouncedSearch = useCallback(
    debounce((searchValue, isUsedChecked) => {
      // Only proceed if search value is not empty or used records checkbox is checked
      if ((!searchValue || searchValue.trim() === "") && !isUsedChecked) {
        fetchData(currentPage, itemsPerPage);
        return;
      }

      setLoading(true);
      BankFieldTechnicanConfigService.searchBank({ 
        keyword: searchValue || '', // Ensure keyword is a string
        pageNumber: currentPage,
        size: itemsPerPage,
        isUsed: isUsedChecked
      })
        .then((res) => {
          const results = res?.data?.response?.bankFieldTechConfigResponse || [];
          setData(results);
          setTotalItems(res?.data?.response?.totalItems || 0);
          if (results.length === 0) {
            setMessage("No records found.");
            setSeverity("info");
            setOpenAlert(true);
          }
        })
        .catch((error) => {
          setMessage(error?.response?.data?.message || error?.message);
          setSeverity("error");
          setOpenAlert(true);
          setData([]);
          setTotalItems(0);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 500),
    [currentPage, itemsPerPage, fetchData]
  );

  const handleShowUsedRecordsChange = (event) => {
    const isChecked = event.target.checked;
    setShowUsedRecords(isChecked);
    
    // Trigger search with current search keyword and checkbox state
    debouncedSearch(searchKeyword, isChecked);
  };

  const handleSearchChange = (value) => {
    console.log('Search Value:', value);
    setSearchKeyword(value);
    
    // Trigger debounced search with current search value and used records state
    debouncedSearch(value, showUsedRecords);
  };

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    setItemsPerPage(itemsPerPage);
    fetchData(pageNumber - 1, itemsPerPage);
  };

  const refreshData = useCallback(() => {
    // Check if there's an active search or filter
    if (searchKeyword.trim() !== "" || showUsedRecords) {
      // If search keyword exists or used records filter is active
      BankFieldTechnicanConfigService.searchBank({ 
        keyword: searchKeyword,
        pageNumber: currentPage,
        size: itemsPerPage,
        isUsed: showUsedRecords
      })
        .then((res) => {
          const results = res?.data?.response?.bankFieldTechConfigResponse || [];
          setData(results);
          setTotalItems(res?.data?.response?.totalItems || 0);
          if (results.length === 0) {
            setMessage("No records found.");
            setSeverity("info");
            setOpenAlert(true);
          }
        })
        .catch((error) => {
          setMessage(error?.response?.data?.message || error?.message);
          setSeverity("error");
          setOpenAlert(true);
          setData([]);
          setTotalItems(0);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      // If no active filters, call the default getBankList
      fetchData(currentPage, itemsPerPage);
    }
  }, [
    searchKeyword, 
    showUsedRecords, 
    currentPage, 
    itemsPerPage, 
    fetchData, 
    setMessage, 
    setSeverity, 
    setOpenAlert
  ]);

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t("bankConfiguration.pageTitle")}
      background="#ffffff"
      useNewPagination={true}
    >
      <Grid
        container
        spacing={9}
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          p: 1,
          maxWidth: "100%",
          margin: "auto",
          paddingBottom: "8px",
          height: "130px"
        }}
      >
        <Grid item xs={12}  style={{ paddingTop: "0px", paddingLeft: "0px", paddingTop: "0px" }}>
          <Typography variant="h8" display="flex" sx={{ marginLeft: 2 }}>
            <ArrowDropDownIcon />
            {t("filtersLable")}
          </Typography>
        </Grid>

        <Grid 
          item 
          xs={3} 
          md={9} 
          sx={{ 
            paddingLeft: "0px !important",
            paddingTop: "0px !important",
          }}
        >
          <FormControl fullWidth>
            <TextField
              value={searchKeyword}
              onChange={(event) => handleSearchChange(event.target.value)}
              label={t("bankConfiguration.bank/abbreviation")}
              fullWidth
              sx={{ 
                marginLeft: 2,
                '& .MuiInputBase-root': {
                  height: '50px'
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{
                        color: "#102a62",
                        width: "42px",
                        height: "42px",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>

        <Grid 
          item 
          xs={12} 
          md={3} 
          sx={{
            paddingLeft: "100px",  
            paddingTop: "0px !important",
          }}
        >
          <Typography variant="subtitle2">
            <Checkbox
              sx={{ paddingLeft: "0px" }}
              checked={showUsedRecords}
              onChange={handleShowUsedRecordsChange}
              color="primary"
            />
            {t("usedRecords")}
          </Typography>
        </Grid>
      </Grid>

      {/* // Table Section */}
      {loading ? (
        <Typography variant="h6" align="center">
          {t("loading")}
        </Typography>
      ) : data.length === 0 ? (
        <Typography 
          variant="h6" 
          align="center" 
          sx={{ 
            marginTop: '2rem', 
            color: 'gray' 
          }}
        >
          {t("No Data Available")}
        </Typography>
      ) : (
        <BankConfigurationTable 
          TableData={data} 
          onRecordUpdate={refreshData}
        />
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between", 
          position: "fixed", 
          width: "100%", 
          left: 0, 
          bottom: "19px", 
          background: "white",
          zIndex: 999,
          padding: "0.5rem",
          borderTop: "1px solid var(--Gray-1, #E9EBEE)",
        }}
      >
        <Button
          style={{
            backgroundColor: "#737D86",
            padding: "6px 16px",
            borderRadius: "4px",
            marginLeft: "30px",
          }}
          variant="contained"
          onClick={() => navigate(-1)}
          startIcon={<img src={arrowLeft} alt="Arrow" />}
        >
          {t("back")}
        </Button>
      </Box>
    </Layout>
  );
};

export default BankConfiguration;
