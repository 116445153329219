import React, { useEffect, useState, useContext } from "react";
import { Typography, Box, Button, Modal } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import { useNavigate } from "react-router-dom";
import { SnackbarContext } from "context/snackBarContext";
import { useTranslation } from "react-i18next";
import MenuIcon1 from "assets/MenuIcon1.svg";
import DragIcon from "assets/_DragabbleIcon_.svg";
import SubMenuArea from './SubMenuArea';
import MSCModal from "./MSCModal";
import MSCService from "services/MSCService";

const tableContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
};

const tableStyle = {
  width: '100%',
  borderCollapse: 'collapse',
};

const tableRowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '16px',
  boxShadow: '-2px 1px 10px -2px #00000040',
  backgroundColor: 'white',
  borderRadius: '5px',
  marginRight: '2px',
  marginLeft: '2px',
};

const tableHeaderStyle = {
  color: '#3b454f',
  fontWeight: 600,
  fontSize: '1.125rem',
  borderBottom: '5px solid #102a62',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  padding: '12px',
  textAlign: 'left',
};

const tableCellStyle = {
  padding: '12px',
  textAlign: 'left',
  color: '#3b454f',
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
};

const actionButtonStyle = {
  cursor: 'pointer',
  padding: '5px',
  margin: '0 5px',
};

const MSCTable = ({ MSCData, onDelete, fetchData, selectedCountryId }) => {
  const navigate = useNavigate();
  const [data, setData] = useState(MSCData || []);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingRow, setEditingRow] = useState(null);

  useEffect(() => {
    setData(MSCData || []);
  }, [MSCData]);

  const handleDeleteClick = (id, event) => {
    // Prevent row selection when clicking delete
    event.stopPropagation();

    setDeleteId(id);
    setOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      // Call delete API with the menuId
      await MSCService.deleteAPI(deleteId, 'ADMIN');

      // Remove the item from the data array
      const updatedData = data.filter(row => row.menuId !== deleteId);
      setData(updatedData);
      
      // Call fetchData to refresh the entire list
      if (fetchData) {
        fetchData(selectedCountryId);
      }
      
      setMessage(t('deletedSuccessfully'));
      setSeverity("success");
      
      if (onDelete) {
        onDelete();
      }
    } catch (error) {
      setMessage(error?.response?.data?.message || "Error deleting record");
      setSeverity("error");
    } finally {
      setOpenAlert(true);
      setOpen(false);
    }
  };

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
    setData(
      [...data].sort((a, b) => {
        if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
        if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
        return 0;
      })
    );
  };

  const getSortIcon = (key) =>
    sortConfig.key === key ? (
      sortConfig.direction === "asc" ? (
        <ArrowUpwardIcon fontSize="small" />
      ) : (
        <ArrowDownwardIcon fontSize="small" />
      )
    ) : (
      <ArrowDownwardIcon fontSize="small" />
    );

  const handleEditClick = async (row, event) => {
    // Prevent row selection when clicking edit
    event.stopPropagation();

    try {
      // Fetch detailed menu information using the API
      const response = await MSCService.getSingleMenuDetailsAPI(row.menuId);
      
      if (response?.data?.response) {
        const menuDetails = response.data.response;
        
        // Open modal with fetched details
        setEditingRow({
          menuId: menuDetails.menuId,
          menuName: menuDetails.menuName,
          menuOrder: menuDetails.menuOrder?.toString() || '',
          menuType: menuDetails.menuType || '',
          menuIcon: menuDetails.menuIcon || null,
          url: menuDetails.link || '',
          countryId: row.countryId
        });
        setIsModalOpen(true);
      }
    } catch (error) {
      // Handle error if API call fails
      setMessage(error?.response?.data?.message || error?.message);
      setSeverity("error");
      setOpenAlert(true);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setEditingRow(null);
  };

  const handleModalConfirm = (updatedData) => {
    // Helper function to convert file to base64
    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };

    // Async function to process payload and update menu
    const updateMenu = async () => {
      try {
        let base64Content = null;
        let fileName = null;
        let isMenuIconChanged = false;

        // Handle image conversion
        if (updatedData.imageFile) {
          // If it's a File object
          if (updatedData.imageFile instanceof File) {
            base64Content = await getBase64(updatedData.imageFile);
            fileName = `menu_icon_${Date.now()}.${updatedData.imageFile.name.split('.').pop()}`;
            isMenuIconChanged = true;
          } 
          // If it's already a data URL
          else if (typeof updatedData.imageFile === 'string') {
            // Check if it's a URL or base64
            if (updatedData.imageFile.startsWith('http')) {
              // If it's a URL, we don't need to change the icon
              base64Content = null;
              isMenuIconChanged = false;
            } else if (updatedData.imageFile.startsWith('data:')) {
              // If it's a base64 data URL
              base64Content = updatedData.imageFile;
              fileName = `menu_icon_${Date.now()}.png`;
              isMenuIconChanged = true;
            }
          }
        }

        // Payload for updating menu
        const payload = {
          menuId: editingRow.menuId,
          menuName: updatedData.description,
          menuOrder: parseInt(updatedData.menuOrder, 10),
          menuType: updatedData.menuType,
          menuIcon: base64Content ? {
            fileName: fileName,
            base64FileContent: base64Content.split(',')[1] // Extract base64 content
          } : null,
          isMenuIconChanged: isMenuIconChanged,
          parentMenuId: null,
          link: updatedData.menuType === 'Link' ? updatedData.url : null,
          countryId: selectedCountryId || editingRow.countryId,
          languageId: null,
          createdByUser: "ADMIN",
          modifiedByUser: "ADMIN"
        };

        // Call API to update menu
        const response = await MSCService.createMenuAPI(payload);
        
        // Refresh the data after successful update
        if (fetchData) {
          fetchData(selectedCountryId); // Use the passed fetchData method with selected country
        }

        // Close the modal
        handleModalClose();

        // Show success message
        setMessage(t('soilAnalysisApplicationPhase.messages.updateSuccess'));
        setSeverity("success");
        setOpenAlert(true);
      } catch (error) {
        // Handle error
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
      }
    };

    // Call the async function
    updateMenu();
  };

  // Modify handleEditClick to handle row selection
  const handleRowClick = (row) => {
    // If the same row is clicked again, deselect it
    setSelectedRow(prevRow => prevRow?.menuId === row.menuId ? null : row);
  };

  // Update table styles to be responsive to editing state
  const getTableStyle = () => ({
    ...tableStyle,
    tableLayout: 'fixed',
    width: selectedRow  ? '40vw' : '100%', // Shrink width when editing
    transition: 'width 0.3s ease-in-out',
    marginLeft: selectedRow ? '0' : 'auto', // Push to the left when editing
    marginRight: selectedRow ? 'auto' : '0'
  });

  return (
    <div 
      style={{
        ...tableContainerStyle,
        display: 'flex', // Use flex to allow area expansion
        alignItems: 'stretch', // Stretch to full height
      }}
    >
      <table style={getTableStyle()}>
        <thead>
          <tr style={tableRowStyle}>
            <th style={{...tableHeaderStyle, width: '10vw'}}>No.</th>
            <th 
              style={{...tableHeaderStyle, width: '10vw'}}
              onClick={() => handleSort('applicationPhase')}
            >
              Menu Icons {getSortIcon('applicationPhase')}
            </th>
            <th 
              style={{...tableHeaderStyle, width: '40vw'}}
              onClick={() => handleSort('description')}
            >
              Description {getSortIcon('description')}
            </th>
            <th 
              style={{...tableHeaderStyle, width: '30vw'}}
              onClick={() => handleSort('menuType')}
            >
              Menu Type {getSortIcon('menuType')}
            </th>
            <th style={{...tableHeaderStyle, width: '10vw'}}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={row.menuId} 
            style={{
              ...tableRowStyle,
              backgroundColor: selectedRow?.menuId === row.menuId 
                ? '#f0f0f0'  // Highlight selected row
                : 'white'
            }}
            onClick={() => handleRowClick(row)}
            >
              <td style={{...tableCellStyle, width: '10vw'}}>
                <div 
                  style={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    justifyContent: 'center' 
                  }}
                >
                  <span 
                    sx={{
                      fontFamily: 'Montserrat',
                      fontSize: '12px',
                      fontWeight: 300,
                      textAlign: 'right',
                      textDecorationSkipInk: 'none',
                      marginBottom: '8px', 
                      fontWeight: 'bold'
                    }}
                  >
                    Order
                  </span>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {/* DragIcon Div */}
                    <div>
                    <img 
                      src={DragIcon} 
                      alt="Drag" 
                      style={{ marginRight: '8px', width: '21px', height: '28px' }} 
                    />
                    </div>

                    {/* Number Div */}
                    <div>
                      {row.menuOrder}
                    </div>
                  </div>
                </div>
              </td>
              <td style={{...tableCellStyle, width: '10vw'}}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <span 
                    sx={{
                      fontFamily: 'Montserrat',
                      fontSize: '12px',
                      fontWeight: 300,
                      textAlign: 'right',
                      textDecorationSkipInk: 'none',
                      marginBottom: '8px', 
                      fontWeight: 'bold'
                    }}
                  >
                    Menu Icon
                  </span>
                  <img 
                    src={
                      row.menuIcon?.startsWith('http') 
                        ? `${row.menuIcon}${process.env.REACT_APP_SAS}`
                        : (row.menuIcon ? `data:image/png;base64,${row.menuIcon}` : MenuIcon1)
                    } 
                    alt="Menu Icon" 
                    style={{ 
                      width: '30px', 
                      height: '30px', 
                      objectFit: 'contain' 
                    }} 
                    onError={(e) => {
                      // Fallback to default icon if image fails to load
                      e.target.src = MenuIcon1;
                    }}
                  />
                </div>
              </td>
              <td style={{...tableCellStyle, width: '40vw'}}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                  <span 
                    sx={{
                      fontFamily: 'Montserrat',
                      fontSize: '12px',
                      fontWeight: 300,
                      textAlign: 'right',
                      textDecorationSkipInk: 'none',
                      marginBottom: '8px', 
                      fontWeight: 'bold'
                    }}
                  >
                    Description
                  </span>
                  <span 
                    sx={{
                      fontFamily: 'Montserrat',
                      fontSize: '26px',
                      fontWeight: 400,
                      textAlign: 'left',
                      textDecorationSkipInk: 'none',
                      color: '#3B454F',
                    }}
                  >
                    {row.menuName || 'N/A'}
                  </span>
                </div>
              </td>
              <td style={{...tableCellStyle, width: '30vw'}}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                  <span 
                    sx={{
                      fontFamily: 'Montserrat',
                      fontSize: '12px',
                      fontWeight: 300,
                      textAlign: 'right',
                      textDecorationSkipInk: 'none',
                      marginBottom: '8px', 
                      fontWeight: 'bold'
                    }}
                  >
                    Menu Type
                  </span>
                  <span>{row.menuType || 'N/A'}</span>
                </div>
              </td>
              <td style={{...tableCellStyle, width: '10vw'}}>
                <img
                  style={actionButtonStyle}
                  src={_ButtonEdit_}
                  alt="Edit Button"
                  onClick={(event) => handleEditClick(row, event)}
                />
                <img
                  style={actionButtonStyle}
                  src={_ButtonDelete_}
                  alt="Delete"
                  onClick={(event) => handleDeleteClick(row.menuId, event)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedRow && (
        <SubMenuArea 
          selectedRow={selectedRow} 
          selectedCountryId={selectedCountryId}
        />
      )}

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "520px",
            margin: "auto",
            mt: 25,
            height: "200px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              {t("deleteModal.heading")}
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography sx={{ mt: 2, fontWeight: 400 }}>
              Are you sure you want to delete this record? Deleted records
              cannot be recovered.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={() => setOpen(false)}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              {t('no')}
            </Button>
            <Button
              onClick={handleDeleteConfirm}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              {t('yes')}
            </Button>
          </Box>
        </Box>
      </Modal>

      <MSCModal
        open={isModalOpen}
        handleCloseModal={handleModalClose}
        title="Edit Menu Item"
        confirmText="UPDATE"
        initialData={{
          menuOrder: editingRow?.menuOrder?.toString() || '',
          description: editingRow?.menuName || '',
          menuType: editingRow?.menuType || '',
          menuIcon: editingRow?.menuIcon || null,
          url: editingRow?.url || '',
        }}
        onConfirm={handleModalConfirm}
      />
    </div>
  );
};

export default MSCTable;
