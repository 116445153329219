import { httpGrow } from "../http-common";

const getTree = async (query) => {
  const response = await httpGrow.get(
    `agricultural-supply-unlock-sale?cropYearId=${query?.cropYearId}&geneticTypeId=${query?.geneticTypeId}&releaseModality=${query?.releaseModality}&ihID=${query?.ihID}`
  );
  return response;
};
const getAgriSupply = (asrSupplyId) => {
  const response = httpGrow.get(`/agricultural-supplies/${asrSupplyId}`);
  return response;
};
const updateTree = (data) => {
  return httpGrow.put(`/agricultural-supply-unlock-sale`, data);
};

const UnlockForSaleManagementService = {
  getTree,
  getAgriSupply,
  updateTree
};

export default UnlockForSaleManagementService;
