import React, { useContext, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import Button from "@mui/material/Button";
import arrowDown from "assets/arrowDown.svg";
import { StyledTreeItem } from "utils/Styledtreeitem";
import { useSelector, useDispatch } from "react-redux";
import { generalInformationSchema } from "utils/validations";
import { updateGeneralInformation } from "slices/generalInformation";
import RenderField from "utils/RenderField";
import { generalFormConfig } from "./FormConfig";
import { SnackbarContext } from "context/snackBarContext";
import FarmerRegisterationService from "services/FarmerRegistrationService";
import { useLocation, useParams } from "react-router-dom";
import {
  convertToDateString,
  toBoolean,
  toStringBoolean,
} from "utils/CommonFunction";
import PickListService from "services/PickListService";
import { useTranslation } from "react-i18next";
import { Divider, Grid, Modal, TextField, Typography } from "@mui/material";
import MapboxMap from "components/pages/Growing/Soil Analysis Registration/MapBox";
import "mapbox-gl/dist/mapbox-gl.css";

export default function GeneralInformation({ handleTabChange }) {
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const { t } = useTranslation();
  const [openMap, setOpenMap] = useState(false);

  const location = useLocation();
  const { edit, details } = location.state || {};

  console.log("🚀 ~ ISEDit:", edit, details);

  console.log("🚀 ~ GeneralInformation ~ id:", id);
  const generalInformation = useSelector(
    (state) => state?.farmers?.generalInformation
  );
  console.log(
    "🚀 ~ GeneralInformation ~ generalInformation:",
    generalInformation
  );
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [latitude, setLatitude] = useState("");
  console.log("🚀 ~ GeneralInformation ~ latitude:", latitude);
  const [longitude, setLongitude] = useState("");
  const [altitude, setAltitude] = useState("");
  const [farmerClassification,setFarmerClassification] = useState([])
  console.log("🚀 ~ GeneralInformation ~ farmerClassification:", farmerClassification)

  const [values, setValues] = useState({
    ...generalInformation,
  });
  console.log("🚀 ~ GeneralInformation ~ values:", values);
  const [errors, setErrors] = useState({});
  console.log("🚀 ~ GeneralInformation ~ errors:", errors);
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
    altitude: null,
  });
  const [dateError, setDateError] = useState(false);
  const [picklistData, setPicklistData] = useState([]);
  const [generalFormConfigs, setGeneralFormConfigs] =
    useState(generalFormConfig);
  console.log(
    "🚀 ~ GeneralInformation ~ generalFormConfigs:",
    generalFormConfigs
  );

  console.log(
    "🚀 ~ GeneralInformation ~ generalInformation:",
    generalInformation
  );

  console.log("dcfdc", toStringBoolean(values.dripIrrigation));

  const getFarmerUniqueCode = async () => {
    try {
      const response = await FarmerRegisterationService.getFarmerUniqueCode();
      return response.data.response;
    } catch (error) {
      console.error("Error generating unique code:", error);
      return null;
    }
  };

  const handleNextClick = (event) => {
    handleTabChange(event, 2); // Move to tab index 2
  };


  const handleClose = () => {
    setCoordinates({ lat: "", lng: "", altitude: "" });
    setOpenMap(false);
  };

  const handleMapClick = async (obj) => {
    setCoordinates({
      lat: parseFloat(obj?.latitude).toFixed(6),
      lng: parseFloat(obj?.longitude).toFixed(6),
      altitude: parseFloat(obj?.altitude || 0).toFixed(6),
    });

    setValues((prev) => ({
      ...prev,
      latitudeDms: parseFloat(
        parseFloat(obj?.latitude).toFixed(6)
      ),
      longitudeDms: parseFloat(
        parseFloat(obj?.longitude).toFixed(6)
      ),
      altitude: parseFloat(parseFloat(obj?.altitude || 0).toFixed(6)),
    }));
  };

  const handleOpen = () => {
    setOpenMap(true);
  };

  const handleSetLocation = () => {
    if (coordinates.lat && coordinates.lng) {
      setLatitude(coordinates.lat);
      setLongitude(coordinates.lng);
      setAltitude(coordinates.altitude || "");
      handleClose();
    }
  };

  useEffect(() => {
    const fetchPicklistData = async () => {
      try {
        const response = await PickListService.picklist({
          pickListCodes: [
            // "FRGINFMI",
            "FRGINFC47",
            "FRGINFNC",
            // "FRGINFUDI",
            // "FRGINFAL",
            "FRGINFRE1",
          ],
        });
        const picklistResponse = response.data.response;

        const groupedPicklistData = Object.keys(picklistResponse).map(
          (pickListCode) => ({
            pickListCode,
            items: picklistResponse[pickListCode].map((item) => ({
              pickListItemCode: item.pickListItemCode,
              pickListDescription: item.pickListDescription,
            })),
          })
        );

        setPicklistData(groupedPicklistData);

        const minorsUnder18 =
          groupedPicklistData
            .find((picklist) => picklist.pickListCode === "FRGINFMI")
            ?.items.map((item) => ({
              value: item.pickListItemCode,
              label: item.pickListDescription,
            })) || [];

        const adultsOver18 =
          groupedPicklistData
            .find((picklist) => picklist.pickListCode === "FRGINFAL")
            ?.items.map((item) => ({
              value: item.pickListItemCode,
              label: item.pickListDescription,
            })) || [];

        const dripIrrigation =
          groupedPicklistData
            .find((picklist) => picklist.pickListCode === "FRGINFUDI")
            ?.items.map((item) => ({
              value: item.pickListItemCode,
              label: item.pickListDescription,
            })) || [];

        const city =
          groupedPicklistData
            .find((picklist) => picklist.pickListCode === "FRGINFC47")
            ?.items.map((item) => ({
              value: item.pickListItemCode,
              label: item.pickListDescription,
            })) || [];

        const country =
          groupedPicklistData
            .find((picklist) => picklist.pickListCode === "FRGINFNC")
            ?.items.map((item) => ({
              value: item.pickListItemCode,
              label: item.pickListDescription,
            })) || [];
        const regions =
          groupedPicklistData
            .find((picklist) => picklist.pickListCode === "FRGINFRE1")
            ?.items.map((item) => ({
              value: item.pickListItemCode,
              label: item.pickListDescription,
            })) || [];
        console.log("regions", regions);
        setGeneralFormConfigs((prevConfig) =>
          prevConfig.map((field) => {
            if (field.name === "region") {
              return {
                ...field,
                options: [...regions],
              };
            }
            if (field.name === "nationality" && !edit) {
              return {
                ...field,
                disabled: false,
              };
            }
            // if (field.name === "dripIrrigation") {
            //   return {
            //     ...field,
            //     options: [...dripIrrigation],
            //   };
            // }
            // if (field.name === "minorsUnder18") {
            //   return {
            //     ...field,
            //     options: [...minorsUnder18],
            //   };
            // }
            // if (field.name === "adultsOver18") {
            //   return {
            //     ...field,
            //     options: [...adultsOver18],
            //   };
            // }
            if (field.name === "city") {
              return {
                ...field,
                options: [...city],
              };
            }
            if (field.name === "farmerCountry") {
              return {
                ...field,
                options: [...country],
              };
            }
            if (field.name === "regionOfBirth") {
              return {
                ...field,
                options: [...regions],
              };
            }
            return field;
          })
        );
      } catch (error) {
        console.error("Error fetching picklist data:", error);
      }
    };

    fetchPicklistData();
  }, []);

  const getFarmerByIds = async () => {
    try {
      const response = await FarmerRegisterationService.getFarmerById({ id });
      const values = response.data.response;
      // Extract farmerClassification
      const farmerClassification =
      values.farmerClasificationDetails?.[values.farmerClasificationDetails.length - 1]?.fcId || null;
    

    // Add farmerClassification to the values object
    const updatedValues = {
      ...values,
      farmerClassification,
    };

    // Update the state with the augmented values
    setValues(updatedValues);


      dispatch(
        updateGeneralInformation({
          farmerId: values.farmerId,
          farmerFullName: values.farmerFullName,
          farmerCode: values.farmerCode,
          fiscalIdCpf: values.fiscalIdCpf,
          dateOfBirth: convertToDateString(values.dateOfBirth),
          gender: values.gender,
          email: values.email,
          nationalId: values.nationalId,

          dripIrrigation: values.dripIrrigation,
          farmerCountry: values.farmerCountry,
          region: values.region,
          city: values.city,
          locality: values.locality,
          zipCode: values.zipCode,
          issuingAuthority: values.issuingAuthority,
          regionOfBirth: values.regionOfBirth,
          maritalStatus: values.maritalStatus,
          fatherName: values.fatherName,
          motherName: values.motherName,
          minorsUnder18: values.minorsUnder18,
          adultsOver18: values.adultsOver18,
          latitude: values.latitudeDms,
          longitude: values.longitudeDms,
          emissionDt: convertToDateString(values.emissionDt),
          residence: values.residence,
          nationalityCountry: values.nationalityCountry,
          nationality: values.nationality,
          spouseName: values.spouseName,
          altitude: values.altitude,
          externalCode: values.externalCode,
          idCard: values.idCard,
          telePhoneNumber:values.telePhoneNumber,
          postalCode:values.postalCode,
          farmerClassification:farmerClassification
        })
      );

      

      return values;
    } catch (error) {
      console.error("Error getting farmer by Id:", error);
      return null;
    }
  };

  const getFarmerClassification = async () => {
    try {
      const response = await FarmerRegisterationService.getFarmerClassification();
      const values = response.data.response.FarmerClassifications;
      const classification =
      values?.map((item) => ({
          value: item.fcId,
          label: item.fcCategory,
        })) || [];
      // setGeneralFormConfigs((prevConfig) =>
      //   prevConfig.map((field) => {
      //     if (field.name === "farmerClassification") {
      //       return {
      //         ...field,
      //         options: [...classification],
      //       };
      //     }
       
      //     return field;
      //   })
      // );

      setGeneralFormConfigs((prevConfig) =>
        prevConfig.map((field) => {
          if (field.name === "farmerClassification") {
            console.log("Setting options:", classification);
            console.log("Default value:", farmerClassification);
            return {
              ...field,
              options: [...classification],
              value: farmerClassification, // Ensure value matches the API response
            };
          }
          return field;
        })
      );
      
    
    } catch (error) {
      console.error("Error getting farmer by Id:", error);
      return null;
    }
  };

  useEffect(() => {
      getFarmerByIds();
      getFarmerClassification()
  }, []);

  useEffect(() => {
    if (!id) {
      const setFarmerCode = async () => {
        const uniqueCode = await getFarmerUniqueCode();
        setValues((prevValues) => ({
          ...prevValues,
          farmerCode: uniqueCode,
        }));
        dispatch(updateGeneralInformation({ farmerCode: uniqueCode }));
      };

      setFarmerCode();
    }
  }, [dispatch]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const newValue =
      name === "altitude" || name === "latitude" || name === "longitude"
        ? value === "0" || value === "0."
          ? value
          : value.replace(/^0+(?!\.)/, "")
        : value;
    setValues((prevValues) => {
      if (name === "maritalStatus" && value === "Single") {
        return {
          ...prevValues,
          [name]: value,
          spouseName: "",
        };
      }

      return {
        ...prevValues,
        [name]: newValue,
      };
    });
  };

  const handleDateChange = (date, fieldName) => {
    if (!date || date === "Invalid Date") {
      setDateError(true);
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
      return;
    }

    setDateError(false);

    const dateValue = new Date(date);
    if (isNaN(dateValue.getTime())) {
      setDateError(true);
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
      return;
    }

    const year = dateValue.getFullYear();
    const month = String(dateValue.getMonth() + 1).padStart(2, "0");
    const day = String(dateValue.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setValues((prevValues) => ({
      ...prevValues,
      [fieldName]: formattedDate,
    }));
  };

  // const handleDateChange = (date) => {
  //   if (!date || date === "Invalid Date") {
  //     setDateError(true);
  //     setValues((prevValues) => ({
  //       ...prevValues,
  //       dateOfBirth: "",
  //     }));
  //     return;
  //   }

  //   setDateError(false);

  //   const dateValue = new Date(date);
  //   const year = dateValue.getFullYear();
  //   const month = String(dateValue.getMonth() + 1).padStart(2, "0");
  //   const day = String(dateValue.getDate()).padStart(2, "0");
  //   const formattedDate = `${year}-${month}-${day}`;

  //   setValues((prevValues) => ({
  //     ...prevValues,
  //     dateOfBirth: formattedDate,
  //   }));
  // };




  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await generalInformationSchema.validate(values, { abortEarly: false });
      setErrors({});
      dispatch(
        updateGeneralInformation({
          farmerFullName: values.farmerFullName,
          farmerCode: values.farmerCode,
          fiscalIdCpf: values.fiscalIdCpf,
          dateOfBirth: convertToDateString(values.dateOfBirth),
          gender: values.gender,
          email: values.email,
          nationalId: values.nationalId,
          dripIrrigation: values.dripIrrigation,
          farmerCountry: values.farmerCountry,
          region: values.region,
          city: values.city,
          locality: values.locality,
          zipCode: values.zipCode,
          issuingAuthority: values.issuingAuthority,
          regionOfBirth: values.regionOfBirth,
          maritalStatus: values.maritalStatus,
          fatherName: values.fatherName,
          motherName: values.motherName,
          minorsUnder18: values.minorsUnder18,
          adultsOver18: values.adultsOver18,
          latitudeDms: values.latitudeDms,
          longitudeDms: values.longitudeDms,
          nationality: values.nationality,
          emissionDt: convertToDateString(values.emissionDt),
          residence: values.residence,
          nationalityCountry: values.nationalityCountry,
          spouseName: values.spouseName,
          altitude: values.altitude,
          externalCode: values.externalCode,
          idCard: values.idCard,
          pob: values.pob,
          issueDate: values.issueDate,
          farmerClassification: values.farmerClassification,
          address: values.address,
          telePhoneNumber: values.telePhoneNumber,
          postalCode:values.postalCode
        })
      );
      setSeverity("success");
      handleNextClick(event);
      setMessage(t("snackbarMessage.savedSuccessfully"));
      setOpenAlert(true);
      // handleTabChange(event, 1)
      console.log("displathc Successdullt");
    } catch (err) {
      console.log("rtt", err);
      const validationErrors = {};
      err?.inner?.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
    }
  };

  const getCoordinates = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude, altitude } = position.coords;
          const fetchAltitude = async (lat, lng) => {
            try {
              const response = await fetch(
                `https://api.open-elevation.com/api/v1/lookup?locations=${lat},${lng}`
              );
              const data = await response.json();
              if (data.results && data.results[0]) {
                return data.results[0].elevation.toFixed(6);
              }
              return "";
            } catch (error) {
              console.error("Error fetching altitude:", error);
              return "";
            }
          };

          const finalAltitude =
            altitude !== undefined && altitude !== null
              ? altitude.toFixed(6)
              : await fetchAltitude(latitude, longitude);
          setValues((prevValues) => ({
            ...prevValues,
            latitude: latitude.toFixed(6),
            longitude: longitude.toFixed(6),
            altitude: finalAltitude,
          }));
        },
        (error) => {
          console.error("Error getting coordinates:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleClear = (field) => {
    if (field === "maritalStatus") {
      setValues((prevValues) => ({
        ...prevValues,
        [field]: "",
        spouseName: "",
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [field]: "",
      }));
    }
  };

  useEffect(() => {
    setGeneralFormConfigs((prevFields) =>
      prevFields.map((field) => {
        if (field.name === "spouseName") {
          if (values.maritalStatus === "Married") {
            return {
              ...field,
              required: true,
              disabled: false,
            };
          } else {
            return {
              ...field,
              required: false,
              disabled: true,
            };
          }
        }
        return field;
      })
    );
  }, [values.maritalStatus]);

  return (
    <Box
      sx={{
        minHeight: 180,
        marginLeft: "0px",
        flexGrow: 10,
        width: "100%",
        paddingBottom: "10rem",
        position:"relative",
        marginTop:"20px",
        "& .MuiTextField-root": { m: 1, color: "red", width: "25ch" },
        "& .MuiFormLabel-root": {
          color: "rgba(16, 42, 98, 1)",
          "& .MuiFormLabel-asterisk": {
            color: "rgba(239, 0, 0, 1)",
            fontSize: "18px",
          },
        },
      }}
    >
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <TreeView
          aria-label="file system navigator"
          defaultCollapseIcon={<img src={arrowDown} alt="Down Arrow" />}
          defaultExpandIcon={<ChevronRightIcon />}
          defaultExpanded={["1", "2", "3", "4", "5"]}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Box
              sx={{
                width: "40%",
                background: "#EFEFEF",
                padding: "5px",
                borderRadius: "10px",
              }}
            >
              <StyledTreeItem nodeId="2" labelText="System Information">
                {generalFormConfigs.slice(0, 2).map((field) => (
                  <RenderField
                    key={field.name}
                    field={field}
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    handleDateChange={handleDateChange}
                    handleClear={handleClear}
                    details={details === true ? true : false}
                  />
                ))}
              </StyledTreeItem>
            </Box>
            <Box
              sx={{
                width: "57.5%",
                background: "#EFEFEF",
                padding: "5px",
                borderRadius: "10px",
              }}
            >
              <StyledTreeItem
                nodeId="5"
                labelText="Property General Information"
              >
                {generalFormConfigs.slice(2, 4).map((field) => (
                  <RenderField
                    key={field.name}
                    field={field}
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    handleDateChange={handleDateChange}
                    handleClear={handleClear}
                    details={details === true ? true : false}
                  />
                ))}
              </StyledTreeItem>
            </Box>
          </Box>
          <Box
            sx={{
              background: "#EFEFEF",
              padding: "5px",
              borderRadius: "10px",
              mb: 2,
            }}
          >
            <StyledTreeItem nodeId="1" labelText="Personal Information">
              {generalFormConfigs.slice(4, 17).map((field) => (
                <RenderField
                  key={field.name}
                  field={field}
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  handleDateChange={handleDateChange}
                  handleClear={handleClear}
                  details={details === true ? true : false}
                />
              ))}
            </StyledTreeItem>
          </Box>
          <Box
            sx={{
              background: "#EFEFEF",
              padding: "5px",
              borderRadius: "10px",
              mb: 2,
            }}
          >
            <StyledTreeItem
              nodeId="3"
              labelText="Contact and Address Information"
            >
              {generalFormConfigs.slice(17).map((field) => (
                <RenderField
                  key={field.name}
                  field={field}
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  handleDateChange={handleDateChange}
                  handleClear={handleClear}
                  details={details === true ? true : false}
                />
              ))}
              <Button
                onClick={handleOpen}
                sx={{ mt: 2, background: "#004F9F" }}
                variant="contained"
                disabled={details === true ? true : false}
              >
                Select on map
              </Button>
              <Modal
                open={openMap}
                onClose={handleClose}
                aria-labelledby="map-modal-title"
                aria-describedby="map-modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "60%",
                    height: "90%",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    borderRadius: 2,
                  }}
                >
                  <Box
                    sx={{
                      background: "#102A62",
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#ffffff",
                        fontSize: "20px",
                        fontWeight: 500,
                      }}
                    >
                      {t("SoilAnalysisRegistration.modals.selectGeoLocation")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      p: 3,
                    }}
                  >
                    <MapboxMap
                      initialCoordinates={
                        latitude && longitude
                          ? {
                              latitude: parseFloat(latitude),
                              longitude: parseFloat(longitude),
                              altitude: parseFloat(altitude) || 0,
                            }
                          : null
                      }
                      setCoordinatesParent={(obj) => handleMapClick(obj)}
                    />
                    {/* Row for latitude, longitude, and altitude */}
                    <Grid container spacing={2} sx={{ marginTop: 1 }}>
                      <Grid item xs={4}>
                        <TextField
                          label={t(
                            "SoilAnalysisRegistration.formLabels.latitude"
                          )}
                          variant="outlined"
                          fullWidth
                          value={coordinates.lat || latitude || ""} // Use existing latitude if available
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label={t(
                            "SoilAnalysisRegistration.formLabels.longitude"
                          )}
                          variant="outlined"
                          fullWidth
                          value={coordinates.lng || longitude || ""} // Use existing longitude if available
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label={t(
                            "SoilAnalysisRegistration.formLabels.altitude"
                          )}
                          variant="outlined"
                          fullWidth
                          value={coordinates.altitude || altitude || ""} // Use existing altitude if available
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>

                    {/* Divider and Buttons */}
                    <Divider sx={{ marginY: 2 }} />
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      gap={2}
                      marginTop={2}
                    >
                      <Button
                        style={{
                          backgroundColor: "#737D86",
                          padding: "6px 16px",
                          borderRadius: "4px",
                          minHeight: 40,
                        }}
                        onClick={handleClose}
                        variant="contained"
                      >
                        {t("cancel")}
                      </Button>
                      <Button
                        sx={{
                          borderRadius: 1,
                          minHeight: 40,
                          background: "#004F9F",
                          width: "150px",
                        }}
                        variant="contained"
                        onClick={handleSetLocation}
                      >
                        {t("SoilAnalysisRegistration.buttons.setLocation")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Modal>
            </StyledTreeItem>
          </Box>
          {/* <StyledTreeItem nodeId="4" labelText="Geographic Coordinates">
            {generalFormConfigs.slice(27).map((field) => (
              <RenderField
                key={field.name}
                field={field}
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleDateChange={handleDateChange}
                handleClear={handleClear}
                details={details === true ? true : false}
              />
            ))}
          
          </StyledTreeItem> */}
        </TreeView>
        {/* {edit &&  */}
        {!details &&
        <Button
        style={{
          marginBottom: "10px",
          position:"fixed",
          bottom: "20px", // Places it 20px from the bottom
          left: "60%", // Horizontally centers it in the viewport
          transform: "translateX(-50%)", // Centers the button by offsetting half its width
          zIndex: "123",
          width:"130px",
          height:"46px",
          color: "white",
          background: "#004F9F",
        }}
        onClick={handleSubmit}
        type="submit"
        variant="contained"
        >
          Save & Next
        </Button>
        }
        {/* } */}
      </form>
    </Box>
  );
}
