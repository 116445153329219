import { httpGrow } from "../http-common";

const getVarietySearch = async (query) => {
    const { 
        pageNumber = 0, 
        size = 50, 
        cuCultureId,
        vrVariety,
        gtGeneticTypeId,
        isActive 
    } = query;
    
    const response = await httpGrow.get(`/variety/search`, {
        params: {
            pageNumber,
            size,
            cuCultureId: cuCultureId || undefined,
            vrVariety: vrVariety || undefined,
            gtGeneticTypeId: gtGeneticTypeId || undefined,
            isActive: isActive !== undefined ? isActive : undefined
        }
    });
    return response;
};


const getVariety = (query) => {
    return httpGrow.get(
      `/variety?pageNumber=${query.pageNumber}&pageSize=${query.size}&countryCode=BRA`
    );
};

const getCuringUniqueCode = () => {
return httpGrow.get(`/curingunit/CuringCode`);
};

// const updateVariety = (data) => {
// return httpGrow.put(`/variety/update-variety-registration`, data);
// };

// Update the updateVariety method
const updateVariety = (data) => {
  return httpGrow.put('/variety/update', data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json'
    }
  });
};

const createVariety = (data) => {
  return httpGrow.post('/variety/save', data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json'
    }
  });
};

const searchVariety = ({ page, size, searchParams }) => {
const params = {
    pageNumber: page,
    pageSize: size,
    ...searchParams,
};

return httpGrow.get('variety/variety-filter', { params });
};
const searchVarietyUnitName= ({searchParam, page, size}) => {
const params = {
    searchParam:searchParam,
    pageNumber: page,
    pageSize: size,
};

return httpGrow.get('variety/variety-filter', { params });
};
const getCultureData = (countryCode) => {
  return httpGrow.get(`/culture`, {
    params: {
      pageNumber: 0,
      pageSize: 999999 // Adjust this number based on your needs
    }
  });
};

const deleteVariety = (vrVarietyId) => {
return httpGrow.delete(`/variety/delete/${vrVarietyId}`);
}

// Add new method to get genetic types
const getGeneticTypesByCultureName = (cultureName) => {
  return httpGrow.get(`/geneticType/genetic-type-cultureName/${cultureName}`);
};

// Add new method to get genetic types by culture
const getGeneticTypesByCulture = (cultureId) => {
  return httpGrow.get(`/geneticType/genetic-type-cultureId/${cultureId}`);
};

// Add new method to get spacing options by culture
const getSpacingByCulture = (cultureId) => {
  return httpGrow.get(`/spacing-registration/spacing-registration-cultureId/${cultureId}`)
    .then(response => {
      console.log('Spacing API Response:', response.data); // Debug log
      const formattedSpacing = response.data.response.map(spacing => ({
        value: spacing.spacingRegistrationUid,
        label: `${spacing.lineSpacing} x ${spacing.plantSpacing}`,
        lineSpacing: spacing.lineSpacing,
        plantSpacing: spacing.plantSpacing,
        spacingRegistrationUid: spacing.spacingRegistrationUid
      }));
      return { ...response, data: { ...response.data, response: formattedSpacing }};
    });
};

// Add new method to get culture types by genetic type ID
const getCultureTypeByGeneticType = (geneticTypeId) => {
  return httpGrow.get(`/culture-type/culture-type-geneticTypeId/${geneticTypeId}`);
};

// Add new method to get variety details by ID
const getVarietyDetailsById = (varietyId) => {
  return httpGrow.get(`/variety/${varietyId}`);
};


const VarietyRegistrationService = {
  getVarietySearch,
  getVariety,
  getCuringUniqueCode,
  updateVariety,
  createVariety,
  searchVariety,
  getCultureData,
  searchVarietyUnitName,
  deleteVariety,
  getGeneticTypesByCulture,
  getSpacingByCulture,
  getCultureTypeByGeneticType,
  getVarietyDetailsById,
  getGeneticTypesByCultureName
};

export default VarietyRegistrationService;