import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Grid,
  TextField,
  MenuItem,
  Icon,
  Box,
  FormControl,
  InputAdornment,
  Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import plusIcon from "assets/plusIcon.svg";
import editButton from "assets/editButton.svg";
import iconEdit from "assets/iconEdit.svg";
import iconView from "assets/iconView.svg";
import iconDelete from "assets/iconDelete.svg";
import { useNavigate } from "react-router-dom";
import { GridActionsCellItem } from "@mui/x-data-grid";
import "./Farmers.css";
import Layout from "components/structure/Layout";
import { useSelector } from "react-redux";
import { SnackbarContext } from "context/snackBarContext";
import FarmerRegisterationService from "services/FarmerRegistrationService";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { debounce } from "lodash";
import FarmerDataService from "services/FarmerService";
import iconSearchs from "../../../../../../assets/iconSearchs.svg";
import label from "../../..//growingLabel.json";
import { useTranslation } from "react-i18next";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CuringTypeTable from "./CuringTypeTable";


const FarmerNew = () => {
    const navigate = useNavigate();
  const { t } = useTranslation();
    const farmerObj = useSelector((state) => state.farmerObj.farmerObj);
    const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
    const [farmerData, setFarmerData] = useState(null);
    console.log("🚀 ~ Farmers ~ farmerData:", farmerData);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(7); // Number of items per page
    const [totalItems, setTotalItems] = useState(5); // Number of items per page
    const [recordsError, setRecordsError] = useState(false);
    const [searchParams, setSearchParams] = useState({
      search: "",
      region: "",
      status: "",
    });
    const [descSearch, setDescSearch] = useState({ search: "" });
  
    console.log("🚀 ~ Farmers ~ farmerObj:", farmerObj);
  
    const callFarmerApi = (pNumber, size) => {
      // setLoading(true);
      // setRecordsError(false);
  
      // FarmerRegisterationService.getAll({
      //   batId: 85168485,
      //   pageNumber: pNumber,
      //   size: size,
      // })
      //   .then((res) => {
      //     setCurrentPage(pNumber);
      //     setItemsPerPage(size);
      //     setLoading(false);
      //     setRecordsError(false);
      //     setFarmerData(res?.data?.response?.farmers);
      //     setTotalItems(res?.data?.response?.totalItems);
      //   })
      //   .catch((error) => {
      //     setMessage(error?.response?.data?.message || error?.message);
      //     setOpenAlert(true);
      //     setSeverity("error");
      //   });
      const cleanedParams = cleanSearchParams(searchParams);
      FarmerDataService.searchFarmer({
        searchParams: cleanedParams,
        page: pNumber,
        size: itemsPerPage,
      })
        .then((res) => {
          setLoading(false);
          setItemsPerPage(size);
          setCurrentPage(res?.data?.response?.currentPage);
          setTotalItems(res?.data?.response?.totalItems);
          setFarmerData(res?.data?.response?.farmers);
          console.log("FARMREG", res?.data?.response);
          setRecordsError(false);
        })
        .catch((error) => {
          setMessage(error?.response?.data?.message || error?.message);
          setOpenAlert(true);
          setSeverity("error");
          setRecordsError(true);
          console.log(error);
        });
    };
  
  
    useEffect(() => {
      callFarmerApi(currentPage, itemsPerPage);
    }, []);
  
    const plusIconComp = (
      <Icon>
        <img
          alt="edit"
          style={{ display: "flex", justifyContent: "center" }}
          width="100%"
          height="100%"
          src={plusIcon}
        />
      </Icon>
    );
  
    const handleChange = (event) => {
      const { name, value } = event.target;
      setSearchParams({
        ...searchParams,
        [name]: value,
      });
    };
  
    const handleSearch = useCallback(
      debounce(() => {
        const cleanedParams = cleanSearchParams(searchParams);
        console.log("cleanedParams", cleanedParams);
        if (
          Object.keys(cleanedParams).length === 1 &&
          cleanedParams.countryCode
        ) {
          callFarmerApi(currentPage, itemsPerPage);
          return;
        }
  
        FarmerDataService.searchFarmer({
          searchParams: cleanedParams,
          page: currentPage,
          size: itemsPerPage,
        })
          .then((res) => {
            setCurrentPage(res?.data?.response?.currentPage);
            setTotalItems(res?.data?.response?.totalItems);
            setFarmerData(res?.data?.response?.farmers);
            setRecordsError(false);
          })
          .catch((error) => {
            setRecordsError(true);
            console.log(error);
          });
      }, 500),
      [
        searchParams.status,
        searchParams.region,
        searchParams.search,
        currentPage,
        itemsPerPage,
      ]
    );
  
    useEffect(() => {
      if (Object.keys(cleanSearchParams(searchParams)).length > 0) {
        handleSearch();
      } else {
        console.log("Api");
        callFarmerApi(currentPage, itemsPerPage);
      }
  
      return () => {
        handleSearch.cancel();
      };
    }, [searchParams, currentPage, itemsPerPage, handleSearch]);
  
    const cleanSearchParams = (params) => {
      const cleanedParams = { ...params };
      Object.keys(cleanedParams).forEach((key) => {
        if (cleanedParams[key] === "") {
          delete cleanedParams[key];
        }
      });
      return cleanedParams;
    };
  
    const handleClear = (name) => {
      setSearchParams({
        ...searchParams,
        [name]: "",
      });
    };
  
    const handleChangeDescription = (event) => {
      const { value } = event.target;
      setDescSearch({ search: value });
    };
  

  // const paginate = (pageNumber, itemsPerPage) => {
  //   setCurrentPage(pageNumber - 1);
  //   if (!cultureName && !searchKeyword && !statusFilter) {
  //     callCuringAPI(pageNumber - 1, itemsPerPage);
  //   } else {
  //     callSearchCuringAPI(
  //       pageNumber - 1,
  //       itemsPerPage,
  //       searchKeyword,
  //       cultureName,
  //       status
  //     );
  //   }
  // };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      // paginate={paginate}
      currentPage={currentPage}
      title={"Farmer List"}
      background="#ffffff"
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          padding: "1rem",
          height: "100px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} />
          <Typography>{t("filtersLable")}</Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={10}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
              <TextField
                  name="search"
                  placeholder="Search"
                  value={searchParams.search}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={iconSearchs} alt="search-icon" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              {/* Culture Field */}
              <Grid item xs={12} sm={2.5}>
                <FormControl sx={{ width: { xs: "100%" }, minHeight: 50 }}>
                <TextField
                    select
                    name="region"
                    label={"Region"}
                    value={searchParams.region}
                    onChange={handleChange}
                    variant="outlined"
                    SelectProps={{
                      IconComponent: ArrowDropDownIcon,
                      endAdornment: searchParams.region && (
                        <CloseIcon
                          onClick={() => handleClear("region")}
                          style={{
                            cursor: "pointer",
                            marginRight: "12px",
                          }}
                        />
                      ),
                    }}
                  >
                    {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem> */}
                    <MenuItem value="Brazil">Brazil - BR</MenuItem>
                    <MenuItem value="Protugul">Protugul - PT</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>

              {/* Status field */}
              <Grid item xs={12} sm={2.5}>
                <FormControl sx={{ width: { xs: "100%" }, minHeight: 50 }}>
                <TextField
                    select
                    name="status"
                    value={searchParams.status}
                    onChange={handleChange}
                    label="Status"
                    SelectProps={{
                      IconComponent: ArrowDropDownIcon,
                      endAdornment: (searchParams.status === "Active" ||
                        searchParams.status === "Inactive") && (
                        <CloseIcon
                          onClick={() => handleClear("status")}
                          style={{
                            cursor: "pointer",
                            marginLeft: "auto",
                            paddingRight: "20px",
                          }}
                        />
                      ),
                    }}
                  >
                    {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem> */}
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={2} textAlign={"right"}>
            <Button
              sx={{
                borderRadius: 1,
                minWidth: 95,
                minHeight: 44,
                background: "#004F9F",
              }}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => navigate("/addFarmerRegistration")} // Adjust for curing Type registration navigation
            >
              {t("addNew")}
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Render table header if no data is found */}
      <CuringTypeTable
        curingData={farmerData} // Update filtered curing data
        setCuringData={setFarmerData}
        // setIsDeleted={setIsDeleted}
      />
    </Layout>
  );
};



export default FarmerNew;
