import { styled } from "@mui/material/styles";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  IconButton,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
  Modal,
  Divider,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "context/snackBarContext";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import SearchTextInput from "components/common/SearchTextInput";
import Save from "assets/SaveNew.svg";
import FundingGroupLimitsService from "services/FundingGroupLimitsService";
import { debounce } from "lodash";

const StyledTableContainer = styled(TableContainer)({
  boxShadow: "none",
  // border: "1px solid #E5E7EB",
  borderRadius: "8px",
  maxWidth: "1880px",
  margin: "0 auto",
  overflowX: "auto", // Enable horizontal scrolling
});

const StyledTable = styled(Table)({
  borderCollapse: "separate",
  borderSpacing: 0,
  minWidth: "100%", // Ensure table spans horizontally
  borderCollapse: "separate",
  borderSpacing: "0px 4px",
});

const HeaderCell = styled(TableCell)({
  backgroundColor: "#E9EBEE",
  color: "#3B454F",
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontSize: "20px",
  textTransform: "uppercase",
  textAlign: "center",
  padding: "13px 20px",
  borderBottom: "none",
  borderTopLeftRadius: "8px", // Rounded top-left edge
  borderTopRightRadius: "8px", // Rounded top-right edge
  overflow: "hidden", // Ensures the rounded edges are respected
});

const SecondaryHeaderCell = styled(TableCell)({
  backgroundColor: "#E9EBEE",
  color: "#3B454F",
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "20px",
  textAlign: "center",
  padding: "13px 20px",
  borderBottom: "none",
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  overflow: "hidden",
});

const SubHeaderCell = styled(TableCell)({
  backgroundColor: "#FFFFFF",
  color: "#3B454F",
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "16px",
  padding: "13px 20px",
  border: "1px solid #E9EBEE",
  borderRadius: "8px", // Rounded top-left edge
  overflow: "hidden",
  "&:last-child": {
    borderRight: "none",
  },
});

const DataCell = styled(TableCell)({
  fontFamily: "Montserrat",
  fontWeight: 400,
  fontSize: "15px",
  color: "#3B454F",
  padding: "13px 20px",
  backgroundColor: "#EFEFEF",
  textAlign: "center",
  // borderBottom: "1px solid #E9EBEE",
  // borderRight: "1px solid #E9EBEE",
  "&:last-child": {
    borderRight: "none",
  },
});

const TotalCell = styled(TableCell)({
  backgroundColor: "#E9EBEE",
  color: "#000000",
  fontFamily: "Montserrat",
  fontWeight: 700,
  fontSize: "16px",
  padding: "13px 20px",
  borderBottom: "none",
  textAlign: "center",
});

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    padding: "13px 5px",
    fontFamily: "Montserrat",
    fontSize: "15px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
});

const FundingGroupTable = forwardRef(
  (
    {
      data,
      cropYearUUID,
      ihId,
      onSave,
      childNodes,
      isTopHierarchyNode,
      refreshData,
      setShowExitWarningOverlayFeed,
    },
    ref
  ) => {
    const [tableData, setTableData] = useState([]);
    console.log("TABLE Data", tableData);
    console.log("INITIAL Data", data, isTopHierarchyNode);

    const { setOpenAlert, setSeverity, setMessage } =
      useContext(SnackbarContext);
    const { t, i18n } = useTranslation();
    const columns = ["Distributed", "Consumed", "Balance"];
    const headers = ["Investment VA", "Investment AM", "Investment BY"];
    const [showColumns, setShowColumns] = useState({
      distributed: true,
      consumed: true,
      balance: true,
    });
    console.log("ColumnsState", showColumns);
    const [minColumnModel, setMinColumnModel] = useState(false);

    const handleColumnChange = (key, isChecked) => {
      const uncheckedCount = Object.values(showColumns).filter(
        (value) => !value
      ).length;

      if (!isChecked && uncheckedCount >= 2) {
        console.log("ALL 3 FALSE");
        setMinColumnModel(true);
        return;
      }
      setShowColumns((prev) => ({
        ...prev,
        [key]: isChecked,
      }));
      setMinColumnModel(false);
    };

    const [manageItemsModal, setManageItemsModal] = useState(false);
    const [removeInvestmentModal, setRemoveInvestmentModal] = useState(false);
    const [removedInvestTableId, setRemovedInvestmentTableId] = useState();
    const [investmentList, setInvestmentList] = useState([]);
    const [investmentListSearch, setInvestmentListSearch] = useState("");
    const [investmentHeader, setInvestmentHeader] = useState([]);
    const [investmentTotal, setInvestmentTotal] = useState([]);
    console.log("🚀 ~ investmentTotal:", investmentTotal);
    console.log("InvestmentHeader", investmentHeader);

    const handleInvestmentListChange = (row, isChecked) => {
      if (!isChecked) {
        // for false i.e unchecked
        setRemovedInvestmentTableId(row.igInvestmentGroupId);
        setRemoveInvestmentModal(true);
      } else {
        // for true i.e checked
        setInvestmentList((prevList) =>
          prevList.map(
            (item) =>
              item.igInvestmentGroupId === row.igInvestmentGroupId
                ? { ...item, inHeaderList: true } // Add inHeaderList: true for the matching item
                : item // Leave the rest unchanged
          )
        );
        setInvestmentHeader((prevHeader) => [
          ...prevHeader,
          { ...row, isHeader: true },
        ]);
      }
    };

    const handleInvestmentHeaderChange = (selectedHeader) => {
      setInvestmentHeader((prevHeader) =>
        prevHeader.map(
          (header) =>
            // Check if the header's description matches the selectedHeader's description
            header.igInvestmentGroupDescription ===
            selectedHeader.igInvestmentGroupDescription
              ? { ...header, isHeader: !selectedHeader.isHeader } // Toggle the isHeader value
              : header // Leave the other headers unchanged
        )
      );
    };

    const handleRemoveInvestmentfromModal = async () => {
      console.log("INVDELETE ID", removedInvestTableId);
      let invDeletePermanent = false;
      data.forEach((item) => {
        if (item.investmentId === removedInvestTableId) {
          invDeletePermanent = true;
        }
      });
      if (invDeletePermanent) {
        try {
          const response =
            await FundingGroupLimitsService.deleteInvestmentDistributionAPI({
              investmentGroupID: removedInvestTableId,
              cropYearUUID: cropYearUUID, // Ensure this variable is defined and accessible
            });

          if (response?.status === 200) {
            setInvestmentList((prevList) =>
              prevList.map(
                (item) =>
                  item.igInvestmentGroupId === removedInvestTableId
                    ? { ...item, inHeaderList: false } // Add inHeaderList: true for the matching item
                    : item // Leave the rest unchanged
              )
            );
            setInvestmentHeader((prevHeader) =>
              prevHeader.filter(
                (item) => item.igInvestmentGroupId !== removedInvestTableId
              )
            );
            refreshData();
          }
        } catch (error) {
          console.error("Error deleting data:", error);
          setMessage(error.message || "Error deleteing Investment");
          setSeverity("error");
          setOpenAlert(true);
        }
      } else {
        setInvestmentList((prevList) =>
          prevList.map(
            (item) =>
              item.igInvestmentGroupId === removedInvestTableId
                ? { ...item, inHeaderList: false } // Add inHeaderList: true for the matching item
                : item // Leave the rest unchanged
          )
        );
        setInvestmentHeader((prevHeader) =>
          prevHeader.filter(
            (item) => item.igInvestmentGroupId !== removedInvestTableId
          )
        );
      }
    };

    const handleTargetChange = (inputValue, ihId, description, rowIndex) => {
      setShowExitWarningOverlayFeed(true);
      
      console.log("Target Change Details:", {
        inputValue: inputValue,
        importHierarchyId: ihId,
        investmentDescription: description,
        timestamp: new Date().toISOString()
      });

      setTableData((prevTableData) => {
        const updatedData = prevTableData.map((row) => {
          if (
            row.ihId === ihId && 
            row.investmentDescription === description
          ) {
            return {
              ...row,
              target: inputValue
            };
          }
          return row;
        });

        console.log("Updated Table Data Immediately:", updatedData);
        
        return updatedData;
      });
    };

    const fetchInvestmentGroupList = async (keyword) => {
      try {
        const InvGroupResponse = await FundingGroupLimitsService.getInvestmentGroupListAPI({
          fundingGroupDescription: keyword,
          pageNumber: 0,
          pageSize: 50
        });

        // Map the response data to match the expected structure
        const updatedInvestmentGroups = InvGroupResponse.data.response.fundingGroups
          .filter(group => 
            group.fundingGroupDescription.toLowerCase().includes(keyword.toLowerCase())
          )
          .map((group) => ({
            ...group,
            inHeaderList: group.isActive,
            igInvestmentGroupId: group.fundingGroupId,
            igInvestmentGroupDescription: group.fundingGroupDescription,
            investments: group.investments.map(inv => ({
              ...inv,
              investmentId: inv.investmentId,
              investmentName: inv.investmentName
            }))
          }));

        setInvestmentList(updatedInvestmentGroups);
      } catch (error) {
        console.error("Error fetching investment group data:", error);
        setInvestmentList([]); // Ensure list is empty on error
      }
    };

    // const debouncedFetchInvestmentGroupList = debounce((value) => {
    //   console.log("searchValue2", value)
    //   fetchInvestmentGroupList();
    // }, 15);

    const debouncedFetchInvestmentGroupList = useCallback(
      debounce((value) => {
        console.log("searchValue2", value);
        fetchInvestmentGroupList(value);
      }, 300),
      [investmentList]
    );

    const handleInvestmentListSearch = (event) => {
      const { value } = event.target;
      console.log("searchValue1", value);
      setInvestmentListSearch(value);
      debouncedFetchInvestmentGroupList(value);
    };

    const fetchInvestmentHeader = async () => {
      try {
        const InvGroupResponse = await FundingGroupLimitsService.getInvestmentGroupListAPI({
          fundingGroupDescription: investmentListSearch,
          pageNumber: 0,
          pageSize: 50
        });

        // Filter active groups and map them to the expected structure
        const activeGroups = InvGroupResponse.data.response.fundingGroups.filter(
          (group) => group.isActive === true
        );

        const updatedActiveGroups = activeGroups.map((group) => ({
          ...group,
          isHeader: true,
          igInvestmentGroupId: group.fundingGroupId,
          igInvestmentGroupDescription: group.fundingGroupDescription,
          investments: group.investments.map(inv => ({
            ...inv,
            investmentId: inv.investmentId,
            investmentName: inv.investmentName
          }))
        }));

        setInvestmentHeader(updatedActiveGroups);
      } catch (error) {
        console.error("Error fetching investment header data:", error);
      }
    };
    console.log("HEADER", investmentHeader);
    useEffect(() => {
      console.log("INSIDE HEADER EFFECT", data);
      // if (data) {
      // setTableData(data.targetDistributionDtoList || []);
      // calculateTotals(data.targetDistributionDtoList || []);

      fetchInvestmentGroupList("");
      fetchInvestmentHeader();
      // }
      // else {
      // setInvestmentList([])
      // setInvestmentHeader([])
      // }
    }, []);

    useEffect(() => {
      console.group("Table Data Synchronization Detailed");
      console.log("Current Data:", data);
      console.log("Child Nodes:", childNodes);
      console.log("Investment List:", investmentList);
      console.log("Investment Headers:", investmentHeader);
      console.log("Current Table Data:", tableData);
      console.groupEnd();

      if (childNodes && investmentList && investmentHeader) {
        const newData = [];
        
        childNodes.forEach((child) => {
          investmentHeader
            .filter(header => header.isHeader)
            .forEach((investment) => {
              // Find the matching record in the original data
              const matchingRecord = data.find(record => 
                record.importHierarchyId === child.value && 
                record.fundingGroupDescription === investment.igInvestmentGroupDescription
              );

              // Create an entry with either the matching record's data or default values
              const entryToAdd = {
                ihId: child.value,
                investmentDescription: investment.igInvestmentGroupDescription,
                investmentId: investment.igInvestmentGroupId,
                target: matchingRecord ? matchingRecord.target : 0,
                distributed: matchingRecord ? matchingRecord.distributed : 0,
                consumed: matchingRecord ? matchingRecord.consumed : 0,
                balance: matchingRecord ? matchingRecord.balance : 0,
                createdDateTimeUTC: matchingRecord ? matchingRecord.createdDateTimeUTC : new Date().toISOString(),
                modifiedDateTimeUTC: matchingRecord ? matchingRecord.modifiedDateTimeUTC : new Date().toISOString(),
                createdByUser: matchingRecord ? matchingRecord.createdByUser : "defaultUser",
                modifiedByUser: matchingRecord ? matchingRecord.modifiedByUser : "defaultUser",
                distributionId: matchingRecord ? matchingRecord.fundingGroupLimitsDistId : null,
                isDelete: matchingRecord ? matchingRecord.isDelete : false,
              };

              newData.push(entryToAdd);
            });
        });

        // Sort the data
        newData.sort((a, b) => {
          const aChildNodeIndex = childNodes.findIndex(node => node.value === a.ihId);
          const bChildNodeIndex = childNodes.findIndex(node => node.value === b.ihId);

          if (aChildNodeIndex !== bChildNodeIndex) {
            return aChildNodeIndex - bChildNodeIndex;
          }

          const aDescription = a.investmentDescription || '';
          const bDescription = b.investmentDescription || '';

          return aDescription.localeCompare(bDescription);
        });

        console.log("Comprehensive Table Data:", newData);
        
        // More verbose comparison
        const isDataChanged = JSON.stringify(newData) !== JSON.stringify(tableData);
        if (isDataChanged) {
          console.log("Data Changed - Updating Table Data");
          setTableData(newData);
        } else {
          console.log("No Changes Detected");
        }
      }
    }, [data, childNodes, investmentList, investmentHeader]);

    const calculateInvestmentTotals = (tableData) => {
      const totals = tableData.reduce((acc, group) => {
        const existingGroup = acc.find(
          (item) => item.investmentDescription === group.investmentDescription
        );

        if (existingGroup) {
          // If the group with the same investmentDescription exists, update totals
          existingGroup.totalTarget =
            Math.round(
              (parseFloat(existingGroup.totalTarget) +
                parseFloat(group.target)) *
                100
            ) / 100;
          existingGroup.totalDistributed =
            Math.round(
              (existingGroup.totalDistributed + group.distributed) * 100
            ) / 100;
          existingGroup.totalConsumed =
            Math.round((existingGroup.totalConsumed + group.consumed) * 100) /
            100;
          existingGroup.totalBalance =
            Math.round((existingGroup.totalBalance + group.balance) * 100) /
            100;
          // existingGroup.available = (existingGroup.totalTarget + group.target) - (existingGroup.totalDistributed + group.distributed);
          existingGroup.available =
            Math.round(
              (existingGroup.totalTarget - existingGroup.totalDistributed) * 100
            ) / 100;
        } else {
          // If it's a new investmentDescription, create a new entry
          acc.push({
            investmentDescription: group.investmentDescription,
            totalTarget: group.target,
            totalDistributed: group.distributed,
            totalConsumed: group.consumed,
            totalBalance: group.balance,
            available: group.target - group.distributed,
          });
        }

        return acc;
      }, []);

      return totals;
    };

    useEffect(() => {
      if (tableData) {
        const updatedTotals = calculateInvestmentTotals(tableData);
        setInvestmentTotal(updatedTotals);
      }
    }, [tableData]);

    const [saveClick, setSaveClick] = useState(false);
    useImperativeHandle(ref, () => ({
      save: () => {
        setSaveClick(true);
        handleSave();
      },
    }));

    const handleSave = async () => {
      try {
        // Transform the tableData to match the required format
        const transformedData = tableData.map(item => {
          // Find the matching record in the current data
          const matchingRecord = data.find(record => 
            record.fundingGroupId === item.investmentId && 
            record.importHierarchyId === item.ihId
          );

          const investmentGroup = investmentHeader.find(
            header => header.igInvestmentGroupId === item.investmentId
          );

          return {
            createdDateTimeUTC: item.createdDateTimeUTC || new Date().toISOString(),
            createdByUser: item.createdByUser || "defaultUser",
            modifiedDateTimeUTC: new Date().toISOString(),
            modifiedByUser: item.modifiedByUser || "defaultUser",
            fundingGroupLimitsDistId: matchingRecord ? matchingRecord.fundingGroupLimitsDistId : "",
            fundingGroupId: item.investmentId,
            cropYearId: cropYearUUID,
            fundingGroupDescription: investmentGroup?.igInvestmentGroupDescription || "",
            importHierarchyId: item.ihId,
            ihLocationName: childNodes.find(node => node.value === item.ihId)?.label || "",
            target: Number(item.target),
            distributed: Number(item.distributed),
            consumed: Number(item.consumed),
            balance: Number(item.balance),
            isDelete: item.isDelete || false
          };
        });

        console.log("Transformed Save Payload:", transformedData);

        const response = await FundingGroupLimitsService.putInvestmentGLDAPI(transformedData);
        
        if (response.status === 200 || 201) {
          // Show success alert
          setOpenAlert(true);
          setSeverity("success");
          setMessage("RECORD SAVED SUCCESSFULLY!");
          
          // Additional success handling
          setShowExitWarningOverlayFeed(false);
          refreshData();
          return true;
        }
        return false;
      } catch (error) {
        console.error("Error saving data:", error);
        
        // Show error alert
        setOpenAlert(true);
        setSeverity("error");
        setMessage(t("investmentGroupLimits.saveError"));
        
        return false;
      }
    };

    useEffect(() => {
      console.log("TableData State Changed:", tableData);
    }, [tableData]);

    useEffect(() => {
      console.group("Table Data Synchronization");
      console.log("Current Data:", data);
      console.log("Child Nodes:", childNodes);
      console.log("Investment List:", investmentList);
      console.log("Investment Headers:", investmentHeader);
      console.log("Resulting Table Data:", tableData);
      console.groupEnd();
    }, [data, childNodes, investmentList, investmentHeader, tableData]);

    return (
      <>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {/* First Grid Item */}
          <Grid item xs={4}>
            <Box sx={{ backgroundColor: "#EFEFEF", borderRadius: "8px" }}>
              <Box sx={{ display: "flex" }}>
                <ArrowDropDownIcon sx={{ marginBottom: 1.1 }} />
                <Typography fontWeight="600" color="#666666">
                  {t("investmentGroupLimits.showColumns")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  ml: 1,
                  overflowX: "auto", // Enables horizontal scrolling
                  whiteSpace: "nowrap", // Prevents wrapping of child elements
                }}
              >
                {/* {columns.map((column) => (
                <Box key={column}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showColumns[column.toLowerCase()]}
                        onChange={(e) => handleColumnChange(column.toLowerCase(), e.target.checked)}
                      />
                    }
                    label={column}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "16px",
                        fontWeight: 400,
                        whiteSpace: "normal", // Allows wrapping of text
                        wordBreak: "break-word", // Ensures long words break correctly
                        overflow: "hidden",
                      },
                    }}
                  />
                </Box>
              ))} */}
                {columns.map((column) => {
                  const isDisabled =
                    Object.values(showColumns).filter((value) => value).length <
                      2 && showColumns[column.toLowerCase()];

                  return (
                    <Box key={column}>
                      <FormControlLabel
                        control={
                          <Tooltip
                            title={
                              isDisabled
                                ? t("investmentGroupLimits.columnTooltip")
                                : ""
                            }
                            arrow
                            disableInteractive
                          >
                            <span>
                              <Checkbox
                                disabled={isDisabled}
                                checked={showColumns[column.toLowerCase()]}
                                onChange={(e) =>
                                  handleColumnChange(
                                    column.toLowerCase(),
                                    e.target.checked
                                  )
                                }
                              />
                            </span>
                          </Tooltip>
                        }
                        label={column}
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "16px",
                            fontWeight: 400,
                            // whiteSpace: "normal", // Allows wrapping of text
                            // wordBreak: "break-word", // Ensures long words break correctly
                            // overflow: "hidden",
                          },
                        }}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Grid>

          {/* Second Grid Item */}
          <Grid item xs={8}>
            <Box sx={{ backgroundColor: "#EFEFEF", borderRadius: "8px" }}>
              <Box sx={{ display: "flex" }}>
                <ArrowDropDownIcon sx={{ marginBottom: 1.1 }} />
                <Typography fontWeight="600" color="#666666">
                  {t("investmentGroupLimits.showHeaders")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                {/* Header checkboxes */}
                <Box
                  sx={{
                    flex: 1,
                    overflowX: "auto",
                    display: "flex",
                    gap: 1,
                    ml: 1,
                  }}
                >
                  {investmentHeader &&
                    investmentHeader.length > 0 &&
                    investmentHeader.map((header) => {
                      // Calculate the count of selected headers
                      const selectedHeadersCount = investmentHeader.filter(
                        (h) => h.isHeader
                      ).length;

                      // Determine if the checkbox should be disabled
                      const isDisabled =
                        selectedHeadersCount < 2 && header.isHeader;

                      return (
                        <Box
                          item
                          key={header.igInvestmentGroupId}
                          sx={{
                            flexShrink: 0, // Prevents items from shrinking
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Tooltip
                                title={
                                  isDisabled
                                    ? t("investmentGroupLimits.headerTooltip")
                                    : ""
                                }
                                arrow
                              >
                                <span>
                                  {" "}
                                  {/* Wrapper for Tooltip to work on disabled elements */}
                                  <Checkbox
                                    checked={header.isHeader}
                                    disabled={isDisabled}
                                    onChange={() =>
                                      handleInvestmentHeaderChange(header)
                                    }
                                  />
                                </span>
                              </Tooltip>
                            }
                            label={header.igInvestmentGroupDescription}
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "16px",
                                fontWeight: 400,
                              },
                            }}
                          />
                        </Box>
                      );
                    })}
                </Box>
                {/* Manage Items button */}
                {isTopHierarchyNode && (
                  <Box
                    sx={{
                      width: "30%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mr: 2 }}
                      onClick={() => setManageItemsModal(true)}
                    >
                      Manage Items
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 10, mt: 1 }}>
          <Grid item xs={4}>
            <StyledTableContainer component={Paper}>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <SubHeaderCell
                      colSpan={4}
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        padding: "25px",
                      }}
                    ></SubHeaderCell>
                  </TableRow>
                  <TableRow>
                    <SubHeaderCell
                      colSpan={4}
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        padding: "26px",
                      }}
                    ></SubHeaderCell>
                  </TableRow>
                  <TableRow>
                    <SecondaryHeaderCell colSpan={4}>
                      {t("investmentGroupLimits.hnSearchField")}
                    </SecondaryHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {childNodes.map((row, index) => (
                    <TableRow key={index}>
                      <DataCell colSpan={4}>{row.label}</DataCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TotalCell colSpan={4}>Totals</TotalCell>
                  </TableRow>
                </TableBody>
              </StyledTable>
            </StyledTableContainer>
          </Grid>
          {/* {(isTopHierarchyNode || data.length > 0) && ( */}
          <Grid item xs={8}>
            <StyledTableContainer component={Paper}>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    {investmentHeader
                      .filter((header) => header.isHeader === true)
                      .map((row, index) => (
                        <HeaderCell
                          colSpan={Math.max(
                            2,
                            1 +
                              Object.values(showColumns).filter(Boolean).length
                          )}
                        >
                          {row.igInvestmentGroupDescription}
                        </HeaderCell>
                      ))}
                  </TableRow>
                  <TableRow>
                    {investmentHeader
                      .filter((header) => header.isHeader === true)
                      .map((header) =>
                        investmentTotal
                          .filter(
                            (item) =>
                              item.investmentDescription ===
                              header.igInvestmentGroupDescription
                          )
                          .map((investment) => (
                            <SubHeaderCell
                              colSpan={Math.max(
                                2,
                                1 +
                                  Object.values(showColumns).filter(Boolean)
                                    .length
                              )}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                  alignItems: "center",
                                  width: "100%", // Full width for inner content
                                }}
                              >
                                {t("investmentGroupLimits.available/target")}
                                <span>
                                  {/* {"0 / "} {totals.investments.reduce((sum, inv) => sum + inv.target, 0).toLocaleString()} */}
                                  <>
                                    {investment.available} /{" "}
                                    {investment.totalTarget}
                                  </>
                                </span>
                              </Box>
                            </SubHeaderCell>
                          ))
                      )}
                  </TableRow>
                  <TableRow>
                    {investmentHeader
                      .filter((header) => header.isHeader === true)
                      .map((row, index) =>
                        ["Target", "Distributed", "Consumed", "Balance"]
                          .filter((header) => {
                            const key = header.toLowerCase(); // Convert to lowercase to match state keys
                            return key === "target" || showColumns[key]; // Always show 'Target', filter others based on state
                          })
                          .map((header) => (
                            <SecondaryHeaderCell
                              key={header}
                              style={{
                                borderLeft:
                                  header === "Target"
                                    ? "none"
                                    : "5px solid white",
                                borderRight: "5px solid white",
                              }}
                            >
                              {header}
                            </SecondaryHeaderCell>
                          ))
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {childNodes.map((child) => (
                    <TableRow key={child.value}>
                      {investmentHeader
                        .filter((header) => header.isHeader === true)
                        .map((header) => {
                          // Find rows for this specific child and header combination
                          const rows = tableData.filter(
                            (data) =>
                              data.ihId === child.value &&
                              data.investmentDescription === header.igInvestmentGroupDescription
                          );

                          // If no rows found, create a default row
                          const rowsToRender = rows.length > 0 
                            ? rows 
                            : [{
                                ihId: child.value,
                                investmentDescription: header.igInvestmentGroupDescription,
                                target: 0,
                                distributed: 0,
                                consumed: 0,
                                balance: 0
                              }];

                          return rowsToRender.map((row, rowIndex) => {
                            // Rest of your existing rendering logic
                            return [
                              "target",
                              "distributed",
                              "consumed",
                              "balance",
                            ].map((key, i) => {
                              const value = row[key]; // Access the corresponding value

                              if (key === "target") {
                                // Render "target" as a TextField
                                return (
                                  // <DataCell >
                                  <StyledTextField
                                    type="number"
                                    key={`${rowIndex}-${i}`}
                                    fullWidth
                                    variant="outlined"
                                    value={
                                      value === 0
                                        ? "0" // Show zero explicitly
                                        : /^\d+(\.\d+)?$/.test(value)
                                        ? value
                                            .toString()
                                            .replace(/^0+(?=\d)/, "") // Remove leading zeros for whole numbers
                                        : value
                                    }
                                    sx={{
                                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                        {
                                          display: "none",
                                        },
                                    }}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      // Validate and handle changes for "target"
                                      if (
                                        /^\d{0,8}(\.\d{0,2})?$/.test(inputValue)
                                      ) {
                                        handleTargetChange(
                                          inputValue || 0,
                                          child.value,
                                          header.igInvestmentGroupDescription
                                        ); // Update the "target" value
                                      }
                                    }}
                                  />
                                  // </DataCell>
                                );
                              } else {
                                // Render other values as plain text
                                if (!showColumns[key]) return null;
                                return (
                                  <DataCell key={`${rowIndex}-${i}`}>
                                    {value?.toLocaleString()}
                                  </DataCell>
                                );
                              }
                            });
                          });
                        })}
                    </TableRow>
                  ))}
                  <TableRow>
                    {investmentHeader
                      .filter((header) => header.isHeader === true)
                      .map((item) =>
                        investmentTotal
                          .filter(
                            (total) =>
                              total.investmentDescription ===
                              item.igInvestmentGroupDescription
                          )
                          .map((itemTotal) => (
                            <>
                              <TotalCell>{itemTotal.totalTarget}</TotalCell>
                              {showColumns.distributed && (
                                <TotalCell>
                                  {itemTotal.totalDistributed}
                                </TotalCell>
                              )}
                              {showColumns.consumed && (
                                <TotalCell>{itemTotal.totalConsumed}</TotalCell>
                              )}
                              {showColumns.balance && (
                                <TotalCell>{itemTotal.totalBalance}</TotalCell>
                              )}
                            </>
                          ))
                      )}
                    {/* {investmentHeader.map((header) => (
                    tableData.filter((data) => data.investmentDescription === header.igInvestmentGroupDescription).map((item) => (
                      
                    ))
                  ))

                  } */}
                    {/* {totals.investments.map((investment, i) =>
                    Object.entries(investment)
                      .filter(([key]) => key === "target" || showColumns[key.toLowerCase()])
                      .map(([key, value], j) => (
                        <TotalCell key={`${i}-${j}`}>
                          {key === "target" ? parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ""}
                        </TotalCell>
                      ))
                  )} */}
                  </TableRow>
                </TableBody>
              </StyledTable>
            </StyledTableContainer>
          </Grid>
          {/* )} */}
        </Grid>
        <Divider
          sx={{
            width: "100%", // Ensures the divider spans the full width
            marginBottom: 1, // Adds spacing between the divider and the button
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            sx={{
              padding: "6px 16px",
              borderRadius: "4px",
              // right: "1rem", // Aligns to the right bottom corner
              // bottom: "1rem", // Position it at the bottom
              marginBottom: 4,
              backgroundColor: "#EF7D00",
              color: "white",
              "&:hover": {
                backgroundColor: "#EF7D00",
              },
            }}
            startIcon={<img src={Save} alt="Right Arrow" />}
            onClick={handleSave}
            variant="contained"
          >
            {t("save")}
          </Button>
        </Box>
        <Modal
          open={manageItemsModal}
          onClose={() => setManageItemsModal(false)}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "7px",
              width: "929px",
              margin: "auto",
              mt: 2,
              maxHeight: "90vh", // Prevent modal from exceeding viewport height
              display: "flex",
              flexDirection: "column", // Ensures the layout respects natural flow
            }}
          >
            <Box
              sx={{
                background: "#102A62",
                // height: "20vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderTopLeftRadius: "7px",
                borderTopRightRadius: "7px",
                color: "#fff",
                pt: 2,
                pb: 1,
              }}
            >
              {t("FundingGroupLimits.modalTitle")}
            </Box>
            <Box
              sx={{
                background: "#EFEFEF",
                borderRadius: "10px",
                padding: "1rem",
                height: "110px",
                margin: 2,
              }}
            >
              <Box sx={{ display: "flex" }}>
                <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} />
                <Typography>{t("filtersLable")}</Typography>
              </Box>
              <TextField
                label={t("investmentGroupLimits.description")}
                value={investmentListSearch}
                onChange={handleInvestmentListSearch}
                size="medium"
                fullWidth={true}
                variant="outlined"
                type="text"
                placeholder="-"
                InputLabelProps={{ shrink: true }}
                sx={{
                  "& .MuiInputBase-input::placeholder": {
                    opacity: 1,
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                flex: 1, 
                overflowY: "auto", 
                overflowX: "hidden", 
                padding: "1rem",
                margin: 1,
              }}
            >
              {investmentList && investmentList.length > 0 ? (
                investmentList.map((row) => (
                  <Box
                    item
                    key={row.igInvestmentGroupId}
                    sx={{
                      background: "#EFEFEF",
                      borderRadius: "10px",
                      padding: "1rem",
                      margin: 1,
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={row.inHeaderList}
                          onChange={() =>
                            handleInvestmentListChange(row, !row.inHeaderList)
                          }
                        />
                      }
                      label={row.igInvestmentGroupDescription}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "16px",
                          fontWeight: 400,
                        },
                      }}
                    />
                  </Box>
                ))
              ) : (
                <Typography 
                  sx={{ 
                    textAlign: 'left', 
                    color: '#666', 
                    padding: '20px',
                    width: '100%',
                    alignSelf: 'flex-start'
                  }}
                >
                  {t("investmentGroupLimits.noData")}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                position: "sticky", // Make it stick within the modal
                bottom: 0, // Stick to the bottom of the container
                // background: "#fff", // Ensure it blends with the modal background
                padding: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "60px",
                zIndex: 2, // Ensure it stays above the content
              }}
            >
              <Button
                onClick={() => setManageItemsModal(false)}
                sx={{
                  background: "#737D86",
                  width: "100px",
                  color: "#ffffff",
                  "&:hover": { background: "#737D86" },
                }}
              >
                {t("investmentGroupLimits.close")}
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={removeInvestmentModal}
          onClose={() => setRemoveInvestmentModal(false)}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "7px",
              width: "560px",
              margin: "auto",
              mt: 25,
              height: "200px",
            }}
          >
            <Box
              sx={{
                background: "#102A62",
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderTopLeftRadius: "7px",
                borderTopRightRadius: "7px",
                color: "#fff",
              }}
            >
              {t("investmentGroupLimits.removeItemConfirm")}
            </Box>
            {/* <Box sx={{ p: 2 }}>
              <Typography variant="body2">
              Are you sure you don't want to use this record? By selecting 'Yes',    
                you will no longer see this option in your forms.
              </Typography>
            </Box> */}
            <Box sx={{ p: 2 }}>
              <Typography
                variant="body2"
                sx={{
                  whiteSpace: "pre-wrap",
                  width: "550px",
                  overflowX: "auto",
                }}
              >
                {t("investmentGroupLimits.removeMessage")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "60px",
                pt: 4,
              }}
            >
              <Button
                onClick={() => setRemoveInvestmentModal(false)}
                sx={{
                  background: "#737D86",
                  width: "100px",
                  color: "#ffffff",
                  "&:hover": { background: "#737D86" },
                }}
              >
                {t("no")}
              </Button>

              <Button
                onClick={() => {
                  handleRemoveInvestmentfromModal();
                  setRemoveInvestmentModal(false);
                }}
                sx={{
                  background: "#004F9F",
                  width: "100px",
                  color: "#ffffff",
                  "&:hover": { background: "#004F9F" },
                }}
              >
                {t("yes")}
              </Button>
              {/* </Box> */}
            </Box>
          </Box>
        </Modal>
      </>
    );
  }
);
export default FundingGroupTable;
