import { Typography, Slider, Box } from "@mui/material";
import Layout from "components/structure/Layout";
import React, { useState, useEffect } from "react";
import Timeline from "@mui/lab/Timeline";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CloseIcon from "@mui/icons-material/Close";
import TaskManagementService from "services/TaskManagementService";
import Settingline from "assets/Setting_line.svg";
import "./DatePicker.css";

const Tasks = () => {
  const [progressData, setProgressData] = useState([]);
  const [sliderValues, setSliderValues] = useState({});
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [timelineTasks, setTimelineTasks] = useState([]);
  const [timelineData, setTimelineData] = useState([]);
  const [timelineFunctionalities, setTimelineFunctionalities] = useState([]);
  const [selectedFunctionality, setSelectedFunctionality] = useState("ALL");
  const [taskHistory, setTaskHistory] = useState([]);
  const [taskHistoryFunctionalities, setTaskHistoryFunctionalities] = useState([
    "ALL",
  ]);
  const [
    selectedTaskHistoryFunctionality,
    setSelectedTaskHistoryFunctionality,
  ] = useState("ALL");
  const [selectedTaskStatus, setSelectedTaskStatus] = useState("ALL TASKS");
  const [taskHistoryDateRange, setTaskHistoryDateRange] = useState([
    null,
    null,
  ]);
  const [taskHistoryStartDate, taskHistoryEndDate] = taskHistoryDateRange;

  useEffect(() => {
    const fetchProgressData = async () => {
      try {
        const response = await TaskManagementService.getProgress();
        if (response.data && response.data.response) {
          setProgressData(response.data.response);

          // Initialize slider values based on the progress data
          const initialSliderValues = response.data.response.reduce(
            (acc, item) => {
              acc[item.functionalityName] = item.progress;
              return acc;
            },
            {}
          );
          setSliderValues(initialSliderValues);
        }
      } catch (error) {
        console.error("Error fetching progress data:", error);
      }
    };

    fetchProgressData();
  }, []);

  // New useEffect for fetching timeline tasks
  useEffect(() => {
    const fetchTimelineTasks = async () => {
      try {
        const fromDate = startDate
          ? startDate.toISOString().split("T")[0]
          : null;
        const toDate = endDate ? endDate.toISOString().split("T")[0] : null;

        const timelineResponse = await TaskManagementService.taskManagement({
          fromDate,
          toDate,
        });

        // Add comprehensive null and empty checks
        if (
          timelineResponse.data && 
          timelineResponse.data.response && 
          timelineResponse.data.response.tasks &&
          timelineResponse.data.response.tasks.length > 0
        ) {
          setTimelineTasks(timelineResponse.data.response.tasks);
        } else {
          // Set to an empty array if no tasks are found
          setTimelineTasks([]);
        }
      } catch (error) {
        console.error("Error fetching timeline tasks:", error);
        // Optionally set to empty array in case of error
        setTimelineTasks([]);
      }
    };

    fetchTimelineTasks();
  }, [startDate, endDate]);

  useEffect(() => {
    const fetchTimelineData = async () => {
      try {
        const response = await TaskManagementService.timeline();
        if (response.data && response.data.response) {
          setTimelineData(response.data.response.tasks);
          setTimelineFunctionalities(
            response.data.response.taskFunctionalities
          );
        }
      } catch (error) {
        console.error("Error fetching timeline data:", error);
      }
    };

    fetchTimelineData();
  }, []);

  useEffect(() => {
    const fetchTaskHistory = async () => {
      try {
        const fromDate = taskHistoryStartDate
          ? taskHistoryStartDate.toISOString().split("T")[0]
          : null;
        const toDate = taskHistoryEndDate
          ? taskHistoryEndDate.toISOString().split("T")[0]
          : null;

        const response = await TaskManagementService.history({
          fromDate,
          toDate,
        });

        if (
          response.data &&
          response.data.response &&
          response.data.response.tasks
        ) {
          // Transform API data to match the existing timeline structure
          const transformedTasks = response.data.response.tasks.map((task) => ({
            date: task.startDate,
            event: task.description,
            status: task.status,
            functionality: task.functionalityName,
          }));

          // Sort tasks by date (most recent first)
          const sortedTasks = transformedTasks.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          );

          setTaskHistory(sortedTasks);

          // Extract unique functionalities from tasks and add "All" at the beginning
          const uniqueFunctionalities = [
            ...new Set(transformedTasks.map((task) => task.functionality)),
          ];
          setTaskHistoryFunctionalities(uniqueFunctionalities);
        }
      } catch (error) {
        console.error("Error fetching task history:", error);
      }
    };

    fetchTaskHistory();
  }, [taskHistoryStartDate, taskHistoryEndDate]);

  // Filter timeline data based on selected functionality
  const filteredTimelineData =
    selectedFunctionality === "ALL"
      ? timelineData
      : timelineData.filter(
          (item) => item.functionalityName === selectedFunctionality
        );

  // Determine color based on task status and due date
  const getTimelineColor = (task) => {
    if (task.isDueDatePassed) return "error";
    switch (task.status) {
      case "Completed":
        return "success";
      case "Pending":
        return "warning";
      default:
        return "secondary";
    }
  };

  // Dynamic slider change handler
  const handleSliderChange = (functionalityName, newValue) => {
    setSliderValues((prev) => ({
      ...prev,
      [functionalityName]: newValue,
    }));
  };

  // Render slider sections dynamically
  const renderProgressSliders = () => {
    return (
      <div
        style={{
          display: "flex",
          gap: "60px",
          paddingLeft: "10px",
          marginTop: "20px",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        {progressData.map((item) => (
          <div
            key={item.functionalityId}
            style={{
              display: "flex",
              flexDirection: "column",
              width: progressData.length > 1 ? "calc(50% - 30px)" : "100%",
            }}
          >
            <Typography
              style={{
                color: "var(--Grey-6, #3B454F)",
                fontFamily: "Montserrat",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                marginBottom: "25px",
              }}
            >
              {item.functionalityName}
            </Typography>
            <div
              style={{ 
                display: "flex", 
                flexDirection: "column", 
                alignItems: "center", 
                width: "100%" 
              }}
            >
              <div
                style={{ 
                  display: "flex", 
                  alignItems: "center", 
                  width: "100%" 
                }}
              >
                <Typography style={{ marginRight: "5px" }}>
                  00
                </Typography>
                <Slider
                  value={sliderValues[item.functionalityName] || 0}
                  onChange={(event, newValue) =>
                    handleSliderChange(item.functionalityName, newValue)
                  }
                  aria-labelledby={`slider-${item.functionalityName}`}
                  min={0}
                  max={100}
                  sx={{
                    width: "70%",
                    borderRadius: "10px",
                    color: "var(--Primary-Blue, #0E2B63)",
                    marginLeft: "20px",
                    "& .MuiSlider-thumb": {
                      height: 40,
                      width: 40,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#0E2B63",
                      color: "white",
                      fontSize: "0.8rem",
                      fontWeight: "bold",
                      "&::after": {
                        content: `"${(
                          sliderValues[item.functionalityName] || 0
                        ).toFixed(0)}%"`,
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    },
                    "& .MuiSlider-track": {
                      height: 20,
                    },
                    "& .MuiSlider-rail": {
                      height: 20,
                      opacity: 0.5,
                      backgroundColor: "#E9EBEE",
                    },
                  }}
                />
                <Typography style={{ marginLeft: "20px" }}>
                {item.totalTaskCount}
                </Typography>
              </div>
              <Typography
                style={{
                  color: "var(--Grey-4, #5A646E)",
                  fontFamily: "Montserrat",
                  fontSize: "12px",
                  marginTop: "5px",
                  ...(sliderValues[item.functionalityName] !== 50 
                    ? {
                        marginLeft: sliderValues[item.functionalityName] > 50 
                          ? `calc(${sliderValues[item.functionalityName] || 0}% - 100px)` 
                          : undefined,
                        marginRight: sliderValues[item.functionalityName] < 50 
                          ? `calc(${100 - (sliderValues[item.functionalityName] || 0)}% - 100px)` 
                          : undefined
                      }
                    : {}
                  ),
                  position: 'relative',
                  width: 'fit-content',
                }}
              >
                 {item.completedTaskCount}
                
              </Typography>
            </div>
          </div>
        ))}
      </div>
    );
  };

  // Modify the generateCalendarDays function to return only 5 days
  const generateCalendarDays = (referenceDate) => {
    const days = [];
    let currentDate = new Date(referenceDate);

    // Start from the current week's first day (Sunday)
    currentDate.setDate(currentDate.getDate() - currentDate.getDay());

    // Generate 5 consecutive days
    for (let i = 0; i < 7; i++) {
      const day = new Date(currentDate);
      day.setDate(currentDate.getDate() + i);
      days.push(day);
    }

    return days;
  };

  const isDateInRange = (date) => {
    if (!startDate || !endDate) return false;
    return date >= startDate && date <= endDate;
  };

  const isStartOrEndDate = (date) => {
    if (!startDate || !endDate) return false;
    return (
      date.toDateString() === startDate.toDateString() ||
      date.toDateString() === endDate.toDateString()
    );
  };

  // Dynamically generate calendar days based on date range or selected date
  const calendarDays = generateCalendarDays(selectedDate);

  // Modify the task history filtering to include both functionality and status
  const filteredTaskHistory = taskHistory
    .filter(
      (task) =>
        selectedTaskHistoryFunctionality === "ALL" ||
        task.functionality === selectedTaskHistoryFunctionality
    )
    .filter((task) => {
      // This filter is only applied when displaying tasks
      switch (selectedTaskStatus) {
        case "ALL TASKS":
          return true;
        case "PENDING":
          return task.status === "Pending";
        case "CANCELED":
          return task.status === "Canceled" || task.status === "Cancelled";
        case "COMPLETED":
          return task.status === "Completed";
        default:
          return true;
      }
    });

  // This calculates total counts BEFORE filtering by status
  const taskStatusCounts = {
    "ALL TASKS": taskHistory.length,
    "PENDING": taskHistory.filter(task => task.status === "Pending").length,
    "CANCELED": taskHistory.filter(task => task.status === "Canceled" || task.status === "Cancelled").length,
    "COMPLETED": taskHistory.filter(task => task.status === "Completed").length
  };

  return (
    <Layout 
    title="Taskboard">
      <div
        style={{
          display: "flex",
          width: "100%",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "53%",
            height: "350px",
            flexShrink: 0,
            borderRadius: "5px",
            border: "1px solid #D9D9D9",
            background: "#FFF",
            boxShadow: "0px 0px 7.6px -2px rgba(0, 0, 0, 0.25)",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: "20px",
              paddingRight: "20px",
              marginTop: "20px",
            }}
          >
            <div>
              <Typography
                style={{
                  color: "var(--Grey-6, #3B454F)",
                  textAlign: "left",
                  fontFamily: "Montserrat",
                  fontSize: "20px !important",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                  marginBottom: "10px",
                }}
              >
                What's up Today
              </Typography>
              <Typography
                style={{
                  color: "var(--Grey-3, #737D86)",
                  fontFamily: "Montserrat",
                  fontSize: "15px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                Total {timelineTasks.length} tasks to deliver
              </Typography>
            </div>
            <div
              style={{
                position: "relative",
                width: "250px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => setDateRange(update)}
                placeholderText="Select Date Range"
                className="custom-datepicker"
                wrapperClassName="custom-datepicker-wrapper"
                style={{
                  borderRadius: "var(--borderRadius, 4px)",
                  border: "1px solid var(--Gray-2, #ACB4BA)",
                  height: "40px",
                  padding: "0px 12px",
                  width: "calc(100% - 80px)",
                  boxSizing: "border-box",
                }}
              />
              {(startDate || endDate) && (
                <CloseIcon
                  onClick={() => setDateRange([null, null])}
                  sx={{
                    color: "var(--Grey-4, #5A646E)",
                    position: "absolute",
                    right: "40px",
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                />
              )}
              <CalendarMonthIcon
                sx={{
                  color: "var(--Grey-4, #5A646E)",
                  position: "absolute",
                  right: "10px",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>

          {/* Horizontal Calendar */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
              width: "100%",
              padding: "0 20px",
              boxSizing: "border-box",
              overflowX: "auto",
              scrollbarWidth: "thin",
              scrollbarColor: "var(--Grey-3, #737D86) transparent",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "max-content",
                justifyContent: "flex-start",
                borderRadius: "8px",
                gap: "10px",
              }}
            >
              {calendarDays.map((day, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "80px", // Slightly wider to accommodate 5 days
                    minWidth: "80px",
                    cursor: "pointer",
                    backgroundColor:
                      day.toDateString() === selectedDate.toDateString()
                        ? "#0E2B63"
                        : "transparent",
                    color:
                      day.toDateString() === selectedDate.toDateString()
                        ? "white"
                        : "var(--Grey-4, #5A646E)",
                    borderRadius: "25px",
                    padding: "5px 0",
                  }}
                  onClick={() => setSelectedDate(day)}
                >
                  <Typography
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: 600,
                      color:
                        day.toDateString() === selectedDate.toDateString()
                          ? "white"
                          : "#ACB4BA",
                    }}
                  >
                    {day.toLocaleString("default", { weekday: "short" })}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: "25px !important",
                      fontWeight: 700,
                    }}
                  >
                    {day.getDate()}
                  </Typography>
                </div>
              ))}
            </div>
          </div>

          <div
            style={{
              height: "150px",
              overflowY: "auto",
              marginTop: "30px",
              paddingLeft: "10px",
            }}
          >
            <Timeline
              position="right"
              sx={{
                padding: 0,
                margin: 0,
                "& .MuiTimelineItem-root": {
                  minHeight: "60px",
                },
                "&::before": {
                  display: "none",
                },
                "& .MuiTimelineContent-root": {
                  alignItems: "start",
                },
              }}
            >
              {timelineTasks.map((item, index) => (
                <TimelineItem
                  key={item.taskId}
                  sx={{
                    "&::before": {
                      display: "none",
                    },
                  }}
                >
                  {/* Timeline Dot and Connector */}
                  <TimelineSeparator>
                    <TimelineConnector
                      sx={{
                        backgroundColor: "#0E2B63",
                        width: "4px",
                        marginLeft: "20px",
                        marginBottom: "20px ",
                      }}
                    />
                  </TimelineSeparator>

                  {/* Event on the right */}
                  <TimelineContent
                    sx={{
                      color: "var(--Grey-6, #3B454F)",
                      textAlign: "left",
                      fontFamily: "Montserrat",
                      width: "60%",
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                      marginBottom: "20px",
                      "& .MuiTimelineContent-root": {
                        alignItems: "start",
                      },
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1,
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#3B454F",
                          fontFamily: "Montserrat",
                          fontSize: "20px !important",
                          fontWeight: 600,
                          marginBottom: "1px",
                        }}
                      >
                        {item.description}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#ACB4BA",
                          fontFamily: "Montserrat",
                          fontWeight: 700,
                          fontSize: "16px !important",
                        }}
                      >
                        {item.functionalityName}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#ACB4BA",
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                          fontSize: "10px",
                          marginTop: "3px",
                        }}
                      >
                        {`${item.startDate} - ${item.endDate}`}
                      </Typography>
                    </div>
                    <Typography
                      sx={{
                        display: "flex",
                        padding: "4px 16px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "12px",
                        borderRadius: "4px",
                        border: "1px solid var(--Gray-2, #ACB4BA)",
                        background: "var(--Gray-1, #E9EBEE)",
                        boxShadow: "2px 2px 10px 0px rgba(172, 180, 186, 0.30)",
                        color: "var(--Grey-6, #3B454F)",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "normal",
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                        marginTop: "20px",
                      }}
                    >
                      View
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </div>
        </div>

        <div
          style={{
            width: "45%",
            height: "350px",
            flexShrink: 0,
            borderRadius: "4px",
            border: "1px solid var(--Gray-2, #ACB4BA)",
            background: "var(--Pure-White, #FFF)",
            boxShadow: "0px 0px 7.6px -2px rgba(0, 0, 0, 0.25)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "20px",
              marginTop: "20px",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                color: "var(--Grey-6, #3B454F)",
                textAlign: "left",
                fontFamily: "Montserrat",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                marginRight: "10px",
              }}
            >
              Progress
            </Typography>
            <img
              src={Settingline}
              alt="Settings"
              style={{
                width: "24px",
                height: "24px",
                marginRight: "12px",
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              gap: "20px",
              paddingLeft: "20px",
              marginTop: "20px",
              flexWrap: "wrap",
            }}
          >
            {renderProgressSliders()}
          </div>
        </div>
      </div>

      {/* Lower two boxes */}

      <div
        style={{
          display: "flex",
          width: "100%",
          gap: "20px",
          alignItems: "center",
          marginTop: "30px",
        }}
      >
        <div
          style={{
            width: "53%",
            height: "400px",
            flexShrink: 0,
            borderRadius: "5px",
            border: "1px solid #D9D9D9",
            background: "#FFF",
            boxShadow: "0px 0px 7.6px -2px rgba(0, 0, 0, 0.25)",
          }}
        >
          <Typography
            style={{
              color: "var(--Grey-6, #3B454F)",
              textAlign: "left",
              fontFamily: "Montserrat",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              marginTop: "20px",
              paddingLeft: "20px",
            }}
          >
            TimeLine
          </Typography>
          <Typography
            style={{
              color: "var(--Grey-3, #737D86)",
              fontFamily: "Montserrat",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              paddingLeft: "20px",
              marginTop: "10px",
            }}
          >
            Total {timelineData.length} tasks to deliver ordered by due date
          </Typography>

          {/* Timeline Filter Buttons */}
          <div
            style={{
              display: "flex",
              gap: "10px",
              paddingLeft: "20px",
              marginTop: "15px",
              flexWrap: "wrap",
              width: "95%",
              justifyContent: "space-evenly",
            }}
          >
            {["ALL", ...timelineFunctionalities.map((f) => f.functionalityName)].map(
              (label) => (
                <Typography
                  key={label}
                  onClick={() => setSelectedFunctionality(label)}
                  style={{
                    display: "flex",
                    padding: "4px 16px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "12px",
                    borderRadius: "4px",
                    border: "1px solid var(--Gray-2, #ACB4BA)",
                    background:
                      selectedFunctionality === label
                        ? "var(--Primary-Blue, #0E2B63)"
                        : "var(--Gray-1, #E9EBEE)",
                    boxShadow: "2px 2px 10px 0px rgba(172, 180, 186, 0.30)",
                    color:
                      selectedFunctionality === label
                        ? "white"
                        : "var(--Grey-6, #3B454F)",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    cursor: "pointer",
                  }}
                >
                  {label}
                </Typography>
              )
            )}
          </div>

          {/* Scrollable Timeline */}
          <div
            style={{
              height: "220px",
              overflowY: "auto",
              marginTop: "20px",
              paddingLeft: "10px",
              marginTop: "30px",
            }}
          >
            <div
              style={{
                display: "flex",
                paddingLeft: "20px",
                marginBottom: "10px",
              }}
            >
              <Typography
                style={{
                  color: "var(--Grey-4, #5A646E)",
                  fontFamily: "Montserrat",
                  fontSize: "15px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  marginLeft: "20px",
                  marginRight: "40px",
                }}
              >
                DUE DATE
              </Typography>
              <Typography
                style={{
                  
                  color: "var(--Grey-4, #5A646E)",
                  fontFamily: "Montserrat",
                  fontSize: "15px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  marginLeft: "40px",
                }}
              >
                TASK
              </Typography>
            </div>
            <Timeline
              position="right"
              sx={{
                padding: 0,
                margin: 0,
                "& .MuiTimelineItem-root": {
                  minHeight: "70px",
                },
                "&::before": {
                  display: "none",
                },
              }}
            >
              {filteredTimelineData.map((item, index) => (
                <TimelineItem
                  key={item.taskId}
                  sx={{
                    "&::before": {
                      display: "none",
                    },
                  }}
                >
                  {/* Date on the left */}
                  <TimelineContent
                    sx={{
                      flex: "0 0 14%", // Flexible width
                      textAlign: "right",
                      paddingRight: "15px",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: item.isDueDatePassed ? "#D82F2F" : "#3B454F",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      {item.endDate}
                    </Typography>
                  </TimelineContent>

                  {/* Timeline Dot and Connector */}
                  <TimelineSeparator
                    sx={{
                      position: "relative",
                      top: "15px",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <TimelineDot
                      color={getTimelineColor(item)}
                      sx={{
                        height: "15px",
                        width: "15px",
                        margin: "0px",
                        backgroundColor: "white",
                        border: "5px solid",
                        borderColor: item.isDueDatePassed
                          ? "#D82F2F"
                          : "#3B454F",
                        boxShadow: "none",
                      }}
                    />
                    {index < filteredTimelineData.length - 1 && (
                      <TimelineConnector
                        sx={{
                          backgroundColor:
                            index % 2 === 0
                              ? "var(--Gray-2, #ACB4BA)"
                              : "var(--Gray-2, #ACB4BA)",
                          width: "2px",
                          margin: 0,
                          flex: 1,
                        }}
                      />
                    )}
                  </TimelineSeparator>

                  {/* Event on the right */}
                  <TimelineContent
                    sx={{
                      flex: "0 0 70%", // Flexible width
                      textAlign: "left",
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <div style={{ flexGrow: 1 }}>
                      <Typography
                        variant="body2"
                        sx={{
                          color: item.isDueDatePassed ? "#D82F2F" : "#3B454F",
                          fontFamily: "Montserrat",
                          fontWeight: 600,
                          fontStyle: "normal",
                          lineHeight: "normal",
                          fontSize: "20px !important",
                        }}
                      >
                        {item.description}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#ACB4BA",
                          fontFamily: "Montserrat",
                          fontWeight: 700,
                          fontSize: "12px !important",
                          marginTop: "2px",
                        }}
                      >
                        {item.functionalityName}
                      </Typography>
                    </div>
                    <Typography
                      sx={{
                        display: "flex",
                        padding: "4px 16px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "12px",
                        borderRadius: "4px",
                        border: "1px solid var(--Gray-2, #ACB4BA)",
                        background: "var(--Gray-1, #E9EBEE)",
                        boxShadow: "2px 2px 10px 0px rgba(172, 180, 186, 0.30)",
                        color: "var(--Grey-6, #3B454F)",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                      }}
                    >
                      View
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </div>
        </div>

        <div
          style={{
            width: "45%",
            height: "400px",
            flexShrink: 0,
            borderRadius: "4px",
            border: "1px solid var(--Gray-2, #ACB4BA)",
            background: "var(--Pure-White, #FFF)",
            boxShadow: "0px 0px 7.6px -2px rgba(0, 0, 0, 0.25)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: "20px",
              paddingRight: "20px",
              marginTop: "20px",
            }}
          >
            <div>
              <Typography
                style={{
                  color: "var(--Grey-6, #3B454F)",
                  textAlign: "left",
                  fontFamily: "Montserrat",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                }}
              >
                Task History
              </Typography>
              <Typography
                style={{
                  color: "var(--Grey-3, #737D86)",
                  fontFamily: "Montserrat",
                  fontSize: "15px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  marginTop: "10px",
                }}
              >
                Show the history about tasks
              </Typography>
            </div>
            <div
              style={{
                position: "relative",
                width: "250px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  left: "10px",
                  display: "flex",
                  alignItems: "center",
                  zIndex: 10,
                  gap: "10px",
                  pointerEvents: "none",
                }}
              >
                <CalendarMonthIcon
                  sx={{
                    color: "var(--Grey-4, #5A646E)",
                    cursor: "pointer",
                  }}
                />
                {!(taskHistoryStartDate || taskHistoryEndDate) && (
                  <Typography
                    sx={{
                      color: "var(--Grey-4, #5A646E)",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                    }}
                  >
                    Select Date Range
                  </Typography>
                )}
              </div>
              <DatePicker
                selectsRange={true}
                startDate={taskHistoryStartDate}
                endDate={taskHistoryEndDate}
                onChange={(update) => setTaskHistoryDateRange(update)}
                placeholderText="" // Remove default placeholder
                className="history-datepicker"
                wrapperClassName="history-datepicker-wrapper"
                style={{
                  borderRadius: "var(--borderRadius, 4px)",
                  border: "1px solid var(--Gray-2, #ACB4BA)",
                  height: "40px",
                  paddingLeft: "40px", // Reduced padding to prevent overlap
                  padding: "0px 42px !important",
                  width: "calc(100% - 80px)",
                  boxSizing: "border-box",
                }}
              />
              {(taskHistoryStartDate || taskHistoryEndDate) && (
                <CloseIcon
                  onClick={() => setTaskHistoryDateRange([null, null])}
                  sx={{
                    color: "var(--Grey-4, #5A646E)",
                    position: "absolute",
                    right: "20px",
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                />
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              gap: "5px",
              paddingLeft: "20px",
              marginTop: "15px",
              flexWrap: "wrap",
              width: "95%",
              justifyContent: "space-evenly",
            }}
          >
            {["ALL", ...taskHistoryFunctionalities].map((label) => (
              <Typography
                key={label}
                onClick={() => setSelectedTaskHistoryFunctionality(label)}
                sx={{
                  display: "flex",
                  padding: "4px 16px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "12px",
                  borderRadius: "4px",
                  border: "1px solid var(--Gray-2, #ACB4BA)",
                  background:
                    selectedTaskHistoryFunctionality === label
                      ? "var(--Primary-Blue, #0E2B63)"
                      : "var(--Gray-1, #E9EBEE)",
                  boxShadow: "2px 2px 10px 0px rgba(172, 180, 186, 0.30)",
                  color:
                    selectedTaskHistoryFunctionality === label
                      ? "white"
                      : "var(--Grey-6, #3B454F)",
                  fontFamily: "Montserrat",
                  fontSize: "14px !important",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                  cursor: "pointer",
                }}
              >
                {label}
              </Typography>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              gap: "20px",
              paddingLeft: "30px",
              flexWrap: "wrap",
              width: "95%",
              justifyContent: "flex-start",
              marginTop: "35px",
            }}
          >
            {["ALL TASKS", "PENDING", "CANCELED", "COMPLETED"].map((label) => (
              <Typography
                key={label}
                onClick={() => setSelectedTaskStatus(label)}
                sx={{
                  color:
                    selectedTaskStatus === label
                      ? "var(--Primary-Blue, #0E2B63)"
                      : "var(--Grey-3, #737D86)",
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  fontWeight: 700,
                  cursor: "pointer",
                  borderBottom:
                    selectedTaskStatus === label
                      ? "2px solid var(--Primary-Blue, #0E2B63)"
                      : "none",
                  paddingBottom: "5px",
                }}
              >
                {label} ({taskStatusCounts[label]})
              </Typography>
            ))}
          </div>

          {/* Task History Timeline */}
          <div
            style={{
              height: "200px",
              overflowY: "auto",
              marginTop: "30px",
              paddingLeft: "10px",
            }}
          >
            <Timeline
              position="right"
              sx={{
                padding: 0,
                margin: 0,
                "& .MuiTimelineItem-root": {
                  minHeight: "70px",
                },
                "&::before": {
                  display: "none",
                },
              }}
            >
              {filteredTaskHistory.map((item, index) => (
                <TimelineItem
                  key={index}
                  sx={{
                    "&::before": {
                      display: "none",
                    },
                  }}
                >
                  {/* Date on the left */}
                  <TimelineContent
                    sx={{
                      flex: "0 0 16%", // Flexible width
                      textAlign: "right",
                      paddingRight: "15px",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color:
                          item.status === "Completed"
                            ? "#027100"
                            : item.status === "Pending"
                            ? "#FBBA00"
                            : item.status === "Canceled"
                            ? "var(--Gray-3, #737D86)"
                            : "var(--Grey-6, #3B454F)",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      {item.date}
                    </Typography>
                  </TimelineContent>

                  {/* Timeline Dot and Connector */}
                  <TimelineSeparator
                    sx={{
                      position: 'relative',
                      top: "15px",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <TimelineDot
                      sx={{
                        height: "15px",
                        width: "15px",
                        margin: "0px",
                        backgroundColor: "white",
                        border: "5px solid",
                        borderColor:
                          item.status === "Completed"
                            ? "#027100"
                            : item.status === "Pending"
                            ? "#FBBA00"
                            : item.status === "Canceled"
                            ? "var(--Gray-3, #737D86)"
                            : "var(--Grey-6, #3B454F)",
                        boxShadow: "none",
                      }}
                    />
                    {index < filteredTaskHistory.length - 1 && (
                      <TimelineConnector
                        sx={{
                          backgroundColor:
                            index % 2 === 0
                              ? "var(--Gray-2, #ACB4BA)"
                              : "var(--Gray-2, #ACB4BA)",
                          width: "2px",
                          margin: 0,
                          flex: 1,
                        }}
                      />
                    )}
                  </TimelineSeparator>

                  {/* Event on the right */}
                  <TimelineContent
                    sx={{
                      flex: "0 0 65%", // Flexible width
                      textAlign: "left",
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <div style={{ flexGrow: 1 }}>
                      <Typography
                        variant="body2"
                        sx={{
                          color:
                            item.status === "Completed"
                              ? "#027100"
                              : item.status === "Pending"
                              ? "#FBBA00"
                              : item.status === "Canceled"
                              ? "var(--Gray-3, #737D86)"
                              : "var(--Grey-6, #3B454F)",
                          fontFamily: "Montserrat",
                          fontWeight: 600,
                          fontSize: "20px !important",
                        }}
                      >
                        {item.event}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#ACB4BA",
                          fontFamily: "Montserrat",
                          fontWeight: 700,
                          fontSize: "12px !important",
                          marginTop: "2px",
                        }}
                      >
                        {item.functionality}
                      </Typography>
                    </div>
                    <Typography
                      sx={{
                        display: "flex",
                        padding: "4px 16px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "12px",
                        borderRadius: "4px",
                        border: "1px solid var(--Gray-2, #ACB4BA)",
                        background:
                          item.status === "Completed"
                            ? "var(--Green-1, #E6F5EA)"
                            : item.status === "Pending"
                            ? "var(--Yellow-1, #FFF4E5)"
                            : "var(--Gray-1, #E9EBEE)",
                        color:
                          item.status === "Completed"
                            ? "var(--Green-6, #2E7D32)"
                            : item.status === "Pending"
                            ? "#FBBA00"
                            : item.status === "Canceled"
                            ? "var(--Gray-3, #737D86)"
                            : "var(--Grey-6, #3B454F)",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.status}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </div>
        </div>
      </div>

      <Box sx={{ height: 50 }}></Box>
    </Layout>
  );
};

export default Tasks;
