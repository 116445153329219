import React, { useState, useEffect, useContext } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import { useNavigate } from "react-router-dom";
import PriceParameterizationService from "services/PriceParameterizationService";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PriceParameterizationTable from "./PriceParameterizationTable";
import { useTranslation } from "react-i18next";
import iconSearch from "assets/iconSearchs.svg";

const GetPriceParameterization = () => {
  const navigate = useNavigate();
  const [priceParameterizationData, setPriceParameterizationData] = useState(
    []
  );
  const [
    filteredPriceParameterizationData,
    setFilteredPriceParameterizationData,
  ] = useState([]);

  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [menu, setMenu] = useState([]);
  const [investType, setInvestType] = useState([]);
  const [edificationType, setEdificationType] = useState([]);
  const [investmentGroup, setInvestmentGroup] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [invType, setInvType] = useState("");
  const [ediType, setEdiType] = useState("");
  const [invDesc, setInvDesc] = useState("");
  const [invGrp, setInvGrp] = useState("");
  console.log("invGrp",invGrp);

  const { t, i18n } = useTranslation();

  const callPriceParameterizationAPI = (pageNumber, size) => {
    setLoading(true);
    PriceParameterizationService.GetPriceParameterizationList({
      pageNumber,
      size,
    })
      .then((res) => {
        const priceParameterizationResults =
          res?.data?.response?.priceParametrizationDetails || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setPriceParameterizationData(priceParameterizationResults);
        setFilteredPriceParameterizationData(priceParameterizationResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setPriceParameterizationData([]);
        setFilteredPriceParameterizationData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getfullPriceParameterAPI = () => {
    setLoading(true);
    PriceParameterizationService.GetPriceParameterizationList({
      pageNumber: currentPage,
      size: 50,
    })
      .then((res) => {
        const priceParameterizationResults =
          res?.data?.response?.priceParametrizationDetails || [];
        const menues = priceParameterizationResults.map(
          (e) => e.gtGeneticAbbreviation || []
        );
        setMenu(menues);
        const investmentTypeList = priceParameterizationResults.map(
          (e) => e.inInvestmentName || []
        );
        setInvestType(investmentTypeList);
        const edificationList = priceParameterizationResults.map(
          (e) => e.edificationType || []
        );
        setEdificationType(edificationList);
        const investmentGroupsList = priceParameterizationResults.map(
          (e) => e.igInvestmentGroupDescription || []
        );
        console.log("deetttaa", investmentGroupsList);
        setInvestmentGroup(investmentGroupsList);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setPriceParameterizationData([]);
        setFilteredPriceParameterizationData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // const callSearchPriceParameterizationAPI = (
  //   pageNumber,
  //   size,
  //   description,
  //   inInvestmentName,
  //   edificationType,
  //   igInvestmentGroupDescription
  // ) => {
  //   setLoading(true);
  //   const params = [
  //     `pageNumber=${pageNumber}`,
  //     `size=${size}`,
  //     description && `description=${description}`,
  //     inInvestmentName && `inInvestmentName=${inInvestmentName}`,
  //     edificationType && `edificationType=${edificationType}`,
  //     igInvestmentGroupDescription &&
  //       `igInvestmentGroupDescription=${igInvestmentGroupDescription}`,
  //   ]
  //     .filter(Boolean)
  //     .join("&");

  //   PriceParameterizationService.searchPriceParameter(`?${params}`)
  //     .then((res) => {
  //       const results =
  //         res?.data?.response?.priceParametrizationOfInvestment || [];
  //       setCurrentPage(pageNumber);
  //       setItemsPerPage(size);
  //       setPriceParameterizationData(results);
  //       setFilteredPriceParameterizationData(results);
  //       setTotalItems(res?.data?.response?.totalItems || 0);
  //     })
  //     .catch((error) => {
  //       setMessage(error?.response?.data?.message || error?.message);
  //       setOpenAlert(true);
  //       setSeverity("error");
  //       setPriceParameterizationData([]);
  //       setFilteredPriceParameterizationData([]);
  //       setTotalItems(0);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };


  const callSearchPriceParameterizationAPI = (
    pageNumber,
    size,
    description,
    inInvestmentName,
    edificationType,
    igInvestmentGroupDescription
  ) => {
    setLoading(true);
    const params = [
      `pageNumber=${pageNumber}`,
      `size=${size}`,
      description && `description=${description}`,
      inInvestmentName && `inInvestmentName=${inInvestmentName}`,
      edificationType && `edificationType=${edificationType}`,
      igInvestmentGroupDescription?.length > 0 &&
        `igInvestmentGroupDescription=${igInvestmentGroupDescription.join(",")}`, // Join array if it exists
    ]
      .filter(Boolean)
      .join("&");
  
    PriceParameterizationService.searchPriceParameter(`?${params}`)
      .then((res) => {
        const results =
          res?.data?.response?.priceParametrizationOfInvestment || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setPriceParameterizationData(results);
        setFilteredPriceParameterizationData(results);
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setPriceParameterizationData([]);
        setFilteredPriceParameterizationData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  useEffect(() => {
    callPriceParameterizationAPI(currentPage, itemsPerPage);
    if (currentPage <= 0) {
      getfullPriceParameterAPI();
    }
  }, [currentPage, itemsPerPage]);

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    if (searchKeyword) {
      callSearchPriceParameterizationAPI(
        pageNumber - 1,
        itemsPerPage,
        searchKeyword
      );
    } else {
      callPriceParameterizationAPI(pageNumber - 1, itemsPerPage);
    }
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t("priceParameterization.pageTitle")}
      background="#ffffff"
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: 1,
          padding: 1.5,
          height: "122px",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1 }} />
          <Typography>{t("priceParameterization.sectionName")}</Typography>
        </Box>

        <Grid container spacing={1.5} sx={{ alignItems: "center" }}>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <TextField
                name="investmentDescription"
                label={t("priceParameterization.investmentDescription")}
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={iconSearch}
                        alt="search"
                        style={{
                          width: "24px",
                          height: "24px",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => {
                  const newValue = event.target.value;
                  setInvDesc(newValue); // Update Abbreviation state
                  callSearchPriceParameterizationAPI(
                    0,
                    itemsPerPage,
                    newValue,
                    invType,
                    ediType,
                    invGrp
                  );
                }}
                // onBlur={(event) => {
                //   const newValue = event.target.value;
                //   if (!newValue) {
                //     setInvDesc("");
                //     callSearchPriceParameterizationAPI(
                //       0,
                //       itemsPerPage,
                //       "",
                //       invType,
                //       ediType,
                //       invDesc,
                //       statusFilter
                //     );
                //   }
                // }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                onChange={(event, newValue) => {
                  setInvType(newValue || ""); // Update Description state
                  //console.log("opcheck",newValue);
                  callSearchPriceParameterizationAPI(
                    0,
                    itemsPerPage,
                    invDesc,
                    newValue,
                    ediType,
                    invGrp
                  );
                }}
                onInputChange={(event, newValue) => {
                  if (!newValue) {
                    setInvType("");
                    callSearchPriceParameterizationAPI(
                      0,
                      itemsPerPage,
                      invDesc,
                      newValue,
                      ediType,
                      invGrp
                    );
                  }
                }}
                options={investType}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label={t("priceParameterization.investmentType")}
                    placeholder="Select an Option"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                onChange={(event, newValue) => {
                  setEdiType(newValue || ""); // Update Culture state
                  console.log("opcheck", newValue);
                  callSearchPriceParameterizationAPI(
                    0,
                    itemsPerPage,
                    invDesc,
                    invType,
                    newValue,
                    invGrp
                  );
                }}
                options={edificationType}
                //getOptionLabel={(option) => option.cultureName || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label={t("priceParameterization.edificationType")}
                    placeholder="Select an Option"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            {/* <FormControl fullWidth>
              <Autocomplete
                disablePortal
                onChange={(event, newValue) => {
                  
                  setInvGrp(newValue || ""); // Update Culture state
                  callSearchPriceParameterizationAPI(
                    0,
                    itemsPerPage,
                    invDesc,
                    invType,
                    ediType,
                    newValue,
                  );
                }}
                options={investmentGroup}
                //getOptionLabel={(option) => option.cultureName || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label={t("priceParameterization.investmentGroups")}
                    placeholder="Select an Option"
                    fullWidth
                  />
                )}
              />
            </FormControl> */}
            <FormControl fullWidth>
              <Autocomplete
                multiple // Enable multi-select
                disablePortal
                onChange={(event, newValue) => {
                  console.log("new value is:",newValue);
                  // setInvGrp(newValue || []); // Update state to handle multiple values
                  const updatedValue = newValue || []; // Ensure it's an array
                 setInvGrp(updatedValue); // Update state
                  callSearchPriceParameterizationAPI(
                    0,
                    itemsPerPage,
                    invDesc,
                    invType,
                    ediType,
                    newValue // Pass the array of selected values
                  );
                }}
                options={investmentGroup}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label={t("priceParameterization.investmentGroups")}
                    placeholder={invGrp.length === 0 ? "Select an Option" : ""}
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <PriceParameterizationTable
        priceParameterizationData={filteredPriceParameterizationData}
        setTotalItems={setTotalItems}
        setPriceParameterizationData={setFilteredPriceParameterizationData}
      />
    </Layout>
  );
};

export default GetPriceParameterization;
