import { httpGrow } from "../http-common";

const createFarmerClassification = (data) => {
  return httpGrow.post(`/farmer-classification/save`, data);
};

const updateFarmerClassification = (data) => {
  return httpGrow.put(`farmer-classification/update`, data);
};

const getFarmerClassification = async (query) => {
  const { pageNumber, pageSize, fcCategory, isActive } = query;

  const response = await httpGrow.get(`/farmer-classification/search`, {
    params: {
      pageNumber,
      pageSize,
      category: fcCategory || undefined,
      status: isActive,
    },
  });
  return response;
};

const FarmerClassificationService = {
  createFarmerClassification,
  updateFarmerClassification,
  getFarmerClassification,
};

export default FarmerClassificationService;
