import React, { useEffect, useState, useContext } from "react";
import { Typography, Box, Button, Modal } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "./FarmerSampleEstimate.css";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import { SnackbarContext } from "context/snackBarContext";
import DefineFarmerSampleEstimateService from "services/DefineFarmerSampleEstimateService";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";

const FarmerSampleEstimateTable = (props) => {
  const [estimateData, setEstimateData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [open, setOpen] = useState(false); // Modal state
  const [deleteId, setDeleteId] = useState(null); // ID of the record to delete
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const { t } = useTranslation();

  useEffect(() => {
    console.log("Fetched data:", props);
    setEstimateData(props.estimateData);
    setOriginalData(props.estimateData);
  }, [props.estimateData]);

  const handleDeleteClick = (analysisId) => {
    setDeleteId(analysisId); // Store the ID of the record to delete
    setOpen(true); // Open the confirmation modal
  };

  const handleDeleteConfirm = async () => {
    try {
      const response =
        await DefineFarmerSampleEstimateService.deleteFarmerSampleEstimate(deleteId);
      if (response.status === 200) {
        setMessage(t("Deleted Successfully"));
        setSeverity("success");
        setOpenAlert(true);

        if (props.onDelete) {
          props.onDelete();
        }
      } else {
        setMessage(t("Failed to delete "));
        setSeverity("error");
        setOpenAlert(true);
      }
    } catch (error) {
      setMessage(t("Error deleting Farmer Sample"));
      setSeverity("error");
      setOpenAlert(true);
    } finally {
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false); // Close the modal without deleting
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...estimateData].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setEstimateData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <ArrowUpwardIcon fontSize="small" />;
      } else if (sortConfig.direction === "desc") {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
    return <ArrowDownwardIcon fontSize="small" />;
  };

  return (
    <div className="table-container">
      <table className="farmer-table">
        <thead>
          <tr>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("educationTypeDesc")}
            >
              {t("code")}
              {getSortIcon("educationTypeDesc")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("educationTypeDesc")}
            >
              {t("defineFarmerSampleEstimatePositionPlant.farmer")}{" "}
              {getSortIcon("educationTypeDesc")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("educationTypeDesc")}
            >
              {t("defineFarmerSampleEstimatePositionPlant.fieldTechnician")}{" "}
              {getSortIcon("educationTypeDesc")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("educationTypeDesc")}
            >
              {t("defineFarmerSampleEstimatePositionPlant.geneticType")}{" "}
              {getSortIcon("educationTypeDesc")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("educationTypeDesc")}
            >
              {t("defineFarmerSampleEstimatePositionPlant.segmentation")}{" "}
              {getSortIcon("educationTypeDesc")}
            </th>
            <th className="actions-header">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  justifyContent: "left",
                  fontWeight: "bold",
                }}
              >
                {t("actions")}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {estimateData?.map((row, index) => (
            <tr key={index}>
              <td>{row.farmerCode}</td>
              <td>{row.farmerFullName}</td>
              <td>{row.saSoilAnalysisDescription}</td>
              <td>{row.saCollectionDate}</td>
              <td>{row.saResultDate}</td>
              <td className="actions-cell">
                <Tooltip title="DELETE" arrow placement="top">
                  <img
                    className="btn delete-btn"
                    onClick={() => handleDeleteClick(row.saAnalysisID)}
                    src={_ButtonDelete_}
                    alt={t("buttons.delete")}
                  />
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for delete confirmation */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "520px",
            margin: "auto",
            mt: 25,
            height: "200px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              {t("deleteConfirmation")}
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, fontWeight: 400 }}
            >
              {t("deleteConfirmationMessage")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              {t("no")}
            </Button>
            <Button
              onClick={handleDeleteConfirm}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              {t("yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default FarmerSampleEstimateTable;
