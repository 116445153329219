import React, { useState, useEffect, useContext } from "react";
import "./Transporter.css";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Box, Button, Modal, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SnackbarContext } from "context/snackBarContext";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import TransporterGetService from "services/TransporterGetService";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 580,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const TransporterTable = (props) => {
  const { t } = useTranslation();
  // console.log("sdfghjkl",props);
  const [soilParameterData, setSoilParameterData] = useState([]);
  const [transporterData, setTransporterData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...transporterData].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setTransporterData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <ArrowUpwardIcon fontSize="small" />;
      } else if (sortConfig.direction === "desc") {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
    // Default icon (ArrowDownward) when no sorting is applied
    return <ArrowDownwardIcon fontSize="small" />;
  };

  const handleOpen = (sapAnalysisId) => {
    setDeleteId(sapAnalysisId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const response = await TransporterGetService.deleteTransporter(deleteId);
      if (response.status === 200) {
        setMessage(response.data.message);
        setSeverity("success");
        setOpenAlert(true);
        setTransporterData((prevData) =>
          prevData.filter((item) => item.transporterID !== deleteId)
        );
      } else {
        setMessage("Failed to delete Furnace Type");
        setSeverity("error");
        setOpenAlert(true);
      }
    } catch (error) {
      setMessage("Error deleting Furnace Type");
      setSeverity("error");
      setOpenAlert(true);
      console.error("Error deleting Furnace Type:", error);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    setTransporterData(props.transporterData);
    setOriginalData(props.transporterData);
  }, [props.transporterData]);

  return (
    <div className="table-container">
      <table className="custom-table">
        <thead>
          <tr>
            <th
              onClick={() => handleSort("transporterName")}
              style={{
                cursor: "pointer",
                display: "flex",
                //justifyContent: "space-between",
              }}
            >
              {t("TransportationRegistration.table.transporterName")}{" "}
              {getSortIcon("transporterName")}
            </th>
            <th
              onClick={() => handleSort("fieldTechnicianUser")}
              style={{
                cursor: "pointer",
                display: "flex",
                //justifyContent: "space-between",
              }}
            >
              {t("TransportationRegistration.table.fieldTechnician")}{" "}
              {getSortIcon("fieldTechnicianUser")}
            </th>

            <th
              onClick={() => handleSort("status")}
              style={{
                cursor: "pointer",
                display: "flex",
                //justifyContent: "space-between",
              }}
            >
              {t("TransportationRegistration.table.status")}{" "}
              {getSortIcon("status")}
            </th>
            <th className="actions-header">Actions</th>
          </tr>
        </thead>

        <tbody>
          {transporterData.map((row, index) => (
            <tr key={index}>
              <td
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/viewTransporterRegistration", {
                    state: { row: row, edit: false },
                  })
                }
              >
                {row.transporterName}
              </td>
              <td
                style={{ cursor: "pointer" }}
                // onClick={() =>
                //   navigate("/viewsoilParameter", {
                //     state: { row: row, edit: false },
                //   })
                // }
                onClick={() =>
                  navigate("/viewTransporterRegistration", {
                    state: { row: row, edit: false },
                  })
                }
              >
                {row.fieldTechnicianUser}
              </td>

              <td
                style={{ cursor: "pointer" }}
                // onClick={() =>
                //   navigate("/viewsoilParameter", {
                //     state: { row: row, edit: false },
                //   })
                // }
                onClick={() =>
                  navigate("/viewTransporterRegistration", {
                    state: { row: row, edit: false },
                  })
                }
              >
                {row.status}
              </td>
              <td className="actions-cell">
                <img
                  className="btn edit-btn"
                  onClick={() =>
                    navigate("/edittransporterRegistration", {
                      state: { row: row, edit: true },
                    })
                  }
                  src={_ButtonEdit_}
                  alt="Edit"
                />
                <img
                  className="btn delete-btn"
                  onClick={() => handleOpen(row.transporterID)}
                  src={_ButtonDelete_}
                  alt="Delete"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              {t("deleteConfirmation")}
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography sx={{ mt: 2, fontWeight: 400 }}>
              {t("deleteConfirmationMessage")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": {
                  background: "#737D86",
                },
              }}
            >
              {t("no")}
            </Button>
            <Button
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": {
                  background: "#004F9F",
                },
              }}
              onClick={handleDelete}
            >
              {t("yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default TransporterTable;
