import { httpGrow } from "../http-common";

const geModuleAPI = async () => {
    const response = await httpGrow.get('/functionalities');
    return response;
  }

  const getAllData = async (params = {}) => {
    const queryParams = new URLSearchParams();

    const pageNumber = params.pageNumber ?? 0;
    const pageSize = params.pageSize ?? 5;

    queryParams.append('pageNumber', pageNumber);
    queryParams.append('pageSize', pageSize);

    if (params.functionalityName) {
        queryParams.append('functionalityName', params.functionalityName);
    }

    if (params.requestDate) {
        queryParams.append('requestDate', params.requestDate);
    }

    if (params.status) {
        // If status is an array, append each value
        if (Array.isArray(params.status)) {
            params.status.forEach(status => {
                queryParams.append('status', status);
            });
        } else {
            // If single status is provided
            queryParams.append('status', params.status);
        }
    }

    // Construct URL with query parameters
    const url = `/workFlowApprovalDashboard/search${queryParams.toString() ? `?${queryParams.toString()}` : ''}`;
    
    const response = await httpGrow.get(url);
    return response;
};

// Example search function with type definitions
const searchApprovalDashboard = async ({
    functionalityName = '',
    requestDate = '',
    status = [],
    pageNumber = 0,
    pageSize = 50
} = {}) => {
    return getAllData({
        functionalityName,
        requestDate,
        status,
        pageNumber,
        pageSize
    });
};
 

const ApprovalDashboardService = {
    geModuleAPI,
    getAllData,
    searchApprovalDashboard
}

export default ApprovalDashboardService;