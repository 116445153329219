import React, { useState, useEffect, useContext } from "react";
import "./TechnicalRecomendation";
import { Box, Button, Modal, Tooltip, Typography } from "@mui/material";
import { SnackbarContext } from "context/snackBarContext";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import TechnicalRecomendationService from "services/TechnicalRecomendationService";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 580,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const TechnicalRecomendationGetData = (props) => {
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const { t, i18n } = useTranslation();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  useEffect(() => {
    setFilteredTableData(props.filteredTableData);
  }, [props.filteredTableData]);
  console.log('filteredTableData::',filteredTableData);

  const handleDeleteClick = (record) => {
    setDeleteRecord(record);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setDeleteRecord(null);
  };
  const handleEditClick = (row) => {
    console.log("row", row);
    props.onEdit(row); // Pass the row data to parent component
  };

  const handleDelete = async () => {
    if (!deleteRecord) return;
    console.log(deleteRecord, "deleteRecord");
    try {
      const response = await TechnicalRecomendationService.deleteDataAPI({
        cropSeasonId: deleteRecord.cropSeasonId,
        investmentId: deleteRecord.investmentId
          ? deleteRecord.investmentId
          : "",
        agriculturalSuppliesId: deleteRecord.agriculturalSuppliesId
          ? deleteRecord.agriculturalSuppliesId
          : "",
        type: deleteRecord.typeId,
      });

      setOpenAlert(true);
      setSeverity("success");
      setMessage(t("TechnicalRecomendation.deleteSuccess"));

      // Update the table data by removing the deleted record
      setFilteredTableData((prevData) =>
        prevData.filter((item) => item.trtsId !== deleteRecord.trtsId)
      );

      handleClose();
    } catch (error) {
      setOpenAlert(true);
      setSeverity("error");
      setMessage(t("TechnicalRecomendation.deleteError"));
    }
  };
  

  return (
    <div className="table-container">
      <table className="custom-table">
        <thead>
          <tr>
            <th
              style={{
                cursor: "pointer",
                display: "flex",
              }}
            >
              {props.typeId === "GRTRTSTYPE-02"
            ? t("TechnicalRecomendation.AgriculturalSupply")
            : t("TechnicalRecomendation.Investment")}
            </th>
            <th
              style={{
                cursor: "pointer",
                display: "flex",
              }}
            >
              {t("TechnicalRecomendation.Rules")}
            </th>
            <th
              style={{
                cursor: "pointer",
                display: "flex",
              }}
            >
              {t("TechnicalRecomendation.Quantity")}
            </th>
            <th className="actions-header">
              {t("TechnicalRecomendation.Actions")}
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredTableData.map((row, index) => (
            <tr key={index}>
              <td style={{ cursor: "pointer" }}> {props.typeId === "GRTRTSTYPE-02" ? row.agriSupplyName : row.investmentName}</td>
              <td style={{ cursor: "pointer" }}>{row.rule}</td>
              <td style={{ cursor: "pointer" }}>{row.quantity}</td>

              <td className="actions-cell">
              <Tooltip title="EDIT" arrow placement="top">
                <img
                  className="btn edit-btn"
                  src={_ButtonEdit_}
                  alt="Edit"
                  onClick={() => handleEditClick(row)}
                />
                </Tooltip>
                <Tooltip title="DELETE" arrow placement="top">
                <img
                  className="btn delete-btn"
                  src={_ButtonDelete_}
                  alt="Delete"
                  onClick={() => handleDeleteClick(row)}
                />
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>

      </table>

      <Modal open={open} onClose={handleClose}>
  <Box sx={style}>
    <Box
      sx={{
        background: "#102A62",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
      >
        {t("TechnicalRecomendation.deleteConfirm")}
      </Typography>
    </Box>
    <Box sx={{ p: 2 }}>
      <Typography sx={{ mt: 2, fontWeight: 400 }}>
        {t("TechnicalRecomendation.deleteMessage")}
      </Typography>
    </Box>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
        mb: 1,
      }}
    >
      <Button
        onClick={handleClose}
        sx={{
          background: "#737D86",
          width: "100px",
          color: "#ffffff",
          "&:hover": {
            background: "#737D86",
          },
        }}
      >
        {t("TechnicalRecomendation.cancel")}
      </Button>
      <Button
        sx={{
          background: "#004F9F",
          width: "100px",
          color: "#ffffff",
          "&:hover": {
            background: "#004F9F",
          },
        }}
        onClick={handleDelete} // Corrected here
      >
        {t("TechnicalRecomendation.yes")}
      </Button>
    </Box>
  </Box>
</Modal>
    </div>
  );
};

export default TechnicalRecomendationGetData;
