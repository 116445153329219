import { httpGrow } from "../http-common";

const getCuringUnits = async (query) => {
  console.log("Query : ", query);
  const { pageNumber, size, curingUnit, curingType, status } = query;

  const response = await httpGrow.get(`/curing-unit/search`, {
    params: {
      pageNumber,
      size,
      curingUnitName: curingUnit || undefined,
      curingType: curingType || undefined,
      status: status,
    },
  });
  return response;
};

const getCuringTypes = async () => {
  const response = await httpGrow.get(
    `/curing-type/list?cultureName=Tobacco&status=true`
  );
  return response;
};

const getFurnaceTypes = async () => {
  const response = await httpGrow.get(`/furnace-type/find?isActive=true`);
  return response;
};

const getCuringControllers = async () => {
  const response = await httpGrow.get(`/curing-device/find?isActive=true`);
  return response;
};

const getPicklistApi = async () => {
  const response = await httpGrow.post(`/picklist`, {
    pickListCodes: ["CURST", "CURFP"],
  });
  return response;
};

const createCuringUnit = (data) => {
  return httpGrow.post(`/curing-unit`, data);
};

const updateCuringUnit = (data) => {
  return httpGrow.put(`curing-unit`, data);
};

const deleteCuringUnit = (curingUnitId) => {
  return httpGrow.delete(`/curing-unit/${curingUnitId}`);
};

const CuringUnitService = {
  getCuringTypes,
  getFurnaceTypes,
  getCuringControllers,
  getPicklistApi,
  createCuringUnit,
  updateCuringUnit,
  getCuringUnits,
  deleteCuringUnit,
};

export default CuringUnitService;
