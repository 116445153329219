import { styled } from "@mui/material/styles";
import React, {
  useContext,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  IconButton,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
  Modal,
  Divider,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "context/snackBarContext";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import SearchTextInput from "components/common/SearchTextInput";
import Save from "assets/SaveNew.svg";
import AgriculturalSuppliesTargetsService from "services/AgriculturalSuppliesTargetsService";

const StyledTableContainer = styled(TableContainer)({
  boxShadow: "none",
  // border: "1px solid #E5E7EB",
  borderRadius: "8px",
  maxWidth: "1880px",
  margin: "0 auto",
  overflowX: "auto", // Enable horizontal scrolling
});

const StyledTable = styled(Table)({
  borderCollapse: "separate",
  borderSpacing: 0,
  minWidth: "100%", // Ensure table spans horizontally
  borderCollapse: "separate",
  borderSpacing: "0px 4px",
});

const HeaderCell = styled(TableCell)({
  backgroundColor: "#E9EBEE",
  color: "#3B454F",
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontSize: "20px",
  textTransform: "uppercase",
  textAlign: "center",
  padding: "13px 20px",
  borderBottom: "none",
  borderTopLeftRadius: "8px", // Rounded top-left edge
  borderTopRightRadius: "8px", // Rounded top-right edge
  overflow: "hidden", // Ensures the rounded edges are respected
});

const SecondaryHeaderCell = styled(TableCell)({
  backgroundColor: "#E9EBEE",
  color: "#3B454F",
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "20px",
  textAlign: "center",
  padding: "13px 20px",
  borderBottom: "none",
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  overflow: "hidden",
});

const SubHeaderCell = styled(TableCell)({
  backgroundColor: "#FFFFFF",
  color: "#3B454F",
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "16px",
  padding: "13px 20px",
  border: "1px solid #E9EBEE",
  borderRadius: "8px", // Rounded top-left edge
  overflow: "hidden",
  "&:last-child": {
    borderRight: "none",
  },
});
const SubHeaderCell2 = styled(TableCell)({
  backgroundColor: "#FFFFFF",
  color: "#3B454F",
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "16px",
  padding: "0px!important",
  paddingRight: "5px !important",
  borderBottom: "none",
  overflow: "hidden",
  "&:last-child": {
    borderRight: "none",
  },
});

const DataCell = styled(TableCell)({
  fontFamily: "Montserrat",
  fontWeight: 400,
  fontSize: "15px",
  color: "#3B454F",
  padding: "13px 20px",
  backgroundColor: "#EFEFEF",
  textAlign: "center",
  // borderBottom: "1px solid #E9EBEE",
  // borderRight: "1px solid #E9EBEE",
  "&:last-child": {
    borderRight: "none",
  },
});

const TotalCell = styled(TableCell)({
  backgroundColor: "#E9EBEE",
  color: "#000000",
  fontFamily: "Montserrat",
  fontWeight: 700,
  fontSize: "16px",
  padding: "13px 20px",
  borderBottom: "none",
  textAlign: "center",
});

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    padding: "13px 5px",
    fontFamily: "Montserrat",
    fontSize: "15px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
});

const AgriculturalSuppliesTable = forwardRef(
  (
    {
      data,
      cropYearUUID,
      ihId,
      onSave,
      childNodes,
      refreshData,
      isTopHierarchyNode,
      showExitWarningOverlayFeed,
      setShowExitWarningOverlayFeed,
    },
    ref
  ) => {
    const [tableData, setTableData] = useState([]);
    // console.log("TABLE Data", tableData)

    const { setOpenAlert, setSeverity, setMessage } =
      useContext(SnackbarContext);
    const { t, i18n } = useTranslation();
    const columns = ["Distributed", "Consumed", "Balance"];
    const headers = ["Investment VA", "Investment AM", "Investment BY"];
    const [showColumns, setShowColumns] = useState({
      distributed: true,
      consumed: true,
      balance: true,
    });
    console.log("ColumnsState", showColumns);
    const [minColumnModel, setMinColumnModel] = useState(false);

    const handleColumnChange = (key, isChecked) => {
      const uncheckedCount = Object.values(showColumns).filter(
        (value) => !value
      ).length;

      if (!isChecked && uncheckedCount >= 2) {
        console.log("ALL 3 FALSE");
        setMinColumnModel(true);
        return;
      }
      setShowColumns((prev) => ({
        ...prev,
        [key]: isChecked,
      }));
      setMinColumnModel(false);
    };

    const [manageItemsModal, setManageItemsModal] = useState(false);
    const [removeInvestmentModal, setRemoveInvestmentModal] = useState(false);
    const [removedInvestTableId, setRemovedInvestmentTableId] = useState();
    const [investmentList, setInvestmentList] = useState([]);
    const [investmentListSearch, setInvestmentListSearch] = useState("");
    const [agriSuppliesHeader, setAgriSuppliesHeader] = useState([]);
    const [agriSuppliesTotal, setAgriSuppliesTotal] = useState([]);
    console.log("agriSuppliesHeader", agriSuppliesHeader);

    const handleInvestmentListChange = (row, isChecked) => {
      if (!isChecked) {
        // for false i.e unchecked
        setRemovedInvestmentTableId(row.igInvestmentGroupId);
        setRemoveInvestmentModal(true);
      } else {
        // for true i.e checked
        setInvestmentList((prevList) =>
          prevList.map(
            (item) =>
              item.igInvestmentGroupId === row.igInvestmentGroupId
                ? { ...item, inHeaderList: true } // Add inHeaderList: true for the matching item
                : item // Leave the rest unchanged
          )
        );
        setAgriSuppliesHeader((prevHeader) => [
          ...prevHeader,
          { ...row, isHeader: true },
        ]);
      }
    };

    const handleagriSuppliesHeaderChange = (selectedHeader) => {
      setAgriSuppliesHeader((prevHeader) =>
        prevHeader.map(
          (header) =>
            // Check if the header's description matches the selectedHeader's description
            header.asrSupplyName === selectedHeader.asrSupplyName
              ? { ...header, isHeader: !selectedHeader.isHeader } // Toggle the isHeader value
              : header // Leave the other headers unchanged
        )
      );
    };

    const handleRemoveInvestmentfromModal = () => {
      setInvestmentList((prevList) =>
        prevList.map(
          (item) =>
            item.igInvestmentGroupId === removedInvestTableId
              ? { ...item, inHeaderList: false } // Add inHeaderList: true for the matching item
              : item // Leave the rest unchanged
        )
      );
      setAgriSuppliesHeader((prevHeader) =>
        prevHeader.filter(
          (item) => item.igInvestmentGroupId !== removedInvestTableId
        )
      );
    };

    const handleTargetChange = (inputValue, ihId, description, rowIndex) => {
      if (showExitWarningOverlayFeed === false) {
        setShowExitWarningOverlayFeed(true);
      }

      console.log("INPUT VALUE", typeof inputValue);
      setTableData((prev) => {
        const updatedData = [...prev];
        const targetRow = updatedData.find(
          (row) =>
            row.ihId === ihId && row.agriSupplyDescription === description
        );
        if (targetRow) {
          targetRow.target = inputValue; // Update target value as a string
        }
        return updatedData;
      });
    };

    const fetchagriSuppliesHeader = async () => {
      try {
        const agriSuppliesResponse =
          await AgriculturalSuppliesTargetsService.getAgriculturalSupplies();

        const IsActiveTarget =
          agriSuppliesResponse.data.response.agriculturalSupplies.filter(
            (target) => target.asrTarget === true
          );

        const updatedIsActiveTarget = IsActiveTarget.map((group) => ({
          ...group,
          isHeader: group.asrTarget,
        }));
        console.log("INSIDE HEADER", updatedIsActiveTarget);
        setAgriSuppliesHeader(updatedIsActiveTarget);
      } catch (error) {
        console.error("Error fetching investment header data:", error);
      }
    };
    console.log("HEADER", agriSuppliesHeader);
    useEffect(() => {
      console.log("INSIDE HEADER EFFECT", data);
      fetchagriSuppliesHeader();
    }, [data]);

    useEffect(() => {
      if (childNodes && agriSuppliesHeader.length > 0) {
        console.log("INSIDE DATA TABLE EFFECT", agriSuppliesHeader.length);
        // Initialize initialData as an empty array if data is not provided or empty
        let initialData = data && data.length > 0 ? [...data] : [];

        childNodes.forEach((child) => {
          const childExists = initialData.some(
            (item) => item.ihId === child.value
          );
          if (!childExists) {
            agriSuppliesHeader.forEach((supply) => {
              const supplyExists = initialData.some(
                (item) =>
                  item.ihId === child.value &&
                  item.agriSupplyDescription === supply.asrSupplyName
              );

              if (!supplyExists) {
                initialData.push({
                  ihId: child.value,
                  agriSupplyDescription: supply.asrSupplyName,
                  agriculturalSuppliesId: supply.asrSupplyId,
                  target: 0,
                  distributed: 0,
                  consumed: 0,
                  balance: 0,
                  createdDateTimeUTC: new Date().toISOString(),
                  modifiedDateTimeUTC: new Date().toISOString(),
                  createdByUser: "defaultUser",
                  modifiedByUser: "defaultUser",
                  agrSupplyInputVolTrgtId: "defaultId",
                });
              }
            });
          } else {
            agriSuppliesHeader.forEach((supply) => {
              const supplyExists = initialData.some(
                (item) =>
                  item.ihId === child.value &&
                  item.agriSupplyDescription === supply.asrSupplyName
              );

              if (!supplyExists) {
                initialData.push({
                  ihId: child.value,
                  agriSupplyDescription: supply.asrSupplyName,
                  agriculturalSuppliesId: supply.asrSupplyId,
                  target: 0,
                  distributed: 0,
                  consumed: 0,
                  balance: 0,
                  createdDateTimeUTC: new Date().toISOString(),
                  modifiedDateTimeUTC: new Date().toISOString(),
                  createdByUser: "defaultUser",
                  modifiedByUser: "defaultUser",
                  agrSupplyInputVolTrgtId: "defaultId",
                });
              }
            });
          }
        });

        console.log("TABLE Data", initialData, "   ", initialData.length);
        setTableData(initialData);
      }
    }, [data, childNodes, agriSuppliesHeader.length]);

    const calculateagriSuppliesTotals = (tableData) => {
      const totals = tableData.reduce((acc, group) => {
        const existingGroup = acc.find(
          (item) => item.agriSupplyDescription === group.agriSupplyDescription
        );

        if (existingGroup) {
          // If the group with the same investmentDescription exists, update totals
          existingGroup.totalTarget =
            Math.round(
              (parseFloat(existingGroup.totalTarget) +
                parseFloat(group.target)) *
                100
            ) / 100;
          existingGroup.totalDistributed =
            Math.round(
              (existingGroup.totalDistributed + group.distributed) * 100
            ) / 100;
          existingGroup.totalConsumed =
            Math.round((existingGroup.totalConsumed + group.consumed) * 100) /
            100;
          existingGroup.totalBalance =
            Math.round((existingGroup.totalBalance + group.balance) * 100) /
            100;
          // existingGroup.available = (existingGroup.totalTarget + group.target) - (existingGroup.totalDistributed + group.distributed);
          existingGroup.available =
            Math.round(
              (existingGroup.totalTarget - existingGroup.totalDistributed) * 100
            ) / 100;
        } else {
          // If it's a new investmentDescription, create a new entry
          acc.push({
            agriSupplyDescription: group.agriSupplyDescription,
            totalTarget: group.target,
            totalDistributed: group.distributed,
            totalConsumed: group.consumed,
            totalBalance: group.balance,
            available: group.target - group.distributed,
          });
        }

        return acc;
      }, []);

      return totals;
    };

    useEffect(() => {
      if (tableData) {
        const updatedTotals = calculateagriSuppliesTotals(tableData);
        setAgriSuppliesTotal(updatedTotals);
      }
    }, [tableData]);

    const [saveClick, setSaveClick] = useState(false);
    useImperativeHandle(ref, () => ({
      // save: () => {
      //   setSaveClick(true);
      //   handleSave();
      // },
      save: handleSave,
    }));

    const handleSave = async () => {
      // setLoading(true);
      try {
        // if (tableData) {
        //   // console.error('Missing production target ID');
        //   // setMessage("Missing production target ID");
        //   // setSeverity("error");
        //   // setOpenAlert(true);
        //   return;
        // }

        const currentDateTime = new Date().toISOString();
        const currentUser = "currentUser"; // Replace with actual user info

        // Calculate totals for the main payload
        // const totals = tableData.reduce((acc, row) => ({
        //   contractTarget: acc.contractTarget + (parseFloat(row.contractTarget) || 0),
        //   productivityTarget: acc.productivityTarget + (parseFloat(row.productivityTarget) || 0),
        //   hectaresTarget: acc.hectaresTarget + (parseFloat(row.hectaresTarget) || 0),
        // }), {
        //   contractTarget: 0,
        //   productivityTarget: 0,
        //   hectaresTarget: 0
        // });

        // Construct the final payload
        const payload = tableData.map((item) => ({
          createdDateTimeUTC:
            item.agrSupplyInputVolTrgtId === "defaultId"
              ? currentDateTime
              : item.createdDateTimeUTC,
          createdByUser:
            item.agrSupplyInputVolTrgtId === "defaultId"
              ? currentUser
              : item.createdDateTimeUTC,
          modifiedDateTimeUTC: currentDateTime,
          modifiedByUser: currentUser,
          agrSupplyInputVolTrgtId:
            item.agrSupplyInputVolTrgtId === "defaultId"
              ? ""
              : item.agrSupplyInputVolTrgtId,
          ihId: item.ihId,
          cropYearId: cropYearUUID,
          agriculturalSuppliesId: item.agriculturalSuppliesId,
          // investmentDescription: item.investmentDescription || "",
          target: item.target,
          distributed: item.distributed,
          consumed: item.consumed,
          balance: item.balance,
        }));

        // Debug log
        console.log("Sending payload to API:", payload);

        // Make the API call
        await AgriculturalSuppliesTargetsService.postAgriculturalSuppliesTargets(
          payload
        );
        setShowExitWarningOverlayFeed(false);
        setMessage("Agricultural Supplies Targets saved successfully");
        setSeverity("success");
        setOpenAlert(true);
        // if (!saveClick) {
        //   window.history.back();
        // }
        refreshData(); //for the issue of extra zeros coming in the table
        return true;
      } catch (error) {
        console.error("Error saving data:", error);
        setMessage(error.message || "Error saving production targets");
        setSeverity("error");
        setOpenAlert(true);
        return false;
      } finally {
        // setLoading(false);
      }
    };

    return (
      <>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {/* First Grid Item */}
          <Grid item xs={4}>
            <Box sx={{ backgroundColor: "#EFEFEF", borderRadius: "8px" }}>
              <Box sx={{ display: "flex" }}>
                <ArrowDropDownIcon sx={{ marginBottom: 1.1 }} />
                <Typography sx={{ fontWeight: 600, color: "#666666" }}>
                  {t("agriculturalSuppliesTargets.showColumns")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  ml: 1,
                  overflowX: "auto", // Enables horizontal scrolling
                  whiteSpace: "nowrap", // Prevents wrapping of child elements
                }}
              >
                {columns.map((column) => {
                  const isDisabled =
                    Object.values(showColumns).filter((value) => value).length <
                      2 && showColumns[column.toLowerCase()];

                  return (
                    <Box key={column}>
                      <FormControlLabel
                        control={
                          <Tooltip
                            title={
                              isDisabled
                                ? t("agriculturalSuppliesTargets.columnTooltip")
                                : ""
                            }
                            arrow
                            disableInteractive
                          >
                            <span>
                              <Checkbox
                                disabled={isDisabled}
                                checked={showColumns[column.toLowerCase()]}
                                onChange={(e) =>
                                  handleColumnChange(
                                    column.toLowerCase(),
                                    e.target.checked
                                  )
                                }
                              />
                            </span>
                          </Tooltip>
                        }
                        label={column}
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "16px",
                            fontWeight: 400,
                            // whiteSpace: "normal", // Allows wrapping of text
                            // wordBreak: "break-word", // Ensures long words break correctly
                            // overflow: "hidden",
                          },
                        }}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Grid>

          {/* Second Grid Item */}
          <Grid item xs={8}>
            <Box sx={{ backgroundColor: "#EFEFEF", borderRadius: "8px" }}>
              <Box sx={{ display: "flex" }}>
                <ArrowDropDownIcon sx={{ marginBottom: 1.1 }} />
                <Typography sx={{ fontWeight: 600, color: "#666666" }}>
                  {t("agriculturalSuppliesTargets.showHeaders")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                {/* Header checkboxes */}
                <Box
                  sx={{
                    flex: 1,
                    overflowX: "auto",
                    display: "flex",
                    gap: 1,
                    ml: 1,
                  }}
                >
                  {agriSuppliesHeader &&
                    agriSuppliesHeader.length > 0 &&
                    agriSuppliesHeader.map((header) => {
                      // Calculate the count of selected headers
                      const selectedHeadersCount = agriSuppliesHeader.filter(
                        (h) => h.isHeader
                      ).length;

                      // Determine if the checkbox should be disabled
                      const isDisabled =
                        selectedHeadersCount < 2 && header.isHeader;

                      return (
                        <Box
                          item
                          key={header.asrSupplyName}
                          sx={{
                            flexShrink: 0, // Prevents items from shrinking
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Tooltip
                                title={
                                  isDisabled
                                    ? t(
                                        "agriculturalSuppliesTargets.headerTooltip"
                                      )
                                    : ""
                                }
                                arrow
                              >
                                <span>
                                  {" "}
                                  {/* Wrapper for Tooltip to work on disabled elements */}
                                  <Checkbox
                                    checked={header.isHeader}
                                    disabled={isDisabled}
                                    onChange={() =>
                                      handleagriSuppliesHeaderChange(header)
                                    }
                                  />
                                </span>
                              </Tooltip>
                            }
                            label={header.asrSupplyName}
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "16px",
                                fontWeight: 400,
                              },
                            }}
                          />
                        </Box>
                      );
                    })}
                </Box>
                {/* Manage Items button */}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 10, mt: 1 }}>
          <Grid item xs={4}>
            <StyledTableContainer component={Paper}>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <SubHeaderCell
                      colSpan={4}
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        padding: "25px",
                      }}
                    ></SubHeaderCell>
                  </TableRow>
                  <TableRow>
                    <SubHeaderCell
                      colSpan={4}
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        padding: "26px",
                      }}
                    ></SubHeaderCell>
                  </TableRow>
                  <TableRow>
                    <SecondaryHeaderCell colSpan={4}>
                      <Typography sx={{ fontWeight: 600, color: "#666666" }}>
                        {t("investmentGroupLimits.hnSearchField")}
                      </Typography>
                    </SecondaryHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {childNodes.map((row, index) => (
                    <TableRow key={index}>
                      <DataCell colSpan={4}>{row.label}</DataCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TotalCell colSpan={4}>TOTALS</TotalCell>
                  </TableRow>
                </TableBody>
              </StyledTable>
            </StyledTableContainer>
          </Grid>
          <Grid item xs={8}>
            <StyledTableContainer component={Paper}>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    {agriSuppliesHeader
                      .filter((header) => header.isHeader === true)
                      .map((row, index) => (
                        <HeaderCell
                          colSpan={Math.max(
                            2,
                            1 +
                              Object.values(showColumns).filter(Boolean).length
                          )}
                          sx={{ borderRight: "5px solid white" }}
                        >
                          {row.asrSupplyName}
                        </HeaderCell>
                      ))}
                  </TableRow>
                  <TableRow>
                    {agriSuppliesHeader
                      .filter((header) => header.isHeader === true)
                      .map((header) =>
                        agriSuppliesTotal
                          .filter(
                            (item) =>
                              item.agriSupplyDescription ===
                              header.asrSupplyName
                          )
                          .map((investment) => (
                            <SubHeaderCell2
                              colSpan={Math.max(
                                2,
                                1 +
                                  Object.values(showColumns).filter(Boolean)
                                    .length
                              )}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "left",
                                  marginLeft: 0,
                                  width: "97%", // Full width for inner content
                                  border: "1px solid #E9EBEE",
                                  borderRadius: "8px", // Rounded top-left edge
                                  padding: "13px 9px 13px 5px",
                                }}
                              >
                                {t(
                                  "agriculturalSuppliesTargets.available/target"
                                )}
                                <span>
                                  {/* {"0 / "} {totals.investments.reduce((sum, inv) => sum + inv.target, 0).toLocaleString()} */}
                                  <>
                                    {investment.available} /{" "}
                                    {investment.totalTarget}
                                  </>
                                </span>
                              </Box>
                            </SubHeaderCell2>
                          ))
                      )}
                  </TableRow>
                  <TableRow>
                    {agriSuppliesHeader
                      .filter((header) => header.isHeader === true)
                      .map((row, index) =>
                        ["Target", "Distributed", "Consumed", "Balance"]
                          .filter((header) => {
                            const key = header.toLowerCase(); // Convert to lowercase to match state keys
                            return key === "target" || showColumns[key]; // Always show 'Target', filter others based on state
                          })
                          .map((header) => (
                            <SecondaryHeaderCell
                              key={header}
                              style={{
                                borderLeft:
                                  header === "Target"
                                    ? "none"
                                    : "5px solid white",
                                borderRight: "5px solid white",
                              }}
                            >
                              {header}
                            </SecondaryHeaderCell>
                          ))
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {childNodes.map((child) => (
                    <TableRow key={child.value}>
                      {agriSuppliesHeader
                        .filter((header) => header.isHeader === true)
                        .map((header) => {
                          const rows = tableData.filter(
                            (data) =>
                              data.ihId === child.value &&
                              data.agriSupplyDescription ===
                                header.asrSupplyName
                          );

                          return rows.map((row, rowIndex) => {
                            // console.log("ROWDATA", row); // Moved outside JSX for debugging

                            return [
                              "target",
                              "distributed",
                              "consumed",
                              "balance",
                            ].map((key, i) => {
                              const value = row[key]; // Access the corresponding value

                              if (key === "target") {
                                // Render "target" as a TextField
                                return (
                                  // <DataCell >
                                  <StyledTextField
                                    type="number"
                                    key={`${rowIndex}-${i}`}
                                    fullWidth
                                    variant="outlined"
                                    value={
                                      value === 0
                                        ? "0" // Show zero explicitly
                                        : /^\d+(\.\d+)?$/.test(value)
                                        ? value
                                            .toString()
                                            .replace(/^0+(?=\d)/, "") // Remove leading zeros for whole numbers
                                        : value
                                    }
                                    sx={{
                                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                        {
                                          display: "none",
                                        },
                                    }}
                                    onKeyDown={(e) => {
                                      if (
                                        e.key === "e" ||
                                        e.key === "E" ||
                                        e.key === "-" ||
                                        e.key === "+"
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      if (
                                        /^\d{0,8}(\.\d{0,2})?$/.test(inputValue)
                                      ) {
                                        handleTargetChange(
                                          parseFloat(inputValue) || 0,
                                          child.value,
                                          header.asrSupplyName
                                        );
                                      }
                                    }}
                                  />
                                  // </DataCell>
                                );
                              } else {
                                // Render other values as plain text
                                if (!showColumns[key]) return null;
                                return (
                                  <DataCell key={`${rowIndex}-${i}`}>
                                    {value?.toLocaleString()}
                                  </DataCell>
                                );
                              }
                            });
                          });
                        })}
                    </TableRow>
                  ))}
                  <TableRow>
                    {agriSuppliesHeader
                      .filter((header) => header.isHeader === true)
                      .map((item) =>
                        agriSuppliesTotal
                          .filter(
                            (total) =>
                              total.agriSupplyDescription === item.asrSupplyName
                          )
                          .map((itemTotal) => (
                            <>
                              <TotalCell>{itemTotal.totalTarget}</TotalCell>
                              {showColumns.distributed && (
                                <TotalCell>
                                  {itemTotal.totalDistributed}
                                </TotalCell>
                              )}
                              {showColumns.consumed && (
                                <TotalCell>{itemTotal.totalConsumed}</TotalCell>
                              )}
                              {showColumns.balance && (
                                <TotalCell>{itemTotal.totalBalance}</TotalCell>
                              )}
                            </>
                          ))
                      )}
                    {/* {agriSuppliesHeader.map((header) => (
                    tableData.filter((data) => data.agriSupplyDescription === header.asrSupplyName).map((item) => (
                      
                    ))
                  ))

                  } */}
                    {/* {totals.investments.map((investment, i) =>
                    Object.entries(investment)
                      .filter(([key]) => key === "target" || showColumns[key.toLowerCase()])
                      .map(([key, value], j) => (
                        <TotalCell key={`${i}-${j}`}>
                          {key === "target" ? parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ""}
                        </TotalCell>
                      ))
                  )} */}
                  </TableRow>
                </TableBody>
              </StyledTable>
            </StyledTableContainer>
          </Grid>
        </Grid>
        <Divider
          sx={{
            width: "100%", // Ensures the divider spans the full width
            marginBottom: 1, // Adds spacing between the divider and the button
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            sx={{
              padding: "6px 16px",
              borderRadius: "4px",
              // right: "1rem", // Aligns to the right bottom corner
              // bottom: "1rem", // Position it at the bottom
              marginBottom: 4,
              backgroundColor: "#EF7D00",
              color: "white",
              "&:hover": {
                backgroundColor: "#EF7D00",
              },
            }}
            startIcon={<img src={Save} alt="Right Arrow" />}
            onClick={handleSave}
            variant="contained"
          >
            {t("save")}
          </Button>
        </Box>
      </>
    );
  }
);

export default AgriculturalSuppliesTable;
