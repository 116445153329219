import React, { useEffect, useState, useContext } from "react";
import { Typography, Box, Button, Modal } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "./PAFB-Table.css";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import ButtonView from "assets/ButtonView.svg"
import ButtonDuplicate from "assets/ButtonDuplicate.svg"
import { useNavigate } from "react-router-dom";
import { SnackbarContext } from "context/snackBarContext";
import { useTranslation } from 'react-i18next';
import PAFBService from "services/PAFBService";


const PreAssessmentFormBuilderTable = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const [loading, setLoading] = useState(props.loading || false);
  const [open, setOpen] = useState(false); // Modal state
  const [deleteId, setDeleteId] = useState(null); // ID of the record to delete

  useEffect(() => {
    setFormData(props.formData);
    setOriginalData(props.formData);
    setLoading(props.loading);
  }, [props.formData, props.loading]);

  const handleDeleteClick = (formId) => {
    setDeleteId(formId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...formData].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setFormData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <ArrowUpwardIcon fontSize="small" />;
      } else if (sortConfig.direction === "desc") {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
    return <ArrowDownwardIcon fontSize="small" />;
  };

  // Helper function to format date
  const formatDate = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toLocaleDateString();
  };

  const handleDeleteConfirm = async () => {
    try {
      // Call the delete API method
      const response = await PAFBService.deleteFormApi(deleteId);
      
      if (response.data.statusCode === 200) {
        // Show success message
        setMessage(t('deletedSuccessfully'));
        setSeverity("success");
        setOpenAlert(true);
        
        // Remove the deleted item from the local state
        setFormData(formData.filter(item => item.fcpFbId !== deleteId));
        
        // Close the modal
        setOpen(false);
      } else {
        // Handle potential API error
        setMessage(t('deleteErrorMessage'));
        setSeverity("error");
        setOpenAlert(true);
      }
    } catch (error) {
      // Handle network or other errors
      setMessage(t('deleteErrorMessage'));
      setSeverity("error");
      setOpenAlert(true);
    }
  };

  return (
    <div className="pafb-container">
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <table className="pafb-table">
          <thead>
            <tr>
              <th>
                <div onClick={() => handleSort("fcpFbFormVersion")}>
                  {t('PreAssessmentFormBuilder.formVersion')} {getSortIcon("fcpFbFormVersion")}
                </div>
              </th>
              <th>
                <div onClick={() => handleSort("fcpFbStartDate")}>
                  {t('PreAssessmentFormBuilder.startDate')} {getSortIcon("fcpFbStartDate")}
                </div>
              </th>
              <th>
                <div onClick={() => handleSort("fcpFbEndDate")}>
                  {t('PreAssessmentFormBuilder.endDate')} {getSortIcon("fcpFbEndDate")}
                </div>
              </th>
              <th className="actions-header">
                <div>
                  {t('actions')}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {formData?.map((row, index) => (
              <tr 
                key={row.fcpFbId} 
                // onClick={() => 
                //   navigate("/preAssessmentFormBuilderDetails", {
                //     state: { row: row, edit: false },
                //   })
                // }
                style={{ cursor: 'pointer' }}
              >
                <td>{row.fcpFbFormVersion}</td>
                <td>{formatDate(row.fcpFbStartDate)}</td>
                <td>{formatDate(row.fcpFbEndDate)}</td>
                <td 
                  className="actions-cell" 
                  onClick={(e) => e.stopPropagation()}
                >
                  <img
                    className="btn duplicate-btn"
                    src={ButtonDuplicate}
                    alt={t('PreAssessmentFormBuilder.table.duplicate')}
                    onClick={() =>
                      navigate("/preAssessmentFormBuilderDetails", {
                        state: { row: row, duplicate: true },
                      })
                    }
                  />
                  {row.isEditableRecord && (
                    <img
                      className="btn edit-btn"
                      src={_ButtonEdit_}
                      alt={t('PreAssessmentFormBuilder.table.edit')}
                      onClick={() =>
                        navigate("/preAssessmentFormBuilderDetails", {
                          state: { row: row, edit: true },
                        })
                      }
                    />
                  )}
                  <img
                    className="btn view-btn"
                    src={ButtonView}
                    alt={t('PreAssessmentFormBuilder.table.view')}
                    onClick={() =>
                      navigate("/preAssessmentFormBuilderDetails", {
                        state: { row: row, view: true },
                      })
                    }
                  />
                  {row.isDeletableRecord && (
                    <img
                      className="btn delete-btn"
                      src={_ButtonDelete_}
                      alt={t('PreAssessmentFormBuilder.table.delete')}
                      onClick={() => handleDeleteClick(row.fcpFbId)}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Modal for delete confirmation */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "520px",
            margin: "auto",
            mt: 25,
            height: "200px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              {t('deleteConfirmation')}
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, fontWeight: 400 }}
            >
              {t('deleteConfirmationMessage')}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              {t('no')}
            </Button>
            <Button
              onClick={handleDeleteConfirm}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              {t('yes')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default PreAssessmentFormBuilderTable;
