import { httpGrow } from "../http-common";

const getFarmerSampleEstimate = async (query) => {
  const response = await httpGrow.get(
    `/soil-analysis/list?pageNumber=${query?.pageNumber}&size=${query?.size}`
  );
  return response;
};

const searchFarmerSampleEstimate = async (query) => {
  let url = `/soil-analysis/find?pageNumber=${query?.pageNumber}&size=${
    query?.size || 50
  }`;

  if (query?.farmerInput) {
    url += `&farmerInput=${query.farmerInput}`;
  }
  if (query?.soilAnalysisDescription) {
    url += `&soilAnalysisDescription=${query.soilAnalysisDescription}`;
  }
  if (query?.saStatus !== undefined) {
    url += `&saStatus=${query.saStatus}`;
  }

  const response = await httpGrow.get(url);
  return response;
};

const deleteFarmerSampleEstimate = (soilAnalysisId) => {
    return httpGrow.delete(`/soil-analysis/deleteSoilAnalysis/${soilAnalysisId}`);
  };

const getCropSeasonAPI = async () => {
  const response = await httpGrow.get("/cropYears");
  return response;
};

const getHierarchyNodeAPI = async () => {
  const response = await httpGrow.get("/importHierarchyVersion/tree");
  return response;
};

const importFarmerSample = (
  file,
  countryId,
  languageId,
  createdByUser,
  modifiedByUser
) => {
  const formData = new FormData();
  formData.append("file", file);

  return httpGrow.post("/soil-analysis/import", formData, {
    params: {
      countryId,
      languageId,
      createdByUser,
      modifiedByUser,
    },
    headers: {
      "Content-Type": "multipart/form-data",
      accept: "application/json",
    },
  });
};

const downloadTemplate = () => {
  return httpGrow.get("/soil-analysis/template", {
    responseType: "blob",
  });
};

const DefineFarmerSampleEstimateService = {
  getFarmerSampleEstimate,
  deleteFarmerSampleEstimate,
  searchFarmerSampleEstimate,
  importFarmerSample,
  downloadTemplate,
  getCropSeasonAPI,
  getHierarchyNodeAPI,
};

export default DefineFarmerSampleEstimateService;
