import { httpGrow } from "../http-common";

const getPriceData = async (query) => {
  const response = await httpGrow.get(`/price-list?agriculturalSupplyId=${query.id}`)
  return response;
}
const createPrice = (data) => {
  return httpGrow.post(
    `/price-list`, data
  );
};
const updatePlanting = (data) => {
  return httpGrow.put(`plantingType`, data);
};

//  const searchPlanting = async (query) => {
//   const response = await httpGrow.get(`/plantingType/search?pageNumber=${query?.pageNumber}&size=${query?.size}&keyword=${query?.keyword}&status=${query?.status}`);
//   return response;
// };

const searchPlanting = async (query) => {
  const response = await httpGrow.get(
    `/plantingType/search?pageNumber=${query?.pageNumber}&pageSize=${query?.size || 50}&keyword=${query?.keyword || ''}&status=${query?.status !== undefined ? query.status : ''}`
  );
  return response;
};


const deletePlanting = (plantingTypeId) => {
  return httpGrow.delete(`/plantingType/${plantingTypeId}`);
};

const PriceService = {
  getPriceData,
  createPrice,
  updatePlanting,
  searchPlanting,
  deletePlanting
};



export default PriceService;