import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RuleService from "services/RuleService";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "react-i18next";
import iconSearch from "assets/iconSearchs.svg";
import RulesTable from "./RulesTable";
import debounce from "lodash.debounce";

const GetRules = () => {
  const navigate = useNavigate();
  const [ruleData, setRuleData] = useState([]);
  const [filteredRuleData, setFilteredRuleData] = useState([]);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [searchTerm, setSearchTerm] = useState(""); // Current input value
  const [results, setResults] = useState([]); // Search results
  const [loading, setLoading] = useState(false); // Loading state

  const { t, i18n } = useTranslation();

  const callRuleAPI = (pageNumber, size) => {
    setLoading(true);
    RuleService.getRule({ pageNumber, size })
      .then((res) => {
        const ruleResults =
          res?.data?.response?.segmentationKpisRoutinesRules || [];
        // console.log("Resultscall",ruleResults);
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setRuleData(ruleResults);
        setFilteredRuleData(ruleResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setRuleData([]);
        setFilteredRuleData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getRefreshData = () => {
    callRuleAPI(currentPage, itemsPerPage);
  };

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (query) {
        try {
          const res = await RuleService.searchRule(query);
          const results = res?.response?.segmentationKpisRoutinesRules || [];
          setFilteredRuleData(results);
          console.log("Results", results);
        } catch (error) {
          console.error("Error fetching search results:", error);
          setMessage(error?.response?.data?.message || error?.message);
          setOpenAlert(true);
          setSeverity("error");
        }
      } else {
        setFilteredRuleData(ruleData); // Reset to original data if search term is empty
      }
    }, 500), // 500ms debounce delay
    [ruleData]
  );

  // Handle input change
  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedSearch(value); // Trigger debounced search
  };

  // useEffect(() => {
  //   callRuleAPI(currentPage, itemsPerPage);
  //   if (currentPage <= 0) {
  //     //getfullGeneticAPI();
  //   }
  // }, [currentPage, itemsPerPage]);
  useEffect(() => {
    callRuleAPI(currentPage, itemsPerPage);
  }, [currentPage, itemsPerPage]);

  // const paginate = (pageNumber, itemsPerPage) => {
  //   setCurrentPage(pageNumber - 1);
  //   if (searchKeyword) {
  //     // callSearchGeneticAPI(pageNumber - 1, itemsPerPage, searchKeyword);
  //   } else {
  //     callRuleAPI(pageNumber - 1, itemsPerPage);
  //   }
  //};
  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    if (searchTerm) {
      debouncedSearch(searchTerm); // Maintain filtered data during pagination
    } else {
      callRuleAPI(pageNumber - 1, itemsPerPage);
    }
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t("Segmentation KPI's - Routines and Rules Registration (List)")}
      background="#ffffff"
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: 1,
          padding: 1.5,
          height: "122px",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1 }} />
          <Typography>{t("segmentationKPI.sectionName")}</Typography>
        </Box>

        <Grid container spacing={1.5} sx={{ alignItems: "center" }}>
          <Grid item container spacing={1.5} xs={12} md={10.2}>
            <Grid item xs={12} sm={6} md={7}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  value={searchTerm}
                  onChange={handleInputChange}
                  label={t("segmentationKPI.code/name/des")}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={iconSearch}
                          alt="search"
                          style={{
                            width: "24px",
                            height: "24px",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                {/* Display search results */}
                <div>
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    results.map((item, index) => <p key={index}>{item}</p>)
                  )}
                </div>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={1.8}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: { xs: 2, md: 0 },
            }}
          >
            <Button
              variant="contained"
              startIcon={<AddCircleIcon />}
              sx={{
                minWidth: 95,
                borderRadius: 1,
                background: "#004F9F",
                height: { xs: "40px", md: "44px" },
              }}
              onClick={() => navigate("/addsegmentationRulesRegistration")}
            >
              {t("addNew")}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <RulesTable
        ruleData={filteredRuleData}
        setRuleData={setFilteredRuleData}
        getfullRuleAPI={getRefreshData}
      />
    </Layout>
  );
};

export default GetRules;
