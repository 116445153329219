import React, { useState, useEffect, useContext } from "react";
import "./PriceParameterization.css";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Box, Button, Modal, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SnackbarContext } from "context/snackBarContext";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import _ButtonView_ from "assets/_ButtonView_.svg";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 580,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const PriceParameterizationTable = ({priceParameterizationData,setTotalItems,setPriceParameterizationData}) => {
  const [originalData, setOriginalData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();


  console.log("priceParameterizationData=--",priceParameterizationData)

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...priceParameterizationData].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setPriceParameterizationData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <ArrowUpwardIcon fontSize="small" />;
      } else if (sortConfig.direction === "desc") {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
    // Default icon (ArrowDownward) when no sorting is applied
    return <ArrowDownwardIcon fontSize="small" />;
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setPriceParameterizationData(priceParameterizationData);
    setOriginalData(priceParameterizationData);
  }, [priceParameterizationData]);

  return (
    <div className="table-container">
      <table className="custom-table">
        <thead>
          <tr>
            <th
              onClick={() => handleSort("description")}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {t("description")}
              {getSortIcon("description")}
            </th>
            <th
              onClick={() => handleSort("inInvestmentName")}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {t("priceParameterization.investmentType")}{" "}
              {getSortIcon("inInvestmentName")}
            </th>
            <th
              onClick={() => handleSort("edificationType")}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {t("priceParameterization.edificationType")}{" "}
              {getSortIcon("edificationType")}
            </th>

            <th
              onClick={() => handleSort("igInvestmentGroupDescription")}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {t("priceParameterization.investmentGroups")} {getSortIcon("igInvestmentGroupDescription")}
            </th>
            <th className="actions-header">{t("Actions")}</th>
          </tr>
        </thead>

        <tbody>
          {priceParameterizationData.map((row, index) => (
            <tr key={index}>
              <td
                style={{ cursor: "pointer" }}
                // onClick={() =>
                //   navigate("/viewgeneticTypeRegistration", {
                //     state: { row: row, edit: false },
                //   })
                // }
              >
                {row.description}
              </td>
              <td
                style={{ cursor: "pointer" }}
                // onClick={() =>
                //   navigate("/viewgeneticTypeRegistration", {
                //     state: { row: row, edit: false },
                //   })
                // }
              >
                {row.inInvestmentName}
              </td>

              <td
                style={{ cursor: "pointer" }}
                // onClick={() =>
                //   navigate("/viewgeneticTypeRegistration", {
                //     state: { row: row, edit: false },
                //   })
                // }
              >
                {row.edificationType}
              </td>

              <td
                style={{ cursor: "pointer" }}
                // onClick={() =>
                //   navigate("/viewgeneticTypeRegistration", {
                //     state: { row: row, edit: false },
                //   })
                // }
              >
                {row.igInvestmentGroupDescription}
              </td>

              <td className="actions-cell">
                <Tooltip title="Edit" arrow placement="top">
                  <img
                    className="btn edit-btn"
                    onClick={() =>
                      navigate("/editPriceParameterizationList", {
                        state: { row: row, edit: true },
                      })
                    }
                    src={_ButtonEdit_}
                    alt="Edit"
                  />
                </Tooltip>
                <Tooltip title="View" arrow placement="top">
                <img
                  className="btn delete-btn"
                  onClick={() =>
                    navigate("/viewPriceParameterizationList", {
                      state: { row: row, edit: false },
                    })
                  }
                  src={_ButtonView_}
                  alt="View"
                />
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              {t("deleteConfirmation")}
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography sx={{ mt: 2, fontWeight: 400 }}>
              {t("deleteConfirmationMessage")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": {
                  background: "#737D86",
                },
              }}
            >
              {t("no")}
            </Button>
            <Button
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": {
                  background: "#004F9F",
                },
              }}
             // onClick={handleDelete}
            >
              {t("yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default PriceParameterizationTable;
