import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  ListItemText,
} from "@mui/material";
import { SnackbarContext } from "context/snackBarContext";
import RedSwitch from "utils/RedSwitch";
import brasilFlag from "assets/BrasilFlag.svg";
import mexicoFlag from "assets/MexicoFlag.svg";
import bangladeshFlag from "assets/BangladeshFlag.svg";
import pakistanFlag from "assets/PakistanFlag.svg";
import VarietyRegistrationService from "services/VarietyRegistrationService";


const CustomMultiSelect = ({ 
  label, 
  options = [], 
  value = [], 
  onChange, 
  disabled = false,
  isSpacingSelect = false 
}) => {
  const handleChange = (event) => {
    const selectedValues = event.target.value;

    if (Array.isArray(selectedValues)) {
      const lastSelected = selectedValues[selectedValues.length - 1];
      const isAlreadySelected = value.some(v => v.value === (lastSelected?.value || lastSelected));

      let newValue;
      if (isAlreadySelected) {
        newValue = value.filter(v => v.value !== (lastSelected?.value || lastSelected));
      } else {
        const optionToAdd = options.find(opt => opt.value === (lastSelected?.value || lastSelected));
        if (optionToAdd) {
          newValue = [...value, optionToAdd];
        } else {
          newValue = [...value, lastSelected];
        }
      }

      // Format spacing options if needed
      if (isSpacingSelect) {
        const formattedOptions = newValue.map(option => ({
          value: option.value,
          label: option.label,
          lineSpacing: option.lineSpacing,
          plantSpacing: option.plantSpacing,
          spacingRegistrationUid: option.spacingRegistrationUid
        }));
        onChange(formattedOptions);
      } else {
        onChange(newValue);
      }
    }
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel 
        id={`${label}-label`}
        shrink
        sx={{
          '&.Mui-focused': {
            color: 'gray',
          },
          padding: '0 8px',
          position: 'relative',
          // transform: 'translate(14px, -9px) scale(0.75)',
          // backgroundColor: 'white',
          zIndex: 1,
          alignSelf: 'flex-start',
          width: 'auto',
          maxWidth: 'fit-content',
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId={`${label}-label`}
        id={`${label}-select`}
        multiple
        value={value}
        onChange={handleChange}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((val) => (
              <Chip
                key={val.value}
                label={val.label}
                size="small"
                onDelete={(e) => {
                  e.stopPropagation();
                  const newValue = value.filter(v => v.value !== val.value);
                  onChange(newValue);
                }}
                onMouseDown={(e) => e.stopPropagation()}
              />
            ))}
          </Box>
        )}
        disabled={disabled}
        sx={{
          marginTop: '-12px',
        }}
      >
        {options.map((option) => (
          <MenuItem 
            key={option.value}
            value={option}
            sx={{
              borderLeft: value?.some(v => v.value === option.value) ? '4px solid #004F9F' : 'none',
              '&.Mui-selected': {
                borderLeft: '4px solid #004F9F',
              },
              '&.Mui-selected:hover': {
                borderLeft: '4px solid #004F9F',
              }
            }}
          >
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default function CountryFilter({ 
  cultureId, 
  onCountryChange, 
  onSpacingChange, 
  onCultureTypeChange,
  cultureTypeOptions,
  initialCountries,
  initialSpacings,
  initialCultureTypes,
  isReadOnly = false
}) {
  const [values, setValues] = useState(initialCountries || {
    statusBrasil: false,
    statusMexico: false,
    statusBangladesh: false,
    statusPakistan: false,
  });

  const [spacingSelections, setSpacingSelections] = useState(initialSpacings || {});
  const [cultureTypeSelections, setCultureTypeSelections] = useState(initialCultureTypes || {});
  const [spacingOptions, setSpacingOptions] = useState([]);
  const [isLoadingSpacing, setIsLoadingSpacing] = useState(false);
  const [isLoadingCultureTypes, setIsLoadingCultureTypes] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  // Update when initial values change
  useEffect(() => {
    console.log('Initial values received:', { // Debug log
      countries: initialCountries,
      spacings: initialSpacings,
      cultureTypes: initialCultureTypes
    });

    if (initialCountries) {
      setValues(initialCountries);
    }
    if (initialSpacings) {
      setSpacingSelections(initialSpacings);
    }
    if (initialCultureTypes) {
      setCultureTypeSelections(initialCultureTypes);
    }
  }, [initialCountries, initialSpacings, initialCultureTypes]);

  // Fetch spacing options
  useEffect(() => {
    const fetchSpacingOptions = async () => {
      if (!cultureId) return;
      
      setIsLoadingSpacing(true);
      try {
        const response = await VarietyRegistrationService.getSpacingByCulture(cultureId);
        
        const formattedOptions = response.data.response.map(spacing => ({
          value: spacing.spacingRegistrationUid,
          label: `${spacing.lineSpacing} x ${spacing.plantSpacing}`,
          lineSpacing: spacing.lineSpacing,
          plantSpacing: spacing.plantSpacing,
          spacingRegistrationUid: spacing.spacingRegistrationUid
        }));
        
        setSpacingOptions(formattedOptions);
      } catch (error) {
        console.error('Failed to fetch spacing options:', error);
        
        // Use SnackbarContext to show an error alert
        setMessage("Failed to load spacing options");
        setOpenAlert(true);
        setSeverity("error");
        
        // Set spacing options to an empty array to prevent further issues
        setSpacingOptions([]);
      } finally {
        setIsLoadingSpacing(false);
      }
    };

    fetchSpacingOptions();
  }, [cultureId]);

  // Handle spacing type changes
  const handleSpacingTypeChange = (countryName, selectedOptions) => {
    const countryKey = `status${countryName.split(' ')[0]}`;

    setSpacingSelections(prev => ({
      ...prev,
      [countryKey]: selectedOptions
    }));

    // Pass the formatted selections to parent
    if (onSpacingChange) {
      onSpacingChange(countryKey, selectedOptions);
    }
  };

  // Update the culture type change handler
  const handleCultureTypeChange = (countryName, selectedOptions) => {
    const countryKey = `status${countryName.split(' ')[0]}`;
    
    setCultureTypeSelections(prev => ({
      ...prev,
      [countryKey]: selectedOptions
    }));

    // Pass the formatted selections to parent
    if (onCultureTypeChange) {
      onCultureTypeChange(countryKey, selectedOptions);
    }
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setValues((prev) => ({
      ...prev,
      [name]: checked,
    }));

    // Call the onCountryChange prop to update the parent state
    if (onCountryChange) {
      onCountryChange(name, checked);
    }
  };

  // Array of country configurations
  const countries = [
    {
      flag: brasilFlag,
      name: "Brasil (pt_BR)",
      statusKey: "statusBrasil",
    },
    {
      flag: mexicoFlag,
      name: "Mexico (es_MX)",
      statusKey: "statusMexico",
    },
    {
      flag: bangladeshFlag,
      name: "Bangladesh (bd_BD)",
      statusKey: "statusBangladesh",
    },
    {
      flag: pakistanFlag,
      name: "Pakistan (en_PK)",
      statusKey: "statusPakistan",
    },
  ];

  // Debug log to verify options are received
  useEffect(() => {
    console.log('Culture type options in CountryFilter:', cultureTypeOptions);
  }, [cultureTypeOptions]);

  const handleCountryToggle = (countryCode, isChecked) => {
    console.log('Country toggle:', countryCode, isChecked); // Debug log
    if (onCountryChange) {
      onCountryChange(countryCode, isChecked);
    }
  };

  // Ensure options are available
  const availableCultureTypeOptions = cultureTypeOptions || [];
  const availableSpacingOptions = spacingOptions || [];

  return (
    <>
      {countries.map((country, index) => (
        <React.Fragment key={country.name}>
          <Grid
            container
            spacing={2}
            sx={{
              background: "#EFEFEF",
              borderRadius: "10px",
              p: 2,
              maxWidth: "100%",
              margin: "auto",
              paddingBottom: "34px",
            }}
          >
            <Grid container spacing={2}>

              {/* This grid for space */}
              <Grid
                item
                xs={12}
                style={{ paddingTop: "0px", paddingLeft: "0px" }}
              >
                <Typography display="flex" sx={{ opacity: "0" }}>
                  {/* <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} /> */}
                  Space
                </Typography>
              </Grid>


              {/* Country Flag and Name */}
              <Grid
                item
                xs={2.3}
                sx={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <img
                  src={country.flag}
                  alt={`${country.name} flag`}
                  style={{
                    width: "30px",
                    height: "20px",
                  }}
                />
                <Typography
                  sx={{
                    color: "#5A646E",
                    fontFamily: "Montserrat",
                    fontSize: "2px",
                    fontWeight: 600,
                    lineHeight: "20px",
                  }}
                >
                  {country.name}
                </Typography>
              </Grid>

              {/* Culture Type */}
              <Grid item xs={5}>
                <CustomMultiSelect
                  label="Culture Type"
                  options={availableCultureTypeOptions}
                  value={cultureTypeSelections[country.statusKey] || []}
                  onChange={(selected) => !isReadOnly && handleCultureTypeChange(country.name, selected)}
                  disabled={!cultureId || isReadOnly}
                />
              </Grid>

              {/* Spacing Type */}
              <Grid item xs={2.5}>
                <CustomMultiSelect
                  label="Spacing Type"
                  options={availableSpacingOptions}
                  value={spacingSelections[country.statusKey] || []}
                  onChange={(selected) => !isReadOnly && handleSpacingTypeChange(country.name, selected)}
                  disabled={isLoadingSpacing || !cultureId || isReadOnly}
                  isSpacingSelect={true}
                />
              </Grid>

              {/* Status Switch */}
              <Grid item xs={2}>
                <FormControl component="fieldset">
                  <FormLabel
                    sx={{
                      color: "#0E2B63",
                      "&.Mui-focused": { color: "#0E2B63" },
                      "&:hover": { color: "#0E2B63" },
                    }}
                  >
                    Status
                  </FormLabel>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ mr: 3, ml: 3 }}>Inactive</Typography>
                    <FormControlLabel
                      control={
                        <RedSwitch
                          checked={values[country.statusKey]}
                          onChange={!isReadOnly ? handleChange : undefined}
                          name={country.statusKey}
                          disabled={isReadOnly}
                        />
                      }
                      label={<Typography sx={{ ml: 2 }}>Active</Typography>}
                      labelPlacement="end"
                    />
                  </Box>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          {/* Add white space between country sections */}
          {index < countries.length - 1 && <Box sx={{ m: "2rem" }} />}
        </React.Fragment>
      ))}
    </>
  );
}
