import { Box, Button, Chip, FormControl, FormHelperText, FormLabel, Grid, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import Divider from 'components/common/GrowingDivider';
import Layout from 'components/structure/Layout'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import RedSwitch from 'utils/RedSwitch';
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import CloseIcon from "@mui/icons-material/Close";
import Save from "assets/SaveNew.svg";
import arrowLeft from "assets/arrowLeft.svg";
import { MenuProps } from 'utils/utils';
import SearchIcon from '../../../../assets/SearchIcon.svg';
import ApprovalLevelConfigurationService from 'services/ApprovalLevelConfigurationService';
import { SnackbarContext } from 'context/snackBarContext';
import { approvalLCSchema } from 'utils/validations';
import PickListService from 'services/PickListService';



const ApprovalLevelConfigurationDetails = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    mId,
    mName,
  } = location?.state?.row || {};

  const { edit } = location.state || {};

  const [values, setValues] = useState();
  console.log("values", values)
  const [approvalLevel, setApprovalLevel] = useState([])
  const [approverProfileMenu, setApproverProfileMenu] = useState([]);
  const [isNew, setIsNew] = useState(false);
  const [logicCondMenu, setLogicCondMenu] = useState([]);
  const [variableMenu, setVariableMenu] = useState([]);
  const [ccMenu, setCcMenu] = useState([]);
  console.log("Approval Levels", approvalLevel)
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const fetchALCDetail = async () => {
    try {
      const response = await ApprovalLevelConfigurationService.getALCDetails(mId);
      console.log("get Response 0", response.data.response)
      const formattedResponse = {
        createdDateTimeUTC: response.data.response.createdDateTimeUTC || "",
        createdByUser: response.data.response.createdByUser || "user",
        modifiedDateTimeUTC: response.data.response.modifiedDateTimeUTC || "",
        modifiedByUser: response.data.response.modifiedByUser || "user",
        moduleId: response.data.response.moduleId,
        moduleName: response.data.response.moduleName,
        whenApproved: response.data.response.alcWhenApproved === "true" ? true : false,
        whenRejected: response.data.response.alcWhenRejected === "true" ? true : false,
      };
      console.log("get Response 1", formattedResponse)
      setValues(formattedResponse)

      const formattedApprovalLevel = (response.data.response.approvalLevelConfigurationDto || []).map((item) => {
        const skipCriteria = (item.approvalLevelConfigurationSkipCriteriaList || []).map((criteria) => ({
          createdDateTimeUTC: criteria.createdDateTimeUTC,
          createdByUser: criteria.createdByUser,
          modifiedDateTimeUTC: criteria.modifiedDateTimeUTC,
          modifiedByUser: criteria.modifiedByUser,
          skipCriteriaId: criteria.alcscId,
          logicCondition: criteria.alcLogicCondition,
          variable: criteria.alcVariable,
          comparisionCriteria: criteria.alcComparisonCriteria,
          value: criteria.alcValue,
          isDelete: criteria.isDelete,
          skipAs: criteria.alcSkipAs,
        }));

        return {
          createdDateTimeUTC: item.createdDateTimeUTC,
          createdByUser: item.createdByUser,
          modifiedDateTimeUTC: item.modifiedDateTimeUTC,
          modifiedByUser: item.modifiedByUser,
          approvalId: item.alcId,
          levelOrder: item.alcLevelOrder || 1,
          levelName: item.alcLevelName || "",
          hierarchyRestriction: item.alcHierarchyRestriction || false,
          approverProfile: item.profileName === undefined ? [] : [item.profileName],
          approverProfileId: item.profileId || "",
          rejectedRequires: item.alcToBeRejectedRequires === "true" ? true : false,
          approvedRequires: item.alcToBeApprovedRequires === "true" ? true : false,
          skipCriteria: skipCriteria,
          skipAsTemp: skipCriteria.length > 0 ? skipCriteria[0].skipAs : false,
          isDelete: item.isDelete,
          filteredApproverProfiles: approverProfileMenu,
        };
      });

      console.log("get Response 2", formattedApprovalLevel)
      setApprovalLevel(formattedApprovalLevel)
    } catch (error) {
      console.error("Error fetching ALCdetails data:", error);
      if (error.status == 404) {
        const defaultResponse = {
          createdDateTimeUTC: "",
          createdByUser: "user",
          modifiedDateTimeUTC: "",
          modifiedByUser: "user",
          moduleId: mId,
          moduleName: mName,
          whenApproved: false,
          whenRejected: false,
        }
        setValues(defaultResponse)
        setIsNew(true)
      }
    }
  };

  const fetchApproverProfileMenu = async () => {
    try {
      const response = await ApprovalLevelConfigurationService.getAllProfile();
      const formatResponse = response.data.response.map((item) => ({
        profileId: item.profileId,
        profileName: item.profileName
      }))
      console.log("profileMenu", formatResponse)
      setApproverProfileMenu(formatResponse)
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  }

  //use effect 
  useEffect(() => {
    fetchApproverProfileMenu()
  }, [mId])

  useEffect(() => {
    if (approverProfileMenu.length > 0) {
      fetchALCDetail();
    }
  }, [approverProfileMenu])

  useEffect(() => {
    const fetchPicklistData = async () => {
      try {
        const response = await PickListService.picklist({
          pickListCodes: [
            "ALCLC",
            "ALCV",
            "ALCCC",
          ],
        });
        const picklistResponse = response.data.response;

        const groupedPicklistData = Object.keys(picklistResponse).map(
          (pickListCode) => ({
            pickListCode,
            items: picklistResponse[pickListCode].map((item) => ({
              pickListItemCode: item.pickListItemCode,
              pickListDescription: item.pickListDescription,
            })),
          })
        );
        console.log("groupedPicklistData", groupedPicklistData)

        const logicCond =
          groupedPicklistData
            .find((picklist) => picklist.pickListCode === "ALCLC")
            ?.items.map((item) => ({
              value: item.pickListItemCode,
              label: item.pickListDescription,
            })) || [];

        const variable =
          groupedPicklistData
            .find((picklist) => picklist.pickListCode === "ALCV")
            ?.items.map((item) => ({
              value: item.pickListItemCode,
              label: item.pickListDescription,
            })) || [];
        const cc =
          groupedPicklistData
            .find((picklist) => picklist.pickListCode === "ALCCC")
            ?.items.map((item) => ({
              value: item.pickListItemCode,
              label: item.pickListDescription,
            })) || [];
        console.log("cc", cc);

        setLogicCondMenu(logicCond)
        setVariableMenu(variable)
        setCcMenu(cc)
      } catch (error) {
        console.error("Error fetching picklist data:", error);
      }
    };
    fetchPicklistData();
  }, []);

  const handleAddNewApprovalLevel = () => {
    setApprovalLevel((prevApprovalLevel) => [
      ...prevApprovalLevel,
      {
        createdDateTimeUTC: "",
        createdByUser: "",
        modifiedDateTimeUTC: "",
        modifiedByUser: "Jnana",
        approvalId: "",
        levelOrder: approvalLevel.length + 1,
        levelName: "",
        hierarchyRestriction: false,
        approverProfile: [],
        approverProfileId: "",
        rejectedRequires: false,
        approvedRequires: false,
        skipCriteria: [],
        skipAsTemp: false,
        isDelete: false,
        filteredApproverProfiles: approverProfileMenu
      },
    ]);
  }

  const handleAddNewSkipCriteria = (index) => {
    setApprovalLevel((prevApprovalLevel) =>
      prevApprovalLevel.map((approval, idx) =>
        idx === index
          ? {
            ...approval,
            skipCriteria: [
              ...approval.skipCriteria,
              {
                createdDateTimeUTC: "",
                createdByUser: "",
                modifiedDateTimeUTC: "",
                modifiedByUser: "Jnana",
                skipCriteriaId: "",
                logicCondition: "",
                variable: "",
                comparisionCriteria: "",
                value: "",
                skipAs: false,
                isDelete: false,
              },
            ],
          }
          : approval
      )
    );
  };

  const handleDeleteApprovalLevels = (index) => {
    setApprovalLevel((prevApprovalLevel) =>
      prevApprovalLevel.map((approval, idx) =>
        idx === index
          ? approval.approvalId
            ? { ...approval, isDelete: true } // Mark as deleted if `approvalId` is present
            : null // Remove the approval level if `approvalId` is not present
          : approval
      ).filter(Boolean) // Filter out `null` values to remove deleted approval levels
    );
  };

  const handleDeleteSkipCriteria = (approvalIndex, criteriaIndex) => {
    setApprovalLevel((prevApprovalLevel) =>
      prevApprovalLevel.map((approval, idx) =>
        idx === approvalIndex
          ? {
            ...approval,
            skipCriteria: approval.skipCriteria.map((criteria, cIdx) =>
              cIdx === criteriaIndex
                ? criteria.skipCriteriaId
                  ? { ...criteria, isDelete: true } // Mark as deleted if `skipCriteriaId` is present
                  : null // Remove the skipCriteria if `skipCriteriaId` is not present
                : criteria
            ).filter(Boolean), // Filter out `null` values to remove deleted skipCriteria
          }
          : approval
      )
    );
  };

  const handleSearch = (index, query) => {
    setApprovalLevel((prevApproval) =>
      prevApproval.map((approval, i) =>
        i === index
          ? {
            ...approval,
            filteredApproverProfiles: approverProfileMenu.filter((item) =>
              item.profileName.toLowerCase().includes(query.toLowerCase())
            ),
          }
          : approval
      )
    );
  };
  //handle fields changes
  const handleTopSwitchChange = (event) => {
    const { name, checked } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: checked, // Dynamically update the corresponding field
    }));
  };

  const handleLevelNameChange = (index, value) => {
    setApprovalLevel((prevApproval) =>
      prevApproval.map((approval, i) =>
        i === index ? { ...approval, levelName: value } : approval
      ))
  }

  const handleApprovalLevelSwitchChange = (index, event) => {
    const { name, checked } = event.target;
    setApprovalLevel((prevApproval) =>
      prevApproval.map((approval, i) =>
        i === index ? { ...approval, [name]: checked } : approval
      )
    );
  };

  // const handleApproverProfileType = (index, selectedValues) => {
  //   setApprovalLevel((prevApproval) =>
  //     prevApproval.map((approval, i) =>
  //       i === index ? { ...approval, approverProfile: selectedValues } : approval
  //     )
  //   );
  // };

  const handleApproverProfileType = (index, selectedValues) => {
    console.log("selectedValues", selectedValues)
    const value = selectedValues[selectedValues.length - 1]
    const id = approverProfileMenu.filter((item) => item.profileName == value).map((item) => item.profileId)
    console.log("selectedValuesId", id[0])
    setApprovalLevel((prevApproval) =>
      prevApproval.map((approval, i) =>
        i === index ? { ...approval, approverProfile: [value], approverProfileId: id[0] } : approval
      )
    );
  };


  const handleApproverProfileChipDelete = (index, valueToDelete) => {
    setApprovalLevel((prevApproval) =>
      prevApproval.map((approval, i) =>
        i === index
          ? {
            ...approval,
            approverProfile: approval.approverProfile.filter(
              (value) => value !== valueToDelete
            ),
          }
          : approval
      )
    );
  };


  // for skip criteria
  const handleSkipCriteriaField = (approvalIndex, criteriaIndex, fieldName, value) => {
    setApprovalLevel((prevApproval) =>
      prevApproval.map((approval, i) =>
        i === approvalIndex
          ? {
            ...approval,
            skipCriteria: approval.skipCriteria.map((criteria, idx) =>
              idx === criteriaIndex ? { ...criteria, [fieldName]: value } : criteria
            ),
          }
          : approval
      )
    );
  };

  const handleSkipAsChange = (approvalIndex, event) => {
    setApprovalLevel((prevApprovalLevel) =>
      prevApprovalLevel.map((approval, index) => {
        if (index === approvalIndex && !approval.isDelete) {
          return {
            ...approval,
            skipCriteria: approval.skipCriteria.map((criteria) =>
              !criteria.isDelete
                ? { ...criteria, skipAs: event.target.checked }
                : criteria
            ),
          };
        }
        return approval;
      })
    );
  };


  const [errors, setErrors] = useState({});

  // handleSubmit
  const handleSubmit = async () => {
    try {
      const schemaValidationErrors = {};
      try {
        await approvalLCSchema.validate(approvalLevel, { abortEarly: false });
      } catch (err) {
        err.inner.forEach((validationError) => {
          schemaValidationErrors[validationError.path] = validationError.message;
        });
      }
      console.log("errors", schemaValidationErrors)
      if (Object.keys(schemaValidationErrors).length > 0) {
        setErrors(schemaValidationErrors);
        // setMessage("Validation errors occurred");
        // setSeverity("error");
        // setOpenAlert(true);
        return;
      }
      else {
        setErrors(schemaValidationErrors);
      }

      const currentDateTime = new Date().toISOString();
      const currentUser = "currentUser"; // Replace with actual user info

      // Construct the final payload
      const payload = {
        createdDateTimeUTC: currentDateTime,
        createdByUser: currentUser,
        modifiedDateTimeUTC: currentDateTime,
        modifiedByUser: currentUser,
        alcWhenApproved: values.whenApproved,
        alcWhenRejected: values.whenRejected,
        moduleId: values.moduleId,
        moduleName: values.moduleName,
        approvalLevelConfigurationDto: approvalLevel.map((approval) => ({
          createdDateTimeUTC: approval.createdDateTimeUTC || "",
          createdByUser: approval.createdByUser || "",
          modifiedDateTimeUTC: approval.modifiedDateTimeUTC || "",
          modifiedByUser: approval.modifiedByUser || "Jnana",
          alcId: approval.approvalId || "",
          profileId: approval.approverProfileId || "",
          profileName: approval.approverProfile[0] || "",
          alcLevelOrder: approval.levelOrder || "",
          alcLevelName: approval.levelName || "",
          alcHierarchyRestriction: approval.hierarchyRestriction || false,
          alcToBeRejectedRequires: approval.rejectedRequires || false,
          alcToBeApprovedRequires: approval.approvedRequires || false,
          approvalLevelConfigurationSkipCriteriaList: approval.skipCriteria.map((sc) => ({
            createdDateTimeUTC: sc.createdDateTimeUTC || "",
            createdByUser: sc.createdByUser || "",
            modifiedDateTimeUTC: sc.modifiedDateTimeUTC || "",
            modifiedByUser: sc.modifiedByUser || "",
            alcscId: sc.skipCriteriaId || "",
            alcLogicCondition: sc.logicCondition || "",
            alcVariable: sc.variable || "",
            alcComparisonCriteria: sc.comparisionCriteria || "",
            alcValue: sc.value || "",
            alcSkipAs: approval.skipAsTemp || "",
            isDelete: sc.isDelete || false,
          })),
          isDelete: approval.isDelete || false,
        }))
      }

      // Debug log
      console.log('Sending payload to API:', payload);

      // Make the API call
      // if (isNew) {
      const response = await ApprovalLevelConfigurationService.postALCAPI(payload);
      if (response.status === 201) {
        setMessage(t("recordUpdatedSuccessfully"));
        setSeverity("success");
        setOpenAlert(true);
        navigate("/approvalWorkflowApprovalLevelConfigurationList");
      }
      // }
      // else {
      //   const response = await ApprovalLevelConfigurationService.putALCAPI(payload);
      //   if (response.status === 201) {
      //     setMessage(t("approvalLevelConfig.putSuccess"));
      //     setSeverity("success");
      //     setOpenAlert(true);
      //     navigate("/approvalWorkflowApprovalLevelConfigurationList");
      //   }
      // }

    } catch (error) {
      console.error("Error saving data:", error);
      setMessage(error.message || "Error saving Approval Level Configuration");
      setSeverity("error");
      setOpenAlert(true);
    } finally {
      // setLoading(false);
    }
  };

  return (
    <Layout
      title={t("approvalLevelConfig.screen2")}
      background="#ffffff"
    >
      <Box sx={{ paddingBottom: 20 }}>
        <Box
          sx={{
            borderRadius: 4,
            backgroundColor: "#EFEFEF",
            // padding: 2,
            display: "flex",
            alignItems: "flex-start",
            // width: "100%",
            p: 2
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "30%",
              alignItems: "flex-start",
              p: 2
            }}>
            <FormControl component="fieldset">
              <FormLabel
                sx={{
                  fontWeight: "500",
                  color: "#5A646E",
                  mb: 1.5,
                  ml: 0.5,
                  "&.Mui-focused": { color: "#5A646E" },
                  "&:hover": { color: "#5A646E" },
                }}
              >
                <Typography fontWeight={500} fontSize="18px !important" color="#3B454F">
                  {t("approvalLevelConfig.when")}{" "}{t("approvalLevelConfig.approved")}
                </Typography>
              </FormLabel>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.4rem",
                  ml: 5,
                }}
              >
                {/* "No" label on the left */}
                <Typography
                  sx={{
                    fontWeight: "500",
                    color: "#5A646E",
                  }}
                >
                  {t("approvalLevelConfig.goTonextLevel")}
                </Typography>

                {/* Switch */}
                <RedSwitch
                  sx={{
                    marginRight: "11px",
                  }}
                  checked={values?.whenApproved}
                  onChange={handleTopSwitchChange}
                  name="whenApproved"
                  color="primary"
                  disabled={edit === false}
                />

                {/* "Yes" label on the right */}
                <Typography
                  sx={{
                    fontWeight: "500",
                    color: "#5A646E",
                  }}
                >
                  {t("approvalLevelConfig.approves")}
                </Typography>
              </Box>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "40%",
              alignItems: "flex-start",
              p: 2
            }}>
            <FormControl component="fieldset">
              <FormLabel
                sx={{
                  fontWeight: "500",
                  color: "#5A646E",
                  mb: 1.5,
                  ml: 0.5,
                  "&.Mui-focused": { color: "#5A646E" },
                  "&:hover": { color: "#5A646E" },
                }}
              >
                <Typography fontWeight={500} fontSize="18px !important" color="#3B454F">
                  {t("approvalLevelConfig.when")}{" "}{t("approvalLevelConfig.rejected")}
                </Typography>
              </FormLabel>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.4rem",
                  ml: 5,
                }}
              >
                {/* "No" label on the left */}
                <Typography
                  sx={{
                    fontWeight: "500",
                    color: "#5A646E",
                  }}
                >
                  {t("approvalLevelConfig.goTonextLevel")}
                </Typography>

                {/* Switch */}
                <RedSwitch
                  sx={{
                    marginRight: "11px",
                  }}
                  checked={values?.whenRejected}
                  onChange={handleTopSwitchChange}
                  name="whenRejected"
                  color="primary"
                  disabled={edit === false}
                />

                {/* "Yes" label on the right */}
                <Typography
                  sx={{
                    fontWeight: "500",
                    color: "#5A646E",
                  }}
                >
                  {t("approvalLevelConfig.rejects")}
                </Typography>
              </Box>
            </FormControl>
          </Box>
        </Box>
        <Divider title={t('approvalLevelConfig.approvalLevels')}>
          <Button
            sx={{
              backgroundColor: "#004F9F",
              "&.Mui-disabled": {
                backgroundColor: "#0000003B",
                color: "#A1A1A1",
              }
            }}
            variant="contained"
            onClick={handleAddNewApprovalLevel}
            disabled={!edit === true}
          >
            {t("addNew")}
          </Button>
        </Divider>
        {approvalLevel.map((approval, index) => !approval.isDelete && (
          <Box
            key={index}
            sx={{
              borderRadius: 4,
              backgroundColor: "#EFEFEF",
              // padding: 2,
              display: "flex",
              alignItems: "flex-start",
              // width: "100%",
              p: 2,
              mt: 1
            }}
          >
            <Grid container spacing={2} alignItems="baseline">
              <Grid item xs={12} sm={6} md={1.1}>
                <FormControl fullWidth>
                  <TextField
                    name="levelOrder"
                    label={t("approvalLevelConfig.levelOrder")}
                    value={approval?.levelOrder}
                    disabled={true}
                    required={true}
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "46px" },
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2.2}>
                <FormControl fullWidth>
                  <TextField
                    name="levelName"
                    autoComplete="off"
                    label={t("approvalLevelConfig.levelName")}
                    value={approval?.levelName}
                    disabled={!edit}
                    required={true}
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "46px" },
                    }}
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => handleLevelNameChange(index, event.target.value)}
                  />
                  {errors[`[${index}].levelName`] && (
                    <FormHelperText error>
                      {errors[`[${index}].levelName`]}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={1.9}>
                <FormControl component="fieldset">
                  <FormLabel
                    sx={{
                      fontWeight: "500",
                      color: "#5A646E",
                      mb: 1.5,
                      ml: 0.5,
                      "&.Mui-focused": { color: "#5A646E" },
                      "&:hover": { color: "#5A646E" },
                    }}
                  >
                    <Typography fontWeight={500} color="#3B454F">
                      {t("approvalLevelConfig.hirarachyRestriction")}
                    </Typography>
                  </FormLabel>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.4rem",
                      ml: 5,
                    }}
                  >
                    {/* "No" label on the left */}
                    <Typography
                      sx={{
                        fontWeight: "500",
                        color: "#5A646E",
                      }}
                    >
                      {t("no")}
                    </Typography>

                    {/* Switch */}
                    <RedSwitch
                      sx={{
                        marginRight: "11px",
                      }}
                      checked={approval?.hierarchyRestriction}
                      onChange={(event) => handleApprovalLevelSwitchChange(index, event)}
                      name="hierarchyRestriction"
                      color="primary"
                      disabled={edit === false}
                    />

                    {/* "Yes" label on the right */}
                    <Typography
                      sx={{
                        fontWeight: "500",
                        color: "#5A646E",
                      }}
                    >
                      {t("yes")}
                    </Typography>
                  </Box>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2.5}>
                <FormControl fullWidth>
                  <InputLabel
                    id={`approver-profile-label-${index}`}
                    sx={{
                      color: "#112a63",
                      "&.Mui-focused": { color: "#112a63" },
                    }}
                    shrink={true}
                  >
                    {t("approvalLevelConfig.approverProfile")}
                    <span style={{ color: "red" }}> *</span>
                  </InputLabel>
                  <Select
                    labelId={`approver-profile-label-${index}`}
                    id={`approver-profile-select-${index}`}
                    multiple
                    disabled={!edit}
                    value={approval.approverProfile || []}
                    required
                    onChange={(event) => {
                      const value = event.target.value;
                      console.log("valueClicked", typeof value, value)
                      if (!value.includes(undefined)) {
                        handleApproverProfileType(index, value);
                      }
                    }}
                    input={<OutlinedInput label="Approver Profile" />}
                    renderValue={(selected) =>
                      selected.length ? (
                        <div style={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
                          {selected.map((value) => (
                            <Chip
                              disabled={!edit}
                              key={value}
                              label={value}
                              onDelete={() => handleApproverProfileChipDelete(index, value)}
                              deleteIcon={
                                <IconButton
                                  size="small"
                                  sx={{
                                    backgroundColor: "rgba(0, 0, 0, 0.18)",
                                    padding: "1px",
                                    "&:hover": {
                                      backgroundColor: "rgba(0, 0, 0, 0.38)", // Slightly lighter shade on hover
                                    },
                                  }}
                                >
                                  <CloseIcon
                                    fontSize="small"
                                    sx={{ fontSize: "19px" }}
                                    onMouseDown={(event) => event.stopPropagation()}
                                  />
                                </IconButton>
                              }
                              sx={{
                                "&.Mui-disabled": {
                                  opacity: 1,
                                  backgroundColor: "#e0e0e0",
                                  color: "#333333",
                                },
                              }}
                            />
                          ))}
                        </div>
                      ) : "Select an option"
                    }
                    MenuProps={MenuProps}
                    displayEmpty
                    sx={{
                      "&.Mui-disabled fieldset": {
                        borderColor: "gray !important",
                        borderWidth: "1px !important",
                      },
                      "&.Mui-disabled:hover fieldset": {
                        borderColor: "gray",
                        borderWidth: "1px",
                      },
                    }}
                  >
                    <MenuItem disableGutters>
                      <TextField
                        placeholder={t("approvalLevelConfig.searchProfile")}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={(e) => handleSearch(index, e.target.value)}
                        onKeyDown={(e) => e.stopPropagation()}
                        InputProps={{
                          sx: {
                            backgroundColor: "#F8F8F8 !important",
                            borderRadius: "28px", // Rounded corners
                            padding: "6px 8px",
                            fontSize: "14px",
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <img src={SearchIcon} alt="search" style={{ width: '20px', height: '20px', color: '#737D86' }} />
                            </InputAdornment>
                          )
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none", // Remove border
                            },
                            "&:hover fieldset": {
                              border: "none", // Remove border on hover
                            },
                            "&.Mui-focused fieldset": {
                              border: "none", // Remove border on focus
                            },
                          },
                        }}
                      />
                    </MenuItem>
                    {approval.filteredApproverProfiles.map((approvalProfile, idx) => (
                      <MenuItem
                        key={idx}
                        value={approvalProfile.profileName}
                        sx={{
                          borderLeft: approval.approverProfile.includes(approvalProfile.profileName)
                            ? "4px solid #004F9F"
                            : "none",
                          paddingLeft: approval.approverProfile.includes(approvalProfile.profileName)
                            ? "8px"
                            : "12px",
                        }}
                      >
                        <ListItemText primary={approvalProfile.profileName} />
                      </MenuItem>
                    ))}
                  </Select>
                  {errors[`[${index}].approverProfileId`] && (
                    <FormHelperText error>
                      {errors[`[${index}].approverProfileId`]}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={1.9}>
                <FormControl component="fieldset">
                  <FormLabel
                    sx={{
                      fontWeight: "500",
                      color: "#5A646E",
                      mb: 1.5,
                      ml: 0.5,
                      "&.Mui-focused": { color: "#5A646E" },
                      "&:hover": { color: "#5A646E" },
                    }}
                  >
                    <Typography fontWeight={500} color="#3B454F">
                      {t("approvalLevelConfig.toBeRejectedReq")}
                    </Typography>
                  </FormLabel>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.4rem",
                      ml: 5,
                    }}
                  >
                    {/* "No" label on the left */}
                    <Typography
                      sx={{
                        fontWeight: "500",
                        color: "#5A646E",
                      }}
                    >
                      {t("approvalLevelConfig.anyUser")}
                    </Typography>

                    {/* Switch */}
                    <RedSwitch
                      sx={{
                        marginRight: "11px",
                      }}
                      checked={approval?.rejectedRequires}
                      onChange={(event) => handleApprovalLevelSwitchChange(index, event)}
                      name="rejectedRequires"
                      color="primary"
                      disabled={edit === false}
                    />

                    {/* "Yes" label on the right */}
                    <Typography
                      sx={{
                        fontWeight: "500",
                        color: "#5A646E",
                      }}
                    >
                      {t("approvalLevelConfig.allUser")}
                    </Typography>
                  </Box>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={1.9}>
                <FormControl component="fieldset">
                  <FormLabel
                    sx={{
                      fontWeight: "500",
                      color: "#5A646E",
                      mb: 1.5,
                      ml: 0.5,
                      "&.Mui-focused": { color: "#5A646E" },
                      "&:hover": { color: "#5A646E" },
                    }}
                  >
                    <Typography fontWeight={500} color="#3B454F">
                      {t("approvalLevelConfig.toBeApprovedReq")}
                    </Typography>
                  </FormLabel>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.4rem",
                      ml: 5,
                    }}
                  >
                    {/* "No" label on the left */}
                    <Typography
                      sx={{
                        fontWeight: "500",
                        color: "#5A646E",
                      }}
                    >
                      {t("approvalLevelConfig.anyUser")}
                    </Typography>

                    {/* Switch */}
                    <RedSwitch
                      sx={{
                        marginRight: "11px",
                      }}
                      checked={approval?.approvedRequires}
                      onChange={(event) => handleApprovalLevelSwitchChange(index, event)}
                      name="approvedRequires"
                      color="primary"
                      disabled={edit === false}
                    />

                    {/* "Yes" label on the right */}
                    <Typography
                      sx={{
                        fontWeight: "500",
                        color: "#5A646E",
                      }}
                    >
                      {t("approvalLevelConfig.allUser")}
                    </Typography>
                  </Box>
                </FormControl>
              </Grid>
              <Grid item md={0.5}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Button
                    disabled={!edit}
                    onClick={() => handleDeleteApprovalLevels(index)}
                  >
                    <img src={_ButtonDelete_} alt="Delete" />
                  </Button>
                </Box>
              </Grid>
              <Grid item md={12}>
                <Box sx={{ backgroundColor: "#FFFFFF", margin: "1 0", paddingLeft: 2, display: "flex", alignItems: "center" }}>
                  <Typography>{t("approvalLevelConfig.skipCriteria")}</Typography>
                  <Button
                    sx={{
                      backgroundColor: "#004F9F",
                      "&.Mui-disabled": {
                        backgroundColor: "#0000003B",
                        color: "#A1A1A1",
                      },
                      marginLeft: "auto",
                      mt: 1,
                      mb: 1,
                      mr: 1
                    }}
                    variant="contained"
                    onClick={() => handleAddNewSkipCriteria(index)}
                    disabled={!edit === true}
                  >
                    {t("addNew")}
                  </Button>
                </Box>
              </Grid>
              {approval.skipCriteria.map((criteria, idx) => !criteria.isDelete && (
                <Grid item md={12}>
                  <Box
                    sx={{
                      backgroundColor: "#FFFFFF",
                      margin: "1 0",
                      padding: 2,
                      display: "flex",
                      alignItems: "center",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.5)",
                      border: "1px solid #ACB4BA",
                      borderRadius: "5px",
                    }}
                  >
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={6} md={2}>
                        <FormControl fullWidth>
                          <TextField
                            select
                            name="logicCondition"
                            disabled={!edit || idx === 0}
                            label={t("approvalLevelConfig.logicCond")}
                            value={idx === 0 ? "" : criteria.logicCondition || "pl"}
                            onChange={(e) =>
                              handleSkipCriteriaField(index, idx, e.target.name, e.target.value)
                            }
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                padding: "7px",
                                minHeight: "50px",
                              },
                              "& .MuiInputLabel-root": {
                                lineHeight: "1.2em",
                              },
                            }}
                            InputProps={{
                              style: { height: "50px" },
                              endAdornment: criteria.logicCondition && (
                                <InputAdornment position="end">
                                  <IconButton
                                    size="small"
                                    onClick={() => handleSkipCriteriaField(index, idx, "logicCondition", "")}
                                    style={{ marginRight: "15px" }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          >
                            <MenuItem value="pl" sx={{ display: "none" }}>{t("selectoption")}</MenuItem>
                            {logicCondMenu.map((menu) => (
                              <MenuItem key={menu.value} value={menu.value}>
                                {menu.label}
                              </MenuItem>
                            ))}
                          </TextField>
                          {/* <FormHelperText
                            error={!!errors[`[${index}].skipCriteria[${idx}].logicCondition`]}
                            sx={{
                              minHeight: "1.5em", // Reserve space for the helper text
                            }}
                          >
                            {errors[`[${index}].skipCriteria[${idx}].logicCondition`] || " "}
                          </FormHelperText> */}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <FormControl fullWidth>
                          <TextField
                            select
                            disabled={!edit}
                            name="variable"
                            label={t("approvalLevelConfig.variable")}
                            value={criteria.variable || "pl"}
                            onChange={(e) =>
                              handleSkipCriteriaField(index, idx, e.target.name, e.target.value)
                            }
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                padding: "7px",
                                minHeight: "50px",
                              },
                              "& .MuiInputLabel-root": {
                                lineHeight: "1.2em",
                              },
                            }}
                            InputProps={{
                              style: { height: "50px" },
                              endAdornment: criteria.variable && (
                                <InputAdornment position="end">
                                  <IconButton
                                    disabled={!edit}
                                    size="small"
                                    onClick={() => handleSkipCriteriaField(index, idx, "variable", "")}
                                    style={{ marginRight: "15px" }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          >
                            <MenuItem value="pl" sx={{ display: "none" }}>{t("selectoption")}</MenuItem>
                            {variableMenu.map((menu) => (
                              <MenuItem key={menu.value} value={menu.value}>
                                {menu.label}
                              </MenuItem>
                            ))}
                          </TextField>
                          {/* <FormHelperText
                            error={!!errors[`[${index}].skipCriteria[${idx}].variable`]}
                            sx={{
                              minHeight: "1.5em", // Reserve space for the helper text
                            }}
                          >
                            {errors[`[${index}].skipCriteria[${idx}].variable`] || " "}
                          </FormHelperText> */}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <FormControl fullWidth>
                          <TextField
                            select
                            disabled={!edit}
                            name="comparisionCriteria"
                            label={t("approvalLevelConfig.cc")}
                            value={criteria.comparisionCriteria || "pl"}
                            onChange={(e) =>
                              handleSkipCriteriaField(index, idx, e.target.name, e.target.value)
                            }
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                padding: "7px",
                                minHeight: "50px",
                              },
                              "& .MuiInputLabel-root": {
                                lineHeight: "1.2em",
                              },
                            }}
                            InputProps={{
                              style: { height: "50px" },
                              endAdornment: criteria.comparisionCriteria && (
                                <InputAdornment position="end">
                                  <IconButton
                                    disabled={!edit}
                                    size="small"
                                    onClick={() => handleSkipCriteriaField(index, idx, "comparisionCriteria", "")}
                                    style={{ marginRight: "15px" }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          >
                            <MenuItem value="pl" sx={{ display: "none" }}>{t("selectoption")}</MenuItem>
                            {ccMenu.map((menu) => (
                              <MenuItem key={menu.value} value={menu.value}>
                                {menu.value}
                              </MenuItem>
                            ))}
                          </TextField>
                          {/* <FormHelperText
                            error={!!errors[`[${index}].skipCriteria[${idx}].comparisionCriteria`]}
                            sx={{
                              minHeight: "1.5em", // Reserve space for the helper text
                            }}
                          >
                            {errors[`[${index}].skipCriteria[${idx}].comparisionCriteria`] || " "}
                          </FormHelperText> */}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <FormControl fullWidth>
                          <TextField
                            name="value"
                            disabled={!edit}
                            label={t("approvalLevelConfig.value")}
                            value={criteria?.value}
                            // disabled={true}
                            fullWidth
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                padding: "7px",
                                minHeight: "50px",
                              },
                              "& .MuiInputLabel-root": {
                                lineHeight: "1.2em",
                              },
                            }}
                            InputProps={{
                              style: { height: "46px" },
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                              handleSkipCriteriaField(index, idx, e.target.name, e.target.value)
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={3.5} />
                      <Grid item md={0.5}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Button
                            disabled={!edit}
                            onClick={() => handleDeleteSkipCriteria(index, idx)}
                          >
                            <img src={_ButtonDelete_} alt="Delete" />
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))}
              {approval.skipCriteria.length > 0 && (
                <Grid item md={12}>
                  <Box
                    sx={{
                      backgroundColor: "#FFFFFF",
                      margin: "1 0",
                      padding: 2,
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <FormControl component="fieldset">
                      <FormLabel
                        sx={{
                          fontWeight: "500",
                          color: "#5A646E",
                          mb: 1.5,
                          ml: 0.5,
                          "&.Mui-focused": { color: "#5A646E" },
                          "&:hover": { color: "#5A646E" },
                        }}
                      >
                        <Typography fontWeight={500} color="#3B454F">
                          {t("approvalLevelConfig.skipAs")}
                        </Typography>
                      </FormLabel>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.4rem",
                          ml: 15,
                        }}
                      >
                        {/* "No" label on the left */}
                        <Typography
                          sx={{
                            fontWeight: "500",
                            color: "#5A646E",
                          }}
                        >
                          {t("approvalLevelConfig.rejected")}
                        </Typography>

                        {/* Switch */}
                        <RedSwitch
                          sx={{
                            marginRight: "11px",
                          }}
                          checked={approval?.skipAsTemp}
                          onChange={(event) => handleApprovalLevelSwitchChange(index, event)}
                          name="skipAsTemp"
                          color="primary"
                          disabled={edit === false}
                        />

                        {/* "Yes" label on the right */}
                        <Typography
                          sx={{
                            fontWeight: "500",
                            color: "#5A646E",
                          }}
                        >
                          {t("approvalLevelConfig.approved")}
                        </Typography>
                      </Box>
                    </FormControl>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        ))}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={3}
          mb={2.5}
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#ffffff",
            padding: "10px",
            boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
            zIndex: 1000
          }}
        >
          {/* Cancel Button - Left bottom corner */}
          <Button
            style={{
              backgroundColor: "#737D86",
              padding: "6px 16px",
              borderRadius: "4px",
            }}
            variant="contained"
            onClick={() => navigate("/approvalWorkflowApprovalLevelConfigurationList")} // Open the modal on click
            startIcon={<img src={arrowLeft} alt="Arrow" />}
          >
            {edit == false ? "Back" : "Cancel"}
          </Button>

          {/* Modal confirmation */}
          {/* <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                backgroundColor: "#fff",
                borderRadius: "7px",
                width: "585px",
                margin: "auto",
                mt: 25,
                height: "200px",
              }}
            >
              <Box
                sx={{
                  background: "#102A62",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderTopLeftRadius: "7px",
                  borderTopRightRadius: "7px",
                }}
              >
                <Typography
                  sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                >
                  {t("cancelTitle")}
                </Typography>
              </Box>
              <Box sx={{ p: 2 }}>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2, fontWeight: 400 }}
                >
                  {t("cancelMessage")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                  mb: 1,
                }}
              >
                <Button
                  onClick={handleCloseModal}
                  sx={{
                    background: "#737D86",
                    width: "100px",
                    color: "#ffffff",
                    "&:hover": { background: "#737D86" },
                  }}
                >
                  {t("no")}
                </Button>
                <Button
                  onClick={handleDeleteConfirm}
                  sx={{
                    background: "#004F9F",
                    width: "100px",
                    color: "#ffffff",
                    "&:hover": { background: "#004F9F" },
                  }}
                >
                  {t("yes")}
                </Button>
              </Box>
            </Box>
          </Modal> */}


          <Button
            sx={{
              padding: "6px 16px",
              borderRadius: "4px",
              backgroundColor: "#EF7D00",
              color: "white",
              "&:hover": {
                backgroundColor: "#EF7D00",
              },
            }}
            startIcon={<img src={Save} alt="Right Arrow" />}
            disabled={!edit}
            onClick={handleSubmit}
            variant="contained"
          >
            {"Save"}
          </Button>

        </Box>
      </Box>
    </Layout>
  )
}

export default ApprovalLevelConfigurationDetails