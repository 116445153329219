import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { AuthData } from "auth/AuthWarpper";
import { SnackbarContext } from "context/snackBarContext";
import VarietyTable from "./VarietyTable";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CuringService from "services/CuringService";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { debounce } from "lodash";
import SpacingServices from "services/SpacingServices";
import VarietyRegistrationService from "services/VarietyRegistrationService";
import Lable from "utils/lables.json";
import label from "../growingLabel.json";
import SelectInput from "components/common/SelectInput";
import TextInput from "components/common/TextInput";
import { useTranslation } from 'react-i18next';

const VarietyList = () => {
  console.log('VarietyList component rendering');

  const navigate = useNavigate();
  const { user } = AuthData();
  const [varietyData, setVarietyData] = useState();
  console.log("🚀 ~ VarietyList ~ varietyData:", varietyData);
  const [allCuringData, setAllCuringData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [searchParams, setSearchParams] = useState({
    isNew: "",
    varietiesName: "",
    tobaccoGroup: "",
    status: "",
    countryCode: user ? user.countryCode : "BRA",
  });

  const [descSearch, setDescSearch] = useState({ varietiesName: "" });
  console.log("🚀 ~ Curing ~ descSearch:", descSearch);

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(3); // Number of items per page
  const [totalItems, setTotalItems] = useState(5); // Number of items per page
  const [recordsError, setRecordsError] = useState(false);
  const [tobaccoGroups, setTobaccoGroups] = useState([]);
  console.log("🚀 ~ Curing ~ tobaccoGroups:", tobaccoGroups);

  const [culture, setCulture] = useState("");
  const [cultureId, setCultureId] = useState("");
  const [geneticType, setGeneticType] = useState("");
  const [cultureOptions, setCultureOptions] = useState([]);
  const [geneticTypeOptions, setGeneticTypeOptions] = useState([]);

  const { t } = useTranslation();

  const statusOptions = [
    { value: "Active", label: t('active') },
    { value: "Inactive", label: t('inactive') }
  ];

  const [isCultureLoading, setIsCultureLoading] = useState(false);
  const [isGeneticTypesLoading, setIsGeneticTypesLoading] = useState(false);

  const callCuringApi = useCallback((pNumber, size) => {
    setLoading(true);
    setRecordsError(false);

    VarietyRegistrationService.getVarietySearch({ 
      pageNumber: pNumber, 
      size: size 
    })
      .then((res) => {
        setCurrentPage(pNumber);
        setItemsPerPage(size);
        setVarietyData(res?.data?.response?.["Variety Registration"]);
        setTotalItems(res?.data?.response?.totalItems);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const paginate = (pageNumber, itemsPerPage) => {
    callCuringApi(pageNumber - 1, itemsPerPage);
  };

  useEffect(() => {
    callCuringApi(currentPage, itemsPerPage);
  }, []);

  const handleChangeDescription = (event) => {
    const { value } = event.target;
    setDescSearch({ varietiesName: value });
  };

  const handleSearch = useCallback(
    debounce(() => {
      setLoading(true);
      
      // Find the selected genetic type object to get its ID
      const selectedGeneticType = geneticTypeOptions.find(type => type.value === geneticType);
      
      const searchPayload = {
        pageNumber: currentPage,
        size: itemsPerPage,
        cuCultureId: cultureId || undefined,
        vrVariety: descSearch.varietiesName || undefined,
        gtGeneticTypeId: selectedGeneticType?.geneticTypeId || undefined,
        isActive: searchParams.status === "Active" ? true : 
                 searchParams.status === "Inactive" ? false : undefined
      };

      // Remove undefined values
      Object.keys(searchPayload).forEach(key => 
        searchPayload[key] === undefined && delete searchPayload[key]
      );

      VarietyRegistrationService.getVarietySearch(searchPayload)
        .then((res) => {
          setCurrentPage(res?.data?.response?.currentPage);
          setTotalItems(res?.data?.response?.totalItems);
          setVarietyData(res?.data?.response?.["Variety Registration"]);
          setRecordsError(false);
        })
        .catch((error) => {
          setRecordsError(true);
          setMessage(error?.response?.data?.message || "Error fetching data");
          setOpenAlert(true);
          setSeverity("error");
        })
        .finally(() => {
          setLoading(false);
        });
    }, 500),
    [searchParams, currentPage, itemsPerPage, cultureId, geneticType, descSearch.varietiesName]
  );

  useEffect(() => {
    handleSearch();
    return () => {
      handleSearch.cancel();
    };
  }, [searchParams, currentPage, itemsPerPage, cultureId, geneticType, descSearch.varietiesName]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await CuringService.getAllCuringData(
          user ? user.countryCode : "BRA"
        );
        setAllCuringData(response.data.response);
      } catch (error) {
        console.error("There was an error!", error);
      }
    };

    fetchData();
  }, []);

  const cleanSearchParams = (params) => {
    const cleanedParams = { ...params };
    Object.keys(cleanedParams).forEach((key) => {
      if (cleanedParams[key] === "") {
        delete cleanedParams[key];
      }
    });
    return cleanedParams;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };

  const handleClear = (name) => {
    setSearchParams({
      ...searchParams,
      [name]: "",
    });
  };

  useEffect(() => {
    const fetchTobaccoGroups = async () => {
      try {
        const response = await SpacingServices.getTobbacoType();
        setTobaccoGroups(response.data.response);
      } catch (error) {
        console.error("Failed to fetch tobacco groups", error);
      }
    };

    fetchTobaccoGroups();
  }, [user]);

  useEffect(() => {
    const fetchCultureAndGeneticTypeData = async () => {
      setIsCultureLoading(true);
      setIsGeneticTypesLoading(true);
      try {
        // Fetch culture data
        const cultureResponse = await VarietyRegistrationService.getCultureData(user?.countryCode || 'BRA');
        const cultures = cultureResponse.data.response.Culture.map(culture => ({
          value: culture.cuCultureName,
          label: culture.cuCultureName,
          cultureId: culture.cuCultureId
        }));
        setCultureOptions(cultures);

        // Fetch genetic types for "tobacco" culture
        const geneticTypesResponse = await VarietyRegistrationService.getGeneticTypesByCultureName('tobacco');
        const geneticTypeOptions = geneticTypesResponse.data.response.map(type => ({
          geneticTypeId: type.gtGeneticTypeId,
          value: type.gtGeneticDescription,
          label: type.gtGeneticDescription
        }));
        setGeneticTypeOptions(geneticTypeOptions);

      } catch (error) {
        console.error('Failed to fetch data:', error);
        setMessage('Failed to load culture or genetic type data');
        setOpenAlert(true);
        setSeverity('error');
      } finally {
        setIsCultureLoading(false);
        setIsGeneticTypesLoading(false);
      }
    };

    fetchCultureAndGeneticTypeData();
  }, [user?.countryCode]);

  const handleCultureChange = (event) => {
    const selectedValue = event.target.value;
    setCulture(selectedValue);
    
    const selectedCulture = cultureOptions.find(option => option.value === selectedValue);
    setCultureId(selectedCulture ? selectedCulture.cultureId : "");
  };

  const handleAddNew = () => {
    console.log('Add New button clicked');
    navigate("/varietyForm");
  };

  const refetchVarietyData = useCallback(() => {
    callCuringApi(currentPage, itemsPerPage);
  }, [currentPage, itemsPerPage]);

  return (
    <Layout
      title={t('VarietyRegistration.pageTitle')}
      background="#ffffff"
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      currentPage={currentPage}
      paginate={paginate}
      useNewPagination={true}
    >
      <Grid 
        container 
        spacing={3}
        sx={{ 
          margin: "0",
          width: "100%",
        }}
      >
        {/* Filter Section */}
        <Grid 
          item 
          xs={12}
          container 
          spacing={2} 
          sx={{ 
            background: "#EFEFEF",
            borderRadius: "10px",
            p: 2,
            maxWidth: "100%",
            margin: "0",
            paddingBottom: "34px",
          }}
        >
          <Grid item xs={12} style={{ paddingTop: "0px", marginLeft: "-34px" }}>
            <Box sx={{ display: "flex" }}>
              <ArrowDropDownIcon sx={{ marginBottom: 1.1 }} />
              <Typography>{Lable.filtersLable}</Typography>
            </Box>
          </Grid>

          <Grid container spacing={2} alignItems="center">
            {/* Culture */}
            <Grid item xs={12} sm={6} md={2.5}>
              <SelectInput
                label={t('VarietyRegistration.culture')}
                options={cultureOptions}
                value={culture}
                onChange={handleCultureChange}
                placeholder={t('VarietyRegistration.selectOption')}
                disabled={isCultureLoading}
              />
            </Grid>

            {/* Variety Name */}
            <Grid item xs={12} sm={6} md={2.5}>
              <TextInput
                label={t('VarietyRegistration.variety')}
                value={descSearch.varietiesName}
                onChange={handleChangeDescription}
                placeholder=""
                size="small"
              />
            </Grid>

            {/* Genetic Type */}
            <Grid item xs={12} sm={6} md={2.5}>
              <SelectInput
                label={t('VarietyRegistration.geneticType')}
                options={geneticTypeOptions}
                value={geneticType}
                onChange={(e) => setGeneticType(e.target.value)}
                placeholder={t('VarietyRegistration.selectOption')}
                disabled={isGeneticTypesLoading}
              />
            </Grid>

            {/* Status */}
            <Grid item xs={12} sm={6} md={2.4}>
              <SelectInput
                label={t('VarietyRegistration.status')}
                options={statusOptions}
                value={searchParams.status}
                onChange={(e) => handleChange({ target: { name: 'status', value: e.target.value }})}
                placeholder={t('VarietyRegistration.selectOption')}
              />
            </Grid>

            {/* Add New Button */}
            <Grid item xs={12} sm={6} md={2.1}>
              <Button
                fullWidth
                sx={{
                  borderRadius: 1,
                  minHeight: 44,
                  background: "#004F9F",
                }}
                variant="contained"
                startIcon={<AddCircleIcon />}
                onClick={handleAddNew}
              >
                {t('VarietyRegistration.addNew')}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {/* Table Section */}
        <Grid item xs={12}>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
              <CircularProgress />
            </Box>
          ) : varietyData && varietyData.length > 0 && !recordsError ? (
            <VarietyTable 
              varietyData={varietyData} 
              onDelete={refetchVarietyData}
            />
          ) : varietyData && varietyData.length === 0 ? (
            <Typography variant="h6" align="center" color="textSecondary">
              {t('VarietyRegistration.noData')}
            </Typography>
          ) : (
            <div>{t('VarietyRegistration.noData')}</div>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default VarietyList;
