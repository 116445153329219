import React, { useState, useEffect, useRef } from "react";
import {
  format,
  addMonths,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameDay,
  isWithinInterval,
} from "date-fns";
import { ChevronLeft, ChevronRight, Calendar } from "lucide-react";
import { Button, TextField, Box, Typography } from "@mui/material";
 
const DateRangePicker = ({ onSelectDateRange, id, start, end, edit, error, helperText }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [currentMonth, setCurrentMonth] = useState(startOfMonth(new Date()));
  const [selecting, setSelecting] = useState("start");
  const popupRef = useRef(null);
 
  // Handle click outside of calendar to close
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
 
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
 
  const formatDateToNumeric = (date) => {
    if (!date) return null; // Handle null or undefined date
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
 
  const changeMonth = (delta) => {
    setCurrentMonth((prevMonth) => addMonths(prevMonth, delta));
  };
 
  const selectDate = (date) => {
    if (selecting === "start" || (startDate && date < startDate)) {
      setStartDate(date);
      setEndDate(null);
      setSelecting("end");
    } else {
      setEndDate(date);
      setSelecting("start");
    }
  };
 
  const clearSelection = () => {
    setStartDate(null);
    setEndDate(null);
    setSelecting("start");
    onSelectDateRange(id, "startDate", null);
    onSelectDateRange(id, "endDate", null);
  };
 
  const closeCalendar = () => {
    setIsOpen(false);
    if (startDate && endDate) {
      onSelectDateRange(id, "startDate", startDate);
      onSelectDateRange(id, "endDate", endDate);
    }
  };
 
  // Update the parent when startDate or endDate change
  useEffect(() => {
    const numericStartDate = formatDateToNumeric(startDate);
    const numericEndDate = formatDateToNumeric(endDate);
 
    if (numericStartDate && numericEndDate) {
      onSelectDateRange(id, "startDate", numericStartDate);
      onSelectDateRange(id, "endDate", numericEndDate);
    } else {
      // Handle null or undefined values
      onSelectDateRange(id, "startDate", null);
      onSelectDateRange(id, "endDate", null);
    }
  }, [startDate, endDate]);
 
  // Fix to adjust the start of the week to Monday
  const renderCalendar = (month) => {
    const monthStart = startOfMonth(month);
    const monthEnd = endOfMonth(month);
 
    if (isNaN(monthStart.getTime()) || isNaN(monthEnd.getTime())) {
      return null;
    }
 
    // Get the first day of the month and its day of the week
    let firstDayOfWeek = monthStart.getDay(); // Sunday is 0, Monday is 1, etc.
    firstDayOfWeek = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1; // Adjust for Monday as the first day of the week
 
    const daysInMonth = eachDayOfInterval({ start: monthStart, end: monthEnd });
 
    // Adjust the number of empty cells before the first day of the month
    const leadingEmptyDays = Array(firstDayOfWeek).fill(null);
 
    // Combine leading empty days and actual days in the month
    const allDays = [...leadingEmptyDays, ...daysInMonth];
 
    return (
      <Box className="calendar-wrapper">
        <Box
          className="month-header"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Button onClick={() => changeMonth(-1)} size="small">
            <ChevronLeft />
          </Button>
          <Typography>{format(month, "MMMM yyyy")}</Typography>
          <Button onClick={() => changeMonth(1)} size="small">
            <ChevronRight />
          </Button>
        </Box>
        <table className="calendar">
          <thead>
            <tr>
              {["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"].map((day) => (
                <th key={day}>{day}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 6 }).map((_, weekIndex) => (
              <tr key={weekIndex}>
                {Array.from({ length: 7 }).map((_, dayIndex) => {
                  const date = allDays[weekIndex * 7 + dayIndex];
                  if (!date) return <td key={dayIndex}></td>; // Empty cell for days before the 1st of the month
 
                  const isSelected =
                    (startDate && isSameDay(date, startDate)) ||
                    (endDate && isSameDay(date, endDate));
                  const isInRange =
                    startDate &&
                    endDate &&
                    isWithinInterval(date, { start: startDate, end: endDate });
 
                  return (
                    <td
                      key={dayIndex}
                      onClick={() => selectDate(date)}
                      style={{
                        cursor: "pointer",
                        padding: "8px",
                        textAlign: "center",
                        backgroundColor: isSelected
                          ? "#1976d2"
                          : isInRange
                          ? "#bbdefb"
                          : "transparent",
                        color: isSelected ? "white" : "inherit",
                      }}
                    >
                      {format(date, "d")}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    );
  };
 
  return (
    <Box position="relative">
      <TextField
        fullWidth
        label="Valid Date"
        disabled={edit == false}
        error={error}
        helperText={helperText}
        sx={{
          "& .MuiOutlinedInput-root": {
            height: "50px",
          },
        }}
        InputProps={{
          readOnly: true,
          endAdornment: <Calendar />,
        }}
        value={
          startDate && endDate
            ? `${format(startDate, "dd/MM/yyyy")} - ${format(
                endDate,
                "dd/MM/yyyy"
              )}`
            : ""
        }
        onClick={() => {
          if (edit) {
            setIsOpen(true);
          }
        }}
        style={{ cursor: "pointer" }}
      />
 
      {isOpen && (
        <Box
          ref={popupRef}
          position="absolute"
          left={0}
          top="100%"
          mt={1}
          bgcolor="background.paper"
          border={1}
          borderColor="divider"
          borderRadius={1}
          boxShadow={3}
          zIndex={50}
          p={2}
        >
          <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2} mb={2}>
            {renderCalendar(currentMonth)}
            {renderCalendar(addMonths(currentMonth, 1))}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body2" color="black">
              {startDate && endDate
                ? `${format(startDate, "dd/MM/yyyy")} - ${format(
                    endDate,
                    "dd/MM/yyyy"
                  )}`
                : startDate
                ? `${format(startDate, "dd/MM/yyyy")}`
                : ""}
            </Typography>
            <Box>
              <Button
                variant="outlined"
                onClick={clearSelection}
                sx={{
                  mr: 1,
                  bgcolor: "#004F9F",
                  "&:hover": {
                    bgcolor: "#004F9F",
                    bgcolor: "#004F9F",
                  },
                }}
              >
                <Typography sx={{ color: "white" }}> Clear </Typography>
              </Button>
              <Button
                variant="contained"
                onClick={closeCalendar}
                sx={{
                  bgcolor: "#004F9F",
                  "&:hover": {
                    bgcolor: "#004F9F",
                    bgcolor: "#004F9F",
                  },
                }}
              >
                <Typography sx={{ color: "white" }}> Close </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
 
export default DateRangePicker;
