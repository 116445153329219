import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import arrowDown from "assets/arrowDown.svg";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { StyledTreeItem } from "utils/Styledtreeitem";
import PropertyRegisterationModal from "./PropertyRegisterationModal";
import DataTable from "../DataTable/DataTable";
import "./FarmerAdd.css";
import {
  updateTaxInformation,
  addTaxField,
  updateTaxField,
  clearTaxInformation,
} from "slices/taxInformationSlice";
import RenderField from "utils/RenderField";
import { taxFormConfig } from "./FormConfig";
import { taxInformationSchema } from "utils/validations";
import iconDelete from "assets/iconDelete.svg";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { clearProperty, deleteProperty } from "slices/propertyAddSlice";
import iconDeleteRound from "assets/iconDeleteRound.svg";
import { SnackbarContext } from "context/snackBarContext";
import FarmerRegisterationService from "services/FarmerRegistrationService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PickListService from "services/PickListService";
import arrowLeft from "assets/arrowLeft.svg";
import { clearGeneralInformation } from "slices/generalInformation";
import { clearAdults } from "slices/adultsSlice";
import { clearEnvironment } from "slices/environmentalSlice";
import { clearDependant } from "slices/dependantSlice";
import CustomButton from "utils/CustomButton";
import { useTranslation } from 'react-i18next';
import TransporterGetService from "services/TransporterGetService";


export default function TaxInformation(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = useParams();
  const { id } = params;
  const taxInformation = useSelector((state) => state.tax.taxInformation);
  const propertyInformation = useSelector(
    (state) => state?.property?.propertyInformation
  );
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const [values, setValues] = useState(() => ({
    ...taxInformation,
    taxFields: taxInformation.taxFields || [],
  }));

  console.log(
    "🚀 ~ const[values,setValues]=useState ~ values:",
    taxInformation
  );

  const [modalValue, setModalValues] = useState([]);
  const [errors, setErrors] = useState({});
  const [dateError, setDateError] = useState(false);
  const [picklistData, setPicklistData] = useState([]);
  const [taxForm, setTaxFormConfig] = useState(taxFormConfig);
  console.log("🚀 ~ TaxInformation ~ taxForm:", taxForm);
  const [bank, setBank] = useState([]);
  const [branches, setBranches] = useState([])
  console.log("🚀 ~ TaxInformation ~ picklistData:", picklistData);
  const [loading, setLoading] = useState(false);


  const getBankList = async () => {
    try {
      const response = await TransporterGetService.getBankDetails();
      const bankData = response.data.response;
  
      const bankOptions = bankData.map((bank) => ({
        value: bank.bankRegistrationId, 
        label: bank.bankName,
        bankCode:bank.bankCode
      }));
  
      setTaxFormConfig((prevConfig) =>
        prevConfig.map((field) =>
          field.name === "bankName"
            ? {
                ...field,
                options: [{ value: "", label: "None" }, ...bankOptions],
              }
            : field
        )
      );
    } catch (error) {
      console.error("Error fetching bank details:", error);
    }
  };

  const fetchBranchDetails = async (bankRegistrationId) => {
    try {
      const response = await TransporterGetService.getBankBranchDetails(
        bankRegistrationId
      );

      const brancList = response.data.response
      console.log("🚀 ~ fetchBranchDetails ~ response:", brancList)

      const bankOptions = brancList.map((bank) => ({
        value: bank.branchNumber, 
        label: bank.branchNumber,
      }));
  
      setTaxFormConfig((prevConfig) =>
        prevConfig.map((field) =>
          field.name === "branchNumber"
            ? {
                ...field,
                options: [{ value: "", label: "None" }, ...bankOptions],
              }
            : field
        )
      );

      // setBranches(response.data.response || []);
    } catch (error) {
      console.error("Error fetching branch details:", error);
      setMessage("Failed to fetch bank branch details");
      setSeverity("error");
      setOpenAlert(true);
      setBranches([]); // Reset branches on error
    }
  };
  

  useEffect(() => {
    const fetchInitialData = async () => {
      await Promise.all([
        getBankList(),
        // getRegionList(),
        // getNationalityList(),
        // getMaritalList(),
        // getTMList(),
        // getFTList(),
        // getCityist(),
      ]);
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    if (taxInformation) {
      setValues((prevValues) => ({
        ...prevValues,
        ...taxInformation,
        taxFields: taxInformation.taxFields || prevValues.taxFields,
      }));
    }
  }, [taxInformation]);

  useEffect(() => {
    const fetchPicklistData = async () => {
      try {
        const response = await PickListService.picklist({
          pickListCodes: ["FRGINFBNK1"],
        });
        const picklistResponse = response.data.response;

        const groupedPicklistData = Object.keys(picklistResponse).map(
          (pickListCode) => ({
            pickListCode,
            items: picklistResponse[pickListCode].map((item) => ({
              pickListItemCode: item.pickListItemCode,
              pickListDescription: item.pickListDescription,
            })),
          })
        );

        setPicklistData(groupedPicklistData);

        const accountTypeOptions =
          groupedPicklistData
            .find((picklist) => picklist.pickListCode === "FRGINFBNK1")
            ?.items.map((item) => ({
              value: item.pickListItemCode,
              label: item.pickListDescription,
            })) || [];

        setTaxFormConfig((prevConfig) =>
          prevConfig.map((field) =>
            field.name === "accountType"
              ? {
                  ...field,
                  options: [
                    // { value: "", label: "None" },
                    ...accountTypeOptions,
                  ],
                }
              : field
          )
        );
      } catch (error) {
        console.error("Error fetching picklist data:", error);
      }
    };

    fetchPicklistData();
  }, []);

  useEffect(() => {
    const getFarmerByIds = async () => {
      try {
        const response = await FarmerRegisterationService.getFarmerById({ id });
        const bank = response?.data?.response?.bankDetails || {};
        console.log("🚀 ~ getFarmerByIds ~ bank:", bank);
        console.log("API Response:", response.data.response);

        const newValues = {
          taxFields: response?.data?.response?.taxDetails || [],
          ...bank,
        };

        setValues(newValues);
        dispatch(updateTaxInformation(newValues));
      } catch (error) {
        console.error("Error getting farmer by id:", error);
      }
    };

    if (id && values.bankName === "") {
      getFarmerByIds();
    }
  }, [id, dispatch]);

  const handleTaxFieldChange = (index, event) => {
    const { name, value, type, checked } = event.target;
    setValues((prevValues) => {
      const updatedTaxFields = [...prevValues.taxFields];
      updatedTaxFields[index] = {
        ...updatedTaxFields[index],
        [name]: type === "checkbox" ? checked : value,
      };
      return {
        ...prevValues,
        taxFields: updatedTaxFields,
      };
    });

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (name === "stateRegistrationNumber" && value) {
        updatedErrors.taxFields = updatedErrors.taxFields || [];
        updatedErrors.taxFields[index] = updatedErrors.taxFields[index] || {};
        updatedErrors.taxFields[index].stateRegistrationNumber = "";
      }
      if (name === "city" && value) {
        updatedErrors.taxFields = updatedErrors.taxFields || [];
        updatedErrors.taxFields[index] = updatedErrors.taxFields[index] || {};
        updatedErrors.taxFields[index].city = "";
      }
      return updatedErrors;
    });
  };

  const validateFields = () => {
    const newErrors = values.taxFields.map((field) => ({
      stateRegistrationNumber: field.stateRegistrationNumber
        ? field.stateRegistrationNumber.length > 20
          ? "State Registration Number must not exceed 20 digits"
          : ""
        : "State Registration Number is required",
      city: field.city
        ? field.city.length > 50
          ? "City must not exceed 50 characters"
          : ""
        : "City is required",
    }));

    const hasFieldErrors = newErrors.some(
      (error) => error.stateRegistrationNumber || error.city
    );

    setErrors((prevErrors) => ({
      ...prevErrors,
      taxFields: newErrors,
    }));

    return hasFieldErrors ? newErrors : {};
  };

  const addTaxFieldHandler = () => {
    setValues((prevValues) => ({
      ...prevValues,
      taxFields: [
        ...prevValues.taxFields,
        { stateRegistrationNumber: "", city: "", nfe: false },
      ],
    }));
  };

  const environmentInformation = useSelector(
    (state) => state?.environment?.addEnvironment
  );
  const adultInformation = useSelector((state) => state?.adult?.addAdult);
  // const propertyInformation = useSelector(
  //   (state) => state?.property?.propertyInformation
  // );
  // const taxInformation = useSelector((state) => state.tax.taxInformation);
  const generalInformation = useSelector(
    (state) => state?.farmers?.generalInformation
  );
  console.log("🚀 ~ TaxInformation ~ generalInformation:", generalInformation)
  const depedantInformation = useSelector(
    (state) => state?.dependant?.addDependant
  );

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   const fieldValidationErrors = validateFields();

  //   let schemaValidationErrors = {};
  //   try {
  //     await taxInformationSchema.validate(values, { abortEarly: false });
  //   } catch (err) {
  //     err?.inner?.forEach((error) => {
  //       schemaValidationErrors[error.path] = error.message;
  //     });

  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       ...schemaValidationErrors,
  //     }));
  //   }

  //   const hasErrors =
  //     Object.values(fieldValidationErrors).some((fieldErrors) =>
  //       Object.values(fieldErrors).some((error) => error)
  //     ) || Object.values(schemaValidationErrors).some((error) => error);

  //   if (!hasErrors) {
  //     setErrors({});
  //     dispatch(updateTaxInformation(values));
  //     setSeverity("success");
  //     setMessage(t("snackbarMessage.savedSuccessfully"));
  //     setOpenAlert(true);
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      // if (
      //   generalInformation.minorsUnder18 === "Yes" &&
      //   filteredDependents.length === 0
      // ) {
      //   setSeverity("error");
      //   setMessage("Please provide mandatory minors");
      //   setOpenAlert(true);
      // } else if (
      //   generalInformation.adultsOver18 === "Yes" &&
      //   adultInformation.length === 0
      // ) {
      //   setSeverity("error");
      //   setMessage("Please provide mandatory adults on property");
      //   setOpenAlert(true);
      // }

      // if (generalInformation.dripIrrigation === "Yes") {
      //   await environmentSchema.validate(values, { abortEarly: false });
      // }
      // setErrors({});

      const {
        branchNumber,
        accountType,
        accountNumber,
        stateRegistrationNumber,
        city,
        // bankId,
        bankNames,
        ...otherValues
      } = values;

      const cleanedPartnersOnProperty = adultInformation.map(
        ({ id, ...rest }) => rest
      );
      const cleanedDependentRegistrationDetails = depedantInformation.map(
        ({ id, attendSchool, ...rest }) => ({
          ...rest,
          attendSchool: attendSchool === "Yes" ? true : false,
        })
      );
      const cleanedFarmerPropertyDetails = propertyInformation.map(
        ({ id, ...rest }) => rest
      );
      console.log(
        "🚀 ~ EnvironmentalLicense ~ propertyInformation:",
        cleanedFarmerPropertyDetails
      );
      const bankDetails = {
        bankName : bankNames,
        branchNumber,
        accountType,
        accountNumber,
        stateRegistrationNumber,
        // bankId,
        city,
        createdDateTimeUTC: new Date(),
        createdByUser: "string",
        modifiedDateTimeUTC: new Date(),
        modifiedByUser: "string",
      };

      const taxFields = values.taxFields;

      const updatedValues = {
        // environmentalLicenseDetails: { ...values },
        bankDetails,
        //DEV ID below
        userUid: "0A00021A-8EC8-179F-818E-C8E04E200003",
        //SIT ID below
        // userUid: "0A00021A-8EC8-179F-818E-C8E04E200003",
        // countryId: "5d68bc83-3635-4a2b-a0df-1199b0b75c6e",
        // languageId: "960d7994-5b31-4e5b-8d05-c6d58d918229",
        countryId: "06fde653-a2c4-43b7-879f-4525039d9148",
        languageId: "0a31e178-fdf9-4c8c-bd7b-447a788eada5",
        cropYearUid: "0388F9D6-08C4-42A6-A8A2-54838E09EC91",
        ...generalInformation,
        // partnersOnProperty: cleanedPartnersOnProperty,
        // dependentRegistrationDetails: cleanedDependentRegistrationDetails,
        // farmerPropertyDetails: cleanedFarmerPropertyDetails,
        taxDetails: taxFields,
        createdDateTimeUTC: new Date(),
        createdByUser: "string",
        modifiedDateTimeUTC: new Date(),
        modifiedByUser: "string",
      };

      console.log("🚀 ~ handleSubmit ~ updatedValues:", updatedValues);
      setLoading(true);

      const response = await FarmerRegisterationService.create(updatedValues);
      console.log("🚀 ~ handleSubmit ~ response:", adultInformation);
      if (response.status === 201) {
        dispatch(clearGeneralInformation());
        dispatch(clearTaxInformation());
        dispatch(clearAdults());
        dispatch(clearProperty());
        dispatch(clearDependant());
        dispatch(clearEnvironment());
        navigate("/farmerRegistration");
        setSeverity("success");
        setMessage(t("snackbarMessage.savedSuccessfully"));
        setOpenAlert(true);
        setLoading(false);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage(t("snackbarMessage.submissionFailed"));
        setOpenAlert(true);
        setLoading(false);
      }
      // }

      // dispatch(updateEnvironment(updatedValues));
      // dispatch(updateFarmerObj(updatedValues))
      // dispatch(clearGeneralInformation())
      // dispatch(clearTaxInformation())
      // dispatch(clearAdults())
      // dispatch(clearProperty())
      // dispatch(clearDependant())
      // dispatch(clearEnvironment())
      // navigate("/farmerRegistration")
    } catch (err) {
      console.error("Failed to submit form");
      const validationErrors = {};
      err?.inner?.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      setSeverity("error");
      setMessage("Please provide all required fields");
      setOpenAlert(true);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      // if (
      //   generalInformation.minorsUnder18 === "Yes" &&
      //   filteredDependents.length === 0
      // ) {
      //   setSeverity("error");
      //   setMessage("Please provide mandatory minors");
      //   setOpenAlert(true);
      // } else if (
      //   generalInformation.adultsOver18 === "Yes" &&
      //   adultInformation.length === 0
      // ) {
      //   setSeverity("error");
      //   setMessage("Please provide mandatory adults on property");
      //   setOpenAlert(true);
      // }

      // if (generalInformation.dripIrrigation === "Yes") {
      //   await environmentSchema.validate(values, { abortEarly: false });
      // }
      // setErrors({});

    const {farmerClassification} = generalInformation

    // const farmerClasificationDetails = farmerClassification.map((classification) => ({
    //   isDelete: false,
    //   createdDateTimeUTC: new Date().toISOString(),
    //   createdByUser: "string",
    //   modifiedDateTimeUTC: new Date().toISOString(),
    //   modifiedByUser: "string",
    //   fcId: farmerClassification ,
    //   FC_Category: "string",
    // }));

    const farmerClasificationDetails = [
      {
      isDelete: false,
      createdDateTimeUTC: new Date().toISOString(),
      createdByUser: "string",
      modifiedDateTimeUTC: new Date().toISOString(),
      modifiedByUser: "string",
      fcId: farmerClassification ,
      FC_Category: "string",
    }
    ]


      const {
        branchNumber,
        accountType,
        accountNumber,
        stateRegistrationNumber,
        city,
        // bankId,
        bankNames,
        ...otherValues
      } = values;

      const cleanedPartnersOnProperty = adultInformation.map(
        ({ id, ...rest }) => rest
      );
      const cleanedDependentRegistrationDetails = depedantInformation.map(
        ({ id, attendSchool, ...rest }) => ({
          ...rest,
          attendSchool: attendSchool === "Yes" ? true : false,
        })
      );
      const cleanedFarmerPropertyDetails = propertyInformation.map(
        ({ id, ...rest }) => rest
      );
      console.log(
        "🚀 ~ EnvironmentalLicense ~ propertyInformation:",
        cleanedFarmerPropertyDetails
      );
      const bankDetails = {
        bankName : bankNames,
        branchNumber,
        accountType,
        accountNumber,
        stateRegistrationNumber,
        // bankId,
        city,
        createdDateTimeUTC: new Date(),
        createdByUser: "string",
        modifiedDateTimeUTC: new Date(),
        modifiedByUser: "string",
      };

      const taxFields = values.taxFields;

      const updatedValues = {
        // environmentalLicenseDetails: { ...values },
        bankDetails,
        //DEV ID below
        userUid: "0A00021A-8EC8-179F-818E-C8E04E200003",
        //SIT ID below
        // userUid: "0A00021A-8EC8-179F-818E-C8E04E200003",
        // countryId: "5d68bc83-3635-4a2b-a0df-1199b0b75c6e",
        // languageId: "960d7994-5b31-4e5b-8d05-c6d58d918229",
        countryId: "06fde653-a2c4-43b7-879f-4525039d9148",
        languageId: "0a31e178-fdf9-4c8c-bd7b-447a788eada5",
        cropYearUid: "0388F9D6-08C4-42A6-A8A2-54838E09EC91",
        ...generalInformation,
        farmerClasificationDetails,
        // partnersOnProperty: cleanedPartnersOnProperty,
        // dependentRegistrationDetails: cleanedDependentRegistrationDetails,
        // farmerPropertyDetails: cleanedFarmerPropertyDetails,
        taxDetails: taxFields,
        createdDateTimeUTC: new Date(),
        createdByUser: "string",
        modifiedDateTimeUTC: new Date(),
        modifiedByUser: "string",
      };

      console.log("🚀 ~ handleSubmit ~ updatedValues:", updatedValues);
      setLoading(true);

      const response = await FarmerRegisterationService.update(updatedValues);
      console.log("🚀 ~ handleSubmit ~ response:", adultInformation);
      if (response.status === 201) {
        dispatch(clearGeneralInformation());
        dispatch(clearTaxInformation());
        dispatch(clearAdults());
        dispatch(clearProperty());
        dispatch(clearDependant());
        dispatch(clearEnvironment());
        navigate("/farmerRegistration");
        setSeverity("success");
        setMessage(t("snackbarMessage.savedSuccessfully"));
        setOpenAlert(true);
        setLoading(false);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage(t("snackbarMessage.submissionFailed"));
        setOpenAlert(true);
        setLoading(false);
      }
      // }

      // dispatch(updateEnvironment(updatedValues));
      // dispatch(updateFarmerObj(updatedValues))
      // dispatch(clearGeneralInformation())
      // dispatch(clearTaxInformation())
      // dispatch(clearAdults())
      // dispatch(clearProperty())
      // dispatch(clearDependant())
      // dispatch(clearEnvironment())
      // navigate("/farmerRegistration")
    } catch (err) {
      console.log("err",err)
      console.error("Failed to submit form");
      const validationErrors = {};
      err?.inner?.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      setSeverity("error");
      setMessage("Please provide all required fields");
      setOpenAlert(true);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };


  
  const handleChange = async (event) => {
    const { name, value } = event.target;
  
    setValues((prevValues) => {
      const updatedValues = { ...prevValues, [name]: value };
  
      if (name === "bankName") {
        const selectedBank = taxForm
          .find((field) => field.name === "bankName")
          ?.options.find((option) => option.value === value);

          console.log("selectedBank",selectedBank)
  
        updatedValues.bankCode = selectedBank ? selectedBank.bankCode : "";
        updatedValues.bankId = selectedBank ? value : "";
        updatedValues.bankNames = selectedBank ?  selectedBank.label : "";
 
  
        // Fetch branch details if a valid bank is selected
        if (value) {
          fetchBranchDetails(value); // Call the API with bankRegistrationId
        } else {
          setBranches([]); // Reset branches if no bank is selected
        }
      }
  
      return updatedValues;
    });
  };
  
  
  const handleDeleteTaxField = (index) => {
    setValues((prevValues) => {
      const updatedTaxFields = [...prevValues.taxFields];
      updatedTaxFields.splice(index, 1);
      return {
        ...prevValues,
        taxFields: updatedTaxFields,
      };
    });
  };

  const handleDateChange = (date, fieldName) => {
    if (!date || date === "Invalid Date") {
      setDateError(true);
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
      return;
    }

    setDateError(false);

    const dateValue = new Date(date);
    if (isNaN(dateValue.getTime())) {
      setDateError(true);
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
      return;
    }

    const year = dateValue.getFullYear();
    const month = String(dateValue.getMonth() + 1).padStart(2, "0");
    const day = String(dateValue.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setValues((prevValues) => ({
      ...prevValues,
      [fieldName]: formattedDate,
    }));
  };

  const handleClear = (field) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: "",
    }));
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { edit, details } = location.state || {};
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const handleYes = () => {
    dispatch(clearGeneralInformation());
    dispatch(clearTaxInformation());
    dispatch(clearAdults());
    dispatch(clearProperty());
    dispatch(clearDependant());
    dispatch(clearEnvironment());
    navigate("/farmerRegistration");
  };

  const handleNo = () => setDeleteOpen(false);

  const styleBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30.5rem",
    bgcolor: "background.paper",
    height: "20.125rem",
    borderRadius: "0.625rem",
  };
  return (
    <Box
      sx={{
        minHeight: 180,
        flexGrow: 1,
        paddingBottom: "10rem",
        width: "100%",
        position:"relative",
        marginTop:"20px",
        "& .MuiTextField-root": { m: 1, color: "red", width: "25ch" },
        "& .MuiFormLabel-root": {
          color: "rgba(16, 42, 98, 1)",
          "& .MuiFormLabel-asterisk": {
            color: "rgba(239, 0, 0, 1)",
            fontSize: "18px",
          },
        },
      }}
    >
      <form autoComplete="off" noValidate onSubmit={generalInformation.farmerId ? handleUpdate : handleSubmit}>
        <TreeView
          aria-label="file system navigator"
          defaultCollapseIcon={<img src={arrowDown} alt="Down Arrow" />}
          defaultExpandIcon={<ChevronRightIcon />}
          defaultExpanded={["1", "4", "5"]}
        >
          <Box sx={{background:"#EFEFEF",padding:"5px",borderRadius:"10px",mb:2}}>

          <StyledTreeItem nodeId="1" labelText="Bank Information">
            {taxForm.slice(0, 5).map((field) => (
              <RenderField
                key={field.name}
                field={field}
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleDateChange={handleDateChange}
                handleClear={handleClear}
                details={details === true ? true : false}

              />
            ))}
          </StyledTreeItem>
          </Box>


        <Box sx={{ width: "100%" ,mb:2}}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px 10px",
            background: "#FFFFFF",
            marginTop: 3,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "20px",
              color: "#5A646E",
              flex: "none",
              order: 0,
              flexGrow: 0,
              zIndex: 0,
            }}
          >
            {t("farmerRegistration.taxInformation")}
          </Typography>
          { !details &&
            <Button
              variant="contained"
              //onClick={handleAddPartner}
              sx={{
                minWidth: 95,
                borderRadius: 1,
                background: "#004F9F",
                height: { xs: "40px", md: "44px" },
                marginLeft: { xs: 0, md: "auto" },
              }}
              onClick={addTaxFieldHandler}
            >
              {t("addNew")}
            </Button>
          }
        </Box>
        <Box
          id="divider"
          sx={{
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0px 20px 0px",
            gap: "20px",
            isolation: "isolate",
            background: "#FFFFFF",
            borderBottom: "5px solid #004F9F",
            flex: "none",
            order: 2,
            alignSelf: "stretch",
            flexGrow: 0,
            zIndex: 1,
          }}
        ></Box>
      </Box>
            <div className="farmer-add-tree-main" style={{background:"#EFEFEF",padding:"5px",borderRadius:"10px",mb:2}}>
              {values.taxFields.map((field, index) => (
                <div className="farmer-inner-field" key={index}>
                  <div className="text-field-wrapper">
                    <TextField
                      value={field.stateRegistrationNumber}
                      onChange={(e) => handleTaxFieldChange(index, e)}
                      name="stateRegistrationNumber"
                      type="text"
                      error={
                        !!errors?.taxFields?.[index]?.stateRegistrationNumber
                      }
                      helperText={
                        errors?.taxFields?.[index]?.stateRegistrationNumber
                      }
                      label="State Registration Number"
                      required
                      variant="outlined"
                      placeholder="-"
                      sx={{
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                        "& .MuiInputBase-input::placeholder": {
                          opacity: 1,
                        },
                      }}
                      disabled={details}
                    />
                    <TextField
                      value={field.city}
                      onChange={(e) => handleTaxFieldChange(index, e)}
                      name="city"
                      error={!!errors?.taxFields?.[index]?.city}
                      helperText={errors?.taxFields?.[index]?.city}
                      label="City"
                      required
                      variant="outlined"
                      placeholder="-"
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          opacity: 1,
                        },
                      }}
                      disabled={details}

                    />
                  </div>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.nfe}
                        onChange={(e) => handleTaxFieldChange(index, e)}
                        name="nfe"
                      disabled={details}
                      />
                    }
                    label="Nfe"
                  />
                  {!details &&
                  <img
                  onClick={() => handleDeleteTaxField(index)}
                  src={iconDeleteRound}
                  alt="Delete"
                  />
                }
                </div>
              ))}
            </div>
          {/* <Box className="farmer-add-property" onClick={addTaxFieldHandler}>
            +Add State Registration
          </Box> */}
          {/* {edit && */}
         
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "10px",
              marginTop:"10px"
            }}
          >
            <Button
              style={{
                marginBottom: "10px",
                marginRight: "10px",
                background: "#737D86",
                position:"fixed",
                left:"30px",
                bottom:"20px",
                zIndex:"123",
                height:"46px",
                width:"106px"
              }}
              // startIcon={<img src={arrowLeft} alt="Right Arrow" />}
              variant="contained"
              // onClick={() => navigate("/farmerRegistration")}
              onClick={details ? () => navigate(-1) : () => setDeleteOpen(true)}
            >
              Cancel
            </Button>
        {!details &&

            <Button
              style={{
                marginBottom: "10px",
                background: "#EF7D00",
                position:"fixed",
                right:"30px",
                bottom:"20px",
                zIndex:"123",
                height:"46px",
                width:"106px"
              }}
              type="submit"
              variant="contained"
            >
              Save
            </Button>
}
          </div>
           
          {/* } */}
          {/* {!edit && <Button
            style={{
              float: "left",
              marginBottom: "10px",
              background: "#737D86",
            }}
            startIcon={<img src={arrowLeft} alt="Right Arrow" />}
            variant="contained"
            onClick={() => setDeleteOpen(true)}
          >
            Cancel
          </Button>} */}
        </TreeView>
      </form>
      <Modal
        open={deleteOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleBox}>
          <Box
            sx={{
              mt: 4,
              justifyContent: "center",
              display: "flex",
              gap: "1rem",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>Are you sure!</div>
            <div>You want to Go Back</div>
            <div>Doing so will erase all the prefilled or filled data</div>
          </Box>
          <Box
            sx={{
              mt: 4,
              justifyContent: "center",
              display: "flex",
              gap: "1rem",
            }}
          >
            <CustomButton variant="cancel" label="No" onClick={handleNo} />

            <CustomButton color="#EF7D00" label="Yes" onClick={handleYes} />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
