// src\services\ProductionTargetsService.jsx
import { httpGrow } from "../http-common";
//https://dev.agri360.bat.net/grow_bs/spacing-targets
// /?cropSeasonId=0a2ad833-9344-13d5-8193-481c5581010d&
//geneticTypeId=0a2a8504-931a-1df7-8193-1a482c0d0000&
//importVersionId=0a2a8e4d-92d8-1893-8192-dd2a1c19000a


const getAPI = async (query) => {
    const { cropYearUUID, geneticTypeId, importVersionId } = query;
    const response = await httpGrow.get(`/spacing-targets/`, {
        params: {
            cropSeasonId:  cropYearUUID,
            geneticTypeId:geneticTypeId,
            importVersionId: importVersionId
        }
    });
    return response;
};

const getCropSeasonAPI = async () => {
    const response = await httpGrow.get('/grCropSeason/search?status=true&cultureName=Tobacco');
    return response;
};

///geneticType/genetic-type-cultureName/Tobacco

const getGeneticType = async () => {
    const response = await httpGrow.get('/geneticType/genetic-type-cultureName/Tobacco');
    return response;
};

///spacing-registration/spacing-registration-geneticId/0a2a8504-931a-1df7-8193-1a482c0d0000?description=Meters

const getSpacingRegistration = async (id) => {
    const response = await httpGrow.get(`spacing-registration/spacing-registration-geneticId/${id}?description=Meters`);
    return response;
};

const getHierarchyNodeAPI = async () => {
    const response = await httpGrow.get('/importHierarchyVersion/tree');
    return response;
};

const getInvestmentGroupListAPI = async (query) => {
    const { investmentGroupDescription } = query;
    const response = await httpGrow.get('investment-group/find?pageNumber=0&size=50', {
        params: {
            investmentGroupDescription: investmentGroupDescription || ""
        }
    });
    return response;
};

const putInvestmentGLDAPI = (data) => {
    return httpGrow.put('/spacing-targets/save', data);
};



const SpacingTargetService = {
    getAPI,
    getCropSeasonAPI,
    getHierarchyNodeAPI,
    getInvestmentGroupListAPI,
    putInvestmentGLDAPI,
    getGeneticType,
    getSpacingRegistration
};

export default SpacingTargetService;
