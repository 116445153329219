import { styled } from "@mui/material/styles";
import React, { useCallback, useContext, useEffect, useState ,  forwardRef,
  useImperativeHandle,} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  IconButton,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
  Modal,
  Divider,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "context/snackBarContext";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import SearchTextInput from "components/common/SearchTextInput";
import Save from "assets/SaveNew.svg";
import { debounce } from "lodash";
import ExpensesLimitDistributionService from "services/ExpensesLimitDistributionService";

const StyledTableContainer = styled(TableContainer)({
  boxShadow: "none",
  // border: "1px solid #E5E7EB",
  borderRadius: "8px",
  maxWidth: "1880px",
  margin: "0 auto",
  overflowX: "auto", // Enable horizontal scrolling
});

const StyledTable = styled(Table)({
  borderCollapse: "separate",
  borderSpacing: 0,
  minWidth: "100%", // Ensure table spans horizontally
  borderCollapse: "separate",
  borderSpacing: "0px 4px",
});

const HeaderCell = styled(TableCell)({
  backgroundColor: "#E9EBEE",
  color: "#3B454F",
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontSize: "20px",
  textTransform: "uppercase",
  textAlign: "center",
  padding: "13px 20px",
  borderBottom: "none",
  borderTopLeftRadius: "8px", // Rounded top-left edge
  borderTopRightRadius: "8px", // Rounded top-right edge
  overflow: "hidden", // Ensures the rounded edges are respected
  "&:not(:last-child)": {
    borderRight: "10px solid white", // Add right border (10px) for all cells except last
  },
});

const SecondaryHeaderCell = styled(TableCell)({
  backgroundColor: "#E9EBEE",
  color: "#3B454F",
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "20px",
  textAlign: "center",
  padding: "13px 20px",
  borderBottom: "none",
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  overflow: "hidden",
});

const SubHeaderCell = styled(TableCell)({
  backgroundColor: "#FFFFFF",
  color: "#3B454F",
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "16px",
  padding: "13px 20px",
  border: "1px solid #E9EBEE",
  borderRadius: "8px", // Rounded top-left edge
  overflow: "hidden",
  "&:not(:last-child)": {
    borderRight: "10px solid white", // Add right border (10px) for all cells except last
  },
});

const DataCell = styled(TableCell)({
  fontFamily: "Montserrat",
  fontWeight: 400,
  fontSize: "15px",
  color: "#3B454F",
  padding: "13px 20px",
  backgroundColor: "#EFEFEF",
  textAlign: "center",
  // borderBottom: "1px solid #E9EBEE",
  // borderRight: "1px solid #E9EBEE",
  "&:last-child": {
    borderRight: "none",
  },
});

const TotalCell = styled(TableCell)({
  backgroundColor: "#E9EBEE",
  color: "#000000",
  fontFamily: "Montserrat",
  fontWeight: 700,
  fontSize: "16px",
  padding: "13px 20px",
  borderBottom: "none",
  textAlign: "center",
});

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    padding: "13px 5px",
    fontFamily: "Montserrat",
    fontSize: "15px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
});

const ExpensesLimitDistributionTable = forwardRef(
  (
    {
      data,
      cropYearUUID,
      ihId,
      onSave,
      childNodes,
      isTopHierarchyNode,
      refreshData,
      setShowExitWarningOverlayFeed,
    },
    ref
  ) =>  {
  const [tableData, setTableData] = useState([]);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const { t } = useTranslation();
  const columns = ["Distributed", "Consumed", "Balance"];
  const [showColumns, setShowColumns] = useState({
    distributed: true,
    consumed: true,
    balance: true,
  });
  const [minColumnModel, setMinColumnModel] = useState(false);
  const [manageItemsModal, setManageItemsModal] = useState(false);
  const [removeExpensesModal, setRemoveExpensesModal] = useState(false);
  const [removedExpensesTableId, setRemovedExpensesTableId] = useState();

  const [allHeaderList, setAllHeaderList] = useState([]);
  const [headerListSearch, setHeaderListSearch] = useState("");
  const [activeHeaders, setActiveHeaders] = useState([]);
  const [expensesTotal, setExpensesTotal] = useState([]);
  const [filteredHeaderList, setFilteredHeaderList] = useState([]);

  console.log("data", data);
  console.log("allHeaderList", allHeaderList);
  console.log("activeHeaders", activeHeaders);
  console.log("tableData", tableData);
  console.log("expensesTotal", expensesTotal);
  useImperativeHandle(ref, () => ({
    // save: () => {
    //   setSaveClick(true);
    //   handleSave();
    // },
    save: handleSave,
  }));
  const handleModalOpen = async () => {
    try {
      // Fetch fresh data when opening modal
      const ExpGroupListResponse = await ExpensesLimitDistributionService.getExpensesGroupListAPI({
        ewtpExpenseswith3rdPartiesDescription: "",
      });
      
      const updatedExpGroupList = ExpGroupListResponse.data.response.expenseWithThirdPartiesGroups.map(
        (group) => ({
          ...group,
          inHeaderList: group.isActive,
        })
      );
      
      setAllHeaderList(updatedExpGroupList);
      setFilteredHeaderList(updatedExpGroupList.sort((a, b) => (b.inHeaderList ? 1 : -1)));
      setManageItemsModal(true);
    } catch (error) {
      console.error("Error fetching expenses group data:", error);
      setMessage("Error fetching header list");
      setSeverity("error");
      setOpenAlert(true);
    }
  };

  const handleModalClose = () => {
    setManageItemsModal(false);
    setHeaderListSearch(""); // Reset search value
    setFilteredHeaderList(
      allHeaderList?.sort((a, b) => (b.inHeaderList ? 1 : -1)) || []
    ); // Reset filtered list
  };

  const handleColumnChange = (key, isChecked) => {
    const uncheckedCount = Object.values(showColumns).filter(
      (value) => !value
    ).length;

    if (!isChecked && uncheckedCount >= 2) {
      console.log("ALL 3 FALSE");
      setMinColumnModel(true);
      return;
    }
    setShowColumns((prev) => ({
      ...prev,
      [key]: isChecked,
    }));
    setMinColumnModel(false);
  };

  // const handleExpensesListChange = (row, isChecked) => {
  //   if (!isChecked) {
  //     // for false i.e unchecked
  //     setRemovedExpensesTableId(row.ewtpExpenseswith3rdPartiesId);
  //     setRemoveExpensesModal(true);
  //   } else {
  //     // for true i.e checked
  //     setAllHeaderList((prevList) =>
  //       prevList.map(
  //         (item) =>
  //           item.ewtpExpenseswith3rdPartiesId ===
  //           row.ewtpExpenseswith3rdPartiesId
  //             ? { ...item, inHeaderList: true } // Add inHeaderList: true for the matching item
  //             : item // Leave the rest unchanged
  //       )
  //     );
  //     setActiveHeaders((prevHeader) => [
  //       ...prevHeader,
  //       { ...row, isHeader: true },
  //     ]);
  //   }
  // };
  const handleExpensesListChange = (row, isChecked) => {
    // Check how many active headers are already selected
    const activeHeaderCount = activeHeaders.length;
  
    if (!isChecked && activeHeaderCount <= 1) {
      // Block unselecting if only one active header exists
      alert(t("ExpensesLimitDistribution.atLeastOneHeader")); // Add a message for the user
      return; // Prevent unchecking
    }
  
    if (!isChecked) {
      // for false i.e unchecked
      setRemovedExpensesTableId(row.ewtpExpenseswith3rdPartiesId);
      setRemoveExpensesModal(true);
    } else {
      // for true i.e checked
      setAllHeaderList((prevList) =>
        prevList.map(
          (item) =>
            item.ewtpExpenseswith3rdPartiesId === row.ewtpExpenseswith3rdPartiesId
              ? { ...item, inHeaderList: true } // Add inHeaderList: true for the matching item
              : item // Leave the rest unchanged
        )
      );
      setActiveHeaders((prevHeader) => [
        ...prevHeader,
        { ...row, isHeader: true },
      ]);
    }
  };

  const handleExpensesHeaderChange = (selectedHeader) => {
    setActiveHeaders((prevHeader) =>
      prevHeader.map(
        (header) =>
          // Check if the header's description matches the selectedHeader's description
          header.ewtpExpenseswith3rdPartiesDescription ===
          selectedHeader.ewtpExpenseswith3rdPartiesDescription
            ? { ...header, isHeader: !selectedHeader.isHeader } // Toggle the isHeader value
            : header // Leave the other headers unchanged
      )
    );
  };

  const deleteExpenseData = async (cropYearUUID, removedExpensesTableId) => {
      try {
        const response = await ExpensesLimitDistributionService.deleteDataAPI({
          cropYearUUID:cropYearUUID,
          expensesWithTPGroupId:removedExpensesTableId
        })
        if (!response.ok) {
          throw new Error("Failed to delete expense data");
        }
    
        console.log("Data deleted successfully");
      } catch (error) {
        console.error("Error deleting expense data:", error);
        throw error; 
      }     
    }
  ;
  const handleRemoveExpensesFromModal = async () => {
    try {
      // Call the delete API before updating state to ensure data integrity.
     
  
      // Update `allHeaderList` state
      setAllHeaderList((prevList) =>
        prevList.map((item) =>
          item.ewtpExpenseswith3rdPartiesId === removedExpensesTableId
            ? { ...item, inHeaderList: false } // Mark as not in the header list
            : item // Leave unchanged
        )
      );
  
      // Update `activeHeaders` state
      setActiveHeaders((prevHeader) =>
        prevHeader.filter(
          (item) => item.ewtpExpenseswith3rdPartiesId !== removedExpensesTableId
        )
      );
      refreshData();
      console.log("Deleting data:", cropYearUUID, removedExpensesTableId);
      await deleteExpenseData(cropYearUUID, removedExpensesTableId);
  
      console.log("State updated after deleting data");
    } catch (error) {
      console.error("Failed to remove expenses from modal:", error);
    }
  };
 
  

  const handleTargetChange = (
    inputValue,
    importHierarchyId,
    description,
    rowIndex
  ) => {
    setShowExitWarningOverlayFeed(true);
    console.log("INPUT VALUE", typeof inputValue);
    setTableData((prev) => {
      const updatedData = [...prev];
      console.log("updatedData", updatedData);
      const targetRow = updatedData.find(
        (row) =>
          row.importHierarchyId === importHierarchyId &&
          row.expensesWith3rdPartyGroupDescription === description
      );
      if (targetRow) {
        targetRow.target = inputValue; // Update target value as a string
      }
      return updatedData;
    });
  };

  //fetching list of header details with adding inHeaderList field with value same as it's status
  const fetchAllHeadersList = async (keyword) => {
    try {
      const ExpGroupListResponse =
        await ExpensesLimitDistributionService.getExpensesGroupListAPI({
          ewtpExpenseswith3rdPartiesDescription: keyword,
        });
      console.log("ExpGroupResponse", ExpGroupListResponse);

      const updatedExpGroupList =
        ExpGroupListResponse.data.response.expenseWithThirdPartiesGroups.map(
          (group) => ({
            ...group,
            inHeaderList: group.isActive,
          })
        );
      console.log("updatedExpGroupList", updatedExpGroupList);
      setAllHeaderList(updatedExpGroupList);
    } catch (error) {
      console.error("Error fetching expenses group data:", error);
    }
  };

  //Active Headers
  const fetchActiveHeadersList = async () => {
    try {
      const response =
        await ExpensesLimitDistributionService.getExpensesGLAPI();
      console.log("response", response);

      const IsActiveExpGroupList =
        response.data.response.expenseWithThirdPartiesGroups.filter(
          (val) => val.isActive === true
        );
      console.log("active", IsActiveExpGroupList);

      const updatedIsActiveExpGroupList = IsActiveExpGroupList.map((group) => ({
        ...group,
        isHeader: group.isActive,
      }));

      setActiveHeaders(updatedIsActiveExpGroupList);
    } catch (error) {
      console.error("Error fetching investment header data:", error);
    }
  };

  useEffect(() => {
    fetchAllHeadersList("");
    fetchActiveHeadersList();
  }, [data]);

  const handleHeaderListSearch = (event) => {
    const { value } = event.target;
    setHeaderListSearch(value);
    // Update filtered list immediately when search value changes
    const matchedList = allHeaderList
      .filter((item) =>
        item.ewtpExpenseswith3rdPartiesDescription
          .toLowerCase()
          .includes(value.toLowerCase())
      )
      .sort((a, b) => (b.inHeaderList ? 1 : -1)); // Sort to bring checked items on top

    setFilteredHeaderList(matchedList);
  };

  useEffect(() => {
    // Initialize filteredHeaderList when allHeaderList changes
    setFilteredHeaderList(
      allHeaderList?.sort((a, b) => (b.inHeaderList ? 1 : -1)) || []
    );
  }, [allHeaderList]);

  useEffect(() => {
    //The useEffect is triggered only if all are available 
    if (childNodes && allHeaderList && activeHeaders) {
      // Initialize initialData as an empty array if data is not provided or empty
      let initialData = data && data.length > 0 ? [...data] : [];
      console.log("initialData", initialData);

      childNodes.forEach((child) => {
        //Check if the Child Exists in initialData
        const childExists = initialData.some((item) => item.importHierarchyId === child.value);
        console.log("childExists", childExists);

        //If the child doesn't exist in initialData, the code then checks for each active header in activeHeaders
        //For each value in activeHeaders, it checks if an entry with the same importHierarchyId and expensesWith3rdPartyGroupDescription already exists in initialData
        if (!childExists) {
          activeHeaders.forEach((value) => {
            const expensesExists = initialData.some(
              (item) =>
                item.importHierarchyId === child.value &&
                item.expensesWith3rdPartyGroupDescription ===value.ewtpExpenseswith3rdPartiesDescription
            );
            console.log('expensesExists',expensesExists)

            if (!expensesExists) {
              initialData.push({
                importHierarchyId: child.value,
                expensesWith3rdPartyGroupDescription:
                value.ewtpExpenseswith3rdPartiesDescription,
                expensesWith3rdPartyGroupId: value.ewtpExpenseswith3rdPartiesId,
                target: 0,
                distributed: 0,
                consumed: 0,
                balance: 0,
                createdDateTimeUTC: new Date().toISOString(),
                modifiedDateTimeUTC: new Date().toISOString(),
                createdByUser: "defaultUser",
                modifiedByUser: "defaultUser",
                expensesWithTPLimitConfigId: "defaultId",
              });
            }
          });
        } 
        //If childExists is true, meaning the child is already present in initialData
        else {
          activeHeaders.forEach((value) => {
            const expensesExists = initialData.some(
              (item) =>
                item.importHierarchyId === child.value &&
                item.expensesWith3rdPartyGroupDescription === value.ewtpExpenseswith3rdPartiesDescription
            );
           
            //If data is not available for specific Headers
            if (!expensesExists) {
              initialData.push({
                importHierarchyId: child.value,
                expensesWith3rdPartyGroupDescription:value.ewtpExpenseswith3rdPartiesDescription,
                expensesWith3rdPartyGroupId: value.ewtpExpenseswith3rdPartiesId,
                target: 0,
                distributed: 0,
                consumed: 0,
                balance: 0,
                createdDateTimeUTC: new Date().toISOString(),
                modifiedDateTimeUTC: new Date().toISOString(),
                createdByUser: "defaultUser",
                modifiedByUser: "defaultUser",
                expensesWithTPLimitConfigId: "defaultId",
              });
            }
          });
        }
      });
      setTableData(initialData);
    }
  }, [data, childNodes, allHeaderList]);

  const calculateExpensesTotal = (tableData) => {
  //Iterates over each group (item) in tableData. Aggregates the totals into an accumulator (acc)
    const totals = tableData.reduce((acc, group) => {
      console.log('acc',acc)
      //find to check if there is already an entry in acc that matches the current item's expensesWith3rdPartyGroupDescription.
      const existingGroup = acc.find(
        (item) =>
          item.expensesWith3rdPartyGroupDescription ===
          group.expensesWith3rdPartyGroupDescription
      );
      console.log('existingGroup',existingGroup);

      if (existingGroup) {
        // If the group with the same expenses Description exists, update totals
        existingGroup.totalTarget =
          Math.round((existingGroup.totalTarget + group.target) * 100) / 100;
        existingGroup.totalDistributed =
          Math.round(
            (existingGroup.totalDistributed + group.distributed) * 100
          ) / 100;
        existingGroup.totalConsumed =
          Math.round((existingGroup.totalConsumed + group.consumed) * 100) /
          100;
        existingGroup.totalBalance =
          Math.round((existingGroup.totalBalance + group.balance) * 100) / 100;
       
        existingGroup.available =
          Math.round(
            (existingGroup.totalTarget - existingGroup.totalDistributed) * 100
          ) / 100;
      } else {
        // If it's a new expenses Description, create a new entry
        acc.push({
          expensesWith3rdPartyGroupDescription:
            group.expensesWith3rdPartyGroupDescription,
          totalTarget: group.target,
          totalDistributed: group.distributed,
          totalConsumed: group.consumed,
          totalBalance: group.balance,
          available: group.target - group.distributed,
        });
      }

      return acc;
    }, []);

    return totals;
  };

  useEffect(() => {
    if (tableData) {
      const updatedTotals = calculateExpensesTotal(tableData);
      setExpensesTotal(updatedTotals);
    }
  }, [tableData]);

  const handleSave = async () => {
    try {
      const currentDateTime = new Date().toISOString();
      const currentUser = "currentUser";

      const payload = tableData.map((item) => ({
        createdDateTimeUTC:
          item.expensesWithTPLimitConfigId === "defaultId"
            ? currentDateTime
            : item.createdDateTimeUTC,
        createdByUser:
          item.expensesWithTPLimitConfigId === "defaultId"
            ? currentUser
            : item.createdDateTimeUTC,
        modifiedDateTimeUTC: currentDateTime,
        modifiedByUser: currentUser,
        expensesWithTPLimitConfigId:item.expensesWithTPLimitConfigId === "defaultId" ? "" : item.expensesWithTPLimitConfigId,
        importHierarchyId: item.importHierarchyId,
        cropYearId: cropYearUUID,
        expensesWith3rdPartyGroupId: item.expensesWith3rdPartyGroupId,
        target: item.target,
        distributed: item.distributed,
        consumed: item.consumed,
        balance: item.balance,
        isDelete: false,
      }));

      // Debug log
      console.log("payload:", payload);

      // Make the API call
      await ExpensesLimitDistributionService.putExpensesGLDAPI(payload);
      setShowExitWarningOverlayFeed(false);

      setMessage("Expenses GLD saved successfully");
      setSeverity("success");
      setOpenAlert(true);
      refreshData();
      return true;
    } catch (error) {
      console.error("Error saving data:", error);
      setMessage(error.message || "Error saving Expenses targets");
      setSeverity("error");
      setOpenAlert(true);
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {/* First Grid Item */}
        <Grid item xs={3.5}>
          <Box sx={{ backgroundColor: "#EFEFEF", borderRadius: "8px" }}>
            <Box sx={{ display: "flex" }}>
              <ArrowDropDownIcon
                sx={{ marginBottom: 1.1, ml: 1, size: "3px" }}
              />
              <Typography sx={{ fontWeight: 600 }}>
                {t("ExpensesLimitDistribution.showColumns")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                ml: 3,
                overflowX: "auto", // Enables horizontal scrolling
                whiteSpace: "nowrap", // Prevents wrapping of child elements
              }}
            >
              {columns.map((column) => {
                const isDisabled =
                  Object.values(showColumns).filter((value) => value).length <
                    2 && showColumns[column.toLowerCase()];

                return (
                  <Box key={column} sx={{ mb: 1.5 }}>
                    <FormControlLabel
                      control={
                        <Tooltip
                          title={
                            isDisabled
                              ? t("ExpensesLimitDistribution.columnTooltip")
                              : ""
                          }
                          arrow
                          disableInteractive
                        >
                          <span>
                            <Checkbox
                              disabled={isDisabled}
                              checked={showColumns[column.toLowerCase()]}
                              onChange={(e) =>
                                handleColumnChange(
                                  column.toLowerCase(),
                                  e.target.checked
                                )
                              }
                            />
                          </span>
                        </Tooltip>
                      }
                      label={column}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "16px",
                          fontWeight: 400,
                        },
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Grid>

        {/* Second Grid Item */}
        <Grid item xs={8.5}>
          <Box sx={{ backgroundColor: "#EFEFEF", borderRadius: "8px" }}>
            <Box sx={{ display: "flex" }}>
              <ArrowDropDownIcon sx={{ marginBottom: 1.1 }} />
              <Typography sx={{ fontWeight: 600 }}>
                {t("ExpensesLimitDistribution.showHeaders")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              {/* Header checkboxes */}
              <Box
                sx={{
                  flex: 1,
                  overflowX: "auto",
                  display: "flex",
                  gap: 1,
                  ml: 1,
                }}
              >
                {activeHeaders &&
                  activeHeaders.length > 0 &&
                  activeHeaders.map((header) => {
                    // Calculate the count of selected headers
                    const selectedHeadersCount = activeHeaders.filter(
                      (h) => h.isHeader
                    ).length;

                    // Determine if the checkbox should be disabled
                    const isDisabled =
                      selectedHeadersCount < 2 && header.isHeader;

                    return (
                      <Box
                        item
                        key={header.ewtpExpenseswith3rdPartiesId}
                        sx={{
                          flexShrink: 0, // Prevents items from shrinking
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Tooltip
                              title={
                                isDisabled
                                  ? t("ExpensesLimitDistribution.headerTooltip")
                                  : ""
                              }
                              arrow
                            >
                              <span>
                                {" "}
                                {/* Wrapper for Tooltip to work on disabled elements */}
                                <Checkbox
                                  checked={header.isHeader}
                                  disabled={isDisabled}
                                  onChange={() =>
                                    handleExpensesHeaderChange(header)
                                  }
                                />
                              </span>
                            </Tooltip>
                          }
                          label={header.ewtpExpenseswith3rdPartiesDescription}
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "16px",
                              fontWeight: 400,
                            },
                          }}
                        />
                      </Box>
                    );
                  })}
              </Box>
              {/* Manage Items button */}
              {isTopHierarchyNode && (
                <Box
                  sx={{
                    width: "20%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mr: 2, mb: 2 }}
                    onClick={handleModalOpen}  
                  >
                    {t("ExpensesLimitDistribution.ManageItemsButton")}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 10, mt: 1 }}>
        <Grid item xs={3.5}>
          <StyledTableContainer component={Paper}>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <SubHeaderCell
                    colSpan={4}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      padding: "25px",
                    }}
                  ></SubHeaderCell>
                </TableRow>
                <TableRow>
                  <SubHeaderCell
                    colSpan={4}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      padding: "26px",
                    }}
                  ></SubHeaderCell>
                </TableRow>
                <TableRow>
                  <SecondaryHeaderCell colSpan={4}>
                    {t("ExpensesLimitDistribution.hnSearchField")}
                  </SecondaryHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {childNodes.map((row, index) => (
                  <TableRow key={index}>
                    <DataCell colSpan={4}>{row.label}</DataCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TotalCell colSpan={4}>
                    {t("ExpensesLimitDistribution.totals")}
                  </TotalCell>
                </TableRow>
              </TableBody>
            </StyledTable>
          </StyledTableContainer>
        </Grid>

        {/* //New Table */}
        <Grid item xs={8.5}>
          <StyledTableContainer
            component={Paper}
            sx={{
              borderRadius: "8px",

              overflowX: "auto",
              maxHeight: "510px",
              pb: "20px",
              "&::-webkit-scrollbar": {
                width: "12px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
                borderRadius: "6px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#555",
              },
            }}
          >
            <StyledTable>
              <TableHead>
                <TableRow>
                  {activeHeaders
                    .filter((header) => header.isHeader === true)
                    .map((row, index) => (
                      <HeaderCell
                        colSpan={Math.max(
                          2,
                          1 + Object.values(showColumns).filter(Boolean).length
                        )}
                      >
                        {row.ewtpExpenseswith3rdPartiesDescription}
                      </HeaderCell>
                    ))}
                </TableRow>
                {/* second row */}
                <TableRow>
                  {activeHeaders
                    .filter((header) => header.isHeader === true)
                    .map((header) =>
                      expensesTotal
                        .filter(
                          (item) =>
                            item.expensesWith3rdPartyGroupDescription ===
                            header.ewtpExpenseswith3rdPartiesDescription
                        )
                        .map((investment) => (
                          <SubHeaderCell
                            colSpan={Math.max(
                              2,
                              1 +
                                Object.values(showColumns).filter(Boolean)
                                  .length
                            )}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%", // Full width for inner content
                              }}
                            >
                              <div style={{ fontWeight: 600 }}>
                                {t(
                                  "ExpensesLimitDistribution.available/target"
                                )}
                              </div>

                              <span style={{ marginLeft: "20px" }}>
                                <>
                                  <span
                                    style={{
                                      fontWeight: 700,
                                      fontSize: "18px",
                                    }}
                                  >
                                    {investment.available}
                                  </span>{" "}
                                  / {investment.totalTarget}
                                </>
                              </span>
                            </Box>
                          </SubHeaderCell>
                        ))
                    )}
                </TableRow>
                {/* Third Row */}
                <TableRow>
                  {activeHeaders
                    .filter((header) => header.isHeader === true)
                    .map((row, index) =>
                      ["Target", "Distributed", "Consumed", "Balance"]
                        .filter((header) => {
                          const key = header.toLowerCase(); // Convert to lowercase to match state keys
                          return key === "target" || showColumns[key]; // Always show 'Target', filter others based on state
                        })
                        .map((header) => (
                          <SecondaryHeaderCell
                            key={header}
                            style={{
                              borderLeft:
                                header === "Target"
                                  ? "none"
                                  : "5px solid white",
                              borderRight: "10px solid white",
                              //borderTopRightRadius: "11px"
                            }}
                          >
                            {header}
                          </SecondaryHeaderCell>
                        ))
                    )}
                </TableRow>
              </TableHead>
              <TableBody>
                {childNodes.map((child) => (
                  <TableRow key={child.value}>
                    {activeHeaders
                      .filter((header) => header.isHeader === true)
                      .map((header) => {
                        const rows = tableData.filter(
                          (data) =>
                            data.importHierarchyId === child.value &&
                            data.expensesWith3rdPartyGroupDescription ===
                              header.ewtpExpenseswith3rdPartiesDescription
                        );

                        return rows.map((row, rowIndex) => {
                          // console.log("ROWDATA", row); // Moved outside JSX for debugging

                          return [
                            "target",
                            "distributed",
                            "consumed",
                            "balance",
                          ].map((key, i) => {
                            const value = row[key]; // Access the corresponding value

                            if (key === "target") {
                              // Render "target" as a TextField
                              return (
                                // <DataCell >
                                <StyledTextField
                                  type="number"
                                  key={`${rowIndex}-${i}`}
                                  fullWidth
                                  variant="outlined"
                                  value={
                                    value === 0
                                      ? "0" // Show zero explicitly
                                      : /^\d+(\.\d+)?$/.test(value)
                                      ? value
                                          .toString()
                                          .replace(/^0+(?=\d)/, "") // Remove leading zeros for whole numbers
                                      : value
                                  }
                                  sx={{
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                      {
                                        display: "none",
                                      },
                                  }}
                                  onKeyDown={(e) => {
                                    if (
                                      e.key === "e" ||
                                      e.key === "E" ||
                                      e.key === "-" ||
                                      e.key === "+"
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    // Validate and handle changes for "target"
                                    if (
                                      /^\d{0,8}(\.\d{0,2})?$/.test(inputValue)
                                    ) {
                                      handleTargetChange(
                                        parseFloat(inputValue) || 0,
                                        child.value,
                                        header.ewtpExpenseswith3rdPartiesDescription
                                      ); // Update the "target" value
                                    }
                                  }}
                                />
                                // </DataCell>
                              );
                            } else {
                              // Render other values as plain text
                              if (!showColumns[key]) return null;
                              return (
                                <DataCell
                                  key={`${rowIndex}-${i}`}
                                  sx={{
                                    fontWeight:
                                      key !== "target" ? 600 : "normal",
                                  }}
                                >
                                  {value?.toLocaleString()}
                                </DataCell>
                              );
                            }
                          });
                        });
                      })}
                  </TableRow>
                ))}
                <TableRow>
                  {activeHeaders
                    .filter((header) => header.isHeader === true)
                    .map((item) =>
                      expensesTotal
                        .filter(
                          (total) =>
                            total.expensesWith3rdPartyGroupDescription ===
                            item.ewtpExpenseswith3rdPartiesDescription
                        )
                        .map((itemTotal) => (
                          <>
                            <TotalCell>{itemTotal.totalTarget}</TotalCell>
                            {showColumns.distributed && (
                              <TotalCell>
                                {itemTotal.totalDistributed}
                              </TotalCell>
                            )}
                            {showColumns.consumed && (
                              <TotalCell>{itemTotal.totalConsumed}</TotalCell>
                            )}
                            {showColumns.balance && (
                              <TotalCell>{itemTotal.totalBalance}</TotalCell>
                            )}
                          </>
                        ))
                    )}
                </TableRow>
              </TableBody>
            </StyledTable>
          </StyledTableContainer>
        </Grid>
      </Grid>

      <Divider
        sx={{
          width: "100%", // Ensures the divider spans the full width
          marginBottom: 1, // Adds spacing between the divider and the button
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          sx={{
            padding: "6px 16px",
            borderRadius: "4px",
            // right: "1rem", // Aligns to the right bottom corner
            // bottom: "1rem", // Position it at the bottom
            marginBottom: 4,
            backgroundColor: "#EF7D00",
            color: "white",
            "&:hover": {
              backgroundColor: "#EF7D00",
            },
          }}
          startIcon={<img src={Save} alt="Right Arrow" />}
          onClick={handleSave}
          variant="contained"
        >
          {t("ExpensesLimitDistribution.saveButton")}
        </Button>
      </Box>

      <Modal open={manageItemsModal} onClose={handleModalClose}>
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "929px",
            margin: "auto",
            mt: 2,
            maxHeight: "90vh", // Prevent modal from exceeding viewport height
            display: "flex",
            flexDirection: "column", // Ensures the layout respects natural flow
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              // height: "20vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
              color: "#fff",
              pt: 2,
              pb: 1,
            }}
          >
            {t("ExpensesLimitDistribution.modalTitle")}
          </Box>
          <Box
            sx={{
              background: "#EFEFEF",
              borderRadius: "10px",
              padding: "1rem",
              height: "110px",
              margin: 2,
            }}
          >
            <Box sx={{ display: "flex" }}>
              <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} />
              <Typography>{t("filtersLable")}</Typography>
            </Box>
            <TextField
              label={t("ExpensesLimitDistribution.description")}
              value={headerListSearch}
              onChange={handleHeaderListSearch}
              size="medium"
              fullWidth={true}
              variant="outlined"
              type="text"
              placeholder="-"
              InputLabelProps={{ shrink: true }}
              sx={{
                "& .MuiInputBase-input::placeholder": {
                  opacity: 1,
                },
              }}
            />
          </Box>
          <Box
            sx={{
              flex: 1, // Allows the scrollable area to take up remaining space
              overflowY: "auto", // Enables scrolling  // Enable vertical scrolling
              overflowX: "hidden", // Prevent horizontal scrolling
              padding: "1rem",
              margin: 1,
            }}
          >
            {filteredHeaderList && filteredHeaderList.length > 0 ? (
              filteredHeaderList.map((row) => (
                <Box
                  item
                  key={row.ewtpExpenseswith3rdPartiesId}
                  sx={{
                    background: "#EFEFEF",
                    borderRadius: "10px",
                    padding: "1rem",
                    margin: 1,
                  }}
                >
                   {activeHeaders.length === 1 && row.inHeaderList ? (
                  <FormControlLabel
                  
                    control={
                      <>
                        <Checkbox
                        checked={row.inHeaderList}
                        onChange={() =>
                          handleExpensesListChange(row, !row.inHeaderList)
                        }
                        disabled
                      />
                    <Tooltip
                      title={t("ExpensesLimitDistribution.columnTooltip")}
                      arrow
                      disableInteractive
                    ></Tooltip>
                      </>  
                    }
                    label={row.ewtpExpenseswith3rdPartiesDescription}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "16px",
                        fontWeight: 400,
                      },
                    }}
                  />
                   ):
                   (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={row.inHeaderList}
                          onChange={() =>
                            handleExpensesListChange(row, !row.inHeaderList)
                          }
                        />
                      }
                      label={row.ewtpExpenseswith3rdPartiesDescription}
                    />
                  )}
                </Box>
              ))
            ) : (
              <Typography>{t("ExpensesLimitDistribution.noData")}</Typography>
            )}
          </Box>
          <Box
            sx={{
              position: "sticky", // Make it stick within the modal
              bottom: 0, // Stick to the bottom of the container
              // background: "#fff", // Ensure it blends with the modal background
              padding: "1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "60px",
              zIndex: 2, // Ensure it stays above the content
            }}
          >
            <Button
              onClick={handleModalClose}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              {t("ExpensesLimitDistribution.close")}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={removeExpensesModal}
        onClose={() => setRemoveExpensesModal(false)}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "560px",
            margin: "auto",
            mt: 25,
            height: "200px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
              color: "#fff",
            }}
          >
            {t("ExpensesLimitDistribution.removeItemConfirm")}
          </Box>

          <Box sx={{ p: 2 }}>
            <Typography
              variant="body2"
              sx={{ whiteSpace: "pre-wrap", width: "550px", overflowX: "auto" }}
            >
              {t("ExpensesLimitDistribution.removeMessage")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "60px",
              pt: 4,
            }}
          >
            <Button
              onClick={() => setRemoveExpensesModal(false)}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              {t("no")}
            </Button>

            <Button
              onClick={() => {
                handleRemoveExpensesFromModal();
                setRemoveExpensesModal(false);
              }}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              {t("yes")}
            </Button>
            {/* </Box> */}
          </Box>
        </Box>
      </Modal>
    </>
  );
})
export default ExpensesLimitDistributionTable;
