import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Grid,
  Typography,
  Modal,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Layout from "components/structure/Layout";
import CuringUnitService from "services/CuringUnitService";
import { SnackbarContext } from "context/snackBarContext";
import arrowLeft from "assets/arrowLeft.svg";
import RedSwitch from "utils/RedSwitch";
import Save from "assets/SaveNew.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import AdditionalCuringUnitData from "./AdditionalData";

export default function AddCuringUnit() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    curingId,
    curingUnitName,
    status,
    capacity,
    squareMeters,
    capacityPerSquareMeter,
    numberOfDockyards,
    furnacePosition,
    shedType,
    coverScreen,
    sideClosure,
    countryId,
    isDelete,
    curingTypeId,
    curingTypeName,
    furnaceTypeId,
    curingDeviceId,
    languageId,
  } = location?.state?.row || {};

  const { edit } = location.state || {};
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [furnaceType, setFurnaceType] = useState(null);
  const [curingController, setCuringController] = useState(null);
  const [options, setOptions] = useState({
    curingTypeOptions: [],
    furnaceTypeOptions: [],
    curingControllerOptions: [],
    furnacePositionOptions: [],
    shedTypeOptions: [],
    coverScreenOptions: ["Yes", "No"],
    sideClosureOptions: ["Yes", "No"],
  });

  const [values, setValues] = useState({
    curingId: curingId || "",
    curingUnitName: curingUnitName || "",
    curingTypeName: curingTypeName || "",
    squareMeters: squareMeters === 0 ? 0 : squareMeters ? squareMeters : "",
    capacity: capacity === 0 ? 0 : capacity ? capacity : "",
    capacityPerSquareMeter:
      capacityPerSquareMeter === 0
        ? 0
        : capacityPerSquareMeter
        ? capacityPerSquareMeter
        : "",
    numberOfDockyards:
      numberOfDockyards === 0 ? 0 : numberOfDockyards ? numberOfDockyards : "",
    furnaceType: furnaceType || null,
    curingController: curingController || null,
    furnacePosition: furnacePosition || null,
    shedType: shedType || null,
    coverScreen: coverScreen || null,
    sideClosure: sideClosure || null,
    curingTypeId: curingTypeId || "",
    furnaceTypeId: furnaceTypeId || "",
    curingDeviceId: curingDeviceId || "",
    status: status === true ? true : false,
    countryId: countryId || "9b72d29c-1555-480b-ac8b-3b10f383b5f6",
    languageId: languageId || "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  });

  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpenModal = () => {
    edit !== false ? setOpen(true) : navigate("/curingUnit");
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/curingUnit");
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "curingTypeName") {
      const selectedCuringType = options.curingTypeOptions.find(
        (curingType) => curingType.curingTypeName === value
      );
      setValues((prevValues) => ({
        ...prevValues,
        curingTypeName: value,
        curingTypeId: selectedCuringType ? selectedCuringType.curingTypeId : "",
      }));
    }

    if (name === "furnaceType") {
      const selectedFurnaceType = options.furnaceTypeOptions.find(
        (furnaceType) => furnaceType.furnaceType === value
      );
      setValues((prevValues) => ({
        ...prevValues,
        furnaceTypeId: selectedFurnaceType
          ? selectedFurnaceType.furnaceTypeid
          : "",
        furnaceType: value,
      }));
    }

    if (name === "curingController") {
      const selectedCuringController = options.curingControllerOptions.find(
        (curingController) => curingController.curingController === value
      );
      setValues((prevValues) => ({
        ...prevValues,
        curingDeviceId: selectedCuringController
          ? selectedCuringController.curingDeviceId
          : "",
        curingController: value,
      }));
    }
  };

  useEffect(() => {
    fetchCuringTypes();
  }, []);

  const fetchCuringTypes = () => {
    CuringUnitService.getCuringTypes()
      .then((res) => {
        const curingTypes =
          res?.data?.response?.curingTyoes.map((curingType) => ({
            curingTypeName: curingType.curingType,
            curingTypeId: curingType.ctrId,
          })) || [];
        setOptions((prevOptions) => ({
          ...prevOptions, // Spread previous options to retain other values
          curingTypeOptions: curingTypes, // Update only furnaceTypeOptions
        }));
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const validateInputs = (schemaValidationErrors, values) => {
    const specialCharPattern = /^[a-zA-Z0-9 ]+$/;

    // Helper function to validate a single field
    const validateField = (fieldName, fieldValue, displayName) => {
      const trimmedValue =
        typeof fieldValue === "string"
          ? fieldValue?.trim()
          : parseFloat(fieldValue, 10);

      if (!trimmedValue) {
        schemaValidationErrors[fieldName] = `${displayName} is required.`;
      } else if (
        !specialCharPattern.test(trimmedValue) &&
        !fieldName in ["shedType", "coverScreen", "sideClosure"]
      ) {
        schemaValidationErrors[
          fieldName
        ] = `No special characters allowed in ${displayName}.`;
      } else if (trimmedValue !== fieldValue) {
        schemaValidationErrors[
          fieldName
        ] = `No leading or trailing spaces allowed in ${displayName}.`;
      } else if (fieldName === "curingUnitName" && trimmedValue.length > 50) {
        schemaValidationErrors[
          fieldName
        ] = `The Character limit cannot exceed more than 50 in ${displayName}.`;
      } else {
        // Clear error if the input is valid
        delete schemaValidationErrors[fieldName];
      }
      // Return the trimmed value or errors
      if (Object.keys(schemaValidationErrors).length > 0) {
        setErrors(schemaValidationErrors);
        return { errors: schemaValidationErrors, value: null };
      }
      return trimmedValue;
    };

    const validateDropdown = (fieldName, fieldValue, displayName) => {
      const trimmedValue = fieldValue?.trim();

      if (!trimmedValue) {
        if (
          values.curingTypeName === "Flue Cured" &&
          ["furnaceType", "curingController", "furnacePosition"].includes(
            fieldName
          )
        ) {
          schemaValidationErrors[fieldName] = `${displayName} is required.`;
        } else if (
          values.curingTypeName === "Air Cured" &&
          ["shedType", "coverScreen", "sideClosure"].includes(fieldName)
        ) {
          schemaValidationErrors[fieldName] = `${displayName} is required.`;
        }
      } else {
        // Clear error if the input is valid
        delete schemaValidationErrors[fieldName];
      }

      // Return the trimmed value or errors
      if (Object.keys(schemaValidationErrors).length > 0) {
        setErrors(schemaValidationErrors);
        console.log("Errors : ", errors);
        return { errors: schemaValidationErrors, value: null };
      }

      return trimmedValue;
    };

    // Validate all fields
    const curingTypeName = validateField(
      "curingTypeName",
      values?.curingTypeName,
      "Curing Type"
    );
    const curingUnitName = validateField(
      "curingUnitName",
      values?.curingUnitName,
      "Curing Unit Name"
    );
    const squareMeters = validateField(
      "squareMeters",
      values?.squareMeters,
      "Square Meters"
    );
    const capacity = validateField("capacity", values?.capacity, "Capacity");
    const capacityPerSquareMeter = validateField(
      "capacityPerSquareMeter",
      values?.capacityPerSquareMeter,
      "Capacity Per Square Meter"
    );
    const numberOfDockyards = validateField(
      "numberOfDockyards",
      values?.numberOfDockyards,
      "Number of Dockyards"
    );

    const furnaceType = validateDropdown(
      "furnaceType",
      values?.furnaceType,
      "Furnace Type"
    );
    const curingController = validateDropdown(
      "curingController",
      values?.curingController,
      "Curing Controller"
    );
    const furnacePosition = validateDropdown(
      "furnacePosition",
      values?.furnacePosition,
      "Furnace Position"
    );
    const shedType = validateDropdown(
      "shedType",
      values?.shedType,
      "Shed Type"
    );
    const coverScreen = validateDropdown(
      "coverScreen",
      values?.coverScreen,
      "Cover Screen"
    );
    const sideClosure = validateDropdown(
      "sideClosure",
      values?.sideClosure,
      "Side Closure"
    );

    // Return the trimmed and validated values as an object
    return {
      curingTypeName,
      curingUnitName,
      squareMeters,
      capacity,
      capacityPerSquareMeter,
      numberOfDockyards,
      furnaceType,
      curingController,
      furnacePosition,
      shedType,
      coverScreen,
      sideClosure,
    };
  };

  const submitCuringUnit = async (payload, isUpdate = false) => {
    try {
      const response = isUpdate
        ? await CuringUnitService.updateCuringUnit(payload)
        : await CuringUnitService.createCuringUnit(payload);

      if (response.status === 201) {
        setSeverity("success");
        isUpdate
          ? setMessage("RECORD UPDATED SUCCESSFULLY!")
          : setMessage("RECORD SAVED SUCCESSFULLY!");
        setOpenAlert(true);
        navigate("/curingUnit");
      }
    } catch (error) {
      if (error.response?.status === 409) {
        setSeverity("error");
        setMessage(error.response.data.message);
      } else if (error.response?.status === 500) {
        setSeverity("error");
        setMessage(
          `The ${values.curingUnitName} already exists. Please provide a unique Curing Unit Name`
        );
      } else {
        setSeverity("error");
        setMessage(isUpdate ? "Update failed" : "Submission Failed");
      }
      setOpenAlert(true);
    }
  };

  const handleSubmission = async (event, isUpdate = false) => {
    event.preventDefault();
    const schemaValidationErrors = {};
    const trimmedValues = validateInputs(schemaValidationErrors, values);
    if (Object.keys(schemaValidationErrors).length > 0) {
      setErrors(schemaValidationErrors);
      return;
    }
    const payload = {
      ...trimmedValues, // Include all trimmed values
      curingTypeId: values.curingTypeId,
      furnaceTypeId:
        values.curingTypeName === "Flue Cured" ? values.furnaceTypeId : null,
      curingDeviceId:
        values.curingTypeName === "Flue Cured" ? values.curingDeviceId : null,
      furnacePosition:
        values.curingTypeName === "Flue Cured" ? values.furnacePosition : null,
      shedType: values.curingTypeName === "Air Cured" ? values.shedType : null,
      coverScreen:
        values.curingTypeName === "Air Cured" ? values.coverScreen : null,
      sideClosure:
        values.curingTypeName === "Air Cured" ? values.sideClosure : null,
      countryId: values.countryId,
      status: values?.status,
      languageId: values.languageId,
      isDelete: false,
      modifiedDateTimeUTC: new Date().toISOString(),
      ...(isUpdate ? { curingId: values.curingId } : { isDelete: false }), // Include `curingId` for updates
    };
    delete payload.furnaceType;
    delete payload.curingController;
    delete payload.curingTypeName;
    await submitCuringUnit(payload, isUpdate);
    delete setErrors([]);
  };

  const handleSubmit = (event) => handleSubmission(event, false);
  const handleUpdate = (event) => handleSubmission(event, true);

  const renderAdditionalData = () => {
    if (
      values.curingTypeName === "Air Cured" ||
      values.curingTypeName === "Flue Cured"
    ) {
      return (
        <AdditionalCuringUnitData
          errors={errors}
          values={values}
          setValues={setValues}
          handleChange={handleChange}
          options={options}
          setOptions={setOptions}
          edit={edit}
        />
      );
    }
    return null;
  };

  return (
    <Layout title={t("curingUnitRegistration.addPageTitle")} background="#ffffff">
      <Box
        sx={{
          minHeight: "10%",
          flexGrow: 1,
          mt: "20px",
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box
            sx={{
              padding: "1rem",
              paddingBottom: 0,
              bgcolor: "#EFEFEF",
              borderRadius: "5px",
            }}
          >
            <Grid container rowSpacing={0} columnSpacing={3}>
              {/* Curing Unit Name */}
              <Grid item xs={4}>
                <TextField
                  name="curingUnitName"
                  label={t("curingUnitRegistration.curingUnitName")}
                  value={values?.curingUnitName}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  helperText={
                    errors.curingUnitName ? errors.curingUnitName : " "
                  }
                  disabled={edit === false}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      ...(edit === false && {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #0000003B",
                        },
                      }),
                    },
                  }}
                  error={!!errors.curingUnitName}
                />
              </Grid>

              {/* Curing Type Dropdown */}
              <Grid item xs={3}>
                <TextField
                  select
                  name="curingTypeName"
                  label={t("curingUnitRegistration.curingType")}
                  value={values.curingTypeName || "Select an Option"}
                  onChange={handleChange}
                  fullWidth
                  helperText={
                    errors.curingTypeName ? errors.curingTypeName : " "
                  }
                  disabled={edit === false}
                  error={!!errors.curingTypeName}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      ...(edit === false && {
                        ".MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #0000003B",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #0000003B",
                        },
                      }),
                    },
                  }}
                  InputProps={{
                    endAdornment: values.curingTypeName && edit !== false && (
                      <InputAdornment
                        position="end"
                        sx={{ paddingRight: "10px" }}
                      >
                        <IconButton
                          size="small"
                          onClick={() => {
                            setValues({ ...values, curingTypeName: "" });
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="Select an Option" sx={{ display: "none" }}>
                    {t("selectoption")}
                  </MenuItem>
                  {options.curingTypeOptions.map((curing, index) => (
                    <MenuItem key={index} value={curing.curingTypeName}>
                      {curing.curingTypeName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={3}>
                <TextField
                  name="squareMeters"
                  label={t("curingUnitRegistration.sqmeters")}
                  value={values.squareMeters}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Regex to match NUMBER(10,2)
                    if (/^\d{0,8}(\.\d{0,2})?$/.test(value)) {
                      handleChange(e); // Update state only if valid
                    }
                  }}
                  inputProps={{
                    inputMode: "numeric", // For better mobile keyboard support
                    pattern: "[0-9]*", // HTML5 validation pattern
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      ...(edit === false && {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #0000003B",
                        },
                      }),
                    },
                  }}
                  variant="outlined"
                  disabled={edit === false}
                  fullWidth
                  helperText={errors.squareMeters ? errors.squareMeters : " "}
                  error={!!errors.squareMeters}
                />
              </Grid>

              {/* Status Switch */}
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <FormLabel
                    sx={{
                      color: "#3B454F !important",
                      fontWeight: "600",
                    }}
                  >
                    {t("status")}
                  </FormLabel>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ mr: 2 }}>{t("Inactive")}</Typography>
                    <FormControlLabel
                      control={
                        <RedSwitch
                          checked={values.status}
                          onChange={handleChange}
                          disabled={edit === false}
                          name="status"
                        />
                      }
                      label={
                        <Typography paddingLeft="10px">
                          {t("Active")}
                        </Typography>
                      }
                      labelPlacement="end"
                    />
                  </Box>
                </FormControl>
              </Grid>

              {/* Capacity */}
              <Grid item xs={4}>
                <TextField
                  name="capacity"
                  label={t("curingUnitRegistration.capacity")}
                  value={values.capacity}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Regex to match NUMBER(10,2)
                    if (/^\d{0,8}(\.\d{0,2})?$/.test(value)) {
                      handleChange(e); // Update state only if valid
                    }
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      ...(edit === false && {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #0000003B",
                        },
                      }),
                    },
                  }}
                  inputProps={{
                    inputMode: "numeric", // For better mobile keyboard support
                    pattern: "[0-9]*", // HTML5 validation pattern
                  }}
                  variant="outlined"
                  disabled={edit === false}
                  fullWidth
                  helperText={errors.capacity ? errors.capacity : " "}
                  error={!!errors.capacity}
                />
              </Grid>

              {/* Capacity per sqm */}
              <Grid item xs={3}>
                <TextField
                  name="capacityPerSquareMeter"
                  label={t("curingUnitRegistration.capacitypersqm")}
                  value={values.capacityPerSquareMeter}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Regex to match NUMBER(10,2)
                    if (/^\d{0,8}(\.\d{0,2})?$/.test(value)) {
                      handleChange(e); // Update state only if valid
                    }
                  }}
                  inputProps={{
                    inputMode: "numeric", // For better mobile keyboard support
                    pattern: "[0-9]*", // HTML5 validation pattern
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      ...(edit === false && {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #0000003B",
                        },
                      }),
                    },
                  }}
                  variant="outlined"
                  disabled={edit === false}
                  fullWidth
                  helperText={
                    errors.capacityPerSquareMeter
                      ? errors.capacityPerSquareMeter
                      : " "
                  }
                  error={!!errors.capacityPerSquareMeter}
                />
              </Grid>

              {/* Number of Dockyards */}
              <Grid item xs={3}>
                <TextField
                  name="numberOfDockyards"
                  label={t("curingUnitRegistration.noOfDockyards")}
                  value={values.numberOfDockyards}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Regex to allow only digits
                    if (
                      value === "" ||
                      (/^\d*$/.test(value) && parseInt(value, 10) <= 999)
                    ) {
                      handleChange(e); // Update state if valid or clearing
                    }
                  }}
                  inputProps={{
                    inputMode: "numeric", // For better mobile keyboard support
                    pattern: "[0-9]*", // HTML5 validation pattern
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      ...(edit === false && {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #0000003B",
                        },
                      }),
                    },
                  }}
                  variant="outlined"
                  disabled={edit === false}
                  fullWidth
                  helperText={
                    errors.numberOfDockyards ? errors.numberOfDockyards : " "
                  }
                  error={!!errors.numberOfDockyards}
                />
              </Grid>
            </Grid>
          </Box>

          {renderAdditionalData()}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              position: "absolute",
              width: "97%",
              bottom: 0,
            }}
          >
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: "6px 16px",
                borderRadius: "4px",
                position: "absolute",
                left: "1rem",
                bottom: "1rem",
                marginBottom: "50px",
              }}
              variant="contained"
              onClick={handleOpenModal}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
            >
              {edit === false ? t("back") : t("cancel")}
            </Button>

            <Modal
              open={open}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  width: "585px",
                  margin: "auto",
                  mt: 25,
                  height: "200px",
                }}
              >
                <Box
                  sx={{
                    background: "#102A62",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                  }}
                >
                  <Typography
                    sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                  >
                    {t("cancelTitle")}
                  </Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, fontWeight: 400 }}
                  >
                    {t("cancelMessage")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    mb: 1,
                  }}
                >
                  <Button
                    onClick={handleCloseModal}
                    sx={{
                      background: "#737D86",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#737D86" },
                    }}
                  >
                    {t("no")}
                  </Button>
                  <Button
                    onClick={handleDeleteConfirm}
                    sx={{
                      background: "#004F9F",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#004F9F" },
                    }}
                  >
                    {t("yes")}
                  </Button>
                </Box>
              </Box>
            </Modal>

            {edit !== false && (
              <Button
                sx={{
                  padding: "6px 16px",
                  borderRadius: "4px",
                  position: "absolute", // Fix the button
                  right: "1rem", // Aligns to the right bottom corner
                  bottom: "1.5rem", // Position it at the bottom
                  marginBottom: "40px",
                  backgroundColor: "#EF7D00",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#EF7D00",
                  },
                }}
                startIcon={<img src={Save} alt="Right Arrow" />}
                onClick={!edit ? handleSubmit : handleUpdate}
                variant="contained"
              >
                {t("save")}
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Layout>
  );
}
