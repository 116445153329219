import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Modal,
  InputAdornment,
  CircularProgress,
  Autocomplete,
  FormControl,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FarmerSampleEstimateTable from "./FarmerSampleEstimateTable";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useTranslation } from "react-i18next";
import iconSearch from "assets/iconSearchs.svg";
import DefineFarmerSampleEstimateService from "services/DefineFarmerSampleEstimateService";
import uploadIcon from "../../../../../src/assets/ImportIcon.svg";

const FarmerSampleEstimate = () => {
  const [estimateData, setEstimateData] = useState([]);
  const [filEstimateData, setFilEstimateData] = useState([]);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [open, setOpen] = useState(false);
  const [cropSeason, setCropSeason] = useState("");
  const [hierarchicalNode, setHierarchicalNode] = useState("");
  const [cropSeasonOptions, setCropSeasonOptions] = useState([]);
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [showFiltersGrid, setShowFiltersGrid] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [cropSeasonId, setCropSeasonId] = useState("");
  const [hierarchicalNodeId, setHierarchicalNodeId] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [geneticTypes, setGeneticTypes] = useState([
    "Virginia",
    "Burley",
    "Maryland",
  ]);
  const [farmerQuantity, setFarmerQuantity] = useState([
    "S1",
    "S2",
    "S4",
    "S5",
  ]);
  const fileInputRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      const [cropSeasonResponse, hierarchyResponse] = await Promise.all([
        DefineFarmerSampleEstimateService.getCropSeasonAPI(),
        DefineFarmerSampleEstimateService.getHierarchyNodeAPI(),
      ]);

      setCropSeasonOptions(
        cropSeasonResponse.data.response.map((item) => ({
          label: item.cropYear.toString(),
          value: item.cropYear.toString(),
          cropSeasonId: item.cropYearUid, // Assuming there's an id field
        }))
      );

      // Flatten hierarchy tree for simplicity. You might want to implement a more sophisticated approach.
      const flattenHierarchy = (node, result = [], level = 0) => {
        result.push({ label: node.locationName, value: node.id, level });
        node.childHierarchies.forEach((child) =>
          flattenHierarchy(child, result, level + 1)
        );
        return result;
      };
      setHierarchyOptions(flattenHierarchy(hierarchyResponse.data.response));
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };

  const CallFarmerSampleEstimateApi = (pageNumber, size) => {
    DefineFarmerSampleEstimateService.getFarmerSampleEstimate({
      pageNumber,
      size,
    })
      .then((res) => {
        const estimateResults = res?.data?.response?.soilAnalysis || [];
        console.log(estimateResults);
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setEstimateData(estimateResults);
        setFilEstimateData(estimateResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (estimateResults.length === 0 && searchKeyword.length > 0) {
          setMessage("No records found.");
          setOpenAlert(true);
          setSeverity("info");
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setEstimateData([]);
        setFilEstimateData([]);
        setTotalItems(0);
      });
  };

  const callSearchAPI = (
    pageNumber,
    size,
    farmerInput,
    soilAnalysisDescription,
    saStatus
  ) => {
    let query = {
      pageNumber,
      size,
      farmerInput,
      soilAnalysisDescription,
      saStatus,
    };

    DefineFarmerSampleEstimateService.searchFarmerSampleEstimate(query)
      .then((res) => {
        const estimateResults = res?.data?.response?.soilAnalysis || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setEstimateData(estimateResults);
        setFilEstimateData(estimateResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (estimateResults.length === 0 && searchKeyword.length > 0) {
          setMessage("No records found.");
          setOpenAlert(true);
          setSeverity("info");
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setEstimateData([]);
        setFilEstimateData([]);
        setTotalItems(0);
      });
  };

  const downloadTemplate = () => {
    DefineFarmerSampleEstimateService.downloadTemplate()
      .then((response) => {
        // Create a blob from the response data
        const blob = new Blob([response.data], {
          type: "text/csv",
        });

        // Create a link element and trigger download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "farmer_sample_template.csv");
        document.body.appendChild(link);
        link.click();

        // Clean up
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        setMessage(
          error?.response?.data?.message || "Error downloading template"
        );
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      await handleFileUpload(file);
    }
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (file) => {
    try {
      await DefineFarmerSampleEstimateService.importFarmerSample(
        file,
        "5d68bc83-3635-4a2b-a0df-1199b0b75c6e", // countryId
        "3fa85f64-5717-4562-b3fc-2c963f66afa6", // languageId
        "Harsha", // createdByUser
        "Harsha" // modifiedByUser
      ).then((response) => {
        let fileData = {
          fileUrl: response.data.response[file.fileName],
          fileName: file.fileName,
          country: "BRA",
          mimeType: file.mimeType,
        };
        setUploadedFiles((oldfiles) => [...oldfiles, fileData]);
      });

      setMessage("Import successful. Farmer data has been saved.");
      setOpenAlert(true);
      setSeverity("success");
      handleCloseModal();
      CallFarmerSampleEstimateApi(currentPage, itemsPerPage);
    } catch (error) {
      setMessage(
        error?.response?.data?.message ||
          "Invalid file format. Please use the correct CSV template."
      );
      setOpenAlert(true);
      setSeverity("error");
    }
  };

  useEffect(() => {
    CallFarmerSampleEstimateApi(currentPage, itemsPerPage);
  }, [currentPage, itemsPerPage]);

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    if (searchKeyword) {
      callSearchAPI(pageNumber - 1, itemsPerPage, searchKeyword);
    } else {
      CallFarmerSampleEstimateApi(pageNumber - 1, itemsPerPage);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files[0];
    if (file && file.type === "text/csv") {
      setSelectedFile(file);
      await handleFileUpload(file);
    } else {
      setMessage("Please upload a CSV file");
      setOpenAlert(true);
      setSeverity("error");
    }
  };

  const handleDeletedRecord = () => {
    // Recalculate total items and adjust pagination if needed
    const newTotalItems = totalItems - 1;
    setTotalItems(newTotalItems);

    // If the current page would be empty, go back one page
    if (estimateData.length <= 1 && currentPage > 0) {
      setCurrentPage(currentPage - 1);
      CallFarmerSampleEstimateApi(currentPage - 1, itemsPerPage);
    } else {
      // Refresh current page
      CallFarmerSampleEstimateApi(currentPage, itemsPerPage);
    }
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t("defineFarmerSampleEstimatePositionPlant.pageTitle")}
      background="#ffffff"
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: "10px",
          padding: "1rem",
          paddingTop: "0.1rem",
          paddingBottom: "0.6rem",
          height: "100px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <ArrowDropDownIcon sx={{ marginRight: 0.2, marginBottom: 1.1 }} />
          <Typography sx={{ fontWeight: "600", color: "#666666" }}>
            {t("filtersLable")}
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={3} style={{ paddingLeft: "20px" }}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  cropSeasonOptions.find(
                    (option) => option.value === cropSeason
                  ) || null
                }
                onChange={(event, newValue) => {
                  if (newValue) {
                    setCropSeason(newValue.value);
                    setCropSeasonId(newValue.cropSeasonId);
                  } else {
                    setCropSeason("");
                    setCropSeasonId("");
                  }
                }}
                options={cropSeasonOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t(
                      "defineFarmerSampleEstimatePositionPlant.cropYear"
                    )}
                    placeholder="Select an option"
                    InputProps={{
                      ...params.InputProps,
                      style: { color: "black" },
                    }}
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 0.23)",
                        },
                      },
                      "& .MuiInputBase-input::placeholder": {
                        fontFamily: "Montserrat",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "left",
                        color: "black",
                        opacity: 1,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  hierarchyOptions.find(
                    (option) => option.value === hierarchicalNode
                  ) || null
                }
                onChange={(event, newValue) => {
                  if (newValue) {
                    setHierarchicalNode(newValue.value);
                    setHierarchicalNodeId(newValue.value);
                    setShowFiltersGrid(newValue.level === 0);
                  } else {
                    setHierarchicalNode("");
                    setHierarchicalNodeId("");
                    setShowFiltersGrid(false);
                  }
                }}
                options={hierarchyOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t(
                      "defineFarmerSampleEstimatePositionPlant.hierarchicalNode"
                    )}
                    placeholder="Select an option"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={iconSearch}
                            alt="search"
                            style={{
                              width: "32px",
                              height: "32px",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 0.23)",
                        },
                      },
                      "& .MuiInputBase-input::placeholder": {
                        fontFamily: "Montserrat",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "left",
                        color: "black",
                        opacity: 1,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={5} textAlign={"right"}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                sx={{
                  borderRadius: 1,
                  minWidth: 120,
                  minHeight: 44,
                  background: "#004F9F",
                  marginRight: "10px",
                  padding: "0px 95px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative", // For hover effects
                  "&:hover": {
                    background: "#002A5C", // Dark blue on hover
                  },
                  "&:hover .hover-image": {
                    opacity: 0, // Hide the image on hover
                  },
                  "&:hover .hover-icon": {
                    opacity: 1, // Show the icon on hover
                  },
                }}
                variant="contained"
                onClick={handleOpenModal}
              >
                {/* AddCircleIcon (hidden initially) */}
                <AddCircleIcon
                  className="hover-icon"
                  sx={{
                    marginRight: "-20px",
                    opacity: 0, // Hidden initially
                    transition: "opacity 0.3s",
                    color: "white",
                    fontSize: "1.5rem",
                  }}
                />

                {/* Image (visible initially) */}
                <img
                  src={uploadIcon}
                  alt=""
                  className="hover-image"
                  style={{
                    marginRight: "10px",
                    transition: "opacity 0.3s",
                  }}
                />

                {/* Button Label */}
                {t("defineFarmerSampleEstimatePositionPlant.import")}
              </Button>

              <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "7px",
                    width: "408px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    minHeight: "420px",
                  }}
                >
                  <Box
                    sx={{
                      background: "#102A62",
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#ffffff",
                        fontSize: "20px",
                        fontWeight: 500,
                      }}
                    >
                      {t("defineFarmerSampleEstimatePositionPlant.modalImport")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      padding: "1rem",
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: "10px !important",
                        fontWeight: 600,
                      }}
                    >
                      FILE LIST:
                    </Typography>
                    <Box
                      sx={{
                        marginLeft: 5,
                        marginRight: 5,
                        marginTop: 2,
                        marginBottom: 2,
                      }}
                    >
                      {uploadedFiles.map((file) => (
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontSize: "13px !important",
                          }}
                        >
                          {file.fileName}
                        </Typography>
                      ))}
                    </Box>
                  </Box>

                  {/* Centering the Icon, Typography, and Button */}
                  <Box
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1, // Space between elements
                    }}
                  >
                    {/* Wrap the icon and text in a Box with a border */}
                    <Box
                      onDragOver={handleDragOver}
                      onDrop={handleDrop}
                      sx={{
                        border: "4px solid #ACB4BA",
                        borderRadius: "5px",
                        p: 2,
                        paddingLeft: 4,
                        paddingRight: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <DescriptionOutlinedIcon
                        sx={{ fontSize: 60, color: "grey" }}
                      />

                      <Typography
                        id="modal-modal-description"
                        sx={{ mt: 1, fontWeight: 400, textAlign: "center" }}
                      >
                        {t("defineFarmerSampleEstimatePositionPlant.dragDrop")}
                      </Typography>

                      {/* Add light gray background for "OR" and "BROWSE FILE" */}
                      <Box
                        sx={{
                          background: "#E9EBEE",
                          width: "80%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          p: 1.5,
                          mt: 1, // Margin top for spacing
                        }}
                      >
                        <Typography
                          id="modal-modal-description"
                          sx={{ fontWeight: 400, textAlign: "center" }}
                        >
                          {t("defineFarmerSampleEstimatePositionPlant.or")}
                        </Typography>

                        <Button
                          onClick={handleBrowseClick}
                          sx={{
                            background: "#0E2B63",
                            width: "150px",
                            color: "white",
                            mt: 1,
                            "&:hover": { background: "#0E2B63" },
                          }}
                        >
                          {t(
                            "defineFarmerSampleEstimatePositionPlant.browseFile"
                          )}
                        </Button>
                        <input
                          type="file"
                          hidden
                          ref={fileInputRef}
                          onChange={handleFileSelect}
                          accept=".csv"
                        />
                      </Box>
                    </Box>

                    <Button
                      onClick={downloadTemplate}
                      sx={{
                        background: "#004F9F",
                        width: "181px",
                        color: "#ffffff",
                        fontSize: "12px",
                        mt: 1,
                        "&:hover": { background: "#004F9F" },
                      }}
                    >
                      {t(
                        "defineFarmerSampleEstimatePositionPlant.downloadTemplate"
                      )}
                    </Button>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "30px",
                      mb: 1,
                    }}
                  >
                    <Button
                      onClick={handleCloseModal}
                      sx={{
                        background: "#737D86",
                        width: "100px",
                        color: "#ffffff",
                        "&:hover": { background: "#737D86" },
                      }}
                    >
                      {t("close")}
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: "10px",
          padding: "1rem",
          paddingTop: "0.1rem",
          paddingBottom: "1rem",
          marginTop: "15px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <ArrowDropDownIcon sx={{ marginRight: 0.2, marginBottom: 1.1 }} />
          <Typography sx={{ fontWeight: "600", color: "#666666" }}>
            {t("defineFarmerSampleEstimatePositionPlant.summary")}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "grid",
            gap: "6px",
            columnGap: "18px",
            gridTemplateRows: "repeat(5, 30px)",
            gridTemplateColumns: "300px repeat(5, 140px)",
            overflowX: "auto",
          }}
        >
          {/* Row 1: One large cell spanning 6 columns */}
          <Box
            sx={{
              gridColumn: "span 1",
            }}
          ></Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gridColumn: "span 5",
              background: "#acb4ba",
              borderRadius: "5px",
              width: "772px",
              color: "#666666",
              fontWeight: "bold",
            }}
          >
            Farmer Quantity
          </Box>

          {/* Row 2 to 5: 6 individual cells */}
          {Array.from({ length: geneticTypes.length + 1 }).map(
            (_, rowIndex) => (
              <React.Fragment key={rowIndex}>
                {Array.from({ length: farmerQuantity.length + 2 }).map(
                  (_, colIndex) => (
                    <Box
                      key={colIndex}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "5px",
                        background: rowIndex === 0 ? "#acb4ba" : "white",
                        fontWeight: rowIndex === 0 && "bold",
                        color: "#666666",
                      }}
                    >
                      {colIndex === 0 && rowIndex === 0
                        ? "Genetic Types"
                        : colIndex === 0 && rowIndex !== 0
                        ? geneticTypes[rowIndex - 1] // Render geneticTypes array values in the first row
                        : colIndex === 1 && rowIndex === 0
                        ? "Totals"
                        : colIndex >= 2 && rowIndex === 0
                        ? farmerQuantity[colIndex - 2]
                        : colIndex === 1 && rowIndex !== 0
                        ? 400
                        : colIndex >= 2 && rowIndex !== 0 && 100}
                    </Box>
                  )
                )}
              </React.Fragment>
            )
          )}
        </Box>
      </Box>

      <FarmerSampleEstimateTable
        estimateData={filEstimateData}
        onDelete={handleDeletedRecord}
      />
    </Layout>
  );
};

export default FarmerSampleEstimate;
