import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Typography,
  Modal,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Layout from "components/structure/Layout";
import CuringTypeService from "services/CuringTypeService"; // Assuming you have this service for curing types
import { SnackbarContext } from "context/snackBarContext";
import arrowLeft from "assets/arrowLeft.svg";
import RedSwitch from "utils/RedSwitch";
import Save from "assets/SaveNew.svg";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import CloseIcon from "@mui/icons-material/Close"; // <-- Import CloseIcon for the close button inside TextField
import { useTranslation } from "react-i18next";

export default function AddCuringType() {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    countryId,
    ctrId,
    cultureId,
    cultureName,
    details,
    curingType,
    isActive,
    isDelete,
    languageId,
    modifiedDateTimeUTC,
  } = location?.state?.row || {};
  const { edit } = location.state || {};
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [cultureNames, setCultureNames] = useState([]);

  const [values, setValues] = useState({
    ctrId: ctrId || "",
    curingType: curingType || "",
    cultureName: cultureName || "",
    cultureId: cultureId || "",
    isActive: isActive === true ? true : false,
    countryId: countryId || "9b72d29c-1555-480b-ac8b-3b10f383b5f6",
    languageId: languageId || "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  });

  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpenModal = () => {
    edit === false ? navigate("/curingType") : setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/curingType");
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "cultureName") {
      const selectedCulture = cultureNames.find(
        (cultureType) => cultureType.cuCultureName === value
      );
      setValues((prevValues) => ({
        ...prevValues,
        cultureId: selectedCulture ? selectedCulture.cuCultureId : "",
        cultureName: value,
      }));
    }
  };

  const CallCultureNamesApi = () => {
    CuringTypeService.getAllCultureNames()
      .then((res) => {
        const cultureList = res?.data?.response || [];
        setCultureNames(cultureList);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setCultureNames([]);
      });
  };

  useEffect(() => {
    CallCultureNamesApi();
  }, []);

  const handleValidation = (schemaValidationErrors, curingTypeTrimmed) => {
    const specialCharPattern = /^[a-zA-Z0-9 ]+$/;

    if (!curingTypeTrimmed) {
      schemaValidationErrors.curingType = "Curing Type is required.";
    } else if (!specialCharPattern.test(curingTypeTrimmed)) {
      schemaValidationErrors.curingType = "No special characters allowed.";
    } else if (curingTypeTrimmed !== values?.curingType) {
      schemaValidationErrors.curingType =
        "No leading or trailing spaces allowed.";
    } else if (curingTypeTrimmed.length > 50) {
      schemaValidationErrors.curingType =
        "The Character limit cannot exceed more than 50 in Curing Type.";
    }

    if (!values.cultureName) {
      schemaValidationErrors.cultureName = "Culture Name is required.";
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const schemaValidationErrors = {};
    const specialCharPattern = /^[a-zA-Z0-9 ]+$/;
    const curingTypeTrimmed = values?.curingType?.trim();

    handleValidation(schemaValidationErrors, curingTypeTrimmed);

    if (Object.keys(schemaValidationErrors).length > 0) {
      setErrors(schemaValidationErrors);
      return;
    }

    const payload = {
      countryId: values.countryId,
      cultureId: values.cultureId,
      cultureName: values.cultureName,
      curingType: curingTypeTrimmed,
      isActive: values?.isActive,
      isDelete: false,
      languageId: values.languageId,
      modifiedDateTimeUTC: new Date().toISOString(),
    };

    try {
      const response = await CuringTypeService.createCuringType({
        ...payload,
      });

      if (response.status === 201) {
        setSeverity("success");
        setMessage("RECORD SAVED SUCCESSFULLY!");
        setOpenAlert(true);
        navigate("/curingType");
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setSeverity("error");
        setMessage(error.response.data.message);
        setOpenAlert(true);
      } else if (error.response && error.response.status === 500) {
        setSeverity("error");
        setMessage(
          `The ${values.curingType} already exists. Please provide a unique Curing Type Name`
        );
        setOpenAlert(true);
      } else {
        setSeverity("error");
        setMessage("Submission Failed");
        setOpenAlert(true);
      }
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    const schemaValidationErrors = {};
    const specialCharPattern = /^[a-zA-Z0-9 ]+$/;
    const curingTypeTrimmed = values?.curingType?.trim();

    handleValidation(schemaValidationErrors, curingTypeTrimmed);

    if (Object.keys(schemaValidationErrors, specialCharPattern).length > 0) {
      setErrors(schemaValidationErrors);
      return;
    }

    try {
      await schemaValidationErrors.validate(values, { abortEarly: false });
    } catch (err) {
      err?.inner?.forEach((error) => {
        schemaValidationErrors[error.path] = error.message;
      });
      setErrors(schemaValidationErrors);
    }

    if (Object.keys(schemaValidationErrors).length === 0) {
      const payload = {
        countryId: values.countryId,
        ctrId: values.ctrId,
        cultureId: values.cultureId,
        cultureName: values.cultureName,
        curingType: curingTypeTrimmed,
        isActive: values?.isActive,
        isDelete: false,
        languageId: values.languageId,
        modifiedDateTimeUTC: new Date().toISOString(),
      };
      try {
        const response = await CuringTypeService.updateCuringType({
          ...payload,
        });
        if (response.status === 201) {
          setSeverity("success");
          setMessage("RECORD UPDATED SUCCESSFULLY!");
          setOpenAlert(true);
          navigate("/curingType");
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          setSeverity("error");
          setMessage(error.response.data.message);
          setOpenAlert(true);
        } else if (error.response && error.response.status === 500) {
          setSeverity("error");
          setMessage(
            `The ${values.curingType} already exists. Please provide a unique Curing Type Name`
          );
          setOpenAlert(true);
        } else {
          setSeverity("error");
          setMessage(t("snackbarMessage.updateFailed"));
          setOpenAlert(true);
        }
      }
    }
  };

  return (
    <Layout title={t("curingTypeRegistration.pageTitle")} background="#ffffff">
      <Box
        sx={{
          minHeight: "10%",
          flexGrow: 1,
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box sx={{ display: "flex" }}>
            <ArrowDropDownIcon sx={{ marginRight: 1 }} />
            <Typography>{t("form")}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              bgcolor: "#EFEFEF",
              height: "90px",
              marginTop: "-25px",
              borderRadius: "5px",
              paddingTop: "25px",
            }}
          >
            <TextField
              name="curingType"
              label={t("curingTypeRegistration.curingType")}
              value={values?.curingType}
              onChange={handleChange}
              variant="outlined"
              disabled={edit === false}
              helperText={errors.curingType ? errors.curingType : " "}
              sx={{
                width: "50%",
                "& .MuiOutlinedInput-root": {
                  padding: "7px",
                  minHeight: "50px",
                  ...(edit === false && {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #0000003B",
                    },
                  }),
                },
                "& .MuiInputLabel-root": {
                  lineHeight: "1.2em",
                  marginLeft: "10px",
                },
              }}
              error={!!errors.curingType}
              InputProps={{
                style: { height: "50px", marginLeft: "10px" },
              }}
            />

            <FormControl sx={{ width: { xs: "33%" }, minHeight: 50 }}>
              <TextField
                select
                name="cultureName"
                label={t("curingTypeRegistration.culture")}
                value={values.cultureName || "Select an Option"}
                onChange={handleChange}
                disabled={edit === false}
                helperText={errors.cultureName ? errors.cultureName : " "}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "7px",
                    minHeight: "50px",
                    ...(edit === false && {
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #0000003B",
                      },
                    }),
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em",
                  },
                }}
                error={!!errors.cultureName} // Show error state if there's an error
                InputProps={{
                  style: { height: "50px" },
                  endAdornment: values.cultureName && (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        disabled={edit === false}
                        onClick={() => {
                          setValues({ ...values, cultureName: "" });
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            cultureName: "",
                          }));
                        }}
                        style={{ marginRight: "15px" }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value="Select an Option" sx={{ display: "none" }}>
                  {t("selectoption")}
                </MenuItem>
                {/* Map over cultureNames to create MenuItem options */}
                {cultureNames.map((culture) => (
                  <MenuItem
                    key={culture.cuCultureId}
                    value={culture.cuCultureName}
                  >
                    {culture.cuCultureName}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <Box
              sx={{
                ml: 0.5,
                display: "flex",
                alignItems: "center",
                gap: "0.4rem",
              }}
            >
              <FormControl component="fieldset">
                <FormLabel
                  sx={{
                    mt: "-30px",
                    color: "#3B454F !important",
                    mb: 2,
                    ml: 0.5,
                    fontWeight: "600",
                  }}
                >
                  {t("status")}
                </FormLabel>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ mr: 3, ml: 3 }}>{t("Inactive")}</Typography>
                  <FormControlLabel
                    control={
                      <RedSwitch
                        sx={{ marginRight: "11px" }}
                        checked={values.isActive}
                        onChange={handleChange}
                        name="isActive"
                        color="primary"
                        disabled={edit === false}
                      />
                    }
                    label={
                      <Typography sx={{ ml: 0 }}>{t("Active")}</Typography>
                    }
                    labelPlacement="end"
                  />
                </Box>
              </FormControl>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              position: "fixed",
              width: "100%",
              bottom: 0,
              padding: "1rem",
            }}
          >
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: "6px 16px",
                borderRadius: "4px",
                position: "fixed",
                left: "1rem",
                bottom: "1rem",
                marginBottom: "50px",
              }}
              variant="contained"
              onClick={handleOpenModal}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
            >
              {edit === false ? t("back") : t("cancel")}
            </Button>

            <Modal
              open={open}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  width: "585px",
                  margin: "auto",
                  mt: 25,
                  height: "200px",
                }}
              >
                <Box
                  sx={{
                    background: "#102A62",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                  }}
                >
                  <Typography
                    sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                  >
                    {t("cancelTitle")}
                  </Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, fontWeight: 400 }}
                  >
                    {t("cancelMessage")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    mb: 1,
                  }}
                >
                  <Button
                    onClick={handleCloseModal}
                    sx={{
                      background: "#737D86",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#737D86" },
                    }}
                  >
                    {t("no")}
                  </Button>
                  <Button
                    onClick={handleDeleteConfirm}
                    sx={{
                      background: "#004F9F",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#004F9F" },
                    }}
                  >
                    {t("yes")}
                  </Button>
                </Box>
              </Box>
            </Modal>

            {edit != false && (
              <Button
                sx={{
                  padding: "6px 16px",
                  borderRadius: "4px",
                  position: "fixed", // Fix the button
                  right: "1rem", // Aligns to the right bottom corner
                  bottom: "1.5rem", // Position it at the bottom
                  marginBottom: "40px",
                  backgroundColor: "#EF7D00",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#EF7D00",
                  },
                }}
                startIcon={<img src={Save} alt="Right Arrow" />}
                onClick={!edit ? handleSubmit : handleUpdate}
                variant="contained"
              >
                {t("save")}
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Layout>
  );
}
