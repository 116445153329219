import React, { useEffect, useState, useContext } from "react";
import { Typography, Button, Modal, Box } from "@mui/material";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "context/snackBarContext";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Tooltip } from "@mui/material";
import ViewIcon from "assets/view.svg";
import SpacingServices from "services/SpacingServices";
import "./PriceParameterization.css"


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 580,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const SpacingTable = (props) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [spacingData, setspacingData] = useState([]);
  console.log("🚀 ~ SpacingTable ~ spacingData:", spacingData);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...spacingData].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setspacingData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <ArrowUpwardIcon fontSize="small" />;
      } else if (sortConfig.direction === "desc") {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
    // Default icon (ArrowDownward) when no sorting is applied
    return <ArrowDownwardIcon fontSize="small" />;
  };

  const handleOpen = (spacingRegistrationUid) => {
    setDeleteId(spacingRegistrationUid);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const response = await SpacingServices.remove(deleteId);
      if (response.status === 200) {
        setMessage("Deleted successfully");
        setSeverity("success");
        setOpenAlert(true);
        
        if (props.refreshData) {
          props.refreshData();
        }
      } else {
        setMessage("Failed to delete Spacing Registration");
        setSeverity("error");
        setOpenAlert(true);
      }
    } catch (error) {
      setMessage("Error deleting Spacing Registration");
      setSeverity("error");
      setOpenAlert(true);
      console.error("Error deleting Spacing Registration:", error);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };
  useEffect(() => {
    setspacingData(props.data);
  }, [props.data]);


  return (
    <div className="table-container">
      <table className="custom-table">
        <thead>
          <tr>
            <th
              onClick={() => handleSort("contractType")}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {"Contract Type"}{" "}
              {getSortIcon("contractType")}
            </th>
         
            <th
              onClick={() => handleSort("isActive")}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {t("spacingRegistration.status")} {getSortIcon("isActive")}
            </th>
            {/* <th className="actions-header">Actions</th> */}
            <th className="actions-header">
              <div
                style={{
                  display: "flex",
                  // alignItems: "center",
                  cursor: "pointer",
                  // justifyContent: "left",
                  justifyContent: "space-between",
                }}
                onClick={
                  // () => handleSort("")
                  {}
                }
              >
                {t("actions")}
                {/* {getSortIcon("")} */}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {spacingData.map((row, index) => (
            <tr key={index}>
              <td
                style={{ cursor: "pointer" }}           
              >
                {row.contractType}
              </td>
              <td
                style={{ cursor: "pointer" }}
              >
                {row.isActive === true ? "Active" : "Inactive"}
              </td>
              <td className="actions-cell">
                <>
                  <Tooltip title="EDIT" placement="top" arrow>
                    <img
                      className="btn edit-btn"
                      onClick={() =>
                        navigate("/addContract", {
                          state: { row: row, edit: true },
                        })
                      }
                      src={_ButtonEdit_}
                      alt="Edit"
                    />
                  </Tooltip>
                </>
                <Tooltip title="VIEW" placement="top" arrow>
                  <img
                    className="btn delete-btn"
                    onClick={() =>
                      navigate("/addContract", {
                        state: { row: row, edit: false, viewOnly: true },
                      })
                    }
                    src={ViewIcon}
                    alt="Delete"
                  />
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              {t("deleteConfirmation")}
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography sx={{ mt: 2, fontWeight: 400 }}>
              {t("deleteConfirmationMessage")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "50px",
              mb: 1,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": {
                  background: "#737D86",
                },
              }}
            >
              {t("no")}
            </Button>
            <Button
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": {
                  background: "#004F9F",
                },
              }}
              onClick={handleDelete}
            >
              {t("yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export default SpacingTable;
