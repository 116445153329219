import { httpGrow } from "../http-common";

const basename = "supplierConfiguration";
const user= "9A6708BB-5DF8-4F38-9AB3-169D69371373" //DEV
// const user = "ef9e61b3-d637-47b3-9c0b-0c4d800e996a" //SIT

const getAPI = async (query) => {
    const { pageNumber = 0, size = 50, keyword, supplyTypeId, typeOfUseId, notConfigured } = query;
    const response = await httpGrow.get(`/agricultural-supplies/searchE1`, {
        params: {
            pageNumber,
            pageSize: size,
            search: keyword || undefined,
            supplyTypeId: supplyTypeId || undefined,
            typeOfUseId: typeOfUseId || undefined,
            notConfigured: notConfigured ? true : undefined,
        }
    });
    return response;
};

const getCropSeasonAPI = async () => {
    const response = await httpGrow.get('grCropSeason/search', {
        params: {
            cultureName: 'Tobacco',
            status: true,
        },
    });
    return response;
};
const getgeneticTypeAPI = async () => {
    const response = await httpGrow.get('/geneticType/search', {
        params: {
            // cultureId: '0a000214-903a-1f3b-8190-3a5fa0f10000',// DEV ID for Tobacco Culture
            cultureId: '9bc06318-8029-4668-b3fe-8d9aad76311b',// SIT ID for Tobacco Culture
            isActive: true,
        },
    });
    return response;
};
const getUsageTypeAPI = async () => {
    const response = await httpGrow.get('/usage-type-drop-down');
    return response;
};
const getVarietyAPI = async () => {
    const response = await httpGrow.get('/variety/search', {
        params: {
            isActive: true,
        },
    });
    return response;
};
const getFundingGroupAPI = async () => {
    const response = await httpGrow.get('/fundingGroup/search', {
        params: {
            status: true,
        },
    });
    return response;
};

const getUsageSetupAPI = async (asrSupplyId) => {
    const response = await httpGrow.get(`/agricultural-supplies/${asrSupplyId}`);
    return response;
};
const getSupplyTypeAPI = async () => {
    const response = await httpGrow.get(`supply-type-drop-down`);
    return response;
};
const getTypeOfUseAPI = async () => {
    const response = await httpGrow.get(`type-of-use-drop-down`);
    return response;
};

const putAgriSupplyRegAPI = (data) => {
    return httpGrow.put('agricultural-supplies', data);
};

const getBase64Image = (url) => {
    return httpGrow.get(`/storage?fileUrl=${url}`);
  };

const AgriculturalSuppliersRegService = {
    getAPI,
    getCropSeasonAPI,
    getgeneticTypeAPI,
    getVarietyAPI,
    getUsageSetupAPI,
    getSupplyTypeAPI,
    getTypeOfUseAPI,
    putAgriSupplyRegAPI,
    getFundingGroupAPI,
    getBase64Image,
    getUsageTypeAPI
};

export default AgriculturalSuppliersRegService