import {
    Autocomplete,
    FormControl,
    Grid,
    InputAdornment,
    TextField,
    Typography,
    Modal,
    Box,
    Button,
  } from "@mui/material";
  import { ArrowDropDownIcon } from "@mui/x-date-pickers";
  import Divider from "components/common/GrowingDivider";
  import Layout from "components/structure/Layout";
  import React, { useEffect, useState, useRef } from "react";
  import { useTranslation } from "react-i18next";
  import FundingGroupLimitsService from "services/FundingGroupLimitsService";
  import FundingGroupTable from "./FundingGroupTable";
  import SearchIcon from "../../../../assets/SearchIcon.svg";
  import { useNavigate } from "react-router-dom";
  
  const FundingGroupLimitsDistribution = () => {
    const { t } = useTranslation();
    const [cropSeason, setCropSeason] = useState("");
    const [cropSeasonId, setCropSeasonId] = useState("");
    const [cropSeasonOptions, setCropSeasonOptions] = useState([]);
    const [hierarchicalNode, setHierarchicalNode] = useState("");
    const [hierarchicalNodeId, setHierarchicalNodeId] = useState("");
    const [hierarchyOptions, setHierarchyOptions] = useState([]);
    const [childNodes, setChildNodes] = useState([]);
    const [isTopHierarchyNode, setIsTopHierarchyNode] = useState(false);
    const [investmentGLD, setInvestmentGLD] = useState(null);
    console.log(
      "investmentGLD:",
      investmentGLD
    );
  
    console.log("iHid", hierarchicalNodeId, "csId", cropSeasonId);
    useEffect(() => {
      fetchInitialData();
      // handleViewHistory();
    }, []);
  
    // Remove or comment out this useEffect since we'll handle it in the new one
    // useEffect(() => {
    //   if (cropSeason && tobaccoType && hierarchicalNode) {
    //     fetchProductionTargets();
    //   }
    // }, [cropSeason, tobaccoType, hierarchicalNode]);
  
    // Add new useEffect to watch the IDs
    useEffect(() => {
      if (cropSeasonId && hierarchicalNodeId) {
        console.log("INSIDE GLD EFFECT");
        fetchInvestmentGLD();
      } else {
        setInvestmentGLD(null);
        //   setSelectedPtdId(null); // Reset ptdId when filters change
      }
    }, [cropSeasonId, hierarchicalNodeId]);
  
    const fetchInitialData = async () => {
      try {
        const [cropSeasonResponse, hierarchyResponse] = await Promise.all([
          FundingGroupLimitsService.getCropSeasonAPI(),
          FundingGroupLimitsService.getHierarchyNodeAPI(),
        ]);
  
        setCropSeasonOptions(
          cropSeasonResponse.data.response.map((item) => ({
            label: item.cropYear.toString(),
            value: item.cropYear.toString(),
            cropSeasonId: item.cropYearUid, // Assuming there's an id field
          }))
        );
  
        // Flatten hierarchy tree for simplicity. You might want to implement a more sophisticated approach.
        const flattenHierarchy = (node, result = [], level = 0) => {
          result.push({
            label: node.locationName,
            value: node.id,
            level,
            tag: node.levelName,
            parentId: node.parentId || "parentNode",
          });
          node.childHierarchies.forEach((child) =>
            flattenHierarchy(child, result, level + 1)
          );
          return result;
        };
        setHierarchyOptions(flattenHierarchy(hierarchyResponse.data.response));
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };
  
    console.log("HierarchyOptions", hierarchyOptions);
    console.log("Selected node: ChildNodes", childNodes);
  
    const fetchInvestmentGLD = async () => {
      try {
        const InvGLDresponse = await FundingGroupLimitsService.getAPI({
          cropYearUUID: cropSeasonId,
          ihId: hierarchicalNodeId,
        });
        console.log("INSIDE GLD EFFECT", InvGLDresponse.data.response);
        setInvestmentGLD(InvGLDresponse.data.response);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };
    const FundingGroupTableRef = useRef();
    const handleParentSave = async () => {
      console.log("handleParentSave called in Parent Component");
      if (FundingGroupTableRef.current) {
        console.log("Calling save method on Child Component");
        const isSuccess = await FundingGroupTableRef.current.save();
        return isSuccess;
      }
      return false;
    };
    const navigate = useNavigate();
    const [unsavedChangesModal, setUnsavedChangesModal] = useState(false);
    const [showExitWarningOverlayFeed, setShowExitWarningOverlayFeed] =
      useState(false);
    const [pendingNavigation, setPendingNavigation] = useState();
    const showModal = () => {
      setUnsavedChangesModal(true);
    };
    console.log(
      "NavigationData",
      showExitWarningOverlayFeed,
      unsavedChangesModal,
      pendingNavigation
    );
    const cancelNavigation = () => {
      setUnsavedChangesModal(false);
      navigate(pendingNavigation);
    };
  
    const confirmNavigation = async () => {
      const isSaveSuccessful = await handleParentSave();
      if (isSaveSuccessful) {
        setUnsavedChangesModal(false);
        navigate(pendingNavigation);
      } else {
        setUnsavedChangesModal(false);
        console.log("Save failed, navigation aborted.");
      }
    };
    useEffect(() => {
      const handlePopState = (event) => {
        const shouldNavigate = window.confirm(
          "You have unsaved changes. Are you sure you want to leave?"
        );
        if (!shouldNavigate) {
          window.history.pushState(
            null,
            "",
            window.location.pathname + window.location.search
          );
        } else {
          window.history.go(-1);
        }
      };
  
      const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";
      };
  
      if (showExitWarningOverlayFeed === true) {
        window.addEventListener("popstate", handlePopState);
        window.addEventListener("beforeunload", handleBeforeUnload);
        window.history.pushState(
          null,
          "",
          window.location.pathname + window.location.search
        );
      }
  
      return () => {
        window.removeEventListener("popstate", handlePopState);
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }, [showExitWarningOverlayFeed]);
    const handleHierarchyChange = (event, newValue) => {
      if (newValue) {
        setHierarchicalNode(newValue.value);
        setHierarchicalNodeId(newValue.value);
        setIsTopHierarchyNode(newValue.level === 0);
        setChildNodes(
          hierarchyOptions.filter(
            (node) =>
              node.level === newValue.level + 1 &&
              node.parentId === newValue.value
          )
        );
        // Log for debugging
        console.log("Selected node:", newValue);
        console.log("Node level:", newValue.level);
      } else {
        setHierarchicalNode("");
        setHierarchicalNodeId("");
        setIsTopHierarchyNode(false);
      }
    };
  
    const renderFundingGroupTable = () => {
      if (!cropSeasonId || !hierarchicalNodeId) {
        return null;
      }
      return (
        <FundingGroupTable
          ref={FundingGroupTableRef}
          data={investmentGLD}
          cropYearUUID={cropSeasonId}
          ihId={hierarchicalNodeId}
          // onSave={handleSaveProductionTargets}
          childNodes={childNodes}
          isTopHierarchyNode={isTopHierarchyNode}
          refreshData={fetchInvestmentGLD}
          setShowExitWarningOverlayFeed={setShowExitWarningOverlayFeed}
        />
      );
    };
    return (
      <Layout
        title={t("FundingGroupLimits.pageTitle")}
        background="#ffffff"
        showExitWarningOverlayFeed={showExitWarningOverlayFeed}
        setShowExitWarningOverlayFeed={showModal}
        setPendingNavigation={setPendingNavigation}
      >
        <Grid
          container
          id="mainfilter"
          spacing={2}
          sx={{
            background: "#EFEFEF",
            borderRadius: "5px",
            p: 2,
            maxWidth: "100%",
            margin: "auto",
            paddingBottom: "34px",
            marginBottom: "12px",
          }}
        >
          <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px" }}>
            <Typography
              variant="h8"
              display="flex"
              fontWeight="600"
              color="#666666"
            >
              <ArrowDropDownIcon />
              {t("investmentGroupLimits.mandatoryFilters")}
            </Typography>
          </Grid>
  
          <Grid item xs={4} style={{ paddingLeft: "0px" }}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  cropSeasonOptions.find(
                    (option) => option.value === cropSeason
                  ) || null
                }
                disableClearable={true}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setCropSeason(newValue.value);
                    setCropSeasonId(newValue.cropSeasonId); // This will trigger useEffect
                  } else {
                    setCropSeason("");
                    setCropSeasonId(""); // This will trigger useEffect
                  }
                }}
                options={cropSeasonOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("investmentGroupLimits.csSearchField")}
                    fullWidth
                    placeholder="Select an Option"
                    required={true}
                    InputLabelProps={{
                      shrink: true,
                      sx: {
                        fontWeight: "bold",
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-input::placeholder": {
                        opacity: 1,
                      },
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "e" || e.key === "-" || e.key === "+") {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
  
          {/* <Grid item xs={4}>
                      <FormControl fullWidth>
                          <Autocomplete
                              value={tobaccoTypeOptions.find(option => option.value === tobaccoType) || null}
                              onChange={(event, newValue) => {
                                  if (newValue) {
                                      setTobaccoType(newValue.value);
                                      setTobaccoTypeId(newValue.tobaccoTypeId); // This will trigger useEffect
                                  } else {
                                      setTobaccoType("");
                                      setTobaccoTypeId(""); // This will trigger useEffect
                                  }
                              }}
                              // onInputChange={renderProductionTargetsTable()}
                              options={tobaccoTypeOptions}
                              renderInput={(params) => (
                                  <TextField {...params} label={t('investmentGroupLimits.hnSearchField')} fullWidth />
                              )}
                          />
                      </FormControl>
                  </Grid> */}
  
          <Grid item xs={4}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  hierarchyOptions.find(
                    (option) => option.value === hierarchicalNode
                  ) || null
                }
                disableClearable={true}
                onChange={handleHierarchyChange}
                options={hierarchyOptions}
                renderOption={(props, option) => (
                  <li {...props}>
                    <div>
                      <div style={{ fontSize: "16px" }}>{option.label}</div>
                      <div style={{ fontSize: "10px", color: "#5A646E" }}>
                        {option.tag}
                      </div>
                    </div>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("investmentGroupLimits.hnSearchField")}
                    fullWidth
                    placeholder="Select an Option"
                    required={true}
                    InputLabelProps={{
                      shrink: true,
                      sx: {
                        fontWeight: "bold",
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-input::placeholder": {
                        opacity: 1,
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={SearchIcon}
                            alt="search"
                            style={{
                              width: "20px",
                              height: "20px",
                              color: "#737D86",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
  
        {cropSeasonId && hierarchicalNodeId && (
          <Divider
            title={t(
              "investmentGroupLimits.subordinateHierarchiesDistributionLimit"
            )}
          />
        )}
        {renderFundingGroupTable()}
        <Modal
          open={unsavedChangesModal}
          onClose={() => cancelNavigation()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "7px",
              width: "585px",
              margin: "auto",
              mt: 25,
              height: "200px",
            }}
          >
            <Box
              sx={{
                background: "#102A62",
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderTopLeftRadius: "7px",
                borderTopRightRadius: "7px",
              }}
            >
              <Typography
                sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
              >
                {t("cancelTitle")}
              </Typography>
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, fontWeight: 400 }}
              >
                {t("unsavedChanges")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
                mb: 1,
              }}
            >
              <Button
                onClick={cancelNavigation}
                sx={{
                  background: "#737D86",
                  width: "100px",
                  color: "#ffffff",
                  "&:hover": { background: "#737D86" },
                }}
              >
                {t("discard")}
              </Button>
              <Button
                onClick={confirmNavigation}
                sx={{
                  background: "#004F9F",
                  width: "100px",
                  color: "#ffffff",
                  "&:hover": { background: "#004F9F" },
                }}
              >
                {t("save")}
              </Button>
            </Box>
          </Box>
        </Modal>
      </Layout>
    );
  };
  
  export default FundingGroupLimitsDistribution;
  