import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import "./Variety.css";
import VarietyRegistrationService from "services/VarietyRegistrationService";
import { useNavigate } from "react-router-dom";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Box, Button, Modal, Typography, CircularProgress } from "@mui/material";
import { SnackbarContext } from "context/snackBarContext";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 580,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const VarietyTable = (props) => {
  const { t } = useTranslation();
  const { varietyData, onDelete } = props;
  const [localVarietyData, setLocalVarietyData] = useState(varietyData);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...localVarietyData].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setLocalVarietyData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <ArrowUpwardIcon fontSize="small" />;
      } else if (sortConfig.direction === "desc") {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
    // Default icon (ArrowDownward) when no sorting is applied
    return <ArrowDownwardIcon fontSize="small" />;
  };

  const handleOpen = (vrVarietyId) => {
    setDeleteId(vrVarietyId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const response = await VarietyRegistrationService.deleteVariety(deleteId);
      if (response.status === 200) {
        setMessage("Variety deleted successfully");
        setSeverity("success");
        setOpenAlert(true);
        
        onDelete();
      } else {
        setMessage("Failed to delete variety");
        setSeverity("error");
        setOpenAlert(true);
      }
    } catch (error) {
      setMessage("Error deleting variety");
      setSeverity("error");
      setOpenAlert(true);
      console.error("Error deleting variety:", error);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const handleRowClick = (row) => {
    navigate("/varietyForm", {
      state: {
        vrVarietyId: row.vrVarietyId,
        cuCultureName: row.cuCultureName,
        vrVariety: row.vrVariety,
        gtGeneticTypeId: row.gtGeneticTypeId,
        gtGeneticDescription: row.gtGeneticDescription,
        varietyCultureType: row.gtGeneticDescription,
        status: row.isActive,
        edit: false,
      },
    });
  };

  useEffect(() => {
    setLocalVarietyData(props.varietyData);
  }, [props.varietyData]);
  return (
    <div className="table-container">
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <table className="custom-table">
          <thead>
            <tr>
              <th
                onClick={() => handleSort("vrVariety")}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {t('VarietyRegistration.table.headers.variety')}
                {getSortIcon("vrVariety")}
              </th>
              <th
                onClick={() => handleSort("cuCultureName")}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {t('VarietyRegistration.table.headers.culture')}
                {getSortIcon("cuCultureName")}
              </th>
              <th
                onClick={() => handleSort("gtGeneticDescription")}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {t('VarietyRegistration.table.headers.geneticType')}
                {getSortIcon("gtGeneticDescription")}
              </th>
              <th
                onClick={() => handleSort("isActive")}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {t('VarietyRegistration.table.headers.status')}
                {getSortIcon("isActive")}
              </th>
              <th className="actions-header">
                {t('VarietyRegistration.table.headers.actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            {localVarietyData.map((row, index) => (
              <tr 
                key={index} 
                onClick={() => handleRowClick(row)} 
                style={{ cursor: 'pointer' }}
              >
                <td>{row.vrVariety}</td>
                <td>{row.cuCultureName}</td>
                <td>{row.gtGeneticDescription}</td>
                <td>
                  {row.isActive === true 
                    ? t('VarietyRegistration.table.status.active') 
                    : t('VarietyRegistration.table.status.inactive')
                  }
                </td>
                <td className="actions-cell">
                  <img
                    className="btn edit-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate("/varietyForm", {
                        state: {
                          vrVarietyId: row.vrVarietyId,
                          cuCultureName: row.cuCultureName,
                          vrVariety: row.vrVariety,
                          gtGeneticTypeId: row.gtGeneticTypeId,
                          gtGeneticDescription: row.gtGeneticDescription,
                          varietyCultureType: row.gtGeneticDescription,
                          status: row.isActive,
                          edit: true,
                        },
                      })
                    }}
                    src={_ButtonEdit_}
                    alt="Edit"
                    style={{ cursor: loading ? 'not-allowed' : 'pointer' }}
                    disabled={loading}
                  />
                  <img
                    className="btn delete-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      !loading && handleOpen(row.vrVarietyId)
                    }}
                    src={_ButtonDelete_}
                    alt="Delete"
                    style={{ cursor: loading ? 'not-allowed' : 'pointer' }}
                    disabled={loading}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}>
              {t('VarietyRegistration.table.deleteConfirmation.title')}
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography sx={{ mt: 2, fontWeight: 400 }}>
              {t('VarietyRegistration.table.deleteConfirmation.message')}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "20px", mb: 1 }}>
            <Button
              onClick={handleClose}
              disabled={loading}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": {
                  background: "#737D86",
                },
              }}
            >
              {t('VarietyRegistration.table.deleteConfirmation.no')}
            </Button>
            <Button
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": {
                  background: "#004F9F",
                },
              }}
              onClick={handleDelete}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : t('VarietyRegistration.table.deleteConfirmation.yes')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default VarietyTable;
