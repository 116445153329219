import { httpGrow } from "../http-common";

const getAPI = async (query) => {
  const { cropYearUUID, tobaccoTypeUUID, ihId } = query;
  const response = await httpGrow.get(`/productionTarget/search`, {
    params: {
      cropYearUUID,
      tobaccoTypeUUID,
      ihId,
    },
  });
  return response;
};

const getCropSeasonAPI = async () => {
  const response = await httpGrow.get("/grCropSeason/search?status=true&cultureName=Tobacco");
  return response;
};

const getGeneticTypeApi = async () => {
  const response = await httpGrow.get(
    `/geneticType/genetic-type-cultureName/Tobacco`
  );
  return response;
};

const getHierarchyNodeAPI = async () => {
  const response = await httpGrow.get("/importHierarchyVersion/tree");
  return response;
};

const AgronomicTechnologiesService = {
  getAPI,
  getCropSeasonAPI,
  getGeneticTypeApi,
  getHierarchyNodeAPI,
};

export default AgronomicTechnologiesService;
