import React from 'react';
import { Box } from '@mui/material';
import SubMenuTable from './SubMenuTable';

const SubMenuArea = ({ selectedRow, selectedCountryId }) => {
  // Style for the submenu area, similar to the inline style in MSCTable
  const submenuAreaStyle = {
    backgroundColor: selectedRow ? '#E9EBEE' : 'transparent',
    flex: 1,
    transition: 'background-color 0.3s ease-in-out',
    minHeight: '100%',
    padding: '10px 10px 0px 10px',
    gap: '0px',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
  };

  // If not editing, return null
  if (!selectedRow) return null;

  return (
    <div style={submenuAreaStyle}>
      <SubMenuTable 
        selectedRow={selectedRow} 
        selectedCountryId={selectedCountryId}
      />
    </div>
  );
};

export default SubMenuArea;
