import { httpGrow } from "../http-common";

const createEducationType = (data) => {
  return httpGrow.post(`/education-type`, data);
};

const updateEducationType = (data) => {
  return httpGrow.put(`education-type`, data);
};

const getEducationTypes = async (query) => {
  const { pageNumber, pageSize, educationTypeDesc, isActive } = query;

  const response = await httpGrow.get(`/education-type/search`, {
    params: {
      pageNumber,
      pageSize,
      educationTypeDesc: educationTypeDesc || undefined,
      isActive: isActive,
    },
  });
  return response;
};

const EducationTypeService = {
  createEducationType,
  updateEducationType,
  getEducationTypes
};

export default EducationTypeService;
