import { httpGrow } from "../http-common";

const getRule = async (query) => {
  const response = await httpGrow.get(
    `segmentationKpisRoutinesRules/search?pageNumber=${query?.pageNumber}&pageSize=${query?.size}`
  );
  return response;
};
const getCulture = () => {
  const response = httpGrow.get(`/culture/cultureNames-and-Id`);
  return response;
};

const createRules = (data) => {
  return httpGrow.post(`/segmentationKpisRoutinesRules`, data);
};

const updateRule = (data) => {
  return httpGrow.put(`/segmentationKpisRoutinesRules`, data);
};

const deleteRule = (segmentationKpisRoutinesRulesId) => {
  return httpGrow.delete(`/segmentationKpisRoutinesRules/${segmentationKpisRoutinesRulesId}`);
};
const searchRule = async (queryString) => {
  try {
    const response = await httpGrow.get(
      `segmentationKpisRoutinesRules/search?searchTerm=${queryString}`
    );
    return response.data; // Assuming the API response has data in response.data
  } catch (error) {
    console.error("Error in searchRule API:", error);
    throw error;
  }
};

const RuleService = {
  getRule,
  getCulture,
  createRules,
  deleteRule,
  searchRule,
  updateRule,
};
export default RuleService;
