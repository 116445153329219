import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./FarmerAdd.css";
import Button from "@mui/material/Button";
import { Icon, Modal } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import plusIcon from "assets/iconLeft.svg";
import Layout from "components/structure/Layout";
import GeneralInformation from "./GeneralInformation";
import TaxInformation from "./TaxInformation";
import { useDispatch, useSelector } from "react-redux";
// import { Farmer_Env_Info, Farmer_General_Info, Farmer_General_info, Farmer_Tax_Info, Minors } from 'slices/farmerslice';
import MinorsComponent from "./Minors";
import Adults from "./Adults";
import EnvironmentalLicense from "./EnvironmentalLicense";
import Properties from "./Properties";
import CustomButton from "utils/CustomButton";
import { clearGeneralInformation } from "slices/generalInformation";
import { clearTaxInformation } from "slices/taxInformationSlice";
import { clearAdults } from "slices/adultsSlice";
import { clearProperty } from "slices/propertyAddSlice";
import { clearDependant } from "slices/dependantSlice";
import { clearEnvironment } from "slices/environmentalSlice";
import arrowRight from "assets/_IconButtonRightArrow_.svg";

const styleBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30.5rem",
  bgcolor: "background.paper",
  height: "20.125rem",
  borderRadius: "0.625rem",
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function FarmerRegisteration() {
  const [value, setValue] = React.useState(0);
  const [adultModalStatus, setAdultModalStatus] = React.useState(false);
  const dispatch = useDispatch();
  // const state = useSelector(state => state);
  const farmers = useSelector((state) => state.farmers);
  const [registerationModalStatus, setRegisterationModalStatus] =
    React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const handleYes = () => {
    dispatch(clearGeneralInformation());
    dispatch(clearTaxInformation());
    dispatch(clearAdults());
    dispatch(clearProperty());
    dispatch(clearDependant());
    dispatch(clearEnvironment());
    navigate("/farmerRegistration");
  };

  const handleNo = () => setDeleteOpen(false);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const plusIconComp = (
    <Icon>
      <img
        alt="edit"
        style={{ display: "flex", justifyContent: "center" }}
        width="100%"
        height="100%"
        src={plusIcon}
      />
    </Icon>
  );

  const nextIconComp = (
    <Icon>
      <img
        alt="next"
        style={{ display: "flex", justifyContent: "center",color:"white" }}
        width="100%"
        height="100%"
        src={arrowRight}
      />
    </Icon>
  );

  // onClick={() => navigate('/farmerRegistration')}

  const location = useLocation();
  const { edit, details } = location.state || {};

  return (
    <Layout title={"New Farmer Registration"} background="#ffffff">
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider", position: "relative" }}
        >
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            sx={{
              "& .MuiTabs-indicator": { backgroundColor: "#EF7D00" },
              "& .Mui-selected": { color: "#EF7D00 !important" },
            }}
          >
            <Tab
              label="General Information"
              sx={{ flexGrow: 1, maxWidth: "50%" }}
              {...a11yProps(0)}
            />
            <Tab
              sx={{ flexGrow: 1, maxWidth: "50%" }}
              label="Tax-Related"
              {...a11yProps(1)}
            />
            {/* <Tab label="Properties" {...a11yProps(2)} />
            <Tab label="Minors" {...a11yProps(3)} />

            <Tab label="Adults on the Property" {...a11yProps(4)} />
            <Tab label="Environmental License" {...a11yProps(5)} /> */}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "white",
              position: "fixed",
              bottom: 0,
              width: "97%",
              zIndex: "111",
              borderTop: "1px solid grey",
            }}
          >
            <Button
              style={{
                margin: "30px 0px 30px 10px",
                height: "46px",
                width:"130px",
                background:"#E9EBEE",
                color: "#ACB4BA",
              }}
              onClick={() => setDeleteOpen(true)}
            >
              {" "}
              {edit ? "Previous" : "Cancel"}
            </Button>
            {/* <Button
              style={{
                color: "white",
                background: "#004F9F",
                height: "46px",
                width: "108px",
                marginLeft:"20px"
              }}
              required
              variant="outlined"
              onClick={(event) => handleTabChange(event, 1)}
            >
              Next
            </Button> */}
          </Box>
          <GeneralInformation handleTabChange={handleTabChange} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                margin: "30px 0px 30px 10px",
                color: "#004F9F",
                borderColor: "#004F9F",
              }}
              required
              variant="outlined"
              startIcon={plusIconComp}
              onClick={(event) => handleTabChange(event, 2)}
            >
              {" "}
              Back
            </Button>
            {/* <Button
              style={{ color: "#004F9F", borderColor: '#004F9F' }}
              required
              variant="outlined"
              endIcon={nextIconComp}
              onClick={(event) => handleTabChange(event, 4)}
            >
              Next
            </Button> */}
          </Box>
          <MinorsComponent
            registerationModalStatus={registerationModalStatus}
            setRegisterationModalStatus={(value) =>
              setRegisterationModalStatus(value)
            }
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                margin: "30px 0px 30px 10px",
                color: "#004F9F",
                borderColor: "#004F9F",
              }}
              required
              variant="outlined"
              startIcon={plusIconComp}
              onClick={(event) => handleTabChange(event, 1)}
            >
              {" "}
              Back
            </Button>
            {/* <Button
              style={{ color: "#004F9F", borderColor: '#004F9F' }}
              required
              variant="outlined"
              endIcon={nextIconComp}
              onClick={(event) => handleTabChange(event, 3)}
            >
              Next
            </Button> */}
          </Box>
          <Properties
            registerationModalStatus={registerationModalStatus}
            setRegisterationModalStatus={(value) =>
              setRegisterationModalStatus(value)
            }
          />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "white",
              position: "fixed",
              bottom: 0,
              width: "97%",
              zIndex: "111",
              borderTop: "1px solid grey",
            }}
          >
            <Button
              style={{
                margin: "30px 0px 30px 10px",
                color: "white",
               background: "#004F9F",
                height: "46px",
                width: "108px",
              }}
              required
              variant="outlined"
              onClick={(event) => handleTabChange(event, 0)}
            >
              {" "}
              {edit ? "Previous" : "Cancel"}
            </Button>
            <Button
              style={{
                // borderColor: "#004F9F",
                marginLeft: "20px",
                width: "108px",
                height: "46px",
                background:"#E9EBEE",
                color: "#ACB4BA",
              }}
              disabled={true}
              variant="outlined"
              onClick={(event) => handleTabChange(event, 1)}
            >
              Next
            </Button>
          </Box>
          <TaxInformation
            registerationModalStatus={registerationModalStatus}
            setRegisterationModalStatus={(value) =>
              setRegisterationModalStatus(value)
            }
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                margin: "30px 0px 30px 10px",
                color: "#004F9F",
                borderColor: "#004F9F",
              }}
              required
              variant="outlined"
              startIcon={plusIconComp}
              onClick={(event) => handleTabChange(event, 3)}
            >
              {" "}
              Back
            </Button>
            {/* <Button
              style={{ color: "#004F9F", borderColor: '#004F9F' }}
              required
              variant="outlined"
              endIcon={nextIconComp}
              onClick={(event) => handleTabChange(event, 5)}
            >
              Next
            </Button> */}
          </Box>
          <Adults
            adultModalStatus={adultModalStatus}
            setAdultModalStatus={(value) => setAdultModalStatus(value)}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <Button
            style={{
              margin: "30px 0px 30px 10px",
              color: "#004F9F",
              borderColor: "#004F9F",
            }}
            required
            variant="outlined"
            startIcon={plusIconComp}
            onClick={(event) => handleTabChange(event, 4)}
          >
            {" "}
            Back
          </Button>
          <EnvironmentalLicense />
        </CustomTabPanel>
        <Modal
          open={deleteOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleBox}>
            <Box
              sx={{
                mt: 4,
                justifyContent: "center",
                display: "flex",
                gap: "1rem",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>Are you sure!</div>
              <div>You want to Go Back</div>
              <div>Doing so will erase all the prefilled or filled data</div>
            </Box>
            <Box
              sx={{
                mt: 4,
                justifyContent: "center",
                display: "flex",
                gap: "1rem",
              }}
            >
              <CustomButton variant="cancel" label="No" onClick={handleNo} />

              <CustomButton color="#EF7D00" label="Yes" onClick={handleYes} />
            </Box>
          </Box>
        </Modal>
      </Box>
    </Layout>
  );
}
