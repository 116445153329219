import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Switch,
  CircularProgress,
  Typography,
  Modal,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Radio,
  RadioGroup,
  Divider,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Layout from "components/structure/Layout";
import { soilParameterSchema, transportGeneralSchema } from "utils/validations";
import TransporterGetService from "services/TransporterGetService";
import CultureGroupService from "services/CultureGroup";
import { SnackbarContext } from "context/snackBarContext";
import arrowRight from "assets/arrowRight.svg";
import arrowLeft from "assets/arrowLeft.svg";
import RedSwitch from "utils/RedSwitch";
import PlantingService from "services/PlantingService";
import Save from "assets/SaveNew.svg";
import TobaccoService from "services/TobaccoService";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import Lable from "utils/lables.json";
import SoilParameterService from "services/SoilParameterService";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import TransporterRegistrationService from "services/TransporterRegistrationService";
import * as Yup from "yup";
import { transportAddSchema, transportAddSchemaLegal } from "utils/validations";

export default function GeneralInformation() {
  console.log("runninggggg");
  const navigate = useNavigate();

  const location = useLocation();
  console.log("vkkkk", location);
  const { row, edit } = location.state || {};

  console.log("Incoming row data:", row);

  //console.log("dhoni", tobaccoCompanyName, tccCountryCode, region);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState({
    // Individual/Legal Entity Data
    BusinessType: row?.BusinessType || "Individual",
    fiscalID: "",
    transporterName: row?.transporterName || "",
    nationalityId: row?.nationalityId || "",
    maritalStatusId: row?.maritalStatusId || "",
    region: row?.region || "",
    stateRegistrationNumber: row?.stateRegistrationNumber || "",
    rntrc: row?.rntrc || "",

    // Route Details
    buyingStation: "",
    buyingStationRoute: "",
    supplyInvoicingUnit: row?.supplyInvoicingUnit || "",
    supplyInvoicingUnitRoute: row?.supplyInvoicingUnitRoute || "",

    // Bank Information
    bankAccountType: row?.bankAccountType || "",
    bankRegistrationId: row?.bankRegistrationId || "",
    bankBranchRegistrationId: row?.bankBranchRegistrationId || "",
    accountNumber: row?.accountNumber || "",

    // Additional Information
    tmResponsibleUID: row?.tmResponsibleUID || "",
    ftResponsibleUID: row?.ftResponsibleUID || "",
    sapVendorCode: row?.sapVendorCode || "",
    coupaRegisterNumber: row?.coupaRegisterNumber || "",
    ciotRequirement: row?.cioTRequirement ? "YES" : "NO",
    tallVoucher: row?.tallVoucher ? "YES" : "NO",
    externalCode: row?.externalCode || "",

    // CNPJ Card Information
    tradeName: row?.tradeName || "",
    businessSize: row?.businessSize || "",
    mainEconomicActivityCode: row?.mainEconomicActivityCode || "",
    secondaryEconomicActivityCode: row?.secondaryEconomicActivityCode || "",
    legalNatureCode: row?.legalNatureCode || "",
    legalName: row?.legalName || "",
    taxation: row?.taxation || "",
    icmsTaxPercentage: row?.icmsTaxPercentage?.toString() || "",
    address: row?.address || "",
    neighborhood: row?.neighborhood || "",
    postalCode: row?.postalCode || "",
    emailForInvoices: row?.emailForInvoices || "",
    bankCode: "", // We'll set this after fetching bank details
  });
  console.log("🚀 ~ GeneralInformation ~ values:", values);

  const [errors, setErrors] = useState({});
  console.log("🚀 ~ GeneralInformation ~ errors:", errors);
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [dataType, setDataType] = useState([]);
  const [region, setRegion] = useState([]);
  const [nationality, setNationality] = useState([]);
  const [marital, setMarital] = useState([]);
  const [expiryDate, setExpiryDate] = useState(null);
  const [establishmentDate, setEstablishmentDate] = useState(null);
  const [dob, setDob] = useState(null);

  const [open, setOpen] = useState(false);
  const [partners, setPartners] = useState([{ id: 1 }]); // Initialize with one partner
  console.log("🚀 ~ GeneralInformation ~ partners:", partners);

  const [FT, setFT] = useState([]);
  const [TM, setTM] = useState([]);
  const [city, setCity] = useState([]);

  const [bank, setBank] = useState([]);
  const [branches, setBranches] = useState([]);

  const { t } = useTranslation();

  const handleAddPartner = () => {
    const newId = Math.max(...partners.map((p) => p.id), 0) + 1;
    setPartners([
      ...partners,
      {
        id: newId,
        plName: "",
        plFiscalID: "",
        plCardId: "",
        plDOB: null,
        plEmail: "",
        plPhoneNumber: "",
      },
    ]);
  };

  const handleDeletePartner = async (partnerId) => {
    try {
      setPartners(partners.filter((partner) => partner.id !== partnerId));
      const response = await TransporterGetService.deletePartner(partnerId);

      if (response.status === 200) {
        setPartners(
          partners.filter((partner) => partner.partnersListId !== partnerId)
        );
        console.log("Partner deleted successfully:", response.data);
      } else {
        console.error("Failed to delete the partner. Status:", response.status);
      }
    } catch (error) {
      console.error("An error occurred while deleting the partner:", error);
    }
  };

  // Function to open the modal
  const handleOpenModal = () => {
    setOpen(true);
  };

  const getRegionList = async () => {
    try {
      const response = await TransporterGetService.getRegion();
      const d = response.data.response;
      // Store the full region objects instead of just stateCode
      setRegion(d);
    } catch (error) {
      console.error("Error fetching regions:", error);
      return null;
    }
  };
  const getNationalityList = async () => {
    try {
      const response = await TransporterGetService.getNationality();
      const d = response.data.response;
      // Store full nationality objects instead of just names
      setNationality(d);
    } catch (error) {
      console.error("Error fetching nationality list:", error);
      return null;
    }
  };

  const getMaritalList = async () => {
    try {
      const response = await TransporterGetService.getMarital();
      const d = response.data.response;
      // Store full marital status objects instead of just names
      setMarital(d);
    } catch (error) {
      console.error("Error fetching marital status list:", error);
      return null;
    }
  };
  const getTMList = async () => {
    try {
      const response = await TransporterGetService.getTMResponsible();
      console.log("responsetm", response);
      const d = response.data.response;
      // Store full objects instead of just names
      setTM(
        d.map((e) => ({
          userId: e.userId,
          userName: e.userName,
        }))
      );
    } catch (error) {
      console.error("Error generating unique code:", error);
      return null;
    }
  };

  // Update the getFTList function
  const getFTList = async () => {
    try {
      const response = await TransporterGetService.getFTResponsible();
      console.log("responseft", response);
      const d = response.data.response;
      // Store full objects instead of just names
      setFT(
        d.map((e) => ({
          userId: e.userId,
          userName: e.userName,
        }))
      );
    } catch (error) {
      console.error("Error generating unique code:", error);
      return null;
    }
  };

  const getCityist = async () => {
    try {
      const response = await TransporterGetService.getCityDetails();
      console.log("polarCity", response.data.response);
      const d = response.data.response;
      // Store full city objects instead of just names
      setCity(d);
    } catch (error) {
      console.error("Error generating unique code:", error);
      return null;
    }
  };

  const getBankList = async () => {
    try {
      const response = await TransporterGetService.getBankDetails();
      const bankData = response.data.response;
      setBank(bankData);

      // If we have row data and bankRegistrationId, set the bank code
      if (row?.bankRegistrationId) {
        const selectedBank = bankData.find(
          (b) => b.bankRegistrationId === row.bankRegistrationId
        );
        if (selectedBank) {
          setValues((prevValues) => ({
            ...prevValues,
            bankCode: selectedBank.bankCode,
            bankRegistrationId: row.bankRegistrationId,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching bank details:", error);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      await Promise.all([
        getBankList(),
        getRegionList(),
        getNationalityList(),
        getMaritalList(),
        getTMList(),
        getFTList(),
        getCityist(),
      ]);
    };

    fetchInitialData();
  }, [row?.transporterID]);

  // Initialize dates from row data
  useEffect(() => {
    if (row) {
      if (row.expirationDateCoupa) {
        setExpiryDate(dayjs(row.expirationDateCoupa));
      }
      if (row.companyEstablishmentDate) {
        setEstablishmentDate(dayjs(row.companyEstablishmentDate));
      }
      if (row.dob) {
        setDob(dayjs(row.dob));
      }
      // Initialize partners if they exist
      if (row.partners && Array.isArray(row.partners)) {
        setPartners(
          row.partners.map((partner) => ({
            ...partner,
            id: partner.id || Math.random(), // Ensure each partner has an ID
          }))
        );
      }
    }
  }, [row]);

  // Fetch branch details when bank is selected
  useEffect(() => {
    if (values.bankRegistrationId) {
      fetchBranchDetails(values.bankRegistrationId);
    }
  }, [values.bankRegistrationId]);

  // Function to close the modal
  const handleCloseModal = () => {
    setOpen(false);
  };

  // Function when user confirms "Yes" on the modal
  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/transporterRegistration");
  };

  const fetchBranchDetails = async (bankRegistrationId) => {
    try {
      const response = await TransporterGetService.getBankBranchDetails(
        bankRegistrationId
      );

      setBranches(response.data.response || []);
    } catch (error) {
      console.error("Error fetching branch details:", error);
      setMessage("Failed to fetch bank branch details");
      setSeverity("error");
      setOpenAlert(true);
      setBranches([]); // Reset branches on error
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "bankRegistrationId") {
      const selectedBank = bank.find((b) => b.bankRegistrationId === value);
      fetchBranchDetails(value);
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
        bankCode: selectedBank ? selectedBank.bankCode : "",
        bankBranchRegistrationId: "",
      }));
    } else if (name === "tmResponsibleUID" || name === "ftResponsibleUID") {
      // For TM and FT fields, store just the userId
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value.userId || value, // Store userId if object, otherwise store value directly
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const transportGeneralSchema = Yup.object().shape({
    //   .matches(/^\d+$/, "Fiscal Id must contain only digits")
    //   .test("cpf-valid", "Invalid Fiscal Id", (value) => {
    //     if (!value) return false;

    //     const cleanCPF = value.replace(/\D/g, "");
    //     if (cleanCPF.length !== 11) return false;
    //     if (/^(\d)\1{10}$/.test(cleanCPF)) return false;

    //     let sum = 0;
    //     for (let i = 0; i < 9; i++) {
    //       sum += parseInt(cleanCPF.charAt(i)) * (10 - i);
    //     }
    //     let remainder = sum % 11;
    //     let firstCheckDigit = remainder < 2 ? 0 : 11 - remainder;

    //     sum = 0;
    //     for (let i = 0; i < 10; i++) {
    //       sum += parseInt(cleanCPF.charAt(i)) * (11 - i);
    //     }
    //     remainder = sum % 11;
    //     let secondCheckDigit = remainder < 2 ? 0 : 11 - remainder;

    //     return (
    //       firstCheckDigit === parseInt(cleanCPF.charAt(9)) &&
    //       secondCheckDigit === parseInt(cleanCPF.charAt(10))
    //     );
    //   }),

    transporterName: Yup.string()
      .trim()
      .required("Transporter Name is required"),
    // nationalityId: Yup.string().trim().required("Nationality is required for Individuals"),
    // maritalStatusId: Yup.string().trim().required("Marital is required"),
    region: Yup.string().trim().required("Region is required"),
    stateRegistrationNumber: Yup.string().trim().required("State is required"),
    // transporterLicenseNumber: Yup.string()
    //   .trim()
    //   .matches(/^[A-Za-z0-9]+$/, "Transporter License Number can only contain alphanumeric characters")
    //   .required("Transporter License Number is required"),
    buyingStation: Yup.string().trim().required("Buying Station is required"),
    buyingStationRoute: Yup.string()
      .trim()
      .required("Buying Station Route is required"),
    supplyInvoicingUnit: Yup.string()
      .trim()
      .required("Supply Invoicing Unit is required"),
    supplyInvoicingUnitRoute: Yup.string()
      .trim()
      .required("Supply Invoicing Unit Route is required"),
    bankAccountType: Yup.string()
      .trim()
      .required("Bank Account Type is required"),
    accountNumber: Yup.string()
      .trim()
      .matches(/^\d+$/, "Account Number must be a number")
      .required("Account Number is required"),
    bankRegistrationId: Yup.string().trim().required("Bank is required"),
    // emailForInvoices: Yup.string()
    //   .trim()
    //   .email("Invalid email address")
    //   .required("Email is required"),
  });

  const handleUpdate = async (event) => {
    event.preventDefault();

    try {
      await transportGeneralSchema.validate(values, { abortEarly: false });
      setErrors({});

      const missingDOB = partners.some((partner) => !partner.plDOB);
      if (missingDOB) {
        setSeverity("error");
        setMessage("Please fill in the Date of Birth for all partners.");
        setOpenAlert(true);
        return;
      }

      const selectedTM = TM.find(
        (tm) =>
          tm.userName === values.tmResponsibleUID?.userName ||
          values.tmResponsibleUID
      );
      const selectedFT = FT.find(
        (ft) =>
          ft.userName === values.ftResponsibleUID?.userName ||
          values.ftResponsibleUID
      );

      const ciotRequirementBool = values.ciotRequirement === "YES";
      const tallVoucherBool = values.tallVoucher === "YES";

      const formattedPartners = partners.map((partner) => ({
        createdByUser: "Mayur",
        createdDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "Mayur",
        modifiedDateTimeUTC: new Date().toISOString(),
        plName: partner.plName || "",
        plFiscalID: partner.plFiscalID || "",
        plCardId: partner.plCardId || "",
        plDOB: dayjs(partner.plDOB).toISOString(),
        plEmail: partner.plEmail || "",
        plPhoneNumber: partner.plPhoneNumber || "",
        partnersListId: partner.partnersListId || "",
      }));

      const payload = {
        createdDateTimeUTC: new Date().toISOString(),
        createdByUser: "Mayur",
        modifiedDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "Mayur",
        businessType: values.BusinessType === "Legal Entity" ? "L" : "I",
        businessNumber: values.businessNumber,
        transporterID: row.transporterID,
        transporterName: values.transporterName,
        nationalityId:
          typeof values.nationalityId === "object"
            ? values.nationalityId.nationalityId
            : values.nationalityId,
        maritalStatusId:
          typeof values.maritalStatusId === "object"
            ? values.maritalStatusId.maritalStatusId
            : values.maritalStatusId,
        buyingStation: values.buyingStation,
        buyingStationRoute: values.buyingStationRoute,
        supplyInvoicingUnit: values.supplyInvoicingUnit,
        supplyInvoicingUnitRoute: values.supplyInvoicingUnitRoute,
        bankAccountType: values.bankAccountType,
        bankRegistrationId: values.bankRegistrationId,
        bankBranchRegistrationID: values.bankBranchRegistrationId,
        accountNumber: values.accountNumber,
        ftResponsibleUID: selectedFT?.userId || "",
        tmResponsibleUID: selectedTM?.userId || "",
        address: values.address || "string",
        attachedDocumentPath: "string",
        businessSize: values.businessSize,
        cioTRequirement: ciotRequirementBool,
        companyEstablishmentDate: establishmentDate
          ? establishmentDate.toISOString()
          : null,
        coupaRegisterNumber: values.coupaRegisterNumber || "string",
        emailForInvoices: values.emailForInvoices,
        expirationDateCoupa: expiryDate ? expiryDate.toISOString() : null,
        externalCode: values.externalCode || "string",
        fieldTechnicianUser: "string",
        icmsTaxPercentage: parseFloat(values.icmsTaxPercentage) || 0,
        legalName: values.legalName || "string",
        legalNatureCode: values.legalNatureCode || "string",
        regionId: values.region,
        mainEconomicActivityCode: values.mainEconomicActivityCode || "string",
        neighborhood: values.neighborhood || "string",
        postalCode: values.postalCode || "string",
        sapVendorCode: values.sapVendorCode || "string",
        secondaryEconomicActivityCode:
          values.secondaryEconomicActivityCode || "string",
        stateRegistrationNumber: values.stateRegistrationNumber || "",
        statusReason: "string",
        tallVoucher: tallVoucherBool,
        taxation: values.taxation || "string",
        tradeName: values.tradeName || "string",
        transporterLicenseNumber: values.rntrc || "string",
        partnerListList: formattedPartners,
      };

      console.log("🚀 ~ handleUpdate ~ payload:", payload);

      const response = await TransporterRegistrationService.updateTransporter(
        payload
      );

      setLoading(false);

      if (response.status === 200) {
        setSeverity("success");
        setMessage("Transporter updated successfully");
        setOpenAlert(true);
        navigate("/transporterRegistration");
      }
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage(
          err?.response?.data?.message ||
            "An error occurred while submitting the form"
        );
        setOpenAlert(true);
      }
    }
  };

  useEffect(() => {
    const fetchTransporterData = async () => {
      if (row?.transporterID) {
        try {
          const response = await TransporterRegistrationService.getTransporter(
            row.transporterID
          );
          const transporterData = response.data.response;
          console.log("transporterData", transporterData);

          // Find the matching TM and FT users
          const selectedTM = TM.find(
            (tm) => tm.userId === transporterData.tmResponsibleUID
          );
          const selectedFT = FT.find(
            (ft) => ft.userId === transporterData.ftResponsibleUID
          );

          setValues((prevValues) => ({
            ...prevValues,
            // ... other fields ...
            tmResponsibleUID:
              selectedTM?.userId || transporterData.tmResponsibleUID,
            ftResponsibleUID:
              selectedFT?.userId || transporterData.ftResponsibleUID,
            fiscalID: transporterData?.fiscalID || "",

            // ... other fields ...
          }));

          if (
            transporterData.partnerListList &&
            Array.isArray(transporterData.partnerListList)
          ) {
            const existingPartners = transporterData.partnerListList
              .slice(0, 5)
              .map((partner) => ({
                id: partner.id || Math.random(),
                plName: partner.plName || "",
                plFiscalID: partner.plFiscalID || "",
                plCardId: partner.plCardId || "",
                plDOB: partner.plDOB ? dayjs(partner.plDOB) : null,
                plEmail: partner.plEmail || "",
                plPhoneNumber: partner.plPhoneNumber || "",
                partnersListId: partner.partnersListId || "",
              }));
            console.log(
              "🚀 ~ existingPartners ~ existingPartners:",
              existingPartners
            );

            setPartners(existingPartners);
          } else {
            setPartners([]); // Reset to empty array if no partners exist
          }

          const bankResponse = await TransporterGetService.getBankDetails();
          const bankData = bankResponse.data.response;
          const selectedBank = bankData.find(
            (b) => b.bankRegistrationId === transporterData.bankRegistrationId
          );

          // Update the values state with the fetched data
          setValues((prevValues) => ({
            ...prevValues,
            // Map API response fields to your form fields
            BusinessType:
              transporterData.businessType === "L"
                ? "Legal Entity"
                : "Individual",
            fiscalID: transporterData.fiscalID || "",
            transporterName: transporterData.transporterName || "",
            region: transporterData.regionId || "",
            stateRegistrationNumber:
              transporterData.stateRegistrationNumber || "",
            rntrc: transporterData.transporterLicenseNumber || "",

            // Route Details
            buyingStation: transporterData.buyingStation || "",
            buyingStationRoute: transporterData.buyingStationRoute || "",
            supplyInvoicingUnit: transporterData.supplyInvoicingUnit || "",
            supplyInvoicingUnitRoute:
              transporterData.supplyInvoicingUnitRoute || "",

            // Bank Information
            bankAccountType: transporterData.bankAccountType || "",
            bankRegistrationId: transporterData.bankRegistrationId || "",
            bankBranchRegistrationId:
              transporterData.bankBranchRegistrationID || "", // Note: ID is uppercase
            accountNumber: transporterData.accountNumber || "",
            bankCode: selectedBank?.bankCode || "",

            // Additional fields from your form that might not be in the API response
            nationalityId: transporterData.nationalityId,
            maritalStatusId: transporterData.maritalStatusId || "",
            tmResponsibleUID: transporterData.tmResponsibleUID || "",
            ftResponsibleUID: transporterData.ftResponsibleUID || "",
            sapVendorCode: transporterData.sapVendorCode || "",
            coupaRegisterNumber: transporterData.coupaRegisterNumber || "",
            ciotRequirement: transporterData.cioTRequirement ? "YES" : "NO", // Note the capital T
            tallVoucher: transporterData.tallVoucher ? "YES" : "NO",
            externalCode: transporterData.externalCode || "",

            // CNPJ Card Information
            tradeName: transporterData.tradeName || "",
            businessSize: transporterData.businessSize || "",
            mainEconomicActivityCode:
              transporterData.mainEconomicActivityCode || "",
            secondaryEconomicActivityCode:
              transporterData.secondaryEconomicActivityCode || "",
            legalNatureCode: transporterData.legalNatureCode || "",
            legalName: transporterData.legalName || "",
            taxation: transporterData.taxation || "",
            icmsTaxPercentage:
              transporterData.icmsTaxPercentage?.toString() || "",
            address: transporterData.address || "",
            neighborhood: transporterData.neighborhood || "",
            postalCode: transporterData.postalCode || "",
            emailForInvoices: transporterData.emailForInvoices || "",
            city: transporterData.city || "",
          }));

          setBank(bankData);

          if (transporterData.expirationDateCoupa) {
            setExpiryDate(dayjs(transporterData.expirationDateCoupa));
          }
          if (transporterData.companyEstablishmentDate) {
            setEstablishmentDate(
              dayjs(transporterData.companyEstablishmentDate)
            );
          }

          if (transporterData.bankRegistrationId) {
            fetchBranchDetails(transporterData.bankRegistrationId);
          }
        } catch (error) {
          console.error("Error fetching transporter data:", error);
          setSeverity("error");
          setMessage("Failed to fetch transporter details");
          setOpenAlert(true);
        }
      }
    };

    fetchTransporterData();
  }, [row?.transporterID, TM, FT]);

  // Add this useEffect after your other useEffects
  useEffect(() => {
    if (row?.bankRegistrationId && bank.length > 0) {
      const selectedBank = bank.find(
        (b) => b.bankRegistrationId === row.bankRegistrationId
      );
      if (selectedBank) {
        setValues((prevValues) => ({
          ...prevValues,
          bankCode: selectedBank.bankCode,
          bankRegistrationId: row.bankRegistrationId,
        }));

        // Also fetch branch details if needed
        fetchBranchDetails(row.bankRegistrationId);
      }
    }
  }, [row, bank]); // Depend on both row and bank array

  useEffect(() => {
    const initializeBankData = async () => {
      if (row?.bankRegistrationId) {
        try {
          const response = await TransporterGetService.getBankDetails();
          const bankData = response.data.response;
          setBank(bankData);

          const selectedBank = bankData.find(
            (b) => b.bankRegistrationId === row.bankRegistrationId
          );
          if (selectedBank) {
            setValues((prevValues) => ({
              ...prevValues,
              bankCode: selectedBank.bankCode,
            }));
          }
        } catch (error) {
          console.error("Error initializing bank data:", error);
        }
      }
    };

    initializeBankData();
  }, [row?.bankRegistrationId]); // Only run when bankRegistrationId changes

  // Add this function to handle partner field changes
  const handlePartnerChange = (partnerId, field, value) => {
    setPartners((prevPartners) =>
      prevPartners.map((partner) =>
        partner.id === partnerId ? { ...partner, [field]: value } : partner
      )
    );
  };

  // Update the common TextField styling to include proper spacing for error messages
  const commonTextFieldSx = {
    "& .MuiOutlinedInput-root": {
      padding: "7px",
      minHeight: "50px",
    },
    "& .MuiInputLabel-root": {
      lineHeight: "1.2em",
    },
    "& .MuiFormHelperText-root": {
      margin: 0,
      marginTop: "3px", // Add some space between field and error message
      position: "absolute",
      bottom: "-20px", // Position error message below the field
    },
    marginBottom: "20px", // Add bottom margin to prevent overlap with next field
  };

  // Add these functions to check for validation errors in specific sections
  const hasTransporterDataErrors = () => {
    const relevantFields = [
      "fiscalID",
      "transporterName",
      "nationalityId",
      "maritalStatusId",
      "regionId",
      "stateRegistrationNumber",
      "rntrc",
      "buyingStation",
      "buyingStationRoute",
      "supplyInvoicingUnit",
      "supplyInvoicingUnitRoute",
      "businessNumber",
    ];

    return relevantFields.some((field) => !!errors[field]);
  };

  const hasBankInfoErrors = () => {
    const relevantFields = [
      "bankAccountType",
      "bankRegistrationId",
      "bankBranchRegistrationId",
      "accountNumber",
    ];

    return relevantFields.some((field) => !!errors[field]);
  };

  return (
    // <Layout title={"Transporter Registration"} background="#ffffff">
    <Box
      sx={{
        marginTop: "20px",
        minHeight: "125vh",
        flexGrow: 1,
        paddingBottom: "10rem",
        width: "100%",
        "& .MuiTextField-root": { m: 1 },
        "& .MuiFormLabel-root": {
          color: "rgba(16, 42, 98, 1)",
          "& .MuiFormLabel-asterisk": {
            color: "rgba(239, 0, 0, 1)",
            fontSize: "18px",
          },
        },
      }}
    >
      <form autoComplete="off" noValidate>
        <Box
          sx={{
            bgcolor: "#EFEFEF",
            height: hasTransporterDataErrors() ? "500px" : "440px",
            minHeight: "auto", // Changed to auto
            padding: "10px",
            borderRadius: "5px",
            marginBottom: 1, // Added small margin bottom
          }}
        >
          <Box sx={{ display: "flex" }}>
            <ArrowDropDownIcon sx={{ marginRight: 1 }} />
            <Typography>Transporter Data</Typography>
          </Box>

          <Grid item xs={12} sm={6} md={12}>
            <FormControl component="fieldset" required>
              <FormLabel component="legend" sx={{ marginLeft: 1 }}>
                {t("TransportationRegistration.formLabels.businessType")}
              </FormLabel>
              <RadioGroup
                row
                sx={{ marginLeft: 1 }}
                value={values.BusinessType} // Add this line
                name="BusinessType"
              >
                <FormControlLabel
                  value="Individual"
                  onChange={handleChange}
                  control={<Radio />}
                  label={t(
                    "TransportationRegistration.businessTypes.individual"
                  )}
                  disabled={!edit}
                />
                <FormControlLabel
                  value="Legal Entity"
                  onChange={handleChange}
                  control={<Radio />}
                  label={t(
                    "TransportationRegistration.businessTypes.legalEntity"
                  )}
                  disabled={!edit}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {values.BusinessType == "Individual" ? (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <TextField
                      name="fiscalID"
                      label={t(
                        "TransportationRegistration.formLabels.fiscalId"
                      )}
                      value={values.fiscalID}
                      onChange={handleChange}
                      disabled={!edit}
                      fullWidth
                      error={!!errors.fiscalID}
                      helperText={errors.fiscalID || ""}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "7px",
                          minHeight: "50px",
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "1.2em",
                        },
                        "& .MuiFormHelperText-root": {
                          margin: 0,
                          height: errors.fiscalID ? "20px" : "0px",
                          opacity: errors.fiscalID ? 1 : 0,
                          transition: "all 200ms",
                        },
                        marginBottom: errors.fiscalID ? "20px" : "0px",
                        transition: "margin 200ms",
                      }}
                      InputProps={{
                        style: { height: "50px" },
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={8.8}>
                  <FormControl fullWidth>
                    <TextField
                      name="transporterName"
                      label={t(
                        "TransportationRegistration.formLabels.transporterName"
                      )}
                      value={values.transporterName}
                      onChange={handleChange}
                      disabled={!edit}
                      fullWidth
                      error={!!errors.transporterName}
                      helperText={errors.transporterName || ""}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "7px",
                          minHeight: "50px",
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "1.2em",
                        },
                        "& .MuiFormHelperText-root": {
                          margin: 0,
                          height: errors.transporterName ? "20px" : "0px",
                          opacity: errors.transporterName ? 1 : 0,
                          transition: "all 200ms",
                        },
                        marginBottom: errors.transporterName ? "20px" : "0px",
                        transition: "margin 200ms",
                      }}
                      InputProps={{
                        style: { height: "50px" },
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <TextField
                      name="nationalityId"
                      label={t(
                        "TransportationRegistration.formLabels.nationality"
                      )}
                      value={
                        typeof values.nationalityId === "object"
                          ? values.nationalityId.nationalityId
                          : values.nationalityId
                      }
                      onChange={handleChange}
                      disabled={!edit}
                      fullWidth
                      error={!!errors.nationalityId}
                      helperText={errors.nationalityId || ""}
                      select
                      SelectProps={{ IconComponent: ArrowDropDownIcon }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "7px",
                          minHeight: "50px",
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "1.2em",
                        },
                        "& .MuiFormHelperText-root": {
                          margin: 0,
                          height: errors.nationalityId ? "20px" : "0px",
                          opacity: errors.nationalityId ? 1 : 0,
                          transition: "all 200ms",
                        },
                        marginBottom: errors.nationalityId ? "20px" : "0px",
                        transition: "margin 200ms",
                      }}
                      InputProps={{
                        style: { height: "50px" },
                      }}
                    >
                      {nationality.map((item) => (
                        <MenuItem
                          key={item.nationalityId}
                          value={item.nationalityId}
                        >
                          {item.nationality}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <TextField
                      name="maritalStatusId"
                      label={t(
                        "TransportationRegistration.formLabels.maritalStatus"
                      )}
                      value={values.maritalStatusId || ""} // Add default empty string
                      onChange={handleChange}
                      disabled={!edit}
                      fullWidth
                      error={!!errors.maritalStatusId}
                      helperText={errors.maritalStatusId || ""}
                      select
                      SelectProps={{ IconComponent: ArrowDropDownIcon }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "7px",
                          minHeight: "50px",
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "1.2em",
                        },
                        "& .MuiFormHelperText-root": {
                          margin: 0,
                          height: errors.maritalStatusId ? "20px" : "0px",
                          opacity: errors.maritalStatusId ? 1 : 0,
                          transition: "all 200ms",
                        },
                        marginBottom: errors.maritalStatusId ? "20px" : "0px",
                        transition: "margin 200ms",
                      }}
                      InputProps={{
                        style: { height: "50px" },
                      }}
                    >
                      {marital.map((item) => (
                        <MenuItem
                          key={item.maritalStatusId}
                          value={item.maritalStatusId}
                        >
                          {item.maritalStatus}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <TextField
                      name="region"
                      label={t("TransportationRegistration.formLabels.region")}
                      value={values.region || ""} // Add default empty string
                      onChange={handleChange}
                      disabled={!edit}
                      fullWidth
                      required
                      select
                      SelectProps={{ IconComponent: ArrowDropDownIcon }}
                      error={!!errors.region}
                      helperText={errors.region || ""}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "7px",
                          minHeight: "50px",
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "1.2em",
                        },
                        "& .MuiFormHelperText-root": {
                          margin: 0,
                          height: errors.regionId ? "20px" : "0px",
                          opacity: errors.regionId ? 1 : 0,
                          transition: "all 200ms",
                        },
                        marginBottom: errors.regionId ? "20px" : "0px",
                        transition: "margin 200ms",
                      }}
                      InputProps={{
                        style: { height: "50px" },
                      }}
                    >
                      {region.map((item) => (
                        <MenuItem key={item.regionId} value={item.regionId}>
                          {item.stateCode}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <TextField
                      name="stateRegistrationNumber"
                      label={t(
                        "TransportationRegistration.formLabels.stateRegistrationNumber"
                      )}
                      value={values.stateRegistrationNumber}
                      onChange={handleChange}
                      disabled={!edit}
                      fullWidth
                      error={!!errors.stateRegistrationNumber} // Show error state
                      helperText={errors.stateRegistrationNumber || ""} // Show error message
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "7px",
                          minHeight: "50px",
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "1.2em",
                        },
                        "& .MuiFormHelperText-root": {
                          margin: 0,
                          height: errors.stateRegistrationNumber
                            ? "20px"
                            : "0px",
                          opacity: errors.stateRegistrationNumber ? 1 : 0,
                          transition: "all 200ms",
                        },
                        marginBottom: errors.stateRegistrationNumber
                          ? "20px"
                          : "0px",
                        transition: "margin 200ms",
                      }}
                      InputProps={{
                        style: { height: "50px" },
                      }}
                    >
                      {dataType.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2.8}>
                  <FormControl fullWidth>
                    <TextField
                      name="rntrc"
                      label={t("TransportationRegistration.formLabels.rntrc")}
                      value={values.rntrc}
                      onChange={handleChange}
                      disabled={!edit}
                      fullWidth
                      error={!!errors.rntrc} // Show error state
                      helperText={errors.rntrc || ""} // Show error message
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "7px",
                          minHeight: "50px",
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "1.2em",
                        },
                        "& .MuiFormHelperText-root": {
                          margin: 0,
                          height: errors.transporterLicenseNumber
                            ? "20px"
                            : "0px",
                          opacity: errors.transporterLicenseNumber ? 1 : 0,
                          transition: "all 200ms",
                        },
                        marginBottom: errors.transporterLicenseNumber
                          ? "20px"
                          : "0px",
                        transition: "margin 200ms",
                      }}
                      InputProps={{
                        style: { height: "50px" },
                      }}
                    >
                      {dataType.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <TextField
                      name="businessNumber"
                      label="Business Number"
                      value={values.businessNumber}
                      onChange={handleChange}
                      disabled={!edit}
                      fullWidth
                      error={!!errors.businessNumber} // Show error state
                      helperText={errors.businessNumber || ""} // Show error message
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "7px",
                          minHeight: "50px",
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "1.2em",
                        },
                        "& .MuiFormHelperText-root": {
                          margin: 0,
                          height: errors.businessNumber ? "20px" : "0px",
                          opacity: errors.businessNumber ? 1 : 0,
                          transition: "all 200ms",
                        },
                        marginBottom: errors.businessNumber ? "20px" : "0px",
                        transition: "margin 200ms",
                      }}
                      InputProps={{
                        style: { height: "50px" },
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={8.8}>
                  <FormControl fullWidth>
                    <TextField
                      name="transporterName"
                      label={t(
                        "TransportationRegistration.formLabels.transporterName"
                      )}
                      value={values.transporterName}
                      onChange={handleChange}
                      disabled={!edit}
                      fullWidth
                      error={!!errors.transporterName} // Show error state
                      helperText={errors.transporterName || ""} // Show error message
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "7px",
                          minHeight: "50px",
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "1.2em",
                        },
                        "& .MuiFormHelperText-root": {
                          margin: 0,
                          height: errors.transporterName ? "20px" : "0px",
                          opacity: errors.transporterName ? 1 : 0,
                          transition: "all 200ms",
                        },
                        marginBottom: errors.transporterName ? "20px" : "0px",
                        transition: "margin 200ms",
                      }}
                      InputProps={{
                        style: { height: "50px" },
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <TextField
                      name="region"
                      label={t("TransportationRegistration.formLabels.region")}
                      value={values.region || ""} // Add default empty string
                      onChange={handleChange}
                      disabled={!edit}
                      fullWidth
                      required
                      select
                      SelectProps={{ IconComponent: ArrowDropDownIcon }}
                      error={!!errors.region}
                      helperText={errors.region || ""}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "7px",
                          minHeight: "50px",
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "1.2em",
                        },
                        "& .MuiFormHelperText-root": {
                          margin: 0,
                          height: errors.regionId ? "20px" : "0px",
                          opacity: errors.regionId ? 1 : 0,
                          transition: "all 200ms",
                        },
                        marginBottom: errors.regionId ? "20px" : "0px",
                        transition: "margin 200ms",
                      }}
                      InputProps={{
                        style: { height: "50px" },
                      }}
                    >
                      {region.map((item) => (
                        <MenuItem key={item.regionId} value={item.regionId}>
                          {item.stateCode}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <TextField
                      name="stateRegistrationNumber"
                      label={t(
                        "TransportationRegistration.formLabels.stateRegistrationNumber"
                      )}
                      value={values.stateRegistrationNumber}
                      onChange={handleChange}
                      disabled={!edit}
                      fullWidth
                      error={!!errors.stateRegistrationNumber} // Show error state
                      helperText={errors.stateRegistrationNumber || ""} // Show error message
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "7px",
                          minHeight: "50px",
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "1.2em",
                        },
                        "& .MuiFormHelperText-root": {
                          margin: 0,
                          height: errors.stateRegistrationNumber
                            ? "20px"
                            : "0px",
                          opacity: errors.stateRegistrationNumber ? 1 : 0,
                          transition: "all 200ms",
                        },
                        marginBottom: errors.stateRegistrationNumber
                          ? "20px"
                          : "0px",
                        transition: "margin 200ms",
                      }}
                      InputProps={{
                        style: { height: "50px" },
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3.5}>
                  <FormControl fullWidth>
                    <TextField
                      name="rntrc"
                      label={t("TransportationRegistration.formLabels.rntrc")}
                      value={values.rntrc}
                      onChange={handleChange}
                      disabled={!edit}
                      fullWidth
                      error={!!errors.rntrc} // Show error state
                      helperText={errors.rntrc || ""} // Show error message
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "7px",
                          minHeight: "50px",
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "1.2em",
                        },
                        "& .MuiFormHelperText-root": {
                          margin: 0,
                          height: errors.transporterLicenseNumber
                            ? "20px"
                            : "0px",
                          opacity: errors.transporterLicenseNumber ? 1 : 0,
                          transition: "all 200ms",
                        },
                        marginBottom: errors.transporterLicenseNumber
                          ? "20px"
                          : "0px",
                        transition: "margin 200ms",
                      }}
                      InputProps={{
                        style: { height: "50px" },
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </>
          )}

          {/* Route Details Section */}
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={11.8}>
              <FormControl fullWidth>
              <TextField
                    name="Route Details"
                    label="Route Details"
                    value={values?.sapDataType}
                    onChange={handleChange}
                    disabled={!edit}
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none", // Removes the border
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none", // Ensures no border on hover
                        },
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                        fontWeight: "bold", // Makes label bold
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  ></TextField>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            {" "}
            {/* Added container margin */}
            <Grid item xs={12} sm={6} md={4}>
              {" "}
              {/* Increased item margin */}
              <FormControl fullWidth>
                <TextField
                  name="buyingStation"
                  label="Buying Station"
                  value={values.buyingStation}
                  onChange={handleChange}
                  disabled={edit == false}
                  fullWidth
                  error={!!errors.buyingStation}
                  helperText={errors.buyingStation || ""}
                  select
                  SelectProps={{ IconComponent: ArrowDropDownIcon }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                    "& .MuiFormHelperText-root": {
                      margin: 0,
                      height: errors.buyingStation ? "20px" : "0px",
                      opacity: errors.buyingStation ? 1 : 0,
                      transition: "all 200ms",
                    },
                    marginBottom: errors.buyingStation ? "20px" : "0px",
                    transition: "margin 200ms",
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  <MenuItem value="Posto de Compra de Rio Negro">Posto de Compra de Rio Negro</MenuItem>
                    <MenuItem value="Posto de Compra de Papanduva">Posto de Compra de Papanduva</MenuItem>
                    <MenuItem value="GLT Santa Cruz do Sul">
                    GLT Santa Cruz do Sul
                    </MenuItem>
                    <MenuItem value="Posto de compra de Imbituva">Posto de compra de Imbituva</MenuItem>
                    <MenuItem value="Unidade Rio Negro">
                      Unidade Rio Negro
                    </MenuItem>
                    <MenuItem value="Ararangua">Ararangua</MenuItem>
                    <MenuItem value="Posto de compra de Pouso Redondo">Posto de compra de Pouso Redondo</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={7.8} sx={{ marginBottom: "25px" }}>
              <FormControl fullWidth>
                <TextField
                  name="buyingStationRoute"
                  label="Buying Station-Route (Main Cities)"
                  value={values.buyingStationRoute}
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  error={!!errors.buyingStationRoute}
                  helperText={errors.buyingStationRoute || ""}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                    "& .MuiFormHelperText-root": {
                      margin: 0,
                      height: errors.buyingStationRoute ? "20px" : "0px",
                      opacity: errors.buyingStationRoute ? 1 : 0,
                      transition: "all 200ms",
                    },
                    marginBottom: errors.buyingStationRoute ? "20px" : "0px",
                    transition: "margin 200ms",
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
            {" "}
            {/* Added container margin */}
            <Grid item xs={12} sm={6} md={4} sx={{ marginBottom: "25px" }}>
              {" "}
              {/* Increased item margin */}
              <FormControl fullWidth>
                <TextField
                  name="supplyInvoicingUnit"
                  label="Supply Invoicing Unit"
                  value={values.supplyInvoicingUnit}
                  onChange={handleChange}
                  disabled={edit == false}
                  fullWidth
                  error={!!errors.supplyInvoicingUnit}
                  helperText={errors.supplyInvoicingUnit || ""}
                  select
                  SelectProps={{ IconComponent: ArrowDropDownIcon }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                    "& .MuiFormHelperText-root": {
                      margin: 0,
                      height: errors.supplyInvoicingUnit ? "20px" : "0px",
                      opacity: errors.supplyInvoicingUnit ? 1 : 0,
                      transition: "all 200ms",
                    },
                    marginBottom: errors.supplyInvoicingUnit ? "20px" : "0px",
                    transition: "margin 200ms",
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  <MenuItem value="Posto de Compra de Rio Negro">Posto de Compra de Rio Negro</MenuItem>
                    <MenuItem value="Posto de Compra de Papanduva">Posto de Compra de Papanduva</MenuItem>
                    <MenuItem value="GLT Santa Cruz do Sul">
                    GLT Santa Cruz do Sul
                    </MenuItem>
                    <MenuItem value="Posto de compra de Imbituva">Posto de compra de Imbituva</MenuItem>
                    <MenuItem value="Unidade Rio Negro">
                      Unidade Rio Negro
                    </MenuItem>
                    <MenuItem value="Ararangua">Ararangua</MenuItem>
                    <MenuItem value="Posto de compra de Pouso Redondo">Posto de compra de Pouso Redondo</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={7.8} sx={{ marginBottom: "25px" }}>
              <FormControl fullWidth>
                <TextField
                  name="supplyInvoicingUnitRoute"
                  label="Supply Invoicing Unit-Route (Main Cities)"
                  value={values.supplyInvoicingUnitRoute}
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  error={!!errors.supplyInvoicingUnitRoute}
                  helperText={errors.supplyInvoicingUnitRoute || ""}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                    "& .MuiFormHelperText-root": {
                      margin: 0,
                      height: errors.supplyInvoicingUnitRoute ? "20px" : "0px",
                      opacity: errors.supplyInvoicingUnitRoute ? 1 : 0,
                      transition: "all 200ms",
                    },
                    marginBottom: errors.supplyInvoicingUnitRoute
                      ? "20px"
                      : "0px",
                    transition: "margin 200ms",
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        {/* Bank Information */}

        <Box
          sx={{
            bgcolor: "#EFEFEF",
            height: hasBankInfoErrors() ? "130px" : "100px",
            padding: "10px",
            borderRadius: "5px",
            transition: "height 200ms ease-in-out",
          }}
        >
          {/* Bank Information content */}
          <Box sx={{ display: "flex" }}>
            {" "}
            {/* Reduced margin bottom */}
            <ArrowDropDownIcon sx={{ marginRight: 1 }} />
            <Typography>Bank Information</Typography>
          </Box>

          <Grid container spacing={2}>
            {" "}
            {/* Reduced top margin */}
            <Grid item xs={12} sm={6} md={2} sx={{ marginBottom: "25px" }}>
              {" "}
              {/* Increased bottom margin */}
              <FormControl fullWidth>
                <TextField
                  name="bankAccountType"
                  label="Account Type"
                  value={values.bankAccountType}
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  error={!!errors.bankAccountType}
                  helperText={errors.bankAccountType || ""}
                  select
                  SelectProps={{ IconComponent: ArrowDropDownIcon }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                    "& .MuiFormHelperText-root": {
                      margin: 0,
                      height: errors.bankAccountType ? "20px" : "0px",
                      opacity: errors.bankAccountType ? 1 : 0,
                      transition: "all 200ms",
                    },
                    marginBottom: errors.bankAccountType ? "20px" : "0px",
                    transition: "margin 200ms",
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  <MenuItem value="Checking account">Checking account</MenuItem>
                  <MenuItem value="Savings Account">Savings Account</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2} sx={{ marginBottom: "25px" }}>
              <FormControl fullWidth>
                <TextField
                  name="bankCode"
                  label={t("TransportationRegistration.formLabels.bankCode")}
                  value={values.bankCode}
                  disabled={true}
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" },
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2.9} sx={{ marginBottom: "25px" }}>
              <FormControl fullWidth>
                <TextField
                  name="bankRegistrationId"
                  label="Bank"
                  value={values.bankRegistrationId}
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  error={!!errors.bankRegistrationId}
                  helperText={errors.bankRegistrationId || ""}
                  select
                  SelectProps={{ IconComponent: ArrowDropDownIcon }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                    "& .MuiFormHelperText-root": {
                      margin: 0,
                      height: errors.bankRegistrationId ? "20px" : "0px",
                      opacity: errors.bankRegistrationId ? 1 : 0,
                      transition: "all 200ms",
                    },
                    marginBottom: errors.bankRegistrationId ? "20px" : "0px",
                    transition: "margin 200ms",
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  {bank.map((item) => (
                    <MenuItem
                      key={item.bankRegistrationId}
                      value={item.bankRegistrationId}
                    >
                      {`${item.bankCode}-${item.bankName}`}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2.9} sx={{ marginBottom: "25px" }}>
              <FormControl fullWidth>
                <TextField
                  name="bankBranchRegistrationId"
                  label="Branch Number"
                  value={values.bankBranchRegistrationId}
                  disabled={!edit}
                  onChange={handleChange}
                  fullWidth
                  select
                  SelectProps={{ IconComponent: ArrowDropDownIcon }}
                  error={!!errors.bankBranchRegistrationId}
                  helperText={errors.bankBranchRegistrationId || ""}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                    "& .MuiFormHelperText-root": {
                      margin: 0,
                      height: errors.bankBranchRegistrationId ? "20px" : "0px",
                      opacity: errors.bankBranchRegistrationId ? 1 : 0,
                      transition: "all 200ms",
                    },
                    marginBottom: errors.bankBranchRegistrationId
                      ? "20px"
                      : "0px",
                    transition: "margin 200ms",
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  {branches.map((branch) => (
                    <MenuItem
                      key={branch.bankBranchRegistrationId}
                      value={branch.bankBranchRegistrationId}
                    >
                      {`${branch.branchNumber} - ${branch.branchName}`}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2} sx={{ marginBottom: "25px" }}>
              <FormControl fullWidth>
                <TextField
                  name="accountNumber"
                  label="Account Number"
                  value={values.accountNumber}
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  error={!!errors.accountNumber}
                  helperText={errors.accountNumber || ""}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                    "& .MuiFormHelperText-root": {
                      margin: 0,
                      height: errors.accountNumber ? "20px" : "0px",
                      opacity: errors.accountNumber ? 1 : 0,
                      transition: "all 200ms",
                    },
                    marginBottom: errors.accountNumber ? "20px" : "0px",
                    transition: "margin 200ms",
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        {/* Additional Information */}

        <Box
          sx={{
            marginTop: 2,
            bgcolor: "#EFEFEF",
            height: "500px",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <ArrowDropDownIcon sx={{ marginRight: 1 }} />
            <Typography>Additional Information</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth>
                <TextField
                  name="tmResponsibleUID"
                  label="T. M. Responsible"
                  value={values.tmResponsibleUID || ""}
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  select
                  SelectProps={{ IconComponent: ArrowDropDownIcon }}
                  error={!!errors.tmResponsibleUID}
                  helperText={errors.tmResponsibleUID || ""}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  {TM.map((item) => (
                    <MenuItem key={item.userId} value={item.userId}>
                      {item.userName}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  name="ftResponsibleUID"
                  label="F. T. Responsible"
                  value={values.ftResponsibleUID || ""}
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  select
                  SelectProps={{ IconComponent: ArrowDropDownIcon }}
                  error={!!errors.ftResponsibleUID}
                  helperText={errors.ftResponsibleUID || ""}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  {FT.map((item) => (
                    <MenuItem key={item.userId} value={item.userId}>
                      {item.userName}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2.9}>
              <FormControl fullWidth>
                <TextField
                  name="sapVendorCode"
                  label="SAP Vender Code"
                  value={values.sapVendorCode}
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  error={!!errors.sapVendorCode} // Show error state
                  helperText={errors.sapVendorCode || ""} // Show error message
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  {dataType.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2.9}>
              <FormControl fullWidth>
                <TextField
                  name="coupaRegisterNumber"
                  label="Coupa Registry Number"
                  value={values.coupaRegisterNumber}
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  error={!!errors.coupaRegisterNumber} // Show error state
                  helperText={errors.coupaRegisterNumber || ""} // Show error message
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  {dataType.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name="expirationDateCoupa"
                  label={t(
                    "TransportationRegistration.formLabels.expirationDateCoupa"
                  )}
                  disabled={!edit}
                  value={expiryDate}
                  onChange={(newDate) => setExpiryDate(newDate)}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      sx: {
                        "& .MuiInputBase-root": {
                          height: "50px",
                        },
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth>
                <TextField
                  name="ciotRequirement"
                  label={t(
                    "TransportationRegistration.formLabels.ciotRequirement"
                  )}
                  value={values.ciotRequirement}
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  select
                  SelectProps={{ IconComponent: ArrowDropDownIcon }}
                  error={!!errors.ciotRequirement} // Show error state
                  helperText={errors.ciotRequirement || ""} // Show error message
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  {["YES", "NO"].map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth>
                <TextField
                  name="tallVoucher"
                  label={t("TransportationRegistration.formLabels.tallVoucher")}
                  value={values.tallVoucher}
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  select
                  SelectProps={{ IconComponent: ArrowDropDownIcon }}
                  error={!!errors.tallVoucher} // Show error state
                  helperText={errors.tallVoucher || ""} // Show error message
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  {["YES", "NO"].map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2.9}>
              <FormControl fullWidth>
                <TextField
                  name="externalCode"
                  label="External Code"
                  value={values.externalCode}
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  error={!!errors.externalCode} // Show error state
                  helperText={errors.externalCode || ""} // Show error message
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  {dataType.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={11.8}>
              <FormControl fullWidth>
                <TextField
                  name="CNPJ Card Information"
                  label="CNPJ Card Information"
                  onChange={handleChange}
                  disabled={true}
                  fullWidth
                  error={!!errors.tradeName} // Show error state
                  helperText={errors.tradeName || ""} // Show error message
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "inherit", // Keeps border color consistent on hover
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0)", // Default border color
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                      fontWeight: "600", // Makes label bold
                      color: "#5A646E",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                ></TextField>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <TextField
                  name="tradeName"
                  label={t("TransportationRegistration.formLabels.tradeName")}
                  value={values.tradeName}
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  error={!!errors.tradeName} // Show error state
                  helperText={errors.tradeName || ""} // Show error message
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  {dataType.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2.9}>
              <FormControl fullWidth>
                <TextField
                  name="businessSize"
                  label={t(
                    "TransportationRegistration.formLabels.businessSize"
                  )}
                  value={values.businessSize}
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  error={!!errors.businessSize} // Show error state
                  helperText={errors.businessSize || ""} // Show error message
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  {dataType.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2.9}>
              <FormControl fullWidth>
                <TextField
                  name="mainEconomicActivityCode"
                  label={t(
                    "TransportationRegistration.formLabels.mainEconomicActivityCode"
                  )}
                  value={values.mainEconomicActivityCode}
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  error={!!errors.mainEconomicActivityCode} // Show error state
                  helperText={errors.mainEconomicActivityCode || ""} // Show error message
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  {dataType.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  name="secondaryEconomicActivityCode"
                  label={t(
                    "TransportationRegistration.formLabels.secondaryEconomicActivityCode"
                  )}
                  value={values.secondaryEconomicActivityCode}
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  error={!!errors.secondaryEconomicActivityCode} // Show error state
                  helperText={errors.secondaryEconomicActivityCode || ""} // Show error message
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  {dataType.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth>
                <TextField
                  name="legalNatureCode"
                  label={t(
                    "TransportationRegistration.formLabels.legalNatureCode"
                  )}
                  value={values.legalNatureCode}
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  error={!!errors.legalNatureCode} // Show error state
                  helperText={errors.legalNatureCode || ""} // Show error message
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  {dataType.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  name="legalName"
                  label={t("TransportationRegistration.formLabels.legalName")}
                  value={values.legalName}
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  error={!!errors.legalName} // Show error state
                  helperText={errors.legalName || ""} // Show error message
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  {dataType.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={1.9}>
              <FormControl fullWidth>
                <TextField
                  name="taxation"
                  label={t("TransportationRegistration.formLabels.taxation")}
                  value={values.taxation}
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  error={!!errors.taxation} // Show error state
                  helperText={errors.taxation || ""} // Show error message
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  {dataType.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={1.9}>
              <FormControl fullWidth>
                <TextField
                  name="icmsTaxPercentage"
                  label={t("TransportationRegistration.formLabels.icmsTax")}
                  value={values.icmsTaxPercentage}
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  error={!!errors.icmsTaxPercentage} // Show error state
                  helperText={errors.icmsTaxPercentage || ""} // Show error message
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  {dataType.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name="companyEstablishmentDate"
                  inputFormat="DD-MM-YYYY"
                  disabled={!edit}
                  label={t(
                    "TransportationRegistration.formLabels.companyEstablishmentDate"
                  )}
                  value={establishmentDate}
                  onChange={(newDate) => setEstablishmentDate(newDate)}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      sx: {
                        "& .MuiInputBase-root": {
                          height: "50px",
                        },
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <FormControl fullWidth>
                <TextField
                  name="address"
                  label={t("TransportationRegistration.formLabels.address")}
                  value={values.address}
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  error={!!errors.address} // Show error state
                  helperText={errors.address || ""} // Show error message
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  {dataType.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={1.9}>
              <FormControl fullWidth>
                <TextField
                  name="neighborhood"
                  label={t(
                    "TransportationRegistration.formLabels.neighborhood"
                  )}
                  value={values.neighborhood}
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  error={!!errors.neighborhood} // Show error state
                  helperText={errors.neighborhood || ""} // Show error message
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  {dataType.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={1.9}>
              <FormControl fullWidth>
                <TextField
                  name="postalCode"
                  label={t("TransportationRegistration.formLabels.postalCode")}
                  value={values.postalCode}
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  error={!!errors.postalCode} // Show error state
                  helperText={errors.postalCode || ""} // Show error message
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  {dataType.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  name="region"
                  label={t("TransportationRegistration.formLabels.region")}
                  value={values.region || ""} // Add default empty string
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  required
                  select
                  SelectProps={{ IconComponent: ArrowDropDownIcon }}
                  error={!!errors.region} // Show error state
                  helperText={errors.region || ""} // Show error message
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  {region.map((item) => (
                    <MenuItem key={item.regionId} value={item.regionId}>
                      {item.stateCode}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  name="city"
                  label={t("TransportationRegistration.formLabels.city")}
                  value={values.city || ""} // Add default empty string
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  select
                  SelectProps={{ IconComponent: ArrowDropDownIcon }}
                  error={!!errors.city} // Show error state
                  helperText={errors.city || ""} // Show error message
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  {city.map((item) => (
                    <MenuItem key={item.cityId} value={item.cityName}>
                      {item.cityName}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={5.8}>
              <FormControl fullWidth>
                <TextField
                  name="emailForInvoices"
                  label={t(
                    "TransportationRegistration.formLabels.emailForInvoices"
                  )}
                  value={values.emailForInvoices}
                  onChange={handleChange}
                  disabled={!edit}
                  fullWidth
                  error={!!errors.emailForInvoices} // Show error state
                  helperText={errors.emailForInvoices || ""} // Show error message
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  {dataType.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px 2px",
              background: "#FFFFFF",
              marginTop: 3,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20px",
                color: "#5A646E",
                flex: "none",
                order: 0,
                flexGrow: 0,
                zIndex: 0,
              }}
            >
              Partners List
            </Typography>
            {edit && (
              <Button
                variant="contained"
                onClick={handleAddPartner}
                sx={{
                  minWidth: 95,
                  borderRadius: 1,
                  background: "#004F9F",
                  height: { xs: "40px", md: "44px" },
                  marginLeft: { xs: 0, md: "auto" },
                }}
              >
                {t("addNew")}
              </Button>
            )}
          </Box>
          <Box
            id="divider"
            sx={{
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "0px 20px 0px",
              gap: "20px",
              isolation: "isolate",
              background: "#FFFFFF",
              borderBottom: "5px solid #004F9F",
              flex: "none",
              order: 2,
              alignSelf: "stretch",
              flexGrow: 0,
              zIndex: 1,
            }}
          ></Box>
        </Box>
        {partners.slice(0, 5).map((partner) => (
          <Box
            key={partner.id}
            sx={{
              marginTop: 2,
              bgcolor: "#EFEFEF",
              height: "150px",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3} sx={{ marginBottom: "20px" }}>
                <FormControl fullWidth>
                  <TextField
                    name="plName"
                    label={t(
                      "TransportationRegistration.formLabels.partnerName"
                    )}
                    value={partner.plName || ""}
                    onChange={(e) =>
                      handlePartnerChange(partner.id, "plName", e.target.value)
                    }
                    disabled={!edit}
                    fullWidth
                    error={!!errors.plName} // Show error state
                    helperText={errors.plName || ""} // Show error message
                    sx={{
                      ...commonTextFieldSx,
                      marginBottom: errors.plName ? "25px" : "20px", // Additional space if there's an error
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                    {dataType.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={3} sx={{ marginBottom: "20px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="plDOB"
                    disabled={!edit}
                    label={t(
                      "TransportationRegistration.formLabels.partnerDob"
                    )}
                    value={partner.plDOB ? dayjs(partner.plDOB) : null}
                    onChange={(newDate) =>
                      handlePartnerChange(partner.id, "plDOB", newDate)
                    }
                    required
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        required: true,
                        FormLabelProps: {
                          sx: {
                            "&::after": {
                              content: '" *"',
                              color: "red",
                            },
                          },
                        },
                        sx: {
                          "& .MuiInputBase-root": {
                            height: "50px",
                          },
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={2} sx={{ marginBottom: "20px" }}>
                <FormControl fullWidth>
                  <TextField
                    name="plFiscalID"
                    label={t(
                      "TransportationRegistration.formLabels.partnerFiscalId"
                    )}
                    value={partner.plFiscalID || ""}
                    onChange={(e) =>
                      handlePartnerChange(
                        partner.id,
                        "plFiscalID",
                        e.target.value
                      )
                    }
                    disabled={!edit}
                    fullWidth
                    error={!!errors.plFiscalID} // Show error state
                    helperText={errors.plFiscalID || ""} // Show error message
                    sx={{
                      ...commonTextFieldSx,
                      marginBottom: errors.plFiscalID ? "25px" : "20px", // Additional space if there's an error
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                    {dataType.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2} sx={{ marginBottom: "20px" }}>
                <FormControl fullWidth>
                  <TextField
                    name="plCardId"
                    label={t(
                      "TransportationRegistration.formLabels.partnerIdCard"
                    )}
                    value={partner.plCardId || ""}
                    onChange={(e) =>
                      handlePartnerChange(
                        partner.id,
                        "plCardId",
                        e.target.value
                      )
                    }
                    disabled={!edit}
                    fullWidth
                    error={!!errors.plCardId} // Show error state
                    helperText={errors.plCardId || ""} // Show error message
                    sx={{
                      ...commonTextFieldSx,
                      marginBottom: errors.plCardId ? "25px" : "20px", // Additional space if there's an error
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                    {dataType.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={0.5}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 19,
                }}
              >
                <img
                  className="btn delete-btn"
                  src={_ButtonDelete_}
                  alt="Delete"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleDeletePartner(
                      partner.partnersListId
                        ? partner.partnersListId
                        : partner.id
                    )
                  }
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3} sx={{ marginBottom: "20px" }}>
                <FormControl fullWidth>
                  <TextField
                    name="plEmail"
                    label="Email"
                    value={partner.plEmail || ""}
                    onChange={(e) =>
                      handlePartnerChange(partner.id, "plEmail", e.target.value)
                    }
                    disabled={!edit}
                    fullWidth
                    error={!!errors.plEmail} // Show error state
                    helperText={errors.plEmail || ""} // Show error message
                    sx={{
                      ...commonTextFieldSx,
                      marginBottom: errors.plEmail ? "25px" : "20px", // Additional space if there's an error
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                    {dataType.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3} sx={{ marginBottom: "20px" }}>
                <FormControl fullWidth>
                  <TextField
                    name="plPhoneNumber"
                    label="Telephone"
                    value={partner.plPhoneNumber || ""}
                    onChange={(e) =>
                      handlePartnerChange(
                        partner.id,
                        "plPhoneNumber",
                        e.target.value
                      )
                    }
                    disabled={!edit}
                    fullWidth
                    error={!!errors.plPhoneNumber} // Show error state
                    helperText={errors.plPhoneNumber || ""} // Show error message
                    sx={{
                      ...commonTextFieldSx,
                      marginBottom: errors.plPhoneNumber ? "25px" : "20px", // Additional space if there's an error
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                    {dataType.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        ))}

        {/* Buttons */}
        {/* <hr style={{ marginTop: "200px" }} /> */}
        <Divider
          sx={{
            position: "fixed",
            bottom: "82px",
            width: "97%",
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            position: "fixed",
            width: "96%",
            bottom: 22,
            padding: "0.5rem",
            background: "white",
            zIndex: 999,
          }}
        >
          {edit && (
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: "6px 16px",
                borderRadius: "4px",
                height: "44px",
                marginLeft: "10px",
              }}
              variant="contained"
              onClick={handleOpenModal}
              disabled={loading}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
            >
              {loading && <CircularProgress size={20} />}
              {edit ? "Cancel" : "Back"}
            </Button>
          )}
          {!edit && (
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: "6px 16px",
                borderRadius: "4px",
                height: "44px",
                marginLeft: "10px",
              }}
              variant="contained"
              onClick={() => navigate("/transporterRegistration")}
              disabled={loading}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
            >
              {loading && <CircularProgress size={20} />}
              {edit ? "Cancel" : "Back"}
            </Button>
          )}

          <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                backgroundColor: "#fff",
                borderRadius: "7px",
                width: "585px",
                margin: "auto",
                mt: 25,
                height: "200px",
              }}
            >
              <Box
                sx={{
                  background: "#102A62",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderTopLeftRadius: "7px",
                  borderTopRightRadius: "7px",
                }}
              >
                <Typography
                  sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                >
                  {t("cancelTitle")}
                </Typography>
              </Box>
              <Box sx={{ p: 2 }}>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2, fontWeight: 400 }}
                >
                  {t("cancelMessage")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                  mb: 1,
                }}
              >
                <Button
                  onClick={handleCloseModal}
                  sx={{
                    background: "#737D86",
                    width: "100px",
                    color: "#ffffff",
                    "&:hover": { background: "#737D86" },
                  }}
                >
                  {t("no")}
                </Button>
                <Button
                  onClick={handleDeleteConfirm}
                  sx={{
                    background: "#004F9F",
                    width: "100px",
                    color: "#ffffff",
                    "&:hover": { background: "#004F9F" },
                  }}
                >
                  {t("yes")}
                </Button>
              </Box>
            </Box>
          </Modal>

          {edit && (
            <Button
              sx={{
                padding: "6px 16px",
                borderRadius: "4px",
                backgroundColor: "#EF7D00",
                color: "white",
                "&:hover": {
                  backgroundColor: "#EF7D00",
                },
                marginRight: "10px",
              }}
              onClick={handleUpdate}
              disabled={loading}
              startIcon={<img src={Save} alt="Right Arrow" />}
              variant="contained"
            >
              {loading && <CircularProgress size={20} />}
              {"Save"}
            </Button>
          )}
        </Box>
      </form>
    </Box>
    // </Layout>
  );
}
