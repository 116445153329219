import React, { useState, useRef, useEffect } from 'react';
import { 
  Modal, 
  Box, 
  Typography, 
  Button, 
  TextField, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel,
  CircularProgress
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from "@mui/icons-material/Add";
import deleteIconNew from "assets/deleteIconNew.svg";
import MSCService from "services/MSCService";

const MSCModal = ({ 
  open, 
  handleCloseModal, 
  title = "Menu Item", 
  message = "", 
  onConfirm,
  confirmText = "SAVE",
  cancelText = "CANCEL",
  initialData = null,
  parentMenuId = null
}) => {
  const { t } = useTranslation();
  const [menuOrder, setMenuOrder] = useState('');
  const [description, setDescription] = useState('');
  const [menuType, setMenuType] = useState('');
  const [functionality, setFunctionality] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const inputFileRef = useRef(null);
  const [url, setUrl] = useState('');
  
  // New state for functionalities
  const [functionalities, setFunctionalities] = useState([]);

  // Fetch functionalities when modal opens
  useEffect(() => {
    const fetchFunctionalities = async () => {
      try {
        // Replace with actual country ID from your context/state
        const countryId = '9B72D29C-1555-480B-AC8B-3B10F383B5F6';
        const response = await MSCService.getFunctionalitiesAPI(countryId);
        
        if (response?.data?.response) {
          setFunctionalities(response.data.response);
        }
      } catch (error) {
        console.error('Error fetching functionalities:', error);
      }
    };

    if (open && menuType === 'Functionality') {
      fetchFunctionalities();
    }
  }, [open, menuType]);

  // Reset form when modal opens or closes
  useEffect(() => {
    console.log('Initial Data:', initialData);
    if (open && initialData) {
      // Pre-fill form with existing data when editing
      setMenuOrder(initialData.menuOrder || '');
      setDescription(initialData.description || '');
      setMenuType(initialData.menuType || '');
      setFunctionality(initialData.functionality || '');
      
      // Handle menuIcon - support both URL and base64
      if (initialData.menuIcon) {
        // If it's a URL, set it directly
        if (initialData.menuIcon.startsWith('http')) {
          setImageFile(
            `${initialData.menuIcon}${process.env.REACT_APP_SAS}`
          );
        } 
        // If it's a base64 string
        else if (initialData.menuIcon.startsWith('data:')) {
          setImageFile(initialData.menuIcon);
        }
        // If it's a base64 content without data URL prefix
        else {
          setImageFile(`data:image/png;base64,${initialData.menuIcon}`);
        }
      } else {
        setImageFile(null);
      }

      setUrl(initialData.url || '');
    } else if (!open) {
      // Reset form when modal closes
      setMenuOrder('');
      setDescription('');
      setMenuType('');
      setFunctionality('');
      setImageFile(null);
      setUrl('');
    }
  }, [open, initialData]);

  // File upload handlers
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const isValidType = ["image/png", "image/jpeg"].includes(file.type);
    
    if (isValidType) {
      setImageFile(file);
    } else {
      alert("Please upload a PNG or JPEG image.");
    }
  };

  const handleImageDelete = () => {
    setImageFile(null);
  };

  const onBtnClick = () => {
    inputFileRef.current.click();
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files[0];
    if (file) {
      const isValidType = ["image/png", "image/jpeg"].includes(file.type);
      if (isValidType) {
        setImageFile(file);
      } else {
        alert("Please upload a PNG or JPEG image.");
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "7px",
          width: "585px",
          margin: "auto",
          mt: 25,
          height: "600px", // Increased height to accommodate image upload
        }}
      >
        <Box
          sx={{
            background: "#102A62",
            height: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTopLeftRadius: "7px",
            borderTopRightRadius: "7px",
          }}
        >
          <Typography
            sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
          >
            {title}
          </Typography>
        </Box>

        <Box 
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 3,
            mb: 2,
            px: 3
          }}
        >
          <TextField
            label="Menu Order"
            variant="outlined"
            fullWidth
            value={menuOrder}
            onChange={(e) => setMenuOrder(e.target.value)}
            sx={{ 
              width: '100%' 
            }}
          />
        </Box>

        <Box 
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 2,
            px: 3
          }}
        >
          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{ 
              width: '100%' 
            }}
            multiline
            rows={2}
          />
        </Box>

        <Box 
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 2,
            px: 3
          }}
        >
          <FormControl 
            variant="outlined" 
            fullWidth 
            sx={{ 
              width: '100%' 
            }}
          >
            <InputLabel>Menu Type</InputLabel>
            <Select
              value={menuType}
              onChange={(e) => {
                setMenuType(e.target.value);
                // Reset URL and functionality when menu type changes
                if (e.target.value !== 'Link') {
                  setUrl('');
                }
                if (e.target.value !== 'Functionality') {
                  setFunctionality('');
                }
              }}
              label="Menu Type"
            >
              <MenuItem value="Category">Category</MenuItem>
              <MenuItem value="Functionality">Functionality</MenuItem>
              <MenuItem value="Link">Link</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* Functionality Dropdown */}
        {menuType === 'Functionality' && (
          <Box 
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mb: 2,
              px: 3
            }}
          >
            <FormControl 
              variant="outlined" 
              fullWidth 
              sx={{ 
                width: '100%' 
              }}
            >
              <InputLabel>Functionality</InputLabel>
              <Select
                value={functionality}
                onChange={(e) => setFunctionality(e.target.value)}
                label="Functionality"
              >
                {functionalities.map((func) => (
                  <MenuItem key={func.menuId} value={func.menuName}>
                    {func.menuName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}

        {menuType === 'Link' && (
          <Box 
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mb: 2,
              px: 3
            }}
          >
            <TextField
              label="URL"
              variant="outlined"
              fullWidth
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              sx={{ 
                width: '100%' 
              }}
              required
            />
          </Box>
        )}

        {/* Image Upload Section */}
        <Box 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            mt: 2,
            mb: 2,
            px: 3
          }}
        >
          <Typography 
            variant="subtitle1" 
            sx={{ 
              mb: 1, 
              color: 'rgba(0, 0, 0, 0.6)', 
              fontWeight: 500,
              alignSelf: 'flex-start'
            }}
          >
            Menu Icon
          </Typography>
          <Box
            sx={{
              width: "79px", // Fixed width
              height: "79px", // Fixed height
              padding: "10px",
              borderRadius: "4px",
              border: "1px dashed gray",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#FFFFFF",
              cursor: !imageFile ? "pointer" : "default",
              "&:hover": {
                backgroundColor: !imageFile ? "#0000003B" : "inherit",
              },
            }}
            onClick={!imageFile ? onBtnClick : undefined}
            onDragOver={onDragOver}
            onDrop={onDrop}
          >
            <input
              style={{ display: "none" }}
              ref={inputFileRef}
              type="file"
              accept=".png, .jpeg"
              onChange={handleFileUpload}
            />

            {!imageFile ? (
              <>
                <AddIcon fontSize="large" sx={{ color: "gray" }} />
                <Typography variant="body1" sx={{ color: "gray" }}>
                  Upload
                </Typography>
              </>
            ) : (
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  "&:hover .delete-icon": {
                    display: "flex",
                  },
                }}
              >
                <img
                  src={imageFile instanceof File ? URL.createObjectURL(imageFile) : imageFile}
                  alt="Preview"
                  style={{ 
                    width: "100%", 
                    height: "100%", 
                    objectFit: "contain", 
                    maxHeight: "79px" 
                  }}
                />
                <Box
                  className="delete-icon"
                  sx={{
                    display: "none",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    borderRadius: "50%",
                    padding: 1,
                    cursor: "pointer",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleImageDelete();
                  }}
                >
                  <img
                    src={deleteIconNew}
                    alt="Delete"
                    style={{
                      width: "24px",
                      height: "24px",
                      objectFit: "contain",
                      pointerEvents: "none",
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            mb: 1,
          }}
        >
          <Button
            onClick={handleCloseModal}
            sx={{
              background: "#737D86",
              width: "100px",
              color: "#ffffff",
              "&:hover": { background: "#737D86" },
            }}
          >
            {cancelText}
          </Button>
          <Button
            onClick={() => {
              onConfirm?.({ 
                menuOrder,     
                description, 
                menuType, 
                functionality, 
                url, 
                imageFile,
                parentMenuId: parentMenuId || initialData?.parentMenuId 
              });
            }}
            sx={{
              background: "#EF7D00",
              width: "100px",
              color: "#ffffff",
              "&:hover": { background: "#EF7D00" },
            }}
          >
            {confirmText}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default MSCModal;