import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  MenuItem,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import AgriculturalSupplyConfigurationService from "services/AscService";
import AgriculturalSupplyConfigurationTable from "./AgriculturalSupplyConfigurationTable";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "context/snackBarContext";

export default function AgriculturalSupplyConfiguration() {
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [values, setValues] = useState({
    cropYear: "",
    cropSeasonId: "",
    geneticTypeId: "",
    tobaccoGenericType: "",
    agriculturalSupply: "",
    applicationPhase: "",
    hasTarget: "",
    useThisRecord: null,
  });
  const [options, setOptions] = useState({
    cropSeasonOptions: [],
    tobaccoGenericTypeOptions: [],
    applicationPhaseOptions: [],
    hasTargetOptions: ["Yes", "No"],
  });
  const [tableData, setTableData] = useState([]);
  const [isDeleted, setIsDeleted] = useState(0);
  const { t } = useTranslation();
  const debounceTimeout = useRef(null); // Declare useRef at the top level of the component

  const handleAgriculturalSupplyChange = (event, newValue) => {
    setValues((prev) => ({
      ...prev,
      agriculturalSupply: newValue,
    }));
    setCurrentPage(0);

    // Clear the previous timeout to debounce the API call
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new timeout to call the API after 2 seconds
    debounceTimeout.current = setTimeout(() => {
      if (newValue) {
        values.cropSeasonId &&
          values.geneticTypeId &&
          searchAgriculturalSCAPI(
            0,
            itemsPerPage,
            values.useThisRecord,
            newValue,
            values.applicationPhase,
            values.hasTarget,
            values.cropSeasonId,
            values.geneticTypeId
          );
      } else {
        values.cropSeasonId &&
          values.geneticTypeId &&
          searchAgriculturalSCAPI(
            0,
            itemsPerPage,
            values.useThisRecord,
            "",
            values.applicationPhase,
            values.hasTarget,
            values.cropSeasonId,
            values.geneticTypeId
          );
      }
    }, 1500);
  };

  const fetchTobaccoGenericTypes = () => {
    AgriculturalSupplyConfigurationService.getTobaccoGenericTypes()
      .then((res) => {
        const typeList = res?.data?.response.map((ele) => ({
          geneticTypeId: ele.gtGeneticTypeId,
          tobaccoGenericType: ele.gtGeneticDescription,
        }));
        setOptions((prev) => ({
          ...prev,
          tobaccoGenericTypeOptions: typeList,
        }));
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const fetchCropSeasonOptions = () => {
    AgriculturalSupplyConfigurationService.getCropSeasons()
      .then((res) => {
        const seasonList = res?.data?.response.cropSeason.map((ele) => ({
          cropSeasonId: ele.cropSeasonId,
          cropYear: ele.cropYear,
          season: ele.season,
        }));
        setOptions((prev) => ({
          ...prev,
          cropSeasonOptions: seasonList,
        }));
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const fetchApplicationPhases = () => {
    AgriculturalSupplyConfigurationService.getApplicationPhases()
      .then((res) => {
        const phaseList = res?.data?.response || [];
        setOptions((prev) => ({
          ...prev,
          applicationPhaseOptions: phaseList,
        }));
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const callAgriculturalSCAPI = (
    pageNumber,
    size,
    cropSeasonId,
    geneticTypeId
  ) => {
    const batOneId = 111111;
    const createdByUser = "UI";
    AgriculturalSupplyConfigurationService.getAPI({
      pageNumber,
      size,
      cropSeasonId,
      geneticTypeId,
      batOneId,
      createdByUser,
    })
      .then((res) => {
        const ascResults =
          res?.data?.response?.["Agricultural Supplies Configuration"] || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setTableData(ascResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setTableData([]);
        setTotalItems(0);
      });
  };

  const searchAgriculturalSCAPI = (
    pageNumber,
    size,
    useThisRecord,
    agriSupplyName,
    applicationPhase,
    target,
    cropSeasonId,
    geneticTypeId
  ) => {
    const batOneId = 111111;
    AgriculturalSupplyConfigurationService.getSearchAPI({
      pageNumber,
      size,
      useThisRecord,
      agriSupplyName,
      applicationPhase,
      target,
      batOneId,
      cropSeasonId,
      geneticTypeId,
    })
      .then((res) => {
        const ascResults =
          res?.data?.response?.["Agricultural Supplies Configuration"] || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setTableData(ascResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setTableData([]);
        setTotalItems(0);
      });
  };

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    setItemsPerPage(itemsPerPage);
  };

  useEffect(() => {
    if (
      !values.agriculturalSupply &&
      !values.applicationPhase &&
      !values.hasTarget &&
      !values.useThisRecord &&
      values.cropSeasonId &&
      values.geneticTypeId
    ) {
      callAgriculturalSCAPI(
        currentPage,
        itemsPerPage,
        values.cropSeasonId,
        values.geneticTypeId
      );
    } else if (
      (values.agriculturalSupply ||
        values.applicationPhase ||
        values.hasTarget ||
        values.useThisRecord) &&
      values.cropSeasonId &&
      values.geneticTypeId
    ) {
      searchAgriculturalSCAPI(
        currentPage,
        itemsPerPage,
        values.useThisRecord,
        values.agriculturalSupply,
        values.applicationPhase,
        values.hasTarget,
        values.cropSeasonId,
        values.geneticTypeId
      );
    }
  }, [
    currentPage,
    itemsPerPage,
    values.cropSeasonId,
    values.geneticTypeId,
    isDeleted,
  ]);

  useEffect(() => {
    fetchTobaccoGenericTypes();
    fetchCropSeasonOptions();
    fetchApplicationPhases();
  }, []);

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t("AgriculturalSupplyConfiguration.pageTitle")}
      background="#ffffff"
      useNewPagination={true}
    >
      <Grid
        container
        id="mainfilter"
        spacing={2}
        sx={{
          background: "#EFEFEF",
          borderRadius: "10px",
          p: 2,
          maxWidth: "100%",
          margin: "auto",
          padding: "20px",
          paddingTop: "10px",
          marginBottom: "8px",
          color: "#666666",
        }}
      >
        <Grid
          item
          xs={12}
          style={{ paddingTop: "0px", paddingLeft: "0px", fontWeight: "bold" }}
        >
          <Typography variant="h8" display="flex">
            <ArrowDropDownIcon />
            {t("AgriculturalSupplyConfiguration.mandatoryFiltersLabel")}
          </Typography>
        </Grid>

        {/* Crop Season */}
        <Grid item xs={6} style={{ paddingLeft: "0px" }}>
          <FormControl sx={{ width: { xs: "100%" }, minHeight: 50 }}>
            <TextField
              select
              name="cropYear"
              label={t("AgriculturalSupplyConfiguration.cropSeason")}
              value={values.cropYear || "Select an Option"}
              onChange={(e) => {
                setCurrentPage(0);
                setValues((prev) => ({
                  ...prev,
                  cropYear: e.target.value,
                  cropSeasonId: options.cropSeasonOptions.find(
                    (ele) => ele.cropYear === e.target.value
                  ).cropSeasonId,
                }));
              }}
              placeholder="Select an Option"
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "7px",
                  minHeight: "50px",
                },
                "& .MuiInputLabel-root": {
                  lineHeight: "1.2em",
                },
              }}
              InputProps={{
                style: { height: "50px" },
                endAdornment: values.cropYear && (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() =>
                        setValues((prev) => ({
                          ...prev,
                          cropYear: "",
                        }))
                      }
                      style={{ marginRight: "15px" }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value="Select an Option" sx={{ display: "none" }}>
                {t("selectoption")}
              </MenuItem>
              {options.cropSeasonOptions.map((crop, index) => (
                <MenuItem key={index} value={crop.cropYear}>
                  {crop.cropYear}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>

        {/* Tobacco Generic Type */}
        <Grid item xs={6}>
          <FormControl sx={{ width: { xs: "100%" }, minHeight: 50 }}>
            <TextField
              select
              name="genericType"
              label={t("AgriculturalSupplyConfiguration.tobaccoGenericType")}
              value={values.tobaccoGenericType || "Select an Option"}
              onChange={(e) => {
                setCurrentPage(0);
                setValues((prev) => ({
                  ...prev,
                  tobaccoGenericType: e.target.value,
                  geneticTypeId: options.tobaccoGenericTypeOptions.find(
                    (ele) => ele.tobaccoGenericType === e.target.value
                  ).geneticTypeId,
                }));
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "7px",
                  minHeight: "50px",
                },
                "& .MuiInputLabel-root": {
                  lineHeight: "1.2em",
                },
              }}
              InputProps={{
                style: { height: "50px" },
                endAdornment: values.tobaccoGenericType && (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() =>
                        setValues((prev) => ({
                          ...prev,
                          tobaccoGenericType: "",
                        }))
                      }
                      style={{ marginRight: "15px" }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value="Select an Option" sx={{ display: "none" }}>
                {t("selectoption")}
              </MenuItem>
              {options.tobaccoGenericTypeOptions.map((type, index) => (
                <MenuItem key={index} value={type.tobaccoGenericType}>
                  {type.tobaccoGenericType}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        container
        id="mainfilter"
        spacing={2}
        sx={{
          background: "#EFEFEF",
          borderRadius: "10px",
          p: 2,
          maxWidth: "100%",
          margin: "auto",
          padding: "20px",
          paddingTop: "10px",
          marginBottom: "8px",
          color: "#666666",
        }}
      >
        <Grid
          item
          xs={12}
          style={{ paddingTop: "0px", paddingLeft: "0px", fontWeight: "bold" }}
        >
          <Typography variant="h8" display="flex">
            <ArrowDropDownIcon />
            {t("AgriculturalSupplyConfiguration.filtersLabel")}
          </Typography>
        </Grid>

        {/* Agricultural Supply */}
        <Grid item xs={6} style={{ paddingLeft: "0px" }}>
          <Autocomplete
            freeSolo
            options={[...new Set(tableData.map((ele) => ele.asrSupplyName))]} // Data for the dropdown
            inputValue={values.agriculturalSupply} // Controlled value for input
            onInputChange={(event, newInputValue) => {
              handleAgriculturalSupplyChange(event, newInputValue);
            }}
            getOptionLabel={(option) => option || ""} // Ensure proper label rendering
            renderInput={(params) => (
              <TextField
                {...params}
                name="agriculturalsupply"
                label={t("AgriculturalSupplyConfiguration.agriculturalSupply")}
                sx={{ width: "100%" }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <SearchIcon
                      sx={{
                        color: "gray",
                        marginLeft: "6px",
                        marginRight: "5px",
                        fontSize: "30px",
                      }}
                    />
                  ),
                  style: { height: "49px", padding: "0px" },
                }}
              />
            )}
          />
        </Grid>

        {/* Application Phase */}
        <Grid item xs={2}>
          <FormControl sx={{ width: { xs: "100%" }, minHeight: 50 }}>
            <TextField
              select
              name="applicationPhase"
              label={t("AgriculturalSupplyConfiguration.applicationPhase")}
              value={values.applicationPhase || "Select an Option"}
              onChange={(e) => {
                setCurrentPage(0);
                setValues((prev) => ({
                  ...prev,
                  applicationPhase: e.target.value,
                }));
                values.cropSeasonId &&
                  values.geneticTypeId &&
                  searchAgriculturalSCAPI(
                    0,
                    itemsPerPage,
                    values.useThisRecord,
                    values.agriculturalSupply,
                    e.target.value,
                    values.hasTarget,
                    values.cropSeasonId,
                    values.geneticTypeId
                  );
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "7px",
                  minHeight: "50px",
                },
                "& .MuiInputLabel-root": {
                  lineHeight: "1.2em",
                },
              }}
              InputProps={{
                style: { height: "50px" },
                endAdornment: values.applicationPhase && (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setCurrentPage(0);
                        setValues((prev) => ({
                          ...prev,
                          applicationPhase: "",
                        }));
                        values.cropSeasonId &&
                          values.geneticTypeId &&
                          searchAgriculturalSCAPI(
                            0,
                            itemsPerPage,
                            values.useThisRecord,
                            values.agriculturalSupply,
                            "",
                            values.hasTarget,
                            values.cropSeasonId,
                            values.geneticTypeId
                          );
                      }}
                      style={{ marginRight: "15px" }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value="Select an Option" sx={{ display: "none" }}>
                {t("selectoption")}
              </MenuItem>
              {options.applicationPhaseOptions.map((phase, index) => (
                <MenuItem key={index} value={phase}>
                  {phase}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>

        {/* Has Target */}
        <Grid item xs={2}>
          <FormControl sx={{ width: { xs: "100%" }, minHeight: 50 }}>
            <TextField
              select
              name="hasTarget"
              label={t("AgriculturalSupplyConfiguration.hasTarget")}
              value={values.hasTarget || "Select an Option"}
              onChange={(e) => {
                setCurrentPage(0);
                setValues((prev) => ({
                  ...prev,
                  hasTarget: e.target.value,
                }));
                values.cropSeasonId &&
                  values.geneticTypeId &&
                  searchAgriculturalSCAPI(
                    0,
                    itemsPerPage,
                    values.useThisRecord,
                    values.agriculturalSupply,
                    values.applicationPhase,
                    e.target.value,
                    values.cropSeasonId,
                    values.geneticTypeId
                  );
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "7px",
                  minHeight: "50px",
                },
                "& .MuiInputLabel-root": {
                  lineHeight: "1.2em",
                },
              }}
              InputProps={{
                style: { height: "50px" },
                endAdornment: values.hasTarget && (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setCurrentPage(0);
                        setValues((prev) => ({
                          ...prev,
                          hasTarget: "",
                        }));
                        values.cropSeasonId &&
                          values.geneticTypeId &&
                          searchAgriculturalSCAPI(
                            0,
                            itemsPerPage,
                            values.useThisRecord,
                            values.agriculturalSupply,
                            values.applicationPhase,
                            "",
                            values.cropSeasonId,
                            values.geneticTypeId
                          );
                      }}
                      style={{ marginRight: "15px" }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value="Select an Option" sx={{ display: "none" }}>
                {t("selectoption")}
              </MenuItem>
              {options.hasTargetOptions.map((target, index) => (
                <MenuItem key={index} value={target}>
                  {target}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={2}
          style={{
            paddingTop: "12px",
            paddingLeft: "20px",
            paddingRight: "0px",
          }}
        >
          <Typography variant="subtitle2">
            <Checkbox
              checked={values.useThisRecord}
              sx={{
                "&.Mui-checked": {
                  color: "#004F9F", // Optional: Text color when checked
                },
              }}
              onChange={(e) => {
                setCurrentPage(0);
                setValues((prev) => ({
                  ...prev,
                  useThisRecord: e.target.checked, // Use 'checked' instead of 'value'
                }));
                values.cropSeasonId &&
                  values.geneticTypeId &&
                  searchAgriculturalSCAPI(
                    0,
                    itemsPerPage,
                    e.target.checked,
                    values.agriculturalSupply,
                    values.applicationPhase,
                    values.hasTarget,
                    values.cropSeasonId,
                    values.geneticTypeId
                  );
                console.log("Checkbox Value: --- ", e.target.checked);
              }}
            />

            {t("AgriculturalSupplyConfiguration.showUsedRecords")}
          </Typography>
        </Grid>
      </Grid>

      {values.cropYear && values.tobaccoGenericType && (
        <AgriculturalSupplyConfigurationTable
          data={tableData}
          setData={setTableData}
          setIsDeleted={setIsDeleted}
        />
      )}
    </Layout>
  );
}
