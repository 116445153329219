import { httpGrow } from "../http-common";

const getAllSoilAnalysisInterpretationData = async (query) => {
  const {
    pageNumber,
    size
  } = query;

  const response = await httpGrow.get(`/soil-analysis-interpretation/list`, {
    params: {
      pageNumber,
      size
    },
  });
  return response;
};

const getSoilAnalysisInterpretationData = async (query) => {
  const {
    pageNumber,
    size,
    interpretationName,
    geneticTypeId,
    sapAnalysisId,
    status,
  } = query;

  const response = await httpGrow.get(`/soil-analysis-interpretation/search`, {
    params: {
      pageNumber,
      size,
      interpretationName: interpretationName || null,
      geneticTypeId: geneticTypeId || null,
      sapAnalysisId: sapAnalysisId || null,
      status: status || null,
    },
  });
  return response;
};

const postSoilAnalysisInterpretationData = async (data) => {
  const response = await httpGrow.post(
    `/soil-analysis-interpretation/save`,
    data
  );
  return response;
};

const putSoilAnalysisInterpretationData = async (data) => {
  const response = await httpGrow.put(
    `/soil-analysis-interpretation/update`,
    data
  );
  return response;
};

const getGeneticTypes = async () => {
  const response = await httpGrow.get(
    `/geneticType/genetic-type-cultureName/Tobacco`
  );
  return response;
};

const getSoilAnalysisParameters = async () => {
  const response = await httpGrow.get(
    `/soil-analysis-parameters/find?sapStatus=true`
  );
  return response;
};

const getFurnaceTypes = async () => {
  const response = await httpGrow.get(`/furnace-type/find?isActive=true`);
  return response;
};

const SoilAnalysisInterpretationRegistrationService = {
  getGeneticTypes,
  getSoilAnalysisParameters,
  getFurnaceTypes,
  getSoilAnalysisInterpretationData,
  getAllSoilAnalysisInterpretationData,
  postSoilAnalysisInterpretationData,
  putSoilAnalysisInterpretationData,
};

export default SoilAnalysisInterpretationRegistrationService;
