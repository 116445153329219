// src\services\ProductionTargetsService.jsx
import { httpGrow } from "../http-common";

const getAPI = async (query) => {
    const { cropYearUUID, ihId } = query;
    const response = await httpGrow.get(`/fundingGroupLimitsDistribution`, {
        params: {
            cropYearUUID,
            importHierarchyId: ihId
        }
    });
    return response;
};

const getCropSeasonAPI = async () => {
    const response = await httpGrow.get('/cropYears');
    return response;
};

const getHierarchyNodeAPI = async () => {
    const response = await httpGrow.get('/importHierarchyVersion/tree');
    return response;
};

const getInvestmentGroupListAPI = async (query) => {
    const { fundingGroupDescription, pageNumber = 0, pageSize = 50 } = query;
    const response = await httpGrow.get('fundingGroup/search', {
        params: {
            pageNumber,
            pageSize,
            ...(fundingGroupDescription && { fundingGroupDescription })
        }
    });
    return response;
};

const putInvestmentGLDAPI = (data) => {
    return httpGrow.put('fundingGroupLimitsDistribution/save', data);
};

const deleteInvestmentDistributionAPI = async (query) => {
    const { investmentGroupID , cropYearUUID } = query;
    const response = await httpGrow.delete(`/investmentGLDistribution/${investmentGroupID}`, {
        params: {
            cropYearUUID
        }
    });
    return response;
};

const FundingGroupLimitsService = {
    getAPI,
    getCropSeasonAPI,
    getHierarchyNodeAPI,
    getInvestmentGroupListAPI,
    putInvestmentGLDAPI,
    deleteInvestmentDistributionAPI
};

export default FundingGroupLimitsService;
