import {
  Autocomplete,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Modal,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import Divider from "components/common/GrowingDivider";
import Layout from "components/structure/Layout";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import PaymentPolicyLimitsDistributionService from "services/PaymentPolicyLimitsDistributionService";
import PaymentPolicyLimitsTable from "./PaymentPolicyLimitsDistributionTable";
import SearchIcon from "../../../../assets/SearchIcon.svg";
import { useNavigate } from "react-router-dom";

const PaymentPolicyLimitsDistribution = () => {
  const { t } = useTranslation();
  const [cropSeason, setCropSeason] = useState("");
  const [cropSeasonId, setCropSeasonId] = useState("");
  const [cropSeasonOptions, setCropSeasonOptions] = useState([]);
  const [hierarchicalNode, setHierarchicalNode] = useState("");
  const [hierarchicalNodeId, setHierarchicalNodeId] = useState("");
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [childNodes, setChildNodes] = useState([]);
  const [isTopHierarchyNode, setIsTopHierarchyNode] = useState(false);
  const [paymentPolicyLD, setPaymentPolicyLD] = useState(null);
  const [showExitWarningOverlayFeed, setShowExitWarningOverlayFeed] =
    useState(false);
  const [unsavedChangesModal, setUnsavedChangesModal] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState();
  const navigate = useNavigate();

  const showModal = () => {
    setUnsavedChangesModal(true);
  };

  const cancelNavigation = () => {
    setUnsavedChangesModal(false);
    navigate(pendingNavigation);
  };

  const confirmNavigation = async () => {
    const isSaveSuccessful = await handleParentSave();
    if (isSaveSuccessful) {
      setUnsavedChangesModal(false);
      navigate(pendingNavigation);
    } else {
      setUnsavedChangesModal(false);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    const handlePopState = (event) => {
      const shouldNavigate = window.confirm(
        "You have unsaved changes. Are you sure you want to leave?"
      );
      if (!shouldNavigate) {
        window.history.pushState(
          null,
          "",
          window.location.pathname + window.location.search
        );
      } else {
        window.history.go(-1);
      }
    };

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue =
        "You have unsaved changes. Are you sure you want to leave?";
    };

    if (showExitWarningOverlayFeed === true) {
      window.addEventListener("popstate", handlePopState);
      window.addEventListener("beforeunload", handleBeforeUnload);
      window.history.pushState(
        null,
        "",
        window.location.pathname + window.location.search
      );
    }

    return () => {
      window.removeEventListener("popstate", handlePopState);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [showExitWarningOverlayFeed]);

  useEffect(() => {
    if (cropSeasonId && hierarchicalNodeId) {
      fetchPaymentPolicyLD();
    } else {
      setPaymentPolicyLD(null);
    }
  }, [cropSeasonId, hierarchicalNodeId]);

  const fetchInitialData = async () => {
    try {
      const [cropSeasonResponse, hierarchyResponse] = await Promise.all([
        PaymentPolicyLimitsDistributionService.getCropSeasonAPI(),
        PaymentPolicyLimitsDistributionService.getHierarchyNodeAPI(),
      ]);

      setCropSeasonOptions(
        cropSeasonResponse.data.response.map((item) => ({
          label: item.cropYear.toString(),
          value: item.cropYear.toString(),
          cropSeasonId: item.cropYearUid,
        }))
      );

      const flattenHierarchy = (node, result = [], level = 0) => {
        result.push({
          label: node.locationName,
          value: node.id,
          level,
          tag: node.levelName,
          parentId: node.parentId || "parentNode",
        });
        node.childHierarchies.forEach((child) =>
          flattenHierarchy(child, result, level + 1)
        );
        return result;
      };
      setHierarchyOptions(flattenHierarchy(hierarchyResponse.data.response));
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };

  const fetchPaymentPolicyLD = async () => {
    try {
      const PaymentPolicyLDresponse =
        await PaymentPolicyLimitsDistributionService.getPaymentPolicyDistributionAPI(
          {
            cropYearUUID: cropSeasonId,
            importHierarchyId: hierarchicalNodeId,
          }
        );
      setPaymentPolicyLD(PaymentPolicyLDresponse.data.response);
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };

  const paymentTableRef = useRef();

  const handleParentSave = async () => {
    if (paymentTableRef.current) {
      const isSuccess = await paymentTableRef.current.save();
      return isSuccess;
    }
    return false;
  };

  const handleHierarchyChange = (event, newValue) => {
    if (newValue) {
      setHierarchicalNode(newValue.value);
      setHierarchicalNodeId(newValue.value);
      setIsTopHierarchyNode(newValue.level === 0);
      setChildNodes(
        hierarchyOptions.filter(
          (node) =>
            node.level === newValue.level + 1 &&
            node.parentId === newValue.value
        )
      );
    } else {
      setHierarchicalNode("");
      setHierarchicalNodeId("");
      setIsTopHierarchyNode(false);
    }
  };

  const renderPaymentPolicyLimitsTable = () => {
    if (!cropSeasonId || !hierarchicalNodeId) {
      return null;
    }
    return (
      <PaymentPolicyLimitsTable
        ref={paymentTableRef}
        data={paymentPolicyLD}
        cropYearUUID={cropSeasonId}
        ihId={hierarchicalNodeId}
        childNodes={childNodes}
        isTopHierarchyNode={isTopHierarchyNode}
        refreshData={fetchPaymentPolicyLD}
        setShowExitWarningOverlayFeed={setShowExitWarningOverlayFeed}
      />
    );
  };

  return (
    <Layout
      title={t("paymentPolicyLimits.pageTitle")}
      background="#ffffff"
      showExitWarningOverlayFeed={showExitWarningOverlayFeed}
      setShowExitWarningOverlayFeed={showModal}
      setPendingNavigation={setPendingNavigation}
    >
      <Grid
        container
        id="mainfilter"
        spacing={2}
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          p: 2,
          maxWidth: "100%",
          margin: "auto",
          paddingBottom: "34px",
          marginBottom: "12px",
        }}
      >
        <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px" }}>
          <Typography
            variant="h8"
            display="flex"
            fontWeight="700"
            color="#666666"
          >
            <ArrowDropDownIcon />
            {t("paymentPolicyLimits.mandatoryFilters")}
          </Typography>
        </Grid>

        <Grid item xs={4} style={{ paddingLeft: "0px" }}>
          <FormControl fullWidth>
            <Autocomplete
              value={
                cropSeasonOptions.find(
                  (option) => option.value === cropSeason
                ) || null
              }
              disableClearable={true}
              onChange={(event, newValue) => {
                if (newValue) {
                  setCropSeason(newValue.value);
                  setCropSeasonId(newValue.cropSeasonId);
                } else {
                  setCropSeason("");
                  setCropSeasonId("");
                }
              }}
              options={cropSeasonOptions}
              renderInput={(params) => (
                <TextField
                  type="number"
                  {...params}
                  label={t("paymentPolicyLimits.csSearchField")}
                  fullWidth
                  placeholder="Select an Option"
                  required={true}
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    "& .MuiInputBase-input::placeholder": {
                      opacity: 1,
                    },
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                  }}
                  onKeyDown={(e) => {
                    if (
                      e.key === "e" ||
                      e.key === "E" ||
                      e.key === "-" ||
                      e.key === "+"
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth>
            <Autocomplete
              value={
                hierarchyOptions.find(
                  (option) => option.value === hierarchicalNode
                ) || null
              }
              disableClearable={true}
              onChange={handleHierarchyChange}
              options={hierarchyOptions}
              renderOption={(props, option) => (
                <li {...props}>
                  <div>
                    <div style={{ fontSize: "16px" }}>{option.label}</div>
                    <div style={{ fontSize: "10px", color: "#5A646E" }}>
                      {option.tag}
                    </div>
                  </div>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("paymentPolicyLimits.hnSearchField")}
                  fullWidth
                  placeholder="Select an Option"
                  required={true}
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    "& .MuiInputBase-input::placeholder": {
                      opacity: 1,
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={SearchIcon}
                          alt="search"
                          style={{
                            width: "20px",
                            height: "20px",
                            color: "#737D86",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>

      {cropSeasonId && hierarchicalNodeId && (
        <Divider
          title={t(
            "paymentPolicyLimits.subordinateHierarchiesDistributionLimit"
          )}
        />
      )}
      {renderPaymentPolicyLimitsTable()}
      <Modal
        open={unsavedChangesModal}
        onClose={() => cancelNavigation()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "585px",
            margin: "auto",
            mt: 25,
            height: "200px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              {t("cancelTitle")}
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, fontWeight: 400 }}
            >
              {t("unsavedChanges")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={cancelNavigation}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              {t("discard")}
            </Button>
            <Button
              onClick={confirmNavigation}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              {t("save")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Layout>
  );
};

export default PaymentPolicyLimitsDistribution;
