// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .timeline-item {
    display: flex;
    /* align-items: flex-start; */
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
  }

  .timeline-segment {
    position: absolute;
    width: 2px;
    background-color: #ccc;
    left: 24px; /* Aligns with the icon */
    top: 50px; /* Starts below the current icon */
    bottom: -40px; /* Ends above the next icon */
    z-index: 0;
  }



  `, "",{"version":3,"sources":["webpack://./src/components/pages/Alerts/Timeline.css"],"names":[],"mappings":"EAAE;IACE,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,kBAAkB;IAClB,UAAU;EACZ;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,sBAAsB;IACtB,UAAU,EAAE,yBAAyB;IACrC,SAAS,EAAE,kCAAkC;IAC7C,aAAa,EAAE,6BAA6B;IAC5C,UAAU;EACZ","sourcesContent":["  .timeline-item {\n    display: flex;\n    /* align-items: flex-start; */\n    margin-bottom: 20px;\n    position: relative;\n    z-index: 1;\n  }\n\n  .timeline-segment {\n    position: absolute;\n    width: 2px;\n    background-color: #ccc;\n    left: 24px; /* Aligns with the icon */\n    top: 50px; /* Starts below the current icon */\n    bottom: -40px; /* Ends above the next icon */\n    z-index: 0;\n  }\n\n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
