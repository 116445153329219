import { httpGrow } from "../http-common";

const get = (query) => {
  return httpGrow.get(
    `contract-type/search?&pageNumber=${query.pageNumber}&pageSize=${query.size}`
  );
  ///pageNumber=0&pageSize=10
};

const getUnitMeasurment = () => {
  return httpGrow.get(
    `/unit-of-measurements`
  );
};

const create = (data) => {
  return httpGrow.post("/contract-type/save", data);
};

const update = (data) => {
  return httpGrow.put(`/contract-type/update`, data);
};

const remove = (data) => {
  return httpGrow.delete(`/spacing-registration/${data}`);
};

const geneticType = (data) => {
  return httpGrow.get(`/geneticType`, data);
};

const filter = (query) => {
  let url = `contract-type/search?`;
  
  ///contract-type/search?pageNumber=0&pageSize=10&contractType=ss&status=true


  if (query.geneticDescription) {
    url += `&contractType=${query.geneticDescription}`;
  }
  
  if (query.isActive !== undefined) {
    url += `&status=${query.isActive}`;
  }
  url += `&pageNumber=${query.pageNumber}&pageSize=${query.size}`;
  
  return httpGrow.get(url);
};

const ContractTypeService = {
  get,
  create,
  update,
  remove,
  getUnitMeasurment,
  geneticType,
  filter,
};

export default ContractTypeService;
