import React, { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  CircularProgress,
  Typography,
  Modal,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import arrowLeft from "assets/arrowLeft.svg";
import RedSwitch from "utils/RedSwitch";
import Save from "assets/SaveNew.svg";
import FurnaceService from "services/FurnaceService";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import Lable from "utils/lables.json";
import * as Yup from "yup";
import { furnaceSchema } from "utils/validations";

export default function AddFurnaceTypeRegistration() {
  console.log("runninggggg");
  const navigate = useNavigate();

  const location = useLocation();
  console.log("vkkkk", location);
  const {
    ftFurnaceTypeName,
    isActive,
    ftFurnaceTypeid,
    countryId,
    languageId,
    isDelete,
  } = location?.state?.row || {};
  const { edit } = location.state || {};

  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState({
    ftFurnaceTypeid: ftFurnaceTypeid,
    ftFurnaceTypeName: ftFurnaceTypeName || "",
    isActive: isActive === true ? true : false,
    countryId: countryId || "",
    languageId: languageId || "",
    isDelete: isDelete === true ? true : false,
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);

  // Function to open the modal
  const handleOpenModal = () => {
    setOpen(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setOpen(false);
  };

  // Function when user confirms "Yes" on the modal
  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/furnaceType");
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (event) => {
    console.log("clicked");
    event.preventDefault();
    setLoading(true);

    try {
      await furnaceSchema.validate(values, { abortEarly: false });
      setErrors({});
      const payload = {
        ftFurnaceTypeName: values?.ftFurnaceTypeName,
        isActive: values?.isActive,
        countryId: values?.countryId,
        languageId: values?.languageId,
        createdDateTimeUTC: new Date().toISOString(),
        createdByUser: "string",
        modifiedDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "string",
      };

      console.log("payload hai", payload);
      setLoading(true);
      const response = await FurnaceService.createFurnace({ ...payload });
      console.log("🚀 ~ handleSubmit ~ response:", response);
      setLoading(false);
      if (response.status === 201) {
        navigate("/furnaceType");
        setSeverity("success");
        setMessage(t("snackbarMessage.successAdd"));
        setOpenAlert(true);
        // setLoading(false);
      }
      if (response.status === 409) {
        setSeverity("error");
        setMessage(response?.data?.message);
        setOpenAlert(true);
        setLoading(false);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage(t("snackbarMessage.submissionFailed"));
        setOpenAlert(true);
        setLoading(false);
      }
    } catch (err) {
      console.log("error hai");
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage(
          err?.response?.data?.message ||
            "An error occurred while submitting the form:"
        );
        setOpenAlert(true);
      }
    }
  };

  const handleUpdate = async (event) => {
    setLoading(true);

    event.preventDefault();
    try {
      //await greenManureSchema.validate(values, { abortEarly: false });
      setErrors({});
      const payload = {
        ftFurnaceTypeid: values?.ftFurnaceTypeid,
        ftFurnaceTypeName: values?.ftFurnaceTypeName,
        isActive: values?.isActive,
        countryId: values?.countryId,
        languageId: values?.languageId,
        createdDateTimeUTC: new Date().toISOString(),
        createdByUser: "string",
        modifiedDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "string",
      };
      setLoading(true);
      const response = await FurnaceService.updateFurnace(payload);
      setLoading(false);
      if (response.status === 201) {
        navigate("/furnaceType");
        setSeverity("success");
        setMessage("Updated successfuly!");
        setOpenAlert(true);
        setLoading(false);
        return;
      }
      if (response.status === 409) {
        navigate("/furnaceTypeRegistration");
        setSeverity("success");
        setMessage(response?.message);
        setOpenAlert(true);
        setLoading(false);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage(t("snackbarMessage.submissionFailed"));
        setOpenAlert(true);
      }
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage("An error occurred while submitting the form");
        setOpenAlert(true);
      }
    }
  };

  return (
    <Layout title={t("furnaceType.pageTitle")} background="#ffffff">
      <Box
        sx={{
          minHeight: "125vh",
          flexGrow: 1,
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          style={{ backgroundColor: "#EFEFEF" }}
        >
          <Box sx={{ display: "flex" }}>
            <ArrowDropDownIcon sx={{ marginRight: 1 }} />
            <Typography>{Lable.fromLable}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              //justifyContent:"space-between",
              height: "8.5rem",
              marginTop: "-1.563rem",
            }}
          >
            <FormControl sx={{ mt: "2px" }}>
              <TextField
                name="ftFurnaceTypeName"
                label={t("furnaceType.furnaceType")}
                value={values?.ftFurnaceTypeName}
                onChange={handleChange}
                disabled={edit == false}
                fullWidth
                required
                error={!!errors.ftFurnaceTypeName}
                helperText={errors.ftFurnaceTypeName}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "0.438rem",
                    minHeigh: "3.125rem",
                    minWidth: "53rem",
                    "&.Mui-disabled": {
                      "& fieldset": {
                        borderColor: "gray",
                        borderWidth: "1px",
                      },
                      "&:hover fieldset": {
                        borderColor: "gray",
                        borderWidth: "1px",
                      },
                    },
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2rem",
                  },
                }}
                InputProps={{
                  style: { height: "3.125rem" },
                }}
              />
            </FormControl>
            {/* <Box
              sx={{
                ml: "0.938rem",
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <FormControl component="fieldset">
                <FormLabel
                  sx={{
                    color: "#3B454F !important",
                    mb: 2,
                    ml: 0.5,
                    fontSize: "18px", 
                    fontWeight:"600px",
                    "&.Mui-focused": { color: "#3B454F" },
                    "&:hover": { color: "#3B454F" },
                  }}
                >
                  {t("Status")}
                </FormLabel>
                <FormControlLabel
                  sx={{
                    ml: 0.5,
                    display: "flex",
                    alignItems: "center",
                    gap: "0.4rem",
                  }}
                  control={
                    <RedSwitch
                      sx={{
                        marginRight: "0.688rem",
                      }}
                      checked={values?.isActive}
                      onChange={handleChange}
                      name="isActive"
                      color="primary"
                      disabled={edit == false}
                    />
                  }
                  label={
                    <Box sx={{ width: "3rem", mr: "0.313rem",color:"#3B454F",fontSize: "15px",fontWeight:"normal" }}>
                      {values.isActive === true ? "Active" : "Inactive"}
                    </Box>
                  }
                  labelPlacement="start"
                />
              </FormControl>
            </Box> */}
            <Box
              sx={{
                ml: 0.5,
                mb: 0.5,
                display: "flex",
                alignItems: "center",
                gap: "0.4rem",
              }}
            >
              <FormControl component="fieldset">
                <FormLabel
                  sx={{
                    fontFamily: "Montserrat, sans-serif", // Font
                    fontWeight: 500, // Weight
                    fontSize: "18px", // Size
                    lineHeight: "20px", // Line height
                    color: "#3B454F !important", // Fixed color
                    mb: 2,
                    ml: 2,
                    "&.Mui-focused": { color: "#0E2B63" },
                    "&:hover": { color: "#0E2B63" },
                  }}
                >
                  {t("status")}
                </FormLabel>
                <Box sx={{ display: "flex", alignItems: "center", ml: 5 }}>
                  <Typography sx={{ mr: 3, ml: 3 }}>{t("inactive")}</Typography>
                  <FormControlLabel
                    control={
                      <RedSwitch
                        sx={{ marginRight: "11px" }}
                        checked={values?.isActive}
                        onChange={handleChange}
                        name="isActive"
                        color="primary"
                        disabled={edit == false}
                      />
                    }
                    label={
                      <Typography sx={{ ml: 0 }}>{t("active")}</Typography>
                    }
                    labelPlacement="end"
                  />
                </Box>
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between", // Ensures buttons are placed on opposite corners
              position: "fixed", // Fix the buttons in place
              width: "100%", // Make the Box take full width
              bottom: 0, // Place at the bottom of the screen
              padding: "1rem", // Add some padding
            }}
          >
            {/* Cancel Button - Left bottom corner */}
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: "6px 16px",
                borderRadius: "4px",
                position: "fixed",
                left: "1rem",
                bottom: "1rem",
                marginBottom: "50px",
              }}
              variant="contained"
              onClick={edit == false ? handleDeleteConfirm : handleOpenModal} // Open the modal on click
              disabled={loading}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
            >
              {loading && <CircularProgress size={20} />}
              {edit == false ? "Cancel" : "Cancel"}
            </Button>

            {/* Modal confirmation */}
            <Modal
              open={open}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  width: "585px",
                  margin: "auto",
                  mt: 25,
                  height: "200px",
                }}
              >
                <Box
                  sx={{
                    background: "#102A62",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                  }}
                >
                  <Typography
                    sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                  >
                    {t("cancelTitle")}
                  </Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, fontWeight: 400 }}
                  >
                    {t("cancelMessage")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    mb: 1,
                  }}
                >
                  <Button
                    onClick={handleCloseModal}
                    sx={{
                      background: "#737D86",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#737D86" },
                    }}
                  >
                    {t("no")}
                  </Button>
                  <Button
                    onClick={handleDeleteConfirm}
                    sx={{
                      background: "#004F9F",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#004F9F" },
                    }}
                  >
                    {t("yes")}
                  </Button>
                </Box>
              </Box>
            </Modal>

            {edit != false && (
              <Button
                sx={{
                  padding: "6px 16px",
                  borderRadius: "4px",
                  position: "fixed", // Fix the button
                  right: "1rem", // Aligns to the right bottom corner
                  bottom: "1.5rem", // Position it at the bottom
                  marginBottom: "40px",
                  backgroundColor: "#EF7D00",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#EF7D00",
                  },
                }}
                disabled={loading}
                startIcon={<img src={Save} alt="Right Arrow" />}
                onClick={!edit ? handleSubmit : handleUpdate}
                variant="contained"
              >
                {loading && <CircularProgress size={20} />}
                {"Save"}
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Layout>
  );
}
