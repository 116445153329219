// src\services\TransporterConfigurationService.jsx
import { httpGrow } from "../http-common";

// User Story constants
const basename = "transporter-configuration";

const getAPI = async (query) => {
    const { 
        pageNumber = 0, 
        size = 50, 
        keyword = '', 
        useThisRecord = undefined 
    } = query;
    const batOneId = '111111';
    
    // Check if the input contains only numbers (can include dots and hyphen)
    const isNumeric = /^[\d.-]+$/.test(keyword.trim());
    
    console.log('Is Numeric:', isNumeric);
    console.log('Search params:', isNumeric ? { fiscalID: keyword } : { transporterName: keyword });
    
    const response = await httpGrow.get(`/${basename}/search`, {
        params: {
            pageNumber,
            size,
            batOneId,
            ...(isNumeric ? { fiscalID: keyword } : { transporterName: keyword }),
            ...(useThisRecord !== undefined && { useThisRecord })
        }
    });
    return response;
};

const updateAPI = (data) => {
    return httpGrow.put(`/${basename}/update`, data);
};

const getCityAPI = async () => {
    const response = await httpGrow.get(`/city`);
    return response;
};

const getRegionAPI = async () => {
    const response = await httpGrow.get(`/region`);
    return response;
};

const getListAPI = async (query) => {
    const { pageNumber = 0, size = 50 } = query;
    const batOneId = '111111';
    
    const response = await httpGrow.get(`/${basename}/list`, {
        params: {
            pageNumber,
            size,
            batOneId
        }
    });
    return response;
};

const TransporterConfigurationService = {
    getAPI,
    updateAPI,
    getCityAPI,
    getRegionAPI,
    getListAPI,
};

export default TransporterConfigurationService;
