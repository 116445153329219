import React, { useEffect, useState } from "react";
import { MenuItem, Chip, Autocomplete, TextField } from "@mui/material";

export const MultiSelectDropdown = ({
  options = [], // Array of objects
  selectedOptions = [], // Array of selected objects
  setSelectedOptions,
  label = "Select Options",
  placeholder = "Choose...",
  chipColor = "secondary",
  error = false, // Error state for the dropdown
  helperText = "", // Helper text for errors or guidance
  disabled = false, // Disable state for the dropdown
}) => {
  const [initialSelectedOptions, setInitialSelectedOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    // Save initial selected options on first render
    setInitialSelectedOptions(selectedOptions.map((option) => ({ ...option })));
  }, []);

  useEffect(() => {
    // Exclude options marked with isDelete: true from the dropdown
    setFilteredOptions(options.filter((option) => !option.isDelete));
  }, [options]);

  const handleChange = (event, newValue) => {
    if (disabled) return; // Prevent changes if disabled

    const updatedValues = [];

    // Handle option addition/removal
    newValue.forEach((selectedOption) => {
      // Check if it's an initial option
      const isInitial = initialSelectedOptions.some(
        (opt) =>
          opt.gtGeneticDescription === selectedOption.gtGeneticDescription
      );

      if (isInitial) {
        // Reset isDelete for initial options
        updatedValues.push({ ...selectedOption, isDelete: false });
      } else {
        updatedValues.push(selectedOption);
      }
    });

    // Mark removed initial options with isDelete: true
    initialSelectedOptions.forEach((initialOption) => {
      if (
        !newValue.some(
          (selectedOption) =>
            selectedOption.gtGeneticDescription ===
            initialOption.gtGeneticDescription
        )
      ) {
        updatedValues.push({ ...initialOption, isDelete: true });
      }
    });

    setSelectedOptions(updatedValues);
  };

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      options={filteredOptions}
      getOptionLabel={(option) => option.gtGeneticDescription || ""}
      value={selectedOptions.filter((option) => !option.isDelete)}
      isOptionEqualToValue={(option, value) =>
        option.gtGeneticDescription === value.gtGeneticDescription
      }
      onChange={disabled ? null : handleChange} // Disable changes when disabled
      disabled={disabled} // Disable the autocomplete
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={selectedOptions.length === 0 ? placeholder : ""}
          error={error} // Pass the error state
          helperText={helperText} // Pass the helper text
          disabled={disabled} // Disable the input field
          InputProps={{
            ...params.InputProps,
            sx: {
              "&::placeholder": {
                color: "black", // Set placeholder text color
                opacity: 1, // Ensure placeholder is fully visible
              },
            },
          }}
          sx={{
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: disabled ? "#d3d3d3" : "",
            },
            ".MuiOutlinedInput-root": {
              ...(disabled && {
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #0000003B",
                },
              }),
            },
            "& .MuiInputBase-input::placeholder": {
              color: "black", // Placeholder color
              opacity: 1, // Ensure visibility
            },
          }}
        />
      )}
      renderTags={(selected, getTagProps) =>
        selected.map((option, index) => (
          <Chip
            key={option.gtGeneticDescription}
            label={option.gtGeneticDescription}
            {...getTagProps({ index })}
            color={chipColor}
            disabled={disabled} // Disable the chip
          />
        ))
      }
      renderOption={(props, option, { selected }) => (
        <MenuItem
          {...props}
          sx={{
            borderLeft: selected && !disabled ? "4px solid navy" : "none", // No border highlight when disabled
          }}
        >
          {option.gtGeneticDescription}
        </MenuItem>
      )}
      sx={{
        "& .MuiChip-root": {
          backgroundColor: "#00000014",
          color: "#000",
        },
        "& .MuiAutocomplete-endAdornment": {
          top: "50%",
          transform: "translateY(-50%)",
        },
      }}
    />
  );
};

export default MultiSelectDropdown;
