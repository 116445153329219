import React, { useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "./Table.css";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import _ButtonView_ from "assets/Vector.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import MultiProfile from "assets/MultiProfile.svg";
import Time from "assets/Time.svg";
import EsgNot from "assets/EsgNot.svg";
import EsgYes from "assets/EsgYes.svg";
import EsgPending from "assets/EsgPending.svg";
import EsgBlocked from "assets/EsgBlocked.svg";

const PreAssessmentTable = ({ data, setData, cropSeasonId }) => {
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const { t } = useTranslation();
  const [expandedRow, setExpandedRow] = useState(null);
  const [expandedType, setExpandedType] = useState(null);
  // const dummyData = [
  //   {
  //     farmerCode: "FC001",
  //     segmentation: "Premium",
  //     city: "Springfield",
  //     locality: "Downtown",
  //     esgStatus: "Compliant",
  //     commercialStatus: "Active",
  //     profileDetails: {
  //       name: "John Doe",
  //       age: 45,
  //       crops: ["Wheat", "Corn"],
  //     },
  //     timeDetails: {
  //       lastUpdated: "2025-01-01",
  //       registeredSince: "2020-05-15",
  //     },
  //   }
  // ];

  // const handleExpand = (row, type) => {
  //   if (expandedRow === row && expandedType === type) {
  //     setExpandedRow(null);
  //     setExpandedType(null);
  //   } else {
  //     setExpandedRow(row);
  //     setExpandedType(type);
  //   }
  // };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <ArrowUpwardIcon fontSize="small" />;
      } else if (sortConfig.direction === "desc") {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
    return <ArrowDownwardIcon fontSize="small" />;
  };

  return (
    <div className="table-container">
      <table className="preassessment-esg-table">
        <thead>
          <tr>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
                position: "relative",
              }}
              onClick={() => handleSort("farmerCode")}
            >
              Farmer
              {getSortIcon("farmerCode")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("segmentation")}
            >
              Segmentation {getSortIcon("segmentation")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("city")}
            >
              City {getSortIcon("city")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("locality")}
            >
              Locality
              {getSortIcon("locality")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("esgStatus")}
            >
              ESG Status
              {getSortIcon("esgStatus")}
            </th>{" "}
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("commercialStatus")}
            >
              Commercial Status
              {getSortIcon("commercialStatus")}
            </th>
            <th className="actions-header">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  justifyContent: "left",
                }}
              >
                {t("actions")}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((farmer, index) => (
            <React.Fragment key={index}>
              <tr key={index}>
                <td>
                  <div
                    style={{
                      position: "absolute",
                      background: "#004F9F",
                      height: "65px",
                      left: "27px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderTopLeftRadius: "5px",
                    }}
                  >
                    <img
                      // onClick={() => handleExpand(index, "profile")}
                      style={{ margin: "0px 20px" }}
                      src={MultiProfile}
                    />
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      background: "#acb4ba",
                      height: "65px",
                      left: "98px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      // onClick={() => handleExpand(index, "time")}
                      style={{ margin: "0px 20px" }}
                      src={Time}
                    />
                  </div>
                  <div
                    style={{
                      marginLeft: "130px",
                      fontWeight: "bold",
                      color: "#102a62",
                    }}
                  >
                    {" "}
                    {farmer.farmerCode}
                  </div>
                </td>
                <td style={{ fontWeight: "bold", color: "#102a62" }}>
                  {farmer.segmentation}
                </td>
                <td style={{ fontWeight: "600", color: "#102a62" }}>
                  {farmer.city}
                </td>
                <td style={{ fontWeight: "500", color: "#102a62" }}>
                  {farmer.locality}
                </td>
                <td>
                  {farmer.esgStatus === "Pending ESG Approval" ? (
                    <img src={EsgPending} />
                  ) : farmer.esgStatus === "ESG Blocked" ? (
                    <img src={EsgBlocked} />
                  ) : farmer.esgStatus === "Pending Pre Assessment" ? (
                    <img src={EsgNot} />
                  ) : (
                    <img src={EsgYes} />
                  )}
                  <img />
                </td>
                <td style={{ fontWeight: "500", color: "#102a62" }}>
                  {farmer.commercialStatus}
                </td>
                <td className="actions-cell">
                  <Tooltip title="EDIT" arrow placement="top">
                    <img
                      className="btn edit-btn"
                      src={_ButtonEdit_}
                      alt="Edit Button"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the row click from firing
                        navigate(`/preAssesmentEdit`, {
                          state: {
                            cropSeasonId: cropSeasonId,
                            farmerId: farmer.farmerId,
                            edit: true,
                          },
                        });
                      }}
                    />
                  </Tooltip>

                  <Tooltip title="VIEW" arrow placement="top">
                    <img
                      className="btn delete-btn"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the row click from firing
                        navigate(`/preAssesmentEdit`, {
                          state: {
                            cropSeasonId: cropSeasonId,
                            farmerId: farmer.farmerId,
                            edit: false,
                          },
                        });
                      }}
                      src={_ButtonView_}
                      alt="View Button"
                    />
                  </Tooltip>
                </td>
              </tr>
              {expandedRow === index && (
                <tr>
                  <td
                    colSpan="6"
                    style={{
                      padding: "10px",
                      backgroundColor:
                        expandedType === "profile" ? "#004F9F" : "#acb4ba",
                      color: "white",
                      marginTop: "-15px",
                      borderBottomLeftRadius: "5px",
                      borderBottomRightRadius: "5px",
                    }}
                  >
                    {expandedType === "profile" && (
                      <div>
                        <strong>Profile Details:</strong>
                        <p>Name: {farmer.profileDetails.name}</p>
                        <p>Age: {farmer.profileDetails.age}</p>
                        <p>Crops: {farmer.profileDetails.crops.join(", ")}</p>
                      </div>
                    )}
                    {expandedType === "time" && (
                      <div>
                        <strong>Time Details:</strong>
                        <p>Last Updated: {farmer.timeDetails.lastUpdated}</p>
                        <p>
                          Registered Since: {farmer.timeDetails.registeredSince}
                        </p>
                      </div>
                    )}
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PreAssessmentTable;
