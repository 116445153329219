import { styled } from "@mui/material/styles";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  IconButton,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
  Modal,
  Divider,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "context/snackBarContext";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import SearchTextInput from "components/common/SearchTextInput";
import Save from "assets/SaveNew.svg";
import InvestmentGroupLimitsService from "services/InvestmentGroupLimitsService";
import { debounce } from "lodash";
import SpacingTargetService from "services/SpacingTargetService";
import Question from "assets/questionCircle.svg";

const StyledTableContainer = styled(TableContainer)({
  boxShadow: "none",
  // border: "1px solid #E5E7EB",
  borderRadius: "8px",
  maxWidth: "1880px",
  margin: "0 auto",
  overflowX: "auto", // Enable horizontal scrolling
});

const StyledTable = styled(Table)({
  borderCollapse: "separate",
  borderSpacing: 0,
  minWidth: "100%", // Ensure table spans horizontally
  borderCollapse: "separate",
  borderSpacing: "3px 4px",
});

const HeaderCell = styled(TableCell)({
  backgroundColor: "#E9EBEE",
  color: "#3B454F",
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontSize: "20px",
  textTransform: "uppercase",
  textAlign: "center",
  padding: "13px 20px",
  borderBottom: "none",
  borderTopLeftRadius: "8px", // Rounded top-left edge
  borderTopRightRadius: "8px", // Rounded top-right edge
  overflow: "hidden", // Ensures the rounded edges are respected
});

const SecondaryHeaderCell = styled(TableCell)({
  backgroundColor: "#E9EBEE",
  color: "#3B454F",
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "20px",
  textAlign: "center",
  padding: "13px 20px",
  borderBottom: "none",
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  overflow: "hidden",
});

const SubHeaderCell = styled(TableCell)({
  backgroundColor: "#FFFFFF",
  color: "#3B454F",
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "16px",
  padding: "13px 20px",
  border: "1px solid #E9EBEE",
  borderRadius: "8px", // Rounded top-left edge
  overflow: "hidden",
  "&:last-child": {
    borderRight: "none",
  },
});

const DataCell = styled(TableCell)({
  fontFamily: "Montserrat",
  fontWeight: 400,
  fontSize: "15px",
  color: "#3B454F",
  padding: "13px 20px",
  backgroundColor: "#EFEFEF",
  textAlign: "center",
  // borderBottom: "1px solid #E9EBEE",
  // borderRight: "1px solid #E9EBEE",
  "&:last-child": {
    borderRight: "none",
  },
});

const TotalCell = styled(TableCell)({
  backgroundColor: "#E9EBEE",
  color: "#000000",
  fontFamily: "Montserrat",
  fontWeight: 700,
  fontSize: "16px",
  padding: "13px 20px",
  borderBottom: "none",
  textAlign: "center",
});

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    padding: "13px 5px",
    fontFamily: "Montserrat",
    fontSize: "15px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
});

const SpacingTargetTable = ({
  data,
  cropYearUUID,
  ihId,
  onSave,
  childNodes,
  isTopHierarchyNode,
  geneticTypeId,
}) => {
  const [tableData, setTableData] = useState([]);
  console.log("🚀 ~ tableData:", tableData);
  console.log("childNodes", childNodes);

  console.log("data", data);
  // console.log("TABLE Data", tableData)

  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const { t, i18n } = useTranslation();
  const columns = ["Percentage", "Distributed", "Consumed"];
  const headers = ["Investment VA", "Investment AM", "Investment BY"];
  const [showColumns, setShowColumns] = useState({
    percentage: true,
    distributed: true,
    consumed: true,
  });
  console.log("ColumnsState", showColumns);
  const [minColumnModel, setMinColumnModel] = useState(false);

  const handleColumnChange = (key, isChecked) => {
    const uncheckedCount = Object.values(showColumns).filter(
      (value) => !value
    ).length;

    if (!isChecked && uncheckedCount >= 2) {
      console.log("ALL 3 FALSE");
      setMinColumnModel(true);
      return;
    }
    setShowColumns((prev) => ({
      ...prev,
      [key]: isChecked,
    }));
    setMinColumnModel(false);
  };

  const [manageItemsModal, setManageItemsModal] = useState(false);
  const [removeInvestmentModal, setRemoveInvestmentModal] = useState(false);
  const [removedInvestTableId, setRemovedInvestmentTableId] = useState();
  const [investmentList, setInvestmentList] = useState([]);
  const [investmentListSearch, setInvestmentListSearch] = useState("");
  const [investmentHeader, setInvestmentHeader] = useState([]);
  const [investmentTotal, setInvestmentTotal] = useState([]);
  const [groupedData, setGroupedData] = useState([]);
  console.log("🚀 ~ groupedData:", groupedData);
  console.log("🚀 ~ investmentTotal:", investmentTotal);
  console.log("InvestmentHeader", investmentHeader);
  const handleInvestmentListChange = (row, isChecked) => {
    if (!isChecked) {
      // for false i.e unchecked
      setRemovedInvestmentTableId(row.spacingRegistrationUid);
      setRemoveInvestmentModal(true);
    } else {
      // for true i.e checked
      setInvestmentList((prevList) =>
        prevList.map(
          (item) =>
            item.spacingRegistrationUid === row.spacingRegistrationUid
              ? { ...item, inHeaderList: true } // Add inHeaderList: true for the matching item
              : item // Leave the rest unchanged
        )
      );
      setInvestmentHeader((prevHeader) => [
        ...prevHeader,
        { ...row, isHeader: true },
      ]);
    }
  };

  const handleInvestmentHeaderChange = (selectedHeader) => {
    setInvestmentHeader((prevHeader) =>
      prevHeader.map(
        (header) =>
          // Check if the header's description matches the selectedHeader's description
          header.spacingRegistrationUid ===
          selectedHeader.spacingRegistrationUid
            ? { ...header, isHeader: !selectedHeader.isHeader } // Toggle the isHeader value
            : header // Leave the other headers unchanged
      )
    );
  };

  const handleRemoveInvestmentfromModal = () => {
    setInvestmentList((prevList) =>
      prevList.map(
        (item) =>
          item.spacingRegistrationUid === removedInvestTableId
            ? { ...item, inHeaderList: false } // Add inHeaderList: true for the matching item
            : item // Leave the rest unchanged
      )
    );
    setInvestmentHeader((prevHeader) =>
      prevHeader.filter(
        (item) => item.spacingRegistrationUid !== removedInvestTableId
      )
    );
  };

  const handleTargetChange = (inputValue, ihId, description, rowIndex) => {
    setTableData((prev) => {
      const updatedData = [...prev];
      const targetRow = updatedData.find(
        (row) =>
          row.importHierarchyId === ihId && row.lineSpacing === description
      );
  
      if (targetRow) {
        // Convert to a floating-point number if inputValue is not empty
        targetRow.stiTarget = inputValue !== "" ? parseFloat(inputValue) : "";
      }
  
      return updatedData;
    });
  };
  

  const fetchInvestmentGroupList = async (keyword) => {
    try {
      const InvGroupResponse =
        await InvestmentGroupLimitsService.getInvestmentGroupListAPI({
          investmentGroupDescription: keyword,
        });

      const updatedInvestmentGroups =
        InvGroupResponse.data.response.investmentGroups.map((group) => ({
          ...group,
          inHeaderList: group.isActive,
        }));
      console.log("searchValueRRR", updatedInvestmentGroups);
      setInvestmentList(updatedInvestmentGroups);
    } catch (error) {
      console.error("Error fetching investment group data:", error);
    }
  };

  // const debouncedFetchInvestmentGroupList = debounce((value) => {
  //   console.log("searchValue2", value)
  //   fetchInvestmentGroupList();
  // }, 15);

  const debouncedFetchInvestmentGroupList = useCallback(
    debounce((value) => {
      console.log("searchValue2", value);
      fetchInvestmentGroupList(value);
    }, 300),
    [investmentList]
  );

  const handleInvestmentListSearch = (event) => {
    const { value } = event.target;
    console.log("searchValue1", value);
    setInvestmentListSearch(value);
    debouncedFetchInvestmentGroupList(value);
  };

  const fetchInvestmentHeader = async () => {
    try {
      const InvGroupResponse =
        await SpacingTargetService.getSpacingRegistration(geneticTypeId);
      console.log(
        "🚀 ~ fetchInvestmentHeader ~ InvGroupResponse:",
        InvGroupResponse
      );

      const data = InvGroupResponse.data.response;
      setInvestmentHeader(data);

      // If you plan to process or filter the investment groups, you can uncomment and update this logic
      // const IsActiveInvGroups = InvGroupResponse.data.response.investmentGroups.filter(investment =>
      //   investment.isActive === true
      // );

      // const updatedIsActiveInvGroups = IsActiveInvGroups.map((group) => ({
      //   ...group,
      //   isHeader: group.isActive,
      // }));

      // const updatedIsActiveInvGroupsTotal = InvGroupResponse.data.response.investmentGroups.map((group) => ({
      //   ...group,
      //   isHeader: group.isActive,
      //   total: 0
      // }));

      // setInvestmentTotal(updatedIsActiveInvGroupsTotal); // If you're updating investment total
    } catch (error) {
      console.error("Error fetching investment header data:", error);

      // If the error has a response with status (common with Axios)
      if (error.response && error.response.status === 404) {
        console.log("🚀 ~ fetchInvestmentHeader ~ No data found (404 error):");
        setInvestmentHeader([]); // Set empty array if 404 error
      } else {
        // Handle other types of errors
        setInvestmentHeader([]); // Set empty array in case of any error
      }
    }
  };

  console.log("HEADER", investmentHeader);
  useEffect(() => {
    console.log("INSIDE HEADER EFFECT", data);
    // if (data) {
    // setTableData(data.targetDistributionDtoList || []);
    // calculateTotals(data.targetDistributionDtoList || []);

    fetchInvestmentGroupList("");
    fetchInvestmentHeader();
    // }
    // else {
    // setInvestmentList([])
    // setInvestmentHeader([])
    // }
  }, [data, geneticTypeId]);

  useEffect(() => {
    if (childNodes && investmentList && investmentHeader) {
      // Initialize initialData as an empty array if data is not provided or empty
      let initialData = data && data.length > 0 ? [...data] : [];

      childNodes.forEach((child) => {
        const childExists = initialData.some(
          (item) => item.importHierarchyId === child.value
        );
        if (!childExists) {
          investmentHeader.forEach((investment) => {
            const investmentExists = initialData.some(
              (item) =>
                item.importHierarchyId === child.value &&
                item.spacingRegistrationId === investment.spacingRegistrationUid
            );

            if (!investmentExists) {
              initialData.push({
                importHierarchyId: child.value,
                lineSpacing: investment.lineSpacing,
                plantSpacing: investment.plantSpacing,
                investmentId: investment.igInvestmentGroupId,
                spacingRegistrationId: investment.spacingRegistrationUid,
                stiTarget: 0,
                distributed: 0,
                consumed: 0,
                percentage: 0,
                createdDateTimeUTC: new Date().toISOString(),
                modifiedDateTimeUTC: new Date().toISOString(),
                createdByUser: "defaultUser",
                modifiedByUser: "defaultUser",
                stiId: "defaultId",
              });
            }
          });
        } else {
          investmentHeader.forEach((investment) => {
            const investmentExists = initialData.some(
              (item) =>
                item.importHierarchyId === child.value &&
                item.lineSpacing === investment.lineSpacing
            );

            if (!investmentExists) {
              initialData.push({
                importHierarchyId: child.value,
                lineSpacing: investment.lineSpacing,
                plantSpacing: investment.plantSpacing,
                investmentId: investment.igInvestmentGroupId,
                spacingRegistrationId: investment.spacingRegistrationUid,
                stiTarget: 0,
                distributed: 0,
                consumed: 0,
                percentage: 0,
                createdDateTimeUTC: new Date().toISOString(),
                modifiedDateTimeUTC: new Date().toISOString(),
                createdByUser: "defaultUser",
                modifiedByUser: "defaultUser",
                stiId: "defaultId",
              });
            }
          });
        }
      });

      console.log("TABLE Data", initialData, "   ", initialData.length);
      setTableData(initialData);
    }
  }, [data, childNodes, investmentList]);

  // const calculateInvestmentTotals = (tableData) => {
  //   const totals = tableData.reduce((acc, group) => {
  //     const existingGroup = acc.find(
  //       (item) => item.spacingRegistrationUid === group.spacingRegistrationUid
  //     );

  //     if (existingGroup) {
  //       // If the group with the same investmentDescription exists, update totals
  //       existingGroup.totalTarget =
  //         Math.round((existingGroup.totalTarget + group.stiTarget) * 100) / 100;
  //       existingGroup.totalDistributed =
  //         Math.round(
  //           (existingGroup.totalDistributed + group.distributed) * 100
  //         ) / 100;
  //       existingGroup.totalConsumed =
  //         Math.round((existingGroup.totalConsumed + group.consumed) * 100) /
  //         100;
  //       existingGroup.totalBalance =
  //         Math.round((existingGroup.totalBalance + group.balance) * 100) / 100;
  //       // existingGroup.available = (existingGroup.totalTarget + group.target) - (existingGroup.totalDistributed + group.distributed);
  //       existingGroup.available =
  //         Math.round(
  //           (existingGroup.totalTarget - existingGroup.totalDistributed) * 100
  //         ) / 100;
  //     } else {
  //       // If it's a new investmentDescription, create a new entry
  //       acc.push({
  //         investmentDescription: group.investmentDescription,
  //         totalTarget: group.stiTarget,
  //         totalDistributed: group.distributed,
  //         totalConsumed: group.consumed,
  //         totalBalance: group.balance,
  //         available: group.target - group.distributed,
  //       });
  //     }

  //     return acc;
  //   }, []);

  //   console.log("totals",totals)
  //   return totals;
  // };

  // useEffect(() => {
  //   if (tableData) {
  //     const updatedTotals = calculateInvestmentTotals(tableData);
  //     setInvestmentTotal(updatedTotals);
  //   }
  // }, [tableData]);

  // Assuming groupData and tableData are already defined

  // Merge totalStiTarget into tableData
  const mergedData = tableData.map((tableItem) => {
    // Find the matching groupData entry based on importHierarchyId
    const matchingGroup = groupedData.find(
      (groupItem) => groupItem.value === tableItem.importHierarchyId
    );

    // If a match is found, add the totalStiTarget and calculate percentage
    if (matchingGroup) {
      const totalStiTarget = matchingGroup.totalStiTarget || 0;
      const stiTarget = tableItem.stiTarget || 0;
      const percentage =
        totalStiTarget > 0 ? (stiTarget / totalStiTarget) * 100 : 0;

      return {
        ...tableItem,
        totalStiTarget,
        percentage: percentage.toFixed(2), // Round to 2 decimal places
      };
    }

    // If no match is found, return the tableItem as is
    return {
      ...tableItem,
      percentage: 0, // Default percentage
    };
  });

  console.log("mergedData", mergedData);

  const calculateInvestmentTotals = (tableData) => {
    const totals = tableData.reduce((aggregatedData, group) => {
      console.log("grup", group);
      const existingGroup = aggregatedData.find(
        (item) => item.lineSpacing === group.lineSpacing
      );

      if (existingGroup) {
        // Update existing group's totals
        existingGroup.totalTarget =
          Math.round((existingGroup.totalTarget + group.stiTarget) * 100) / 100;
        existingGroup.totalDistributed =
          Math.round(
            (existingGroup.totalDistributed + group.distributed) * 100
          ) / 100;
        existingGroup.totalConsumed =
          Math.round((existingGroup.totalConsumed + group.consumed) * 100) /
          100;
        existingGroup.totalBalance =
          Math.round((existingGroup.totalBalance + group.percentage) * 100) /
          100;
        existingGroup.available =
          Math.round(
            (existingGroup.totalTarget - existingGroup.totalDistributed) * 100
          ) / 100;
      } else {
        // Add new group entry
        aggregatedData.push({
          lineSpacing: group.lineSpacing,
          // investmentDescription: group.investmentDescription,
          totalTarget: Math.round(group.stiTarget * 100) / 100,
          totalDistributed: Math.round(group.distributed * 100) / 100,
          totalConsumed: Math.round(group.consumed * 100) / 100,
          totalBalance: Math.round(group.percentage * 100) / 100,
          available:
            Math.round((group.stiTarget - group.distributed) * 100) / 100,
        });
      }

      return aggregatedData;
    }, []);

    console.log("Calculated Totals:", totals);
    return totals;
  };

  useEffect(() => {
    if (tableData && tableData.length > 0) {
      const updatedTotals = calculateInvestmentTotals(tableData);
      setInvestmentTotal(updatedTotals);
    }
  }, [tableData]);

  const groupAndSumStiTarget = (data) => {
    return data.reduce((acc, curr) => {
      const { importHierarchyId, stiTarget } = curr;

      // If the group doesn't exist, initialize it
      if (!acc[importHierarchyId]) {
        acc[importHierarchyId] = {
          importHierarchyId,
          totalStiTarget: 0,
          items: [],
        };
      }

      // Add the current stiTarget to the total
      acc[importHierarchyId].totalStiTarget += stiTarget;

      // Add the full item to the group
      acc[importHierarchyId].items.push(curr);

      return acc;
    }, {});
  };

  useEffect(() => {
    if (tableData) {
      // Group tableData to get summed STI Targets
      const grouped = groupAndSumStiTarget(tableData);

      // Map through childNodes and add totalStiTarget
      const updatedChildNodes = childNodes.map((node) => ({
        ...node,
        totalStiTarget: grouped[node.value]?.totalStiTarget || 0,
      }));

      // Update state with grouped childNodes
      setGroupedData(updatedChildNodes);
    }
  }, [tableData]);

  const handleSave = async () => {
    try {
      const currentDateTime = new Date().toISOString();
      const currentUser = "currentUser"; // Replace with actual user info

      // Map tableData to the required structure
      const importHierarchyPayload = mergedData
        .filter((item) => item.importHierarchyId) // Exclude items without importHierarchyId
        .map((item) => ({
          createdDateTimeUTC:
            item.stiId === "defaultId"
              ? currentDateTime
              : item.createdDateTimeUTC,
          createdByUser:
            item.stiId === "defaultId" ? currentUser : item.createdByUser,
          modifiedDateTimeUTC: currentDateTime,
          modifiedByUser: currentUser,
          stiId: item.stiId === "defaultId" ? "" : item.stiId,
          spacingRegistrationId: item.spacingRegistrationId,
          importHierarchyId: item.importHierarchyId,
          stiTarget: item.stiTarget,
          percentage: item.percentage,
          distributed: item.distributed,
          consumed: item.consumed,
          parentTarget: item.parentTarget || 0,
        }));

      // Construct the final payload
      const finalPayload = {
        cropSeasonId: cropYearUUID,
        geneticTypeId: geneticTypeId,
        parentImportHierarchyId: ihId,
        importHierarchyId: importHierarchyPayload,
      };

      // Debug log
      console.log("Final Payload:", finalPayload);

      // Make the API call
      await SpacingTargetService.putInvestmentGLDAPI(finalPayload);

      setMessage("Spacing Target saved successfully");
      setSeverity("success");
      setOpenAlert(true);
    } catch (error) {
      console.error("Error saving data:", error);
      setMessage(error.message || "Error saving production targets");
      setSeverity("error");
      setOpenAlert(true);
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {/* First Grid Item */}
        <Grid item xs={4}>
          <Box sx={{ backgroundColor: "#EFEFEF", borderRadius: "8px" }}>
            <Box sx={{ display: "flex" }}>
              <ArrowDropDownIcon sx={{ marginBottom: 1.1 }} />
              <Typography>{t("investmentGroupLimits.showColumns")}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                ml: 1,
                overflowX: "auto", // Enables horizontal scrolling
                whiteSpace: "nowrap", // Prevents wrapping of child elements
              }}
            >
              {/* {columns.map((column) => (
                <Box key={column}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showColumns[column.toLowerCase()]}
                        onChange={(e) => handleColumnChange(column.toLowerCase(), e.target.checked)}
                      />
                    }
                    label={column}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "16px",
                        fontWeight: 400,
                        whiteSpace: "normal", // Allows wrapping of text
                        wordBreak: "break-word", // Ensures long words break correctly
                        overflow: "hidden",
                      },
                    }}
                  />
                </Box>
              ))} */}
              {columns.map((column) => {
                const isDisabled =
                  Object.values(showColumns).filter((value) => value).length <
                    2 && showColumns[column.toLowerCase()];

                return (
                  <Box key={column}>
                    <FormControlLabel
                      control={
                        <Tooltip
                          title={
                            isDisabled
                              ? t("investmentGroupLimits.columnTooltip")
                              : ""
                          }
                          arrow
                          disableInteractive
                        >
                          <span>
                            <Checkbox
                              disabled={isDisabled}
                              checked={showColumns[column.toLowerCase()]}
                              onChange={(e) =>
                                handleColumnChange(
                                  column.toLowerCase(),
                                  e.target.checked
                                )
                              }
                              sx={{
                                color: "#004F9F", // Unchecked color
                                "&.Mui-checked": {
                                  color: "#004F9F", // Checked color
                                },
                              }}
                            />
                          </span>
                        </Tooltip>
                      }
                      label={column}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "16px",
                          fontWeight: 400,
                          // whiteSpace: "normal", // Allows wrapping of text
                          // wordBreak: "break-word", // Ensures long words break correctly
                          // overflow: "hidden",
                        },
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Grid>

        {/* Second Grid Item */}
        <Grid item xs={8}>
          <Box sx={{ backgroundColor: "#EFEFEF", borderRadius: "8px" }}>
            <Box sx={{ display: "flex" }}>
              <ArrowDropDownIcon sx={{ marginBottom: 1.1 }} />
              <Typography>{t("investmentGroupLimits.showHeaders")}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              {/* Header checkboxes */}
              <Box
                sx={{
                  flex: 1,
                  overflowX: "auto",
                  display: "flex",
                  gap: 1,
                  ml: 1,
                }}
              >
                {investmentHeader &&
                  investmentHeader.length > 0 &&
                  investmentHeader.map((header) => {
                    // Calculate the count of selected headers
                    const selectedHeadersCount = investmentHeader.filter(
                      (h) => h.lineSpacing
                    ).length;

                    // Determine if the checkbox should be disabled
                    const isDisabled =
                      selectedHeadersCount < 2 && header.lineSpacing;

                    return (
                      <Box
                        item
                        key={header.spacingRegistrationUid}
                        sx={{
                          flexShrink: 0, // Prevents items from shrinking
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Tooltip
                              title={
                                isDisabled
                                  ? t("investmentGroupLimits.headerTooltip")
                                  : ""
                              }
                              arrow
                            >
                              <span>
                                {" "}
                                {/* Wrapper for Tooltip to work on disabled elements */}
                                <Checkbox
                                  checked={header.isHeader}
                                  disabled={isDisabled}
                                  onChange={() =>
                                    handleInvestmentHeaderChange(header)
                                  }
                                  sx={{
                                    color: "#004F9F", // Unchecked color
                                    "&.Mui-checked": {
                                      color: "#004F9F", // Checked color
                                    },
                                  }}
                                />
                              </span>
                            </Tooltip>
                          }
                          label={
                            header.lineSpacing + "x " + header.plantSpacing
                          }
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "16px",
                              fontWeight: 400,
                            },
                          }}
                        />
                      </Box>
                    );
                  })}
              </Box>
              {/* Manage Items button */}
              {/* {isTopHierarchyNode && (
                <Box
                  sx={{
                    width: "30%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mr: 2 }}
                    onClick={() => setManageItemsModal(true)}
                  >
                    Manage Items
                  </Button>
                </Box>
              )} */}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 10, mt: 1 }}>
        <Grid item xs={4}>
          <StyledTableContainer component={Paper}>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <SubHeaderCell
                    colSpan={4}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      padding: "25px",
                    }}
                  ></SubHeaderCell>
                </TableRow>
                <TableRow>
                  <SubHeaderCell
                    colSpan={4}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      padding: "26px",
                    }}
                  ></SubHeaderCell>
                </TableRow>
                <TableRow>
                  <SecondaryHeaderCell colSpan={4}>
                    {t("investmentGroupLimits.hnSearchField")}
                  </SecondaryHeaderCell>
                  <SecondaryHeaderCell colSpan={4}>Totals (Ha)</SecondaryHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupedData?.map((row, index) => (
                  <TableRow key={index}>
                    <DataCell colSpan={4}>{row.label}</DataCell>
                    <DataCell colSpan={4}>{row.totalStiTarget}</DataCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TotalCell colSpan={4}>Totals</TotalCell>
                  <TotalCell colSpan={4}></TotalCell>
                </TableRow>
              </TableBody>
            </StyledTable>
          </StyledTableContainer>
        </Grid>
        <Grid item xs={8}>
          <StyledTableContainer component={Paper}>
            <StyledTable>
              <TableHead>
                <TableRow>
                  {investmentHeader
                    .filter((header) => header.isHeader === true)
                    .map((row, index) => (
                      <HeaderCell
                        colSpan={Math.max(
                          2,
                          1 + Object.values(showColumns).filter(Boolean).length
                        )}
                      >
                        {row.lineSpacing + " x " + row.plantSpacing}
                      </HeaderCell>
                    ))}
                </TableRow>
                <TableRow>
                  {investmentHeader
                    .filter((header) => header.isHeader === true)
                    .map((header) =>
                      investmentTotal
                        .filter(
                          (item) => item.lineSpacing === header.lineSpacing
                        )
                        .map((investment) => (
                          <SubHeaderCell
                            colSpan={Math.max(
                              2,
                              1 +
                                Object.values(showColumns).filter(Boolean)
                                  .length
                            )}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%", // Full width for inner content
                              }}
                            >
                              {t("investmentGroupLimits.available/target")}
                              <span>
                                {/* {"0 / "} {totals.investments.reduce((sum, inv) => sum + inv.target, 0).toLocaleString()} */}
                                <>
                                {isNaN(investment.available) ? 0 : investment.available}/{" "}
                                  {investment.totalTarget}
                                </>
                              </span>
                            </Box>
                          </SubHeaderCell>
                        ))
                    )}
                </TableRow>
                <TableRow>
                  {investmentHeader
                    .filter((header) => header.isHeader === true)
                    .map((row, index) =>
                      ["Target", "Percentage", "Distributed", "Consumed"]
                        .filter((header) => {
                          const key = header.toLowerCase(); // Convert to lowercase to match state keys
                          return key === "target" || showColumns[key]; // Always show 'Target', filter others based on state
                        })
                        .map((header) => (
                          <SecondaryHeaderCell
                            key={header}
                            style={{
                              borderLeft:
                                header === "Target"
                                  ? "none"
                                  : "5px solid white",
                              borderRight: "5px solid white",
                              minWidth: "50px",
                            }}
                          >
                            {/* Display 'Target (Ha)' for Target */}
                            {header === "Target"
                              ? "Target (Ha)"
                              : header === "Percentage"
                              ? "%"
                              : header}

                            {header === "Percentage" && (
                              <Tooltip
                                title="Shows the percentage that the target corresponds to from the total Ha target assigned to the subordinate."
                                arrow
                              >
                                <img
                                  src={Question}
                                  alt="New"
                                  style={{
                                    marginLeft: "15px",
                                    width: "15px",
                                    height: "15px",
                                    cursor: "pointer",
                                  }}
                                />
                              </Tooltip>
                            )}
                          </SecondaryHeaderCell>
                        ))
                    )}
                </TableRow>
              </TableHead>

              <TableBody>
                {childNodes.map((child) => (
                  <TableRow key={child.value}>
                    {investmentHeader
                      .filter((header) => header.isHeader === true)
                      .map((header) => {
                        const rows = mergedData.filter(
                          (data) =>
                            data.importHierarchyId === child.value &&
                            data.lineSpacing === header.lineSpacing
                        );

                        // console.log("Filtered Rows for header", header, rows);

                        return rows.map((row, rowIndex) => {
                          console.log("ROWDATA", row); // Moved outside JSX for debugging

                          return [
                            "stiTarget",
                            "percentage",
                            "distributed",
                            "consumed",
                          ].map((key, i) => {
                            const value = row[key]; // Access the corresponding value

                            if (key === "stiTarget") {
                              // Render "target" as a TextField
                              return (
                                // <DataCell >
                                <StyledTextField
                                  type="number"
                                  key={`${rowIndex}-${i}`}
                                  fullWidth
                                  variant="outlined"
                                  value={value}
                                  sx={{
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                      {
                                        display: "none",
                                      },
                                  }}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                  
                                    // Allow only valid numbers or an empty string
                                    if (/^\d{0,10}(\.\d{0,2})?$/.test(inputValue) || inputValue === "") {
                                      // Normalize to remove leading zeros before passing
                                      const normalizedValue = inputValue.replace(/^0+(?!\.|$)/, ""); // Remove leading zeros
                                      handleTargetChange(normalizedValue, child.value, header.lineSpacing, rowIndex);
                                    }
                                  }}
                                  
                                />
                                // </DataCell>
                              );
                            } else {
                              // Render other values as plain text
                              if (!showColumns[key]) return null;
                              return (
                                <DataCell key={`${rowIndex}-${i}`}>
                                  {value?.toLocaleString()}
                                </DataCell>
                              );
                            }
                          });
                        });
                      })}
                  </TableRow>
                ))}
                <TableRow>
                  {investmentHeader
                    .filter((header) => header.isHeader === true)
                    .map((item) =>
                      investmentTotal
                        .filter(
                          (total) => total.lineSpacing === item.lineSpacing
                        )
                        .map((itemTotal) => (
                          <>
                            <TotalCell>{itemTotal.totalTarget}</TotalCell>
                            {showColumns.percentage && (
                              <TotalCell> - </TotalCell>
                            )}
                            {showColumns.distributed && (
                              <TotalCell>
                                {itemTotal.totalDistributed}
                              </TotalCell>
                            )}
                            {showColumns.consumed && (
                              <TotalCell>{itemTotal.totalConsumed}</TotalCell>
                            )}
                          </>
                        ))
                    )}
                  {/* {investmentHeader.map((header) => (
                    tableData.filter((data) => data.investmentDescription === header.igInvestmentGroupDescription).map((item) => (
                      
                    ))
                  ))

                  } */}
                  {/* {totals.investments.map((investment, i) =>
                    Object.entries(investment)
                      .filter(([key]) => key === "target" || showColumns[key.toLowerCase()])
                      .map(([key, value], j) => (
                        <TotalCell key={`${i}-${j}`}>
                          {key === "target" ? parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ""}
                        </TotalCell>
                      ))
                  )} */}
                </TableRow>
              </TableBody>
            </StyledTable>
          </StyledTableContainer>
        </Grid>
      </Grid>
      <Divider
        sx={{
          width: "100%", // Ensures the divider spans the full width
          marginBottom: 1, // Adds spacing between the divider and the button
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          sx={{
            padding: "6px 16px",
            borderRadius: "4px",
            // right: "1rem", // Aligns to the right bottom corner
            // bottom: "1rem", // Position it at the bottom
            marginBottom: 4,
            backgroundColor: "#EF7D00",
            color: "white",
            "&:hover": {
              backgroundColor: "#EF7D00",
            },
          }}
          startIcon={<img src={Save} alt="Right Arrow" />}
          onClick={handleSave}
          variant="contained"
        >
          {t("save")}
        </Button>
      </Box>
    </>
  );
};
export default SpacingTargetTable;
