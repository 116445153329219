// GetCuringUnit.jsx
import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import CuringUnitTable from "./CuringUnitTable";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CuringUnitService from "services/CuringUnitService";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const GetCuringUnit = () => {
  const navigate = useNavigate();
  const [curingData, setCuringData] = useState([]);
  const [curingTypes, setCuringTypes] = useState([]);
  const [filCuringData, setfilCuringData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [curingUnit, setCuringUnit] = useState("");
  const [curingType, setCuringType] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [status, setStatus] = useState("");
  const [isDeleted, setIsDeleted] = useState(0);
  const { t } = useTranslation();
  const debounceTimeout = useRef(null); // Declare useRef at the top level of the component

  const fetchCuringTypes = () => {
    CuringUnitService.getCuringTypes()
      .then((res) => {
        const curingTypes =
          res?.data?.response?.curingTyoes.map((curingType) => ({
            curingType: curingType.curingType,
            ctrId: curingType.ctrId,
          })) || [];
        setCuringTypes(curingTypes);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const handleSearchKeywordChange = (event) => {
    const value = event.target.value;
    setCuringUnit(value);
    setCurrentPage(0);

    // Clear the previous timeout to debounce the API call
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new timeout to call the API after 2 seconds
    debounceTimeout.current = setTimeout(() => {
      if (value) {
        callSearchCuringAPI(0, itemsPerPage, value, curingType, status);
      } else {
        callSearchCuringAPI(0, itemsPerPage, "", curingType, status);
      }
    }, 1500);
  };

  const handleClearStatus = () => {
    setStatusFilter("");
    setStatus(null);
    setCurrentPage(0);
    callSearchCuringAPI(0, itemsPerPage, curingUnit, curingType, null);
  };

  const handleClearCuringType = () => {
    setCuringType("");
    setCuringType("");
    setCurrentPage(0);
    callSearchCuringAPI(0, itemsPerPage, curingUnit, "", status);
  };

  const handleStatusChange = (statusValue) => {
    setStatusFilter(statusValue);
    setCurrentPage(0);
    const isActive =
      statusValue === "Active"
        ? true
        : statusValue === "Inactive"
        ? false
        : null;
    setStatus(isActive);
    callSearchCuringAPI(0, itemsPerPage, curingUnit, curingType, isActive);
  };

  const handleCuringTypeChange = (curingType) => {
    setCuringType(curingType);
    setCurrentPage(0);
    callSearchCuringAPI(0, itemsPerPage, curingUnit, curingType, status);
  };

  const callSearchCuringAPI = (
    pageNumber,
    size,
    curingUnit,
    curingType,
    status
  ) => {
    setLoading(true);
    let query = { pageNumber, size, curingUnit, curingType, status };

    if (status === true || status === false) {
      query.status = status;
    }
    if (!curingType) {
      query.curingType = "";
    }

    CuringUnitService.getCuringUnits(query)
      .then((res) => {
        const curingResults = res?.data?.response?.curingUnits || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setCuringData(curingResults);
        setfilCuringData(curingResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (curingResults.length === 0 && curingUnit.length > 0) {
          setMessage("No records found.");
          setSeverity("info");
          setOpenAlert(true);
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setCuringData([]);
        setfilCuringData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCuringTypes();
  }, []);

  useEffect(() => {
    callSearchCuringAPI(
      currentPage,
      itemsPerPage,
      curingUnit,
      curingType,
      status
    );
  }, [currentPage, itemsPerPage, isDeleted]);

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    callSearchCuringAPI(
      pageNumber - 1,
      itemsPerPage,
      curingUnit,
      curingType,
      status
    );
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t("curingUnitRegistration.pageTitle")}
      background="#ffffff"
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          padding: "1rem",
          height: "100px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} />
          <Typography sx={{ fontWeight: "600" }}>
            {t("filtersLable")}
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={10}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3.6}>
                <TextField
                  name="curingType"
                  label={t("curingUnitRegistration.curingUnitName")}
                  value={curingUnit}
                  onChange={handleSearchKeywordChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px", // Adjust padding for the input content
                      minHeight: "50px", // Adjust the overall height
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em", // Adjust label spacing if needed
                    },
                    width: "100%",
                  }}
                  InputProps={{
                    style: { height: "50px" }, // Directly setting the height
                  }}
                />
              </Grid>

              {/* Culture Field */}
              <Grid item xs={12} sm={3.6}>
                <FormControl sx={{ width: { xs: "100%" }, minHeight: 50 }}>
                  <TextField
                    select
                    name="Culture"
                    label={t("curingUnitRegistration.curingType")}
                    value={curingType || "Select an Option"}
                    onChange={(event) => {
                      handleCuringTypeChange(event.target.value);
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                      endAdornment: curingType && (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={handleClearCuringType}
                            style={{ marginRight: "15px" }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem value="Select an Option" sx={{ display: "none" }}>
                      {t("selectoption")}
                    </MenuItem>
                    {/* Map over cultureNames to create MenuItem options */}
                    {curingTypes.map((curing) => (
                      <MenuItem key={curing.ctrId} value={curing.curingType}>
                        {curing.curingType}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>

              {/* Status field */}
              <Grid item xs={12} sm={3.6}>
                <FormControl sx={{ width: { xs: "100%" }, minHeight: 50 }}>
                  <TextField
                    select
                    name="status"
                    label={t("status")}
                    value={statusFilter || "Select an Option"}
                    onChange={(event) => handleStatusChange(event.target.value)}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                      endAdornment: statusFilter && (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={handleClearStatus}
                            style={{ marginRight: "15px" }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem value="Select an Option" sx={{ display: "none" }}>
                      {t("selectoption")}
                    </MenuItem>
                    <MenuItem value="Active">{t("active")}</MenuItem>
                    <MenuItem value="Inactive">{t("inactive")}</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={2} textAlign={"right"}>
            <Button
              sx={{
                borderRadius: 1,
                minWidth: 95,
                minHeight: 44,
                background: "#004F9F",
              }}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => navigate("/addCuringUnit")} // Adjust for curing unit registration navigation
            >
              {t("addNew")}
            </Button>
          </Grid>
        </Grid>
      </Box>
      {/* Render table header if no data is found */}
      <CuringUnitTable
        curingData={filCuringData}
        setCuringData={setfilCuringData}
        setIsDeleted={setIsDeleted}
      />
    </Layout>
  );
};

export default GetCuringUnit;
