import { httpGrow } from "../http-common";

const createLevelOfEducation = (data) => {
  return httpGrow.post(`/levelOfEducationRegistration`, data);
};

const updateLevelOfEducation = (data) => {
  return httpGrow.put(`/levelOfEducationRegistration`, data);
};

const getLevelOfEducations = async (query) => {
  const { pageNumber, pageSize, levelOfEducationDesc, isActive } = query;

  const response = await httpGrow.get(`/levelOfEducationRegistration/search`, {
    params: {
      pageNumber,
      pageSize,
      levelOfEducationDesc: levelOfEducationDesc || undefined,
      educationTypeDesc: undefined,
      isActive: isActive,
    },
  });
  return response;
};

const getEducationTypes = async () => {
  return httpGrow.get(`/education-type/search?isActive=true`);
};

const LevelOfEducationService = {
  createLevelOfEducation,
  updateLevelOfEducation,
  getLevelOfEducations,
  getEducationTypes,
};

export default LevelOfEducationService;
