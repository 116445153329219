import { useLocation } from "react-router-dom"; // Import useLocation
import Tooltip from "@mui/material/Tooltip";
import MoreDetails from "assets/MoreDetails.svg";
import Arrow from "assets/UpDownArrow.svg";
import Details from "assets/Details.svg";
import view from "assets/view.svg";
import "./Table.css";
import { useTranslation } from "react-i18next";
import Layout from "components/structure/Layout";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React, { useEffect, useState } from "react";
import download from "assets/download.svg";
import Plus from "assets/Plus.svg";
import Approve from "assets/Approve.svg";
import Reject from "assets/Reject.svg";
import { Autocomplete, Box, Button, Typography, Grid } from "@mui/material";
import FormData from "./FormData";
import SimulationData from "./SimulatonData";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; 
import { useNavigate } from "react-router-dom";

const PreAssesmentForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { row } = location.state; // Access the passed row data
  const { t } = useTranslation();
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const handleMoreDetailsClick = () => {
    setShowMoreDetails(!showMoreDetails); // Toggle visibility of the MoreDetails box
  };
  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <>
      <Layout title={t(`${row.functionalityName}`)} background="#ffffff">
        <div>
          <Grid item xs={12} sx={{ paddingTop: "0px", paddingLeft: "0px" }}>
            <Typography variant="h8" display="flex">
              <ArrowDropDownIcon />
              {t("Approval Instance")}
            </Typography>
          </Grid>
          <table className="approval-dashboard-table">
            <thead>
              <tr>
                <th
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                >
                  {t("System Module")}
                </th>
                <th
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                >
                  {t("Approval Description")}
                </th>
                <th
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                >
                  {t("Requestor")}
                </th>
                <th
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                >
                  {t("Request Date")}
                </th>
                <th
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                >
                  {t("status")}
                </th>
                <th
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                >
                  {t("Approval Level")}
                </th>
                <th className="actions-header">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      justifyContent: "left",
                    }}
                  >
                    {t("Details/Actions")}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{row.functionalityName}</td>
                <td>{row.approvalDesc}</td>
                <td>{row.requestor}</td>
                <td>{row.requestDate}</td>
                <td>{row.status}</td>
                <td>{'Level 1'}</td>
                <td className="actions-cell">
                  <Tooltip title="view" arrow placement="top">
                    <img
                      src={view}
                      alt="view"
                      style={{ cursor: "pointer" }} // Optional: Add a pointer cursor to indicate clickability
                    />
                  </Tooltip>

                  <Tooltip
                  title={showMoreDetails ? "Less Details" : "More Details"}
                  arrow
                  placement="top"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "3px",
                    }}
                    onClick={handleMoreDetailsClick}
                  >
                    <img src={Details} alt="Details" />
                    <img
                      src={Arrow}
                      style={{ transform: !showMoreDetails && "rotate(180deg)" }}
                      height="10px"
                      alt="Details"
                    />
                  </div>
                </Tooltip>
                </td>
              </tr>
            </tbody>
          </table>
          {showMoreDetails && (
            <div className="more-details-box">
              <table className="more-details-table">
                <thead>
                  <tr>
                    <th>{t("Date")}</th>
                    <th>{t("Approval Level")}</th>
                    <th>{t("User")}</th>
                    <th>{t("Comment")}</th>
                    <th>{t("Action")}</th>
                    <th>{t("Attachment")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{row.requestDate}</td>
                    <td>{'Level 1'}</td>
                    <td>{row.requestor}</td>
                    <td>{row.approvalDesc}</td>
                    <td>{row.status}</td>
                    <td>{row.status}</td>
                  </tr>
                </tbody>
              </table>

              {/* Buttons Section */}
              <div className="button-container">
                <div className="left-buttons">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#102A62",
                      alignItems: "center",
                      gap: "8px",
                      color: "#ffffff",
                    }}
                  >
                    <img
                      src={Plus}
                      alt="Plus Icon"
                      style={{ width: 20, height: 17 }}
                    />
                    {t("ADD COMMENT")}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#004F9F",
                      alignItems: "center",
                      gap: "8px",
                      color: "#ffffff",
                    }}
                  >
                    <img
                      src={download}
                      alt="Download Icon"
                      style={{ width: 20, height: 17 }}
                    />
                    {t("EXPORT HISTORY")}
                  </Button>
                </div>
                {row.status !== "Rejected" && row.status !== "Approved" && (
                  <div className="right-buttons">
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#50AF47",
                        alignItems: "center",
                        gap: "8px",
                        color: "#ffffff",
                      }}
                    >
                      <img
                        src={Approve}
                        alt="Approve Icon"
                        style={{ width: 20, height: 25 }}
                      />
                      {t("APPROVE")}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#EF0000",
                        alignItems: "center",
                        gap: "8px",
                        color: "#ffffff",
                      }}
                    >
                      <img
                        src={Reject}
                        alt="Reject Icon"
                        style={{ width: 20, height: 25 }}
                      />
                      {t("REJECT")}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {row.functionalityName === "Hierarchy Configuration" && <SimulationData />}
        {row.functionalityName === "Season Registration" && <FormData />}
        <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          backgroundColor: "#ffffff",
          padding: "10px 20px",
          borderTop: "1px solid #ddd",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <Button
          onClick={handleBackClick}
          variant="contained"
          sx={{
            backgroundColor: "#737D86",
            color: "#ffffff",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
         <ArrowBackIcon />
          {t("Back")}
        </Button>
      </Box>
      </Layout>
    </>
  );
};
export default PreAssesmentForm;
