import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
    Autocomplete,
    Box,
    Button,
    TextField,
    FormControl,
    MenuItem,
    Typography,
    Grid,
    InputAdornment,
    IconButton,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import Layout from "components/structure/Layout";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { SnackbarContext } from "context/snackBarContext";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import SearchTextInput from "components/common/SearchTextInput";
import { useTranslation } from "react-i18next";
import SearchIcon from '../../../../assets/SearchIcon.svg';
import AgriculturalSuppliersRegService from 'services/AgriculturalSuppliesRegService';
import AgriculturalSuppliesRegTable from './AgriculturalSuppliesRegTable';


const AgriculturalSuppliesReg = () => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(() => {
        return parseInt(localStorage.getItem("agriSupplyReg")) || 5;
    });
    const [totalItems, setTotalItems] = useState(0);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [supplyType, setSupplyType] = useState("");
    const [supplyTypeId, setSupplyTypeId] = useState("");
    const [supplyTypeMenu, setSupplyTypeMenu] = useState([]);
    const [typeOfUse, setTypeOfUse] = useState("");
    const [typeOfUseId, setTypeOfUseId] = useState("");
    const [typeOfUseMenu, setTypeOfUseMenu] = useState([]);
    const [notConfigured, setNotConfigured] = useState(false);

    console.log("usedRecords", notConfigured)
    const handleNotConfigured = () => {
        setNotConfigured(!notConfigured);
        fetchData(0, itemsPerPage, searchKeyword, supplyTypeId, typeOfUseId, !notConfigured);
    };

    const debouncedSearch = useCallback(
        debounce((value) => {
            console.log('usedRecordscalback', supplyType, typeOfUse)
            fetchData(0, itemsPerPage, value, supplyTypeId, typeOfUseId, notConfigured);
        }, 10),
        [itemsPerPage, notConfigured, supplyTypeId, typeOfUseId]
    );

    const handleSearchChange = (value) => {
        setSearchKeyword(value);
        if (value === "") {
            fetchData(0, itemsPerPage, "", supplyTypeId, typeOfUseId, notConfigured); // Fetch data without search keyword when input is cleared
        } else {
            debouncedSearch(value);
        }
    };

    const fetchData = (
        pageNumber,
        size,
        keyword,
        supplyTypeId,
        typeOfUseId,
        notConfigured
    ) => {
        setLoading(true);
        const query = {
            pageNumber,
            size,
            keyword,
            supplyTypeId,
            typeOfUseId,
            notConfigured,
        };
        console.log("usedRecordsquery", query)
        AgriculturalSuppliersRegService.getAPI(query)
            .then((res) => {
                const results =
                    res?.data?.response?.agriculturalSupplies || [];
                setData(results);
                setTotalItems(res?.data?.response?.totalItems || 0);
                if (results.length === 0 && keyword.length > 0) {
                    setMessage("No records found.");
                    setSeverity("info");
                    setOpenAlert(true);
                }
            })
            .catch((error) => {
                setMessage(error?.response?.data?.message || error?.message);
                setSeverity("error");
                setOpenAlert(true);
                setData([]);
                setTotalItems(0);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const fetchSupplyType = async () => {
        try {
            const [supplyTypeResponse] = await Promise.all([
                AgriculturalSuppliersRegService.getSupplyTypeAPI(),
            ]);

            console.log("Supply menu", supplyTypeResponse.data.response)
            setSupplyTypeMenu(supplyTypeResponse.data.response.map(item => ({
                value: item.supplyType.toString(),
                id: item.supplyTypeId.toString(),
            })))

        } catch (error) {
            console.error("Error fetching supply type:", error);
        }
    };
    const fetchTypeOfUse = async () => {
        try {
            const [typeOfUseResponse] = await Promise.all([
                AgriculturalSuppliersRegService.getTypeOfUseAPI(),
            ]);

            console.log("TOU menu", typeOfUseResponse.data.response)
            setTypeOfUseMenu(typeOfUseResponse.data.response.map(item => ({
                value: item.typeOfUse.toString(),
                id: item.typeOfUseId.toString(),
            })))

        } catch (error) {
            console.error("Error fetching Type Of use type:", error);
        }
    };

    useEffect(() => {
        fetchData(currentPage, itemsPerPage, searchKeyword, supplyTypeId, typeOfUseId, notConfigured);
    }, [currentPage, itemsPerPage, supplyType, typeOfUse]);

    useEffect(() => {
        fetchSupplyType();
        fetchTypeOfUse();
    }, [])

    const paginate = (pageNumber, itemsPerPage) => {
        setCurrentPage(pageNumber - 1);
        setItemsPerPage(itemsPerPage);
        localStorage.setItem("agriSupplyReg", itemsPerPage)
        fetchData(pageNumber - 1, itemsPerPage, searchKeyword, supplyTypeId, typeOfUseId, notConfigured);
    };

    const handleSupplyType = (event, newValue) => {
        console.log("Selected ST", newValue)
        if (newValue) {
            setSupplyType(newValue.value);
            setSupplyTypeId(newValue.id)
        } else {
            setSupplyType("")
            setSupplyTypeId("")
        }
    };
    const handleTypeOfUse = (event, newValue) => {
        if (newValue) {
            setTypeOfUse(newValue.value);
            setTypeOfUseId(newValue.id)
        } else {
            setTypeOfUse("");
            setTypeOfUseId("")
        }
    };

    return (
        <Layout
            itemsPerPage={itemsPerPage}
            totalItems={totalItems}
            paginate={paginate}
            currentPage={currentPage}
            title={t("agriSupplyReg.pageTitle")}
            background="#ffffff"
            useNewPagination={true}
        >
            <Box
                sx={{
                    background: "#EFEFEF",
                    borderRadius: "10px",
                    padding: "1rem",
                    height: "110px",
                }}
            >
                <Box sx={{ display: "flex" }}>
                    <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} />
                    <Typography fontWeight={600} color="#666666">{t("filtersLable")}</Typography>
                </Box>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4}>
                        <SearchTextInput
                            label={t("agriSupplyReg.codeName")}
                            value={searchKeyword}
                            onChange={(e) => handleSearchChange(e.target.value)}
                            placeholder={t("")}
                            startAdornment={false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl sx={{ width: "100%" }}>
                            <Autocomplete
                                disableClearable={false}
                                disablePortal
                                value={supplyTypeMenu.find(option => option.id === supplyTypeId) || null}
                                onChange={handleSupplyType}
                                options={supplyTypeMenu}
                                getOptionLabel={(option) => option.value || ""}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        padding: "7px", // Adjust padding for the input content
                                        height: "46px", // Adjust the overall height
                                    },
                                    "& .MuiInputLabel-root": {
                                        lineHeight: "1.2em", // Adjust label spacing if needed
                                    },
                                }}
                                InputProps={{
                                    style: { height: "60%" }, // Directly setting the height
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("agriculturalSupplyFieldNotebookRegistration.supplyType")}
                                        fullWidth
                                        placeholder='Select an Option'
                                        InputLabelProps={{ shrink: true }}
                                        sx={{
                                            '& .MuiInputBase-input::placeholder': {
                                                opacity: 1,
                                            },
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <img src={SearchIcon} alt="search" style={{ width: '20px', height: '20px', color: '#737D86' }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl sx={{ width: "100%" }}>
                            <Autocomplete
                                disableClearable={false}
                                disablePortal
                                value={typeOfUseMenu.find(option => option.id === typeOfUseId) || null}
                                onChange={handleTypeOfUse}
                                options={typeOfUseMenu}
                                getOptionLabel={(option) => option.value || ""}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        padding: "7px", // Adjust padding for the input content
                                        height: "46px", // Adjust the overall height
                                    },
                                    "& .MuiInputLabel-root": {
                                        lineHeight: "1.2em", // Adjust label spacing if needed
                                    },
                                }}
                                InputProps={{
                                    style: { height: "60%" }, // Directly setting the height
                                }}
                                InputLabelProps={{ shrink: true }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("agriSupplyReg.typeOfUse")}
                                        fullWidth
                                        placeholder='Select an Option'
                                        InputLabelProps={{ shrink: true }}
                                        sx={{
                                            '& .MuiInputBase-input::placeholder': {
                                                opacity: 1,
                                            },
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <img src={SearchIcon} alt="search" style={{ width: '20px', height: '20px', color: '#737D86' }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} display="flex" justifyContent="flex-end" textAlign={"right"}>
                        <FormControlLabel
                            label={t("agriSupplyReg.notConfigured")}
                            sx={{
                                "& .MuiFormControlLabel-label": {
                                    color: "rgba(0, 0, 0, 0.6)",
                                    fontWeight: 400
                                },
                            }}
                            control={
                                <Checkbox
                                    checked={notConfigured}
                                    onChange={handleNotConfigured}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                        />

                    </Grid>
                </Grid>
            </Box>
            {loading ? (
                <Typography variant="h6" align="center">
                    {t("loading")}
                </Typography>
            ) : (
                <>
                    {(
                        totalItems > 0 && <AgriculturalSuppliesRegTable agriData={data} />
                    )}
                </>
            )}


        </Layout>
    );
};


export default AgriculturalSuppliesReg