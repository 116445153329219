import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Grid,
  Typography,
  Modal,
  MenuItem,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import Layout from "components/structure/Layout";
import SoilAnalysisInterpretationDistributionService from "services/SoilAnalysisInterpretationRegistrationService";
import { SnackbarContext } from "context/snackBarContext";
import arrowLeft from "assets/arrowLeft.svg";
import RedSwitch from "utils/RedSwitch";
import Save from "assets/SaveNew.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import CustomDivider from "./CustomDivider";
import _DraggableIcon_ from "assets/_DragabbleIcon_.svg";
import { MultiSelectDropdown } from "./CustomMultiSelect";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";

export default function AddSoilAnalysisInterpretationRegistration() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    createdDateTimeUTC,
    createdByUser,
    modifiedDateTimeUTC,
    modifiedByUser,
    iprId,
    iprInterpretationName,
    geneticTypes,
    sapParameterRuleName,
    sapAnalysisRuleId,
    sapParameterSubRuleName,
    sapAnalysisSubRuleId,
    soilAnalysisInterpretationRuleRequestDtoList,
    iprStatus,
  } = location?.state?.row || {};

  const { edit } = location.state || {};
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [options, setOptions] = useState({
    geneticTypeOptions: [],
    soilAnalysisParameterOptions: [],
    operatorOptions: ["<", "<=", "=", ">=", ">"],
  });
  const [allGeneticTypes, setAllGeneticTypes] = useState([]);

  const [originalValues, setOriginalValues] = useState([]);
  const [values, setValues] = useState({
    createdDateTimeUTC: createdDateTimeUTC || "2024-12-18T14:39:10.457Z",
    createdByUser: createdByUser || "UI",
    modifiedDateTimeUTC: modifiedDateTimeUTC || "2024-12-18T14:39:10.457Z",
    modifiedByUser: modifiedByUser || "UI",
    iprId: iprId || "",
    iprInterpretationName: iprInterpretationName || "",
    geneticTypes: geneticTypes || [],
    sapParameterRuleName: sapParameterRuleName || "",
    sapAnalysisRuleId: sapAnalysisRuleId || "",
    sapParameterSubRuleName: sapParameterSubRuleName || "",
    sapAnalysisSubRuleId: sapAnalysisSubRuleId || "",
    soilAnalysisInterpretationRuleRequestDtoList:
      soilAnalysisInterpretationRuleRequestDtoList || [
        {
          createdDateTimeUTC: createdDateTimeUTC || "2024-12-18T14:39:10.457Z",
          createdByUser: createdByUser || "UI",
          modifiedDateTimeUTC:
            modifiedDateTimeUTC || "2024-12-18T14:39:10.457Z",
          modifiedByUser: modifiedByUser || "UI",
          operator1: "",
          value1: "",
          operator2: "",
          value2: "",
          interpretationResult: "",
          isDelete: false,
          soilAnalysisInterpretationSubRules: [
            {
              createdDateTimeUTC:
                createdDateTimeUTC || "2024-12-18T14:39:10.457Z",
              createdByUser: createdByUser || "UI",
              modifiedDateTimeUTC:
                modifiedDateTimeUTC || "2024-12-18T14:39:10.457Z",
              modifiedByUser: modifiedByUser || "UI",
              operator1: "",
              value1: "",
              operator2: "",
              value2: "",
              interpretationResult: "",
              isDelete: false,
            },
          ],
        },
      ],
    iprStatus: iprStatus === undefined ? "Inactive" : iprStatus,
  });
  const [deletedSubRules, setDeletedSubRules] = useState([]);
  const [deletedRules, setDeletedRules] = useState(0);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState({
    rule: null,
    index: "",
    subRuleIndex: "",
  });
  const [openDelete, setOpenDelete] = useState(false);

  const { t } = useTranslation();

  const handleOpenModal = () => {
    edit === false
      ? navigate("/soilAnalysisInterpretationRegistration")
      : setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    setDeletedRules(
      values.soilAnalysisInterpretationRuleRequestDtoList.filter(
        (rule) => rule.isDelete
      ).length
    );

    const subRulesCount =
      values.soilAnalysisInterpretationRuleRequestDtoList.map(
        (rule) =>
          rule.soilAnalysisInterpretationSubRules.filter(
            (subRule) => subRule.isDelete
          ).length
      );

    setDeletedSubRules(subRulesCount);
  }, [values]);

  const fetchGeneticTypes = () => {
    SoilAnalysisInterpretationDistributionService.getGeneticTypes()
      .then((res) => {
        const geneticTypes =
          res?.data?.response?.map((geneticType) => ({
            gtGeneticDescription: geneticType.gtGeneticDescription,
            gtGeneticTypeId: geneticType.gtGeneticTypeId,
            isDelete: false,
          })) || [];
        setOptions((prev) => ({
          ...prev,
          geneticTypeOptions: geneticTypes,
        }));
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const handleCancelConfirm = () => {
    setOpen(false);
    navigate("/soilAnalysisInterpretationRegistration");
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? (checked ? "Active" : "Inactive") : value,
    }));

    if (name === "sapParameterRuleName") {
      const sapParameterRuleId = options.soilAnalysisParameterOptions.find(
        (ele) => ele.parameterName === value
      );
      setValues((prevValues) => ({
        ...prevValues,
        sapAnalysisRuleId: sapParameterRuleId
          ? sapParameterRuleId.parameterId
          : "",
      }));
    }

    if (name === "sapParameterSubRuleName") {
      const sapParameterRuleId = options.soilAnalysisParameterOptions.find(
        (ele) => ele.parameterName === value
      );
      setValues((prevValues) => ({
        ...prevValues,
        sapAnalysisSubRuleId: sapParameterRuleId
          ? sapParameterRuleId.parameterId
          : "",
      }));
    }
  };

  const handleRuleChange = (event, index) => {
    const { name, value, type, checked } = event.target;

    setValues((prevValues) => ({
      ...prevValues,
      soilAnalysisInterpretationRuleRequestDtoList:
        prevValues.soilAnalysisInterpretationRuleRequestDtoList.map((rule, i) =>
          i === index
            ? {
                ...rule,
                [name]: value,
              }
            : rule
        ),
    }));
  };

  const handleRuleClear = (name, index) => {
    setValues((prevValues) => ({
      ...prevValues,
      soilAnalysisInterpretationRuleRequestDtoList:
        prevValues.soilAnalysisInterpretationRuleRequestDtoList.map((rule, i) =>
          i === index
            ? {
                ...rule,
                [name]: "",
              }
            : rule
        ),
    }));
  };

  const handleSubRuleChange = (event, index, subRuleIndex) => {
    const { name, value, type, checked } = event.target;

    setValues((prevValues) => ({
      ...prevValues,
      soilAnalysisInterpretationRuleRequestDtoList:
        prevValues.soilAnalysisInterpretationRuleRequestDtoList.map((rule, i) =>
          i === index
            ? {
                ...rule,
                soilAnalysisInterpretationSubRules:
                  rule.soilAnalysisInterpretationSubRules.map(
                    (subRule, subIndex) =>
                      subIndex === subRuleIndex
                        ? {
                            ...subRule,
                            [name]: value,
                          }
                        : subRule // Exclude the sub-rule at subRuleIndex
                  ),
              }
            : rule
        ),
    }));
  };

  const handleSubRuleClear = (name, index, subRuleIndex) => {
    setValues((prevValues) => ({
      ...prevValues,
      soilAnalysisInterpretationRuleRequestDtoList:
        prevValues.soilAnalysisInterpretationRuleRequestDtoList.map((rule, i) =>
          i === index
            ? {
                ...rule,
                soilAnalysisInterpretationSubRules:
                  rule.soilAnalysisInterpretationSubRules.map(
                    (subRule, subIndex) =>
                      subIndex === subRuleIndex
                        ? {
                            ...subRule,
                            [name]: "",
                          }
                        : subRule // Exclude the sub-rule at subRuleIndex
                  ),
              }
            : rule
        ),
    }));
  };

  const updateGeneticTypes = (geneticTypes, allGeneticTypes) => {
    // Create a Set of `gtGeneticTypeId` from the current geneticTypes
    const geneticTypeIdsSet = new Set(
      geneticTypes.map((item) => item.gtGeneticTypeId)
    );

    // Find objects in `allGeneticTypes` that are not in `geneticTypes`
    const missingGeneticTypes = allGeneticTypes.filter(
      (item) => !geneticTypeIdsSet.has(item.gtGeneticTypeId)
    );

    // Mark `isDelete` as true for the missing objects
    const updatedMissingGeneticTypes = missingGeneticTypes.map((item) => ({
      ...item,
      isDelete: true,
    }));

    // Combine original `geneticTypes` with the updated missing objects
    const updatedGeneticTypes = [
      ...geneticTypes,
      ...updatedMissingGeneticTypes,
    ];

    return updatedGeneticTypes;
  };

  const handleDeleteConfirm = async () => {
    deleteItem.rule === true
      ? handleRuleDelete(deleteItem.index)
      : handleSubRuleDelete(deleteItem.index, deleteItem.subRuleIndex);
    setOpenDelete(false);
  };

  const handleClose = () => {
    setOpenDelete(false); // Close the modal without deleting
  };

  useEffect(() => {
    fetchSoilAnalysisParameters();
    fetchGeneticTypes();
    setOriginalValues(values);
    setAllGeneticTypes(values.geneticTypes);
  }, []);

  const fetchSoilAnalysisParameters = () => {
    SoilAnalysisInterpretationDistributionService.getSoilAnalysisParameters()
      .then((res) => {
        const parameters =
          res?.data?.response?.soilAnalysisParameters.map((params) => ({
            parameterName: params.sapParameterName,
            parameterId: params.sapAnalysisId,
          })) || [];
        setOptions((prevOptions) => ({
          ...prevOptions, // Spread previous options to retain other values
          soilAnalysisParameterOptions: parameters,
        }));
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const validateInputs = (schemaValidationErrors, values) => {
    const specialCharPattern = /^[a-zA-Z0-9 ]+$/;

    const validateField = (fieldName, fieldValue) => {
      const trimmedValue =
        typeof fieldValue === "string" ? fieldValue.trim() : null;

      if (
        trimmedValue === null ||
        (trimmedValue === "" &&
          (fieldName !== "iprInterpretationName" ||
            (trimmedValue === "" && fieldValue === "")))
      ) {
        schemaValidationErrors[fieldName] = "This field is required.";
      } else if (fieldName === "iprInterpretationName" && trimmedValue === "") {
        schemaValidationErrors[
          fieldName
        ] = `Interpretation Name must not contain leading or trailing spaces`;
      } else if (
        fieldName === "iprInterpretationName" &&
        trimmedValue.length > 50
      ) {
        schemaValidationErrors[fieldName] =
          "The Character limit cannot exceed more than 50 in Interpretation Name";
      } else if (
        typeof trimmedValue === "string" &&
        !specialCharPattern.test(trimmedValue)
      ) {
        schemaValidationErrors[fieldName] = "No special characters allowed.";
      } else if (
        typeof trimmedValue === "string" &&
        trimmedValue !== fieldValue
      ) {
        schemaValidationErrors[
          fieldName
        ] = `${fieldName} must not contain leading or trailing spaces`;
      } else {
        // Clear error if the input is valid
        delete schemaValidationErrors[fieldName];
      }
    };

    // Iterate through the `values` object
    Object.keys(values).forEach((key) => {
      const value = values[key];

      // Validate geneticTypes list
      if (
        key === "geneticTypes" &&
        Array.isArray(value) &&
        value.length === 0
      ) {
        schemaValidationErrors[key] = "This field is required.";
      }

      // Validate fields that are strings, ignoring specific keys
      const ignoredKeys = [
        "iprId",
        "createdDateTimeUTC",
        "modifiedDateTimeUTC",
        "sapAnalysisRuleId",
        "sapAnalysisSubRuleId",
      ];
      if (typeof value === "string" && !ignoredKeys.includes(key)) {
        validateField(key, value);
      }
    });

    return schemaValidationErrors;
  };

  const handleAddNewRule = () => {
    setValues((prevValues) => ({
      ...prevValues,
      soilAnalysisInterpretationRuleRequestDtoList: [
        ...(prevValues.soilAnalysisInterpretationRuleRequestDtoList || []),
        {
          createdDateTimeUTC: createdDateTimeUTC || "2024-12-18T14:39:10.457Z",
          createdByUser: createdByUser || "UI",
          modifiedDateTimeUTC:
            modifiedDateTimeUTC || "2024-12-18T14:39:10.457Z",
          modifiedByUser: modifiedByUser || "UI",
          operator1: "",
          value1: "",
          operator2: "",
          value2: "",
          interpretationResult: "",
          isDelete: false,
          soilAnalysisInterpretationSubRules: [
            {
              createdDateTimeUTC:
                createdDateTimeUTC || "2024-12-18T14:39:10.457Z",
              createdByUser: createdByUser || "UI",
              modifiedDateTimeUTC:
                modifiedDateTimeUTC || "2024-12-18T14:39:10.457Z",
              modifiedByUser: modifiedByUser || "UI",
              operator1: "",
              value1: "",
              operator2: "",
              value2: "",
              interpretationResult: "",
              isDelete: false,
            },
          ],
        },
      ],
    }));
  };

  const handleRuleDelete = (index) => {
    setValues((prevValues) => {
      const updatedRules =
        prevValues.soilAnalysisInterpretationRuleRequestDtoList.map(
          (rule, i) => {
            if (i === index && rule.iprRuleId) {
              // Mark the rule as deleted
              const updatedSubRules = rule.soilAnalysisInterpretationSubRules
                .map(
                  (subRule) =>
                    subRule.ipSubRuleId
                      ? { ...subRule, isDelete: true } // Mark sub-rule as deleted if it has `ipSubRuleId`
                      : null // Mark for removal
                )
                .filter(Boolean); // Remove null entries

              return {
                ...rule,
                isDelete: true,
                soilAnalysisInterpretationSubRules: updatedSubRules,
              };
            }
            return rule; // Return the rule as-is
          }
        );

      // Filter out rules that don't have `iprRuleId` and are to be deleted
      const filteredRules = updatedRules.filter(
        (rule, i) => i !== index || rule.iprRuleId
      );

      return {
        ...prevValues,
        soilAnalysisInterpretationRuleRequestDtoList: filteredRules,
      };
    });
  };

  const handleAddSubRule = (index) => {
    setValues((prevValues) => ({
      ...prevValues,
      soilAnalysisInterpretationRuleRequestDtoList:
        prevValues.soilAnalysisInterpretationRuleRequestDtoList.map((rule, i) =>
          i === index
            ? {
                ...rule,
                soilAnalysisInterpretationSubRules: [
                  ...(rule.soilAnalysisInterpretationSubRules || []),
                  {
                    createdDateTimeUTC:
                      createdDateTimeUTC || "2024-12-18T14:39:10.457Z",
                    createdByUser: createdByUser || "UI",
                    modifiedDateTimeUTC:
                      modifiedDateTimeUTC || "2024-12-18T14:39:10.457Z",
                    modifiedByUser: modifiedByUser || "UI",
                    operator1: "",
                    value1: "",
                    operator2: "",
                    value2: "",
                    interpretationResult: "",
                    isDelete: false,
                  },
                ],
              }
            : rule
        ),
    }));
  };

  const handleSubRuleDelete = (index, subRuleIndex) => {
    setValues((prevValues) => {
      const updatedRules =
        prevValues.soilAnalysisInterpretationRuleRequestDtoList.map(
          (rule, i) => {
            if (i === index) {
              const updatedSubRules =
                rule.soilAnalysisInterpretationSubRules.map(
                  (subRule, subIndex) => {
                    if (subIndex === subRuleIndex && subRule.ipSubRuleId) {
                      // If sub-rule has `ipSubRuleId`, mark it as deleted
                      return { ...subRule, isDelete: true };
                    }
                    return subRule; // Return the sub-rule as-is
                  }
                );

              // Filter out sub-rules that don't have `ipSubRuleId` and are being deleted
              const filteredSubRules = updatedSubRules.filter(
                (_, subIndex) =>
                  subIndex !== subRuleIndex ||
                  updatedSubRules[subIndex]?.ipSubRuleId
              );

              return {
                ...rule,
                soilAnalysisInterpretationSubRules: filteredSubRules,
              };
            }
            return rule; // Return the rule as-is
          }
        );

      return {
        ...prevValues,
        soilAnalysisInterpretationRuleRequestDtoList: updatedRules,
      };
    });
  };

  const submitSoilAnalysisData = async (payload, isUpdate = false) => {
    try {
      const response = isUpdate
        ? await SoilAnalysisInterpretationDistributionService.putSoilAnalysisInterpretationData(
            payload
          )
        : await SoilAnalysisInterpretationDistributionService.postSoilAnalysisInterpretationData(
            payload
          );

      if (response.status === 201) {
        setSeverity("success");
        setMessage(
          isUpdate
            ? "RECORD UPDATED SUCCESSFULLY!"
            : "RECORD SAVED SUCCESSFULLY!"
        );
        setOpenAlert(true);
        navigate("/soilAnalysisInterpretationRegistration");
      }
    } catch (error) {
      if (error.response?.status === 409) {
        setSeverity("error");
        setMessage(error.response.data.message);
      } else if (error.response?.status === 500) {
        setSeverity("error");
        setMessage(
          `The ${values.iprInterpretationName} already exists. Please provide a unique Interpretation Name`
        );
      } else {
        setSeverity("error");
        setMessage(isUpdate ? "Update failed" : "Submission Failed");
      }
      setOpenAlert(true);
    }
  };

  const handleSubmission = async (event, isUpdate = false) => {
    event.preventDefault();
    const schemaValidationErrors = {};
    const trimmedValues = validateInputs(schemaValidationErrors, values);
    if (Object.keys(schemaValidationErrors).length > 0) {
      setErrors(schemaValidationErrors);
      return;
    }

    // Destructure iprId first to exclude it from the spread
    const { iprId, geneticTypes, ...restValues } = values;
    const updatedGeneticTypes = updateGeneticTypes(
      geneticTypes,
      allGeneticTypes
    );

    const payload = {
      ...restValues,
      geneticTypes: updatedGeneticTypes,
      soilAnalysisInterpretationRuleRequestDtoList:
        values?.soilAnalysisInterpretationRuleRequestDtoList?.map((rule) => {
          // Destructure iprRuleId from each rule
          const { iprRuleId, interpretationResult, ...restRule } = rule;

          return {
            ...restRule,
            ...(isUpdate && { iprRuleId }),
            soilAnalysisInterpretationSubRules:
              rule?.soilAnalysisInterpretationSubRules?.map((subRule) => {
                // Destructure ipSubRuleId from each subRule
                const { ipSubRuleId, ...restSubRule } = subRule;

                return {
                  ...restSubRule,
                  ...(isUpdate && { ipSubRuleId }),
                };
              }),
          };
        }),
      modifiedDateTimeUTC: new Date().toISOString(),
      ...(isUpdate && { iprId }),
    };

    await submitSoilAnalysisData(payload, isUpdate);
    delete setErrors([]);
  };

  const handleSubmit = (event) => handleSubmission(event, false);
  const handleUpdate = (event) => handleSubmission(event, true);

  return (
    <Layout
      title={t("soilAnalysisInterpretationRegistration.pageTitle2")}
      background="#ffffff"
    >
      <Box
        sx={{
          marginTop: "2rem",
          minHeight: "10%",
          flexGrow: 1,
          mt: "20px",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box
            sx={{
              padding: "0.5rem",
              paddingBottom: 0,
              bgcolor: "#EFEFEF",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <ArrowDropDownIcon sx={{ marginRight: 1 }} />
              <Typography sx={{ fontWeight: "600", color: "#666666" }}>
                {t("form")}
              </Typography>
            </Box>
            <Grid container columnSpacing={3}>
              {/* Interpretation Name */}
              <Grid item xs={5}>
                <TextField
                  name="iprInterpretationName"
                  label={t(
                    "soilAnalysisInterpretationRegistration.interpretationName"
                  )}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      ...(edit === false && {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #0000003B",
                        },
                      }),
                    },
                  }}
                  value={values?.iprInterpretationName}
                  onChange={handleChange}
                  variant="outlined"
                  disabled={edit === false}
                  fullWidth
                  helperText={
                    errors.iprInterpretationName
                      ? errors.iprInterpretationName
                      : " "
                  }
                  error={!!errors.iprInterpretationName}
                  required
                />
              </Grid>

              {/* Gentic Types Dropdown */}
              <Grid item xs={3}>
                <MultiSelectDropdown
                  options={options.geneticTypeOptions}
                  selectedOptions={values?.geneticTypes} // Selected values
                  setSelectedOptions={(newSelectedOptions) => {
                    setValues((prevValues) => ({
                      ...prevValues,
                      geneticTypes: newSelectedOptions.map((ele) => ({
                        ...ele,
                      })),
                    }));
                  }}
                  label="Genetic Type"
                  placeholder="Select an option"
                  chipColor="secondary"
                  helperText={errors.geneticTypes ? errors.geneticTypes : " "} // Provide helper text
                  error={!!errors.geneticTypes} // Pass error state
                  disabled={edit === false}
                />
              </Grid>

              {/* Status Switch */}
              <Grid item xs={2}>
                <FormControl fullWidthk>
                  <FormLabel
                    sx={{
                      color: "#3B454F !important",
                      mb: 2,
                      ml: 0.5,
                      fontWeight: "600",
                    }}
                  >
                    {t("status")}
                  </FormLabel>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ ml: 4, mr: 3 }}>
                      {t("Inactive")}
                    </Typography>
                    <FormControlLabel
                      control={
                        <RedSwitch
                          checked={
                            values.iprStatus === "Inactive" ||
                            values.iprStatus === null ||
                            values.iprStatus === undefined
                              ? false
                              : true
                          }
                          onChange={handleChange}
                          name="iprStatus"
                          disabled={edit === false}
                        />
                      }
                      label={
                        <Typography paddingLeft="20px">
                          {t("Active")}
                        </Typography>
                      }
                      labelPlacement="end"
                    />
                  </Box>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          {/* Parameters */}
          <CustomDivider
            title={t("soilAnalysisInterpretationRegistration.parameters")}
          />
          <Box
            sx={{
              display: "inline-flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                marginTop: "1rem",
                padding: "0.5rem",
                paddingBottom: 0,
                bgcolor: "#EFEFEF",
                borderRadius: "10px",
                width: "48.6%",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <ArrowDropDownIcon sx={{ marginRight: 1 }} />
                <Typography sx={{ fontWeight: "600", color: "#666666" }}>
                  {t("soilAnalysisInterpretationRegistration.rule")}
                </Typography>
              </Box>
              <Grid container marginTop="-5px">
                <h1
                  style={{
                    fontWeight: 700,
                    lineHeight: "40px",
                    color: "#5A646E",
                    margin: "15px",
                  }}
                >
                  1
                </h1>
                <Grid item xs={10.5}>
                  <TextField
                    select
                    name="sapParameterRuleName"
                    label={t(
                      "soilAnalysisInterpretationRegistration.parameterName"
                    )}
                    value={values.sapParameterRuleName || "Select an Option"}
                    onChange={handleChange}
                    fullWidth
                    helperText={
                      errors.sapParameterRuleName
                        ? errors.sapParameterRuleName
                        : " "
                    }
                    disabled={edit === false}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        ...(edit === false && {
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #0000003B",
                          },
                        }),
                      },
                    }}
                    error={!!errors.sapParameterRuleName}
                    InputProps={{
                      endAdornment: values.sapParameterRuleName &&
                        edit !== false && (
                          <InputAdornment
                            position="end"
                            sx={{ paddingRight: "10px" }}
                          >
                            <IconButton
                              size="small"
                              onClick={() => {
                                setValues({
                                  ...values,
                                  sapParameterRuleName: "",
                                });
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                    }}
                  >
                    <MenuItem value="Select an Option" sx={{ display: "none" }}>
                      {t("selectoption")}
                    </MenuItem>
                    {options.soilAnalysisParameterOptions
                      .filter(
                        (ele) =>
                          ele.parameterName !== values?.sapParameterSubRuleName
                      )
                      .map((parameter, index) => (
                        <MenuItem key={index} value={parameter.parameterName}>
                          {parameter.parameterName}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                marginTop: "1rem",
                padding: "0.5rem",
                paddingBottom: 0,
                bgcolor: "#EFEFEF",
                borderRadius: "10px",
                width: "48.6%",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <ArrowDropDownIcon sx={{ marginRight: 1 }} />
                <Typography sx={{ fontWeight: "600", color: "#666666" }}>
                  {t("soilAnalysisInterpretationRegistration.subrule")}
                </Typography>
              </Box>
              <Grid container marginTop="-5px">
                <h1
                  style={{
                    fontWeight: 700,
                    lineHeight: "40px",
                    color: "#5A646E",
                    margin: "15px",
                  }}
                >
                  2
                </h1>
                {/* Sub Rule Dropdown */}
                <Grid item xs={10.5}>
                  <TextField
                    select
                    name="sapParameterSubRuleName"
                    label={t(
                      "soilAnalysisInterpretationRegistration.parameterName"
                    )}
                    value={values.sapParameterSubRuleName || "Select an Option"}
                    onChange={handleChange}
                    disabled={edit === false}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        ...(edit === false && {
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #0000003B",
                          },
                        }),
                      },
                    }}
                    fullWidth
                    helperText={
                      errors.sapParameterSubRuleName
                        ? errors.sapParameterSubRuleName
                        : " "
                    }
                    error={!!errors.sapParameterSubRuleName}
                    InputProps={{
                      endAdornment: values.sapParameterSubRuleName &&
                        edit !== false && (
                          <InputAdornment
                            position="end"
                            sx={{ paddingRight: "10px" }}
                          >
                            <IconButton
                              size="small"
                              onClick={() => {
                                setValues({
                                  ...values,
                                  sapParameterSubRuleName: "",
                                });
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                    }}
                  >
                    <MenuItem value="Select an Option" sx={{ display: "none" }}>
                      {t("selectoption")}
                    </MenuItem>
                    {options.soilAnalysisParameterOptions
                      .filter(
                        (ele) =>
                          ele.parameterName !== values?.sapParameterRuleName
                      )
                      .map((param, index) => (
                        <MenuItem key={index} value={param.parameterName}>
                          {param.parameterName}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
            </Box>
          </Box>

          {values?.sapParameterRuleName && (
            <Box>
              {/* Interpretation Rules */}
              <CustomDivider
                title={t(
                  "soilAnalysisInterpretationRegistration.interpretationRules"
                )}
                CustomButton={
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddNewRule}
                    sx={{
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      textTransform: "none", // Prevents uppercase transformation
                      lineHeight: "20px",
                      backgroundColor: "#004F9F",
                      height: "46px",
                      width: "max-content",
                      marginBottom: "-5px",
                      display: edit === false && "none",
                    }}
                  >
                    {t("addNew").toUpperCase()}
                  </Button>
                }
              />
              {values?.soilAnalysisInterpretationRuleRequestDtoList.map(
                (rules, index) => (
                  <Box
                    display={
                      values.soilAnalysisInterpretationRuleRequestDtoList[index]
                        .isDelete === true && "none"
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        marginTop: "1rem",
                        padding: "0.5rem",
                        paddingBottom: 0,
                        bgcolor: "#EFEFEF",
                        borderRadius: "10px",
                      }}
                    >
                      <img
                        className="btn edit-btn"
                        src={_DraggableIcon_}
                        alt="Draggable Icon"
                        style={{
                          height: "30px",
                          width: "30px",
                          alignSelf: "center",
                        }}
                      />
                      <Box width="100%">
                        <Box sx={{ display: "flex" }}>
                          <ArrowDropDownIcon sx={{ marginRight: 1 }} />
                          <Typography>
                            {t("soilAnalysisInterpretationRegistration.rule")}
                          </Typography>
                        </Box>
                        <Grid container columnSpacing={2} marginTop="-15px">
                          <label
                            style={{
                              fontWeight: 800,
                              lineHeight: "40px",
                              color: "#5A646E",
                              margin: "15px",
                              marginLeft: "30px",
                              fontSize: "20px",
                              marginRight: "5%",
                            }}
                          >
                            {values?.sapParameterRuleName}
                          </label>
                          <Grid item width="135px">
                            <TextField
                              select
                              name="operator1"
                              label={t(
                                "soilAnalysisInterpretationRegistration.operator"
                              )}
                              value={
                                values
                                  .soilAnalysisInterpretationRuleRequestDtoList[
                                  index
                                ].operator1
                              }
                              onChange={(event) =>
                                handleRuleChange(event, index)
                              }
                              disabled={edit === false}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  ...(edit === false && {
                                    "&:hover .MuiOutlinedInput-notchedOutline":
                                      {
                                        border: "1px solid #0000003B",
                                      },
                                  }),
                                },
                              }}
                              fullWidth
                              InputProps={{
                                endAdornment: values
                                  .soilAnalysisInterpretationRuleRequestDtoList[
                                  index
                                ].operator1 &&
                                  edit !== false && (
                                    <InputAdornment
                                      position="end"
                                      sx={{ paddingRight: "10px" }}
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          handleRuleClear("operator1", index);
                                        }}
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                              }}
                            >
                              {options.operatorOptions.map(
                                (operator, index) => (
                                  <MenuItem key={index} value={operator}>
                                    {operator}
                                  </MenuItem>
                                )
                              )}
                            </TextField>
                          </Grid>
                          <Grid item width="290px">
                            <TextField
                              name="value1"
                              label={t(
                                "soilAnalysisInterpretationRegistration.value"
                              )}
                              value={
                                values
                                  ?.soilAnalysisInterpretationRuleRequestDtoList[
                                  index
                                ].value1
                              }
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  ...(edit === false && {
                                    "&:hover .MuiOutlinedInput-notchedOutline":
                                      {
                                        border: "1px solid #0000003B",
                                      },
                                  }),
                                },
                              }}
                              onChange={(event) => {
                                const value = event.target.value;
                                if (
                                  /^[a-zA-Z0-9]*$/.test(value) ||
                                  value === ""
                                ) {
                                  handleRuleChange(event, index);
                                }
                              }}
                              variant="outlined"
                              disabled={edit === false}
                              fullWidth
                            />
                          </Grid>
                          <Grid item width="135px">
                            <TextField
                              select
                              name="operator2"
                              label={t(
                                "soilAnalysisInterpretationRegistration.operator"
                              )}
                              value={
                                values
                                  .soilAnalysisInterpretationRuleRequestDtoList[
                                  index
                                ].operator2
                              }
                              onChange={(event) =>
                                handleRuleChange(event, index)
                              }
                              fullWidth
                              disabled={edit === false}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  ...(edit === false && {
                                    "&:hover .MuiOutlinedInput-notchedOutline":
                                      {
                                        border: "1px solid #0000003B",
                                      },
                                  }),
                                },
                              }}
                              InputProps={{
                                endAdornment: values
                                  .soilAnalysisInterpretationRuleRequestDtoList[
                                  index
                                ].operator2 &&
                                  edit !== false && (
                                    <InputAdornment
                                      position="end"
                                      sx={{ paddingRight: "10px" }}
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          handleRuleClear("operator2", index);
                                        }}
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                              }}
                            >
                              {options.operatorOptions.map(
                                (operator, index) => (
                                  <MenuItem key={index} value={operator}>
                                    {operator}
                                  </MenuItem>
                                )
                              )}
                            </TextField>
                          </Grid>
                          <Grid item width="290px">
                            <TextField
                              name="value2"
                              label={t(
                                "soilAnalysisInterpretationRegistration.value"
                              )}
                              value={
                                values
                                  ?.soilAnalysisInterpretationRuleRequestDtoList[
                                  index
                                ].value2
                              }
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  ...(edit === false && {
                                    "&:hover .MuiOutlinedInput-notchedOutline":
                                      {
                                        border: "1px solid #0000003B",
                                      },
                                  }),
                                },
                              }}
                              onChange={(event) => {
                                const value = event.target.value;
                                if (
                                  /^[a-zA-Z0-9]*$/.test(value) ||
                                  value === ""
                                ) {
                                  handleRuleChange(event, index);
                                }
                              }}
                              variant="outlined"
                              disabled={edit === false}
                              fullWidth
                            />
                          </Grid>
                          {values?.soilAnalysisInterpretationRuleRequestDtoList[
                            index
                          ].soilAnalysisInterpretationSubRules.length === 0 && (
                            <Grid sx={{ display: "inline-flex" }}>
                              <label
                                style={{
                                  fontWeight: 500,
                                  lineHeight: "40px",
                                  color: "#5A646E",
                                  margin: "15px",
                                  marginRight: "0px",
                                  marginLeft: "20px",
                                  fontSize: "14px",
                                }}
                              >
                                INTERPRETATION RESULT
                              </label>
                              <Grid item width="240px">
                                <TextField
                                  name="interpretationResult"
                                  value={
                                    values
                                      ?.soilAnalysisInterpretationRuleRequestDtoList[
                                      index
                                    ].interpretationResult
                                  }
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      ...(edit === false && {
                                        "&:hover .MuiOutlinedInput-notchedOutline":
                                          {
                                            border: "1px solid #0000003B",
                                          },
                                      }),
                                    },
                                  }}
                                  onChange={handleChange}
                                  variant="outlined"
                                  disabled={edit === false}
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                      <Tooltip title="DELETE" arrow placement="top">
                        <img
                          className="btn delete-btn"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent the row click from firing
                            setOpenDelete(true);
                            setDeleteItem({
                              rule: true,
                              index: index,
                              subRuleIndex: "",
                            });
                            // handleRuleDelete(index);
                          }}
                          src={_ButtonDelete_}
                          disabled={edit === false}
                          alt="Delete"
                          style={{
                            display:
                              (values
                                .soilAnalysisInterpretationRuleRequestDtoList
                                .length <=
                                deletedRules + 1 ||
                                edit === false) &&
                              "none",
                          }}
                        />
                      </Tooltip>
                    </Box>
                    {values.sapParameterSubRuleName && (
                      <Box>
                        {values?.soilAnalysisInterpretationRuleRequestDtoList[
                          index
                        ]?.soilAnalysisInterpretationSubRules.map(
                          (subrules, subRuleIndex) => (
                            <Box
                              display={
                                values
                                  ?.soilAnalysisInterpretationRuleRequestDtoList[
                                  index
                                ]?.soilAnalysisInterpretationSubRules[
                                  subRuleIndex
                                ].isDelete === true && "none"
                              }
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  marginTop: "1rem",
                                  padding: "0.5rem",
                                  paddingBottom: 0,
                                  bgcolor: "#F8F8F8",
                                  borderRadius: "10px",
                                  width: "auto",
                                  ml: "20px",
                                }}
                              >
                                <img
                                  className="btn edit-btn"
                                  src={_DraggableIcon_}
                                  alt="Draggable Icon"
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    alignSelf: "center",
                                  }}
                                />
                                <Box width="100%">
                                  <Box sx={{ display: "flex" }}>
                                    <ArrowDropDownIcon
                                      sx={{ marginRight: 1 }}
                                    />
                                    <Typography>
                                      {t(
                                        "soilAnalysisInterpretationRegistration.subrule"
                                      )}
                                    </Typography>
                                  </Box>
                                  <Grid
                                    container
                                    columnSpacing={2}
                                    marginTop="-15px"
                                  >
                                    <label
                                      style={{
                                        fontWeight: 800,
                                        lineHeight: "40px",
                                        color: "#5A646E",
                                        margin: "15px",
                                        marginLeft: "30px",
                                        fontSize: "24px",
                                        marginRight: "25px",
                                      }}
                                    >
                                      {values?.sapParameterSubRuleName}
                                    </label>
                                    <Grid item width="135px">
                                      <TextField
                                        select
                                        name="operator1"
                                        label={t(
                                          "soilAnalysisInterpretationRegistration.operator"
                                        )}
                                        value={
                                          values
                                            ?.soilAnalysisInterpretationRuleRequestDtoList[
                                            index
                                          ].soilAnalysisInterpretationSubRules[
                                            subRuleIndex
                                          ].operator1
                                        }
                                        disabled={edit === false}
                                        sx={{
                                          "& .MuiOutlinedInput-root": {
                                            ...(edit === false && {
                                              "&:hover .MuiOutlinedInput-notchedOutline":
                                                {
                                                  border: "1px solid #0000003B",
                                                },
                                            }),
                                          },
                                        }}
                                        onChange={(event) =>
                                          handleSubRuleChange(
                                            event,
                                            index,
                                            subRuleIndex
                                          )
                                        }
                                        fullWidth
                                        InputProps={{
                                          endAdornment: values
                                            ?.soilAnalysisInterpretationRuleRequestDtoList[
                                            index
                                          ].soilAnalysisInterpretationSubRules[
                                            subRuleIndex
                                          ].operator1 &&
                                            edit !== false && (
                                              <InputAdornment
                                                position="end"
                                                sx={{
                                                  paddingRight: "10px",
                                                }}
                                              >
                                                <IconButton
                                                  size="small"
                                                  onClick={() =>
                                                    handleSubRuleClear(
                                                      "operator1",
                                                      index,
                                                      subRuleIndex
                                                    )
                                                  }
                                                >
                                                  <CloseIcon />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                        }}
                                      >
                                        {options.operatorOptions.map(
                                          (operator, index) => (
                                            <MenuItem
                                              key={index}
                                              value={operator}
                                            >
                                              {operator}
                                            </MenuItem>
                                          )
                                        )}
                                      </TextField>
                                    </Grid>
                                    <Grid
                                      item
                                      sx={{
                                        width: "290px",
                                        "@media (max-width: 1424px)": {
                                          // Adjust width and font size when zoomed in or on smaller screens
                                          width: "135px", // Halve the width
                                        },
                                      }}
                                    >
                                      <TextField
                                        name="value1"
                                        label={t(
                                          "soilAnalysisInterpretationRegistration.value"
                                        )}
                                        value={
                                          values
                                            ?.soilAnalysisInterpretationRuleRequestDtoList[
                                            index
                                          ].soilAnalysisInterpretationSubRules[
                                            subRuleIndex
                                          ].value1
                                        }
                                        onChange={(event) => {
                                          const value = event.target.value;
                                          if (
                                            /^[a-zA-Z0-9]*$/.test(value) ||
                                            value === ""
                                          ) {
                                            handleSubRuleChange(
                                              event,
                                              index,
                                              subRuleIndex
                                            );
                                          }
                                        }}
                                        sx={{
                                          "& .MuiOutlinedInput-root": {
                                            ...(edit === false && {
                                              "&:hover .MuiOutlinedInput-notchedOutline":
                                                {
                                                  border: "1px solid #0000003B",
                                                },
                                            }),
                                          },
                                        }}
                                        variant="outlined"
                                        disabled={edit === false}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item width="135px">
                                      <TextField
                                        select
                                        name="operator2"
                                        label={t(
                                          "soilAnalysisInterpretationRegistration.operator"
                                        )}
                                        value={
                                          values
                                            ?.soilAnalysisInterpretationRuleRequestDtoList[
                                            index
                                          ].soilAnalysisInterpretationSubRules[
                                            subRuleIndex
                                          ].operator2
                                        }
                                        disabled={edit === false}
                                        sx={{
                                          "& .MuiOutlinedInput-root": {
                                            ...(edit === false && {
                                              "&:hover .MuiOutlinedInput-notchedOutline":
                                                {
                                                  border: "1px solid #0000003B",
                                                },
                                            }),
                                          },
                                        }}
                                        onChange={(event) =>
                                          handleSubRuleChange(
                                            event,
                                            index,
                                            subRuleIndex
                                          )
                                        }
                                        fullWidth
                                        InputProps={{
                                          endAdornment: values
                                            ?.soilAnalysisInterpretationRuleRequestDtoList[
                                            index
                                          ].soilAnalysisInterpretationSubRules[
                                            subRuleIndex
                                          ].operator2 &&
                                            edit !== false && (
                                              <InputAdornment
                                                position="end"
                                                sx={{
                                                  paddingRight: "10px",
                                                }}
                                              >
                                                <IconButton
                                                  size="small"
                                                  onClick={() =>
                                                    handleSubRuleClear(
                                                      "operator2",
                                                      index,
                                                      subRuleIndex
                                                    )
                                                  }
                                                >
                                                  <CloseIcon />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                        }}
                                      >
                                        {options.operatorOptions.map(
                                          (operator, index) => (
                                            <MenuItem
                                              key={index}
                                              value={operator}
                                            >
                                              {operator}
                                            </MenuItem>
                                          )
                                        )}
                                      </TextField>
                                    </Grid>
                                    <Grid
                                      item
                                      sx={{
                                        width: "290px",
                                        "@media (max-width: 1424px)": {
                                          // Adjust width and font size when zoomed in or on smaller screens
                                          width: "135px", // Halve the width
                                        },
                                      }}
                                    >
                                      <TextField
                                        name="value2"
                                        label={t(
                                          "soilAnalysisInterpretationRegistration.value"
                                        )}
                                        value={
                                          values
                                            ?.soilAnalysisInterpretationRuleRequestDtoList[
                                            index
                                          ].soilAnalysisInterpretationSubRules[
                                            subRuleIndex
                                          ].value2
                                        }
                                        disabled={edit === false}
                                        sx={{
                                          "& .MuiOutlinedInput-root": {
                                            ...(edit === false && {
                                              "&:hover .MuiOutlinedInput-notchedOutline":
                                                {
                                                  border: "1px solid #0000003B",
                                                },
                                            }),
                                          },
                                        }}
                                        onChange={(event) => {
                                          const value = event.target.value;
                                          if (
                                            /^[a-zA-Z0-9]*$/.test(value) ||
                                            value === ""
                                          ) {
                                            handleSubRuleChange(
                                              event,
                                              index,
                                              subRuleIndex
                                            );
                                          }
                                        }}
                                        variant="outlined"
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid sx={{ display: "inline-flex" }}>
                                      <label
                                        style={{
                                          fontWeight: 500,
                                          lineHeight: "40px",
                                          color: "#5A646E",
                                          margin: "15px",
                                          marginRight: "0px",
                                          marginLeft: "20px",
                                          fontSize: "14px",
                                        }}
                                      >
                                        INTERPRETATION RESULT
                                      </label>
                                      <Grid item width="240px">
                                        <TextField
                                          name="interpretationResult"
                                          value={
                                            values
                                              ?.soilAnalysisInterpretationRuleRequestDtoList[
                                              index
                                            ]
                                              .soilAnalysisInterpretationSubRules[
                                              subRuleIndex
                                            ].interpretationResult
                                          }
                                          sx={{
                                            "& .MuiOutlinedInput-root": {
                                              ...(edit === false && {
                                                "&:hover .MuiOutlinedInput-notchedOutline":
                                                  {
                                                    border:
                                                      "1px solid #0000003B",
                                                  },
                                              }),
                                            },
                                          }}
                                          onChange={(event) =>
                                            handleSubRuleChange(
                                              event,
                                              index,
                                              subRuleIndex
                                            )
                                          }
                                          variant="outlined"
                                          disabled={edit === false}
                                          fullWidth
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Box>
                                <Tooltip title="DELETE" arrow placement="top">
                                  <img
                                    className="btn delete-btn"
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevent the row click from firing
                                      setOpenDelete(true);
                                      setDeleteItem({
                                        rule: false,
                                        index: index,
                                        subRuleIndex: subRuleIndex,
                                      });
                                      // handleSubRuleDelete(index, subRuleIndex);
                                    }}
                                    src={_ButtonDelete_}
                                    alt="Delete"
                                    style={{
                                      display:
                                        (values
                                          ?.soilAnalysisInterpretationRuleRequestDtoList[
                                          index
                                        ].soilAnalysisInterpretationSubRules
                                          .length <=
                                          deletedSubRules[index] + 1 ||
                                          edit === false) &&
                                        "none",
                                    }}
                                  />
                                </Tooltip>
                              </Box>
                            </Box>
                          )
                        )}
                        <Button
                          style={{
                            backgroundColor: "#737D86",
                            padding: "6px 16px",
                            marginTop: "7px",
                            marginLeft: "20px",
                            borderRadius: "4px",
                            fontSize: "12px",
                            display: edit === false && "none",
                          }}
                          variant="contained"
                          onClick={() => handleAddSubRule(index)}
                        >
                          ADD SUBRULE
                        </Button>
                      </Box>
                    )}
                  </Box>
                )
              )}
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              bottom: 0,
              marginTop: "35px",
              padding: "0.5rem",
            }}
          >
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: "6px 16px",
                borderRadius: "4px",
                left: "1rem",
                bottom: "1rem",
                marginBottom: "50px",
              }}
              variant="contained"
              onClick={handleOpenModal}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
            >
              {edit === false ? t("back") : t("cancel")}
            </Button>

            <Modal
              open={open}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  width: "585px",
                  margin: "auto",
                  mt: 25,
                  height: "200px",
                }}
              >
                <Box
                  sx={{
                    background: "#102A62",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                  }}
                >
                  <Typography
                    sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                  >
                    {t("cancelTitle")}
                  </Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, fontWeight: 400 }}
                  >
                    {t("cancelMessage")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    mb: 1,
                  }}
                >
                  <Button
                    onClick={handleCloseModal}
                    sx={{
                      background: "#737D86",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#737D86" },
                    }}
                  >
                    {t("no")}
                  </Button>
                  <Button
                    onClick={handleCancelConfirm}
                    sx={{
                      background: "#004F9F",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#004F9F" },
                    }}
                  >
                    {t("yes")}
                  </Button>
                </Box>
              </Box>
            </Modal>

            <Modal
              open={openDelete}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  width: "520px",
                  margin: "auto",
                  mt: 25,
                  height: "200px",
                }}
              >
                <Box
                  sx={{
                    background: "#102A62",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                  }}
                >
                  <Typography
                    sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                  >
                    {t("deleteConfirmation")}
                  </Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, fontWeight: 400 }}
                  >
                    {t("deleteConfirmationMessage")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    mb: 1,
                  }}
                >
                  <Button
                    onClick={handleClose}
                    sx={{
                      background: "#737D86",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#737D86" },
                    }}
                  >
                    {t("No")}
                  </Button>
                  <Button
                    onClick={handleDeleteConfirm}
                    sx={{
                      background: "#004F9F",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#004F9F" },
                    }}
                  >
                    {t("Yes")}
                  </Button>
                </Box>
              </Box>
            </Modal>

            {edit !== false && (
              <Button
                sx={{
                  padding: "6px 16px",
                  borderRadius: "4px",
                  right: "1rem",
                  bottom: "1.5rem",
                  marginTop:"8px",
                  marginBottom: "40px",
                  backgroundColor: "#EF7D00",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#EF7D00",
                  },
                }}
                startIcon={<img src={Save} alt="Right Arrow" />}
                onClick={edit === undefined ? handleSubmit : handleUpdate}
                variant="contained"
              >
                {t("save")}
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Layout>
  );
}
