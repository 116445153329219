import { httpGrow } from "../http-common";

// const createTobacco = (data) => {
//   return httpGrow.post(`/tobaccoCompaniesCompetitors`, data);
// };

const getCropSeasonAPI = async () => {
  const response = await httpGrow.get('grCropSeason/search?status=true&cultureName=Tobacco');
  return response;
}
const getInvestmentAPI = async () => {
  const response = await httpGrow.get('/investment-group/investments');
  return response;
}
const getAgriculturalSupplyAPI = async () => {
  const response = await httpGrow.get('/agricultural-supplies/searchE1');
  return response;
}
const picklistAPI = (data) => {
  return httpGrow.post(`/picklist`, data);
};
const getDataAPI = async ({cropYear, type}) => {
  return await httpGrow.get(`/tech-recommendations/search?pageNumber=0&size=50&cropYear=${cropYear}&type=${type}`);
};
const postDataAPI = async (data) => {
  return await httpGrow.post(`/tech-recommendations`,data)
};

const deleteDataAPI = async ({ cropSeasonId, type, investmentId, agriculturalSuppliesId }) => {
  // Construct the query string based on the optional parameters
  let queryParams = "";
  if (investmentId) {
    queryParams += `?investmentId=${investmentId}`;
  }
  if (agriculturalSuppliesId) {
    queryParams += (queryParams ? "&" : "?") + `agriculturalSuppliesId=${agriculturalSuppliesId}`;
  }

  // Construct the full URL
  const url = `/tech-recommendations/${cropSeasonId}/${type}${queryParams}`;

  // Make the DELETE request
  return await httpGrow.delete(url);
};
const putDataAPI = async (data) => {
  return await httpGrow.put(`/tech-recommendations`,data)
};




const TechnicalRecomendationService = {
  getCropSeasonAPI,
  getInvestmentAPI,
  getAgriculturalSupplyAPI,
  picklistAPI,
  getDataAPI,
  postDataAPI,
  deleteDataAPI,
  putDataAPI
};

export default TechnicalRecomendationService;
