import { httpGrow } from "../http-common";

const createReasonForNotStudying = (data) => {
  return httpGrow.post(`/reason-for-not-studying/save`, data);
};

const updateReasonForNotStudying = (data) => {
  return httpGrow.put(`reason-for-not-studying/update`, data);
};

const getReasonForNotStudying = async (query) => {
  const { pageNumber, pageSize, reasonForNotStudying, isActive } = query;

  const response = await httpGrow.get(`/reason-for-not-studying/search`, {
    params: {
      pageNumber,
      pageSize,
      reasonForNotStudying: reasonForNotStudying || undefined,
      status: isActive,
    },
  });
  return response;
};

const ReasonForNotStudyingService = {
  createReasonForNotStudying,
  updateReasonForNotStudying,
  getReasonForNotStudying,
};

export default ReasonForNotStudyingService;
