// GetCompetitorConfiguration.jsx
import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Checkbox,
  InputAdornment,
  FormControl,
  Grid,
  MenuItem,
  IconButton,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import CompetitorConfigurationTable from "./CompetitorConfigurationTable";
import SearchIcon from "@mui/icons-material/Search";
import CompetitorConfigurationService from "services/CompetitorConfigurationService";
import debounce from "lodash/debounce";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import label from "../growingLabel.json";
import { useTranslation } from "react-i18next";

const GetCompetitorConfiguration = () => {
  const [initialData, setInitialData] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [regionOptions, setRegionOptions] = useState([]);
  const [region, setRegion] = useState("");
  const [showUsedRecords, setShowUsedRecords] = useState(false);
  const { t } = useTranslation();
  const [changes, setChanges] = useState(0);

  const debouncedSearch = useCallback(
    debounce((value, region, showUsedRecords) => {
      fetchSearchData(
        currentPage,
        itemsPerPage,
        value,
        region,
        showUsedRecords
      );
    }, 1500),
    [itemsPerPage]
  );

  const handleRegionChange = (newValue) => {
    setRegion(newValue);
    setCurrentPage(0);
    fetchSearchData(0, itemsPerPage, searchKeyword, newValue, showUsedRecords);
  };

  useEffect(() => {
    if (!searchKeyword && !region && !showUsedRecords) {
      fetchSearchData(currentPage, itemsPerPage);
    }
    fetchSearchData(
      currentPage,
      itemsPerPage,
      searchKeyword,
      region,
      showUsedRecords
    );
    return () => {
      debouncedSearch.cancel(); // Clean up on unmount
    };
  }, [currentPage, itemsPerPage, changes]);

  const fetchRegions = async () => {
    try {
      const res = await CompetitorConfigurationService.getAPI(0, 50);
      // Safely access 'Competatior Configuration' and map regions
      const regionNames =
        res?.data?.response?.["Competatior Configuration"]?.map(
          (item) => item.region
        ) || []; // Extract 'region' from each record
      // Store unique region names
      const uniqueRegionNames = [...new Set(regionNames)];
      setRegionOptions(uniqueRegionNames);
    } catch (error) {
      // Handle error
      console.log("Error: ", error);
      setMessage("Error fetching regions");
      setSeverity("error");
      setOpenAlert(true);
    }
  };

  const handleSearchChange = (event, newValue) => {
    setCurrentPage(0);
    setSearchKeyword(newValue); // Update state immediately

    if (newValue.trim() === "") {
      // If the input is cleared, cancel debounce and reset filters
      debouncedSearch.cancel();
      fetchSearchData(0, itemsPerPage, "", region, showUsedRecords);
    } else {
      debouncedSearch(newValue, region, showUsedRecords); // Proceed with debounced search
    }
  };

  const handleClearRegion = () => {
    setRegion("");
    fetchSearchData(
      currentPage,
      itemsPerPage,
      searchKeyword,
      "",
      showUsedRecords
    );
  };

  const fetchSearchData = (
    pageNumber,
    size,
    keyword = undefined,
    region = undefined,
    showUsedRecords = undefined
  ) => {
    setLoading(true);
    const query = {
      pageNumber,
      size,
      keyword: keyword,
      region: region,
      showUsedRecords: showUsedRecords,
      batOneId: 85189949,
    };
    CompetitorConfigurationService.searchAPI(query)
      .then((res) => {
        const results =
          res?.data?.response?.["Competatior Configuration"] || [];
        setData(results);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (
          results.length === 0 &&
          ((keyword && keyword.length > 0) || region || showUsedRecords)
        ) {
          setMessage(label.noDataMessage);
          setSeverity("info");
          setOpenAlert(true);
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
        setData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchRegions();
    fetchInitialData();
  }, []);

  const fetchInitialData = (pageNumber, size) => {
    setLoading(true);
    const query = {
      pageNumber,
      size,
      batOneId: 85189949,
      createdByUser: "UI",
    };
    CompetitorConfigurationService.getAPI(query)
      .then((res) => {
        const results =
          res?.data?.response?.["Competatior Configuration"] || [];
        setData(results);
        setInitialData(results);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (results.length === 0) {
          setMessage(label.noDataMessage);
          setSeverity("info");
          setOpenAlert(true);
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
        setInitialData([]);
        setData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleShowUsedRecords = () => {
    setShowUsedRecords(!showUsedRecords);
    fetchSearchData(0, itemsPerPage, searchKeyword, region, !showUsedRecords);
  };

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    setItemsPerPage(itemsPerPage);
    if (!searchKeyword && !region && !showUsedRecords) {
      fetchSearchData(pageNumber - 1, itemsPerPage);
    } else {
      fetchSearchData(
        pageNumber - 1,
        itemsPerPage,
        searchKeyword,
        region,
        showUsedRecords
      );
    }
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t("CompetitorConfiguration.pageTitle")}
      background="#ffffff"
      useNewPagination={true}
    >
      <Grid
        container
        spacing={2}
        sx={{
          background: "#EFEFEF",
          borderRadius: "10px",
          p: "5px",
          maxWidth: "100%",
          margin: "auto",
          paddingBottom: "8px",
        }}
      >
        <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px" }}>
          <Typography variant="h8" display="flex">
            <ArrowDropDownIcon />
            {t("CompetitorConfiguration.filtersLabel")}
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Autocomplete
            freeSolo
            options={initialData.map((ele) => ele.tobaccoCompanyName)}
            inputValue={searchKeyword}
            onInputChange={(event, newValue) =>
              handleSearchChange(event, newValue)
            }
            getOptionLabel={(option) => option || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                name="companyName"
                label={t("CompetitorConfiguration.companyName")}
                sx={{ width: "100%" }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <SearchIcon
                      sx={{
                        color: "gray",
                        marginLeft: "6px",
                        marginRight: "5px",
                        fontSize: "2vw",
                      }}
                    />
                  ),
                  style: { height: "46px", padding: "0px" },
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={3}>
          <FormControl sx={{ width: { xs: "100%" } }}>
            <TextField
              select
              name="Region"
              label={t("CompetitorConfiguration.region")}
              value={region || "Select an Option"}
              onChange={(event) => handleRegionChange(event.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "0px",
                  minHeight: "46px",
                  height: "46px",
                },
                "& .MuiInputLabel-root": {
                  lineHeight: "1.2em",
                },
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  padding: "10px",
                },
              }}
              InputProps={{
                endAdornment: region && (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={handleClearRegion}
                      style={{ marginRight: "25px" }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value="Select an Option" sx={{ display: "none" }}>
                Select an Option
              </MenuItem>
              {regionOptions.map((region) => (
                <MenuItem key={region} value={region}>
                  {region}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={2}
          style={{
            paddingTop: "12px",
            paddingLeft: "20px",
            paddingRight: "0px",
          }}
        >
          <Typography variant="subtitle2">
            <Checkbox
              checked={showUsedRecords}
              onChange={handleShowUsedRecords}
              color="primary"
            />
            {t("CompetitorConfiguration.showUsedRecords")}
          </Typography>
        </Grid>

        {/* Blank grid to add some height */}
        <Grid xs={12} sx={{ height: "20px" }}></Grid>
      </Grid>

      {/* Table Section */}
      {(() => {
        if (loading) {
          return (
            <Typography variant="h6" align="center">
              Loading...
            </Typography>
          );
        } else if (totalItems > 0) {
          if (showUsedRecords) {
            if (data.length > 0) {
              return (
                <CompetitorConfigurationTable
                  data={data}
                  setData={setData}
                  setChanges={setChanges}
                />
              );
            } else {
              return (
                <Typography variant="h6" align="center">
                  {t("CompetitorConfiguration.noUsedRecords")}
                  {region ? " for the selected region" : ""}.
                </Typography>
              );
            }
          } else {
            return (
              <CompetitorConfigurationTable
                data={data}
                setData={setData}
                setChanges={setChanges}
              />
            );
          }
        } else {
          return (
            <Typography variant="h6" align="center">
              {/* No records found{region ? " for the selected region" : ""}. */}
              {t("CompetitorConfiguration.noDataMessage")}
            </Typography>
          );
        }
      })()}
    </Layout>
  );
};

export default GetCompetitorConfiguration;
