// CuringTypeTable.jsx
import React, { useEffect, useState, useContext } from "react";
import { Typography, Box, Button, Modal } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "./CuringTypeTable.css";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import { useNavigate } from "react-router-dom";
import CuringTypeService from "services/CuringTypeService";
import { SnackbarContext } from "context/snackBarContext";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";

const CuringTypeTable = (props) => {
  const navigate = useNavigate();
  const [curingData, setCuringData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [open, setOpen] = useState(false); // Modal state
  const [deleteId, setDeleteId] = useState(null); // ID of the record to delete
  const { t } = useTranslation();

  useEffect(() => {
    setCuringData(props.curingData);
  }, [props.curingData]);

  const handleDeleteClick = (ctrId) => {
    setDeleteId(ctrId); // Store the ID of the record to delete
    setOpen(true); // Open the confirmation modal
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await CuringTypeService.deleteCuringType(deleteId); // Delete by stored ID
      if (response.status === 200) {
        setMessage(t("deletedSuccessfully"));
        setSeverity("success");
        setOpenAlert(true);
        props.setCuringData((prevData) =>
          prevData.filter((item) => item.ctrId !== deleteId)
        );
        props.setIsDeleted((prev) => (prev += 1));
      } else {
        setMessage("Failed to delete curing type");
        setSeverity("error");
        setOpenAlert(true);
      }
    } catch (error) {
      setMessage("Error deleting curing type");
      setSeverity("error");
      setOpenAlert(true);
    } finally {
      setOpen(false); // Close the modal after deletion
    }
  };

  const handleClose = () => {
    setOpen(false); // Close the modal without deleting
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...curingData].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    props.setCuringData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <ArrowUpwardIcon fontSize="small" />;
      } else if (sortConfig.direction === "desc") {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
    return <ArrowDownwardIcon fontSize="small" />;
  };

  return (
    <div className="table-container">
      <table className="curing-type-table">
        <thead>
          <tr>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("curingType")}
            >
              {t("curingTypeRegistration.curingType")}{" "}
              {getSortIcon("curingType")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("cultureName")}
            >
              {t("curingTypeRegistration.culture")} {getSortIcon("cultureName")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("isActive")}
            >
              {t("status")}
              {getSortIcon("isActive")}
            </th>
            <th className="actions-header">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  justifyContent: "left",
                }}
              >
                {t("actions")}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {curingData?.map((row, index) => (
            <tr
              key={index}
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate("/addCuringType", {
                  state: { row: row, edit: false },
                })
              }
            >
              <td>{row.curingType}</td>
              <td>{row.cultureName}</td>
              <td>{row.isActive ? "Active" : "Inactive"}</td>
              <td className="actions-cell">
                <Tooltip title="EDIT" arrow placement="top">
                  <img
                    className="btn edit-btn"
                    src={_ButtonEdit_}
                    alt="Edit Button"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the row click from firing
                      navigate("/addCuringType", {
                        state: { row: row, edit: true },
                      });
                    }}
                  />
                </Tooltip>

                <Tooltip title="DELETE" arrow placement="top">
                  <img
                    className="btn delete-btn"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the row click from firing
                      handleDeleteClick(row.ctrId);
                    }}
                    src={_ButtonDelete_}
                    alt="Delete"
                  />
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for delete confirmation */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "520px",
            margin: "auto",
            mt: 25,
            height: "200px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              {t("deleteConfirmation")}
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, fontWeight: 400 }}
            >
              {t("deleteConfirmationMessage")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              {t("No")}
            </Button>
            <Button
              onClick={handleDeleteConfirm}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              {t("Yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CuringTypeTable;
