import React, { useState, useEffect, useContext } from "react";
import "./Segmentation.css";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Box, Button, Modal, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SnackbarContext } from "context/snackBarContext";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import { useTranslation } from "react-i18next";
import SegmentationKpiService from "services/SegmentationKpiService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 580,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const SegmentationTable = ({ kpiData, setKpiData, setIsDeleted }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const { t } = useTranslation();

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...kpiData].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setKpiData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <ArrowUpwardIcon fontSize="small" />;
      } else if (sortConfig.direction === "desc") {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
    return <ArrowDownwardIcon fontSize="small" />;
  };

  const handleOpen = (deleteID) => {
    setDeleteId(deleteID);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      const response = await SegmentationKpiService.deleteSegmentationKpi(
        deleteId
      );
      if (response.status === 200) {
        setMessage(response.data.message);
        setSeverity("success");
        setOpenAlert(true);
        setIsDeleted((prev) => (prev += 1));
      } else {
        setMessage("Failed to delete Segmentation Kpi");
        setSeverity("error");
        setOpenAlert(true);
      }
    } catch (error) {
      setMessage("Error deleting Segmentation Kpi");
      setSeverity("error");
      setOpenAlert(true);
      console.error("Error deleting Segmentation Kpi:", error);
    } finally {
      setOpen(false);
    }
  };

  return (
    <div className="table-container">
      <table className="custom-table">
        <thead>
          <tr>
            <th
              onClick={() => handleSort("name")}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {t("segmentationKPI.name")}
              {getSortIcon("name")}
            </th>
            <th
              onClick={() => handleSort("description")}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {t("description")} {getSortIcon("description")}
            </th>
            <th
              onClick={() => handleSort("cultureName")}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {t("segmentationKPI.origin")}
              {getSortIcon("cultureName")}
            </th>

            <th className="actions-header">{t("Actions")}</th>
          </tr>
        </thead>

        <tbody>
          {kpiData.map((row, index) => (
            <tr
              key={index}
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate("/addsegmentationRegistration", {
                  state: { row: row, edit: false },
                })
              }
            >
              <td>{row.segmentationName}</td>
              <td>{row.segmentationDescription}</td>
              <td>{row.origins.join(", ")}</td>
              <td className="actions-cell">
                <Tooltip title="Edit" arrow placement="top">
                  <img
                    className="btn edit-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate("/addsegmentationRegistration", {
                        state: { row: row, edit: true },
                      });
                    }}
                    src={_ButtonEdit_}
                    alt="Edit"
                  />
                </Tooltip>
                <Tooltip title="Delete" arrow placement="top">
                  <img
                    className="btn delete-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpen(row.segmentationKpiId);
                    }}
                    src={_ButtonDelete_}
                    alt="Delete"
                  />
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              {t("deleteConfirmation")}
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography sx={{ mt: 2, fontWeight: 400 }}>
              {t("deleteConfirmationMessage")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": {
                  background: "#737D86",
                },
              }}
            >
              {t("no")}
            </Button>
            <Button
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": {
                  background: "#004F9F",
                },
              }}
              onClick={handleDelete}
            >
              {t("yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default SegmentationTable;
