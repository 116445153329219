import React from 'react';
import { 
  Modal, 
  Box, 
  Typography, 
  Button 
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const DeleteConfirmationModal = ({ 
  open, 
  onClose, 
  onConfirm, 
  title = 'deleteConfirmation', 
  message = 'deleteConfirmationMessage' 
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "7px",
          width: "520px",
          margin: "auto",
          mt: 25,
          height: "200px",
        }}
      >
        <Box
          sx={{
            background: "#102A62",
            height: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTopLeftRadius: "7px",
            borderTopRightRadius: "7px",
          }}
        >
          <Typography
            sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
          >
            {t(title)}
          </Typography>
        </Box>
        <Box sx={{ p: 2 }}>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, fontWeight: 400 }}
          >
            {t(message)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            mb: 1,
          }}
        >
          <Button
            onClick={onClose}
            sx={{
              background: "#737D86",
              width: "100px",
              color: "#ffffff",
              "&:hover": { background: "#737D86" },
            }}
          >
            {t('no')}
          </Button>
          <Button
            onClick={onConfirm}
            sx={{
              background: "#004F9F",
              width: "100px",
              color: "#ffffff",
              "&:hover": { background: "#004F9F" },
            }}
          >
            {t('yes')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteConfirmationModal;
