const { httpGrow } = require("http-common");

const getCropSeasonAPI = async () => {
  const response = await httpGrow.get('grCropSeason/search');
  return response;
};

const getFarmerCPAList = async (query) => {
    console.log("Query : ", query);
    const { pageNumber, size, cropSeasonId, status} = query;
    const response = await httpGrow.get(`/farmer-pre-assesment/search`, {
      params: {
        pageNumber,
        size,
        cropSeasonId: cropSeasonId,
        status: status
        // status: status,
      },
    });
    return response;
  };

  const getHierarchyNodeAPI = async () => {
    const response = await httpGrow.get('/importHierarchyVersion/tree');
    return response;
};

  const getPreAssessmentRMFarmerList = async (cropSeasonId) => {
    const response = await httpGrow.get(`/farmer-pre-assesment/${cropSeasonId}`)
    return response
  }

  const getInitialAllFarmerList = async () => {
    const response = await httpGrow.get(`/farmer/getAllFarmerList`)
    return response
  }

  const putFPAPI = (data) => {
    return httpGrow.put('farmer-pre-assesment/update', data);
};

const postFPAPI = (data) => {
    return httpGrow.post('farmer-pre-assesment/save', data);
};
  
const downloadTemplate = () => {
  return httpGrow.get('farmer-pre-assesment/template/assesment', {
    responseType: 'blob'
  });
};

const importPresAssessment = (file, countryId, languageId, createdByUser, modifiedByUser, cropSeasonId, isEnableCropSeason ) => {
  const formData = new FormData();
  formData.append('file', file);

  return httpGrow.post('/farmer-pre-assesment/import', formData, {
    params: {
      cropSeasonId,
      isEnableCropSeason,
      countryId,
      languageId,
      createdByUser,
      modifiedByUser
    },
    headers: {
      'Content-Type': 'multipart/form-data',
      'accept': 'application/json'
    }
  });
};

const FarmersContractPreAssessmentService = {
    getCropSeasonAPI,
    getFarmerCPAList,
    getHierarchyNodeAPI,
    getPreAssessmentRMFarmerList,
    getInitialAllFarmerList,
    putFPAPI,
    postFPAPI,
    downloadTemplate,
    importPresAssessment
};

export default FarmersContractPreAssessmentService;