import {
  AppBar,
  Box,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tab,
  Tabs,
} from "@mui/material";
import * as React from "react";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import withRouter from "./withRouter";
import { ArrowDropUp } from "@mui/icons-material";
import "./Navbar.css";

const styles = (theme) => {};

const items = [
  { pathName: "/", label: "Growing" },
  { pathName: "/", label: "Access Control" },
  // { pathName: "/", label: "Reports" },
  // { pathName: "/", label: "Analytical Reports" }
];
const subItems = [
  {pathName:"/approvaldashboard",label:"Approval Dashboard"},
  {pathName:"/farmersegmentation",label:"Farmer Segmentation"},
  { pathName: "/varietySeeds", label: "Variety Seeds" },
  {
    pathName: "/expenseslimitdistribution",

    label: "Expenses Limit Configuration",
  },
  {
    pathName: "/technicalrecomendation",
    label: "Technical Recomendation of Third supplies",
  },
  {
    pathName: "/farmercontract",
    label: "Farmer Contract",
  },
  { pathName: "/irregularityRegistration", label: "Irregularity Registration" },
  // { pathName: "/getmoduleregistration", label: "Module Registration" },
  // { pathName: "/cropStage", label: "Crop Stage Registration" },
  // { pathName: "/getCropStageCalander", label: "Crop Stage Calendar" },
  { pathName: "/hierarchyConfiguration", label: "Hierarchy Configuration" },
  { pathName: "/spacingRegistration", label: "Spacing Registration" },
  { pathName: "/cropSeasonRegistration", label: "Crop Season Registration" },
  { pathName: "/curingUnit", label: "Curing Unit Registration" },
  { pathName: "/farmerRegistration", label: "Farmer Registration" },
  {
    pathName: "/AgriCultureSupplyManagement",
    label: "Agricultural Supply Management",
  },
  {
    pathName: "/HierarchyTreeConfigurationHome",
    label: "Hierarchy Tree Configuration",
  },
  { pathName: "/variety", label: "Variety Registration" },

  { pathName: "/cultureGroup", label: "Culture Group" },
  { pathName: "/EquipmentRegistration", label: "Equipment Registration" },
  { pathName: "/supplyTypeList", label: "Supply Type" },

  { pathName: "/cropPhaseRegistration", label: "Crop Phase Registration" },

  {
    pathName: "/fieldNotebookSupplyRegistration",
    label: "Agricultural Supply Field Notebook Registration",
  },
  { pathName: "/occurrenceType", label: "Field Occurence Registration" },

  { pathName: "/greenManure", label: "Green Manure Registration" },
  {
    pathName: "/plantingTypeRegistration",
    label: "Planting Type Registration",
  },
  { pathName: "/trayTypeRegistration", label: "Tray Type Registration" },
  {
    pathName: "/tobaccoCompetitorsRegistration",
    label: "Tobacco companies competitors registration",
  },
  { pathName: "/furnaceType", label: "Furnace Type Registration" },
  {
    pathName: "/investmentsRegistration",
    label: "Investments Group Registration",
  },
  {
    pathName: "/funding",
    label: "Funding Group Registration",
  },
  // /funding
  {
    pathName: "/additionalFreightGroupList",
    label: "Additional Freight Group Registration",
  },
  { pathName: "/expenses3rdPartyList", label: "Expenses with 3rd Parties" },
  { pathName: "/paymentPolicies", label: "Payment Policies Registration" },
  {
    pathName: "/paymentPolicyLimitsDistribution",
    label: "Payment Policy Limits Distribution",
  },
  {
    pathName: "/soilAnalysisApplicationPhase",
    label: "Soil Analysis Application Phase",
  },
  { pathName: "/curingDevice", label: "Curing Device Registration" },
  { pathName: "/curingType", label: "Curing Type Registration" },
  { pathName: "/agronomicLossType", label: "Agronomic Loss Type Registration" },
  { pathName: "/soilAnalysis", label: "Soil Analysis Registration" },
  { pathName: "/competitorConfiguration", label: "Competitor Configuration" },
  { pathName: "/localityConfiguration", label: "Locality Configuration" },
  { pathName: "/cityConfiguration", label: "City Configuration" },
  { pathName: "/productionTargets", label: "Production Targets" },
  { pathName: "/soilParameter", label: "Soil Analysis-Parameter Registration" },
  { pathName: "/transporterRegistration", label: "Transporter Registration" },
  { pathName: "/bankConfiguration", label: "Bank Configuration" },

  { pathName: "/getCultureRegistration", label: "Culture Registration" },
  { pathName: "/supplierConfiguration", label: "Supplier Configuration" },
  { pathName: "/transporterConfiguration", label: "Tranporter Configuration" },
  {
    pathName: "/investmentGroupLimits",
    label: "Investment Group Limits Distribution",
  },
  {
    pathName: "/agriculturalSuppliesTargets",
    label: "Agricultural Supplies Targets",
  },
  {
    pathName: "/AgriculturalSupplyConfiguration",
    label: "Agricultural Supply Configuration",
  },
  {
    pathName: "/additionalFreightLimits",
    label: "Additional Freight Limits",
  },
  { pathName: "/geneticTypeRegistration", label: "Genetic Type Registration" },
  { pathName: "/priceParameterizationList", label: "Price Parametrization of Investment" },
  { pathName: "/segmentationRegistration", label: "Segmentation KPI Registration" },
  { pathName: "/spacingTarget", label: "Spacing Target" },
  {
    pathName: "/listCultureTypeRegistration",
    label: "Culture Type Registration",
  },
  {
    pathName: "/preAssessmentReleaseModuleCropSeasonList",
    label: "Pre Assessment Release Module – Crop Season List",
  },
  {
    pathName: "/agriculturalSuppliesRegistrationList",
    label: "Agricultural Supplies Registration List",
  },
  {
    pathName: "/fundinggrouplimits",
    label: "Funding Group Limits Distribution",
  },
  {
    pathName: "/centralAgriculturalSupplyManagement",
    label: "Central Agricultural Supply Management",
  },
  {
    pathName: "/preAssessmentFormBuilderList",
    label: "Pre Assessment Form Builder",
  },
  {
    pathName: "/preAssessmentFormApplication",
    label: "Farmer Contract Pre Assessment ESG",
  },
  { pathName: "/soilAnalysisInterpretationRegistration", label: "Soil Analysis Interpretation Registration" },
  { pathName: "/approvalWorkflowApprovalLevelConfigurationList", label: "Approval Workflow – Approval Level Configuration - List" },
  {
    pathName: "/tasks",
    label: "Task Management",
  },
  {
    pathName: "/educationType",
    label: "Education Type Registration",
  },
  {
    pathName: "/levelOfEducation",
    label: "Level Of Education Registration",
  },
  {
    pathName: "/reasonForNotStudying",
    label: "Reason for Not Studying Registration",
  },
  {
    pathName: "/farmerClassification",
    label: "Farmer Classification Registration",
  },
  {
    pathName: "/contractType",
    label: "Contract Type",
  },
  {
    pathName: "/defineFamerSampleEstimate",
    label: "Define Farmer Sample for Estimate by Position/Plant",
  },
  { pathName: "/segmentationKPI-Rules", label: "Segmentation KPI's Routines and Rules" },
  {
    pathName: "/agronomicTechnologiesDashboard",
    label: "Agronomic Technologies Dashboard",
  },
  {
    pathName: "/MSC",
    label: "Menu Structure Configuration",
  },
];
const subItems2 = [
  { pathName: "/manageProfiles", label: "Manage Profiles" },
  { pathName: "/users", label: "Manage Users" },
];
const subItems3 = [
  { pathName: "/manageProfiles", label: "Manage Profiles" },
  { pathName: "/users", label: "Manage Users" },
];
let selectedMenu = null;
class AppBarTop extends React.Component {
  state = {
    value: selectedMenu ? selectedMenu : 0,
    hover: selectedMenu ? selectedMenu : 0,
    open: false,
    anchorEl: null,
  };
  handleMenuClick = (index) => {
    console.log("index", index);
  };
  handleMenuOpen = (index, event) => {
    console.log("index", index + event);
    const { currentTarget } = event;
    selectedMenu = index;
    this.setState({
      open: true,
      anchorEl: currentTarget,
      hover: index,
    });
  };

  handleMenuClose = (item) => {
    const { navigate } = this.props;
    const { showExitWarningOverlayFeed } = this.props;
    const { setShowExitWarningOverlayFeed } = this.props;
    const { setPendingNavigation } = this.props;
    console.log("item", item.pathName);
    if (showExitWarningOverlayFeed) {
      setShowExitWarningOverlayFeed();
      setPendingNavigation(item.pathName);
    } else {
      navigate(item.pathName);
      // window.history.replaceState(null, "", `/gw_agri360${item.pathName}`);
      this.setState({
        open: false,
        anchorEl: null,
        value: selectedMenu,
      });
    }
  };
  handleInputSearch = () => {};
  hasPermission = (permission) => {
    // return true
    return this.props?.functionalities?.includes(permission);
  };

  render() {
    const { classes } = this.props;
    const { anchorEl, open } = this.state;
    return (
      <Box className="bottoMenu">
        <AppBar position="static">
          <Paper>
            <Tabs
              value={this.state.value}
              variant="fullWidth"
              sx={{
                "& .Mui-selected": {
                  color: "#EF7D00 !important",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "#EF7D00 !important",
                },
                "& .MuiButtonBase-root": {
                  display: "flex",
                  justifyContent: "center",
                  minHeight: "unset",
                },
                "& .MuiTabs-scroller": {
                  padding: "10px",
                },
              }}
            >
              {console.log("tabs value", this.state.value)}
              {items.map((item, index) => (
                <Tab
                  key={index}
                  onMouseEnter={this.handleMenuOpen.bind(this, index)}
                  data-key={index}
                  sx={{ justifyContent: "space-around", cursor: "default" }}
                  label={item.label}
                  aria-owns={open ? "menu-list-grow" : undefined}
                  aria-haspopup={"true"}
                  component="div"
                  icon={
                    open && this.state.hover === index ? (
                      <ArrowDropDown />
                    ) : (
                      <ArrowDropUp />
                    )
                  }
                  iconPosition="right"
                />
              ))}
            </Tabs>
            <Popper
              open={this.state.open}
              anchorEl={anchorEl}
              id="menu-list-grow"
              onMouseEnter={() => this.setState({ open: true })}
              onMouseLeave={() => this.setState({ open: false })}
              sx={{
                width: anchorEl ? anchorEl.clientWidth : "auto",
                zIndex: 999,
              }}
            >
              <Paper
                sx={{
                  minWidth: "200px",
                  maxHeight: "300px",
                  overflowY: "auto",
                }}
              >
                <MenuList>
                  {this.state.hover === 0 &&
                    subItems
                      .sort((a, b) => a.label.localeCompare(b.label))
                      .map((item, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => this.handleMenuClose(item)}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                  {this.state.hover === 1 &&
                    subItems2.map((item, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => this.handleMenuClose(item)}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  {this.state.hover === 2 &&
                    subItems3.map((item, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => this.handleMenuClose(item)}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                </MenuList>
              </Paper>
            </Popper>
          </Paper>
        </AppBar>
      </Box>
    );
  }
}

export default withRouter(AppBarTop);
