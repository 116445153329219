import React from "react";
import styled from "@emotion/styled";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { purple, green, blue } from "@mui/material/colors";
import Footer from "../Footer";
import Navbar from "components/navbar/Navbar";
import Pagination from "components/pages/Hierarchy/Paginationcc";
// Define custom theme

const theme = createTheme({
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: green[500],
    },
    background: {
      default: "#F5F5F7",
    },
  },
  background: "#ffff",
  typography: {
    fontFamily: "Montserrat",
  },

  components: {
    MuiFormControlLabel: {
      root: {
        marginLeft: 0, // Remove the left margin
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label": {
            color: "#0E2B63",
          },
          "& label.Mui-focused": {
            color: "#0E2B63",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "#0E2B63",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#0E2B63",
            },
            "&:hover fieldset": {
              borderColor: "#0E2B63",
              borderWidth: "0.15rem",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#0E2B63",
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "#0E2B63",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0E2B63",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0E2B63",
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.MuiOutlinedInput-root": {
            backgroundColor: "#ffff",
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        "& .MuiSwitch-switchBase.Mui-checked": {
          color: "#000243",
          "&:hover": {
            backgroundColor: "#000243",
          },
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
          backgroundColor: "#000243",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#737D86",
          fontWeight: 500,
          fontFamily: "Montserrat",
          "& .MuiInputLabel-asterisk": {
            color: "red !important",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          marginRight: "20px",
          fontWeight: 600,
          color: "#191E31",
        },
      },
    },
  },
  
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: "#004F9F", // Default color
        "&.Mui-checked": {
          color: "#004F9F", // Checked state color
        },
      },
    },
  },
  MuiStepConnector: {
    styleOverrides: {
      root: {
        marginTop: "-2px",
        marginBottom: "-4px",
        minHeight: "calc(24px + 0.5rem)",
      },
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      root: {
        height: "1.25rem",
        lineHeight: "1.25rem",
      },
    },
  },
});
const Layout = ({
  title,
  children,
  itemsPerPage,
  totalItems,
  paginate,
  currentPage,
  background,
  cancel,
  useNewPagination,
  showExitWarningOverlayFeed,
  setShowExitWarningOverlayFeed,
  setPendingNavigation,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Container background={background}>
        <Navbar
          title={title}
          showExitWarningOverlayFeed={showExitWarningOverlayFeed}
          setShowExitWarningOverlayFeed={setShowExitWarningOverlayFeed}
          setPendingNavigation={setPendingNavigation}
        />
        <Content background={background}>{children}</Content>
        {/* {(totalItems?.length > 0 ??  */}
        <Footer
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
          paginate={paginate}
          currentPage={currentPage}
          useNewPagination={useNewPagination}
        />
        {/* )} */}
      </Container>
    </ThemeProvider>
  );
};

const Container = styled.div`
  position: relative;
  min-height: calc(100vh);
  background: ${(props) =>
    props.background || props.theme.palette.background.default};
`;
const Content = styled.div`
  margin-top: 4rem;
  padding: 32px 25px 0px 25px;
  background: ${(props) =>
    props.background || props.theme.palette.background.default};
`;
export default Layout;
