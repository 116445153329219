import { httpGrow } from "../http-common";

const basename = "agricultural-supplies-configuration";

const getAPI = async (query) => {
  const {
    pageNumber = 0,
    size = 50,
    cropSeasonId,
    geneticTypeId,
    batOneId = 111111,
    createdByUser = "UI",
  } = query;
  const response = await httpGrow.get(`/${basename}/list`, {
    params: {
      pageNumber,
      size,
      cropSeasonId,
      geneticTypeId,
      batOneId,
      createdByUser,
    },
  });
  return response;
};

const getSearchAPI = async (query) => {
  const {
    pageNumber = 0,
    size = 50,
    useThisRecord,
    agriSupplyName,
    applicationPhase,
    target,
    batOneId = 111111,
    cropSeasonId,
    geneticTypeId,
  } = query;
  const response = await httpGrow.get(`/${basename}/search`, {
    params: {
      pageNumber,
      size,
      useThisRecord: useThisRecord || null,
      agriSupplyName: agriSupplyName || null,
      applicationPhase: applicationPhase || null,
      target: target || null,
      batOneId,
      cropSeasonId,
      geneticTypeId,
    },
  });
  return response;
};

const getCropSeasons = async () => {
  const response = await httpGrow.get(
    "/grCropSeason/search?status=true&cultureName=Tobacco"
  );
  return response;
};

const getTobaccoGenericTypes = async () => {
  const response = await httpGrow.get("/geneticType/tobacco-genetic-types");
  return response;
};

const getApplicationPhases = async () => {
  const response = await httpGrow.get(`/${basename}/unique-application-phases`);
  return response;
};

const updateAPI = (data) => {
  return httpGrow.put(`/${basename}/update`, data);
};

const AgriculturalSupplyConfigurationService = {
  getAPI,
  getSearchAPI,
  updateAPI,
  getTobaccoGenericTypes,
  getCropSeasons,
  getApplicationPhases,
};

export default AgriculturalSupplyConfigurationService;
