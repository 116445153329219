import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Grid,
  TextField,
  MenuItem,
  Icon,
  Box,
  FormControl,
  FormHelperText,
  Typography,
} from "@mui/material";

import Button from "@mui/material/Button";
import plusIcon from "assets/plusIcon.svg";
import editButton from "assets/editButton.svg";
import iconEdit from "assets/iconEdit.svg";
import iconView from "assets/iconView.svg";
import iconDelete from "assets/iconDelete.svg";
import { useNavigate } from "react-router-dom";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Layout from "components/structure/Layout";
import { useSelector } from "react-redux";
import { SnackbarContext } from "context/snackBarContext";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { debounce } from "lodash";
import SeasonRegistrationService from "services/SeasonRegistrationService";
import CultureService from "services/CultureService";
import CropSeasonService from "services/CropSeasonService";
import Table from "./Table";
import label from "../growingLabel.json";

const RegistrationOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DataContainer = styled.div`
  margin-top: 2rem;
`;

const GetCropSeasonRegistration = () => {
  const navigate = useNavigate();
  const farmerObj = useSelector((state) => state.farmerObj.farmerObj);
  const [errors, setErrors] = useState({});
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [rows, setRows] = useState([]);
  const [data, setData] = useState();
  const [seasonData, setSeasonData] = useState([]);
  console.log("🚀 ~ GetCropSeasonRegistration ~ data:", data);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageSearch, setCurrentPageSearch] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(7); // Number of items per page
  const [totalItems, setTotalItems] = useState(5); // Number of items per page
  const [recordsError, setRecordsError] = useState(false);

  const [cropYears, setCropYears] = useState([]);
  const [cultureData, setCultures] = useState([]);

  const [descSearch, setDescSearch] = useState({ search: "" });

  const [filterSeason, setFilterParams] = useState({
    cropYear: "",
    status: "",
    cultureName: "",
  });
  const columns = [
    {
      field: "season",
      headerName: "Crop Season",
      width: 300,
      align: "start",
      headerAlign: "start",
    },
    {
      field: "cropYear",
      headerName: "Year",
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "cultureName",
      headerName: "Culture",
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    //registeredSpacing
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 250,
      headerAlign: "center",
      align: "center",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<img src={editButton} />}
            label="Edit"
            className="gridActionCellEditSingle"
            onClick={() =>
              navigate(`/addcropSeasonRegistration`, {
                state: {
                  year: params.row.cropYear,
                  cultureNameEdit: params.row.cultureId,
                  editSeason: params.row.season,
                  status: params.row.isActive,
                  edit: true,
                  editSeasonId: params.row.cropSeasonId,
                },
              })
            }
          />,

          <GridActionsCellItem
            icon={<img src={iconView} height="30px" width="30px" />}
            className="gridActionCell gridActionCellEdit"
            label="Details"
            onClick={() =>
              navigate(`/addcropSeasonRegistration`, {
                state: {
                  year: params.row.cropYear,
                  cultureNameEdit: params.row.cultureId,
                  editSeason: params.row.season,
                  status: params.row.isActive,
                  edit: false,
                  details: true,
                  editSeasonId: params.row.cropSeasonId,
                },
              })
            }
            showInMenu
          />,
          <GridActionsCellItem
            icon={<img src={iconEdit} height="30px" width="30px" />}
            className="gridActionCell gridActionCellEdit"
            label="Edit"
            onClick={() =>
              navigate(`/addcropSeasonRegistration`, {
                state: {
                  year: data.cropYear,
                  cultureNameEdit: data.cultureId,
                  editSeason: data.season,
                  status: data.isActive,
                  edit: true,
                  editSeasonId: data.cropSeasonId,
                },
              })
            }
            showInMenu
          />,
          // <GridActionsCellItem
          //   icon={<img src={iconDelete} height="30px" width="30px" />}
          //   label="Delete"
          //   className="gridActionCell gridActionCellDelete"

          //   showInMenu
          // />,
        ];
      },
    },
  ];
  const callCropSeasonApi = (pNumber, size) => {
    setLoading(true);
    setRecordsError(false);

    CropSeasonService.getCropSeasons({
      pageNumber: pNumber,
      size: size,
    })
      .then((res) => {
        const rows = res?.data?.response?.cropSeason.map(
          (cropseason, index) => ({
            id: index + 1,
            season: cropseason.season,
            cropYear: cropseason.cropYear,
            cultureName: cropseason.cultureName,
            cultureId: cropseason.cultureId,
            cropSeasonId: cropseason.cropSeasonId,
            countryCode: cropseason.countryCode,
            status: cropseason.status,
          })
        );
        setCurrentPage(pNumber);
        setItemsPerPage(size);
        setLoading(false);
        setRecordsError(false);
        setRows(rows);
        setData(res?.data?.response);
        setSeasonData(res?.data?.response?.cropSeason);
        setTotalItems(res?.data?.response?.totalItems);
        if (res?.status === 404) {
          setCurrentPage(0);
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        if (error?.response?.status === 404) {
          setCurrentPage(0);
        }
      });
  };

  const paginate = (pageNumber, itemsPerPage) => {
    callCropSeasonApi(pageNumber - 1, itemsPerPage);
    // callCropSeasonSearchApi(pageNumber - 1, itemsPerPage)
  };

  useEffect(() => {
    callCropSeasonApi(currentPage, itemsPerPage);
  }, []);

  const handleClear = (name) => {
    setFilterParams({
      ...filterSeason,
      [name]: "",
    });
  };

  useEffect(() => {
    SeasonRegistrationService.getCropYears()
      .then((response) => {
        // Extract crop years from response
        const cropYearsFromApi = response.data.response.map(
          (item) => item.cropYear
        );
        setCropYears(cropYearsFromApi);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    CultureService.getMenuItems()
      .then((response) => {
        console.log("Culture Data" + response.data);
        // Extract CultureName from response
        const cultureFromApi = response.data.response.map(
          (item) => item.cuCultureName
        );
        setCultures(cultureFromApi);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilterParams({
      ...filterSeason,
      [name]: value,
    });
    setLoading(true);
  };

  const handleSearch = useCallback(
    debounce(() => {
      const cleanedParams = cleanSearchParams(filterSeason);

      if (
        Object.keys(cleanedParams).length === 1 &&
        cleanedParams.countryCode
      ) {
        callCropSeasonApi(currentPage, itemsPerPage);
        return;
      }

      CropSeasonService.getCropSeasonsSearch({
        searchParams: cleanedParams,
        pageNumber: currentPage,
        size: itemsPerPage,
      })
        .then((res) => {
          const rows = res?.data?.response?.cropSeason.map(
            (cropseason, index) => ({
              id: index + 1,
              season: cropseason.season,
              cropYear: cropseason.cropYear,
              cultureName: cropseason.cultureName,
              cultureId: cropseason.cultureId,
              cropSeasonId: cropseason.cropSeasonId,
              countryCode: cropseason.countryCode,
              status: cropseason.status,
            })
          );
          setCurrentPage(currentPage);
          setItemsPerPage(itemsPerPage);
          setLoading(false);
          setRecordsError(false);
          setRows(rows);
          setData(res?.data?.response);
          setTotalItems(res?.data?.response?.totalItems);
          setSeasonData(res?.data?.response?.cropSeason);
          if (res?.status === 404) {
            setCurrentPage(0);
          }
        })
        .catch((error) => {
          setRecordsError(true);
          console.log(error);
          setData(null);
          if (error?.response?.status === 404) {
            setCurrentPage(0);
          }
        });
    }, 500),
    [
      filterSeason.status,
      filterSeason.cultureName,
      filterSeason.cropYear,
      currentPage,
      itemsPerPage,
    ]
  );

  useEffect(() => {
    if (Object.keys(cleanSearchParams(filterSeason)).length > 0) {
      handleSearch();
    } else {
      console.log("Api");
      callCropSeasonApi(currentPage, itemsPerPage);
    }

    return () => {
      handleSearch.cancel();
    };
  }, [filterSeason, currentPage, itemsPerPage, handleSearch]);

  const cleanSearchParams = (params) => {
    const cleanedParams = { ...params };
    Object.keys(cleanedParams).forEach((key) => {
      if (cleanedParams[key] === "") {
        delete cleanedParams[key];
      } else if (cleanedParams[key] === "Active") {
        cleanedParams[key] = true;
      } else if (cleanedParams[key] === "Inactive") {
        cleanedParams[key] = false;
      }
    });
    return cleanedParams;
  };

  const handleButtonClick = async () => {
    navigate("/addcropSeasonRegistration");
  };

  return (
    <Layout
      title={"Crop Season List"}
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      useNewPagination={true}
    >
      <RegistrationOptions>
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid
            item
            md={4}
            xs={10}
            sx={{
              display: { md: "flex", xs: "block" },
              alignItems: "center",
              gap: "2rem",
            }}
          >
            <FormControl sx={{ minWidth: "200px", minHeight: 50 }}>
              <TextField
                select
                id="cropYear"
                labelId="cy-select-label"
                label={"Years"}
                value={filterSeason.cropYear}
                name="cropYear"
                onChange={handleChange}
                error={!!errors.cropYear}
                SelectProps={{
                  IconComponent: ArrowDropDownIcon,
                  endAdornment: filterSeason.cropYear != "" && (
                    <CloseIcon
                      onClick={() => handleClear("cropYear")}
                      style={{
                        cursor: "pointer",
                        marginLeft: "auto",
                        paddingRight: "20px",
                      }}
                    />
                  ),
                }}
              >
                <MenuItem value="">Crop Year</MenuItem>
                {cropYears.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText error={true}>{errors.cropYear}</FormHelperText>
            </FormControl>
            <FormControl sx={{ minWidth: "200px", minHeight: 50 }}>
              <TextField
                select
                name="status"
                value={filterSeason.status}
                onChange={handleChange}
                label="Status"
                SelectProps={{
                  IconComponent: ArrowDropDownIcon,
                  endAdornment: (filterSeason.status === "Active" ||
                    filterSeason.status === "Inactive") && (
                    <CloseIcon
                      onClick={() => handleClear("status")}
                      style={{
                        cursor: "pointer",
                        marginLeft: "auto",
                        paddingRight: "20px",
                      }}
                    />
                  ),
                }}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </TextField>

              <FormHelperText error={true}>{errors.cropYear}</FormHelperText>
            </FormControl>
            <FormControl sx={{ minWidth: "200px", minHeight: 50 }}>
              <TextField
                select
                id="cultureName"
                labelId="tobacco-select-label"
                label={"Culture"}
                name="cultureName"
                onChange={handleChange}
                value={filterSeason.cultureName}
                error={!!errors.cultureName}
                SelectProps={{
                  IconComponent: ArrowDropDownIcon,
                  endAdornment: filterSeason.cultureName != "" && (
                    <CloseIcon
                      onClick={() => handleClear("cultureName")}
                      style={{
                        cursor: "pointer",
                        marginLeft: "auto",
                        paddingRight: "20px",
                      }}
                    />
                  ),
                }}
              >
                <MenuItem value="">Culture</MenuItem>
                {cultureData.map((culture) => (
                  <MenuItem key={culture} value={culture}>
                    {culture}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText error={true}>{errors.cultureName}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          style={{ justifyContent: "flex-end" }}
          item
          xs={12}
          md={12}
          xl={12}
        >
          <div>
            <Button
              sx={{
                width: { xs: "80%", md: 250 },
                borderRadius: 50,
                background: "#102A62",
              }}
              variant="contained"
              startIcon={<img src={plusIcon} alt="plus icon" />}
              onClick={handleButtonClick}
            >
              Add Crop Season
            </Button>
          </div>
        </Grid>
      </RegistrationOptions>
      {data && !recordsError ? (
        <DataContainer>
          {/* <DataTable data={data} rows={rows} columns={columns} /> */}
          <Table
            seasonData={seasonData}
            callCropSeasonApi={callCropSeasonApi}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
          />
        </DataContainer>
      ) : (
        <Typography variant="h6" align="center" color="textSecondary">
          {label.noDataMessage}
        </Typography>
      )}
    </Layout>
  );
};

export default GetCropSeasonRegistration;
