import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Modal,
} from "@mui/material";
import Layout from "components/structure/Layout";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React, { useCallback, useContext, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate,useLocation } from "react-router-dom";
import { useEffect } from "react";
import RedSwitch from "utils/RedSwitch";
import Divider from "components/common/GrowingDivider";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import arrowLeft from "assets/arrowLeft.svg";
import Save from "assets/SaveNew.svg";
import FarmerSegmentationService from "services/FarmerSegmentationService";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "context/snackBarContext";

const AddGroup = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [cropSeason, setCropSeason] = useState("");
  const [cropSeasonOptions, setCropSeasonOptions] = useState([]);
  const [cropSeasonId, setCropSeasonId] = useState("");
  const [cultureType, setCultureType] = useState("");
  const [cultureTypeOptions, setCultureTypeOptions] = useState([]);
  const [cultureTypeId, setCultureTypeId] = useState("");
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [considerMostVolume, setConsiderMostVolume] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [inputBoxes, setInputBoxes] = useState([
    { id: 1, cultureTypeId: "", priority: false },
  ]);
  const location = useLocation();
  const rowData = location.state?.rowData; // Access the row data
const [isEditMode, setIsEditMode] = useState(false);

useEffect(() => {
  if (rowData) {

    setIsEditMode(true);
    // Populate form fields with row data
    console.log(rowData,'rowData');
    setGroupName(rowData.groupName || "");
    setCropSeason(rowData.cropYear.toString() || "");

    setCropSeasonId(rowData.cropSeasonId || "");
    setIsActive(rowData.isActive || false);
    setConsiderMostVolume(!rowData.isCultureTypeWisePriority);
    
    // Populate culture type list
    if (rowData.farmerTobaccoCultureTypeLists && rowData.farmerTobaccoCultureTypeLists.length > 0) {
      const formattedInputBoxes = rowData.farmerTobaccoCultureTypeLists.map((item, index) => ({
        id: item.tobaccoCultureTypeId,
        cultureTypeId: item.cultureTypeId,
        cultureType:item.cultureName,
        priority: item.isPriority
      }));
      console.log(formattedInputBoxes,'formattedInputBoxes');
      setInputBoxes(formattedInputBoxes);
    }
  }
}, [rowData]);


  const handleSearchKeywordChange = (event) => {
    setGroupName(event.target.value);
  };
  const fetchCultureType = async () => {
    try {
      let response = await FarmerSegmentationService.getCultureType();

      setCultureTypeOptions(
        response.data.response.map((item) => ({
          label: item.ctDescription,
          value: item.ctDescription,
          cultureTypeId: item.ctCultureTypeId, // Assuming there's an id field
        }))
      );
      console.log("setCultureTypeOptions", setCultureTypeOptions);
    } catch (error) {
      console.error("Error fetching cultureTypeOptions data:", error);
    }
  };
  const fetchCropSeason = async () => {
    try {
      let response = await FarmerSegmentationService.getCropSeason();

      setCropSeasonOptions(
        response.data.response.map((item) => ({
          label: item.cropYear.toString(),
          value: item.cropYear.toString(),
          cropSeasonId: item.cropSeasonId, // Assuming there's an id field
        }))
      );
      console.log("setCropSeasonOptions", setCropSeasonOptions);
    } catch (error) {
      console.error("Error fetching cropSeasonOptions data:", error);
    }
  };
  useEffect(() => {
    fetchCropSeason()
    fetchCultureType();
  }, []);

  

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);
  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/farmersegmentation");
  };

  console.log('cultureTypeOptions',cultureTypeOptions)
  const handleSave = async () => {
    try {
      setLoading(true);

      const farmerTobaccoCultureTypeLists = inputBoxes.map((box) => ({
        createdDateTimeUTC: new Date().toISOString(),
        createdByUser: "SYSTEM",
        modifiedDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "SYSTEM",
        cultureTypeId: box.cultureTypeId,
        isPriority: box.priority,
        isDelete: false,
      }));


      const payload = {
        createdDateTimeUTC: new Date().toISOString(),
        createdByUser: "SYSTEM",
        modifiedDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "SYSTEM",
        groupName: groupName,
        cropSeasonId: cropSeasonId,
        isActive: isActive,
        isCultureTypeWisePriority: !considerMostVolume,
        isDelete: false,
        farmerTobaccoCultureTypeLists,
      };

      const response = await FarmerSegmentationService.PostData(payload);

  console.log(response,'response')
      if (response.data.statusCode === 200) {
        setMessage(t("RECORD SAVED SUCCESSFULLY!"));
        setSeverity("success");
        setOpenAlert(true);
        navigate("/farmersegmentation");
      } else {
        setMessage(t("FAILED TO SAVE RECORD!"));
        setSeverity("error");
        setOpenAlert(true);
      }
      
        
    } catch (error) {
      console.error("Error saving data:", error);
      setMessage(t("FAILED TO SAVED RECORD!"));
      setSeverity("error");
      setOpenAlert(true);
    } finally {
      setLoading(false);
    }
  };
  const handleUpdate = async () => {
    try {
      setLoading(true);

      const farmerTobaccoCultureTypeLists = inputBoxes.map((box) => ({
        createdDateTimeUTC: new Date().toISOString(),
        createdByUser: "SYSTEM",
        modifiedDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "SYSTEM",
        cultureTypeId: box.cultureTypeId,
        isPriority: box.priority,
        isDelete: false,
        tobaccoCultureTypeId:box.id
      }));


      const payload = {
        createdDateTimeUTC: new Date().toISOString(),
        createdByUser: "SYSTEM",
        modifiedDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "SYSTEM",
        groupName: groupName,
        cropSeasonId: cropSeasonId,
        isActive: isActive,
        isCultureTypeWisePriority: !considerMostVolume,
        isDelete: false,
        farmerTobaccoCultureTypeLists,
        ftgpFarmerTobaccoPriorityId:rowData.ftgpFarmerTobaccoPriorityId
      };

      const response = await FarmerSegmentationService.updateData(payload);

  console.log(response,'response')
      if (response.data.statusCode === 200) {
        setMessage(t("RECORD UPDATED SUCCESSFULLY!"));
        setSeverity("success");
        setOpenAlert(true);
        navigate("/farmersegmentation");
      } else {
        setMessage(t("FAILED TO SAVE RECORD!"));
        setSeverity("error");
        setOpenAlert(true);
      }
      
        
    } catch (error) {
      console.error("Error saving data:", error);
      setMessage(t("FAILED TO SAVED RECORD!"));
      setSeverity("error");
      setOpenAlert(true);
    } finally {
      setLoading(false);
    }
  };

  const handleAddBox = () => {
    const newBox = { id: Date.now(), cultureTypeId: "", priority: false };
    setInputBoxes((prevBoxes) => [...prevBoxes, newBox]);
  };

  const handleDelete = (id) => {
    setInputBoxes((prevBoxes) => prevBoxes.filter((box) => box.id !== id));
  };

  const handleCultureTypeChange = (id, newValue) => {
    setInputBoxes((prevBoxes) =>
      prevBoxes.map((box) =>
        box.id === id
          ? { ...box, cultureTypeId: newValue?.cultureTypeId || "" }
          : box
      )
    );
  };

  let edit = true;

  return (
    <Layout
      title={t("Farmer Segmetation - Tobacco Prioroty Definition")}
      background="#ffffff"
    >
      <Box sx={{ flexGrow: 1, bgcolor: "#f5f5f5", p: 2 }}>
        <Grid item xs={12} sx={{ paddingTop: "0px", paddingLeft: "0px" }}>
          <Typography variant="h8" display="flex">
            <ArrowDropDownIcon />
            {t("Form")}
          </Typography>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                label={t("Group Name")}
                value={groupName}
                onChange={handleSearchKeywordChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "7px", // Adjust padding for the input content
                    minHeight: "50px", // Adjust the overall height
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em", // Adjust label spacing if needed
                  },
                  width: "100%",
                }}
                InputProps={{
                  style: { height: "50px" }, // Directly setting the height
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  cropSeasonOptions.find(
                    (option) => option.value === cropSeason
                  ) || null
                }
                onChange={(event, newValue) => {
                  if (newValue) {
                    setCropSeason(newValue.value);
                    setCropSeasonId(newValue.cropSeasonId); // This will trigger useEffect
                  } else {
                    setCropSeason("");
                    setCropSeasonId(""); // This will trigger useEffect
                  }
                }}
                options={cropSeasonOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("cropSeason")}
                    fullWidth
                    placeholder="Select an Option"
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      "& .MuiInputBase-input::placeholder": {
                        opacity: 1,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={2} sx={{ display: "flex" }}>
            <Typography>Status</Typography>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>{t("Inactive")}</Box>
              <RedSwitch
                color="primary"
                checked={isActive}
                onChange={(event) => setIsActive(event.target.checked)}
              />
              <Box>{t("Active")}</Box>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant="h8" display="flex">
            {t(
              "Use this rule for determine what Tobacco type is used in segmentation:"
            )}
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ display: "flex", mt: 1 }}>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>{t("Select priority")}</Box>
          <RedSwitch 
            color="primary"
            checked={considerMostVolume}
            onChange={(e) => setConsiderMostVolume(e.target.checked)}
          />
          <Box>{t("Consider type with most volume")}</Box>
        </Box>
      </Grid>

      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          width: "100%",
          mt: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between", // Align items to the left and right
            alignItems: "center",
            mb: 0, // Add some margin for spacing
            width: "100%", // Ensure the Box takes up full width
          }}
        >
          <Typography variant="h6" sx={{ flexGrow: 1, alignItems: "center" }}>
            {t("Culture Type List")}
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddCircleIcon />}
            sx={{
              minWidth: 95,
              borderRadius: 1,
              background: "#004F9F",
              height: { xs: "40px", md: "44px" },
            }}
            onClick={handleAddBox}
          >
            {t("TechnicalRecomendation.addNewItemsButton")}
          </Button>
        </Box>

        {/* Full Width Divider */}
        <Divider sx={{ width: "100%", mt: 0 }} />
      </Box>

      {inputBoxes?.map((box) => (
        <Box
          key={box.id}
          sx={{
            background: "#EFEFEF",
            borderRadius: "5px",
            p: 2,
            maxWidth: "100%",
            margin: "auto",
            mt: 1,
            mb: 2,
          }}
        >
          <Grid container spacing={2} >
            <Grid item xs={8}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    cultureTypeOptions.find(
                      (option) => option.cultureTypeId === box.cultureTypeId
                    ) || null
                  }
                  onChange={(event, newValue) =>
                    handleCultureTypeChange(box.id, newValue)
                  }
                  options={cultureTypeOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("cultureType")}
                      fullWidth
                      placeholder="Select an Option"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          opacity: 1,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            {!considerMostVolume && (
              <Grid item xs={2} display="flex" >
                <Typography sx={{display:'flex',alignItems:'flex-start',}}>Priority</Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box>{t("No")}</Box>
                  <RedSwitch
                    color="primary"
                    checked={box.priority}
                    onChange={(e) =>
                      setInputBoxes((prev) =>
                        prev.map((item) =>
                          item.id === box.id
                            ? { ...item, priority: e.target.checked }
                            : item
                        )
                      )
                    }
                  />
                  <Box>{t("Yes")}</Box>
                </Box>
              </Grid>
            )}

            <Grid
              item
              xs={2}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <img
                src={_ButtonDelete_}
                alt="Delete"
                style={{
                  cursor: "pointer",
                  width: "24px",
                  height: "24px",
                  marginRight: "8px",
                }}
                onClick={() => handleDelete(box.id)}
              />
            </Grid>
          </Grid>
        </Box>
      ))}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between", // Ensures buttons are placed on opposite corners
          position: "fixed", // Fix the buttons in place
          width: "100%", // Make the Box take full width
          left: 0, // Ensure it starts from the left edge
          bottom: "19px", // Place at the bottom of the screen
          background: "white",
          zIndex: 999,
          padding: "0.5rem",
          borderTop: "1px solid var(--Gray-1, #E9EBEE)",
        }}
      >
        {/* Cancel Button - Left bottom corner */}
        <Button
          style={{
            backgroundColor: "#737D86",
            padding: "6px 16px",
            borderRadius: "4px",
            marginLeft: "30px",
          }}
          variant="contained"
          onClick={ handleOpenModal} // Open the modal on click
          disabled={loading}
          startIcon={<img src={arrowLeft} alt="Arrow" />}
        >
          {loading && <CircularProgress size={20} />}
          { t("cancel")}
        </Button>

        {/* Modal confirmation */}
        <Modal
          open={open}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "7px",
              width: "585px",
              margin: "auto",
              mt: 25,
              height: "200px",
            }}
          >
            <Box
              sx={{
                background: "#102A62",
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderTopLeftRadius: "7px",
                borderTopRightRadius: "7px",
              }}
            >
              <Typography
                sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
              >
                Cancel Operation
              </Typography>
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, fontWeight: 400 }}
              >
                {t("cancelMessage")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
                mb: 1,
              }}
            >
              <Button
                onClick={handleCloseModal}
                sx={{
                  background: "#737D86",
                  width: "100px",
                  color: "#ffffff",
                  "&:hover": { background: "#737D86" },
                }}
              >
                {t("no")}
              </Button>
              <Button
                onClick={handleDeleteConfirm}
                sx={{
                  background: "#004F9F",
                  width: "100px",
                  color: "#ffffff",
                  "&:hover": { background: "#004F9F" },
                }}
              >
                {t("yes")}
              </Button>
            </Box>
          </Box>
        </Modal>

    
          <Button
            sx={{
              padding: "6px 16px",
              borderRadius: "4px",
              backgroundColor: "#EF7D00",
              color: "white",
              "&:hover": {
                backgroundColor: "#EF7D00",
              },
              marginRight: "30px",
            }}
            disabled={loading}
            startIcon={<img src={Save} alt="Right Arrow" />}
            variant="contained"
            onClick={rowData?.ftgpFarmerTobaccoPriorityId ? handleUpdate : handleSave}
          >
            {loading ? <CircularProgress size={20} /> : t("save")}
          </Button>
      </Box>
    </Layout>
  );
};

export default AddGroup;
