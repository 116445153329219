import React from 'react';
import CkEditorComponent from './CkEditorComponent';
import MyEditor from './MyEditor';
import Layout from "components/structure/Layout";

const EditorHome = () => {
  return (
    <Layout
      title={"Editor"}
      background="#ffffff"
    >
    {/* <CkEditorComponent/> */}
    <MyEditor />
    </Layout>
  );
};

export default EditorHome;
