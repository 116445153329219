import React, { useState, useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import {
  Drawer,
  CircularProgress,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import SearchIcon from "@mui/icons-material/Search";
import MenuStructureVisulization from "../../services/MenuStructureVisulization";
import { ReactComponent as MenuArrowDown } from "assets/MenuArrowDown.svg";
import { ReactComponent as MenuArrowUp } from "assets/MenuArrowUp.svg";
import MenuArrowLeft from "assets/MenuArrowLeft.svg";
import FavoutiteStarLine from "assets/FavouriteStarLine.svg";
import FavoutiteSolidLine from "assets/FavouriteSolidLine.svg";
import StarFill from "assets/star-fill.svg";

const Sidebar = ({ open, onClose }) => {
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  const [selectedSubSubMenu, setSelectedSubSubMenu] = useState(null);
  const [subMenus, setSubMenus] = useState([]);
  const [subSubMenus, setSubSubMenus] = useState([]);
  const [expandedSubMenus, setExpandedSubMenus] = useState({});
  const [isSubMenuDrawerOpen, setIsSubMenuDrawerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [favoriteSubMenus, setFavoriteSubMenus] = useState({});
  const [favoriteMenuItems, setFavoriteMenuItems] = useState({});

  // Filtered menu items based on search term
  const filteredMenuItems = useMemo(() => {
    if (!searchTerm) return menuItems;
    return menuItems.filter(item => 
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [menuItems, searchTerm]);

  // Filtered submenus based on search term
  const filteredSubMenus = useMemo(() => {
    if (!searchTerm) return subMenus;
    return subMenus.filter(subMenu => 
      subMenu.menuName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [subMenus, searchTerm]);

  // Filtered sub-submenus based on search term
  const filteredSubSubMenus = useMemo(() => {
    if (!searchTerm) return subSubMenus;
    return subSubMenus.filter(subSubMenu => 
      subSubMenu.menuName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [subSubMenus, searchTerm]);

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        setLoading(true);
        const response = await MenuStructureVisulization.getMenu();
        const menu = response?.data?.response || [];
        console.log("menu : ", menu);

        // Create default Favorites item
        const favoritesItem = {
          id: 'favorites',
          name: 'Favorites',
          hasSubMenus: true,
          icon: StarFill, // Using the imported StarFill icon
        };

        const sortedMenuItems = [
          favoritesItem, // Add Favorites as the first item
          ...menu
            .filter((item) => !item.parentMenuId) // Only top-level menus
            .map((item) => ({
              id: item.menuId,
              name: item.menuName || "Unnamed Menu",
              hasSubMenus: true, // Always true to trigger API call
              icon: item.menuIcon, // Add icon to the menu item
            }))
        ];

        setMenuItems(sortedMenuItems);
      } catch (err) {
        console.error("Error fetching menu items:", err);
        setError("Failed to fetch menu items");
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchMenuItems();
    }
  }, [open]);

  useEffect(() => {
    const fetchFavorites = async () => {
      if (selectedMenuItem?.id === 'favorites') {
        try {
          const response = await MenuStructureVisulization.getFavorites();
          const favorites = response?.data?.response || [];
          
          // Filter favorites where mfuFavorites is true
          const trueFavorites = favorites.filter(fav => fav.mfuFavorites === true);
          
          // Create a map of existing favorites with their details
          const existingFavoritesMap = trueFavorites.reduce((acc, fav) => {
            acc[fav.menuId] = {
              menuFavoriteId: fav.menuFavoriteId,
              menuName: fav.menuName,
            };
            return acc;
          }, {});

          setFavoriteMenuItems(existingFavoritesMap);
          
          // Mark which submenus are favorites
          const favoritesMenuIds = trueFavorites.map(fav => fav.menuId);
          
          // Update favorite submenu state
          const favoriteSubMenusState = favoritesMenuIds.reduce((acc, menuId) => {
            acc[menuId] = true;
            return acc;
          }, {});
          
          setFavoriteSubMenus(favoriteSubMenusState);

          // Set first favorite as selected by default
          const favoritesSubMenus = trueFavorites.map(fav => ({
            menuId: fav.menuFavoriteId,
            menuName: fav.menuName,
          }));

          setSubMenus(favoritesSubMenus);
          
          if (favoritesSubMenus.length > 0) {
            setSelectedSubMenu(favoritesSubMenus[0]);
          }
        } catch (err) {
          console.error("Error fetching favorites:", err);
          setSubMenus([]);
          setSelectedSubMenu(null);
        }
      }
    };

    fetchFavorites();
  }, [selectedMenuItem]);

  const fetchSubMenus = async (menuId) => {
    try {
      console.log("Fetching submenus for menuId:", menuId);
      const response = await MenuStructureVisulization.getSubMenu({
        parentMenuId: menuId,
      });

      const fetchedSubMenus = response?.data?.response || [];
      console.log("Fetched Submenus:", fetchedSubMenus);
      setSubMenus(fetchedSubMenus);

      // Reset lower-level menus
      setSelectedSubMenu(null);
      setSelectedSubSubMenu(null);
      setSubSubMenus([]);

      // Set first submenu as selected by default
      if (fetchedSubMenus.length > 0) {
        setSelectedSubMenu(fetchedSubMenus[0]);
      }
    } catch (err) {
      console.error("Error fetching submenus:", err);
      setSubMenus([]);
      setSelectedSubMenu(null);
    }
  };

  const fetchSubSubMenus = async (subMenuId) => {
    try {
      console.log("Fetching sub-submenus for subMenuId:", subMenuId);
      const response = await MenuStructureVisulization.getSubMenu({
        parentMenuId: subMenuId,
      });

      const fetchedSubSubMenus = response?.data?.response || [];
      console.log("Fetched Sub-Submenus:", fetchedSubSubMenus);
      setSubSubMenus(fetchedSubSubMenus);

      // Reset sub-submenu selection
      setSelectedSubSubMenu(null);

      // Set first sub-submenu as selected by default
      if (fetchedSubSubMenus.length > 0) {
        setSelectedSubSubMenu(fetchedSubSubMenus[0]);
      }
    } catch (err) {
      console.error("Error fetching sub-submenus:", err);
      setSubSubMenus([]);
      setSelectedSubSubMenu(null);
    }
  };

  const handleMenuItemClick = (item) => {
    if (selectedMenuItem && selectedMenuItem.id === item.id) {
      // Reset all menu selections
      setSelectedMenuItem(null);
      setSelectedSubMenu(null);
      setSelectedSubSubMenu(null);
      setSubMenus([]);
      setSubSubMenus([]);
      setIsSubMenuDrawerOpen(false);
      onClose();
    } else {
      if (item.id === 'favorites') {
        setSelectedMenuItem(item);
        setIsSubMenuDrawerOpen(true);
        onClose();
      } else {
        setSelectedMenuItem(item);
        fetchSubMenus(item.id);
        onClose();
        setIsSubMenuDrawerOpen(true);
      }
    }
  };

  const handleSubMenuDrawerClose = () => {
    // Reset menu selections when closing submenu drawer
    setIsSubMenuDrawerOpen(false);
    setSelectedMenuItem(null);
    setSelectedSubMenu(null);
    setSelectedSubSubMenu(null);
    setSubMenus([]);
    setSubSubMenus([]);
  };

  const toggleSubMenuExpansion = (subMenuId) => {
    setExpandedSubMenus((prev) => ({
      ...prev,
      [subMenuId]: !prev[subMenuId],
    }));
  };

  const handleSubMenuClick = (subMenu) => {
    setSelectedSubMenu(subMenu);
    fetchSubSubMenus(subMenu.menuId);
    toggleSubMenuExpansion(subMenu.menuId);
  };

  const handleSubSubMenuClick = (subSubMenu) => {
    setSelectedSubSubMenu(subSubMenu);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleBackToMainMenu = () => {
    setIsSubMenuDrawerOpen(false);
    setSelectedMenuItem(null);
    setSelectedSubMenu(null);
    setSelectedSubSubMenu(null);
    setSubMenus([]);
    setSubSubMenus([]);
    onClose(); // Explicitly call onClose to reopen the main sidebar
  };

  const toggleFavoriteSubMenu = async (subMenu) => {
    try {
      // Determine if this submenu is currently a favorite
      const isCurrentlyFavorite = favoriteSubMenus[subMenu.menuId];
      
      // Prepare the payload
      const payload = {
        batOneID: 85193243, // Hard-coded user ID as in the example
        menuId: subMenu.menuId,
        mfuFavorites: !isCurrentlyFavorite,
        isDelete: false, 
        createdByUser: "Mayur", 
        modifiedByUser: "Mayur", 
        languageId: "3fa85f64-5717-4562-b3fc-2c963f66afa6", 
        countryId: "3fa85f64-5717-4562-b3fc-2c963f66afa6", 
      };

      // Check if the menu item already exists in favorites and has a menuFavoriteId
      const existingFavorite = favoriteMenuItems[subMenu.menuId];
      if (existingFavorite && existingFavorite.menuFavoriteId) {
        payload.menuFavoriteId = existingFavorite.menuFavoriteId;
      }

      // Call the save favorites API
      const response = await MenuStructureVisulization.saveFavorites(payload);

      // Update local state for favorites
      const updatedFavoriteSubMenus = { ...favoriteSubMenus };
      const updatedFavoriteMenuItems = { ...favoriteMenuItems };

      if (!isCurrentlyFavorite) {
        // Adding to favorites
        updatedFavoriteSubMenus[subMenu.menuId] = true;
        updatedFavoriteMenuItems[subMenu.menuId] = {
          ...payload,
          menuFavoriteId: response.data?.response?.menuFavoriteId
        };
      } else {
        // Removing from favorites
        delete updatedFavoriteSubMenus[subMenu.menuId];
        delete updatedFavoriteMenuItems[subMenu.menuId];
      }

      setFavoriteSubMenus(updatedFavoriteSubMenus);
      setFavoriteMenuItems(updatedFavoriteMenuItems);

      // Optionally, refresh favorites if in favorites view
      if (selectedMenuItem?.id === 'favorites') {
        const favoritesResponse = await MenuStructureVisulization.getFavorites();
        const favorites = favoritesResponse?.data?.response || [];
        
        const favoritesSubMenus = favorites.map(fav => ({
          menuId: fav.menuFavoriteId,
          menuName: fav.menuName,
        }));

        setSubMenus(favoritesSubMenus);
      }
    } catch (error) {
      console.error("Error toggling favorite:", error);
      // Optionally show an error message to the user
    }
  };

  return (
    <>
      <StyledDrawer
        anchor="left"
        open={open}
        onClose={onClose}
        elevation={0}
        BackdropProps={{
          style: {
            backgroundColor: "transparent",
          },
        }}
        sx={{
          position: "fixed",
          zIndex: 1100,
          top: "64px",
          height: "calc(100vh - 30px - 56px)",
          "& .MuiDrawer-paper": {
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            display: "flex",
            flexDirection: "row",
            paddingLeft: "20px",
          },
        }}
      >
        {/* First Level - Menu Items */}
        <OptionsSidebarContainer>
          <SearchContainer>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search here"
              value={searchTerm}
              onChange={handleSearchChange}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "24px",
                  backgroundColor: "#F8F8F8",
                  "& fieldset": {
                    borderColor: "#E0E0E0", // Neutral border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#C0C0C0", // Slightly darker on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent", // Remove blue focus border
                  },
                },
                marginBottom: "10px",
              }}
            />
          </SearchContainer>
          {loading ? (
            <LoadingContainer>
              <CircularProgress />
              <Typography variant="body2">Loading menu...</Typography>
            </LoadingContainer>
          ) : error ? (
            <ErrorContainer>
              <Typography variant="body2" color="error">
                {error}
              </Typography>
            </ErrorContainer>
          ) : (
            <OptionsList>
              {filteredMenuItems.map((item) => (
                <OptionItem
                  key={item.id}
                  onClick={() => handleMenuItemClick(item)}
                  selected={selectedMenuItem?.id === item.id}
                >
                  <OptionItemIcon>
                    {item.icon ? (
                      <MenuItemImage
                        src={`${item.icon}${
                          process.env.REACT_APP_SAS
                            ? `?${process.env.REACT_APP_SAS}`
                            : ""
                        }`}
                        alt={item.name}
                      />
                    ) : (
                      <SettingsIcon />
                    )}
                  </OptionItemIcon>
                  <OptionItemText>{item.name}</OptionItemText>
                </OptionItem>
              ))}
            </OptionsList>
          )}
        </OptionsSidebarContainer>
      </StyledDrawer>

      {/* Submenu Drawer - Replaces previous inline submenu */}
      <SubMenuDrawer
        anchor="left"
        open={isSubMenuDrawerOpen}
        onClose={handleSubMenuDrawerClose}
        sx={{
          position: "fixed",
          zIndex: 1100,
          top: "64px",
          height: "calc(100vh - 30px - 56px)",
          "& .MuiDrawer-paper": {
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "350px",
            overflow: "hidden",
          },
        }}
      >
        <SearchContainer>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search here"
            value={searchTerm}
            onChange={handleSearchChange}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "24px",
                backgroundColor: "#F8F8F8",
                "& fieldset": {
                  borderColor: "#E0E0E0", // Neutral border color
                },
                "&:hover fieldset": {
                  borderColor: "#C0C0C0", // Slightly darker on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent", // Remove blue focus border
                },
              },
              marginBottom: "10px",
            }}
          />
        </SearchContainer>
        {selectedMenuItem && (
          <SubMenuContainer>
            <SubMenuIconColumn>
              {menuItems.map((item) => (
                <OptionItem
                  key={item.id}
                  selected={selectedMenuItem?.id === item.id}
                  onClick={() => {
                    setSelectedMenuItem(item);
                    fetchSubMenus(item.id);
                  }}
                >
                  <OptionItemIcon>
                    {item.icon ? (
                      <MenuItemImage
                        src={`${item.icon}${
                          process.env.REACT_APP_SAS
                            ? `?${process.env.REACT_APP_SAS}`
                            : ""
                        }`}
                        alt={item.name}
                      />
                    ) : (
                      <SettingsIcon />
                    )}
                  </OptionItemIcon>
                </OptionItem>
              ))}
            </SubMenuIconColumn>
            <SubMenuContentColumn>
              <SubMenuHeader>
                <BackButton onClick={handleBackToMainMenu}>
                  <img
                    src={MenuArrowLeft}
                    alt="Back to Main Menu"
                    style={{
                      width: "6.5px",
                      height: "11px",
                      color: "#0E2B63",
                    }}
                  />
                </BackButton>
                <SubMenuTitle>{selectedMenuItem.name}</SubMenuTitle>
              </SubMenuHeader>

              <SubMenuList>
                {filteredSubMenus.map((subMenu) => (
                  <React.Fragment key={subMenu.menuId}>
                    <SubMenuItem
                      onClick={() => handleSubMenuClick(subMenu)}
                      selected={selectedSubMenu?.menuId === subMenu.menuId}
                    >
                      <OptionItemIcon>
                        <FavoriteIconButton 
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent parent click event
                            toggleFavoriteSubMenu(subMenu);
                          }}
                        >
                          <img
                            src={favoriteSubMenus[subMenu.menuId] 
                              ? FavoutiteSolidLine 
                              : FavoutiteStarLine}
                            alt="Favorite"
                            style={{
                              width: "24px",
                              height: "24px",
                              marginRight: "10px",
                            }}
                          />
                        </FavoriteIconButton>
                      </OptionItemIcon>
                      <OptionItemText
                        style={{
                          color: "#0E2B63",
                          fontFamily: "Montserrat",
                          fontWeight: 600,
                          fontStyle: "normal",
                          fontSize: "14px",
                        }}
                      >
                        {subMenu.menuName}
                      </OptionItemText>
                      <MenuIconContainer>
                        {expandedSubMenus[subMenu.menuId] ? (
                          <MenuArrowUp />
                        ) : (
                          <MenuArrowDown />
                        )}
                      </MenuIconContainer>
                    </SubMenuItem>

                    {/* Third Level - Sub-Submenus (dropdown) */}
                    {selectedSubMenu?.menuId === subMenu.menuId &&
                      filteredSubSubMenus.length > 0 &&
                      expandedSubMenus[subMenu.menuId] && (
                        <SubSubMenuContainer
                          style={{ backgroundColor: "#E9EBEE" }}
                        >
                          {filteredSubSubMenus.map((subSubMenu) => (
                            <SubSubMenuItem
                              key={subSubMenu.menuId}
                              onClick={() => handleSubSubMenuClick(subSubMenu)}
                              selected={
                                selectedSubSubMenu?.menuId === subSubMenu.menuId
                              }
                            >
                              <OptionItemText
                                style={{
                                  color: "#3B454F",
                                  fontFamily: "Montserrat",
                                  fontWeight: 500,
                                  fontStyle: "normal",
                                  fontSize: "14px",
                                }}
                              >
                                {subSubMenu.menuName}
                              </OptionItemText>
                              <OptionItemIcon>
                                <FavoriteIconButton 
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevent parent click event
                                    toggleFavoriteSubMenu(subSubMenu);
                                  }}
                                >
                                  <img
                                    src={favoriteSubMenus[subSubMenu.menuId] 
                                      ? FavoutiteSolidLine 
                                      : FavoutiteStarLine}
                                    alt="Favorite"
                                    style={{
                                      width: "24px",
                                      height: "24px",
                                      marginRight: "10px",
                                    }}
                                  />
                                </FavoriteIconButton>
                              </OptionItemIcon>
                            </SubSubMenuItem>
                          ))}
                        </SubSubMenuContainer>
                      )}
                  </React.Fragment>
                ))}
              </SubMenuList>
            </SubMenuContentColumn>
          </SubMenuContainer>
        )}
      </SubMenuDrawer>
    </>
  );
};

// Styled components
const StyledDrawer = styled(Drawer)`
  & .MuiDrawer-paper {
    padding-left: 10px;
    border-right: 1px solid var(--Gray-1, #e9ebee);
    background: var(--Pure-White, #fff);
    box-shadow: 1px 0px 15.3px -2px rgba(0, 0, 0, 0.25);
  }
`;

const OptionsSidebarContainer = styled.div`
  width: 300px;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

const OptionsList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
`;

const OptionItem = styled.div`
  width: 90%;
  padding: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 25px;
  font-size: 0.875rem;
  background-color: ${(props) => (props.selected ? "#f0f0f0" : "transparent")};
`;

const OptionItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
`;

const OptionItemText = styled.div`
  flex-grow: 1;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.5px;
  line-height: 24px;
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
  color: #0e2b63;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 10px;
  padding: 15px;

  & .MuiTypography-body2 {
    font-size: 0.75rem;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 15px;
  text-align: center;
  font-size: 0.875rem;
`;

const SubMenuContainer = styled.div`
  width: 100%;
  height: 100%;
  background: var(--Gray-1, #e9ebee);
  display: flex;
  border-left: 1px solid #e0e0e0;
  overflow: hidden;
`;

const SubMenuIconColumn = styled.div`
  width: 15%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid #e0e0e0;
`;

const SubMenuContentColumn = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const SubMenuIconItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin: 5px;
  cursor: pointer;
  border-radius: 8px;
  background-color: ${(props) => (props.selected ? "#e0e0e0" : "transparent")};

  &:hover {
    background-color: #e0e0e0;
  }
`;

const SubMenuHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  font-weight: bold;
  font-size: 0.85rem;
`;

const SubMenuTitle = styled.div`
  display: flex;
  align-items: center;
  width: 95%;
  overflow: hidden;
  gap: 8px;
  color: var(--Grey-6, #3b454f);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
`;

const SubMenuList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
`;

const SubMenuItem = styled(OptionItem)`
  background-color: ${(props) =>
    props.selected ? "transparent" : "transparent"};
  font-size: 0.8rem;
  padding: 10px;
  gap: 5px;
  width: 95%;
  margin: 0 auto;
  box-sizing: border-box;
`;

const SubSubMenuContainer = styled.div`
  background-color: #f9f9f9;
  padding-left: 35px;
`;

const SubSubMenuItem = styled(OptionItem)`
  background-color: ${(props) =>
    props.selected ? "transparent" : "transparent"};
  padding-left: 20px;
`;

const MenuIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 2px;
  min-width: 24px;
  justify-content: flex-start;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-right: 10px;
  margin-left: 10px;
`;

const SubMenuDrawer = styled(Drawer)`
  '& .MuiDrawer-paper': {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '350px',
    overflow: 'hidden',
  }
`;

// Add a new styled component for the search container
const SearchContainer = styled.div`
  padding: 20px;
`;

// Add a new styled component for menu item images
const MenuItemImage = styled.img`
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 4px;
`;

const FavoriteIconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Sidebar;
