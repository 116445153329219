import React from 'react';
import {
  Grid,
  TextField,
  Paper,
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  ListItem,
  List
} from '@mui/material';
import { useTranslation } from "react-i18next";
import Divider from "components/common/GrowingDivider";

const FormData = () => {
  const { t } = useTranslation();
  const formFields = [
    {
      id: 'fiscalId',
      label: 'Fiscal ID',
      value: '013.543.980-02',
      xs: 12,
      sm: 6,
      md: 2.4
    },
    {
      id: 'farmer',
      label: 'Farmer',
      value: '533112 - Marcos Silva',
      xs: 12,
      sm: 6,
      md: 2.4
    },
    {
      id: 'registry',
      label: 'Rural Environment Registry',
      value: '224.567.925.041',
      xs: 12,
      sm: 6,
      md: 2.4
    },
    {
      id: 'city',
      label: 'City',
      value: 'Santa Cruz do Sul',
      xs: 12,
      sm: 6,
      md: 2.4
    },
    {
      id: 'state',
      label: 'State',
      value: 'Rio Grande do Sul',
      xs: 12,
      sm: 6,
      md: 2.4
    },
    {
      id: 'cropSeason',
      label: 'Crop Season',
      value: '2024',
      xs: 12,
      sm: 6,
      md: 2.4
    },
    {
      id: 'fieldTechnician',
      label: 'Field Technician',
      value: 'Matheus Kist',
      xs: 12,
      sm: 6,
      md: 2.4
    },
    {
      id: 'esgStatus',
      label: 'ESG Status',
      value: 'Pending Approval',
      xs: 12,
      sm: 6,
      md: 2.4
    },
    {
      id: 'approvalDate',
      label: 'Approval/Reject Date',
      value: '',
      xs: 12,
      sm: 6,
      md: 2.4
    }
  ];
  const sections = [
    {
      title: 'Human and Labour Rights',
      items: [
        {
          text: 'Compliance with Human Rights: No extreme breaches on slavery, human trafficking, and severe abuse.',
          value: 'yes'
        },
        {
          text: 'No Workers\' Rights breaches.',
          value: 'yes'
        }
      ]
    },
    {
      title: 'Health & Safety',
      items: [
        {
          text: 'PPE provision, availability, and usage',
          value: 'yes'
        },
        {
          text: 'Safe CPA Storage',
          value: 'yes'
        }
      ]
    },
    {
      title: 'Farmers\' Livelihoods',
      items: [
        {
          text: 'Living Income Analysis - Action Plan (when applicable)',
          value: 'yes'
        }
      ]
    },
    {
      title: 'WASH (Water Access, Sanitation & Hygiene)',
      items: [
        {
          text: 'Availability for farmers and farm workers of safe and affordable drinking water source, access hand-washing facility with soap and safe and improved sanitation service.',
          value: 'no'
        }
      ]
    }
  ];

  return (
    <>
      <Paper sx={{ p: 2, backgroundColor: '#f5f5f5', mt:2 }}>
        <Grid container spacing={2}>
          {formFields.map((field) => (
            <Grid item xs={field.xs} sm={field.sm} md={field.md} key={field.id}>
              <TextField
                label={field.label}
                value={field.value}
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: true,
                  sx: { backgroundColor: '#ffffff' }
                }}
                size="small"
              />
            </Grid>
          ))}
        </Grid>

      </Paper>

      <Box sx={{ width: '100%', mt:5 }}>
        {sections.map((section, index) => (
          <Box key={index} sx={{ mb: 3 }}>
            <Divider title={t(`${section.title}`)}sx={{ borderColor: '#1976d2', borderWidth: 2, mb: 2 }} />

          
            <List sx={{ bgcolor: '#ffffff' }}>
              {section.items.map((item, itemIndex) => (
                <ListItem
                  key={itemIndex}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',

                    mt: 1,
                    mb: 1,
                    bgcolor: '#f5f5f5' ,
                    borderRadius: 1,

                  }}
                >
                  <Typography sx={{ flex: 1 }}>
                  {item.text}
                </Typography>
                  <FormControl>
                    <RadioGroup
                    row
                    value={item.value}
                    sx={{ ml: 2 }}
                  >
                      <FormControlLabel
                        value="yes"
                        control={<Radio disabled />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio disabled />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </ListItem>
              ))}
            </List>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default FormData;