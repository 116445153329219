import React, { useState, useEffect } from "react";
import {
  Box,
  Autocomplete,
  FormControl,
  Grid,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import AgronomicTechnologiesDashboard from "./AgronomicTechnologiesDashboard";
import AgronomicTechnologiesService from "services/AgronomicTechnologiesService";
import iconSearch from "assets/iconSearchs.svg";
import { useTranslation } from "react-i18next";

export default function AgronomicTechnologies() {
  const [cropSeason, setCropSeason] = useState("");
  const [geneticType, setGeneticType] = useState("");
  const [hierarchicalNode, setHierarchicalNode] = useState("");
  const [selectedHierarchicalNodes, setSelectedHierarchicalNodes] = useState(
    []
  );
  const [hierarchicalNodeIds, setHierarchicalNodeIds] = useState([]);
  const [cropSeasonOptions, setCropSeasonOptions] = useState([]);
  const [geneticTypeOptions, setGeneticTypeOptions] = useState([]);
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [data, setData] = useState({
    Soil: [
      {
        name: "Plot With Georeferencing",
        target: 1000,
        actual: 600,
        farmers: 300,
      },
      {
        name: "Plot With Georeferencing 2",
        target: 1000,
        actual: 500,
        farmers: 400,
      },
      {
        name: "Plot With Georeferencing",
        target: 1000,
        actual: 400,
        farmers: 500,
      },
      {
        name: "Plot With Georeferencing",
        target: 1000,
        actual: 100,
        farmers: 600,
      },
    ],
    Curing: [
      {
        name: "Plot With Georeferencing",
        target: 1000,
        actual: 200,
        farmers: 700,
      },
      {
        name: "Plot With Georeferencing 2",
        target: 1000,
        actual: 500,
        farmers: 800,
      },
      {
        name: "Plot With Georeferencing",
        target: 1000,
        actual: 1000,
        farmers: 900,
      },
      {
        name: "Plot With Georeferencing",
        target: 1000,
        actual: 300,
        farmers: 1000,
      },
    ],
    "Planting Type": [
      {
        name: "Plot With Georeferencing",
        target: 1000,
        actual: 400,
        farmers: 100,
      },
      {
        name: "Plot With Georeferencing 2",
        target: 1000,
        actual: 700,
        farmers: 200,
      },
      {
        name: "Plot With Georeferencing",
        target: 1000,
        actual: 800,
        farmers: 300,
      },
      {
        name: "Plot With Georeferencing",
        target: 1000,
        actual: 900,
        farmers: 400,
      },
    ],
    "Standard Seedbed": [
      {
        name: "Plot With Georeferencing",
        target: 1000,
        actual: 500,
        farmers: 500,
      },
      {
        name: "Plot With Georeferencing 2",
        target: 1000,
        actual: 900,
        farmers: 600,
      },
    ],
  });
  const [cropSeasonId, setCropSeasonId] = useState("");
  const [geneticTypeId, setGeneticTypeId] = useState("");
  const [hierarchicalNodeId, setHierarchicalNodeId] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    fetchInitialData();

    // Set up interval to log table data every 3 seconds
    const intervalId = setInterval(() => {
      if (data) {
        console.log("Data (3s interval):", data);
      }
    }, 3000);

    // Cleanup function to clear the interval when component unmounts
    return () => clearInterval(intervalId);
  }, [data]);

  useEffect(() => {
    if (cropSeasonId && geneticTypeId && hierarchicalNodeId) {
      fetchDashboardData();
    } else {
      // setData(null); // Clear the table data if any of the required IDs is missing
    }
  }, [cropSeasonId, geneticTypeId, hierarchicalNodeId]);

  const fetchInitialData = async () => {
    try {
      const [cropSeasonResponse, geneticTypeResponse, hierarchyResponse] =
        await Promise.all([
          AgronomicTechnologiesService.getCropSeasonAPI(),
          AgronomicTechnologiesService.getGeneticTypeApi(),
          AgronomicTechnologiesService.getHierarchyNodeAPI(),
        ]);

      setCropSeasonOptions(
        cropSeasonResponse.data.response.cropSeason.map((item) => ({
          label: item.cropYear.toString(),
          value: item.cropYear.toString(),
          cropSeasonId: item.cropSeasonId, // Assuming there's an id field
        }))
      );

      setGeneticTypeOptions(
        geneticTypeResponse.data.response.map((item) => ({
          label: item.gtGeneticDescription,
          value: item.gtGeneticDescription,
          geneticTypeId: item.gtGeneticTypeId, // Assuming there's an id field
        }))
      );

      // Flatten hierarchy tree for simplicity. You might want to implement a more sophisticated approach.
      const flattenHierarchy = (node, result = [], level = 0) => {
        result.push({ label: node.locationName, value: node.id, level });
        node.childHierarchies.forEach((child) =>
          flattenHierarchy(child, result, level + 1)
        );
        return result;
      };
      setHierarchyOptions(flattenHierarchy(hierarchyResponse.data.response));
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };

  const fetchDashboardData = async () => {
    // try {
    //   const response = await AgronomicTechnologiesService.getAPI({
    //     cropYearUUID: cropSeasonId,
    //     geneticTypeUUID: geneticTypeId,
    //     ihId: hierarchicalNodeId,
    //   });
    //   console.log("Received data:", response.data.response);
    //   if (response.data.response) {
    //     setData(response.data.response);
    //   }
    // } catch (error) {
    //   console.error("Error fetching data:", error);
    //   setData(null);
    // }
  };

  const handleHierarchyChange = (event, newValue) => {
    setHierarchicalNode(newValue?.value || "");
  };

  const renderAgronomicTechnologiesDashboard = () => {
    // if (!cropSeasonId || !geneticTypeId || !hierarchicalNodeId) {
    //   return null;
    // }

    return (
      <AgronomicTechnologiesDashboard
        data={data}
        cropYearUUID={cropSeasonId}
        geneticTypeUUID={geneticTypeId}
        ihId={hierarchicalNodeId}
      />
    );
  };

  return (
    <Layout
      title={t("agronomicTechnologiesDashboard.pageTitle")}
      background="#ffffff"
    >
      <Grid
        container
        id="mainfilter"
        spacing={2}
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          p: 2,
          maxWidth: "100%",
          margin: "auto",
          paddingBottom: "34px",
          marginBottom: "8px",
        }}
      >
        <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px" }}>
          <Typography
            variant="h8"
            display="flex"
            fontWeight="bold"
            color="#666666"
          >
            <ArrowDropDownIcon />
            {t("agronomicTechnologiesDashboard.mandatoryFilters")}
          </Typography>
        </Grid>

        <Grid item xs={4} style={{ paddingLeft: "0px" }}>
          <FormControl fullWidth>
            <Autocomplete
              value={
                cropSeasonOptions.find(
                  (option) => option.value === cropSeason
                ) || null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setCropSeason(newValue.value);
                  setCropSeasonId(newValue.cropSeasonId);
                } else {
                  setCropSeason("");
                  setCropSeasonId("");
                }
              }}
              options={cropSeasonOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Crop Year"
                  placeholder="Select an option"
                  InputProps={{
                    ...params.InputProps,
                    style: { color: "black" },
                    startAdornment: <></>,
                  }}
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 0.23)",
                      },
                    },
                    "& .MuiInputBase-input::placeholder": {
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      textAlign: "left",
                      color: "black",
                      opacity: 1,
                    },
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth>
            <Autocomplete
              value={
                geneticTypeOptions.find(
                  (option) => option.value === geneticType
                ) || null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setGeneticType(newValue.value);
                  setGeneticTypeId(newValue.geneticTypeId);
                } else {
                  setGeneticType("");
                  setGeneticTypeId("");
                }
              }}
              options={geneticTypeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tobacco Type"
                  placeholder="Select an option"
                  InputProps={{
                    ...params.InputProps,
                    style: { color: "black" },
                    startAdornment: <></>,
                  }}
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 0.23)",
                      },
                    },
                    "& .MuiInputBase-input::placeholder": {
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      textAlign: "left",
                      color: "black",
                      opacity: 1,
                    },
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth>
            <Autocomplete
              multiple // Add this to enable multiple selection
              value={hierarchyOptions.filter((option) =>
                selectedHierarchicalNodes.includes(option.value)
              )}
              onChange={(event, newValues) => {
                const selectedValues = newValues.map((item) => item.value);
                setSelectedHierarchicalNodes(selectedValues); // New state for multiple values
                setHierarchicalNodeIds(selectedValues); // If you need to track IDs separately
              }}
              options={hierarchyOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Hierarchical Nodes" // Updated to plural
                  placeholder={
                    hierarchicalNodeIds.length && "Select one or more options"
                  }
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <InputAdornment position="start">
                          <img
                            src={iconSearch}
                            alt="search"
                            style={{
                              width: "32px",
                              height: "32px",
                            }}
                          />
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 0.23)",
                      },
                    },
                    "& .MuiInputBase-input::placeholder": {
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      textAlign: "left",
                      color: "black",
                      opacity: 1,
                    },
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>

      {renderAgronomicTechnologiesDashboard()}
    </Layout>
  );
}
