import { SnackbarContext } from 'context/snackBarContext';
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import _ButtonLock_ from "assets/_ButtonLock_.svg";
import _ButtonPriceList_ from "assets/_ButtonPriceList_.svg";
import "./AgriSupplyReg.css";
import warning from 'assets/warning.svg';
import { Tooltip } from '@mui/material';

const AgriculturalSuppliesRegTable = (props) => {

  const [agriData, setAgriData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const { t, i18n } = useTranslation();

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...agriData].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setAgriData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <ArrowUpwardIcon fontSize="small" />;
      } else if (sortConfig.direction === "desc") {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
    // Default icon (ArrowDownward) when no sorting is applied
    return <ArrowDownwardIcon fontSize="small" />;
  };

  const handleOpen = (sapAnalysisId) => {
    setDeleteId(sapAnalysisId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    setAgriData(props.agriData);
  }, [props.agriData]);

  return (
    <div className="table-container">
      <table className='custom-tableAgri'>
        <thead>
          <tr>
            <th
              onClick={() => handleSort("asrCode")}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {t("agriSupplyReg.code")}{getSortIcon("asrCode")}
            </th>
            <th
              onClick={() => handleSort("asrSupplyName")}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {t("agriSupplyReg.name")} {getSortIcon("asrSupplyName")}
            </th>
            <th
              onClick={() => handleSort("asrSupplyName")}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {t("agriSupplyReg.supplyType")} {getSortIcon("asrSupplyName")}
            </th>
            <th
              onClick={() => handleSort("typeOfUse")}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {t("agriSupplyReg.typeOfUse")} {getSortIcon("typeOfUse")}
            </th>
            <th
              onClick={() => handleSort("asrUnitOfMeasurement")}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {t("agriSupplyReg.uom")} {getSortIcon("asrUnitOfMeasurement")}
            </th>

            <th
              onClick={() => handleSort("asrThirdPartyAgriculturalSupply")}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {t("agriSupplyReg.3rdPsupply")} {getSortIcon("asrThirdPartyAgriculturalSupply")}
            </th>
            <th className="actions-headerAgri">{t("Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {agriData.map((row, index) => (
            <tr key={index}>
              <td
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/agriculturalSuppliesRegistrationForm", {
                    state: { row: row, edit: false },
                  })
                }
              >
                {row.notConfigured && (
                  <Tooltip title={t("agriSupplyReg.notConfigured").toUpperCase()} arrow>
                    <img
                      src={warning}
                      alt="Not Configured"
                      style={{ width: "16px", height: "16px", marginRight: "8px" }}
                    />
                  </Tooltip>

                )}
                {row.asrCode}
              </td>
              <td
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/agriculturalSuppliesRegistrationForm", {
                    state: { row: row, edit: false },
                  })
                }
              >
                {row.asrSupplyName}
              </td>
              <td
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/agriculturalSuppliesRegistrationForm", {
                    state: { row: row, edit: false },
                  })
                }
              >
                {row.supplyTypeName}
              </td>
              <td
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/agriculturalSuppliesRegistrationForm", {
                    state: { row: row, edit: false },
                  })
                }
              >
                {row.typeOfUse}
              </td>

              <td
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/agriculturalSuppliesRegistrationForm", {
                    state: { row: row, edit: false },
                  })
                }
              >
                {row.asrUnitOfMeasurement}
              </td>
              <td
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/agriculturalSuppliesRegistrationForm", {
                    state: { row: row, edit: false },
                  })
                }
              >
                {row.asrThirdPartyAgriculturalSupply === true ? t("agriSupplyReg.yes") : t("agriSupplyReg.no")}
              </td>
              <td className="actions-cellAgri">
                {row.asrThirdPartyAgriculturalSupply && (
                  <Tooltip title={t("agriSupplyReg.managePrice")} arrow>
                    <img
                      className="btn edit-btnAgri"
                      onClick={() =>
                        navigate("/priceParameter", {
                          state: { row: row, edit: true },
                        })
                      }
                      src={_ButtonPriceList_}
                      alt="price"
                    />
                  </Tooltip>
                )}

                {row.unlockedforOffSeason && (
                  <Tooltip title={t("agriSupplyReg.lockUnlock")} arrow>
                    <img
                      className="btn edit-btnAgri"
                      onClick={() =>
                        navigate(
                          row.asrThirdPartyAgriculturalSupply === true
                            ? "/thirdpartysuplock"
                            : "/agriculturalSuppliesUnlockForSaleManagement",
                          {
                            state: {
                              supplierDetails: row,
                              edit: true
                            },
                          }
                        )
                      }
                      src={_ButtonLock_}
                      alt="Edit"
                    />
                  </Tooltip>
                )}

                {/* // )} */}
                <Tooltip title={t("edit")} arrow>
                  <img
                    className="btn edit-btnAgri"
                    onClick={() =>
                      navigate("/agriculturalSuppliesRegistrationForm", {
                        state: { row: row, edit: true },
                      })
                    }
                    src={_ButtonEdit_}
                    alt="Edit"
                  />
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AgriculturalSuppliesRegTable