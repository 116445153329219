import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  FormControl,
  Grid,
  TextField,
  Typography,
  InputAdornment
} from "@mui/material";
import SearchIcon from '../../../../assets/SearchIcon.svg'
import Layout from "components/structure/Layout";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import Divider from "components/common/GrowingDivider";
//import VarietySeedsTargetsTable from './VarietySeedsTargetsTable';
import VarietySeedService from "services/VarietySeedService";
import { useTranslation } from "react-i18next";
import CustomTable from "./CustomTable";


export default function VarietySeeds() {
  const [cropSeason, setCropSeason] = useState("");
  const [geneticType, setGeneticType] = useState("");
  const [hierarchicalNode, setHierarchicalNode] = useState("");
  const [cropSeasonOptions, setCropSeasonOptions] = useState([]);
  const [geneticTypeOptions, setGeneticTypeOptions] = useState([]);
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [cropSeasonId, setCropSeasonId] = useState("");
  const [geneticTypeId, setGeneticTypeId] = useState("");
  const [hierarchicalNodeId, setHierarchicalNodeId] = useState("");
  const [childNodes, setChildNodes] = useState([])
  const [isTopHierarchyNode, setIsTopHierarchyNode] = useState(false);
  const [varietyData, setVarietyData] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    // Fetch variety data when geneticTypeId changes
    if (geneticTypeId) {
      fetchVarietyData(geneticTypeId);
    }
  }, [geneticTypeId]);

  //Api Integration for fetching Variety Data
  const fetchVarietyData = async (geneticTypeId) => {
    try {
      const result = await VarietySeedService.getVarietyType(geneticTypeId);
      setVarietyData(result.data.response);
    } catch (error) {
      console.error("Error fetching variety data:", error);
    }
  };

  const renderVarietySeedsTable = () => {
    if (!cropSeasonId && !hierarchicalNodeId && !geneticTypeId) {
      return null;
    }

    return (
      <CustomTable
        varietyData={varietyData}
        cropSeasonId={cropSeasonId}
        geneticTypeId={geneticTypeId}
        hierarchicalNodeId={hierarchicalNodeId}
        childNodes={childNodes}
        isTopHierarchyNode={isTopHierarchyNode}
      />
    );
  };
  const fetchInitialData = async () => {
    try {
      const [cropSeasonResponse, geneticTypeResponse, hierarchyResponse] =
        await Promise.all([
          VarietySeedService.getCropSeasonAPI(),
          VarietySeedService.getGeneticType(),
          VarietySeedService.getHierarchyNodeAPI(),
        ]);

      setCropSeasonOptions(
        cropSeasonResponse.data.response.map((item) => ({
          label: item.cropYear.toString(),
          value: item.cropYear.toString(),
          cropSeasonId: item.cropYearUid, // Assuming there's an id field
        }))
      );

      setGeneticTypeOptions(
        geneticTypeResponse.data.response.map((item) => ({
          label: item.gtGeneticDescription.toString(),
          value: item.gtGeneticDescription.toString(),
          geneticTypeId: item.gtGeneticTypeId,
        }))
      );
      // Flatten hierarchy tree for simplicity. You might want to implement a more sophisticated approach.
      const flattenHierarchy = (node, result = [], level = 0) => {
        result.push({
            label: node.locationName,
            value: node.id,
            level,
            tag: node.levelName
        });
        node.childHierarchies.forEach(child => flattenHierarchy(child, result, level + 1));
        return result;
    };
    setHierarchyOptions(flattenHierarchy(hierarchyResponse.data.response));

    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };
  console.log('hid',hierarchicalNodeId);
 

  const handleHierarchyChange = (event, newValue) => {
    if (newValue) {
        setHierarchicalNode(newValue.value);
        setHierarchicalNodeId(newValue.value);
        setIsTopHierarchyNode(newValue.level === 0);
        setChildNodes(
            hierarchyOptions.filter((node) =>
                node.level === newValue.level+1
            )
        )
        console.log('Selected node:', newValue);
        console.log('Node level:', newValue.level);
    } else {
        setHierarchicalNode("");
        setHierarchicalNodeId("");
        setIsTopHierarchyNode(false);
    }
};
  return (
    <>
      <Layout title={t("VarietySeedsTargets.pageTitle")} background="#ffffff">
        <Grid
          container
          id="mainfilter"
          spacing={2}
          sx={{
            background: "#EFEFEF",
            borderRadius: "5px",
            //p: 0.5,
            maxWidth: "100%",
            margin: "auto",
            paddingBottom: "20px",
            marginBottom: "8px",
            width: "1880px",
          }}
        >
          <Grid item xs={12} sx={{ paddingTop: "0px", paddingLeft: "0px" }}>
            <Typography variant="h8" display="flex">
              <ArrowDropDownIcon />
              {t("VarietySeedsTargets.filtersLable")}
            </Typography>
          </Grid>

          <Grid item xs={4} sx={{ paddingLeft: "0px" }}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  cropSeasonOptions.find(
                    (option) => option.value === cropSeason
                  ) || null
                }
                onChange={(event, newValue) => {
                  if (newValue) {
                    setCropSeason(newValue.value);
                    setCropSeasonId(newValue.cropSeasonId); // This will trigger useEffect
                  } else {
                    setCropSeason("");
                    setCropSeasonId(""); // This will trigger useEffect
                  }
                }}
                options={cropSeasonOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("VarietySeedsTargets.cropSeason")}
                    fullWidth
                    placeholder='Select an Option'
                    InputLabelProps={{ shrink: true }}
                    sx={{
                        '& .MuiInputBase-input::placeholder': {
                            opacity: 1,
                        },
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  geneticTypeOptions.find(
                    (option) => option.value === geneticType
                  ) || null
                }
                onChange={(event, newValue) => {
                  if (newValue) {
                    setGeneticType(newValue.value);
                    setGeneticTypeId(newValue.geneticTypeId); // This will trigger useEffect
                  } else {
                    setGeneticType("");
                    setGeneticTypeId(""); // This will trigger useEffect
                  }
                }}
                options={geneticTypeOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("VarietySeedsTargets.geneticType")}
                    fullWidth
                    placeholder='Select an Option'
                    InputLabelProps={{ shrink: true }}
                    sx={{
                        '& .MuiInputBase-input::placeholder': {
                            opacity: 1,
                        },
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={3.9}>
                    <FormControl fullWidth>
                        <Autocomplete
                            value={hierarchyOptions.find(option => option.value === hierarchicalNode) || null}
                            disableClearable={true}
                            onChange={handleHierarchyChange}
                            options={hierarchyOptions}
                            renderOption={(props, option) => (
                                <li {...props}>
                                    <div>
                                        <div style={{ fontSize: '16px' }}>
                                            {option.label}
                                        </div>
                                        <div style={{ fontSize: '10px', color: '#5A646E' }}>
                                            {option.tag}
                                        </div>
                                    </div>
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField {...params}
                                    label={t('VarietySeedsTargets.hierarchicalNode')}
                                    fullWidth
                                    placeholder='Select an Option'
                                    required={true}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{
                                        '& .MuiInputBase-input::placeholder': {
                                            opacity: 1,
                                        },
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={SearchIcon} alt="search" style={{ width: '20px', height: '20px', color: '#737D86' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
        </Grid>

        {cropSeasonId && geneticTypeId && hierarchicalNodeId && (
          <Divider title={t("VarietySeedsTargets.subordinatesTitle")} />
        )}
        { cropSeasonId && geneticTypeId && hierarchicalNodeId &&
        renderVarietySeedsTable()}
         
      </Layout>
    </>
  );
}
