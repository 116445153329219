import Layout from "components/structure/Layout";
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  FormControl,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "./Timeline.css";
import { IconButton, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import mailunread from "assets/mailunread.svg";
import mailopen from "assets/mailopen.svg";
import infooutline from "assets/infooutline.svg";
import DateRange from "assets/DateRange.svg";
import advancedFormat from "dayjs/plugin/advancedFormat";
import AlertsService from "services/AlertsService";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";

const StyledConnector = styled(StepConnector)(({ steps }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#ACB4BA",
    borderWidth: 3,
    position: "absolute",
    left: "82px",
    height: steps.length > 1 ? "calc(100% - 200px)" : "calc(100% - 64px)",
    top: "64px",
    zIndex: 0,
  },
}));

const Alerts = () => {
  const { t } = useTranslation();
  const [openSections, setOpenSections] = useState({
    today: true,
    yesterday: false,
    thisWeek: false,
    old: false,
  });

  const toggleSection = (label) => {
    setOpenSections((prev) => ({
      ...prev,
      [label]: !prev[label],
    }));
  };

  const [module, setModule] = useState("");
  const [moduleId, setModuleId] = useState("");
  const [moduleOptions, setModuleOptions] = useState([]);
  const [alertData, setAlertData] = useState([]);
  const [alertAllData, setAlertAllData] = useState([]);
  const [unreadAlertData, setUnreadAlertData] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const StyledDatePickerWrapper = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    '& .react-datepicker-wrapper': {
      width: '100%'
    },
    '& .react-datepicker__input-container': {
      position: 'relative',
      width: '100%',
    },
    '& .react-datepicker__input-container input': {
      width: '100%',
      height: '50px',
      paddingRight: '20px', // Space for calendar icon
      paddingLeft:'5px',
      border:'1px solid #0e2b63',
      borderRadius:'4px',
      fontSize: '1rem',
      backgroundColor: theme.palette.background.paper,
      '&:focus': {
        borderColor: '2px solid #0e2b63',
        outline: 'none'
      }
    }
  }));
  
  const CalendarIcon = styled(Box)(({ theme }) => ({
    position: 'absolute',
    right: '2px',
    top: '50%',
    transform: 'translateY(-50%)',
    pointerEvents: 'none',
    color: theme.palette.text.secondary
  }));

  useEffect(() => {
    fetchModuleData();
  }, []);
  useEffect(() => {
    // When moduleId changes, fetch alert data
    console.log("Hi");
    if (moduleId) {
      fetchAlertData({ moduleId });
    }
  }, [moduleId]);

  useEffect(() => {
    fetchAllAlertData();
  }, []);

  const fetchModuleData = async () => {
    try {
      let response = await AlertsService.geModuleAPI();

      setModuleOptions(
        response.data.response.map((item) => ({
          label: item.functionalityName.toString(),
          value: item.functionalityName.toString(),
          moduleId: item.fId, // Assuming there's an id field
        }))
      );
      console.log("moduleOptions", moduleOptions);
    } catch (error) {
      console.error("Error fetching Module data:", error);
    }
  };
  const fetchAlertData = async ({ moduleId }) => {
    try {
      let response = await AlertsService.getAlertData({ moduleId });
      console.log("alertDataresponse", response);
      setAlertData(response?.data?.response?.alertsAndNotifications || []);
      console.log("alertData", alertData);
    } catch (error) {
      console.error("Error fetching alert data:", error);
    }
  };

  const fetchAllAlertData = async () => {
    try {
      let response = await AlertsService.getAllAlertData();
      console.log("allalert", response);
      setAlertAllData(response?.data?.response?.alertsAndNotifications || []);

      const unreadAlerts = alertAllData.filter(
        (alert) => alert.status === "unread"
      );
      setUnreadAlertData(unreadAlerts); // Store unread alerts in state
      console.log("unreadAlertData", unreadAlertData);
    } catch (error) {
      console.error("Error fetching alert data:", error);
    }
  };

  const [checkBox, setCheckBox] = useState(false);

  const handleCheckBoxChange = () => setCheckBox(!checkBox);

  dayjs.extend(customParseFormat);
  dayjs.extend(advancedFormat);
  dayjs.extend(isBetween);

  const formatDateAndTime = (isoDate) => {
    const date = dayjs(isoDate);
    return {
      datePart: date.format("Do MMM YY"),
      timePart: date.format("hh:mm A"),
    };
  };

  const getDateCategory = (date) => {
    const today = dayjs();
    const yesterday = today.subtract(1, "day");
    const startOfWeek = today.startOf("week");
    const alertDate = dayjs(date);

    if (alertDate.isSame(today, "day")) return "Today";
    if (alertDate.isSame(yesterday, "day")) return "Yesterday";
    if (alertDate.isBetween(startOfWeek, today, null, "[]")) return "This Week";
    return "Old";
  };

  const filterStepsByCategory = (category, alertDataToUse) => {
    return alertDataToUse.filter((step) => {
      const dateCategory = getDateCategory(step.createdDateTimeUTC);
      const isUnread = checkBox ? step.status === "unread" : true;
      return dateCategory === category && isUnread;
    });
  };

  return (
    <Layout title={t("Alerts.title")}>
      <Box sx={{ paddingX: "20px", paddingY: "10px", textAlign: "left" }}>
        <Typography
          sx={{
            fontWeight: 800,
            color: "#5A646E",
            fontSize: "32px !important",
          }}
        >
          {t("Alerts.header")}
        </Typography>

        <Typography
          sx={{ color: "#5A646E", fontWeight: 300, fontSize: "16px" }}
        >
          {t("Alerts.sub-header")}
        </Typography>
      </Box>

      <Box sx={{ width: "80%", paddingTop: "2rem" }}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box display={"flex"} gap={4}>
            <Grid item xs={12} sm={6} md={0}>
              <FormControl sx={{ minWidth: "250px" }}>
                <Autocomplete
                  value={module}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setModule(newValue.value);
                      setModuleId(newValue.moduleId); // This will trigger useEffect
                    } else {
                      setModule("");
                      setModuleId(""); // This will trigger useEffect
                    }
                  }}
                  options={moduleOptions}
                  disableClearable={false} // Allow clearing the value
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Alerts.Module")}
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>
            </Grid>

            {/* <Grid item xs={12} sm={6} md={0} sx={{}}>
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => setDateRange(update)}
                placeholderText="Select Date Range"
                label="Date"
                className="custom-datepicker"
                wrapperClassName="custom-datepicker-wrapper"
              />
            </Grid> */}
            <Grid item xs={12} sm={6} md={0}>
              <StyledDatePickerWrapper>
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => setDateRange(update)}
                  placeholderText="Select Date Range"
                />
                <CalendarIcon>
                  <img src={DateRange}/>
                </CalendarIcon>
              </StyledDatePickerWrapper>
            </Grid>
          </Box>

          <Grid
            item
            md={4}
            sx={{ float: "right" }}
            className="farmer-addbutton-container"
          >
            <Box
              sx={{
                backgroundColor: "white",
                color: "black",
                border: "1px solid #ccc",
                paddingY: "0.1rem",
                paddingX: "0.7rem",
              }}
            >
              <Typography variant="subtitle2">
                <Checkbox
                  sx={{ paddingLeft: "0px" }}
                  checked={checkBox}
                  onChange={() => handleCheckBoxChange()}
                  color="primary"
                />
                {t("Alerts.unreadbox")}
              </Typography>
            </Box>
          </Grid>
        </Box>

        <Box sx={{ paddingX: "0px", paddingY: "10px", textAlign: "left" }}>
          {["Today", "Yesterday", "This Week", "Old"].map((label) => {
            const filteredSteps = filterStepsByCategory(
              label,
              moduleId ? alertData : alertAllData
            );

            return (
              <Box key={label} sx={{ mb: 4 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    px: 2,
                    py: 1,
                    bgcolor: "#f5f5f5",
                    cursor: "pointer",
                  }}
                  onClick={() => toggleSection(label.toLowerCase())}
                >
                  <IconButton
                    size="small"
                    sx={{
                      transform: openSections[label.toLowerCase()]
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                      transition: "transform 0.3s ease",
                    }}
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    {label}
                  </Typography>
                </Box>

                <Collapse
                  in={openSections[label.toLowerCase()]}
                  timeout="auto"
                  unmountOnExit
                >
                  {filteredSteps.length === 0 ? (
                    <Typography variant="body2" color="text.secondary">
                      {t("Alerts.noRecordsMessage")}
                    </Typography>
                  ) : (
                    <Stepper
                      orientation="vertical"
                      connector={
                        <StyledConnector
                          steps={filteredSteps.map((step, index) => ({
                            ...step,
                            index,
                          }))}
                        />
                      }
                      sx={{
                        "& .MuiStepLabel-root": {
                          paddingLeft: "50px",
                          display: "flex",
                          alignItems: "flex-start",
                        },
                        position: "relative",
                      }}
                    >
                      {filteredSteps.map((step, index) => {
                        const { datePart, timePart } = formatDateAndTime(
                          step.createdDateTimeUTC
                        );

                        return (
                          <Step key={index} expanded>
                            <StepLabel
                              icon={
                                <Box
                                  sx={{
                                    borderRadius: "50%",
                                    width: 64,
                                    height: 64,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    bgcolor: "#737D86",
                                    zIndex: 1,
                                    position: "relative",
                                  }}
                                >
                                  <img
                                    style={{ width: "43px", height: "43px" }}
                                    src={infooutline}
                                  />
                                </Box>
                              }
                            >
                              <Box
                                sx={{
                                  border: "none",
                                  p: 2,
                                  borderRadius: 2,
                                  bgcolor: "white",
                                  width: "100%",
                                  maxWidth: "calc(100% - 60px)",
                                  ml: 2,
                                  position: "relative",
                                }}
                              >
                                <Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    <Box>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{
                                          fontWeight: 600,
                                          fontSize: "18px",
                                        }}
                                      >
                                        {step.moduleName}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="text.secondary"
                                      >
                                        <span
                                          style={{
                                            fontWeight: 400,
                                            fontSize: "12px",
                                          }}
                                        >
                                          {datePart}, {timePart}
                                        </span>{" "}
                                        •{" "}
                                        <span
                                          style={{
                                            fontWeight: 600,
                                            fontSize: "12px",
                                          }}
                                        >
                                          {step.header}
                                        </span>
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        borderRadius: "50%",
                                        width: 32,
                                        height: 32,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        bgcolor: "#E9EBEE",
                                      }}
                                    >
                                      <img
                                        style={{
                                          width: "24px",
                                          height: "24px",
                                        }}
                                        src={
                                          step.status === "unread"
                                            ? mailunread
                                            : mailopen
                                        }
                                        alt="mail status"
                                      />
                                    </Box>
                                  </Box>
                                  {step.seasonName && (
                                    <Box
                                      sx={{
                                        border: "1px solid #ccc",
                                        borderRadius: 1,
                                        mt: 2,
                                        p: 2,
                                        bgcolor: "#f9f9f9",
                                        width: "calc(100% - 50px)",
                                      }}
                                    >
                                      <Typography
                                        variant="body2"
                                        color="text.primary"
                                      >
                                        {step.seasonName}
                                      </Typography>
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            </StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                  )}
                </Collapse>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Layout>
  );
};
export default Alerts;
