import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { SnackbarContext } from "context/snackBarContext";
import CultureTypeRegistrationService from 'services/CultureTypeRegistrationService';
import { useTranslation } from "react-i18next";

export const useCultureData = (queryParams) => {
  const queryClient = useQueryClient();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const { t } = useTranslation();
  console.log('useCultureData called with params:', queryParams); // Debug log

  const culturesQuery = useQuery({
    queryKey: ['cultures', queryParams],
    queryFn: () => CultureTypeRegistrationService.getAPI(queryParams),
    select: (data) => ({
      cultures: data?.data?.response?.Culture || [],
      totalItems: data?.data?.response?.totalItems || 0,
      menu: [...new Set((data?.data?.response?.Culture || [])
        .map(item => item.cuCultureName))]
        .map(name => ({ value: name, label: name }))
    }),
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchInterval: 5 * 60 * 1000,
    staleTime: 0,
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => CultureTypeRegistrationService.deleteAPI(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['cultures']);
    }
  });

  const cultureTypeQuery = useQuery({
    queryKey: ['cultureType', queryParams.cultureTypeId],
    queryFn: async () => {
      console.log('Fetching culture type data for ID:', queryParams.cultureTypeId);
      try {
        if (!queryParams.cultureTypeId) {
          console.warn('No Culture Type ID provided');
          return null;
        }

        const response = await CultureTypeRegistrationService.getCultureTypeByCultureId(queryParams.cultureTypeId);
        
        if (!response || !response.data) {
          console.warn('Invalid or empty response from API');
          return null;
        }

        return response.data;
      } catch (error) {
        console.error('API Error:', error);
        
        setMessage(t("cultureTypeRegistration.noCultureTypesRegistered"));
        setSeverity('error');
        setOpenAlert(true);
        
        return null;
      }
    },
    enabled: !!queryParams.cultureTypeId,
    retry: 1,
    retryDelay: 1000,
    staleTime: 0,
    cacheTime: 0,
    onError: () => {
      setMessage(t("cultureTypeRegistration.noCultureTypesRegistered"));
      setSeverity('error');
      setOpenAlert(true);
    }
  });

  const geneticTypesQuery = useQuery({
    queryKey: ['geneticTypes', queryParams],
    queryFn: () => CultureTypeRegistrationService.getGeneticTypeAPI(queryParams),
    select: (data) => ({
      geneticTypes: data?.data?.response?.GeneticTypes || [],
      totalItems: data?.data?.response?.totalItems || 0,
      menu: [...new Set((data?.data?.response?.GeneticTypes || [])
        .map(item => item.gtGeneticDescription))]
        .map(description => ({ value: description, label: description }))
    }),
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    staleTime: 0,
  });

  const curingTypesQuery = useQuery({
    queryKey: ['curingTypes', queryParams],
    queryFn: () => CultureTypeRegistrationService.getCuringTypeAPI(queryParams),
    select: (data) => ({
      curingTypes: data?.data?.response?.curingTyoes || [],
      totalItems: data?.data?.response?.totalItems || 0,
      menu: [...new Set((data?.data?.response?.curingTyoes || [])
        .map(item => item.curingType))]
        .map(type => ({ value: type, label: type }))
    })
  });

  const cultureOptionsQuery = useQuery({
    queryKey: ['cultureOptions'],
    queryFn: () => CultureTypeRegistrationService.getCultureFull({ pageNumber: 0, pageSize: 999 }),
    select: (data) => {
      const cultures = data?.data?.response?.Culture || [];
      return cultures.map(culture => ({
        value: culture.cuCultureName,
        label: culture.cuCultureName,
        id: culture.cuCultureId
      }));
    }
  });

  const postCultureTypeMutation = useMutation({
    mutationFn: (cultureTypeData) => CultureTypeRegistrationService.postCultureTypeAPI(cultureTypeData),
    onSuccess: () => {
      queryClient.invalidateQueries(['cultures']);
      setMessage(t('cultureTypeRegistration.createdSuccessfully'));
      setSeverity('success');
      setOpenAlert(true);
    },
    onError: (error) => {
      // Log the full error to console for debugging
      console.error('Culture Type Creation Error:', error);
      
      // Do not show any alert for backend errors
      return;
    }
  });

  return {
    culturesQuery,
    deleteMutation,
    cultureTypeQuery,
    geneticTypesQuery,
    curingTypesQuery,
    cultureOptionsQuery,
    postCultureTypeMutation,
  };
};