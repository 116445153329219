import React from "react";
import { Typography, Slider } from "@mui/material";
import CircularProgressDemo from "./CustomCircularProgress";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";

export default function AgronomicTechnologiesDashboard({ data }) {
  return (
    <>
      {Object.keys(data).map((category) => (
        <>
          <div
            style={{
              display: "flex",
              margin: "18px 0px",
            }}
          >
            <PlayArrowRoundedIcon
              fontSize="large"
              sx={{ transform: "rotate(90deg)", color: "#666666" }}
            />
            <span
              style={{
                marginLeft: "7px",
                marginTop: "9px",
                fontSize: "18px",
                fontWeight: "500",
                color: "#666666",
              }}
            >
              {category}
            </span>
          </div>
          <div
            style={{ display: "flex", columnGap: "1.5%", overflowX: "auto" }}
          >
            {data[category].map((item, index) => (
              <div
                style={{
                  minWidth: "19%",
                  minHeight: "190px",
                  flexShrink: 0,
                  borderRadius: "4px",
                  border: "2px solid var(--Gray-2, #ACB4BA)",
                  background: "var(--Pure-White, #FFF)",
                  boxShadow: "0px 0px 7.6px -2px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  style={{
                    color: "var(--Grey-6, #3B454F)",
                    textAlign: "left",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    margin: "10px 0px 0px 10px",
                  }}
                >
                  {item.name}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "10px",
                    marginTop: "10px",
                  }}
                >
                  <CircularProgressDemo
                    progress={parseInt((item.actual * 100) / item.target)}
                  />
                  <div
                    style={{
                      backgroundColor: "rgb(248,248,248)",
                      height: "45px",
                      width: "26%",
                      border: "1px solid lightgray",
                      boxShadow: "0px 0px 1px gray inset",
                      borderRadius: "5px",
                      padding: "5px",
                      marginLeft: "20px",
                      marginRight: "13px",
                    }}
                  >
                    Target:
                    <article
                      style={{
                        fontWeight: "500",
                        marginLeft: "25px",
                        marginTop: "7px",
                      }}
                    >
                      {item.target}
                    </article>
                  </div>
                  <div
                    style={{
                      backgroundColor: "rgb(248,248,248)",
                      height: "45px",
                      width: "26%",
                      border: "1px solid lightgray",
                      boxShadow: "0px 0px 1px gray inset",
                      borderRadius: "5px",
                      padding: "5px",
                      marginRight: "13px",
                    }}
                  >
                    Actual:
                    <article
                      style={{
                        fontWeight: "500",
                        marginLeft: "27px",
                        marginTop: "7px",
                      }}
                    >
                      {item.actual}
                    </article>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    paddingLeft: "20px",
                    marginTop: "10px",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "60px",
                      flexWrap: "wrap",
                      width: "100%",
                    }}
                  >
                    <div
                      key={item.functionalityId}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Typography
                        style={{
                          color: "var(--Grey-6, #3B454F)",
                          fontFamily: "Montserrat",
                          fontSize: "8px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "8px",
                          marginBottom: "15px",
                        }}
                      >
                        Farmers Collected
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Slider
                          value={
                            parseInt((item.farmers * 100) / item.target) || 0
                          }
                          aria-labelledby={`slider-${item.functionalityName}`}
                          min={0}
                          max={100}
                          sx={{
                            width: "70%",
                            borderRadius: "10px",
                            color: "var(--Primary-Blue, #0E2B63)",
                            marginLeft: "10px",
                            "& .MuiSlider-thumb": {
                              height: 40,
                              width: 40,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#0E2B63",
                              color: "white",
                              fontSize: "0.8rem",
                              "&::after": {
                                content: `"${(
                                  parseInt(
                                    (item.farmers * 100) / item.target
                                  ) || 0
                                ).toFixed(0)}%"`,
                                position: "relative",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              },
                            },
                            "& .MuiSlider-track": {
                              height: 20,
                            },
                            "& .MuiSlider-rail": {
                              height: 20,
                              opacity: 0.5,
                              backgroundColor: "#E9EBEE",
                            },
                          }}
                        />
                        <Typography
                          style={{
                            marginTop: "10px",
                            marginLeft: "20px",
                            fontSize: "5px",
                          }}
                        >
                          {parseInt(item.farmers)} /{item.target}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ))}
    </>
  );
}
