// GetCuringType.jsx
import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Button,
  TextField,
  Autocomplete,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import CuringTypeTable from "./CuringTypeTable";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CuringTypeService from "services/CuringTypeService";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const GetCuringType = () => {
  const navigate = useNavigate();
  const [curingData, setCuringData] = useState([]);
  const [cultureNames, setCultureNames] = useState([]);
  const [filCuringData, setfilCuringData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [cultureName, setCultureName] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [status, setStatus] = useState(null);
  const { t } = useTranslation();
  const [isDeleted, setIsDeleted] = useState(0);

  const debounceTimeout = useRef(null); // Declare useRef at the top level of the component

  const handleSearchKeywordChange = (event, newValue) => {
    const value = newValue;
    setSearchKeyword(value);
    setCurrentPage(0);

    // Clear the previous timeout to debounce the API call
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new timeout to call the API after 2 seconds
    debounceTimeout.current = setTimeout(() => {
      if (value) {
        callSearchCuringAPI(0, itemsPerPage, value, cultureName, status);
      } else {
        callSearchCuringAPI(0, itemsPerPage, "", cultureName, status);
      }
    }, 1500);
  };

  const handleClearStatus = () => {
    setStatusFilter("");
    setStatus(null);
    setCurrentPage(0);
    callSearchCuringAPI(0, itemsPerPage, searchKeyword, cultureName, null);
  };

  const handleClearCulture = () => {
    setCultureName("");
    setCultureName("");
    setCurrentPage(0);
    callSearchCuringAPI(0, itemsPerPage, searchKeyword, "", status);
  };

  const handleStatusChange = (statusValue) => {
    setStatusFilter(statusValue);
    setCurrentPage(0);
    const isActive =
      statusValue === "Active"
        ? true
        : statusValue === "Inactive"
        ? false
        : null;
    setStatus(isActive);
    callSearchCuringAPI(0, itemsPerPage, searchKeyword, cultureName, isActive);
  };

  const handleCultureChange = (culture) => {
    setCultureName(culture);
    setCurrentPage(0);
    callSearchCuringAPI(0, itemsPerPage, searchKeyword, culture, status);
  };

  const CallCultureNamesApi = () => {
    CuringTypeService.getAllCultureNames()
      .then((res) => {
        const cultureList =
          res?.data?.response.map((ele) => ele.cuCultureName) || [];
        setCultureNames(cultureList);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setCultureNames([]);
      });
  };

  const callCuringAPI = (pageNumber, size) => {
    setLoading(true);
    CuringTypeService.getCuring({ pageNumber, size })
      .then((res) => {
        const curingResults = res?.data?.response?.curingTyoes || [];
        const curingOptions = [
          ...new Set(curingResults.map((ele) => ele.curingType)),
        ];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setCuringData(curingOptions);
        setfilCuringData(curingResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (curingResults.length === 0 && searchKeyword.length > 0) {
          setMessage("No records found.");
          setOpenAlert(true);
          setSeverity("info");
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setCuringData([]);
        setfilCuringData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const callSearchCuringAPI = (
    pageNumber,
    size,
    keyword,
    cultureName,
    status
  ) => {
    setLoading(true);
    let query = { pageNumber, size, keyword, cultureName, status };

    if (status === true || status === false) {
      query.status = status;
    }
    if (!cultureName) {
      query.cultureName = "";
    }

    CuringTypeService.searchCuring(query)
      .then((res) => {
        const curingResults = res?.data?.response?.curingTyoes || [];
        const curingOptions = [
          ...new Set(curingResults.map((ele) => ele.curingType)),
        ];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setfilCuringData(curingResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (curingResults.length === 0 && searchKeyword.length > 0) {
          setMessage("No records found.");
          setOpenAlert(true);
          setSeverity("info");
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setfilCuringData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    CallCultureNamesApi();
  }, []);

  useEffect(() => {
    if (!cultureName && !searchKeyword && !statusFilter) {
      callCuringAPI(currentPage, itemsPerPage);
    } else {
      callSearchCuringAPI(
        currentPage,
        itemsPerPage,
        searchKeyword,
        cultureName,
        status
      );
    }
  }, [currentPage, itemsPerPage, isDeleted]);

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    if (!cultureName && !searchKeyword && !statusFilter) {
      callCuringAPI(pageNumber - 1, itemsPerPage);
    } else {
      callSearchCuringAPI(
        pageNumber - 1,
        itemsPerPage,
        searchKeyword,
        cultureName,
        status
      );
    }
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t("curingTypeRegistration.curingTypeList")}
      background="#ffffff"
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          padding: "1rem",
          height: "100px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} />
          <Typography>{t("filtersLable")}</Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={10}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={curingData} // Array of options for the autocomplete
                  value={searchKeyword} // Controlled value for the autocomplete
                  onChange={(event, newValue) =>
                    handleSearchKeywordChange(event, newValue)
                  } // Handle autocomplete selection
                  freeSolo // Allows free text input
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="curingType"
                      label={t("curingTypeRegistration.curingType")}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "7px", // Adjust padding for the input content
                          minHeight: "50px", // Adjust the overall height
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "1.2em", // Adjust label spacing if needed
                        },
                        width: "100%",
                      }}
                      InputProps={{
                        ...params.InputProps, // Pass autocomplete props
                        style: { height: "50px" }, // Directly setting the height
                      }}
                    />
                  )}
                />
              </Grid>

              {/* Culture Field */}
              <Grid item xs={12} sm={2.5}>
                <FormControl sx={{ width: { xs: "100%" }, minHeight: 50 }}>
                  <TextField
                    select
                    name="Culture"
                    label={t("curingTypeRegistration.culture")}
                    value={cultureName || "Select an Option"}
                    onChange={(event) => {
                      handleCultureChange(event.target.value);
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                      endAdornment: cultureName && (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={handleClearCulture}
                            style={{ marginRight: "15px" }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem value="Select an Option" sx={{ display: "none" }}>
                      {t("selectoption")}
                    </MenuItem>
                    {/* Map over cultureNames to create MenuItem options */}
                    {cultureNames.map((culture, index) => (
                      <MenuItem key={index} value={culture}>
                        {culture}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>

              {/* Status field */}
              <Grid item xs={12} sm={2.5}>
                <FormControl sx={{ width: { xs: "100%" }, minHeight: 50 }}>
                  <TextField
                    select
                    name="status"
                    label={t("status")}
                    value={statusFilter || "Select an Option"}
                    onChange={(event) => handleStatusChange(event.target.value)}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                      endAdornment: statusFilter && (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={handleClearStatus}
                            style={{ marginRight: "15px" }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem value="Select an Option" sx={{ display: "none" }}>
                      {t("selectoption")}
                    </MenuItem>
                    <MenuItem value="Active">{t("active")}</MenuItem>
                    <MenuItem value="Inactive">{t("inactive")}</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={2} textAlign={"right"}>
            <Button
              sx={{
                borderRadius: 1,
                minWidth: 95,
                minHeight: 44,
                background: "#004F9F",
              }}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => navigate("/addCuringType")} // Adjust for curing Type registration navigation
            >
              {t("addNew")}
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Render table header if no data is found */}
      <CuringTypeTable
        curingData={filCuringData} // Update filtered curing data
        setCuringData={setfilCuringData}
        setIsDeleted={setIsDeleted}
      />
    </Layout>
  );
};

export default GetCuringType;
