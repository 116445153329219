import { httpGrow } from "../http-common";

const getMenu = () => {
  return httpGrow.get(`/menu`);
};

const getSubMenu = (query) => {
  return httpGrow.get(`/menu/sub-menus?parentMenuId=${query?.parentMenuId}`);
};

const getFavorites = () => {
  return httpGrow.get(`/menu-favorites/getMenuFavorites/85193243`);
};

const saveFavorites = (data) => {
  return httpGrow.put(`/menu-favorites/save`, data);
};



const MenuStructureVisulization = {
  getMenu,
  getSubMenu,
  getFavorites,
  saveFavorites
};
export default MenuStructureVisulization;
