import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Switch,
  CircularProgress,
  Typography,
  Modal,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Radio,
  RadioGroup,
  Divider,
  Autocomplete,
  IconButton,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Layout from "components/structure/Layout";
import { soilParameterSchema, transportGeneralSchema } from "utils/validations";
import TransporterGetService from "services/TransporterGetService";
import CultureGroupService from "services/CultureGroup";
import { SnackbarContext } from "context/snackBarContext";
import arrowRight from "assets/arrowRight.svg";
import arrowLeft from "assets/arrowLeft.svg";
import RedSwitch from "utils/RedSwitch";
import PlantingService from "services/PlantingService";
import Save from "assets/SaveNew.svg";
import TobaccoService from "services/TobaccoService";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import Lable from "utils/lables.json";
import SoilParameterService from "services/SoilParameterService";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import TransporterRegistrationService from "services/TransporterRegistrationService";
import * as Yup from "yup";
import { transportAddSchema, transportAddSchemaLegal } from "utils/validations";
import SearchIcon from "assets/SearchIcon.svg";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import AdditionalFreightLimitDistribution from "services/AdditionalFreightsLimitDistributionServices";
import moment from "moment";
import PriceService from "services/PriceService";
import DateRangePicker from "components/pages/Growing/PriceParameterizationOfInvestment/dateRangePicker";
import PriceParameterizationService from "services/PriceParameterizationService";
import ClearIcon from "@mui/icons-material/Clear";

export default function Price() {
  console.log("runninggggg");
  const navigate = useNavigate();

  const location = useLocation();
  console.log("vkkkk", location);
  const { row } = location.state || {};
  const edit = true;
  const { state } = location;

  console.log("Incoming row data:", row);

  //console.log("dhoni", tobaccoCompanyName, tccCountryCode, region);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState({
    // Individual/Legal Entity Data
    BusinessType: row?.BusinessType || "Individual",
    fiscalID: "",
    transporterName: row?.transporterName || "",
    nationalityId: row?.nationalityId || "",
    maritalStatusId: row?.maritalStatusId || "",
    region: row?.region || "",
    stateRegistrationNumber: row?.stateRegistrationNumber || "",
    rntrc: row?.rntrc || "",

    // Route Details
    buyingStation: "",
    buyingStationRoute: "",
    supplyInvoicingUnit: row?.supplyInvoicingUnit || "",
    supplyInvoicingUnitRoute: row?.supplyInvoicingUnitRoute || "",

    // Bank Information
    bankAccountType: row?.bankAccountType || "",
    bankRegistrationId: row?.bankRegistrationId || "",
    bankBranchRegistrationId: row?.bankBranchRegistrationId || "",
    accountNumber: row?.accountNumber || "",

    // Additional Information
    tmResponsibleUID: row?.tmResponsibleUID || "",
    ftResponsibleUID: row?.ftResponsibleUID || "",
    sapVendorCode: row?.sapVendorCode || "",
    coupaRegisterNumber: row?.coupaRegisterNumber || "",
    ciotRequirement: row?.cioTRequirement ? "YES" : "NO",
    tallVoucher: row?.tallVoucher ? "YES" : "NO",
    externalCode: row?.externalCode || "",

    // CNPJ Card Information
    tradeName: row?.tradeName || "",
    businessSize: row?.businessSize || "",
    mainEconomicActivityCode: row?.mainEconomicActivityCode || "",
    secondaryEconomicActivityCode: row?.secondaryEconomicActivityCode || "",
    legalNatureCode: row?.legalNatureCode || "",
    legalName: row?.legalName || "",
    taxation: row?.taxation || "",
    icmsTaxPercentage: row?.icmsTaxPercentage?.toString() || "",
    address: row?.address || "",
    neighborhood: row?.neighborhood || "",
    postalCode: row?.postalCode || "",
    emailForInvoices: row?.emailForInvoices || "",
    bankCode: "", // We'll set this after fetching bank details
  });
  console.log("🚀 ~ Price ~ values:", values);

  const [errors, setErrors] = useState({});
  console.log("🚀 ~ Price ~ errors:", errors);
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [dataType, setDataType] = useState([]);
  const [region, setRegion] = useState([]);
  const [nationality, setNationality] = useState([]);
  const [marital, setMarital] = useState([]);
  const [expiryDate, setExpiryDate] = useState(null);
  const [establishmentDate, setEstablishmentDate] = useState(null);
  const [dob, setDob] = useState(null);
  const [selectedOption, setSelectedOption] = useState("Hierarchical Node");
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  console.log("🚀 ~ Price ~ hierarchyOptions:", hierarchyOptions);
  const [cropSeasonOptions, setCropSeasonOptions] = useState([]);
  console.log("first", cropSeasonOptions);

  // State to track the selected radio button

  const [startDate, setStartDate] = useState(null);
  console.log("sss",startDate)
  const [EndDate, setEndDate] = useState(state?.endDate || "");

  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [partners, setPartners] = useState([
    {
      id: Date.now(),
      supplier: "",
      plCardId: "",
      minimumQuantity: "",
      price: "",
      selectedOption: "Hierarchical Node",
      startDate: "",
      endDate: "",
      supplier: "",
    },
  ]);
  console.log("🚀 ~ Price ~ partners:", partners);

  const [FT, setFT] = useState([]);
  const [TM, setTM] = useState([]);
  const [city, setCity] = useState([]);
  console.log("🚀 ~ Price ~ city:", city);

  const [bank, setBank] = useState([]);
  const [branches, setBranches] = useState([]);

  const { t } = useTranslation();

  const [isTopHierarchyNode, setIsTopHierarchyNode] = useState(false);
  const [cropSeason, setCropSeason] = useState("");
  const [cropSeasonPrice, setCropSeasonPrice] = useState("");

  const [cropSeasonId, setCropSeasonId] = useState("");
  const [cropSeasonIdPrice, setCropSeasonIdPrice] = useState("");

  const [hierarchicalNode, setHierarchicalNode] = useState("");
  const [hierarchicalNodePrice, setHierarchicalNodePrice] = useState("");

  const [hierarchicalNodeId, setHierarchicalNodeId] = useState("");
  const [hierarchicalNodeIdPrice, setHierarchicalNodeIdPrice] = useState("");

  const [showFiltersGrid, setShowFiltersGrid] = useState(true);
  const [manageApprovalEvidencesModal, setApprovalEvidencesModal] =
    useState(false);
  const inputFileRef = React.useRef();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [percentage, setPercentage] = React.useState(0);
  console.log("uploadedFiles", uploadedFiles);
  const [additionalFLD, setAdditionalFLD] = useState(null);
  const [childNodes, setChildNodes] = useState([]);
  console.log("Child Nodes", childNodes);
  console.log("CSID", cropSeasonId);
  console.log("ihID", hierarchicalNodeId);
  console.log("Child Nodes: Hoptions", hierarchyOptions);
  const [showAllPrices, setShowAllPrices] = React.useState(true);
  console.log("🚀 ~ Price ~ showAllPrices:", showAllPrices)

  const [filterPartners, setFilteredPartners] = useState([]);
  console.log("🚀 ~ Price ~ filterPartners:", filterPartners);
  const [priceData, setPriceData] = useState([]);
  const [supplier, setSupplier] = useState([]);

  const [priceList, setPriceList] = useState([
    {
      id: Date.now(),
      priceDefinedBy: "",
      startDate: "",
      endDate: "",
      supplier: "",
      minimumQuantity: "",
      price: "",
      hierarchicalNode: true,
      cityId: "",
      city: false,
      hierarchicalNodeId: "",
      hierarchicalNodeName: "",
      supplier: "",
    },
  ]);

  console.log("priceeeeeeee", priceList);
  const handleAddPrice = () => {
    setPriceList([
      ...priceList,
      {
        id: Date.now(), // Use a unique identifier like timestamp
        priceDefinedBy: "hierarchical node", // Default value
        startDate: "",
        endDate: "",
        supplier: "",
        minimumQuantity: "",
        price: "",
        hierarchicalNode: true, // Default selection
        city: false, // Default unselected
      },
    ]);
    setShowAllPrices(true)
    setFilteredPartners([])
  };

  // const handleToggle = (event) => {
  //   const showAll = event.target.checked;
  //   setShowAllPrices(showAll);

  //   // Filter data based on the toggle state
  //   const today = new Date();
  //   const filteredData = priceList.filter((item) => {
  //     if (showAll) {
  //       return true; // Show all prices
  //     }
  //     const endDate = new Date(item.endDate);
  //     return endDate >= today; // Show only non-expired prices
  //   });

  //   // Update the filtered partners state
  //   setFilteredPartners(filteredData);
  // };

  // const handleAddPrice = () => {
  //   setPriceList((prevPriceList) => {
  //     const newPriceList = [
  //       ...prevPriceList,
  //       {
  //         id: Date.now(), // Use a unique identifier like timestamp
  //         priceDefinedBy: "hierarchical node", // Default value
  //         startDate: "",
  //         endDate: "",
  //         supplier: "",
  //         minimumQuantity: "",
  //         price: "",
  //         hierarchicalNode: true, // Default selection
  //         city: false, // Default unselected
  //       },
  //     ];

  //     // Automatically set the toggle to true and update filtered partners
  //     setShowAllPrices(true);
  //     setFilteredPartners(newPriceList); // Update to show all prices

  //     return newPriceList; // Update the state
  //   });
  // };

  const handleToggle = (event) => {
    const showAll = event.target.checked;
    setShowAllPrices(showAll);

    // Filter data based on the toggle state
    setPriceList((prevPriceList) => {
      const today = new Date();
      const filteredData = prevPriceList.filter((item) => {
        if (showAll) {
          return true; // Show all prices
        }
        const endDate = new Date(item.endDate);
        return endDate >= today; // Show only non-expired prices
      });

      setFilteredPartners(filteredData); // Update filtered data
      return prevPriceList; // Keep the original list
    });
  };

  // useEffect(() => {
  //   handleToggle({ target: { checked: showAllPrices } });
  // }, []); // Run once on mount

  // const handlePartnerChange = (id, key) => {
  //   setPriceList((prevList) =>
  //     prevList.map((item) =>
  //       item.id === id
  //         ? {
  //             ...item,
  //             hierarchicalNode: key === "hierarchicalNode",
  //             city: key === "city",
  //           }
  //         : {
  //             ...item,
  //             hierarchicalNode: false,
  //             city: false,
  //           }
  //     )
  //   );
  // };

  const handleAddPartner = () => {
    setFilteredPartners([]);
    const newId = Math.max(...partners.map((p) => p.id), 0) + 1;
    setPartners([
      ...partners,
      {
        id: Date.now(),
        supplier: "",
        plFiscalID: "",
        plCardId: "",
        plDOB: null,
        minimumQuantity: "",
        price: "",
        selectedOption: "Hierarchical Node",
        startDate: "",
        endDate: "",
      },
    ]);
  };

  const handleRadioChange = (id, value) => {
    setPartners((prevPartners) =>
      prevPartners.map((partner) =>
        partner.id === id ? { ...partner, selectedOption: value } : partner
      )
    );
  };

  const handleDeletePartner = async (partnerId) => {
    try {
      setPartners(partners.filter((partner) => partner.id !== partnerId));
      const response = await TransporterGetService.deletePartner(partnerId);

      if (response.status === 200) {
        setPartners(
          partners.filter((partner) => partner.partnersListId !== partnerId)
        );
        console.log("Partner deleted successfully:", response.data);
      } else {
        console.error("Failed to delete the partner. Status:", response.status);
      }
    } catch (error) {
      console.error("An error occurred while deleting the partner:", error);
    }
  };

  const fetchInitialData = async () => {
    try {
      const [cropSeasonResponse, hierarchyResponse] = await Promise.all([
        AdditionalFreightLimitDistribution.getCity(),
        AdditionalFreightLimitDistribution.getHierarchyNodeAPI(),
      ]);

      setCropSeasonOptions(
        cropSeasonResponse.data.response.map((item) => ({
          label: item.cityName.toString(),
          value: item.cityName.toString(),
          cropSeasonId: item.cityId, // Assuming there's an id field
        }))
      );

      // Flatten hierarchy tree for simplicity. You might want to implement a more sophisticated approach.
      const flattenHierarchy = (node, result = [], level = 0) => {
        result.push({
          label: node.locationName,
          value: node.id,
          level,
          tag: node.levelName,
          parentId: node.parentId || "parentNode",
        });
        node.childHierarchies.forEach((child) =>
          flattenHierarchy(child, result, level + 1)
        );
        return result;
      };
      setHierarchyOptions(flattenHierarchy(hierarchyResponse.data.response));
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };

  // Function to open the modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const getRegionList = async () => {
    try {
      const response = await TransporterGetService.getRegion();
      const d = response.data.response;
      // Store the full region objects instead of just stateCode
      setRegion(d);
    } catch (error) {
      console.error("Error fetching regions:", error);
      return null;
    }
  };
  const getNationalityList = async () => {
    try {
      const response = await TransporterGetService.getNationality();
      const d = response.data.response;
      // Store full nationality objects instead of just names
      setNationality(d);
    } catch (error) {
      console.error("Error fetching nationality list:", error);
      return null;
    }
  };

  const getMaritalList = async () => {
    try {
      const response = await TransporterGetService.getMarital();
      const d = response.data.response;
      // Store full marital status objects instead of just names
      setMarital(d);
    } catch (error) {
      console.error("Error fetching marital status list:", error);
      return null;
    }
  };
  const getTMList = async () => {
    try {
      const response = await TransporterGetService.getTMResponsible();
      console.log("responsetm", response);
      const d = response.data.response;
      // Store full objects instead of just names
      setTM(
        d.map((e) => ({
          userId: e.userId,
          userName: e.userName,
        }))
      );
    } catch (error) {
      console.error("Error generating unique code:", error);
      return null;
    }
  };

  // Update the getFTList function
  const getFTList = async () => {
    try {
      const response = await TransporterGetService.getFTResponsible();
      console.log("responseft", response);
      const d = response.data.response;
      // Store full objects instead of just names
      setFT(
        d.map((e) => ({
          userId: e.userId,
          userName: e.userName,
        }))
      );
    } catch (error) {
      console.error("Error generating unique code:", error);
      return null;
    }
  };

  const getCityList = async () => {
    try {
      const response = await TransporterGetService.getCityDetails();
      console.log("polarCity", response.data.response);
      const d = response.data.response;
      // Store full city objects instead of just names
      setCity(d);
    } catch (error) {
      console.error("Error generating unique code:", error);
      return null;
    }
  };

  const getBankList = async () => {
    try {
      const response = await TransporterGetService.getBankDetails();
      const bankData = response.data.response;
      setBank(bankData);

      // If we have row data and bankRegistrationId, set the bank code
      if (row?.bankRegistrationId) {
        const selectedBank = bankData.find(
          (b) => b.bankRegistrationId === row.bankRegistrationId
        );
        if (selectedBank) {
          setValues((prevValues) => ({
            ...prevValues,
            bankCode: selectedBank.bankCode,
            bankRegistrationId: row.bankRegistrationId,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching bank details:", error);
    }
  };

  // useEffect(() => {
  //   const fetchInitialData = async () => {
  //     await Promise.all([
  //       getBankList(),
  //       getRegionList(),
  //       getNationalityList(),
  //       getMaritalList(),
  //       getTMList(),
  //       getFTList(),
  //       getCityist(),
  //     ]);
  //   };

  //   fetchInitialData();
  // }, [row?.transporterID]);

  // Initialize dates from row data
  // useEffect(() => {
  //   if (row) {
  //     if (row.expirationDateCoupa) {
  //       setExpiryDate(dayjs(row.expirationDateCoupa));
  //     }
  //     if (row.companyEstablishmentDate) {
  //       setEstablishmentDate(dayjs(row.companyEstablishmentDate));
  //     }
  //     if (row.dob) {
  //       setDob(dayjs(row.dob));
  //     }
  //     // Initialize partners if they exist
  //     if (row.partners && Array.isArray(row.partners)) {
  //       setPartners(
  //         row.partners.map((partner) => ({
  //           ...partner,
  //           id: partner.id || Math.random(), // Ensure each partner has an ID
  //         }))
  //       );
  //     }
  //   }
  // }, [row]);

  // Fetch branch details when bank is selected
  useEffect(() => {
    if (values.bankRegistrationId) {
      fetchBranchDetails(values.bankRegistrationId);
    }
  }, [values.bankRegistrationId]);

  // Function to close the modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Function when user confirms "Yes" on the modal
  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/agriculturalSuppliesRegistrationList");
  };

  const fetchBranchDetails = async (bankRegistrationId) => {
    try {
      const response = await TransporterGetService.getBankBranchDetails(
        bankRegistrationId
      );

      setBranches(response.data.response || []);
    } catch (error) {
      console.error("Error fetching branch details:", error);
      setMessage("Failed to fetch bank branch details");
      setSeverity("error");
      setOpenAlert(true);
      setBranches([]); // Reset branches on error
    }
  };

  // const handleChange = (event) => {
  //   const { name, value } = event.target;

  //   if (name === "bankRegistrationId") {
  //     const selectedBank = bank.find((b) => b.bankRegistrationId === value);
  //     fetchBranchDetails(value);
  //     setValues((prevValues) => ({
  //       ...prevValues,
  //       [name]: value,
  //       bankCode: selectedBank ? selectedBank.bankCode : "",
  //       bankBranchRegistrationId: "",
  //     }));
  //   } else if (name === "tmResponsibleUID" || name === "ftResponsibleUID") {
  //     // For TM and FT fields, store just the userId
  //     setValues((prevValues) => ({
  //       ...prevValues,
  //       [name]: value.userId || value, // Store userId if object, otherwise store value directly
  //     }));
  //   } else {
  //     setValues((prevValues) => ({
  //       ...prevValues,
  //       [name]: value,
  //     }));
  //   }
  // };

  const handleChange = (id, field, value) => {
    setPartners((prevPartners) =>
      prevPartners.map((partner) =>
        partner.id === id ? { ...partner, [field]: value } : partner
      )
    );
  };

  // const filterPriceListByStartDate = (startDate, priceListData) => {
  //   // If no startDate is provided, return the original data
  //   if (!startDate) return priceListData;
  
  //   // Convert startDate to a dayjs object and set to start of day
  //   const selectedDate = dayjs(startDate).startOf('day');
  
  //   // Debugging: Log the formatted selected date
  //   console.log("🚀 ~ filterPriceListByStartDate ~ selectedDate:", selectedDate.format("YYYY-MM-DD"));
  
  //   // Filter priceListData where item.startDate is on or after selectedDate
  //   const filteredData = priceListData.filter((item) => 
  //     dayjs(item.startDate).startOf('day').isSameOrAfter(selectedDate)
  //   );
  
  //   // Debugging: Log the filtered data
  //   console.log("🚀 ~ filterPriceListByStartDate ~ filteredData:", filteredData);
  
  //   return filteredData;
  // };
  
  // Usage in your component

  const handleChangeDate = ({ target }) => {
    const { name, value } = target;
  
    // Update the StartDate in state
    setStartDate(value);
  
    // Filter the priceList data based on the selected date (ignoring time)
    const updatedPriceList = priceList.filter((partner) => {
      const partnerDate = new Date(partner.startDate);
      const selectedDate = new Date(value);
  
      // Extract year, month, and day for comparison
      const partnerDateString = `${partnerDate.getFullYear()}-${partnerDate.getMonth()}-${partnerDate.getDate()}`;
      const selectedDateString = `${selectedDate.getFullYear()}-${selectedDate.getMonth()}-${selectedDate.getDate()}`;
  
      console.log("🚀 ~ handleChangeDate ~ partnerDateString:", partnerDateString);
      console.log("🚀 ~ handleChangeDate ~ selectedDateString:", selectedDateString);
  
      // Compare only year, month, and day
      return partnerDateString === selectedDateString;
    });
  
    // Update the filteredPartners state with the filtered data
    setFilteredPartners(updatedPriceList);
  };
  


  // const handleUpdate = async (event) => {
  //   event.preventDefault();

  //   try {
  //     setErrors({});
  //     // {
  //     //   "priceListId": "3a8e95ad-3716-4f4e-a6d7-977f93f67b44",
  //     //   "startDate": "2024-12-22T18:30:00.000+00:00",
  //     //   "endDate": "2025-01-22T18:30:00.000+00:00",
  //     //   "minimumQuantity": 4,
  //     //   "price": 40.25,
  //     //   "priceDefinedBy": "Hierarchical Node",
  //     //   "hierarchicalNodeId": "0a2a8e52-9306-1291-8193-06d8f0840021",
  //     //   "hierarchicalNodeName": "South Region"
  //     // },

  //     const formattedPartners = partners.map((partner) => ({
  //       priceListId: partner.id || "",
  //       startDate: partner.startDate,
  //       endDate: partner.endDate,
  //       minimumQuantity: partner.minimumQuantity,
  //       price: partner.price,
  //       priceDefinedBy: partner.selectedOption,
  //       hierarchicalNodeId: partner.hierarchicalNodeId,
  //       hierarchicalNodeName: partner.hierarchicalNodeName
  //     }));

  //     const payload = {
  //      ...priceData,
  //      priceLists: formattedPartners,
  //     };

  //     console.log("🚀 ~ handleUpdate ~ payload:", payload);

  //     const response = await PriceService.createPrice(
  //       payload
  //     );

  //     setLoading(false);

  //     if (response.status === 200) {
  //       setSeverity("success");
  //       setMessage("Transporter updated successfully");
  //       setOpenAlert(true);
  //       navigate("/transporterRegistration");
  //     }
  //   } catch (err) {
  //     setLoading(false);
  //     if (err instanceof Yup.ValidationError) {
  //       let newError = {};
  //       err?.inner?.forEach((err) => {
  //         newError[err.path] = err.message;
  //       });
  //       setErrors(newError);
  //     } else {
  //       console.error("An error occurred while submitting the form:", err);
  //       setSeverity("error");
  //       setMessage(
  //         err?.response?.data?.message ||
  //           "An error occurred while submitting the form"
  //       );
  //       setOpenAlert(true);
  //     }
  //   }
  // };

  // const handleUpdate = async (event) => {
  //   event.preventDefault();

  //   setLoading(true);
  //   setErrors({});

  //   try {
  //     // Validate partners
  //     if (!Array.isArray(partners) || partners.length === 0) {
  //       throw new Error("No partners available to update.");
  //     }

  //     const isValidUUID = (id) =>
  //       /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(
  //         id
  //       );

  //     const formattedPriceLists = priceList.map((partner) => ({
  //       priceListId: isValidUUID(partner.id) ? partner.id : undefined, // Only include if valid UUID
  //       startDate: partner.startDate || "",
  //       endDate: partner.endDate || "",
  //       minimumQuantity: partner.minimumQuantity || 0,
  //       price: parseFloat(partner.price) || 0.0,
  //       priceDefinedBy: partner.selectedOption || "Default",
  //       hierarchicalNodeId: partner.hierarchicalNodeId || "",
  //       hierarchicalNodeName: partner.hierarchicalNodeName || "",
  //       cityId: partner.cityId || "",
  //       cityName: partner.cityName || "",
  //     }));

  //     const payload = {
  //       agriculturalSupplies: {
  //         createdDateTimeUTC:
  //           priceData.createdDateTimeUTC || new Date().toISOString(),
  //         createdByUser: priceData.createdByUser || "ADMIN",
  //         modifiedDateTimeUTC:
  //           priceData.modifiedDateTimeUTC || new Date().toISOString(),
  //         modifiedByUser: priceData.modifiedByUser || "ADMIN",
  //         asrSupplyId: priceData.asrSupplyId || "",
  //         asrSupplyName: priceData.asrSupplyName || "",
  //         asrCode: priceData.asrCode || 0,
  //         sapCode: priceData.sapCode || 0,
  //         supplyTypeName: priceData.supplyTypeName || "",
  //         unlockedforOffSeason: priceData.unlockedforOffSeason || false,
  //         notConfigured: priceData.notConfigured || false,
  //         displayName: priceData.displayName || "",
  //         typeOfUse: priceData.typeOfUse || "",
  //         blockedforBilling: priceData.blockedforBilling || false,
  //         asrUnitOfMeasurement: priceData.asrUnitOfMeasurement || "",
  //         asrThirdPartyAgriculturalSupply:
  //           priceData.asrThirdPartyAgriculturalSupply || false,
  //         isDelete: priceData.isDelete || false,
  //         agriculturalInputGroup: priceData.agriculturalInputGroup || "",
  //       },
  //       priceLists: formattedPriceLists,
  //       createdByUser: "ADMIN",
  //       modifiedByUser: "ADMIN",
  //     };

  //     console.log("🚀 ~ handleUpdate ~ payload:", payload);

  //     const response = await PriceService.createPrice(payload);

  //     setLoading(false);

  //     if (response.status === 201) {
  //       setSeverity("success");
  //       setMessage("Third Party List Updated successfully");
  //       setOpenAlert(true);
  //       navigate("/agriculturalSuppliesRegistrationList");
  //     }
  //   } catch (err) {
  //     setLoading(false);

  //     if (err instanceof Yup.ValidationError) {
  //       const newError = {};
  //       err?.inner?.forEach((error) => {
  //         newError[error.path] = error.message;
  //       });
  //       setErrors(newError);
  //     } else {
  //       console.error("An error occurred while submitting the form:", err);
  //       setSeverity("error");
  //       setMessage(
  //         err?.response?.data?.message ||
  //           "An error occurred while submitting the form"
  //       );
  //       setOpenAlert(true);
  //     }
  //   }
  // };

  const handleUpdate = async (event) => {
    event.preventDefault();

    setLoading(true);
    setErrors({});

    try {
      // Validate partners
      if (!Array.isArray(partners) || partners.length === 0) {
        throw new Error("No partners available to update.");
      }

      const isValidUUID = (id) =>
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(
          id
        );

      const formattedPriceLists = priceList.map((partner) => {
        const formattedPartner = {
          priceListId: isValidUUID(partner.id) ? partner.id : undefined, // Only include if valid UUID
          startDate: partner.startDate || "",
          endDate: partner.endDate || "",
          minimumQuantity: partner.minimumQuantity || 0,
          price: parseFloat(partner.price) || 0.0,
          priceDefinedBy:
            partner.hierarchicalNodeId && !partner.cityId
              ? "Hierarchical Node"
              : "City",
          supplierId: partner.supplier,
        };

        // Add city-related fields if they have valid values
        if (partner.cityId) {
          formattedPartner.cityId = partner.cityId;
          formattedPartner.cityName = partner.cityName || "";
        }

        // Add hierarchical node-related fields if they have valid values
        if (partner.hierarchicalNodeId && !partner.city) {
          formattedPartner.hierarchicalNodeId = partner.hierarchicalNodeId;
          formattedPartner.hierarchicalNodeName =
            partner.hierarchicalNodeName || "";
        }

        return formattedPartner;
      });

      const payload = {
        agriculturalSupplies: {
          createdDateTimeUTC:
            priceData.createdDateTimeUTC || new Date().toISOString(),
          createdByUser: priceData.createdByUser || "ADMIN",
          modifiedDateTimeUTC:
            priceData.modifiedDateTimeUTC || new Date().toISOString(),
          modifiedByUser: priceData.modifiedByUser || "ADMIN",
          asrSupplyId: priceData.asrSupplyId || "",
          asrSupplyName: priceData.asrSupplyName || "",
          asrCode: priceData.asrCode || 0,
          sapCode: priceData.sapCode || 0,
          supplyTypeName: priceData.supplyTypeName || "",
          unlockedforOffSeason: priceData.unlockedforOffSeason || false,
          notConfigured: priceData.notConfigured || false,
          displayName: priceData.displayName || "",
          typeOfUse: priceData.typeOfUse || "",
          blockedforBilling: priceData.blockedforBilling || false,
          asrUnitOfMeasurement: priceData.asrUnitOfMeasurement || "",
          asrThirdPartyAgriculturalSupply:
            priceData.asrThirdPartyAgriculturalSupply || false,
          isDelete: priceData.isDelete || false,
          agriculturalInputGroup: priceData.agriculturalInputGroup || "",
        },
        priceLists: formattedPriceLists,
        createdByUser: "ADMIN",
        modifiedByUser: "ADMIN",
      };

      console.log("🚀 ~ handleUpdate ~ payload:", payload);

      const response = await PriceService.createPrice(payload);

      setLoading(false);

      if (response.status === 201) {
        setSeverity("success");
        setMessage("Third Party List Updated successfully");
        setOpenAlert(true);
        navigate("/agriculturalSuppliesRegistrationList");
      }
    } catch (err) {
      setLoading(false);

      if (err instanceof Yup.ValidationError) {
        const newError = {};
        err?.inner?.forEach((error) => {
          newError[error.path] = error.message;
        });
        setErrors(newError);
      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage(
          err?.response?.data?.message ||
            "An error occurred while submitting the form"
        );
        setOpenAlert(true);
      }
    }
  };

  // useEffect(() => {
  //   const fetchTransporterData = async () => {
  //     if (row?.transporterID) {
  //       try {
  //         const response = await TransporterRegistrationService.getTransporter(
  //           row.transporterID
  //         );
  //         const transporterData = response.data.response;
  //         console.log("transporterData", transporterData);

  //         // Find the matching TM and FT users
  //         const selectedTM = TM.find(
  //           (tm) => tm.userId === transporterData.tmResponsibleUID
  //         );
  //         const selectedFT = FT.find(
  //           (ft) => ft.userId === transporterData.ftResponsibleUID
  //         );

  //         setValues((prevValues) => ({
  //           ...prevValues,
  //           // ... other fields ...
  //           tmResponsibleUID:
  //             selectedTM?.userId || transporterData.tmResponsibleUID,
  //           ftResponsibleUID:
  //             selectedFT?.userId || transporterData.ftResponsibleUID,
  //           fiscalID: transporterData?.fiscalID || "",

  //           // ... other fields ...
  //         }));

  //         if (
  //           transporterData.partnerListList &&
  //           Array.isArray(transporterData.partnerListList)
  //         ) {
  //           const existingPartners = transporterData.partnerListList
  //             .slice(0, 5)
  //             .map((partner) => ({
  //               id: partner.id || Math.random(),
  //               plName: partner.plName || "",
  //               plFiscalID: partner.plFiscalID || "",
  //               plCardId: partner.plCardId || "",
  //               plDOB: partner.plDOB ? dayjs(partner.plDOB) : null,
  //               plEmail: partner.plEmail || "",
  //               plPhoneNumber: partner.plPhoneNumber || "",
  //               partnersListId: partner.partnersListId || "",
  //             }));
  //           console.log(
  //             "🚀 ~ existingPartners ~ existingPartners:",
  //             existingPartners
  //           );

  //           setPartners(existingPartners);
  //         } else {
  //           setPartners([]); // Reset to empty array if no partners exist
  //         }

  //         const bankResponse = await TransporterGetService.getBankDetails();
  //         const bankData = bankResponse.data.response;
  //         const selectedBank = bankData.find(
  //           (b) => b.bankRegistrationId === transporterData.bankRegistrationId
  //         );

  //         // Update the values state with the fetched data
  //         setValues((prevValues) => ({
  //           ...prevValues,
  //           // Map API response fields to your form fields
  //           BusinessType:
  //             transporterData.businessType === "L"
  //               ? "Legal Entity"
  //               : "Individual",
  //           fiscalID: transporterData.fiscalID || "",
  //           transporterName: transporterData.transporterName || "",
  //           region: transporterData.regionId || "",
  //           stateRegistrationNumber:
  //             transporterData.stateRegistrationNumber || "",
  //           rntrc: transporterData.transporterLicenseNumber || "",

  //           // Route Details
  //           buyingStation: transporterData.buyingStation || "",
  //           buyingStationRoute: transporterData.buyingStationRoute || "",
  //           supplyInvoicingUnit: transporterData.supplyInvoicingUnit || "",
  //           supplyInvoicingUnitRoute:
  //             transporterData.supplyInvoicingUnitRoute || "",

  //           // Bank Information
  //           bankAccountType: transporterData.bankAccountType || "",
  //           bankRegistrationId: transporterData.bankRegistrationId || "",
  //           bankBranchRegistrationId:
  //             transporterData.bankBranchRegistrationID || "", // Note: ID is uppercase
  //           accountNumber: transporterData.accountNumber || "",
  //           bankCode: selectedBank?.bankCode || "",

  //           // Additional fields from your form that might not be in the API response
  //           nationalityId: transporterData.nationalityId,
  //           maritalStatusId: transporterData.maritalStatusId || "",
  //           tmResponsibleUID: transporterData.tmResponsibleUID || "",
  //           ftResponsibleUID: transporterData.ftResponsibleUID || "",
  //           sapVendorCode: transporterData.sapVendorCode || "",
  //           coupaRegisterNumber: transporterData.coupaRegisterNumber || "",
  //           ciotRequirement: transporterData.cioTRequirement ? "YES" : "NO", // Note the capital T
  //           tallVoucher: transporterData.tallVoucher ? "YES" : "NO",
  //           externalCode: transporterData.externalCode || "",

  //           // CNPJ Card Information
  //           tradeName: transporterData.tradeName || "",
  //           businessSize: transporterData.businessSize || "",
  //           mainEconomicActivityCode:
  //             transporterData.mainEconomicActivityCode || "",
  //           secondaryEconomicActivityCode:
  //             transporterData.secondaryEconomicActivityCode || "",
  //           legalNatureCode: transporterData.legalNatureCode || "",
  //           legalName: transporterData.legalName || "",
  //           taxation: transporterData.taxation || "",
  //           icmsTaxPercentage:
  //             transporterData.icmsTaxPercentage?.toString() || "",
  //           address: transporterData.address || "",
  //           neighborhood: transporterData.neighborhood || "",
  //           postalCode: transporterData.postalCode || "",
  //           emailForInvoices: transporterData.emailForInvoices || "",
  //           city: transporterData.city || "",
  //         }));

  //         setBank(bankData);

  //         if (transporterData.expirationDateCoupa) {
  //           setExpiryDate(dayjs(transporterData.expirationDateCoupa));
  //         }
  //         if (transporterData.companyEstablishmentDate) {
  //           setEstablishmentDate(
  //             dayjs(transporterData.companyEstablishmentDate)
  //           );
  //         }

  //         if (transporterData.bankRegistrationId) {
  //           fetchBranchDetails(transporterData.bankRegistrationId);
  //         }
  //       } catch (error) {
  //         console.error("Error fetching transporter data:", error);
  //         setSeverity("error");
  //         setMessage("Failed to fetch transporter details");
  //         setOpenAlert(true);
  //       }
  //     }
  //   };

  //   fetchTransporterData();
  // }, [row?.transporterID, TM, FT]);

  // Add this useEffect after your other useEffects
  useEffect(() => {
    if (row?.bankRegistrationId && bank.length > 0) {
      const selectedBank = bank.find(
        (b) => b.bankRegistrationId === row.bankRegistrationId
      );
      if (selectedBank) {
        setValues((prevValues) => ({
          ...prevValues,
          bankCode: selectedBank.bankCode,
          bankRegistrationId: row.bankRegistrationId,
        }));

        // Also fetch branch details if needed
        fetchBranchDetails(row.bankRegistrationId);
      }
    }
  }, [row, bank]); // Depend on both row and bank array

  useEffect(() => {
    const initializeBankData = async () => {
      if (row?.bankRegistrationId) {
        try {
          const response = await TransporterGetService.getBankDetails();
          const bankData = response.data.response;
          setBank(bankData);

          const selectedBank = bankData.find(
            (b) => b.bankRegistrationId === row.bankRegistrationId
          );
          if (selectedBank) {
            setValues((prevValues) => ({
              ...prevValues,
              bankCode: selectedBank.bankCode,
            }));
          }
        } catch (error) {
          console.error("Error initializing bank data:", error);
        }
      }
    };

    initializeBankData();
  }, [row?.bankRegistrationId]); // Only run when bankRegistrationId changes

  // Add this function to handle partner field changes

  // Update the common TextField styling to include proper spacing for error messages
  const commonTextFieldSx = {
    "& .MuiOutlinedInput-root": {
      padding: "7px",
      minHeight: "50px",
    },
    "& .MuiInputLabel-root": {
      lineHeight: "1.2em",
    },
    "& .MuiFormHelperText-root": {
      margin: 0,
      marginTop: "3px", // Add some space between field and error message
      position: "absolute",
      bottom: "-20px", // Position error message below the field
    },
    marginBottom: "20px", // Add bottom margin to prevent overlap with next field
  };

  // Add these functions to check for validation errors in specific sections
  const hasTransporterDataErrors = () => {
    const relevantFields = [
      "fiscalID",
      "transporterName",
      "nationalityId",
      "maritalStatusId",
      "regionId",
      "stateRegistrationNumber",
      "rntrc",
      "buyingStation",
      "buyingStationRoute",
      "supplyInvoicingUnit",
      "supplyInvoicingUnitRoute",
      "businessNumber",
    ];

    return relevantFields.some((field) => !!errors[field]);
  };

  const hasBankInfoErrors = () => {
    const relevantFields = [
      "bankAccountType",
      "bankRegistrationId",
      "bankBranchRegistrationId",
      "accountNumber",
    ];

    return relevantFields.some((field) => !!errors[field]);
  };

  // const handleHierarchyChange = (event, newValue) => {
  //   if (newValue) {
  //     setHierarchicalNode(newValue.value);
  //     setHierarchicalNodeId(newValue.value);
  //     setIsTopHierarchyNode(newValue.level === 0);
  //     setChildNodes(
  //       hierarchyOptions.filter(
  //         (node) =>
  //           node.level === newValue.level + 1 &&
  //           node.parentId === newValue.value
  //       )
  //     );
  //     console.log("Selected node:", newValue);
  //     console.log("Node level:", newValue.level);
  //   } else {
  //     setHierarchicalNode("");
  //     setHierarchicalNodeId("");
  //     setIsTopHierarchyNode(false);
  //   }
  // };

  // const handleHierarchyChange = (event, newValue) => {
  //   if (newValue) {
  //     setHierarchicalNode(newValue.value);
  //     setHierarchicalNodeId(newValue.value);
  //     setIsTopHierarchyNode(newValue.level === 0);

  //     // Filter the child nodes for the selected node
  //     setChildNodes(
  //       hierarchyOptions.filter(
  //         (node) => node.level === newValue.level + 1 && node.parentId === newValue.value
  //       )
  //     );

  //     // Filter the partners based on the selected hierarchical node ID
  //     const filteredPartners = partners.filter(
  //       (partner) => partner.hierarchicalNodeId === newValue.value
  //     );
  //     console.log("Filtered Partners:", filteredPartners);

  //     // You can set the filtered partners here if needed
  //     setPartners(filteredPartners);

  //     console.log("Selected node:", newValue);
  //     console.log("Node level:", newValue.level);
  //   } else {
  //     setHierarchicalNode("");
  //     setHierarchicalNodeId("");
  //     setIsTopHierarchyNode(false);
  //   }
  // };

  const handleCityChange = (event, newValue) => {
    console.log("neeewdddd", newValue);
    if (newValue) {
      // Assuming `partner.id` is the partner you're targeting to update
      const cityId = newValue.cropSeasonId; // Make sure `newValue` contains `cityId`
      const cityName = newValue.label; // The city name from the selected option

      // Find the specific partner you want to update based on partner.id
      setPartners((prevPartners) =>
        prevPartners.map((partner) =>
          partner.id === partner.id // You can match the partner.id here
            ? {
                ...partner,
                cityId: cityId, // Update with selected cityId
                cityName: cityName, // Update with selected cityName
              }
            : partner
        )
      );
    }
  };

  const handleHierarchyChange = (event, newValue) => {
    if (newValue) {
      setHierarchicalNode(newValue.value);
      setHierarchicalNodeId(newValue.value);
      setIsTopHierarchyNode(newValue.level === 0);

      // Filter the child nodes for the selected node
      setChildNodes(
        hierarchyOptions.filter(
          (node) =>
            node.level === newValue.level + 1 &&
            node.parentId === newValue.value
        )
      );
      setShowAllPrices(true);
      // Filter the partners based on the selected hierarchical node ID
      const filteredPartners = priceList.filter(
        (partner) => partner.hierarchicalNodeId === newValue.value
      );
      console.log("Filtered Partners:", filteredPartners);

      // Update the filteredPartners state with the selected data
      setFilteredPartners(filteredPartners); // Assuming you have this state set for filtered partners

      console.log("Selected node:", newValue);
      console.log("Node level:", newValue.level);
    } else {
      setHierarchicalNode("");
      setHierarchicalNodeId("");
      setIsTopHierarchyNode(false);

      // If no node is selected, reset filtered partners
      setFilteredPartners([]); // Optional: Reset filtered data when no selection
    }
  };

  const handleCityChangeFilter = (event, newValue) => {
    if (newValue) {
      setHierarchicalNode(newValue.value);
      setHierarchicalNodeId(newValue.value);
      setIsTopHierarchyNode(newValue.level === 0);

      // Filter the child nodes for the selected node
      setChildNodes(
        hierarchyOptions.filter(
          (node) =>
            node.level === newValue.level + 1 &&
            node.parentId === newValue.value
        )
      );

      // Filter the partners based on the selected hierarchical node ID
      const filteredPartners = priceList.filter(
        (partner) => partner.hierarchicalNodeId === newValue.value
      );
      console.log("Filtered Partners:", filteredPartners);

      // Update the filteredPartners state with the selected data
      setFilteredPartners(filteredPartners); // Assuming you have this state set for filtered partners

      console.log("Selected node:", newValue);
      console.log("Node level:", newValue.level);
    } else {
      setHierarchicalNode("");
      setHierarchicalNodeId("");
      setIsTopHierarchyNode(false);

      // If no node is selected, reset filtered partners
      setFilteredPartners([]); // Optional: Reset filtered data when no selection
    }
  };

  // const handleHierarchyChangePrice = (event, newValue) => {
  //   if (newValue) {
  //     setHierarchicalNodePrice(newValue.value);
  //     setHierarchicalNodeIdPrice(newValue.value);
  //     setIsTopHierarchyNode(newValue.level === 0);
  //     setChildNodes(
  //       hierarchyOptions.filter(
  //         (node) =>
  //           node.level === newValue.level + 1 &&
  //           node.parentId === newValue.value
  //       )
  //     );
  //     console.log("Selected node:", newValue);
  //     console.log("Node level:", newValue.level);
  //   } else {
  //     setHierarchicalNodePrice("");
  //     setHierarchicalNodeIdPrice("");
  //     setIsTopHierarchyNode(false);
  //   }
  // };

  // const handlePartnerChange = (partnerId, field, value) => {
  //   setPartners((prevPartners) =>
  //     prevPartners.map((partner) =>
  //       partner.id === partnerId ? { ...partner, [field]: value } : partner
  //     )
  //   );
  // };

  const getSupplierList = async () => {
    try {
      const response = await PriceParameterizationService.getSupplierDetails();
      const d = response.data.response;
      // Store full city objects instead of just names
      setSupplier(d);
    } catch (error) {
      console.error("Error generating unique code:", error);
      return null;
    }
  };

  const handlePartnerChange = (id, field, value) => {
    setPriceList((prevPriceList) =>
      prevPriceList.map((item) => {
        if (item.id === id) {
          if (field === "hierarchicalNode") {
            return {
              ...item,
              hierarchicalNode: value,
              city: !value, // If hierarchicalNode is true, city should be false
            };
          } else if (field === "city") {
            return {
              ...item,
              city: value,
              hierarchicalNode: !value, // If city is true, hierarchicalNode should be false
            };
          } else {
            return { ...item, [field]: value }; // For other fields
          }
        }
        return item; // Return unchanged item for other IDs
      })
    );
    setFilteredPartners((prevPriceList) =>
      prevPriceList.map((item) => {
        if (item.id === id) {
          if (field === "hierarchicalNode") {
            return {
              ...item,
              hierarchicalNode: value,
              city: !value, // If hierarchicalNode is true, city should be false
            };
          } else if (field === "city") {
            return {
              ...item,
              city: value,
              hierarchicalNode: !value, // If city is true, hierarchicalNode should be false
            };
          } else {
            return { ...item, [field]: value }; // For other fields
          }
        }
        return item; // Return unchanged item for other IDs
      })
    );
  };

  // const handlePartnerChange = (partnerId, field, value) => {
  //   setPartners((prevPartners) =>
  //     prevPartners.map((partner) =>
  //       partner.id === partnerId ? { ...partner, [field]: value } : partner
  //     )
  //   );
  // };

  const handleHierarchyChangePrice = (partnerId, event, newValue) => {
    if (newValue) {
      setHierarchicalNodePrice(newValue.value);
      setHierarchicalNodeIdPrice(newValue.value);
      setIsTopHierarchyNode(newValue.level === 0);
      setChildNodes(
        hierarchyOptions.filter(
          (node) =>
            node.level === newValue.level + 1 &&
            node.parentId === newValue.value
        )
      );

      // Update the partner with hierarchical node details
      handlePartnerChange(partnerId, "hierarchicalNodeId", newValue.value);
      handlePartnerChange(partnerId, "hierarchicalNodeName", newValue.label);

      console.log("Selected node:", newValue);
      console.log("Node level:", newValue.level);
    } else {
      setHierarchicalNodePrice("");
      setHierarchicalNodeIdPrice("");
      setIsTopHierarchyNode(false);

      // Clear hierarchical node data for the partner
      handlePartnerChange(partnerId, "hierarchicalNodeId", "");
      handlePartnerChange(partnerId, "hierarchicalNodeName", "");
    }
  };

  console.log("🚀 ~ Price ~ priceData:", priceData);
  useEffect(() => {
    if (row) {
      const initializeBankData = async () => {
        try {
          const response = await PriceService.getPriceData({
            id: row.asrSupplyId,
          });
          console.log("🚀 ~ initializeBankData ~ response:", response);
          const bankData = response.data.response.agriculturalSupplies;
          const price = response.data.response.priceLists;
          setPriceData(bankData);

          console.log("priceddssssssssssssx", price);

          if (price && Array.isArray(price)) {
            const existingPartners = price.map((partner) => {
              const cityData = city.find((c) => c.cityId === partner.cityId);
              return {
                id: partner.priceListId || "",
                minimumQuantity: partner.minimumQuantity || 0,
                price: parseFloat(partner.price) || 0.0,
                selectedOption: partner.priceDefinedBy || "Default",
                hierarchicalNodeId: partner.hierarchicalNodeId || "",
                hierarchicalNodeName: partner.hierarchicalNodeName || "",
                hierarchicalNode: !!partner.hierarchicalNodeId,

                startDate: partner.startDate || "",
                endDate: partner.endDate || "",
                cityId: partner.cityId || "",
                cityName: cityData?.cityName || "", // Ensure cityName is a string
                priceDefinedBy: cityData?.cityId || "", // Ensure this is set to cityName
                city: !!partner.cityId,
                supplier: partner.supplierId,
              };
            });

            // id: item.priceListId,
            // priceDefinedBy: item.cityId
            //   ? city.find((c) => c.cityId === item.cityId)
            //   : hierarchyOptions.find((c) => c.value === item.importHierarchyId),
            // startDate: item.startDate,
            // endDate: item.endDate,
            // supplier: supplier.find(
            //   (item2) => item2.suppliesRegistrationId === item.suppliesRegistrationId
            // ),
            // minimumQuantity: item.minimumQuantity,
            // price: item.price,
            // hierarchicalNode: item.importHierarchyId ? true : false,
            // city: item.cityId ? true : false,

            console.log(
              "🚀 ~ existingPartners ~ existingPartners:",
              existingPartners
            );

            setPriceList(existingPartners);
          } else {
            setPartners([]); // Reset to empty array if no partners exist
          }
        } catch (error) {
          console.error("Error initializing bank data:", error);
        }
      };

      initializeBankData();
    }
  }, []);

  useEffect(() => {
    fetchInitialData();
    getCityList();
    getSupplierList();
    // handleViewHistory();
  }, []);

  const handleDeletePrice = (priceId) => {
    setPriceList(priceList.filter((price) => price.id !== priceId));
  };

  return (
    <Layout title={t("price.pageTitle")} background="#ffffff">
      <Box
        sx={{
          marginTop: "20px",
          minHeight: "125vh",
          flexGrow: 1,
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <form autoComplete="off" noValidate>
          <Box
            sx={{
              bgcolor: "#EFEFEF",
              height: hasTransporterDataErrors() ? "300px" : "300px",
              minHeight: "auto", // Changed to auto
              padding: "10px",
              borderRadius: "5px",
              marginBottom: 1, // Added small margin bottom
            }}
          >
            <Box sx={{ display: "flex" }}>
              <ArrowDropDownIcon sx={{ marginRight: 1 }} />
              <Typography>{t("price.agriculturalSupply")}</Typography>
            </Box>

            <>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <TextField
                      name="code"
                      label={t("code")}
                      value={priceData.asrCode ? priceData.asrCode : " "}
                      // onChange={handleChange}
                      disabled={true}
                      fullWidth
                      error={!!errors.code}
                      helperText={errors.code || ""}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "7px",
                          minHeight: "50px",
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "1.2em",
                        },
                        "& .MuiFormHelperText-root": {
                          margin: 0,
                          height: errors.fiscalID ? "20px" : "0px",
                          opacity: errors.fiscalID ? 1 : 0,
                          transition: "all 200ms",
                        },
                        marginBottom: errors.fiscalID ? "20px" : "0px",
                        transition: "margin 200ms",
                      }}
                      InputProps={{
                        style: { height: "50px" },
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <TextField
                      name="sapCode"
                      label={t("price.sapCode")}
                      value={priceData.sapCode ? priceData.sapCode : " "}
                      onChange={handleChange}
                      disabled={true}
                      fullWidth
                      error={!!errors.sapCode}
                      helperText={errors.sapCode || ""}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "7px",
                          minHeight: "50px",
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "1.2em",
                        },
                        "& .MuiFormHelperText-root": {
                          margin: 0,
                          height: errors.transporterName ? "20px" : "0px",
                          opacity: errors.transporterName ? 1 : 0,
                          transition: "all 200ms",
                        },
                        marginBottom: errors.transporterName ? "20px" : "0px",
                        transition: "margin 200ms",
                      }}
                      InputProps={{
                        style: { height: "50px" },
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={5.8}>
                  <FormControl fullWidth>
                    <TextField
                      name="name"
                      label={t("price.name")}
                      value={
                        priceData.displayName ? priceData.displayName : " "
                      }
                      onChange={handleChange}
                      disabled={true}
                      fullWidth
                      error={!!errors.name}
                      helperText={errors.name || ""}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "7px",
                          minHeight: "50px",
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "1.2em",
                        },
                        "& .MuiFormHelperText-root": {
                          margin: 0,
                          height: errors.transporterName ? "20px" : "0px",
                          opacity: errors.transporterName ? 1 : 0,
                          transition: "all 200ms",
                        },
                        marginBottom: errors.transporterName ? "20px" : "0px",
                        transition: "margin 200ms",
                      }}
                      InputProps={{
                        style: { height: "50px" },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <TextField
                      name="uom"
                      label={t("price.uom")}
                      value={
                        priceData.asrUnitOfMeasurement
                          ? priceData.asrUnitOfMeasurement
                          : " "
                      }
                      onChange={handleChange}
                      disabled={true}
                      fullWidth
                      error={!!errors.uom}
                      helperText={errors.uom || ""}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "7px",
                          minHeight: "50px",
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "1.2em",
                        },
                        "& .MuiFormHelperText-root": {
                          margin: 0,
                          height: errors.transporterName ? "20px" : "0px",
                          opacity: errors.transporterName ? 1 : 0,
                          transition: "all 200ms",
                        },
                        marginBottom: errors.transporterName ? "20px" : "0px",
                        transition: "margin 200ms",
                      }}
                      InputProps={{
                        style: { height: "50px" },
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth>
                    <TextField
                      name="supplyType"
                      label={t("price.supplyType")}
                      value={
                        priceData.asrSupplyName ? priceData.asrSupplyName : " "
                      }
                      onChange={handleChange}
                      disabled={true}
                      fullWidth
                      error={!!errors.supplyType}
                      helperText={errors.supplyType || ""}
                      SelectProps={{ IconComponent: ArrowDropDownIcon }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "7px",
                          minHeight: "50px",
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "1.2em",
                        },
                        "& .MuiFormHelperText-root": {
                          margin: 0,
                          height: errors.nationalityId ? "20px" : "0px",
                          opacity: errors.nationalityId ? 1 : 0,
                          transition: "all 200ms",
                        },
                        marginBottom: errors.nationalityId ? "20px" : "0px",
                        transition: "margin 200ms",
                      }}
                      InputProps={{
                        style: { height: "50px" },
                      }}
                    ></TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <TextField
                      name="typeOfUse"
                      label={t("price.typeOfUse")}
                      value={priceData.typeOfUse || ""} // Add default empty string
                      onChange={handleChange}
                      disabled={true}
                      fullWidth
                      error={!!errors.typeOfUse}
                      helperText={errors.typeOfUse || ""}
                      SelectProps={{ IconComponent: ArrowDropDownIcon }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "7px",
                          minHeight: "50px",
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "1.2em",
                        },
                        "& .MuiFormHelperText-root": {
                          margin: 0,
                          height: errors.maritalStatusId ? "20px" : "0px",
                          opacity: errors.maritalStatusId ? 1 : 0,
                          transition: "all 200ms",
                        },
                        marginBottom: errors.maritalStatusId ? "20px" : "0px",
                        transition: "margin 200ms",
                      }}
                      InputProps={{
                        style: { height: "50px" },
                      }}
                    ></TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2.8}>
                  <FormControl fullWidth>
                    <TextField
                      name="aig"
                      label={t("price.aig")}
                      value={priceData.agriculturalInputGroup || ""} // Add default empty string
                      onChange={handleChange}
                      disabled={true}
                      fullWidth
                      error={!!errors.aig}
                      helperText={errors.aig || ""}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "7px",
                          minHeight: "50px",
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "1.2em",
                        },
                        "& .MuiFormHelperText-root": {
                          margin: 0,
                          height: errors.regionId ? "20px" : "0px",
                          opacity: errors.regionId ? 1 : 0,
                          transition: "all 200ms",
                        },
                        marginBottom: errors.regionId ? "20px" : "0px",
                        transition: "margin 200ms",
                      }}
                      InputProps={{
                        style: { height: "50px" },
                      }}
                    ></TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <TextField
                      name="seedType"
                      label={t("price.seedType")}
                      value={priceData.seedType || " "}
                      onChange={handleChange}
                      disabled={true}
                      fullWidth
                      error={!!errors.seedType} // Show error state
                      helperText={errors.seedType || ""} // Show error message
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "7px",
                          minHeight: "50px",
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "1.2em",
                        },
                        "& .MuiFormHelperText-root": {
                          margin: 0,
                          height: errors.stateRegistrationNumber
                            ? "20px"
                            : "0px",
                          opacity: errors.stateRegistrationNumber ? 1 : 0,
                          transition: "all 200ms",
                        },
                        marginBottom: errors.stateRegistrationNumber
                          ? "20px"
                          : "0px",
                        transition: "margin 200ms",
                      }}
                      InputProps={{
                        style: { height: "50px" },
                      }}
                    >
                      {dataType.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
            </>

            {/* Route Details Section */}
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={11.8}>
                <FormControl fullWidth>
                  <TextField
                    name={t("price.lockInformation")}
                    label={t("price.lockInformation")}
                    onChange={handleChange}
                    disabled={true}
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "inherit", // Keeps border color consistent on hover
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 0)", // Default border color
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                        fontWeight: "bold", // Makes label bold
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              {" "}
              {/* Added container margin */}
              <Grid item xs={12} sm={6} md={4}>
                {" "}
                {/* Increased item margin */}
                <FormControl fullWidth>
                  <TextField
                    name="bfb"
                    label={t("price.bfb")}
                    value={priceData.blockedforBilling || " "}
                    onChange={handleChange}
                    disabled={true}
                    fullWidth
                    error={!!errors.bfb}
                    helperText={errors.bfb || ""}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& .MuiFormHelperText-root": {
                        margin: 0,
                        height: errors.buyingStation ? "20px" : "0px",
                        opacity: errors.buyingStation ? 1 : 0,
                        transition: "all 200ms",
                      },
                      marginBottom: errors.buyingStation ? "20px" : "0px",
                      transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={7.8} sx={{ marginBottom: "25px" }}>
                <FormControl fullWidth>
                  <TextField
                    name="uos"
                    label={t("price.uos")}
                    value={
                      priceData.unlockedforOffSeason ? "True" : "False" || " "
                    }
                    onChange={handleChange}
                    disabled={true}
                    fullWidth
                    error={!!errors.uos}
                    helperText={errors.uos || ""}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& .MuiFormHelperText-root": {
                        margin: 0,
                        height: errors.buyingStationRoute ? "20px" : "0px",
                        opacity: errors.buyingStationRoute ? 1 : 0,
                        transition: "all 200ms",
                      },
                      marginBottom: errors.buyingStationRoute ? "20px" : "0px",
                      transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "8px 2px",
                background: "#FFFFFF",
                marginTop: 3,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "20px",
                  color: "#5A646E",
                  flex: "none",
                  order: 0,
                  flexGrow: 0,
                  zIndex: 0,
                }}
              >
                Price List
              </Typography>
              {edit && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ mr: "6px" }}>
                    {t("priceParameterization.hideOldRecords")}
                  </Box>
                  <RedSwitch
                    color="primary"
                    name="cclStatus"
                    //checked={c?.cclStatus}
                    // onChange={(event) =>
                    //   handleChange(
                    //     event,
                    //     index,
                    //     c.cultureTypeCountryLinkId
                    //   )
                    // }
                    checked={showAllPrices}
                    onChange={handleToggle}
                  />

                  <Box sx={{ ml: "6px", mr: "6px" }}>
                    {t("priceParameterization.showAll")}
                  </Box>
                  <Button
                    variant="contained"
                    onClick={handleAddPrice}
                    sx={{
                      minWidth: 95,
                      borderRadius: 1,
                      background: "#004F9F",
                      height: { xs: "40px", md: "44px" },
                      marginLeft: { xs: 0, md: "auto" },
                    }}
                  >
                    {t("addNew")}
                  </Button>
                </Box>
              )}
            </Box>
            <Box
              id="divider"
              sx={{
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px 20px 0px",
                gap: "20px",
                isolation: "isolate",
                background: "#FFFFFF",
                borderBottom: "5px solid #004F9F",
                flex: "none",
                order: 2,
                alignSelf: "stretch",
                flexGrow: 0,
                zIndex: 1,
              }}
            ></Box>
          </Box>
          <Grid
            container
            id="mainfilter"
            spacing={2}
            sx={{
              background: "#EFEFEF",
              borderRadius: "5px",
              p: 2,
              maxWidth: "100%",
              margin: "auto",
              paddingBottom: "34px",
              marginBottom: "12px",
              marginTop: "15px",
            }}
          >
            <Grid
              item
              xs={12}
              style={{ paddingTop: "0px", paddingLeft: "0px" }}
            >
              <Typography variant="h8" display="flex">
                <ArrowDropDownIcon />
                {"Filters"}
              </Typography>
            </Grid>

            {/* <Grid item xs={3.5} md={1.5} style={{ paddingLeft: "0px" }}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    fullWidth
                    id="startDate"
                    name="startDate"
                    label="Date"
                    format="DD/MM/YYYY"
                    value={startDate ? dayjs(startDate) : null}
                    onChange={(date) =>
                      handleChangeDate({
                        target: { name: "startDate", value: date },
                      })
                    }
                    open={open}
                    onClose={() => setOpen(false)}
                    renderInput={(params) => <TextField {...params} />}
                    slotProps={{
                      field: {
                        readOnly: true,
                      },
                      textField: {
                        onClick: () => setOpen(true),
                      },
                    }}
                    error={!!errors.startDate}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid> */}

<Grid item xs={3.5} md={1.5} style={{ paddingLeft: "0px" }}>
  <FormControl fullWidth>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        fullWidth
        id="startDate"
        
        name="startDate"
        label="Date"
        format="DD/MM/YYYY"
        value={startDate ? dayjs(startDate) : null}
        onChange={(date) =>
          handleChangeDate({
            target: { name: "startDate", value: date },
          })
        }
        open={open}
        onClose={() => setOpen(false)}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleChangeDate({ target: { name: "startDate", value: null } })}
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                  {params.InputProps?.endAdornment}
                </>
              ),
            }}
          />
        )}
        slotProps={{
          field: {
            clearable: true 
          },
          textField: {
            onClick: () => setOpen(true),
          },
        }}
        error={!!errors.startDate}
      />
    </LocalizationProvider>
  </FormControl>
</Grid>


            <Grid item xs={3.5}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    hierarchyOptions.find(
                      (option) => option.value === hierarchicalNode
                    ) || null
                  }
                  // disableClearable={true}
                  onChange={handleHierarchyChange}
                  options={hierarchyOptions}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <div>
                        <div style={{ fontSize: "16px" }}>{option.label}</div>
                        <div style={{ fontSize: "10px", color: "#5A646E" }}>
                          {option.tag}
                        </div>
                      </div>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("investmentGroupLimits.hnSearchField")}
                      fullWidth
                      placeholder="Select an Option"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          opacity: 1,
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={SearchIcon}
                              alt="search"
                              style={{
                                width: "20px",
                                height: "20px",
                                color: "#737D86",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3.5} style={{ marginLeft: "0px" }}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    cropSeasonOptions.find(
                      (option) => option.value === cropSeason
                    ) || null
                  }
                  // disableClearable={true}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setCropSeason(newValue.value);
                      setCropSeasonId(newValue.cropSeasonId); // This will trigger useEffect

                      // Update child nodes based on the selected crop season
                      setShowAllPrices(true);

                      // Filter partners related to the selected crop season
                      const filteredPartners = priceList.filter(
                        (partner) => partner.cityId === newValue.cropSeasonId
                      );
                      console.log("Filtered Partners:", filteredPartners);
                      setFilteredPartners(filteredPartners); // Update filtered partners state
                    } else {
                      setCropSeason("");
                      setCropSeasonId(""); // Reset crop season state
                      setChildNodes([]); // Clear child nodes if no selection
                      setFilteredPartners([]); // Clear filtered partners
                    }
                  }}
                  // onChange={(event, newValue) => {
                  //   if (newValue) {
                  //     setCropSeason(newValue.value);
                  //     setCropSeasonId(newValue.cropSeasonId); // This will trigger useEffect
                  //   } else {
                  //     setCropSeason("");
                  //     setCropSeasonId(""); // This will trigger useEffect
                  //   }
                  // }}
                  // onInputChange={renderProductionTargetsTable()}
                  options={cropSeasonOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"City"}
                      fullWidth
                      placeholder="Select an Option"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          opacity: 1,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          {filterPartners && filterPartners.length > 0
            ? filterPartners.map((price) => (
                <Box
                  key={price.id}
                  sx={{
                    minHeight: "10%",
                    marginTop: 5,
                    flexGrow: 1,
                    paddingBottom: "0.5rem",
                    width: "100%",
                    "& .MuiTextField-root": { m: 1 },
                    "& .MuiFormLabel-root": {
                      color: "rgba(16, 42, 98, 1)",
                      "& .MuiFormLabel-asterisk": {
                        color: "rgba(239, 0, 0, 1)",
                        fontSize: "18px",
                      },
                    },
                  }}
                >
                  <form autoComplete="off" noValidate>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        bgcolor: "#EFEFEF",
                        height: "120px",
                        marginTop: "-25px",
                        borderRadius: "5px",
                        paddingRight: "10px",
                      }}
                    >
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={6} md={1.9}>
                          <FormControl fullWidth>
                            <DateRangePicker
                              id={price.id}
                              onSelectDateRange={handlePartnerChange}
                              start={price.startDate}
                              end={price.endDate}
                              edit={edit}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={2.1}>
                          {/* <FormControl fullWidth error={!!errors.fiscalID}>
                            <Autocomplete
                              value={price?.supplier || null}
                              disabled={edit == false}
                              onChange={(event, newValue) => {
                                handlePartnerChange(
                                  price.id,
                                  "supplier",
                                  newValue
                                );
                              }}
                              options={supplier}
                              getOptionLabel={(option) =>
                                option.supplierCompanyName || ""
                              }
                              isOptionEqualToValue={(option, value) =>
                                option.suppliesRegistrationId ===
                                value?.suppliesRegistrationId
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t("priceParameterization.supplier")}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                  disabled={edit == false}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      padding: "0 14px", // Adjust padding to fit the height
                                      minHeight: "50px",
                                      display: "flex",
                                      alignItems: "center",
                                    },
                                    "& input": {
                                      padding: "0 14px", // Add left and right padding without increasing height
                                    },
                                    "& .MuiFormHelperText-root": {
                                      margin: 0,
                                      height: errors.fiscalID ? "20px" : "0px",
                                      opacity: errors.fiscalID ? 1 : 0,
                                      transition: "all 200ms",
                                    },
                                    marginBottom: errors.fiscalID
                                      ? "20px"
                                      : "0px",
                                    transition: "margin 200ms",
                                  }}
                                  inputProps={{
                                    ...params.inputProps,
                                    style: { height: "100%" }, // Ensure the input fills the available height
                                  }}
                                />
                              )}
                              renderOption={(props, option) => (
                                <li
                                  {...props}
                                  key={option.suppliesRegistrationId}
                                >
                                  {option.supplierCompanyName}
                                </li>
                              )}
                              sx={{
                                "& .MuiAutocomplete-inputRoot": {
                                  padding: "0 !important", // Prevent extra padding in input
                                  minHeight: "50px", // Ensure consistent height
                                },
                              }}
                              fullWidth
                            />
                          </FormControl> */}
                          <FormControl fullWidth error={!!errors.fiscalID}>
                            <Autocomplete
                              value={
                                supplier.find(
                                  (opt) =>
                                    opt.suppliesRegistrationId ===
                                    price?.supplier
                                ) || null
                              }
                              disabled={edit == false}
                              onChange={(event, newValue) => {
                                handlePartnerChange(
                                  price.id,
                                  "supplier",
                                  newValue.suppliesRegistrationId
                                );
                              }}
                              options={supplier}
                              getOptionLabel={(option) =>
                                option.supplierCompanyName || ""
                              }
                              isOptionEqualToValue={(option, value) =>
                                option.suppliesRegistrationId ===
                                value?.suppliesRegistrationId
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t("priceParameterization.supplier")}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                  disabled={edit == false}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      padding: "0 14px", // Adjust padding to fit the height
                                      minHeight: "50px",
                                      display: "flex",
                                      alignItems: "center",
                                    },
                                    "& input": {
                                      padding: "0 14px", // Add left and right padding without increasing height
                                    },
                                    "& .MuiFormHelperText-root": {
                                      margin: 0,
                                      height: errors.fiscalID ? "20px" : "0px",
                                      opacity: errors.fiscalID ? 1 : 0,
                                      transition: "all 200ms",
                                    },
                                    marginBottom: errors.fiscalID
                                      ? "20px"
                                      : "0px",
                                    transition: "margin 200ms",
                                  }}
                                  inputProps={{
                                    ...params.inputProps,
                                    style: { height: "100%" }, // Ensure the input fills the available height
                                  }}
                                />
                              )}
                              renderOption={(props, option) => (
                                <li
                                  {...props}
                                  key={option.suppliesRegistrationId}
                                >
                                  {option.supplierCompanyName}
                                </li>
                              )}
                              sx={{
                                "& .MuiAutocomplete-inputRoot": {
                                  padding: "0 !important", // Prevent extra padding in input
                                  minHeight: "50px", // Ensure consistent height
                                },
                              }}
                              fullWidth
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={1.9}>
                          <FormControl fullWidth>
                            <TextField
                              name="minimumQuantity"
                              label={t("priceParameterization.minimumQuantity")}
                              //InputLabelProps={{ shrink: true }}
                              value={price?.minimumQuantity}
                              onChange={(e) =>
                                handlePartnerChange(
                                  price.id,
                                  "minimumQuantity",
                                  e.target.value
                                )
                              }
                              disabled={edit == false}
                              fullWidth
                              error={!!errors.transporterName} // Show error state
                              helperText={errors.transporterName || ""} // Show error message
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  padding: "7px",
                                  minHeight: "50px",
                                  // bgcolor: "#E9EBEE",
                                },
                                "& .MuiInputLabel-root": {
                                  lineHeight: "1.2em",
                                },
                                //   "& fieldset": {
                                //     borderColor: "#BDBDBD", // Default border color
                                //   },
                                //   "&:hover fieldset": {
                                //     borderColor: "#BDBDBD !important", // Override hover border color
                                //   },
                                //   "& .MuiFormHelperText-root": {
                                //     margin: 0,
                                //     height: errors.transporterName ? "20px" : "0px",
                                //     opacity: errors.transporterName ? 1 : 0,
                                //     transition: "all 200ms",
                                //   },
                                //   marginBottom: errors.transporterName ? "20px" : "0px",
                                //   transition: "margin 200ms",
                              }}
                              InputProps={{
                                style: { height: "50px" },
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={1}>
                          <FormControl fullWidth>
                            <TextField
                              name="price"
                              label={t("priceParameterization.price")}
                              //InputLabelProps={{ shrink: true }}
                              value={price?.price}
                              onChange={(e) =>
                                handlePartnerChange(
                                  price.id,
                                  "price",
                                  e.target.value
                                )
                              }
                              disabled={edit == false}
                              fullWidth
                              error={!!errors.fiscalID} // Show error state
                              helperText={errors.fiscalID || ""} // Show error message
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  padding: "7px",
                                  minHeight: "50px",
                                  //bgcolor: "#E9EBEE",
                                },
                                "& .MuiInputLabel-root": {
                                  lineHeight: "1.2em",
                                },
                                //   "& fieldset": {
                                //     borderColor: "#BDBDBD", // Default border color
                                //   },
                                //   "&:hover fieldset": {
                                //     borderColor: "#BDBDBD !important", // Override hover border color
                                //   },
                                //   "& .MuiFormHelperText-root": {
                                //     margin: 0,
                                //     height: errors.fiscalID ? "20px" : "0px",
                                //     opacity: errors.fiscalID ? 1 : 0,
                                //     transition: "all 200ms",
                                //   },
                                //   marginBottom: errors.fiscalID ? "20px" : "0px",
                                //   transition: "margin 200ms",
                              }}
                              InputProps={{
                                style: { height: "50px" },
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2.5}>
                          <FormControl component="fieldset">
                            <FormLabel
                              component="legend"
                              disabled={edit == false}
                              sx={{
                                marginLeft: 2.5,
                                "&.Mui-focused": { color: "inherit" },
                                "&:hover": { color: "inherit" },
                              }}
                            >
                              {t("priceParameterization.priceDefinedBy")}
                            </FormLabel>
                            <RadioGroup
                              sx={{
                                marginLeft: 2.5,
                                display: "flex",
                                flexDirection: "row", // Ensures horizontal layout
                              }}
                              value={
                                priceList.find((item) => item.hierarchicalNode)
                                  ?.id || ""
                              }
                            >
                              <FormControlLabel
                                value={price.id}
                                disabled={edit == false}
                                onChange={() =>
                                  handlePartnerChange(
                                    price.id,
                                    "hierarchicalNode"
                                  )
                                }
                                name="hierarchicalNode"
                                control={<Radio />}
                                label={t(
                                  "priceParameterization.hierarchicalNode"
                                )}
                                sx={{
                                  display: "flex", // Ensures each label is aligned horizontally
                                  alignItems: "center", // Ensures items are vertically aligned
                                  marginRight: 2, // Optional: Adds space between labels
                                }}
                                checked={price.hierarchicalNode === true}
                              />
                              <FormControlLabel
                                value={price.id}
                                disabled={edit == false}
                                onChange={() =>
                                  handlePartnerChange(price.id, "city")
                                }
                                name="city"
                                control={<Radio />}
                                label={t("priceParameterization.city")}
                                sx={{
                                  display: "flex", // Ensures each label is aligned horizontally
                                  alignItems: "center", // Ensures items are vertically aligned
                                  marginRight: 0, // Optional: Removes extra margin on the right
                                  //marginLeft:"-30px",
                                }}
                                checked={price.city === true}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        {price.hierarchicalNode && (
                          <Grid item xs={12} sm={6} md={2.5}>
                            <FormControl fullWidth>
                              <Autocomplete
                                value={
                                  hierarchyOptions.find(
                                    (option) =>
                                      option.value === price.hierarchicalNodeId
                                  ) || null
                                }
                                disableClearable={true}
                                onChange={(event, newValue) => {
                                  setPriceList((prevPriceList) =>
                                    prevPriceList.map((item) =>
                                      item.id === price.id
                                        ? {
                                            ...item,
                                            hierarchicalNodeId:
                                              newValue?.value || "", // Update with the correct value
                                          }
                                        : item
                                    )
                                  );
                                }}
                                options={hierarchyOptions}
                                getOptionLabel={(option) =>
                                  option.label || "No option selected"
                                }
                                // renderOption={(props, option) => (
                                //   <li {...props}>
                                //     <div>
                                //       <div style={{ fontSize: "16px" }}>
                                //         {option.label}
                                //       </div>
                                //       <div
                                //         style={{
                                //           fontSize: "10px",
                                //           color: "#5A646E",
                                //         }}
                                //       >
                                //         {option.tag}
                                //       </div>
                                //     </div>
                                //   </li>
                                // )}
                                renderInput={(params) => (
                                  <TextField
                                    name="hierarchicalNode"
                                    {...params}
                                    label={t("Hierarchical Node")}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    sx={{
                                      "& .MuiInputBase-input::placeholder": {
                                        opacity: 1,
                                      },
                                    }}
                                    InputProps={{
                                      ...params.InputProps,
                                      style: { height: "50px" },
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {price.city && (
                          <Grid item xs={12} sm={6} md={2.5}>
                            <FormControl fullWidth error={!!errors.fiscalID}>
                              <Autocomplete
                                value={
                                  city.find(
                                    (option) => option.cityId === price.cityId
                                  ) || null
                                }
                                onChange={(event, newValue) => {
                                  setPriceList((prevPriceList) =>
                                    prevPriceList.map((item) =>
                                      item.id === price.id
                                        ? {
                                            ...item,
                                            cityId: newValue?.cityId || "", // Use cityId or empty string
                                          }
                                        : item
                                    )
                                  );
                                }}
                                options={city}
                                getOptionLabel={(option) =>
                                  option.cityName || "No city selected"
                                }
                                disabled={edit == false}
                                disableClearable
                                // onChange={(event, newValue) => {
                                //   if (newValue) {
                                //     setPriceList((prevPriceList) =>
                                //       prevPriceList.map((item) =>
                                //         item.id === price.id
                                //           ? {
                                //               ...item,
                                //               priceDefinedBy: {
                                //                 ...price.priceDefinedBy,
                                //                 cityName: newValue,
                                //               },
                                //             }
                                //           : item
                                //       )
                                //     );
                                //   } else {
                                //     setPriceList((prevPriceList) =>
                                //       prevPriceList.map((item) =>
                                //         item.id === price.id
                                //           ? {
                                //               ...item,
                                //               priceDefinedBy: {
                                //                 ...price.priceDefinedBy,
                                //                 cityName: "",
                                //               },
                                //             }
                                //           : item
                                //       )
                                //     );
                                //   }
                                // }}
                                // options={city.map((item) => item.cityName)} // Use cityName for options
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={t("City")}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        padding: "0 14px", // Add padding for consistent height
                                        minHeight: "50px",
                                        display: "flex",
                                        alignItems: "center",
                                      },
                                      "& input": {
                                        padding: "0 14px", // Add padding inside the input
                                      },
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      style: { height: "100%" }, // Ensure the input fills the available height
                                    }}
                                  />
                                )}
                                renderOption={(props, option) => (
                                  <li {...props}>
                                    <div style={{ fontSize: "16px" }}>
                                      {option.cityName}{" "}
                                      {/* Render only the cityName here */}
                                    </div>
                                  </li>
                                )}
                                sx={{
                                  "& .MuiAutocomplete-inputRoot": {
                                    padding: "0 !important", // Prevent extra padding in the input
                                    minHeight: "50px", // Ensure consistent height
                                  },
                                }}
                                fullWidth
                              />
                            </FormControl>
                          </Grid>
                        )}
                      </Grid>

                      {/* <img
          src={_ButtonDelete_}
          disabled={edit == false}
          alt="Delete"
          style={{ cursor: "pointer", marginRight: 5 }}
          onClick={() => handleDeletePrice(price.id)}
        /> */}
                      <img
                        src={_ButtonDelete_}
                        alt="Delete"
                        style={{
                          cursor: edit ? "pointer" : "default", // Use default cursor when disabled
                          marginRight: 5,
                          pointerEvents: edit ? "auto" : "none", // Disable click interaction
                        }}
                        onClick={() => handleDeletePrice(price.id)} // This will only work when edit is true
                      />
                    </Box>
                  </form>
                </Box>
              ))
            : priceList.map((price) => (
                // render your price details here
                <Box
                  key={price.id}
                  sx={{
                    minHeight: "10%",
                    marginTop: 5,
                    flexGrow: 1,
                    paddingBottom: "0.5rem",
                    width: "100%",
                    "& .MuiTextField-root": { m: 1 },
                    "& .MuiFormLabel-root": {
                      color: "rgba(16, 42, 98, 1)",
                      "& .MuiFormLabel-asterisk": {
                        color: "rgba(239, 0, 0, 1)",
                        fontSize: "18px",
                      },
                    },
                  }}
                >
                  <form autoComplete="off" noValidate>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        bgcolor: "#EFEFEF",
                        height: "120px",
                        marginTop: "-25px",
                        borderRadius: "5px",
                        paddingRight: "10px",
                      }}
                    >
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={6} md={1.9}>
                          <FormControl fullWidth>
                            <DateRangePicker
                              id={price.id}
                              onSelectDateRange={handlePartnerChange}
                              start={price.startDate}
                              end={price.endDate}
                              edit={edit}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={2.1}>
                          <FormControl fullWidth error={!!errors.fiscalID}>
                            <Autocomplete
                              value={
                                supplier.find(
                                  (opt) =>
                                    opt.suppliesRegistrationId ===
                                    price?.supplier
                                ) || null
                              }
                              disabled={edit == false}
                              onChange={(event, newValue) => {
                                handlePartnerChange(
                                  price.id,
                                  "supplier",
                                  newValue.suppliesRegistrationId
                                );
                              }}
                              options={supplier}
                              getOptionLabel={(option) =>
                                option.supplierCompanyName || ""
                              }
                              isOptionEqualToValue={(option, value) =>
                                option.suppliesRegistrationId ===
                                value?.suppliesRegistrationId
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t("priceParameterization.supplier")}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                  disabled={edit == false}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      padding: "0 14px", // Adjust padding to fit the height
                                      minHeight: "50px",
                                      display: "flex",
                                      alignItems: "center",
                                    },
                                    "& input": {
                                      padding: "0 14px", // Add left and right padding without increasing height
                                    },
                                    "& .MuiFormHelperText-root": {
                                      margin: 0,
                                      height: errors.fiscalID ? "20px" : "0px",
                                      opacity: errors.fiscalID ? 1 : 0,
                                      transition: "all 200ms",
                                    },
                                    marginBottom: errors.fiscalID
                                      ? "20px"
                                      : "0px",
                                    transition: "margin 200ms",
                                  }}
                                  inputProps={{
                                    ...params.inputProps,
                                    style: { height: "100%" }, // Ensure the input fills the available height
                                  }}
                                />
                              )}
                              renderOption={(props, option) => (
                                <li
                                  {...props}
                                  key={option.suppliesRegistrationId}
                                >
                                  {option.supplierCompanyName}
                                </li>
                              )}
                              sx={{
                                "& .MuiAutocomplete-inputRoot": {
                                  padding: "0 !important", // Prevent extra padding in input
                                  minHeight: "50px", // Ensure consistent height
                                },
                              }}
                              fullWidth
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={1.9}>
                          <FormControl fullWidth>
                            <TextField
                              name="minimumQuantity"
                              label={t("priceParameterization.minimumQuantity")}
                              //InputLabelProps={{ shrink: true }}
                              value={price?.minimumQuantity}
                              onChange={(e) =>
                                handlePartnerChange(
                                  price.id,
                                  "minimumQuantity",
                                  e.target.value
                                )
                              }
                              disabled={edit == false}
                              fullWidth
                              error={!!errors.transporterName} // Show error state
                              helperText={errors.transporterName || ""} // Show error message
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  padding: "7px",
                                  minHeight: "50px",
                                  // bgcolor: "#E9EBEE",
                                },
                                "& .MuiInputLabel-root": {
                                  lineHeight: "1.2em",
                                },
                                //   "& fieldset": {
                                //     borderColor: "#BDBDBD", // Default border color
                                //   },
                                //   "&:hover fieldset": {
                                //     borderColor: "#BDBDBD !important", // Override hover border color
                                //   },
                                //   "& .MuiFormHelperText-root": {
                                //     margin: 0,
                                //     height: errors.transporterName ? "20px" : "0px",
                                //     opacity: errors.transporterName ? 1 : 0,
                                //     transition: "all 200ms",
                                //   },
                                //   marginBottom: errors.transporterName ? "20px" : "0px",
                                //   transition: "margin 200ms",
                              }}
                              InputProps={{
                                style: { height: "50px" },
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={1}>
                          <FormControl fullWidth>
                            <TextField
                              name="price"
                              label={t("priceParameterization.price")}
                              //InputLabelProps={{ shrink: true }}
                              value={price?.price}
                              onChange={(e) =>
                                handlePartnerChange(
                                  price.id,
                                  "price",
                                  e.target.value
                                )
                              }
                              disabled={edit == false}
                              fullWidth
                              error={!!errors.fiscalID} // Show error state
                              helperText={errors.fiscalID || ""} // Show error message
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  padding: "7px",
                                  minHeight: "50px",
                                  //bgcolor: "#E9EBEE",
                                },
                                "& .MuiInputLabel-root": {
                                  lineHeight: "1.2em",
                                },
                                //   "& fieldset": {
                                //     borderColor: "#BDBDBD", // Default border color
                                //   },
                                //   "&:hover fieldset": {
                                //     borderColor: "#BDBDBD !important", // Override hover border color
                                //   },
                                //   "& .MuiFormHelperText-root": {
                                //     margin: 0,
                                //     height: errors.fiscalID ? "20px" : "0px",
                                //     opacity: errors.fiscalID ? 1 : 0,
                                //     transition: "all 200ms",
                                //   },
                                //   marginBottom: errors.fiscalID ? "20px" : "0px",
                                //   transition: "margin 200ms",
                              }}
                              InputProps={{
                                style: { height: "50px" },
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2.5}>
                          <FormControl component="fieldset">
                            <FormLabel
                              component="legend"
                              disabled={edit == false}
                              sx={{
                                marginLeft: 2.5,
                                "&.Mui-focused": { color: "inherit" },
                                "&:hover": { color: "inherit" },
                              }}
                            >
                              {t("priceParameterization.priceDefinedBy")}
                            </FormLabel>
                            <RadioGroup
                              sx={{
                                marginLeft: 2.5,
                                display: "flex",
                                flexDirection: "row", // Ensures horizontal layout
                              }}
                              value={
                                priceList.find((item) => item.hierarchicalNode)
                                  ?.id || ""
                              }
                            >
                              <FormControlLabel
                                value={price.id}
                                disabled={edit == false}
                                onChange={() =>
                                  handlePartnerChange(
                                    price.id,
                                    "hierarchicalNode",
                                    true
                                  )
                                }
                                name="hierarchicalNode"
                                control={<Radio />}
                                label={t(
                                  "priceParameterization.hierarchicalNode"
                                )}
                                sx={{
                                  display: "flex", // Ensures each label is aligned horizontally
                                  alignItems: "center", // Ensures items are vertically aligned
                                  marginRight: 2, // Optional: Adds space between labels
                                }}
                                checked={price.hierarchicalNode === true}
                              />
                              <FormControlLabel
                                value={price.id}
                                disabled={edit == false}
                                onChange={() =>
                                  handlePartnerChange(price.id, "city", true)
                                }
                                name="city"
                                control={<Radio />}
                                label={t("priceParameterization.city")}
                                sx={{
                                  display: "flex", // Ensures each label is aligned horizontally
                                  alignItems: "center", // Ensures items are vertically aligned
                                  marginRight: 0, // Optional: Removes extra margin on the right
                                  //marginLeft:"-30px",
                                }}
                                checked={price.city === true}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        {price.hierarchicalNode && (
                          <Grid item xs={12} sm={6} md={2.5}>
                            <FormControl fullWidth>
                              <Autocomplete
                                value={
                                  hierarchyOptions.find(
                                    (option) =>
                                      option.value === price.hierarchicalNodeId
                                  ) || null
                                }
                                disableClearable={true}
                                onChange={(event, newValue) => {
                                  setPriceList((prevPriceList) =>
                                    prevPriceList.map((item) =>
                                      item.id === price.id
                                        ? {
                                            ...item,
                                            hierarchicalNodeId:
                                              newValue?.value || "", // Update with the correct value
                                          }
                                        : item
                                    )
                                  );
                                }}
                                options={hierarchyOptions}
                                getOptionLabel={(option) =>
                                  option.label || "No option selected"
                                }
                                // renderOption={(props, option) => (
                                //   <li {...props}>
                                //     <div>
                                //       <div style={{ fontSize: "16px" }}>
                                //         {option.label}
                                //       </div>
                                //       <div
                                //         style={{
                                //           fontSize: "10px",
                                //           color: "#5A646E",
                                //         }}
                                //       >
                                //         {option.tag}
                                //       </div>
                                //     </div>
                                //   </li>
                                // )}
                                renderInput={(params) => (
                                  <TextField
                                    name="hierarchicalNode"
                                    {...params}
                                    label={t("Hierarchical Node")}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    sx={{
                                      "& .MuiInputBase-input::placeholder": {
                                        opacity: 1,
                                      },
                                    }}
                                    InputProps={{
                                      ...params.InputProps,
                                      style: { height: "50px" },
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {price.city && (
                          <Grid item xs={12} sm={6} md={2.5}>
                            <FormControl fullWidth error={!!errors.fiscalID}>
                              <Autocomplete
                                value={
                                  city.find(
                                    (option) => option.cityId === price.cityId
                                  ) || null
                                }
                                onChange={(event, newValue) => {
                                  setPriceList((prevPriceList) =>
                                    prevPriceList.map((item) =>
                                      item.id === price.id
                                        ? {
                                            ...item,
                                            cityId: newValue?.cityId || "", // Use cityId or empty string
                                          }
                                        : item
                                    )
                                  );
                                }}
                                options={city}
                                getOptionLabel={(option) =>
                                  option.cityName || "No city selected"
                                }
                                disabled={edit == false}
                                disableClearable
                                // onChange={(event, newValue) => {
                                //   if (newValue) {
                                //     setPriceList((prevPriceList) =>
                                //       prevPriceList.map((item) =>
                                //         item.id === price.id
                                //           ? {
                                //               ...item,
                                //               priceDefinedBy: {
                                //                 ...price.priceDefinedBy,
                                //                 cityName: newValue,
                                //               },
                                //             }
                                //           : item
                                //       )
                                //     );
                                //   } else {
                                //     setPriceList((prevPriceList) =>
                                //       prevPriceList.map((item) =>
                                //         item.id === price.id
                                //           ? {
                                //               ...item,
                                //               priceDefinedBy: {
                                //                 ...price.priceDefinedBy,
                                //                 cityName: "",
                                //               },
                                //             }
                                //           : item
                                //       )
                                //     );
                                //   }
                                // }}
                                // options={city.map((item) => item.cityName)} // Use cityName for options
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={t("City")}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        padding: "0 14px", // Add padding for consistent height
                                        minHeight: "50px",
                                        display: "flex",
                                        alignItems: "center",
                                      },
                                      "& input": {
                                        padding: "0 14px", // Add padding inside the input
                                      },
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      style: { height: "100%" }, // Ensure the input fills the available height
                                    }}
                                  />
                                )}
                                renderOption={(props, option) => (
                                  <li {...props}>
                                    <div style={{ fontSize: "16px" }}>
                                      {option.cityName}{" "}
                                      {/* Render only the cityName here */}
                                    </div>
                                  </li>
                                )}
                                sx={{
                                  "& .MuiAutocomplete-inputRoot": {
                                    padding: "0 !important", // Prevent extra padding in the input
                                    minHeight: "50px", // Ensure consistent height
                                  },
                                }}
                                fullWidth
                              />
                            </FormControl>
                          </Grid>
                        )}
                      </Grid>

                      {/* <img
          src={_ButtonDelete_}
          disabled={edit == false}
          alt="Delete"
          style={{ cursor: "pointer", marginRight: 5 }}
          onClick={() => handleDeletePrice(price.id)}
        /> */}
                      <img
                        src={_ButtonDelete_}
                        alt="Delete"
                        style={{
                          cursor: edit ? "pointer" : "default", // Use default cursor when disabled
                          marginRight: 5,
                          pointerEvents: edit ? "auto" : "none", // Disable click interaction
                        }}
                        onClick={() => handleDeletePrice(price.id)} // This will only work when edit is true
                      />
                    </Box>
                  </form>
                </Box>
              ))}

          {/* Buttons */}
          {/* <hr style={{ marginTop: "200px" }} /> */}
          <Divider
            sx={{
              position: "fixed",
              bottom: "82px",
              width: "97%",
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              position: "fixed",
              width: "96%",
              bottom: 22,
              padding: "0.5rem",
              background: "white",
              zIndex: 999,
            }}
          >
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: "6px 16px",
                borderRadius: "4px",
                height: "44px",
                marginLeft: "10px",
              }}
              variant="contained"
              onClick={handleOpenModal}
              disabled={loading}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
            >
              {loading && <CircularProgress size={20} />}
              {edit ? "Cancel" : "Back"}
            </Button>

            <Modal
              open={openModal}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  width: "585px",
                  margin: "auto",
                  mt: 25,
                  height: "200px",
                }}
              >
                <Box
                  sx={{
                    background: "#102A62",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                  }}
                >
                  <Typography
                    sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                  >
                    {t("cancelTitle")}
                  </Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, fontWeight: 400 }}
                  >
                    {t("cancelMessage")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    mb: 1,
                  }}
                >
                  <Button
                    onClick={handleCloseModal}
                    sx={{
                      background: "#737D86",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#737D86" },
                    }}
                  >
                    {t("no")}
                  </Button>
                  <Button
                    onClick={handleDeleteConfirm}
                    sx={{
                      background: "#004F9F",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#004F9F" },
                    }}
                  >
                    {t("yes")}
                  </Button>
                </Box>
              </Box>
            </Modal>

            {edit && (
              <Button
                sx={{
                  padding: "6px 16px",
                  borderRadius: "4px",
                  backgroundColor: "#EF7D00",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#EF7D00",
                  },
                  marginRight: "10px",
                }}
                onClick={handleUpdate}
                disabled={loading}
                startIcon={<img src={Save} alt="Right Arrow" />}
                variant="contained"
              >
                {loading && <CircularProgress size={20} />}
                {"Save"}
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Layout>
  );
}
