// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-datepicker {
    border-radius: var(--borderRadius, 4px) !important;
    border: 1px solid var(--Gray-2, #ACB4BA) !important;
    height: 40px !important;
    padding: 0px 12px !important;
    width: 100% !important;
    box-sizing: border-box !important;
    font-family: Montserrat !important;
    font-size: 14px !important;
  }
  
  .custom-datepicker-wrapper {
    width: 250px !important;
  }
  
  .custom-datepicker-wrapper .react-datepicker-wrapper {
    width: 100% !important;
  }


  .history-datepicker {
    border-radius: var(--borderRadius, 4px) !important;
    border: 1px solid var(--Gray-2, #ACB4BA) !important;
    height: 40px !important;
    padding: 0px 42px !important;
    width: 100% !important;
    box-sizing: border-box !important;
    font-family: Montserrat !important;
    font-size: 14px !important;
  }
  
  .history-datepicker-wrapper {
    width: 250px !important;
  }
  
  .history-datepicker-wrapper .react-datepicker-wrapper {
    width: 100% !important;
  }


`, "",{"version":3,"sources":["webpack://./src/components/pages/Growing/TaskManagement/DatePicker.css"],"names":[],"mappings":"AAAA;IACI,kDAAkD;IAClD,mDAAmD;IACnD,uBAAuB;IACvB,4BAA4B;IAC5B,sBAAsB;IACtB,iCAAiC;IACjC,kCAAkC;IAClC,0BAA0B;EAC5B;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,sBAAsB;EACxB;;;EAGA;IACE,kDAAkD;IAClD,mDAAmD;IACnD,uBAAuB;IACvB,4BAA4B;IAC5B,sBAAsB;IACtB,iCAAiC;IACjC,kCAAkC;IAClC,0BAA0B;EAC5B;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,sBAAsB;EACxB","sourcesContent":[".custom-datepicker {\n    border-radius: var(--borderRadius, 4px) !important;\n    border: 1px solid var(--Gray-2, #ACB4BA) !important;\n    height: 40px !important;\n    padding: 0px 12px !important;\n    width: 100% !important;\n    box-sizing: border-box !important;\n    font-family: Montserrat !important;\n    font-size: 14px !important;\n  }\n  \n  .custom-datepicker-wrapper {\n    width: 250px !important;\n  }\n  \n  .custom-datepicker-wrapper .react-datepicker-wrapper {\n    width: 100% !important;\n  }\n\n\n  .history-datepicker {\n    border-radius: var(--borderRadius, 4px) !important;\n    border: 1px solid var(--Gray-2, #ACB4BA) !important;\n    height: 40px !important;\n    padding: 0px 42px !important;\n    width: 100% !important;\n    box-sizing: border-box !important;\n    font-family: Montserrat !important;\n    font-size: 14px !important;\n  }\n  \n  .history-datepicker-wrapper {\n    width: 250px !important;\n  }\n  \n  .history-datepicker-wrapper .react-datepicker-wrapper {\n    width: 100% !important;\n  }\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
