import Layout from "components/structure/Layout";
import React, { useState, useContext, useEffect } from "react";
import PreAssessmentTable from "./PreAssessmentTable";
import { SnackbarContext } from "context/snackBarContext";
import PreAssessmentFormService from "services/PreAssessmentESGFormService";

const PreAssesmentApplication = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState({
    farmerId: "",
    cropSeasonId: "0A2A910C-9344-112F-8193-4421BA110000",
  });

  const callFarmerApi = (pageNumber, size, cropSeasonId) => {
    PreAssessmentFormService.getAllFarmersData({
      pageNumber,
      size,
      cropSeasonId,
    })
      .then((res) => {
        const farmerResults = res?.data?.response?.farmers || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setData(farmerResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (
          farmerResults.length === 0
          //  && searchKeyword.length > 0
        ) {
          setMessage("No records found.");
          setOpenAlert(true);
          setSeverity("info");
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setData([]);
        setTotalItems(0);
      });
  };

  useEffect(() => {
    callFarmerApi(currentPage, itemsPerPage, values.cropSeasonId);
  }, [currentPage, itemsPerPage]);

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    callFarmerApi(pageNumber - 1, itemsPerPage, values.cropSeasonId);
  };

  return (
    <Layout
      title={"Pre Assessment - Form Application"}
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      background="#ffffff"
      useNewPagination={true}
    >
      <PreAssessmentTable data={data} setData={setData} cropSeasonId={values.cropSeasonId}/>
    </Layout>
  );
};

export default PreAssesmentApplication;
